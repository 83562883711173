import { Draft, PayloadAction } from '@reduxjs/toolkit'

import { AppPeek, AppPeekQueueItem } from '../../../types/peek.types'
import { isDef } from '../../../types/lang.types'
import { PeekState } from './peek.slice'
import { PeekShowPayload } from './peek.types'

export function show(state: Draft<PeekState>, action: PayloadAction<PeekShowPayload>): void {
  const { activePeek } = state
  const { peek, props } = action.payload

  if (!isDef(activePeek) || activePeek.peek !== peek) {
    if (isDef(activePeek)) {
      addToQueue(state, activePeek)
    }

    state.activePeek = { peek, props }
  }
}

export function close(state: Draft<PeekState>, action: PayloadAction<AppPeek>): void {
  if (isDef(state.activePeek) && state.activePeek.peek === action.payload) {
    state.activePeek = null
    showNext(state)
  } else if (!removeFromQueue(state, action.payload)) {
    console.error('Unable to close peek: ', action.payload)
  }
}

export function closeLast(state: Draft<PeekState>): void {
  state.activePeek = null
  showNext(state)
}

export function replaceAll(state: Draft<PeekState>, action: PayloadAction<PeekShowPayload>): void {
  state.peeksQueue = null
  state.activePeek = action.payload
}

function showNext(state: Draft<PeekState>): void {
  const queueItem = isDef(state.peeksQueue) ? state.peeksQueue.shift() : null

  if (isDef(queueItem)) {
    state.activePeek = queueItem
  }
}

function addToQueue(state: Draft<PeekState>, queueItem: AppPeekQueueItem): void {
  if (!isDef(state.peeksQueue)) {
    state.peeksQueue = [queueItem]
  } else if (isNotExist(state, queueItem)) {
    state.peeksQueue.push(queueItem)
  }
}

function isNotExist(state: Draft<PeekState>, queueItem: AppPeekQueueItem): boolean {
  const { peeksQueue } = state
  return isDef(peeksQueue) && !peeksQueue.find(item => item.peek === queueItem.peek)
}

function removeFromQueue(state: Draft<PeekState>, peek: AppPeek): boolean {
  const index = getPeekIndex(state, peek)
  if (index !== -1) {
    const queue = state.peeksQueue!.slice()
    queue.splice(index, 1)
    state.peeksQueue = queue.length ? queue : null
    return true
  }

  console.error('Unable to remove peek from queue: ', peek)

  return false
}

function getPeekIndex(state: Draft<PeekState>, peek: AppPeek): number {
  return isDef(state.peeksQueue) ? state.peeksQueue.findIndex(item => item.peek === peek) : -1
}

import { UserOutlined } from '@ant-design/icons'
import React from 'react'
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Flex,
  Space,
  Switch,
  InputNumber,
  Select,
  TimePicker,
  Divider,
  Button,
} from 'antd'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { useMasterServicesFormGeneralFields } from './master-services-form-general-fields.hook'
import { MasterServicesFormGeneralFieldsProps } from './master-services-form-general-fields.types'
import './master-services-form-general-fields.styles.less'

export const MasterServicesFormGeneralFields: React.FC<MasterServicesFormGeneralFieldsProps> = props => {
  const { form, exercisesTypesOptions, timeStepMinutesOptions, onAddTimeStepHandler } = props

  const {
    nameValidationRules,
    exerciseTypeValidationRules,
    timeStepMinutesValidationRules,
    timeValidationRules,
    restrictionStartTimeValidationRules,
    restrictionMinutesDurationValidationRules,
    isReviewEnabled,
    hasMinimumTimeBeforeCancel,
    restrictionStartTime,
    restrictionMinutesDuration,
    isTravelTimeEnabled,
    isIntervalBeforeBookingEnabled,
    hasBookingTimeRestriction,
    hasMinBookingTime,
    timeStep,
    toggleOnlinePaymentEnabled,
    toggleReviewEnabled,
    toggleHasMinimumTimeBeforeCancel,
    toggleTravelTime,
    toggleIntervalBeforeBooking,
    toggleHasBookingTimeRestriction,
    toggleMinBookingTime,
    onTimeStepChange,
    addTimeStep,
    getTimeStepMinutesOptions,
  } = useMasterServicesFormGeneralFields({ form, timeStepMinutesOptions, onAddTimeStepHandler })

  return (
    <Flex gap="middle" vertical>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Основная информация
          </Typography.Title>
          <Row justify="start" gutter={16}>
            <Col span={12}>
              <Form.Item label="Название персональной услуги" name="name" rules={nameValidationRules}>
                <Input size="large" placeholder="Уникальное название для персональной услуги" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Категории записей" name="exerciseTypeIds" rules={exerciseTypeValidationRules}>
                <Select
                  size="large"
                  className="master-services-form-general-fields__select"
                  placeholder="Выбрать категорию"
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.label.props.children[1].props.children.toLowerCase().includes(input.toLowerCase())
                  }
                  options={
                    exercisesTypesOptions
                      ? exercisesTypesOptions.map(option => ({
                          value: option.value,
                          label: (
                            <Flex align="center" gap={6}>
                              <UserOutlined style={{ color: 'inherit' }} />
                              <Typography>{option.label}</Typography>
                            </Flex>
                          ),
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Описание" name="description">
            <Input.TextArea size="large" rows={2} placeholder="Описание" />
          </Form.Item>
        </Flex>
      </CustomCard>

      <CustomCard>
        <Flex gap="middle" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Настройки
          </Typography.Title>
          <Flex gap="5px" vertical>
            <Space align="center" size="small">
              <Form.Item name="onlinePaymentEnabled">
                <Switch size="small" />
              </Form.Item>

              <Typography.Text
                className="master-services-form-general-fields__toggled-title"
                onClick={toggleOnlinePaymentEnabled}
              >
                Включить онлайн-оплату
              </Typography.Text>
              <QuestionTooltip title="Активация оплаты в виджетах. Позволит вам принимать онлайн-оплату сразу при оформлении записи клиентом. Убедитесь, что вы подключили интернет-эквайринг в настройках компании (Управление -> Компания)." />
            </Space>
            <Form.Item name="reviewEnabled">
              <Space align="center" size="small">
                <Switch disabled size="small" value={isReviewEnabled} onChange={toggleReviewEnabled} />
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleReviewEnabled}
                >
                  Запрашивать отзыв после успешного посещения
                </Typography.Text>
                <QuestionTooltip title="В течение нескольких часов после изменения статуса клиента на 'пришел' мы отправим ему email с просьбой оценить качество услуг. Клиент также сможет поставить или изменить оценку в виджете 'Личный кабинет'" />
              </Space>
            </Form.Item>
            <Flex gap="small" align="baseline">
              <Form.Item name="hasMinimumTimeBeforeCancel">
                <Switch
                  disabled
                  size="small"
                  value={hasMinimumTimeBeforeCancel}
                  onChange={toggleHasMinimumTimeBeforeCancel}
                />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleHasMinimumTimeBeforeCancel}
                >
                  Ограничение для отмены записи
                </Typography.Text>
                {hasMinimumTimeBeforeCancel && (
                  <CustomCard customClassName="master-services-form-general-fields__card">
                    <Form.Item name="minimumTimeBeforeCancel" label="Максимальное время" required>
                      <InputNumber
                        size="large"
                        suffix="час"
                        placeholder="0"
                        className="master-services-form-general-fields__input-number"
                      />
                    </Form.Item>
                  </CustomCard>
                )}
              </Flex>
              <QuestionTooltip title="Это свойство позволит вам установить минимальное время, за которое клиент не сможет отменить свою запись." />
            </Flex>
            <Flex gap="small" align="baseline">
              <Form.Item name="travelTimeEnabled">
                <Switch disabled size="small" value={isTravelTimeEnabled} onChange={toggleTravelTime} />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleTravelTime}
                >
                  Время на дорогу исполнителю
                </Typography.Text>
                {isTravelTimeEnabled && (
                  <Form.Item name="travelTimeDuration">
                    <InputNumber size="large" suffix="мин" placeholder="0" />
                  </Form.Item>
                )}
              </Flex>
              <QuestionTooltip title="При установке этого свойства система будет отслеживать текущее местоположение исполнителя. Если клиент хочет забронировать услугу именно с этим исполнителем, то при построении расписания будет учтено время на дорогу от предыдущего места нахождения исполнителя. Если местоположение не меняется, дополнительное время не будет добавлено." />
            </Flex>
            <Flex gap="small" align="baseline">
              <Form.Item name="intervalBeforeBookingEnabled">
                <Switch
                  disabled
                  size="small"
                  value={isIntervalBeforeBookingEnabled}
                  onChange={toggleIntervalBeforeBooking}
                />
              </Form.Item>
              <Flex vertical gap="small">
                <Typography.Text
                  className="master-services-form-general-fields__title"
                  // onClick={toggleIntervalBeforeBooking}
                >
                  Минимальной интервал до записи
                </Typography.Text>
                {isIntervalBeforeBookingEnabled && (
                  <Form.Item name="intervalMinutesDuration">
                    <InputNumber size="large" suffix="мин" placeholder="0" />
                  </Form.Item>
                )}
              </Flex>
              <QuestionTooltip title="Активируйте это свойство, чтобы избежать внезапных записей за минуту до начала. Установите время в минутах — именно через столько минут клиент сможет записаться на свободную запись с момента открытия расписания." />
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>

      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className="master-services-form-general-fields__title">
            Настройки временных слотов
          </Typography.Title>
          <Row justify="start" gutter={16}>
            <Col span={6}>
              <Form.Item
                label="Длина одного слота для записи"
                tooltip="Это правило определяет, как будет сформировано расписание для клиента в виджете. С таким же шагом ваши менеджеры будут создавать записи этой услуги в расписании. Например, установив интервал в 1 час, клиент увидит слоты: 10:00, 11:00, 12:00 и т.д. А при интервале в 30 минут — 10:00, 10:30, 11:00 и так далее."
                name="timeStepMinutes"
                rules={timeStepMinutesValidationRules}
              >
                <Select
                  size="large"
                  placeholder="Выбрать"
                  optionLabelProp="label"
                  className="master-services-form-general-fields__select"
                  options={getTimeStepMinutesOptions()}
                  dropdownRender={menu => (
                    <>
                      {menu}
                      <Divider style={{ margin: '8px 0' }} />
                      <Space style={{ padding: '0 8px 4px' }}>
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="Укажите минуты"
                          value={timeStep}
                          onChange={onTimeStepChange}
                        />
                        <Button type="text" onClick={addTimeStep} disabled={(timeStep ?? 0) <= 0}>
                          Добавить
                        </Button>
                      </Space>
                    </>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Flex gap="small" align="baseline">
            <Form.Item name="hasMinBookingTime">
              <Switch size="small" />
            </Form.Item>
            <Flex vertical gap="small">
              <Typography.Text
                onClick={toggleMinBookingTime}
                className="master-services-form-general-fields__toggled-title"
              >
                Минимальная длительность бронирования
              </Typography.Text>
              {hasMinBookingTime && (
                <Flex gap="middle">
                  <Form.Item name="minHoursForBooking" label="Часы" rules={timeValidationRules}>
                    <InputNumber size="large" placeholder="0" />
                  </Form.Item>
                  <Form.Item name="minMinutesForBooking" label="Минуты" rules={timeValidationRules}>
                    <InputNumber size="large" placeholder="0" />
                  </Form.Item>
                </Flex>
              )}
            </Flex>
            <QuestionTooltip title="Позволяет установить минимальное время, которое клиент должен забронировать. Например, если длина одного слота для услуги составляет 30 минут, вы можете установить минимальную длительность бронирования в 60 минут. Это значит, что клиент должен будет забронировать минимум 1 час, но при этом сможет добавлять дополнительные 30-минутные слоты. Цена услуги указывается за 30 минут, но минимальная бронирование будет на 1 час." />
          </Flex>
          <Flex gap="small" align="baseline">
            <Form.Item name="hasBookingTimeRestriction">
              <Switch size="small" />
            </Form.Item>
            <Flex vertical gap="small">
              <Space>
                <Typography.Text
                  className="master-services-form-general-fields__toggled-title"
                  onClick={toggleHasBookingTimeRestriction}
                >
                  Защита от ночных бронирований
                </Typography.Text>
                <QuestionTooltip title="Это свойство позволит вам избежать неожиданных записей на раннее утро после завершения рабочего дня. Установите время, с которого начинается перерыв для новых бронирований, и укажите его продолжительность в часах. Таким образом, вы будете уверены, что сможете подготовить команду без сюрпризов." />
              </Space>
              {hasBookingTimeRestriction && (
                <Flex vertical gap="small">
                  <CustomCard customClassName="master-services-form-general-fields__card">
                    <Flex gap="middle">
                      <Form.Item name="restrictionStartTime" label="Время" rules={restrictionStartTimeValidationRules}>
                        <TimePicker
                          size="large"
                          format="HH:mm"
                          suffixIcon={null}
                          placeholder="10:00"
                          allowClear={false}
                        />
                      </Form.Item>
                      <Form.Item
                        name="restrictionMinutesDuration"
                        label="Часы"
                        rules={restrictionMinutesDurationValidationRules}
                      >
                        <InputNumber size="large" suffix="ч" placeholder="0" />
                      </Form.Item>
                    </Flex>
                  </CustomCard>
                  {restrictionStartTime && (
                    <Typography.Text className="master-services-form-general-fields__restriction-time">{`После ${restrictionStartTime.format(
                      'HH:mm'
                    )}, первый слот для записи будет доступен с ${restrictionStartTime
                      .add(restrictionMinutesDuration ?? 0, 'hour')
                      .format('HH:mm')}`}</Typography.Text>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>
    </Flex>
  )
}

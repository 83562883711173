import { NNumber, NString } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface EmployeesPageParams {
  page?: NNumber
  size?: NNumber
  type?: NString
}

export const EMPLOYEES_PAGE_SIZE: number = DEFAULT_PAGE_SIZE

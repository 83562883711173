import { Flex, Button, Alert, Divider, Form, Space, Switch, Typography, Select, FormInstance } from 'antd'
import { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { TillsApi } from '../../../../../api/types/tills-api.types'
import { StudiosFormTypes } from '../../studios-form-types'
import { IRouteParams } from './types/hooks'
import { OfflineTillCard } from './ui/offline-till-card/offline-till-card.component'
import { fetchTills, saveTills, fetchOfflineTillsFromBD } from './utils/actions-tills'
import { validateOfflineTillName } from './utils/validate-tills'

interface Props {
  form: FormInstance<StudiosFormTypes>
  activeKeys: string[]
  onCollapseChange: (key: string[]) => void
}

export const StudiosFormOflineTills: React.FC<Props> = props => {
  const { form, activeKeys, onCollapseChange } = props
  // Hooks
  const { id } = useParams<IRouteParams>()

  const printCopies = Form.useWatch('printCopies', form)

  // State
  const [notEditedOfflineTills, setNotEditedOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])
  const [offlineTills, setOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [hasError, setHasError] = useState(false)

  // Constants
  const hasTills = offlineTills.length > 0
  const updateDevicesButtonLabel = hasTills ? 'Обновить кассы' : 'Найти кассы'

  // Actions
  const togglePrintCopies = () => {
    form.setFieldValue('printCopies', !printCopies)
  }

  const handleChangeOfflineTill = (till: Partial<TillsApi.OfflineTillDTO>) => {
    setOfflineTills(
      offlineTills.map(t => {
        if (t.idDevice === till.idDevice && till.nameDevice) {
          t.nameDevice = till.nameDevice
          t.isChanged = true
        }
        return t
      })
    )
  }

  const setTills = (tills: TillsApi.OfflineTillDTO[]) => {
    setOfflineTills(tills)
    setNotEditedOfflineTills(tills.map(item => ({ ...item })))
  }

  const updateOfflineTills = async (withoutNotification?: boolean) => {
    await fetchTills(setTills, setIsUpdating, id, withoutNotification)
  }

  const handleFetchTills = async () => {
    await updateOfflineTills(false)
  }

  const handleSaveTills = async () => {
    await saveTills(offlineTills, id)
    await updateOfflineTills(true)

    onCancelHandler()
  }

  const onCancelHandler = useCallback(() => {
    const filteredKeys = activeKeys.filter(key => key !== '0')
    onCollapseChange(filteredKeys)
  }, [activeKeys, onCollapseChange])

  // Validations
  const validatorOfflineTillName = (value: string, oldOfflineTillName?: string) =>
    validateOfflineTillName(notEditedOfflineTills, value, oldOfflineTillName)

  // Life cycles
  const setDefaultOfflineTills = async () => {
    const offlineTillsFromBD = await fetchOfflineTillsFromBD(id)
    setTills(offlineTillsFromBD.offlineTills)
  }

  useEffect(() => {
    setDefaultOfflineTills()
  }, [])

  // Render
  return (
    <Flex vertical gap="large">
      <Flex vertical gap="small" align="start">
        <Button loading={isUpdating} onClick={handleFetchTills}>
          {updateDevicesButtonLabel}
        </Button>
        {!hasTills && (
          <Alert
            message={`Нажимая кнопку “${updateDevicesButtonLabel}”, убедитесь что вы это делаете с компьютера который соединён с кассой`}
            type="warning"
            showIcon
            closable
          />
        )}
      </Flex>

      <Flex vertical gap="large">
        {offlineTills.map(till => (
          <OfflineTillCard
            offlineTill={till}
            handleChange={handleChangeOfflineTill}
            key={till.idDevice}
            validatorOfflineTillName={validatorOfflineTillName}
            setHasError={setHasError}
          />
        ))}
      </Flex>

      <Form.Item>
        <Button type="primary" ghost>
          Добавить ещё кассу
        </Button>
      </Form.Item>

      <Divider style={{ margin: 0 }} />

      <Space align="center" size="small">
        <Form.Item name="printCopies">
          <Switch size="small" />
        </Form.Item>
        <Typography.Text className="studios-form__toggled-title" onClick={togglePrintCopies}>
          Печать нескольких экземпляров чеков продаж
        </Typography.Text>
      </Space>

      <Form.Item label="Методы оплат" style={{ maxWidth: '480px' }}>
        <Select size="large" mode="multiple" />
      </Form.Item>

      <Space size="middle">
        <Button onClick={onCancelHandler}>Отменить</Button>
        <Button type="primary" disabled={!hasTills || hasError} onClick={handleSaveTills}>
          Сохранить
        </Button>
      </Space>
    </Flex>
  )
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { genScheduleGroupPagePath, genStudiosEditPagePath } from '../../../format/path.format'
import { useStudio } from '../../../hooks/use-studios.hook'
import {
  genSchedulePageListIsLoading,
  genSchedulePageListRoomsWorkTime,
  genSchedulePageListStudioWorkTime,
  genSchedulePageListStudiosRoomsTags,
  genSchedulePageListTableRowList,
  genSchedulePageListSlotClosingMode,
  genBreaksInClosingMode,
} from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { isDef } from '../../../types/lang.types'
import { AppModal } from '../../../types/modal.types'
import { useSchedulePageParams } from '../schedule-page-hooks/schedule-page-params.hook'
import {
  ScheduleGroupPageSection,
  ScheduleGroupPageUrlParams,
} from '../../schedule-group-page/schedule-group-page.types'
import { validateStrEnumValue } from '../../../utils/enum.utils'
import { TimetableItemType } from '../../../types/timetable.types'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import { TimetableDataItem } from '../../../components/exercises/exercises-table/exercises-table.types'
import { formatDateTime } from '../../../format/date.format'

export function useSchedulePageTable() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const { studioId, date } = useSchedulePageParams()
  const { studioOffset } = useStudio()

  const isLoading = useSelector(genSchedulePageListIsLoading)

  const schedule = useSelector(genSchedulePageListTableRowList)
  const breaksInClosingMode = useSelector(genBreaksInClosingMode)

  const studioWorkTime = useSelector(genSchedulePageListStudioWorkTime)
  const studiosRoomsTags = useSelector(genSchedulePageListStudiosRoomsTags)
  const studioRoomsWorkTime = useSelector(genSchedulePageListRoomsWorkTime)

  const slotClosingMode = useSelector(genSchedulePageListSlotClosingMode)

  const handleAddBreak = React.useCallback(
    (studioOffset: number, date: string, studioRoomId?: string, timeFrom?: string, timeTo?: string) => {
      const studiosRoom = studiosRoomsTags?.find(room => room.id === studioRoomId)

      if (timeFrom && timeTo && studiosRoom) {
        const newBreak: TimetableDataItem = {
          id: crypto.randomUUID(),
          timeFrom: formatDateTime(date, timeFrom, studioOffset),
          timeTo: formatDateTime(date, timeTo, studioOffset),
          timetableItemType: TimetableItemType.TECHNICAL_BREAK,
          studiosRoom,
          isFilled: false,
          isHidden: false,
          comment: null,
        }

        dispatch(schedulePageListActions.createBreak(newBreak))
      }
    },
    [dispatch, studiosRoomsTags]
  )

  const onAddHandler = React.useCallback(
    (
      studioRoomId?: string,
      timeFrom?: string,
      timeTo?: string,
      isFrozenTime?: boolean,
      timetableType?: 'weekly' | 'daily',
      customDate?: string
    ): void => {
      if (isDef(studioOffset) && isDef(customDate ?? date)) {
        const roomId = studiosRoomsTags?.[0].id as string
        const scheduleDate = dayjs(customDate).format('YYYY-MM-DD') ?? date

        if (!slotClosingMode) {
          dispatch(
            modalActions.show({
              modal: AppModal.SCHEDULE_PAGE_MODAL_CREATE,
              props: {
                studioId,
                studioOffset,
                studioRoomId: studioRoomId ?? roomId,
                date: scheduleDate,
                timeFrom,
                timeTo,
                isFrozenTime,
                timetableType,
              },
            })
          )
        } else {
          handleAddBreak(studioOffset, scheduleDate, studioRoomId, timeFrom, timeTo)
        }
      }
    },
    [date, dispatch, handleAddBreak, slotClosingMode, studioId, studioOffset, studiosRoomsTags]
  )

  const onViewHandler = React.useCallback(
    (scheduleId: string, activeKey: string): void => {
      const section = validateStrEnumValue<ScheduleGroupPageSection>(ScheduleGroupPageSection, activeKey)
      const params: ScheduleGroupPageUrlParams =
        isDef(section) && section !== ScheduleGroupPageSection.OVERVIEW
          ? { studioId, scheduleId, section }
          : { studioId, scheduleId }

      push({ pathname: genScheduleGroupPagePath(params), state: { date: date } })
    },
    [date, push, studioId]
  )

  const onMoveHandler = React.useCallback(
    (id: string, roomId: string, timeFrom: string, timeTo: string) => {
      if (!slotClosingMode) {
        dispatch(schedulePageListActions.updateExercise({ id, roomId, timeFrom, timeTo }))
      } else {
        const studiosRoom = studiosRoomsTags?.find(room => room.id === roomId)
        if (studioOffset && studiosRoom) {
          dispatch(schedulePageListActions.updateBreakTime({ id, timeFrom, timeTo, studiosRoom }))
        }
      }
    },
    [dispatch, slotClosingMode, studioOffset, studiosRoomsTags]
  )

  const onCancelHandler = React.useCallback(
    (id: string, eventType: TimetableItemType): void => {
      if (!slotClosingMode) {
        dispatch(
          modalActions.show({
            modal: AppModal.SCHEDULE_PAGE_MODAL_CONFIRM,
            props: { id, eventType },
          })
        )
      } else {
        dispatch(schedulePageListActions.removeBreak(id))
      }
    },
    [dispatch, slotClosingMode]
  )

  const onCommentHandler = React.useCallback(
    (id: string, comment: string): void => {
      dispatch(schedulePageListActions.updateBreakComment({ id, comment }))
    },
    [dispatch]
  )

  const onEditStudioHandler = React.useCallback((): void => {
    push(genStudiosEditPagePath(studioId))
  }, [push, studioId])

  return {
    studioOffset,
    date,
    schedule,
    breaksInClosingMode,
    studioWorkTime,
    studioRoomsWorkTime,
    isLoading,
    studiosRoomsTags,
    slotClosingMode,
    onAddHandler,
    onViewHandler,
    onCancelHandler,
    onEditStudioHandler,
    onMoveHandler,
    onCommentHandler,
  }
}

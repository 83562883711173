import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { ClientsEditPagePeekTransactionHistoryProps } from './clients-edit-page-peek-transaction-history.types'
import {
  genClientsEditPagePeekTransactionHistoryTransactionHistory,
  genClientsEditPagePeekTransactionHistoryIsLoading,
  genClientsEditPagePeekTransactionHistoryTotalElements,
} from '../../../store/pages/clients-edit-page/clients-edit-page-peek-transaction-history/clients-edit-page-peek-transaction-history.selectors'
import { clientsEditPagePeekTransactionHistoryActions } from '../../../store/pages/clients-edit-page/clients-edit-page-peek-transaction-history/clients-edit-page-peek-transaction-history.slice'

const CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY_INITIAL_PAGE: number = 0
const CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY_INITIAL_SIZE: number = 25

export function useClientsEditPagePeekTransactionHistory(props: ClientsEditPagePeekTransactionHistoryProps) {
  const { id } = props
  const dispatch = useDispatch()

  const transactionHistory = useSelector(genClientsEditPagePeekTransactionHistoryTransactionHistory)
  const isLoading = useSelector(genClientsEditPagePeekTransactionHistoryIsLoading)
  const totalElements = useSelector(genClientsEditPagePeekTransactionHistoryTotalElements)

  const [page, setPage] = useState(CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY_INITIAL_SIZE)

  const pagination = useMemo(
    () => genListPaginationConfig(page, pageSize, totalElements),
    [page, pageSize, totalElements]
  )

  const onChangePageHandler = useCallback((page: number, pageSize: number): void => {
    setPage(page)
    setPageSize(pageSize)
  }, [])

  const onChangePageSizeHandler = useCallback((pageSize: number): void => {
    setPageSize(pageSize)
  }, [])

  useEffect(() => {
    dispatch(
      clientsEditPagePeekTransactionHistoryActions.fetchAudit({
        id,
        page,
        size: pageSize,
      })
    )
  }, [dispatch, id, page, pageSize])

  useEffect(() => {
    return () => {
      dispatch(clientsEditPagePeekTransactionHistoryActions.reset())
    }
  }, [dispatch])

  return {
    transactionHistory,
    isLoading,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}

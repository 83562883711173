import { StudiosHistoryPeek } from '../../../components/studios/studios-history-peek/studios-history-peek.component'
import { useStudiosPagePeekHistory } from './studios-page-peek-history.hook'
import { StudiosPagePeekHistoryProps } from './studios-page-peek-history.types'

export const StudiosPagePeekHistory: React.FC<StudiosPagePeekHistoryProps> = props => {
  const { id } = props

  const { studioHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useStudiosPagePeekHistory(id)

  return (
    <StudiosHistoryPeek
      studioHistory={studioHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapEmployeesScheduleToDaysOffFormValues } from '../../../../mapping/employees.mapping'

export const getEmployeesScheduleEditIsLoading = (state: AppState) => state.employeesScheduleEdit.isLoading
export const getEmployeesScheduleEditError = (state: AppState) => state.employeesScheduleEdit.error
export const getEmployeesScheduleEdit = (state: AppState) => state.employeesScheduleEdit.schedule

const getDaysOff = (state: AppState) => state.employeesScheduleEdit.daysOff

export const getEmployeesScheduleEditDayesOff = createSelector(getDaysOff, daysOff =>
  mapEmployeesScheduleToDaysOffFormValues(daysOff)
)

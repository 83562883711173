import { generateGUID } from '../utils/guid.utils'
import APIRequests from './core/APIRequests'
import {
  IGetCommandResultResponse,
  IGetListRowOfflineTillResponse,
  IPrintCheckCommand,
  IPrintCheckResponse,
  IPrintTerminalReportResponse,
  IPrintXReportResponse,
  IToggleShiftResponse,
} from './types'

export const KkmAPI = Object.freeze({
  getRowOfflineTills: async () =>
    await APIRequests.post<IGetListRowOfflineTillResponse>('/Execute', {
      Active: null,
      Command: 'List',
      FN_DateEnd: null,
      FN_IsFiscal: null,
      FN_MemOverflowl: null,
      InnKkm: null,
      NumDevice: 0,
      OFD_DateErrorDoc: null,
      OFD_Error: null,
      OnOff: null,
    }),

  openShift: async (numDevice: number, idDevice: string, cashierName: string, cashierVATIN: string) =>
    await APIRequests.post<IToggleShiftResponse>('/Execute', {
      Command: 'OpenShift',
      NumDevice: numDevice,
      CashierName: cashierName,
      CashierVATIN: cashierVATIN,
      NotPrint: false,
      IdCommand: generateGUID(),
      InnKkm: '',
      TaxVariant: '',
      IdDevice: idDevice,
    }),

  closeShift: async (numDevice: number, idDevice: string, cashierName: string, cashierVATIN: string) =>
    await APIRequests.post<IToggleShiftResponse>('/Execute', {
      Command: 'CloseShift',
      NotPrint: false,
      NumDevice: numDevice,
      CashierName: cashierName,
      CashierVATIN: cashierVATIN,
      IdCommand: generateGUID(),
      InnKkm: '',
      TaxVariant: '',
      IdDevice: idDevice,
    }),

  printCheck: async (kkmCommand: IPrintCheckCommand) =>
    await APIRequests.post<IPrintCheckResponse>('/Execute', kkmCommand),

  printXReport: async (numDevice: number) =>
    await APIRequests.post<IPrintXReportResponse>('/Execute', {
      Command: 'XReport',
      IdCommand: generateGUID(),
      NumDevice: numDevice,
    }),

  printTerminalReport: async (numDevice: number) =>
    await APIRequests.post<IPrintTerminalReportResponse>('/Execute', {
      Command: 'TerminalReport',
      NumDevice: numDevice,
      Detailed: true,
      Timeout: 120,
      IdCommand: generateGUID(),
    }),

  getCommandResult: async (IdCommand: string) =>
    await APIRequests.post<IGetCommandResultResponse>('/Execute', {
      Command: 'GetRezult',
      IdCommand: IdCommand,
    }),
})

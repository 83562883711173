import { Fragment, useEffect, useState } from 'react'
import { clsx } from 'clsx'

import { isDef } from '../../../types/lang.types'
import { AutocompleteInput } from './autocomplete-input/autocomplete-input.component'
import { AutocompleteDropdown } from './autocomplete-dropdown/autocomplete-dropdown.component'
import { AutocompleteOption } from './autocomplete-option/autocomplete-option.component'
import { AutocompleteOptionsList } from './autocomplete-options-list/autocomplete-options-list.component'
import { genAutocompleteItemToString } from './autocomplete.utils'
import { useAutocomplete } from './autocomplete.hook'
import { AutocompleteOptionDataItem } from './autocomplete-option/autocomplete-option.types'
import { AutocompleteProps } from './autocomplete.types'

import './autocomplete.styles.less'

export const Autocomplete = <Option extends AutocompleteOptionDataItem>(props: AutocompleteProps<Option>) => {
  const { className, suffix } = props
  const { value, defaultValue, options } = props
  const { delayTime, disabled = false, loading = false, placeholder = 'Введите значение для поиска', size } = props
  const { formatValue = true } = props
  const { itemToStringFn = genAutocompleteItemToString } = props
  const { onChange, onBlur, onFocus, onInputChange, onSelect, onClear } = props
  const {
    InputComponent = AutocompleteInput,
    DropdownComponent = AutocompleteDropdown,
    OptionsListComponent = AutocompleteOptionsList,
    OptionComponent = AutocompleteOption,
    renderSlotComponent = () => Fragment,
  } = props

  const { clearInputAfterSelect } = props

  const {
    isOpen,
    inputValue,
    selectedItem,
    highlightedIndex,
    getMenuProps,
    getItemProps,
    getInputProps,
    closeMenu,
    setInputValue,
    handleInputClick,
  } = useAutocomplete<Option>({
    value,
    defaultValue,
    options,
    delayTime,
    itemToStringFn,
    onInputChange,
    onChange,
    onBlur,
    onFocus,
    onSelect,
    clearInputAfterSelect,
  })

  // Set value for input if it was changed by outside
  useEffect(() => {
    if (value !== inputValue && isDef(value)) {
      setInputValue(value)
    }
  }, [value, setInputValue])

  // TODO: Remove it
  // ГОВНОКОД ON
  const [defaultV, setDefaultV] = useState('')
  useEffect(() => {
    if (defaultValue) {
      setDefaultV(defaultValue)
    }
  }, [defaultValue])
  // ГОВНОКОД OFF

  return (
    <div className={clsx('autocomplete', className)}>
      <InputComponent
        {...getInputProps({ value: value, defaultValue: defaultV, disabled, placeholder, onClick: handleInputClick })}
        size={size}
        suffix={suffix}
        formatValue={formatValue}
        onClear={onClear}
      />
      <DropdownComponent isOpen={isOpen} {...getMenuProps()}>
        {renderSlotComponent(closeMenu)}
        <OptionsListComponent
          options={options}
          loading={loading}
          selectedItem={selectedItem}
          highlightedIndex={highlightedIndex}
          getItemProps={getItemProps}
          OptionComponent={OptionComponent}
        />
      </DropdownComponent>
    </div>
  )
}

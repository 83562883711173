import { FC } from 'react'

import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { TransactionsPageFilter } from './transactions-page-filter/transactions-page-filter.component'
import { TransactionsPageTable } from './transactions-page-table/transactions-page-table.component'
import { useTransactionsPage } from './transactions-page.hook'
import { TransactionsShift } from './transactions-shift/transactions-shift.component'

export const TransactionsPage: FC = () => {
  const { isLoaded, isLoading, isShowShiftTransactions } = useTransactionsPage()

  return (
    <>
      {isShowShiftTransactions && <TransactionsShift />}

      {!isLoaded && isLoading ? (
        <PageLoader />
      ) : (
        <>
          <TransactionsPageFilter />
          <TransactionsPageTable />
        </>
      )}
    </>
  )
}

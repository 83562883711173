import { EditTwoTone, ExclamationCircleOutlined } from '@ant-design/icons'
import type { DatePickerProps } from 'antd'
import { DatePicker, Modal, Tooltip } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import * as React from 'react'

import { formatDateToHumanize } from '../../../../format/date.format'
import { ClientSubscriptionStatus } from '../../../../types/clients-subscriptions.types'
import { isDef, NString } from '../../../../types/lang.types'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import './clients-subscriptions-table-expiration.styles.less'

interface Props {
  subscriptionId: string
  autoActivationDate: NString
  expirationDate: NString
  status: ClientSubscriptionStatus
  onExpirationDate: (expirationDate: string, subscriptionId: string) => void
}

const { confirm } = Modal

export const ClientsSubscriptionsTableExpiration: React.FC<Props> = props => {
  const { autoActivationDate, expirationDate } = props
  const { subscriptionId, onExpirationDate } = props
  const { status } = props

  function handlePickerChange(date: Dayjs | null, dateString: string) {
    confirm({
      title: `Подтвердите изменение срока действия абонемента`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены что хотите изменить срок действия абонемента на ${formatDateToHumanize(dateString)}`,
      onOk: () => onExpirationDate(dateString, subscriptionId),
    })
  }

  if (status === ClientSubscriptionStatus.NEW && isDef(autoActivationDate)) {
    const text = `Ожидает активации`
    return <TableCellText text={text} />
  }

  if (status === ClientSubscriptionStatus.ACTIVE && isDef(expirationDate)) {
    const text = `до ${formatDateToHumanize(expirationDate)}`
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TableCellText text={text} />
        <Tooltip title="Изменить срок действия абонемента">
          <div className="clients-subscriptions-table-expiration__button-edit">
            <EditTwoTone />
            <DatePicker
              onChange={handlePickerChange as DatePickerProps['onChange']}
              className="clients-subscriptions-table-expiration__date-picker"
              placement="bottomRight"
              value={dayjs(expirationDate)}
            />
          </div>
        </Tooltip>
      </div>
    )
  }

  if (status === ClientSubscriptionStatus.ACTIVE && !isDef(expirationDate)) {
    const text = `-`
    return <TableCellText text={text} />
  }

  if (isDef(expirationDate)) {
    return <TableCellText text={`до ${formatDateToHumanize(expirationDate)}`} />
  }

  return <TableCellText text="-" />
}

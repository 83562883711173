import { ConditionTypes, ImpactDirection, ImpactType } from '../../../../../../api/types/api.types'

export function genImpactDirectionOptions() {
  return [
    { label: 'Скидка', value: ImpactDirection.DISCOUNT },
    { label: 'Наценка', value: ImpactDirection.MARKUP },
  ]
}

export function genImpactTypeOptions() {
  return [
    { label: '%', value: ImpactType.PERCENT },
    { label: '₽', value: ImpactType.FIXED },
  ]
}

export function genImpactTitle(type: string) {
  switch (type) {
    case ConditionTypes.TIME:
      return 'Влияние по дням неделям и времени'
    case ConditionTypes.GRADE:
      return 'Влияние по должности'
    case ConditionTypes.DURATION:
      return 'Влияние по длительности'
    case ConditionTypes.WITH_TRAINER:
      return 'Влияние по исполнителю'
  }
}

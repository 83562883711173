import { forwardRef, Ref } from 'react'
import { Typography } from 'antd'

import './products-autocomplete-option.styles.less'

import { ProductsAutocompleteOptionProps } from './products-autocomplete-option.types'

export const ProductsAutocompleteOptionInternal = (props: ProductsAutocompleteOptionProps, ref: Ref<HTMLLIElement>) => {
  const { label, details, ...restProps } = props

  return (
    <li
      className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child products-autocomplete-option"
      ref={ref}
      {...restProps}
    >
      <Typography className="products-autocomplete-option__fullName">{label}</Typography>
    </li>
  )
}

export const ProductsAutocompleteOption = forwardRef(
  ProductsAutocompleteOptionInternal
) as typeof ProductsAutocompleteOptionInternal

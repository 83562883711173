import { ExercisesGroupHistory } from '../../../components/exercises-group/exercises-group-history/exercises-group-history.component'
import { useScheduleGroupPageHistory } from './schedule-group-page-history.hook'

export const ScheduleGroupPageHistory = () => {
  const { scheduleHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useScheduleGroupPageHistory()

  return (
    <ExercisesGroupHistory
      exerciseHistory={scheduleHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { useDispatch } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'

export function useCustomFieldsPageButton() {
  const dispatch = useDispatch()

  const onClick = (): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CUSTOM_FIELDS_PAGE_MODAL_CREATE,
      })
    )
  }
  return {
    onClick,
  }
}

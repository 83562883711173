import { Typography } from 'antd'

import { useSalariesPage } from './salaries-page.hook'
import { SalariesPageForm } from './salaries-page-form/salaries-page-form.component'

export const SalariesPage = () => {
  useSalariesPage()

  return (
    <>
      <Typography.Title level={2}>Зарплаты сотрудников</Typography.Title>
      <SalariesPageForm />
    </>
  )
}

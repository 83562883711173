import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import clsx from 'clsx'

import { ClientsBookingsTableBaseDataItem } from '../clients-bookings-table-base/clients-bookings-table-base.types'
import { genClientsBookingsTableActiveColumns } from './clients-bookings-table-active.utils'
import { ClientsBookingsTableActiveProps } from './clients-bookings-table-active.types'
import { isDef } from '../../../../types/lang.types'
import { ClientsBookingsTableMultibooking } from '../clients-bookings-table-multibooking/clients-bookings-table-multibooking.component'
import './clients-bookings-table-active.styles.less'

export const ClientsBookingsTableActive: React.FC<ClientsBookingsTableActiveProps> = props => {
  const { clientId, data, pagination, loading } = props
  const { onChangePage, onChangePageSize } = props
  const { onVisit, onBarcode, onCancel, onBooking, onHistory, onChangePaymentType } = props

  const columns: ColumnsType<ClientsBookingsTableBaseDataItem> = React.useMemo(
    () =>
      genClientsBookingsTableActiveColumns({
        onVisit,
        onBarcode,
        onCancel,
        onBooking,
        onHistory,
        onChangePaymentType,
      }),
    [onVisit, onBarcode, onCancel, onBooking, onHistory, onChangePaymentType]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  const getRowClassName = (record: ClientsBookingsTableBaseDataItem) => {
    const expandRow = expandedKeys.find(key => key === record.id)

    if (record.multibooking) {
      return clsx('clients-bookings-table-active__row--multibooking', {
        'clients-bookings-table-active__row--expand-multibooking': expandRow,
      })
    }
    return 'clients-bookings-table-active__row'
  }

  const [expandedKeys, setExpandedKeys] = React.useState<string[]>([])

  const onMultibookingHandler = (key: string) => {
    setExpandedKeys(prevKeys => {
      if (prevKeys.includes(key)) {
        return prevKeys.filter(k => k !== key)
      }
      return [...prevKeys, key]
    })
  }

  const expandedRowRender = (record: ClientsBookingsTableBaseDataItem) => {
    const expandRow = expandedKeys.find(key => key === record.id)

    if (record.multibooking) {
      return (
        <ClientsBookingsTableMultibooking
          id={record.id}
          timetableId={record.multibooking.id}
          clientId={clientId}
          expandRow={!!expandRow}
          onMultibooking={onMultibookingHandler}
          onVisit={onVisit}
          onBarcode={onBarcode}
          onCancel={onCancel}
          onBooking={onBooking}
          onHistory={onHistory}
          onChangePaymentType={onChangePaymentType}
        />
      )
    }
  }

  return (
    <>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={(data ?? []).sort((a, b) => {
          if (a.paymentType === 'RESERVED' && b.paymentType !== 'RESERVED') return 1
          else if (a.paymentType !== 'RESERVED' && b.paymentType === 'RESERVED') return -1
          return 0
        })}
        pagination={paginationConfig}
        loading={loading}
        rowClassName={getRowClassName}
        expandable={{
          defaultExpandAllRows: true,
          expandedRowRender: expandedRowRender,
          rowExpandable: record => isDef(record.multibooking),
          showExpandColumn: false,
          expandedRowClassName: record => 'clients-bookings-table-active__expanded-row',
        }}
        className="clients-bookings-table-active"
      />
    </>
  )
}

import { Button, Form, Row, Typography } from 'antd'
import { api } from '@api/api'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ConsignmentForm } from './consignment-form'

export const ConsignmentCreatePage = () => {
  const history = useHistory()

  const [providers, setProviders] = useState([] as any)
  const getAllProviders = async () => {
    setProviders((await api.goodsProvidersService.fetchAll({ size: 1000 })).data)
  }

  const [organizations, setOrganizations] = useState([] as any)
  const getAllOrganizations = async () => {
    setOrganizations((await api.organizationsService.fetchAll({ size: 1000 })).data)
  }

  const [categories, setCategories] = useState([] as any)
  const getAllCategories = async () => {
    setCategories((await api.goodsCategoriesService.fetchAll({ size: 1000 })).data)
  }

  const [goods, setGoods] = useState([] as any)

  const [allGoods, setAllGoods] = useState([] as any)
  const getAllGoods = async () => {
    setAllGoods((await api.productsGoodsService.fetchAll({ size: 1000 })).data)
  }

  const [studios, setStudios] = useState([] as any)
  const onChangeOrganization = async () => {
    form.setFieldValue('studioId', null)
    setStudios((await api.studiosService.fetchAll({ orgId: form.getFieldValue('organizationId'), size: 1000 })).data)
  }

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({})
    getAllProviders()
    getAllOrganizations()
    getAllCategories()
    getAllGoods()
  }, [])

  const onSave = async () => {
    let hasErrors = false
    await form.validateFields().catch(err => {
      hasErrors = true
    })
    const fieldsFormValues = form.getFieldsValue()
    !hasErrors &&
      (await api.goodsConsignmentNotesService
        .create({
          ...fieldsFormValues,
          goodsConsignments: goods,
          consignmentNoteDate: fieldsFormValues.consignmentNoteDate.utcOffset(0, true).format('YYYY-MM-DD'),
        })
        .then(() => history.push('/goods')))
  }

  return (
    <ConsignmentForm
      form={form}
      providers={providers}
      organizations={organizations}
      categories={categories}
      goods={goods}
      setGoods={setGoods}
      allGoods={allGoods}
      getAllCategories={getAllCategories}
      getAllProviders={getAllProviders}
      studios={studios}
      onChangeOrganization={onChangeOrganization}
      onSave={onSave}
      title="Добавление накладной"
    />
  )
}

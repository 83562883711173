import { Collapse, Flex, FormInstance, Row, Typography } from 'antd'
import { Fragment, useState } from 'react'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { CustomCollapsePanel } from '../../../custom-collapse-panel/custom-collapse-panel.component'
import { StudiosFormTypes } from '../studios-form-types'
import { StudiosFormOflineTills } from './studios-form-ofline-tills/studios-form-ofline-tills.component'

interface Props {
  form: FormInstance<StudiosFormTypes>
}

export const StudiosFormTills: React.FC<Props> = props => {
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  return (
    <CustomCard>
      <Flex gap="large" vertical>
        <Collapse
          onChange={key => {
            if (Array.isArray(key)) {
              onCollapseChange(key)
            }
          }}
          activeKey={activeKeys}
          ghost
          bordered={false}
        >
          <CustomCollapsePanel
            index={'0'}
            panelHeader={
              <Fragment>
                <Row onClick={event => event.stopPropagation()}>
                  <Typography.Title level={5} className="studios-form__title">
                    Настройки офлайн-кассы
                  </Typography.Title>
                </Row>
              </Fragment>
            }
            isActive={activeKeys.includes('0')}
            onEditHandler={() => {
              onCollapseChange([])
              setTimeout(() => onCollapseChange(['0']), 100)
            }}
            onRemoveHandler={() => console.log('remove')}
          >
            <StudiosFormOflineTills {...props} activeKeys={activeKeys} onCollapseChange={onCollapseChange} />
          </CustomCollapsePanel>
        </Collapse>
      </Flex>
    </CustomCard>
  )
}

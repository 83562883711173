import { ElementStates } from '../master-services-widget-form-design-settings.types'

export function genDescriptionFontStyles(state: ElementStates) {
  return {
    desktop: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#787779',
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#787779',
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

export function genPriceFontStyles(state: ElementStates) {
  return {
    desktop: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#F8873D',
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 25,
      letterSpacing: 0,
      wordSpacing: 0,
    },
    mobile: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#F8873D',
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: -0.31,
      wordSpacing: 0,
    },
  }
}

export function genNameFontStyles(state: ElementStates) {
  return {
    desktop: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#333333',
      fontSize: 18,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 25,
      letterSpacing: 0,
      wordSpacing: 0,
    },
    mobile: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#333333',
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: -0.31,
      wordSpacing: 0,
    },
  }
}

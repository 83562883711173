import { AppState } from '../../../app.store'

export const getTransactionsPageModalRefundSum = (state: AppState) =>
  state.transactionsPage.transactionsPageModalRefund.refundSum?.refundSum

export const getTransactionsPageModalKKMCommandData = (state: AppState) =>
  state.transactionsPage.transactionsPageModalRefund.refundSum?.kkmCommandData

export const getTransactionsPageModalRefundIsLoading = (state: AppState) =>
  state.transactionsPage.transactionsPageModalRefund.isLoading

export const getTransactionsPageModalRefundIsLoaded = (state: AppState) =>
  state.transactionsPage.transactionsPageModalRefund.isLoaded

export const getTransactionsPageModalRefundIsRefunded = (state: AppState) =>
  state.transactionsPage.transactionsPageModalRefund.isRefunded

import { ProductType } from '@api/types/api.types'
import { Tag } from 'antd'

interface Props {
  productType: ProductType
}

export const TableCellTagProductType = (props: Props) => {
  const { productType } = props

  const getTagInfo = (type: ProductType) => {
    let tagText = ''
    let tagColor = 'default'
    switch (type) {
      case ProductType.SERVICE:
        tagText = 'Разовая услуга'
        tagColor = 'orange'
        break
      case ProductType.SUBSCRIPTION:
        tagText = 'Абонемент'
        tagColor = 'purple'
        break
      case ProductType.GOODS:
        tagText = 'Товар'
        tagColor = 'geekblue'
        break
      case ProductType.FULL_PAYMENT_SERVICE:
        tagText = 'Разовая услуга'
        tagColor = 'orange'
        break
      case ProductType.ADVANCE_SUB_SERVICE:
        tagText = 'Персональная услуга'
        tagColor = 'cyan'
        break
      case ProductType.INSTANT_SUB_SERVICE:
        tagText = 'Персональная услуга'
        tagColor = 'cyan'
        break
      default:
        break
    }

    return { tagText, tagColor }
  }

  const { tagText, tagColor } = getTagInfo(productType)
  return (
    <Tag style={{ width: 'fit-content', height: 'fit-content' }} color={tagColor}>
      {tagText}
    </Tag>
  )
}

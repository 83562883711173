import { ClientsTransactionHistoryPeek } from '../../../components/clients/clients-transaction-history-peek/clients-transaction-history-peek.component'
import { useClientsEditPagePeekTransactionHistory } from './clients-edit-page-peek-transaction-history.hook'
import { ClientsEditPagePeekTransactionHistoryProps } from './clients-edit-page-peek-transaction-history.types'

export const ClientsEditPagePeekTransactionHistory: React.FC<ClientsEditPagePeekTransactionHistoryProps> = props => {
  const { transactionHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useClientsEditPagePeekTransactionHistory(props)

  return (
    <ClientsTransactionHistoryPeek
      transactionHistory={transactionHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ClientsTransactionHistoryPeekProps } from './clients-transaction-history-peek.types'

export const ClientsTransactionHistoryPeek: FC<ClientsTransactionHistoryPeekProps> = props => {
  const { isLoading, transactionHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(transactionHistory) ? (
        <HistoryList
          title="История действий с транзакцией"
          type={HistoryListType.TRANSACTION}
          history={transactionHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

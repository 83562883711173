import { Space, Typography, Row, Col, Form, InputNumber } from 'antd'

import { isDef } from '../../../../../types/lang.types'

interface Props {
  element: string
  elementGroup?: string
  state?: string
  property: string
}

export const MasterServicesWidgetFormUiBorderRadius: React.FC<Props> = props => {
  const { element, elementGroup, state, property } = props

  return (
    <Space direction="vertical">
      <Typography.Text>Закругление, px</Typography.Text>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              property,
              'topLeft',
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Верх-левый</Typography.Text>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              property,
              'topRight',
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Верх-правый</Typography.Text>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              property,
              'bottomRight',
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Низ-правый</Typography.Text>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              property,
              'bottomLeft',
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Низ-левый</Typography.Text>
        </Col>
      </Row>
    </Space>
  )
}

import React from 'react'

import { NNumber } from '../../../../types/lang.types'
import { MasterServicesPricingFormValues } from '../master-services-form.types'

interface Props {
  isEdit: boolean | undefined
  onSelectPricingHandler?: (id: string) => void
  onClosePricingModalHandler?: () => void
  onSavePricingHandler?: (value: MasterServicesPricingFormValues, subServiceKey?: NNumber) => void
}

export const useMasterServicesFormSubservices = (props: Props) => {
  const { isEdit, onSelectPricingHandler, onClosePricingModalHandler, onSavePricingHandler } = props

  const [activeKeys, setActiveKeys] = React.useState<string[]>([])
  const [editableSubServiceKey, seteditableSubServiceKey] = React.useState<NNumber>(null)
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const onOpenModal = (id?: string, key?: number) => {
    if (typeof key === 'number') {
      seteditableSubServiceKey(key)
    }
    if (id && onSelectPricingHandler) {
      onSelectPricingHandler(id)
    }
    setIsModalOpen(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
    seteditableSubServiceKey(null)
    if (onClosePricingModalHandler) {
      onClosePricingModalHandler()
    }
  }

  const onModalConfirm = (values: MasterServicesPricingFormValues) => {
    if (onSavePricingHandler) {
      onSavePricingHandler(values, editableSubServiceKey)
    }
    onModalCancel()
  }

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  React.useEffect(() => {
    if (!isEdit) {
      setActiveKeys(['0'])
    }
  }, [isEdit])

  return {
    activeKeys,
    isModalOpen,
    setActiveKeys,
    onCollapseChange,
    onOpenModal,
    onModalCancel,
    onModalConfirm,
  }
}

import React from 'react'
import { Form, Button, Collapse, Flex, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useMasterServicesFormSubservices } from './master-services-form-subservices.hook'
import { MasterServicesFormSubservicesProps } from './master-services-form-subservices.types'
import { MasterServicesFormSubservice } from './master-services-form-subservice/master-services-form-subservice.component'
import { CustomCard } from '../../../custom-card/custom-card.component'
import { MasterServicesFormPricingModal } from '../master-services-form-pricing-modal/master-services-form-pricing-modal.component'
import './master-services-form-subservices.styles.less'

export const MasterServicesFormSubservices: React.FC<MasterServicesFormSubservicesProps> = props => {
  const {
    isEdit,
    selectedPricing,
    positions,
    positionsOptions,
    onSavePricingHandler,
    onSelectPricingHandler,
    onClosePricingModalHandler,
    onAddServiceCategory,
    ...rest
  } = props

  const { activeKeys, isModalOpen, onCollapseChange, onOpenModal, onModalCancel, onModalConfirm } =
    useMasterServicesFormSubservices({
      isEdit,
      onSavePricingHandler,
      onSelectPricingHandler,
      onClosePricingModalHandler,
    })

  return (
    <>
      <CustomCard>
        <Flex gap="large" vertical>
          <Typography.Title level={4} className="master-services-form-subservices__title">
            Все услуги
          </Typography.Title>
          <Form.List name="subServices" initialValue={[{}]}>
            {(fields, { add, remove, move }) => {
              return (
                <>
                  <Collapse
                    onChange={key => {
                      if (Array.isArray(key)) {
                        onCollapseChange(key)
                      }
                    }}
                    activeKey={activeKeys}
                    ghost
                    bordered={false}
                  >
                    {fields.map((field, i) => {
                      return (
                        <MasterServicesFormSubservice
                          {...rest}
                          isEdit={isEdit}
                          fields={fields}
                          field={field}
                          index={i}
                          key={`${i}`}
                          activeKeys={activeKeys}
                          onCollapseChange={onCollapseChange}
                          remove={remove}
                          onOpenPricingModal={onOpenModal}
                          onAddServiceCategory={onAddServiceCategory}
                        />
                      )
                    })}
                  </Collapse>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      onCollapseChange([])

                      if (!isEdit) {
                        onCollapseChange(['0'])
                        add(0)
                        move(fields.length, 0)
                      } else {
                        onCollapseChange([fields.length.toString()])
                        add(0)
                      }
                    }}
                    className="master-services-form-subservices__button"
                  >
                    Добавить услугу
                  </Button>
                </>
              )
            }}
          </Form.List>
        </Flex>
      </CustomCard>
      <MasterServicesFormPricingModal
        open={isModalOpen}
        pricing={selectedPricing}
        positions={positions}
        positionsOptions={positionsOptions}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
      />
    </>
  )
}

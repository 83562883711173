import { Form, Radio } from 'antd'

import { PositionsForm } from '../positions-form.component'
import { PositionsFormGrades } from './positions-form-grades/positions-form-grades.component'
import { PositionsEditFormProps } from './positions-edit-form.types'
import { genPositionTypesOptions } from '../../../../utils/positions.utils'

export const PositionsEditForm: React.FC<PositionsEditFormProps> = props => {
  const { form, isLoading, positionGrades } = props
  const { onUpdateGrade, onCreateGrade, onRemoveGrade, onFinish } = props

  return (
    <PositionsForm form={form} isLoading={isLoading} submitText="Редактировать должность" onFinish={onFinish}>
      <PositionsFormGrades
        name="grades"
        form={form}
        positionGrades={positionGrades}
        onUpdateGrade={onUpdateGrade}
        onCreateGrade={onCreateGrade}
        onRemoveGrade={onRemoveGrade}
      />
      <Form.Item name="positionType">
        <Radio.Group options={genPositionTypesOptions()} disabled></Radio.Group>
      </Form.Item>
    </PositionsForm>
  )
}

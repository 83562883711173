// @ts-nocheck

import { notification } from 'antd'

import { Api } from '../../../api/api'
import { runKkmCommands } from './utils/run-kkm-commands.utils'
import { getKkmCommands } from './utils/get-kkm-commands.utils'
import { sendKkmCommands } from './utils/send-kkm-commands.utils'
import { ISyncUtilResult } from './sync-kkm-with-backend.types'
import { SYNC_UTIL_ERROR_RESPONSE } from './constants/response.constants'

// ! This utility is used to synchronize cash register and backend.
// ! Most likely it will be rewritten or even deleted.

export const syncKkmWithBackend = async (
  getKKMCommandsCallback: (api: Api) => Promise<any>,
  sendKKMResponsesCallback: (api: Api, kkmResponses: any) => Promise<any>,
  successNotificationMessage: string,
  errorMessageForSendKKMCommands?: string
): ISyncUtilResult<any> => {
  // Get commands
  let kkmCommands = await getKkmCommands(getKKMCommandsCallback)
  if (kkmCommands.hasError) return SYNC_UTIL_ERROR_RESPONSE

  // Run commands
  let kkmResponses = null
  if (kkmCommands?.response?.kkmCommandData) {
    kkmResponses = await runKkmCommands(kkmCommands?.response?.kkmCommandData)
    if (kkmResponses.hasError) return SYNC_UTIL_ERROR_RESPONSE
  }

  // Send commands results
  const result = await sendKkmCommands(sendKKMResponsesCallback, kkmResponses, errorMessageForSendKKMCommands)
  if (result.hasError) return SYNC_UTIL_ERROR_RESPONSE

  notification.success({
    message: successNotificationMessage,
  })

  return result
}

import { createSelector } from '@reduxjs/toolkit'

import { mapFranchisesToOptions } from '../../../mapping/franchises.mapping'
import {
  mapEmployeesGradesToOptions,
  mapEmployeesPositionsToOptions,
} from '../../../mapping/employees-positions.mapping'
import { genEmployeeFormValues } from '../../../mapping/employees.mapping'
import { AppState } from '../../app.store'
import { isDef } from '../../../types/lang.types'
import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'
import { mapCustomFieldsFilterEnabled } from '../../../mapping/custom-fields.mapping'

export const genEmployee = (state: AppState) => state.employeesEditPage.employee

const getFranchisesInternal = (state: AppState) => state.employeesEditPage.franchises
const getEmployeesPositionsInternal = (state: AppState) => state.employeesEditPage.positions
const getDirectionsInternal = (state: AppState) => state.employeesEditPage.directions
const getExercisesTypesInternal = (state: AppState) => state.employeesEditPage.exercisesTypes
const getCustomFieldsInternal = (state: AppState) => state.employeesEditPage.customFields

const getEmployeesPosition = (state: AppState) => state.employeesEditPage.position

export const getEmployeesEditPageIsLoading = (state: AppState) => state.employeesEditPage.isLoading
export const getEmployeesEditPageIsLoaded = (state: AppState) => state.employeesEditPage.isLoaded

export const getEmployeesEditPageFormValues = createSelector(genEmployee, genEmployeeFormValues)

export const getEmployeesEditPageFranchisesOptions = createSelector(getFranchisesInternal, franchises =>
  mapFranchisesToOptions(franchises?.content)
)

export const getEmployeesEditPagePositionsOptions = createSelector(getEmployeesPositionsInternal, employeesPositions =>
  mapEmployeesPositionsToOptions(employeesPositions?.content)
)

export const getEmployeesEditPageDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

export const getEmployeesEditPageExercisesTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToOptions(exercisesTypes?.content)
)

export const getEmployeesEditPageCustomFields = createSelector(getCustomFieldsInternal, customFields =>
  mapCustomFieldsFilterEnabled(customFields)
)

export const getEmployeesEditPageGradesOptions = createSelector(
  getEmployeesPositionsInternal,
  getEmployeesPosition,
  (employeesPositions, employeesPosition) => {
    if (isDef(employeesPositions) && isDef(employeesPositions.content)) {
      const position = employeesPositions.content.find(position => position.id === employeesPosition)
      return mapEmployeesGradesToOptions(position?.grades)
    } else {
      return undefined
    }
  }
)

export const getEmployeesPositionType = createSelector(
  getEmployeesPositionsInternal,
  getEmployeesPosition,
  (employeesPositions, employeesPosition) => {
    if (isDef(employeesPositions) && isDef(employeesPositions.content)) {
      return employeesPositions?.content?.find(position => position.id === employeesPosition)?.positionType
    }
  }
)

import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { exercisesTypesEditPageActions } from './exercises-types-edit-page.slice'
import { genExercisesTypesPagePath } from '../../../format/path.format'
import { genExercisesTypeDTO } from '../../../mapping/exercises-types.mapping'

export function* fetchPageData(action: ReturnType<typeof exercisesTypesEditPageActions.fetchPageData>) {
  try {
    const [exercisesType, directions]: [
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchById>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesTypesService.fetchById, action.payload),
      callApi(api.exercisesDirectionsService.fetchAll, {
        size: 200,
      }),
    ])

    yield put(
      exercisesTypesEditPageActions.fetchPageDataSuccess({
        exercisesType: exercisesType.data,
        directions: directions.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* updateExercisesType(action: ReturnType<typeof exercisesTypesEditPageActions.updateExercisesType>) {
  try {
    const { id, data } = action.payload
    const exercisesTypeDTO = genExercisesTypeDTO(data)

    yield callApi(api.exercisesTypesService.update, id, exercisesTypeDTO)

    yield put(exercisesTypesEditPageActions.updateExercisesTypeSuccess())
    yield put(push(genExercisesTypesPagePath()))
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesEditPageActions.updateExercisesTypeError(new Error()))
  }
}

export function* exercisesTypesEditPageSagas() {
  yield takeLatest(exercisesTypesEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(exercisesTypesEditPageActions.updateExercisesType, updateExercisesType)
}

import * as React from 'react'

import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'

interface Props {
  id: string
}

export const ClientsSubscriptionsTableId: React.FC<Props> = props => {
  const { id } = props

  return <TableCellText text={id} />
}

import { useEffect, useState } from 'react'

import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

interface ShowFields {
  isShowSelectInput: boolean
  isShowTextInput: boolean
  isShowPlaceholderInput: boolean
  isShowTextArea: boolean
}

export const useCustomFieldsShowFields = (selectType: CustomFieldsApi.CustomFieldsType): ShowFields => {
  const [isShowSelectInput, setIsShowSelectInput] = useState(false)
  const [isShowTextInput, setIsShowTextInput] = useState(false)
  const [isShowPlaceholderInput, setIsShowPlaceholderInput] = useState(false)
  const [isShowTextArea, seIstShowTextArea] = useState(false)

  useEffect(() => {
    switch (selectType) {
      case 'INPUT':
        setIsShowSelectInput(false)
        setIsShowTextInput(true)
        setIsShowPlaceholderInput(true)
        seIstShowTextArea(false)
        break
      case 'MULTILINE_INPUT':
        setIsShowSelectInput(false)
        setIsShowTextInput(false)
        setIsShowPlaceholderInput(true)
        seIstShowTextArea(true)
        break
      case 'SELECT':
        setIsShowSelectInput(true)
        setIsShowTextInput(false)
        setIsShowPlaceholderInput(true)
        seIstShowTextArea(false)
        break
      case 'CHECKBOX_GROUP':
        setIsShowSelectInput(true)
        setIsShowTextInput(false)
        setIsShowPlaceholderInput(false)
        seIstShowTextArea(false)
        break
      default:
        break
    }
  }, [selectType])

  return { isShowSelectInput, isShowTextInput, isShowPlaceholderInput, isShowTextArea }
}

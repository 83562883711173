import { createSelector } from '@reduxjs/toolkit'

import { mapExercisesTypesToExercisesForm } from '../../../mapping/exercises-types.mapping'
import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { AppState } from '../../app.store'

const getStudiosInternal = (state: AppState) => state.productsCreatePageReducer.studios
const getExercisesInternal = (state: AppState) => state.productsCreatePageReducer.exercises

export const getStudiosOptions = createSelector(getStudiosInternal, studios => mapStudiosToOptions(studios?.content))
export const getExercisesOptions = createSelector(getExercisesInternal, exercise =>
  mapExercisesTypesToExercisesForm(exercise?.content)
)

import React from 'react'
import { FormInstance } from 'antd'

import { SalariesFormValues } from './salaries-form-types'
import { genSalariesFormIsValid } from './salaries-form-utils'

type UseSalariesFormProps = {
  form: FormInstance<SalariesFormValues>
  onFieldsChangeHandler: (isValid: boolean) => void
}

export function useSalariesForm(props: UseSalariesFormProps) {
  const { form, onFieldsChangeHandler } = props

  const onFieldsChange = React.useCallback((): void => {
    const isValid = genSalariesFormIsValid(form)
    onFieldsChangeHandler(isValid)
  }, [form, onFieldsChangeHandler])

  return onFieldsChange
}

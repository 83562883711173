import * as React from 'react'
import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { NString, Nullable } from '../../../types/lang.types'
import { AppModalQueueItem } from '../../../types/modal.types'
import { AppLayoutModalController } from './app-layout-modal-controller/app-layout-modal-controller.component'
import { AppLayoutSidebar } from './app-layout-sidebar/app-layout-sidebar.component'
import { AppLayoutSideBarStudio, AppLayoutSidebarMenuItem } from './app-layout-sidebar/app-layout-sidebar.types'
import { AppLayoutTopBar } from './app-layout-top-bar/app-layout-top-bar.component'
import { AppLayoutTopBarMenu, AppLayoutTopBarMenuItem } from './app-layout-top-bar/app-layout-top-bar.types'
import './app-layout.styles.less'
import { getCurrentEmployee, getTheme } from '../../../store/common/layout/layout.selectors'
import { AppLayoutPeekController } from './app-layout-peek-controller/app-layout-peek-controller.component'
import { AppPeekQueueItem } from '../../../types/peek.types'
import { genSchedulePageListSlotClosingMode } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'

interface Props extends React.PropsWithChildren {
  topBarStudios: Nullable<AppLayoutSideBarStudio[]>
  topBarMenuItems: AppLayoutTopBarMenu[]
  sideBarMenuItems: AppLayoutSidebarMenuItem[]
  userMenu: AppLayoutTopBarMenuItem[]

  selectedStudioId: NString

  activeModal: Nullable<AppModalQueueItem>
  activePeek: Nullable<AppPeekQueueItem>
}

export const AppLayout: React.FC<Props> = props => {
  const { children } = props
  const { topBarStudios, topBarMenuItems, sideBarMenuItems, userMenu } = props
  const { activeModal, activePeek, selectedStudioId } = props

  const theme = useSelector(getTheme)
  const currentEmployee = useSelector(getCurrentEmployee)
  const slotClosingMode = useSelector(genSchedulePageListSlotClosingMode)

  const [isSidebarCollapsed, setSidebarCollapsed] = React.useState(false)

  return (
    <Layout className="app-layout">
      <AppLayoutSidebar
        className="app-layout__sidebar"
        items={sideBarMenuItems}
        selectedStudioId={selectedStudioId}
        isCollapsed={isSidebarCollapsed}
        studios={topBarStudios}
        theme={theme}
      />

      <Layout className="app-layout__wrapper">
        <AppLayoutTopBar
          className="app-layout__header"
          userMenu={userMenu}
          menuItems={topBarMenuItems}
          theme={theme}
          currentEmployee={currentEmployee}
          isCollapsed={isSidebarCollapsed}
          sidebarCollapse={() => setSidebarCollapsed(!isSidebarCollapsed)}
        />

        <Layout.Content
          className={clsx(
            'app-layout__content',
            `app-layout__content--${theme}`,
            slotClosingMode && 'app-layout__content--overlay'
          )}
        >
          {children}
        </Layout.Content>
      </Layout>

      <AppLayoutModalController activeModal={activeModal} />
      <AppLayoutPeekController activePeek={activePeek} />
    </Layout>
  )
}

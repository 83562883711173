import { KkmAPI } from '../../../../../kkm-api/kkm'
import { IPrintTerminalReportResponse } from '../../../../../kkm-api/types'
import { IOpenShiftInternalResult } from '../../types/utils'
import { notificationsXReport } from './notification-terminal-report'

export const printTerminalReport = async () => {
  let result: IOpenShiftInternalResult<IPrintTerminalReportResponse> = {
    hasError: false,
    response: null,
  }

  // Get all devices
  let hasError = false
  const offlineTillsResponse = await KkmAPI.getRowOfflineTills().catch(err => {
    console.error(err)
    hasError = true
  })
  if (hasError || !offlineTillsResponse) {
    notificationsXReport.errorWithPrintTerminalReport()
    return
  }

  // Find device
  const device = offlineTillsResponse.ListUnit.find(device => device.IdTypeDevice === 'GateEmulator')
  if (!device?.NumDevice) {
    notificationsXReport.errorWithPrintTerminalReport()
    return
  }

  // Print report
  result.response = await KkmAPI.printTerminalReport(device?.NumDevice).catch(err => {
    hasError = true
    console.error(err)
  })

  if (hasError) {
    notificationsXReport.errorWithPrintTerminalReport()
    return
  }

  notificationsXReport.successWithPrintTerminalReport()
}

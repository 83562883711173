import { DefaultOptionType } from 'antd/es/select'
import { useEffect, useState } from 'react'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { fetchOfflineTills } from '@pages/transactions-page/transactions-shift/utils/tills/fetch-tills'

import { TillsApi } from '../../api/types/tills-api.types'
import { useNotConnectedOfflineTills } from './use-not-connected-offline-tills.hook'

export const useOfflineTillsOptions = (
  onlyConnectedTills: boolean = false,
  setOfflineTills: (tills: TillsApi.OfflineTillDTO[]) => void,
  isDisabled: boolean = false
): [DefaultOptionType[], boolean, boolean] => {
  // State
  const [offlineTillsOptions, setOfflineTillsOptions] = useState<DefaultOptionType[]>([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Custom hooks
  const [successCallback, notConnectedOfflineTills] = useNotConnectedOfflineTills()

  // Life cycle
  useEffect(() => {
    // Fetch tills
    const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)
    if (!studioId) return

    if (onlyConnectedTills) {
      fetchOfflineTills(setOfflineTills, setOfflineTillsOptions, setIsLoading, setHasError, studioId, successCallback)
      return
    } else {
      fetchOfflineTills(setOfflineTills, setOfflineTillsOptions, setIsLoading, setHasError, studioId)
    }
  }, [])

  useEffect(() => {
    const connectedOfflineTillsOptions = offlineTillsOptions.filter(
      till => !notConnectedOfflineTills.includes(String(till.value))
    )
    setOfflineTillsOptions(connectedOfflineTillsOptions)
  }, [notConnectedOfflineTills])

  return [offlineTillsOptions, isLoading, hasError]
}

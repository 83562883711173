import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NStudioEntity } from './studio.types'

const initialState: NStudioEntity.IState = {
  studios: [],
  studio: null,
  isLoading: false,
  error: null,
}

export const { actions: studioEntityActions, reducer: studioEntityReducer } = createSlice({
  name: '@@studio-entity',
  initialState,
  reducers: {
    // Studios
    fetchAllStudios: (state: Draft<NStudioEntity.IState>) => {
      state.isLoading = true
    },
    fetchAllStudiosSuccess: (
      state: Draft<NStudioEntity.IState>,
      action: PayloadAction<NStudioEntity.IFetchAllStudiosSuccessPayload>
    ) => {
      state.isLoading = false
      state.studios = action.payload.studios
    },
    fetchAllStudiosError: (state: Draft<NStudioEntity.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { employeesPagePeekHistoryActions } from './employees-page-peek-history.slice'

function* fetchAudit(action: ReturnType<typeof employeesPagePeekHistoryActions.fetchAudit>) {
  try {
    const { page, size, id } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      'employee',
      id,
      { ...params, sort: 'time,desc' }
    )
    yield put(employeesPagePeekHistoryActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(employeesPagePeekHistoryActions.fetchAuditError(new Error()))
  }
}

export function* employeesPagePeekHistorySagas() {
  yield takeLatest(employeesPagePeekHistoryActions.fetchAudit.type, fetchAudit)
}

import { FormInstance } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useWatch } from 'antd/lib/form/Form'
import { DefaultOptionType } from 'antd/lib/select'

import { MasterServicesFormValues } from '../master-services-form.types'
import {
  genMasterServicesFormExerciseTypeValidationRules,
  genMasterServicesFormNameValidationRules,
  genMasterServicesFormRestrictionMinutesDurationValidationRules,
  genMasterServicesFormRestrictionStartTimeValidationRules,
  genMasterServicesFormTimeStepMinutesValidationRules,
  genMasterServicesFormTimeValidationRules,
  genTimeSlotDurationOptions,
} from './master-services-form-general-fields.utils'
import { isDefAndNotEmpty, NNumber } from '../../../../types/lang.types'

interface Props {
  form: FormInstance<MasterServicesFormValues>
  timeStepMinutesOptions?: DefaultOptionType[]
  onAddTimeStepHandler: (value: NNumber) => void
}

export function useMasterServicesFormGeneralFields(props: Props) {
  const { form, timeStepMinutesOptions, onAddTimeStepHandler } = props

  const nameValidationRules = useMemo(genMasterServicesFormNameValidationRules, [])
  const exerciseTypeValidationRules = useMemo(genMasterServicesFormExerciseTypeValidationRules, [])
  const timeStepMinutesValidationRules = useMemo(genMasterServicesFormTimeStepMinutesValidationRules, [])
  const timeValidationRules = useMemo(genMasterServicesFormTimeValidationRules, [])
  const restrictionStartTimeValidationRules = useMemo(genMasterServicesFormRestrictionStartTimeValidationRules, [])
  const restrictionMinutesDurationValidationRules = useMemo(
    genMasterServicesFormRestrictionMinutesDurationValidationRules,
    []
  )

  const [isReviewEnabled, setIsReviewEnabled] = useState(false)
  const [hasMinimumTimeBeforeCancel, setHasMinimumTimeBeforeCancel] = useState(false)
  const [isTravelTimeEnabled, setIsTravelTimeEnabled] = useState(false)
  const [isIntervalBeforeBookingEnabled, setIsIntervalBeforeBookingEnabled] = useState(false)

  const [timeStep, setTimeStep] = useState<NNumber>()

  const isOnlinePaymentEnabled = useWatch('onlinePaymentEnabled', form)
  const hasMinBookingTime = useWatch('hasMinBookingTime', form)
  const hasBookingTimeRestriction = useWatch('hasBookingTimeRestriction', form)
  const restrictionStartTime = useWatch('restrictionStartTime', form)
  const restrictionMinutesDuration = useWatch('restrictionMinutesDuration', form)

  const toggleOnlinePaymentEnabled = () => {
    form.setFieldValue('onlinePaymentEnabled', !isOnlinePaymentEnabled)
  }

  const toggleReviewEnabled = () => {
    form.setFieldValue('reviewEnabled', !isReviewEnabled)
    setIsReviewEnabled(!isReviewEnabled)
  }

  const toggleHasMinimumTimeBeforeCancel = () => {
    form.setFieldValue('hasMinimumTimeBeforeCancel', !hasMinimumTimeBeforeCancel)
    setHasMinimumTimeBeforeCancel(!hasMinimumTimeBeforeCancel)
  }

  const toggleTravelTime = () => {
    form.setFieldValue('travelTimeEnabled', !isTravelTimeEnabled)
    setIsTravelTimeEnabled(!isTravelTimeEnabled)
  }

  const toggleIntervalBeforeBooking = () => {
    form.setFieldValue('intervalBeforeBookingEnabled', !isIntervalBeforeBookingEnabled)
    setIsIntervalBeforeBookingEnabled(!isIntervalBeforeBookingEnabled)
  }

  const toggleMinBookingTime = () => {
    form.setFieldValue('hasMinBookingTime', !hasMinBookingTime)
  }

  const toggleHasBookingTimeRestriction = () => {
    form.setFieldValue('hasBookingTimeRestriction', !hasBookingTimeRestriction)
  }

  const onTimeStepChange = (value: NNumber) => {
    setTimeStep(value)
  }

  const addTimeStep = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    onAddTimeStepHandler(timeStep)
    setTimeStep(undefined)
  }

  const getTimeStepMinutesOptions = () => {
    const defaultOptions = genTimeSlotDurationOptions()
    const values = new Set(defaultOptions.map(d => d.value))

    return [
      ...defaultOptions,
      ...(isDefAndNotEmpty(timeStepMinutesOptions) ? [...timeStepMinutesOptions] : []).filter(
        d => !values.has(d.value)
      ),
    ].sort((optionA, optionB) =>
      typeof optionA['value'] === 'number' && typeof optionB['value'] === 'number' && optionA.value < optionB.value
        ? -1
        : 1
    )
  }

  useEffect(() => {
    const values = form.getFieldsValue()

    setIsReviewEnabled(!!values.reviewEnabled)
    setHasMinimumTimeBeforeCancel(!!values.hasMinimumTimeBeforeCancel)
  }, [form])

  return {
    nameValidationRules,
    exerciseTypeValidationRules,
    timeStepMinutesValidationRules,
    timeValidationRules,
    restrictionStartTimeValidationRules,
    restrictionMinutesDurationValidationRules,
    isReviewEnabled,
    hasMinimumTimeBeforeCancel,
    restrictionStartTime,
    restrictionMinutesDuration,
    isTravelTimeEnabled,
    isIntervalBeforeBookingEnabled,
    hasBookingTimeRestriction,
    hasMinBookingTime,
    timeStep,
    toggleOnlinePaymentEnabled,
    toggleReviewEnabled,
    toggleHasMinimumTimeBeforeCancel,
    toggleTravelTime,
    toggleIntervalBeforeBooking,
    toggleHasBookingTimeRestriction,
    toggleMinBookingTime,
    onTimeStepChange,
    addTimeStep,
    getTimeStepMinutesOptions,
  }
}

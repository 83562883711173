import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NEmployeesSchedule } from './employees-schedule.types'

const initialState: NEmployeesSchedule.IState = {
  schedules: null,
  isLoading: false,
  error: null,
}

export const { actions: employeesScheduleActions, reducer: employeesScheduleReducer } = createSlice({
  name: '@@employees-schedule',
  initialState,
  reducers: {
    // Fetch
    fetchAll: (state: Draft<NEmployeesSchedule.IState>) => {
      state.isLoading = true
    },
    fetchAllSuccess: (
      state: Draft<NEmployeesSchedule.IState>,
      action: PayloadAction<NEmployeesSchedule.ISuccessPayload>
    ) => {
      const { schedules } = action.payload

      state.schedules = schedules
      state.isLoading = false
    },
    fetchAllError: (state: Draft<NEmployeesSchedule.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Copy
    copy: (state: Draft<NEmployeesSchedule.IState>, _action: PayloadAction<NEmployeesSchedule.ICopyPayload>) => {
      state.isLoading = true
    },
    copySuccess: (state: Draft<NEmployeesSchedule.IState>) => {
      state.isLoading = false
    },
    copyError: (state: Draft<NEmployeesSchedule.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Stop
    stop: (state: Draft<NEmployeesSchedule.IState>, _action: PayloadAction<NEmployeesSchedule.IStopPayload>) => {
      state.isLoading = true
    },
    stopSuccess: (state: Draft<NEmployeesSchedule.IState>) => {
      state.isLoading = false
    },
    stopError: (state: Draft<NEmployeesSchedule.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Active
    active: (state: Draft<NEmployeesSchedule.IState>, _action: PayloadAction<NEmployeesSchedule.IActivePayload>) => {
      state.isLoading = true
    },
    activeSuccess: (state: Draft<NEmployeesSchedule.IState>) => {
      state.isLoading = false
    },
    activeError: (state: Draft<NEmployeesSchedule.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Delete
    delete: (state: Draft<NEmployeesSchedule.IState>, _action: PayloadAction<NEmployeesSchedule.IDeletePayload>) => {
      state.isLoading = true
    },
    deleteSuccess: (state: Draft<NEmployeesSchedule.IState>) => {
      state.isLoading = false
    },
    deleteError: (state: Draft<NEmployeesSchedule.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

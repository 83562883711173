import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { ClientsEditPagePeekOverviewHistoryFetchAuditPayload } from './clients-edit-page-peek-overview-history.types'

export interface ClientsEditPagePeekOverviewHistoryState {
  clientAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ClientsEditPagePeekOverviewHistoryState = {
  clientAudit: null,
  isLoading: false,
  error: null,
}

export const {
  actions: clientsEditPagePeekOverviewHistoryActions,
  reducer: clientsEditPagePeekOverviewHistoryReducer,
} = createSlice({
  name: '@@clients-edit-page-peek-overview-history',
  initialState,
  reducers: {
    fetchAudit: (
      state: Draft<ClientsEditPagePeekOverviewHistoryState>,
      _: PayloadAction<ClientsEditPagePeekOverviewHistoryFetchAuditPayload>
    ): void => {
      state.isLoading = true
    },
    fetchAuditSuccess: (
      state: Draft<ClientsEditPagePeekOverviewHistoryState>,
      action: PayloadAction<Pagination<AuditApi.Audit>>
    ): void => {
      state.clientAudit = action.payload
      state.isLoading = false
    },
    fetchAuditError: (state: Draft<ClientsEditPagePeekOverviewHistoryState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isLoading = false
    },
    reset: () => initialState,
  },
})

import { NEmployeesScheduleApi } from '@api/types/employees-schedule-api.types'
import { EMPLOYEE_SCHEDULE_FOR_ENTITY } from '@constants/employees/schedule'
import { FormInstance } from 'antd'
import dayjs from 'dayjs'

import { Nullable, isDef } from '../../types/lang.types'

export const initEmployeeScheduleForm = (
  schedule: Nullable<NEmployeesScheduleApi.IEmployeeSchedule>,
  form: FormInstance<any>
) => {
  // Init employee / position
  if (isDef(schedule?.employee)) {
    form.setFieldsValue({
      scheduleForEntity: EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE,
      employeeId: schedule?.employee?.id,
      employee: schedule?.employee?.name,
    })
  } else if (isDef(schedule?.employeePosition)) {
    form.setFieldsValue({
      scheduleForEntity: EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION,
      position: schedule?.employeePosition?.name,
      employeePositionId: schedule?.employeePosition?.id,
    })
  }

  // Init date range
  if (isDef(schedule?.validFrom) && isDef(schedule?.validTo)) {
    form.setFieldsValue({
      schedulePeriod: [dayjs(schedule?.validFrom), dayjs(schedule?.validTo)],
    })
  }
}

import { formatNameColorsToHex } from '@utils/colors.utils'

import { IFigurePreset } from '../work-time-schedule.types'
import { FIGURE_DIRECTION, FIGURE_MODE, FIGURE_TYPE } from '../constants/figure'
import { TAG_TYPES } from '../constants/tag'
import { IFigure } from '../ui/hour-cell/hour-cell.types'

export const getDefaultFigurePreset = (): IFigurePreset => {
  return {
    figure: {
      color: formatNameColorsToHex('cyan'),
      type: FIGURE_TYPE.CIRCLE,
      direction: FIGURE_DIRECTION.LEFT,
      mode: FIGURE_MODE.FULL,
    },
    details: {
      name: 'Неизвестная комната',
      color: formatNameColorsToHex('cyan', 1),
    },
    tag: {
      type: TAG_TYPES.ONE_ROOM,
    },
  }
}

export const buildFigurePresetByFigure = (figure: IFigure) => {
  const figurePreset = getDefaultFigurePreset()

  figurePreset.figure.color = figure.color
  figurePreset.figure.type = figure.type
  figurePreset.figure.mode = figure.mode
  figurePreset.figure.direction = figure.direction
  figurePreset.details.color = figure.details.color
  figurePreset.details.name = figure.details.roomName
  figurePreset.tag.type = figure.tag.type

  return figurePreset
}

import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Row, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { genFranchisesCreatePagePath } from '../../../format/path.format'

export const FranchisesPageTopBar = () => {
  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
      <Col>
        <Typography.Title level={2} style={{ margin: 0 }}>
          Компании
        </Typography.Title>
      </Col>
      <Col>
        <Link to={genFranchisesCreatePagePath()} className="menu-item">
          <Button type="primary" icon={<PlusOutlined />}>
            Добавить компанию
          </Button>
        </Link>
      </Col>
    </Row>
  )
}

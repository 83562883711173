import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { ExercisesTypesApi } from '../../../../api/types/exercises-types-api.types'
import { TrainersApi } from '../../../../api/types/trainers-api.types'
import { CREATE_EXERCISE_BROADCAST } from '../../../../constants/broadcast'
import { Nullable } from '../../../../types/lang.types'
import {
  SchedulePageModalCreateCreateExercisePayload,
  SchedulePageModalCreateFetchDictionariesPayload,
  SchedulePageModalCreateFetchExercisesTypesPayload,
  SchedulePageModalCreateFetchTrainersPayload,
} from './schedule-page-modal-create.types'

export interface SchedulePageCreateModalState {
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  date: Nullable<string>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: SchedulePageCreateModalState = {
  exercisesTypes: null,
  trainers: null,
  date: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: schedulePageModalCreateActions, reducer: schedulePageModalCreateReducer } = createSlice({
  name: '@@schedule-page-create-modal',
  initialState,
  reducers: {
    fetchDictionaries: (
      state: Draft<SchedulePageCreateModalState>,
      _: PayloadAction<SchedulePageModalCreateFetchDictionariesPayload>
    ) => {
      state.isLoading = true
    },
    fetchDictionariesSuccess: (state: Draft<SchedulePageCreateModalState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    fetchDictionariesError: (state: Draft<SchedulePageCreateModalState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchExercisesTypes: (
      state: Draft<SchedulePageCreateModalState>,
      action: PayloadAction<SchedulePageModalCreateFetchExercisesTypesPayload>
    ) => {
      state.isLoading = true
    },
    fetchExercisesTypesSuccess: (
      state: Draft<SchedulePageCreateModalState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercisesTypes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchExercisesTypesError: (state: Draft<SchedulePageCreateModalState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainers: (
      state: Draft<SchedulePageCreateModalState>,
      _action: PayloadAction<SchedulePageModalCreateFetchTrainersPayload>
    ) => {
      state.isLoading = true
    },
    fetchTrainersSuccess: (
      state: Draft<SchedulePageCreateModalState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ) => {
      state.trainers = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainersError: (state: Draft<SchedulePageCreateModalState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    createExercise: (
      state: Draft<SchedulePageCreateModalState>,
      action: PayloadAction<SchedulePageModalCreateCreateExercisePayload>
    ) => {
      state.date = action.payload.date
      state.isLoading = true
    },
    createExerciseSuccess: (state: Draft<SchedulePageCreateModalState>, action: PayloadAction<any>) => {
      state.isLoading = false
      const bc = new BroadcastChannel(CREATE_EXERCISE_BROADCAST)
      bc.postMessage(
        JSON.stringify({
          id: action?.payload?.data?.id,
          status: 'success',
        })
      )
      bc.close()
    },
    createExerciseError: (state: Draft<SchedulePageCreateModalState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
      const bc = new BroadcastChannel(CREATE_EXERCISE_BROADCAST)
      bc.postMessage(
        JSON.stringify({
          id: null,
          status: 'error',
        })
      )
      bc.close()
    },
    reset: () => initialState,
  },
})

import { SearchOutlined } from '@ant-design/icons'
import { OrganizationsApi } from '@api/types/organizations-api.types'
import { Search } from '@components/controls/search/search.component'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { useTransactionsCreatePageForm } from '@pages/transactions-create-page/transactions-create-page-form/transactions-create-page-form.hook'
import { useTransactionsCreatePageModalProducts } from '@pages/transactions-create-page/transactions-create-page-modal-products/transactions-create-page-modal-products.hook'
import { TransactionsCreatePageSubmit } from '@pages/transactions-create-page/transactions-create-page-submit/transactions-create-page-submit.component'
import { TransactionsCreatePageTable } from '@pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.component'
import { TransactionsCreatePageTotal } from '@pages/transactions-create-page/transactions-create-page-total/transactions-create-page-total.component'
import { TransactionsCreatePageUnpaidRecordsSelect } from '@pages/transactions-create-page/transactions-create-page-unpaid-records-select/transactions-create-page-unpaid-records-select.component'
import { TransactionsCreatePageVisitsCards } from '@pages/transactions-create-page/transactions-create-page-visits-cards/transactions-create-page-visits-cards.component'
import { getTransactionsCreatePageTotal } from '@store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { Card, Col, Dropdown, Flex, Form, Input, Radio, RadioChangeEvent, Row, Switch, Typography } from 'antd'
import { FC, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { PaymentMethod } from '@api/types/api.types'

import { api } from '../../../api/api'
import { TillsApi } from '../../../api/types/tills-api.types'
import { formatPaymentMethod } from '../../../format/text.format'
import { Nullable } from '../../../types/lang.types'
import { TransactionsProductsModalTable } from '../transactions-products-modal/transactions-products-modal-table/transactions-products-modal-table.component'
import { useTransactionsForm } from './transactions-form.hook'
import { TransactionsFormProps } from './transactions-form.types'
import { getTotalDepositSum } from './transactions-form.utils'
import { TransactionsTillsSelect } from './ui/transactions-tills-select.component'

import './transactions-form.styles.less'

export const TransactionsForm: FC<TransactionsFormProps> = props => {
  // SECTION: Props
  const { form, clientId, children, exerciseId } = props
  const {
    selectedUnpaidRecords,
    handleAddUnpaidRecord,
    handleRemoveUnpaidRecord,
    isLoadingUnpaidRecords,
    totalUnpaidRecords,
  } = props

  // SECTION: Constants
  const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID')
  const studiosWithoutPaymentMethodLimitation = [
    'f7034d7d-dc20-4d06-b5d2-378db9e18ace',
    '92bb2651-7318-483e-9e69-ac8532f3d320',
  ]

  // SECTION: State
  const [offlineTills, setOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])
  const [depositSum, setDepositSum] = useState<number>(0)
  const [currentOrganization, setCurrentOrganization] = useState<Nullable<OrganizationsApi.Organization>>(null)
  const [visible, setVisible] = useState(false)

  // SECTION: Hook
  const hasDeposit = Form.useWatch('hasDeposit', form)
  const priceTotal = useSelector(getTransactionsCreatePageTotal)

  const [disabledSelectPayment, setDisabledSelectPayment] = useState(false)
  useEffect(() => {
    if (hasDeposit === true && depositSum >= price) {
      setDisabledSelectPayment(true)
      form.setFieldValue('paymentMethod', 'NO_PAYMENT')
    }
    if (hasDeposit === true && depositSum < price) {
      setDisabledSelectPayment(false)
      form.setFieldValue('paymentMethod', null)
    }
    if (hasDeposit === false) {
      form.setFieldValue('paymentMethod', null)
      setDisabledSelectPayment(false)
    }
  }, [hasDeposit, depositSum, priceTotal])

  // SECTION: Custom hooks
  const {
    hasPrompt,
    isLoading,
    products,
    haveProductsDiscount: discount,
    onSubmitHandler: onSubmit,
    onChangePaymentMethodHandler: onChangePaymentMethod,
    price,
    paymentMethod,
  } = useTransactionsCreatePageForm(form, offlineTills, currentOrganization, depositSum)

  const { discountReasonValidationRules, paymentMethods, haveSubscriptionsStudioLimitation } =
    useTransactionsForm(products)

  const {
    products: data,
    pagination,
    loading,
    onAddHandler: onAdd,
    onChangePageHandler: onChangePage,
    onChangePageSizeHandler: onChangePageSize,
    onSearchHandler: onSearch,
  } = useTransactionsCreatePageModalProducts()

  // SECTION: Actions
  const handleOnAddProduct = (productId: string) => {
    onAdd(productId)
    setVisible(false)
  }

  const handleInputChange = (value: string) => {
    onSearch(value)
    setVisible(true)
  }

  const handleDropdownVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const onChangePaymentMethodHandler = useCallback(
    (e: RadioChangeEvent): void => {
      onChangePaymentMethod(e.target.value)
    },
    [onChangePaymentMethod]
  )

  // SECTION: Effects
  const getCurrentStudioHelper = async () => {
    if (!studioId) return

    const response = await api.studiosService.fetchById(studioId)
    setCurrentOrganization(response?.data?.organization ?? null)
  }

  useEffect(() => {
    getCurrentStudioHelper()
  }, [])

  const updateDepositHelper = async () => {
    if (!clientId) return
    const response = await api.clientsService.fetchById(clientId).catch(error => console.error(error))
    setDepositSum(response?.data?.deposit ?? 0)
  }

  useEffect(() => {
    updateDepositHelper()
  }, [clientId])

  // SECTION: Config
  // const timeOptions = [
  //   {
  //     value: 1800,
  //     label: '30 минут',
  //   },
  //   {
  //     value: 3600,
  //     label: '1 час',
  //   },
  //   {
  //     value: 7200,
  //     label: '2 часа',
  //   },
  //   {
  //     value: 18000,
  //     label: '5 часов',
  //   },
  //   {
  //     value: 86400,
  //     label: '24 часа',
  //   },
  // ]

  // SECTION: Render
  return (
    <Form form={form} layout="vertical" disabled={isLoading} onFinish={onSubmit} style={{ minWidth: '1050px' }}>
      <Prompt when={hasPrompt} message="Вы уверены, что хотите уйти? Все несохраненные данные будут потеряны." />

      <Row gutter={16} className="transactions-form-content">
        <Col span={16}>
          <Card className="transactions-form-content__card transactions-form-content__card--left">
            <Flex vertical gap="middle">
              <Flex className="transactions-form-content__card-actions" vertical gap="small">
                <Dropdown
                  trigger={['click']}
                  onVisibleChange={handleDropdownVisibleChange}
                  className="transactions-form-content__dropdown"
                  overlay={
                    <TransactionsProductsModalTable
                      data={data}
                      loading={loading}
                      pagination={pagination}
                      onAdd={handleOnAddProduct}
                      onChangePage={onChangePage}
                      onChangePageSize={onChangePageSize}
                    />
                  }
                  visible={visible}
                >
                  <Flex vertical gap="small">
                    <Typography.Text className="transactions-form-content__card-text">
                      Поиск товара или услуги
                    </Typography.Text>
                    <Search
                      className="transactions-form-content__search"
                      placeholder="Поиск товара, услуги или абонементов"
                      onChange={handleInputChange}
                      suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
                    />
                  </Flex>
                </Dropdown>

                {clientId && (
                  <TransactionsCreatePageUnpaidRecordsSelect
                    selectedUnpaidRecords={selectedUnpaidRecords}
                    addUnpaidRecord={handleAddUnpaidRecord}
                    records={totalUnpaidRecords}
                    isLoadingRecords={isLoadingUnpaidRecords}
                  />
                )}
              </Flex>

              <TransactionsCreatePageVisitsCards
                handleAddUnpaidRecord={handleAddUnpaidRecord}
                removeUnpaidRecord={handleRemoveUnpaidRecord}
                selectedUnpaidRecords={selectedUnpaidRecords}
                addProduct={onAdd}
                clientId={clientId}
              />
              <TransactionsCreatePageTable form={form} clientId={clientId} exerciseId={exerciseId} />
            </Flex>
          </Card>
        </Col>

        <Col span={8}>
          <Card className="transactions-form-content__card transactions-form-content__card--right">
            <Flex vertical gap="middle">
              <Form.Item
                className="transactions-form-fields__paymentMethods"
                name="paymentMethod"
                rules={[{ required: true, message: 'Выберите метод оплаты' }]}
              >
                {(studioId && studiosWithoutPaymentMethodLimitation.includes(studioId)) ||
                haveSubscriptionsStudioLimitation ? (
                  <Radio.Group disabled={disabledSelectPayment} onChange={onChangePaymentMethodHandler}>
                    {paymentMethods.map(paymentMethod => (
                      <Radio.Button key={paymentMethod} value={paymentMethod}>
                        {formatPaymentMethod(paymentMethod)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                ) : (
                  <Radio.Group onChange={onChangePaymentMethodHandler}>
                    {paymentMethods
                      .filter(paymentMethod => paymentMethod !== 'CARD' && paymentMethod !== 'CASH')
                      .map(paymentMethod => (
                        <Radio.Button key={paymentMethod} value={paymentMethod}>
                          {formatPaymentMethod(paymentMethod)}
                        </Radio.Button>
                      ))}
                  </Radio.Group>
                )}
              </Form.Item>

              {Boolean(depositSum) && (
                <Flex vertical>
                  <Flex gap="middle" align="center">
                    <Form.Item name="hasDeposit">
                      <Switch size="small" />
                    </Form.Item>
                    <Typography.Paragraph>
                      Использовать депозит {formatRublesPenniesCurrency(depositSum)}
                    </Typography.Paragraph>
                  </Flex>
                </Flex>
              )}

              <Flex vertical>
                {hasDeposit && Boolean(depositSum) && (
                  <Typography.Text className="transactions-form-content__card-deposit">
                    Спишем с депозита {formatRublesPenniesCurrency(getTotalDepositSum(price, depositSum))}
                  </Typography.Text>
                )}
                <TransactionsCreatePageTotal depositSum={depositSum} form={form} />
              </Flex>

              {discount || paymentMethod === PaymentMethod.NO_PAYMENT ? (
                <Form.Item
                  className="transactions-form-fields"
                  label="Причина скидки"
                  name="discountReason"
                  rules={discountReasonValidationRules}
                >
                  <Input placeholder="Причина скидки" type="text" />
                </Form.Item>
              ) : null}

              {/* <Form.Item
                name="chosenTime"
                label={
                  <Flex gap="small" align="center">
                    <Typography.Text>Время на оплату</Typography.Text>
                    <Tooltip
                      placement="top"
                      title="Запись будет создана на установленное время и автоматически удалится, если клиент не оплатит её до истечения срока."
                    >
                      <Button
                        className="transactions-form-content__question-icon"
                        shape="round"
                        type="text"
                        icon={<QuestionCircleOutlined />}
                      />
                    </Tooltip>
                  </Flex>
                }
                rules={chosenTimeValidationRules}
              >
                <Select placeholder="Выберите время на оплату (не работает)" options={timeOptions}></Select>
              </Form.Item> */}

              {!!currentOrganization?.offlineTillSupportedPaymentMethodCategories?.length && (
                <TransactionsTillsSelect form={form} setOfflineTills={setOfflineTills} />
              )}

              <TransactionsCreatePageSubmit form={form} />
            </Flex>
          </Card>
        </Col>
      </Row>

      {children}
    </Form>
  )
}

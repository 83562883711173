import { MenuProps } from 'antd'
import { AsyncButton } from '@components/ui/async-button/async-button.component'
import { TillsApi } from '@api/types/tills-api.types'
import dayjs from 'dayjs'

import { printTerminalReport } from '../../utils/terminal-report/print-terminal-report'
import { sellsReport } from '../../utils/sells-report/sells-report'
import { refundsReport } from '../../utils/refunds-report/refunds-report'

import './online-till-shift-status.styles.less'

export const getAdditionalActionsItems = (shifts: TillsApi.IOfflineTillShift[]): MenuProps['items'] => {
  const sortedShifts = shifts.sort((a, b) => {
    return dayjs(a?.openTime).isAfter(dayjs(b?.openTime)) ? -1 : 1
  })

  const shiftsItemsForSells = sortedShifts.map(shift => {
    const formattedDate = dayjs(shift.openTime ?? dayjs())
      .locale('ru')
      .format('D MMMM')

    return {
      key: shift.id,
      label: (
        <AsyncButton shape="round" type="text" onClick={() => sellsReport(shift, formattedDate)}>
          {`Смена ${shift?.sessionNumber}, ${formattedDate}, ${shift?.employeeId?.name ?? 'Неизвестный сотрудник'}`}
        </AsyncButton>
      ),
    }
  })

  const shiftsItemsForRefunds = sortedShifts.map(shift => {
    const formattedDate = dayjs(shift.openTime ?? dayjs())
      .locale('ru')
      .format('D MMMM')

    return {
      key: shift.id,
      label: (
        <AsyncButton shape="round" type="text" onClick={() => refundsReport(shift, formattedDate)}>
          {`Смена ${shift?.sessionNumber}, ${formattedDate}, ${shift?.employeeId?.name ?? 'Неизвестный сотрудник'}`}
        </AsyncButton>
      ),
    }
  })

  return [
    {
      key: '1',
      label: (
        <div className="shift-status__button-wrapper">
          <AsyncButton shape="round" type="text" onClick={printTerminalReport}>
            Контрольная лента
          </AsyncButton>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Отчёт кассира',
      // @ts-ignore
      children: shiftsItemsForSells,
    },
    {
      key: '3',
      label: 'Отчёт возвратов',
      // @ts-ignore
      children: shiftsItemsForRefunds,
    },
  ]
}

export const onKeyPressCheckNumber = (e: React.KeyboardEvent) => {
  const specialCharRegex = new RegExp('[0-9]')
  const pressedKey = e.key
  if (
    !specialCharRegex.test(pressedKey) &&
    !e.ctrlKey &&
    !e.metaKey &&
    !e.altKey &&
    e.keyCode !== 8 &&
    e.keyCode !== 46
  ) {
    e.preventDefault()
    return false
  }
}

export const onKeyPressCheckPhone = (e: React.KeyboardEvent) => {
  const specialCharRegex = new RegExp('[0-9+]')
  const pressedKey = e.key
  if (
    !specialCharRegex.test(pressedKey) &&
    !e.ctrlKey &&
    !e.metaKey &&
    !e.altKey &&
    e.keyCode !== 8 &&
    e.keyCode !== 46
  ) {
    e.preventDefault()
    return false
  }
}

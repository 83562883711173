import { useDispatch, useSelector } from 'react-redux'

import {
  genCustomFieldsIsLoading,
  genCustomFieldsTableRowList,
} from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'

export function useCustomFieldsPageTable() {
  const dispatch = useDispatch()

  const fields = useSelector(genCustomFieldsTableRowList)
  const isLoading = useSelector(genCustomFieldsIsLoading)

  const onEditHandler = (id: string) => {
    dispatch(
      modalActions.show({
        modal: AppModal.CUSTOM_FIELDS_PAGE_MODAL_EDIT,
        props: { id },
      })
    )
  }

  const onPauseHandler = (id: string, status: boolean): void => {
    dispatch(customFieldsPageActions.updateCustomField({ id: id, data: { enabled: !status } }))
  }

  const onRemoveHandler = (id: string): void => {
    dispatch(customFieldsPageActions.removeCustomField(id))
  }

  return {
    fields,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onPauseHandler,
  }
}

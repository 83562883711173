import { Button, notification } from 'antd'

export const notificationsTills = Object.freeze({
  notConnected: () =>
    notification.warning({
      message: `Выбранная касса не подключена к компьютеру`,
    }),

  notFoundOnPC: () =>
    notification.info({
      message: `Не найдено подключенных касс к компьютеру`,
    }),

  fetchErrorOnPC: () =>
    notification.error({
      message: `Не удалось получить список касс с компьютера`,
      description: `Проверьте подключение к компьютеру и повторите попытку`,
    }),

  fetchErrorFromBD: (description: string) =>
    notification.error({
      message: `Не удалось получить список касс с сервера`,
      description: `Причина: ${description}`,
    }),

  notFoundInBD: () =>
    notification.info({
      message: `Не найдено сохраненных касс на сервере`,
    }),

  fetched: () =>
    notification.info({
      message: `Кассы успешно получены`,
    }),

  saved: () =>
    notification.success({
      message: `Кассы сохранены`,
    }),

  saveError: () =>
    notification.error({
      message: `Не удалось сохранить кассы`,
    }),
  createErrorStudio: () =>
    notification.error({
      message: `Не удалось создать студию`,
      description: `Заполните все обязательные поля`,
    }),
  saveErrorStudio: () =>
    notification.error({
      message: `Не удалось изменить студию`,
      description: `Заполните все обязательные поля`,
    }),
  findNotAddedTill: () => {
    const handleClick = () => {
      const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID')
      window.location.href = `/studios/${studioId}`
    }

    notification.warning({
      message: (
        <>
          <p>Обнаружена новая касса. Для использования добавьте её в настройках студии</p>
          <Button onClick={handleClick}>Перейти к настройке</Button>
        </>
      ),
    })
  },
})

import { Typography } from 'antd'

import { NNumber, NString } from '../../../types/lang.types'

interface Props {
  title: NString
  required: boolean
}

export const TableCellRequired = (props: Props) => {
  const { title, required } = props
  return (
    <div>
      <Typography>{title}</Typography>
      {required ? <Typography style={{ color: 'red' }}>Обязательное</Typography> : null}
    </div>
  )
}

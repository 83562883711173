import { ClientsSubscriptionsTableDataItem } from '../../../components/clients/clients-subscriptions-table/clients-subscriptions-table.types'
import { ClientSubscriptionStatus } from '../../../types/clients-subscriptions.types'

export function sortClientsEditPageSubscriptions(
  data: ClientsSubscriptionsTableDataItem[]
): ClientsSubscriptionsTableDataItem[] {
  return data.sort((a, b) => {
    if (
      a.status === ClientSubscriptionStatus.NO_VISITS ||
      a.status === ClientSubscriptionStatus.REFUNDED ||
      a.status === ClientSubscriptionStatus.EXPIRED
    ) {
      return 1
    } else {
      if (a.purchaseDate < b.purchaseDate) {
        return 1
      }
      return -1
    }
  })
}

import { ProductServicesForm } from '../../../components/products/products-services-form/product-services-form.component'
import { useProductServicesCreatePageForm } from './products-services-edit-page-form.hook'

export const ProductServicesEditPageForm = () => {
  const { form, onFinishHandler, studiosOptions, exercisesOptions } = useProductServicesCreatePageForm()
  return (
    <ProductServicesForm
      disabledFormat={true}
      studiosOptions={studiosOptions}
      // @ts-ignore
      exercisesOptions={exercisesOptions}
      form={form}
      submitText="Редактирование услуги"
      onFinish={onFinishHandler}
    />
  )
}

import * as React from 'react'
import { Button, Flex, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

export const StudioCell: React.FC = () => {
  return (
    <Flex>
      <p>-</p>
    </Flex>
  )
}

import * as React from 'react'

import { genPaymentMethodsList } from '../../../utils/payment.utils'
import {
  genTransactionsFormPhoneValidationRules,
  genTransactionsFormPaymentMethodValidationRules,
  genTransactionsFormDiscountReasonValidationRules,
  checkSubscriptionsHaveStudioLimitation,
  genTransactionsFormChosenTimeValidationRules,
} from './transactions-form.utils'
import { MarkCodesAndCountableEntityItem, Nullable } from '../../../types/lang.types'
import { ProductsApi } from '../../../api/types/products-api.types'

export function useTransactionsForm(products: Nullable<MarkCodesAndCountableEntityItem<ProductsApi.Product>[]>) {
  const paymentMethods = React.useMemo(genPaymentMethodsList, [])
  const phoneValidationRules = React.useMemo(genTransactionsFormPhoneValidationRules, [])
  const paymentMethodValidationRules = React.useMemo(genTransactionsFormPaymentMethodValidationRules, [])
  const discountReasonValidationRules = React.useMemo(genTransactionsFormDiscountReasonValidationRules, [])
  const chosenTimeValidationRules = React.useMemo(genTransactionsFormChosenTimeValidationRules, [])

  const haveSubscriptionsStudioLimitation = React.useMemo(() => {
    return checkSubscriptionsHaveStudioLimitation(products)
  }, [products])

  return {
    phoneValidationRules,
    paymentMethodValidationRules,
    discountReasonValidationRules,
    chosenTimeValidationRules,

    paymentMethods,
    haveSubscriptionsStudioLimitation,
  }
}

import { useRouteMatch } from 'react-router-dom'

import { PositionsEditPageParams } from '../positions-edit-page.types'

export function usePositionsEditPageParams() {
  const match = useRouteMatch<PositionsEditPageParams>()
  const { params } = match
  const { id } = params

  return {
    id,
  }
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { PositionsApi } from '../../../api/types/positions-api.types'
import { PositionsPageParams } from '../../../pages/positions-page/positions-page.types'
import { PositionsPageRemovePositionPayload } from './positions-page.types'

export interface PositionsPageState {
  positions: Nullable<Pagination<PositionsApi.Position>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: PositionsPageState = {
  positions: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: positionsPageActions, reducer: positionsPageReducer } = createSlice({
  name: '@@positions-page',
  initialState,
  reducers: {
    fetchAllPositions: (state: Draft<PositionsPageState>, _: PayloadAction<PositionsPageParams>) => {
      state.isLoading = true
    },
    fetchAllPositionsSuccess: (
      state: Draft<PositionsPageState>,
      action: PayloadAction<Nullable<Pagination<PositionsApi.Position>>>
    ) => {
      state.positions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllPositionsError: (state: Draft<PositionsPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removePosition: (state: Draft<PositionsPageState>, _: PayloadAction<PositionsPageRemovePositionPayload>) => {
      state.isLoading = true
    },
    removePositionSuccess: (state: Draft<PositionsPageState>) => {
      state.isLoading = false
    },
    removePositionError: (state: Draft<PositionsPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, FormInstance, Typography, Card } from 'antd'
import { LeftOutlined, SearchOutlined } from '@ant-design/icons'
import { TransactionsFormValues } from '@components/transactions/transactions-form/transactions-form.types'
import { useTransactionsForm } from '@components/transactions/transactions-form/transactions-form.hook'
import { TillsApi } from '@api/types/tills-api.types'

import { genTransactionsPagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'
import { useTransactionsCreatePageParams } from '../transactions-create-page-hooks/transactions-create-page-params.hook'
import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { useTransactionsCreatePageForm } from '../transactions-create-page-form/transactions-create-page-form.hook'
import './transactions-create-page-header.styles.less'

interface Props {
  form: FormInstance<TransactionsFormValues>
  children?: React.ReactNode
  setClientId: React.Dispatch<React.SetStateAction<string | null>>
}

export const TransactionsCreatePageHeader: React.FC<Props> = props => {
  const { studioId } = useTransactionsCreatePageParams()
  const { form, setClientId } = props
  const [offlineTills] = React.useState<TillsApi.OfflineTillDTO[]>([])
  const [isShowShiftTransactions] = React.useState(false)

  // @ts-ignore
  //! TODO: FIX IT
  const { products } = useTransactionsCreatePageForm(form, offlineTills, isShowShiftTransactions, 0)

  const { phoneValidationRules } = useTransactionsForm(products)

  const onChangePhoneHandler = React.useCallback(
    (value?: string): void => {
      const formValues = form.getFieldsValue()
      form.setFieldsValue({
        ...formValues,
        phone: value,
      })
    },
    [form]
  )

  return (
    <div className="transactions-create-page-header">
      <Link to={genTransactionsPagePath(studioId)}>
        <Button className="transactions-create-page-header__button" type="text" icon={<LeftOutlined />} size="small">
          {formatPathName(AppPath.TRANSACTIONS)}
        </Button>
      </Link>

      <Typography.Title className="transactions-create-page-header__title" level={2}>
        {formatPathName(AppPath.TRANSACTIONS_CREATE)}
      </Typography.Title>

      <Card className="transactions-create-page-header__client-card">
        <Form form={form} layout="vertical">
          <Form.Item name="phone" rules={phoneValidationRules}>
            <Typography.Text className="transactions-create-page-header__client-card-title">
              Выберите клиента
            </Typography.Text>
            <ClientsAutocompleteContainer
              size="large"
              form={form}
              name="phone"
              transactionsForm
              onChange={onChangePhoneHandler}
              setClientId={setClientId}
              placeholder="Поиск"
              suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

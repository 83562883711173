import { api } from '@api/api'
import { useState } from 'react'
import { Table } from 'antd'

import { genProvidersTableColumns } from '../goods-columns.utils'
import { DeleteModal } from '../modals/delete-modal'
import { EditProvider } from '../modals/edit-provider'

export const ProvidersTable = (props: any) => {
  const { providers, isLoading, getAllProviders } = props
  const [provider, setProvider] = useState({} as any)
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const onOpenModal = (row: any, method: 'delete' | 'edit') => {
    setProvider(row)
    if (method === 'delete') {
      setIsDeleteModalOpened(true)
    } else {
      setIsEditModalOpened(true)
    }
  }

  const onDelete = async () => {
    await api.goodsProvidersService.remove(provider.id)
    await getAllProviders()
    setIsDeleteModalOpened(false)
  }

  const columns = genProvidersTableColumns(onOpenModal)

  const updateProvider = async (event: any) => {
    await api.goodsProvidersService.update(provider.id, event)
    await getAllProviders()
    setIsEditModalOpened(false)
  }

  return (
    <>
      <EditProvider
        onFinish={updateProvider}
        provider={provider}
        isOpen={isEditModalOpened}
        onCancel={() => {
          setIsEditModalOpened(false)
        }}
      />
      <DeleteModal onCancel={() => setIsDeleteModalOpened(false)} isOpen={isDeleteModalOpened} onDelete={onDelete} />
      <Table columns={columns} dataSource={providers.content} loading={isLoading} />
    </>
  )
}

import { TeamOutlined } from '@ant-design/icons/lib/icons'
import { LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY } from '@constants/local-storage'
import { Select } from 'antd'
import { FC, useEffect, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import './shift-select.styles.less'

import { IShiftSelectProps } from './shift-select.types'

export const ShiftSelect: FC<IShiftSelectProps> = ({
  value,
  handleChangeValue,
  options,
  isLoading,
  isDisabled,
  connectedOfflineTillsOptions,
}) => {
  const [valueFromLocalStorage, setValueFromLocalStorage] = useLocalStorage<string | null>(
    LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY
  )

  useEffect(() => {
    if (!value && options.length) {
      if (
        valueFromLocalStorage &&
        connectedOfflineTillsOptions.some(option => option.value === valueFromLocalStorage)
      ) {
        handleChangeValue(valueFromLocalStorage)
        return
      }

      const defaultValue = connectedOfflineTillsOptions[0]?.value
      if (!defaultValue) return

      handleChangeValue(String(defaultValue))
      setValueFromLocalStorage(String(defaultValue))
    }
  }, [options, value])

  const selectOptions = useMemo(() => {
    if (!options.length)
      return [
        {
          value: 'add-new',
          label: 'Новая касса',
        },
      ]
    return options
  }, [options])

  const selectValue = useMemo(() => {
    if (!options.length) return 'add-new'
    return value
  }, [value, options])

  const handleChangeValueInternal = (value: string) => {
    setValueFromLocalStorage(value)
    handleChangeValue(value)
  }

  return (
    <div data-component-name="ShiftSelect" className="shift-select__wrapper">
      <TeamOutlined className="shift-select__icon" />
      <Select
        className="shift-select"
        options={selectOptions}
        loading={isLoading}
        value={selectValue}
        onChange={handleChangeValueInternal}
        disabled={false}
      />
    </div>
  )
}

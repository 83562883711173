import { TillsApi } from '@api/types/tills-api.types'
import { tillEntityActions } from '@store/common/entity/till/till.slice'
import { modalActions } from '@store/common/modal/modal.slice'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'

export function useFranchisesModalTillCreateForm() {
  const [form] = Form.useForm()
  const titleText = 'Подключение новой онлайн-кассы'
  const btnTitle = 'Создать кассу'

  const dispatch = useDispatch()
  const onCancel = (): void => {
    dispatch(modalActions.closeLast())
  }
  const onFinish = (values: TillsApi.IOnlineTillDTO): void => {
    dispatch(tillEntityActions.createTill(values))
    onCancel()
  }

  return { form, titleText, onFinish, onCancel, btnTitle }
}

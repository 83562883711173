import { TeamOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Typography } from 'antd'

import { ScheduleGroupPageSearch } from '../schedule-group-page-guests-search/schedule-group-page-guests-search.component'
import './schedule-group-page-topbar.styles.less'

export const ScheduleGroupPageTopBar = ({
  clientsCount,
  maxClientsCount,
  onSearch,
  onQrCode,
}: {
  clientsCount: number
  maxClientsCount: number
  onSearch: (value: string) => void
  onQrCode: () => void
}) => {
  return (
    <Flex align="center" gap={53} className="schedule-group-page-topbar">
      <Col style={{ width: 463 }}>
        <ScheduleGroupPageSearch onSearch={onSearch} onQrCode={onQrCode} />
      </Col>
      <Button style={{ borderRadius: 50 }} type="dashed" icon={<TeamOutlined style={{ color: '#1677ff' }} />}>
        <Typography.Text style={{ fontWeight: 700 }}>
          {clientsCount} из {maxClientsCount}
        </Typography.Text>
      </Button>
    </Flex>
  )
}

import { DefaultOptionType } from 'antd/lib/select'
import { SearchApi } from '@api/types/search-api.types'

import { PositionsApi } from '../api/types/positions-api.types'
import {
  PositionsFormGradeValues,
  PositionsFormValues,
} from '../components/positions/positions-form/positions-form.types'
import { PositionsTableDataItem } from '../components/positions/positions-table/positions-table.types'
import { Nullable, isDef, isDefAndNotEmpty } from '../types/lang.types'
import { PositionType } from '../types/positions.types'

export function mapPositionsToRemovePositionOptions(
  positions: Nullable<PositionsApi.Position[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(positions)) {
    return positions
      .filter(position => position.positionType === PositionType.CUSTOM)
      .reduce((acc: DefaultOptionType[], position: PositionsApi.Position) => {
        acc.push({
          value: position.id,
          label: position.name,
        })

        acc.sort((optionA, optionB) =>
          typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
            ? -1
            : 1
        )
        return acc
      }, [])
  }
}

export function mapPositionsToPositionsTableRowList(
  positions: Nullable<PositionsApi.Position[]>
): Nullable<PositionsTableDataItem[]> {
  if (isDefAndNotEmpty(positions)) {
    return positions.map(position => ({
      id: position.id,
      name: position.name,
      gradesCount: position.grades ? position.grades.length : null,
      positionType: position.positionType,
    }))
  }
}

export function genPositionGrades(data: Nullable<PositionsApi.Position>): Nullable<PositionsFormGradeValues[]> {
  if (isDef(data) && isDefAndNotEmpty(data.grades)) {
    return data.grades.map(grade => ({
      id: grade.id,
      name: grade.name,
    }))
  }
}

export function genPositionFormValues(data: Nullable<PositionsApi.Position>): Nullable<PositionsFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      grades: data.grades?.map(grade => grade.name),
      positionType: data.positionType,
    }
  }
}

export function genPositionDTO(data: PositionsFormValues): PositionsApi.PositionDTO {
  return {
    name: data.name,
    positionType: data.positionType,
  }
}

export function genGradeCreateDTO(
  name: string,
  positionId: string,
  positionType: PositionType
): PositionsApi.GradeCreateDTO {
  return {
    name: name,
    parentId: positionId,
    positionType: positionType,
  }
}

export function genGradeUpdateDTO(name: string): PositionsApi.GradeUpdateDTO {
  return {
    name: name,
  }
}

export function mapPositionsToPositionsAutocompleteOptionDataItems(positions: Nullable<SearchApi.SearchItem[]>) {
  if (isDefAndNotEmpty(positions)) {
    return positions?.map((position: SearchApi.SearchItem) => {
      const { id, details, label } = position

      return {
        slug: id,
        label: label,
        details: details,
      }
    })
  }

  return null
}

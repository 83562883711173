import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'antd/lib'
import { useEffect, useState } from 'react'

interface DefaultOptionType {
  value: string
  format: string
  directions?: DefaultOptionType[]
  id?: string
}

export function useProductsSubcriptionsForm(exercisesOptions: DefaultOptionType[], form: FormInstance) {
  const durationOptions = [
    { label: '1 неделя', value: 7 },
    { label: '2 недели', value: 14 },
    { label: '30 дней', value: 30 },
    { label: '60 дней', value: 60 },
    { label: '180 дней', value: 180 },
    { label: '1 год', value: 365 },
    { label: '2 года', value: 730 },
  ]
  const [filteredOptions, setFilteredOptions] = useState(exercisesOptions)
  const [filteredDirectionsOptions, setFilteredDirectionsOptions] = useState<DefaultOptionType[]>([])

  const typesSelect = useWatch('availableTypes', form)
  const directionsSelect = useWatch('availableDirections', form)

  useEffect(() => {
    const selectedIds = typesSelect?.map((type: DefaultOptionType) => type.value)

    if (selectedIds && selectedIds.length > 0) {
      const selectedFormats = exercisesOptions
        ?.filter(option => selectedIds.includes(option.value))
        .map(option => option.format)

      setFilteredOptions(exercisesOptions?.filter(option => selectedFormats?.includes(option.format)))

      if (selectedFormats?.includes('GROUP')) {
        const groupDirections = exercisesOptions
          ?.filter(option => selectedIds.includes(option.value) && option.format === 'GROUP')
          .flatMap(option => option.directions)
          .reduce((uniqueDirections: DefaultOptionType[], direction) => {
            if (!uniqueDirections.some(d => d.id === direction?.id)) {
              uniqueDirections.push(direction!)
            }
            return uniqueDirections
          }, [])

        setFilteredDirectionsOptions(groupDirections)
      } else {
        setFilteredDirectionsOptions([])
      }
    } else {
      setFilteredDirectionsOptions([])
      setFilteredOptions(exercisesOptions)
    }
  }, [typesSelect, exercisesOptions])

  useEffect(() => {
    const filteredDirectionsIds = filteredDirectionsOptions.map(direction => direction.id)
    const updatedDirectionsSelect = directionsSelect?.filter((direction: DefaultOptionType) =>
      filteredDirectionsIds.includes(direction.value)
    )

    if (JSON.stringify(directionsSelect) !== JSON.stringify(updatedDirectionsSelect)) {
      form.setFieldsValue({ availableDirections: updatedDirectionsSelect })
    }
  }, [filteredDirectionsOptions, directionsSelect, form])

  return { filteredOptions, filteredDirectionsOptions, durationOptions }
}

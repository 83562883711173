import clsx from 'clsx'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Collapse, Flex, Tooltip } from 'antd'

import { useTheme } from '../../hooks/use-theme.hook'
import './custom-collapse-panel.styles.less'

interface Props {
  children: React.ReactNode
  panelHeader: React.ReactNode
  isActive: boolean
  index: string
  customClassName?: string
  onEditHandler: () => void
  onRemoveHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

export const CustomCollapsePanel: React.FC<Props> = props => {
  const { children, isActive, index } = props
  const { panelHeader, customClassName, onEditHandler, onRemoveHandler, ...rest } = props

  const { theme } = useTheme()

  return (
    <Collapse.Panel
      {...rest}
      header={panelHeader}
      showArrow={false}
      className={clsx(`custom-collapse-panel ${customClassName} custom-collapse-panel--${theme}`, {
        'custom-collapse-panel--active': isActive,
      })}
      key={index}
      extra={
        <Flex align="center" justify="space-between" className="custom-collapse-panel__extra" gap="middle">
          <Tooltip title="Редактировать">
            <EditOutlined className="custom-collapse-panel__icon" onClick={onEditHandler} />
          </Tooltip>
          <Tooltip title="Удалить">
            <DeleteOutlined className="custom-collapse-panel__icon" onClick={onRemoveHandler} />
          </Tooltip>
        </Flex>
      }
    >
      {children}
    </Collapse.Panel>
  )
}

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { transactionsPageActions } from './transactions-page-common.slice'
import { callApi } from '../../../../utils/sagas.utils'

export function* fetchAllTransactions(action: ReturnType<typeof transactionsPageActions.fetchAllTransactions>) {
  try {
    const { studioId, page, size, paymentMethod, status, clientId, sellerId, productId, dateFrom, dateTo } =
      action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.transactionsService.fetchAll>> = yield callApi(
      api.transactionsService.fetchAll,
      {
        ...params,
        studioId,
        paymentMethod,
        status,
        clientIds: clientId ?? undefined,
        sellerIds: sellerId ?? undefined,
        productIds: productId ?? undefined,
        dateFrom,
        dateTo,
        sort: 'createDate,desc',
      }
    )

    yield put(transactionsPageActions.fetchAllTransactionsSuccess(response.data))
  } catch (e) {
    yield put(transactionsPageActions.fetchAllTransactionsError(new Error()))
  }
}

export function* transactionsPageCommonSagas() {
  yield takeLatest(transactionsPageActions.fetchAllTransactions.type, fetchAllTransactions)
}

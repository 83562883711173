import { FormInstance } from 'antd'
import { useState, useCallback } from 'react'

import { StudiosFormTypes } from './studios-form-types'

export const useStudiosCreateForm = (isEdit: boolean, form: FormInstance<StudiosFormTypes>) => {
  const [currentTab, setCurrentTab] = useState('1')

  const onChangeCurrentTab = (key: string) => {
    setCurrentTab(key)
  }

  const onNextTab = useCallback(async () => {
    form
      .validateFields()
      .then((values: any) => {
        onChangeCurrentTab((+currentTab + 1).toString())
      })
      .catch((errorInfo: any) => {
        if ('values' in errorInfo) {
          console.error('error', JSON.stringify(errorInfo))
        } else {
          onChangeCurrentTab((+currentTab + 1).toString())
        }
      })
  }, [currentTab, form])

  return {
    currentTab,
    onChangeCurrentTab,
    onNextTab,
  }
}

import { TillsApi } from '@api/types/tills-api.types'
import { formatRublesPenniesCurrency } from '@format/number.format'

import { Nullable } from '../../types/lang.types'

export interface IOfflineTillBalance {
  card: string
  cash: string
  online: string
  total: string
  refund: string
  income: string
}

export const useOfflineTillBalance = (currentOfflineTill: Nullable<TillsApi.OfflineTillDTO>): [IOfflineTillBalance] => {
  if (!currentOfflineTill)
    return [
      {
        card: '0,00 ₽',
        cash: '0,00 ₽',
        online: '0,00 ₽',
        total: '0,00 ₽',
        refund: '0,00 ₽',
        income: '0,00 ₽',
      },
    ]

  const balance = currentOfflineTill.lastTillShift?.balance
  return [
    {
      card: formatRublesPenniesCurrency(balance?.card?.sum ?? 0),
      cash: formatRublesPenniesCurrency(balance?.cash?.sum ?? 0),
      online: formatRublesPenniesCurrency(balance?.online?.sum ?? 0),
      total: formatRublesPenniesCurrency(balance?.total?.sum ?? 0),
      refund: formatRublesPenniesCurrency(balance?.total?.refund ?? 0),
      income: formatRublesPenniesCurrency(balance?.total?.income ?? 0),
    },
  ]
}

import * as React from 'react'
import { Button, Modal } from 'antd'

import { ExercisesGroupBookFromWaitingForm } from '../exercises-group-book-from-waiting-form/exercises-group-book-from-waiting-form.component'
import { ExercisesGroupBookFromWaitingModalProps } from './exercises-group-book-from-waiting-modal.types'
import { useExercisesGroupBookFromWaitingModal } from './exercises-group-book-from-waiting-modal.hook'

export const ExercisesGroupBookFromWaitingModal: React.FC<ExercisesGroupBookFromWaitingModalProps> = props => {
  const { form, placesOptions, paymentTypesOptions, loading, name } = props
  const { onCancel, onSave } = props

  const { isValid, onFieldsChange } = useExercisesGroupBookFromWaitingModal(form)

  const onSaveHandler = React.useCallback((): void => {
    const values = form.getFieldsValue()
    onSave(values)
  }, [form, onSave])

  return (
    <Modal
      title={`Записать гостя ${name}`}
      open={true}
      onCancel={onCancel}
      footer={
        <Button type="primary" loading={loading} onClick={onSaveHandler} disabled={!isValid}>
          Записать
        </Button>
      }
    >
      <ExercisesGroupBookFromWaitingForm
        form={form}
        loading={loading}
        placesOptions={placesOptions}
        paymentTypesOptions={paymentTypesOptions}
        onFieldsChange={onFieldsChange}
      />
    </Modal>
  )
}

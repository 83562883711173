import { FC } from 'react'
import { Button, Modal } from 'antd'
import { RollbackOutlined } from '@ant-design/icons'

import { IModalBackConfirmProps } from './modal-confirm-back.types'

export const ModalConfirmBack: FC<IModalBackConfirmProps> = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      title="Вернуться назад"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} icon={<RollbackOutlined />}>
          Вернуться
        </Button>,
      ]}
    >
      <p>
        Вы уверены что хотите вернуться назад? <br />
        Заполненные данные будут утеряны.
      </p>
    </Modal>
  )
}

import { TillsApi } from '../../../../../../api/types/tills-api.types'

export const validateOfflineTillName = (
  offlineTills: TillsApi.OfflineTillDTO[],
  value: string,
  oldOfflineTillName?: string
) => {
  if (oldOfflineTillName === value) {
    return Promise.resolve()
  }

  const offlineTillExists = offlineTills.some(till => till.nameDevice === value)
  if (offlineTillExists) {
    return Promise.reject(new Error('Имя кассы должно быть уникальным'))
  }
  return Promise.resolve()
}

import * as React from 'react'
import { genExercisesFormMaxClientsCountValidationRules } from '@components/exercises/exercises-form/exercises-form.utils'

import {
  genExercisesGroupEditFormDirectionValidationRules,
  genExercisesGroupEditFormStudiosRoomsValidationRules,
  genExercisesGroupEditFormStudiosValidationRules,
  genExercisesGroupEditFormTimeFromValidationRules,
  genExercisesGroupEditFormTimeToValidationRules,
} from './exercises-group-edit-form.utils'

export function useExercisesGroupEditForm() {
  const timeFromValidationRules = React.useMemo(genExercisesGroupEditFormTimeFromValidationRules, [])
  const timeToValidationRules = React.useMemo(genExercisesGroupEditFormTimeToValidationRules, [])

  const studiosValidationRules = React.useMemo(genExercisesGroupEditFormStudiosValidationRules, [])
  const studiosRoomsValidationRules = React.useMemo(genExercisesGroupEditFormStudiosRoomsValidationRules, [])
  const directionValidationRules = React.useMemo(genExercisesGroupEditFormDirectionValidationRules, [])
  const maxClientsCountValidationRules = React.useMemo(genExercisesFormMaxClientsCountValidationRules, [])

  const timeRangePlaceholder = React.useMemo<[string, string]>(() => ['Время начала', 'Время окончания'], [])

  return {
    timeFromValidationRules,
    timeToValidationRules,
    studiosValidationRules,
    studiosRoomsValidationRules,
    directionValidationRules,
    maxClientsCountValidationRules,

    timeRangePlaceholder,
  }
}

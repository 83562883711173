import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genProductAuditInternal = (state: AppState) => state.productsPageReducer.productsPagePeekHistory.productAudit

export const genProductsPagePeekHistoryIsLoading = (state: AppState) =>
  state.productsPageReducer.productsPagePeekHistory.isLoading

export const genProductsPagePeekHistoryTotalElements = createSelector(
  genProductAuditInternal,
  audit => audit?.totalElements
)

export const genProductsPagePeekHistoryProductHistory = createSelector(
  genProductAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

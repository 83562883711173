import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { validateStrEnumValue } from '@utils/enum.utils'

import { genCustomFieldsPagePath } from '../../../format/path.format'
import { getCustomFieldsResource } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { useCustomFieldsPageParams } from '../custom-fields-page-hooks/custom-fields-page-params.hook'
import { CustomFieldsPageParams } from '../studios-feilds-page.types'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

export function useCustomFieldsPageTabs() {
  useCustomFieldsPageParams()
  const dispatch = useDispatch()
  const onChangeHandler = (key: string) => {
    let resource: CustomFieldsApi.CustomFieldResource
    switch (key) {
      case '1':
        resource = 'STUDIO'
        break
      case '2':
        resource = 'ROOM'
        break
      case '3':
        resource = 'CLIENT'
        break
      case '4':
        resource = 'EMPLOYEE'
        break
      default:
        resource = 'STUDIO'
    }
    dispatch(customFieldsPageActions.filtersTypeCustomFields(resource))
  }

  return {
    onChangeHandler,
  }
}

import { useState } from 'react'

export const useModal = (handleOk: () => void): [boolean, () => void, () => void, () => void] => {
  const [isShowModal, setIsShowModal] = useState(false)

  const handleOpenModal = () => setIsShowModal(true)
  const handleCloseModal = () => setIsShowModal(false)
  const handleConfirmModal = () => {
    handleOk()
    handleCloseModal()
  }

  return [isShowModal, handleOpenModal, handleCloseModal, handleConfirmModal]
}

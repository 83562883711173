import { Flex, Typography } from 'antd'

import { Square } from '../svg/square/square.component'
import { TAG_COLORS } from '../../constants/tag'
import { Circle } from '../svg/circle/circle.component'
import { FIGURE_DIRECTION, FIGURE_MODE } from '../../constants/figure'

export const ScheduleInfo = () => {
  return (
    <Flex justify="end" gap="middle" className="work-time-schedule__info">
      <Flex gap="small">
        <div className="work-time-schedule__info-square" />
        <Typography.Paragraph>Не рабочее время активного зала или студии</Typography.Paragraph>
      </Flex>
      <Flex gap="small">
        <Square color={TAG_COLORS.ALL_STUDIOS} />
        <Typography.Paragraph>Все студии</Typography.Paragraph>
      </Flex>
      <Flex gap="small">
        <Square color={TAG_COLORS.ALL_ROOMS} />
        <Typography.Paragraph>Все залы</Typography.Paragraph>
      </Flex>
      <Flex gap="small">
        <Circle color="#1677FF" />
        <Typography.Paragraph>Полный час</Typography.Paragraph>
      </Flex>
      <Flex gap="small">
        <Circle color="#1677FF" mode={FIGURE_MODE.HALF} direction={FIGURE_DIRECTION.LEFT} />
        <Typography.Paragraph>Половина часа</Typography.Paragraph>
      </Flex>
    </Flex>
  )
}

import { all, put, takeLatest, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { genEmployeesPagePath } from '../../../format/path.format'
import { genEmployeeCreateDTO } from '../../../mapping/employees.mapping'
import { employeesCreatePageActions } from './employees-create-page.slice'
import { callApi } from '../../../utils/sagas.utils'
import { getEmployeesPositionType } from './employees-create-page.selectors'
import { PositionType } from '../../../types/positions.types'
import { customFieldsSettingsActions } from '../../common/custom-fields-settings/custom-fields-settings.slice'

export function* fetchPageData(_: ReturnType<typeof employeesCreatePageActions.fetchPageData>) {
  try {
    const [franchises, positions, directions, exercisesTypes]: [
      Awaited<ReturnType<typeof api.organizationsService.fetchAll>>,
      Awaited<ReturnType<typeof api.employeesPositionsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>>
    ] = yield all([
      callApi(api.organizationsService.fetchAll, {
        size: 100,
      }),
      callApi(api.employeesPositionsService.fetchAll, {
        size: 100,
      }),
      callApi(api.exercisesDirectionsService.fetchAll, {
        size: 100,
      }),
      callApi(api.exercisesTypesService.fetchAll, {
        size: 100,
      }),
    ])

    yield put(
      employeesCreatePageActions.fetchPageDataSuccess({
        franchises: franchises.data,
        positions: positions.data,
        directions: directions.data,
        exercisesTypes: exercisesTypes.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(employeesCreatePageActions.fetchPageDataError(new Error()))
  }
}

export function* createEmployee(action: ReturnType<typeof employeesCreatePageActions.createEmployee>) {
  try {
    const employeePositionType: PositionType = yield select(getEmployeesPositionType)
    const employeeCreateDTO = genEmployeeCreateDTO(action.payload, employeePositionType)
    // @ts-ignore
    const response = yield callApi(api.employeesService.create, employeeCreateDTO)
    yield put(employeesCreatePageActions.createEmployeeSuccess())
    yield put(
      customFieldsSettingsActions.updateEmployeeCustomFields({
        id: response.data.id,
      })
    )
    yield put(push(genEmployeesPagePath()))
  } catch (e) {
    console.error(e)
    yield put(employeesCreatePageActions.createEmployeeError(new Error()))
  }
}

export function* employeesCreatePageSagas() {
  yield takeLatest(employeesCreatePageActions.fetchPageData, fetchPageData)
  yield takeLatest(employeesCreatePageActions.createEmployee, createEmployee)
}

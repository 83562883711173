import { clsx } from 'clsx'
import { Card } from 'antd'
import { memo, useRef, useEffect, useState } from 'react'

import { MasterServicesWidgetFormPreviewCode } from './master-services-widget-form-preview-code/master-services-widget-form-preview-code.component'
import { MasterServicesWidgetFormPreviewProps } from './master-services-widget-form-preview.types'
import { genMasterServicesWidgetScript } from './master-services-widget-form-preview.utils'
import { DeviceMode } from '../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'
import { MasterServicesWidgetFormUiDeviceMode } from '../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.component'
import './master-services-widget-form-preview.styles.less'

const DESKTOP_WIDTH = 816

const InjectScript = memo(({ script }: { script: string }) => {
  const wrappingWidgetRef = useRef<HTMLDivElement | null>(null)
  const wrappingScriptRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (wrappingWidgetRef.current !== null && wrappingScriptRef.current !== null) {
      wrappingScriptRef.current.innerHTML = ''
      const doc = document.createRange().createContextualFragment(script)
      wrappingScriptRef.current.appendChild(doc)
    }

    return () => {
      ;(window as any)['loaded-_smBookingWidget'] = false
    }
  })

  return (
    <>
      <div id="vc-widget-online-booking" ref={wrappingWidgetRef}></div>
      <div ref={wrappingScriptRef} />
    </>
  )
})

export const MasterServicesWidgetFormPreview: React.FC<MasterServicesWidgetFormPreviewProps> = props => {
  const { defaultMasterServiceId, form, tenantKey } = props
  const [deviceMode, setDeviceMode] = useState(DeviceMode.DESKTOP)

  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = cardRef?.current
    if (!element) return

    const size = element.getBoundingClientRect()
    if (size.width > DESKTOP_WIDTH) {
      setDeviceMode(DeviceMode.DESKTOP)
    } else {
      setDeviceMode(DeviceMode.MOBILE)
    }
  }, [])

  useEffect(() => {
    return () => {
      document.getElementById('_smBookingWidget')?.remove()
      delete (window as { [key: string]: any })['_smBookingWidget'] as any
    }
  }, [])

  return (
    <Card
      bordered={false}
      className={clsx('master-services-widget-form-preview__card', {
        'master-services-widget-form-preview__card-mobile': deviceMode === DeviceMode.MOBILE,
      })}
      ref={cardRef}
    >
      <InjectScript
        script={genMasterServicesWidgetScript({
          masterServiceId: defaultMasterServiceId,
          tenantKey: tenantKey,
          staticWidgetMode: true,
        })}
      />
      <MasterServicesWidgetFormUiDeviceMode mode={deviceMode} onChaneMode={setDeviceMode} />
      <MasterServicesWidgetFormPreviewCode form={form} tenantKey={tenantKey} />
    </Card>
  )
}

import { Breadcrumb } from 'antd'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const Breadcrumbs: FC = () => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/employees/schedule">График расписания</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Редактирование расписания</Breadcrumb.Item>
    </Breadcrumb>
  )
}

import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { TablePaginationConfig } from 'antd/lib'

import { api } from '../../../../api/api'
import { PaymentType } from '../../../../api/types/api.types'
import { ClientsBookingsTableActiveEvents } from '../clients-bookings-table-active/clients-bookings-table-active.types'
import { genClientsBookingsTableActiveColumns } from '../clients-bookings-table-active/clients-bookings-table-active.utils'
import { ClientsBookingsTableBaseDataItem } from '../clients-bookings-table-base/clients-bookings-table-base.types'
import { mapExercisesTimetableBookingToClientsBookingsTableBaseDataItems } from './clients-bookings-table-multibooking.utils'
import './clients-bookings-table-multibooking.styles.less'

interface Props extends ClientsBookingsTableActiveEvents {
  id: string
  timetableId: string
  clientId: string
  expandRow: boolean
  onMultibooking: (key: string) => void
}

export const ClientsBookingsTableMultibooking: React.FC<Props> = props => {
  const { id, timetableId, clientId, expandRow, onMultibooking } = props
  const { onVisit, onBarcode, onCancel, onBooking, onHistory, onChangePaymentType } = props

  const [isLoading, setIsLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const [bookings, setBookings] = useState<ClientsBookingsTableBaseDataItem[] | undefined>()

  const fetchBookingsByTimetableId = useCallback(
    async (page: number) => {
      setIsLoading(true)

      await api.exercisesTimetableService
        .fetchBookingsByTimetableId(timetableId, {
          clientId,
          page,
          size: 25,
        })
        .then(res => {
          const bookings = mapExercisesTimetableBookingToClientsBookingsTableBaseDataItems(res.data.content)
          setBookings(bookings)
          setTotal(res.data.totalElements)
          setPage(res.data.number + 1)
        })
        .catch(console.error)
        .finally(() => setIsLoading(false))
    },
    [clientId, timetableId]
  )

  const refetchMultibooking = useCallback(() => {
    fetchBookingsByTimetableId(page - 1)
  }, [fetchBookingsByTimetableId, page])

  const onChangePageHandler = useCallback(
    (page: number): void => {
      fetchBookingsByTimetableId(page - 1)
    },
    [fetchBookingsByTimetableId]
  )

  const onVisitHandler = useCallback(
    (bookingId: string, exerciseId: string, checked: boolean) => {
      onVisit(bookingId, exerciseId, checked, refetchMultibooking)
    },
    [onVisit, refetchMultibooking]
  )

  const onChangePaymentTypeHandler = useCallback(
    (exerciseId: string, paymentType: PaymentType, bookingId: string) => {
      onChangePaymentType(exerciseId, paymentType, bookingId, refetchMultibooking)
    },
    [onChangePaymentType, refetchMultibooking]
  )

  const onCancelMultibookingHandler = useCallback(() => {
    refetchMultibooking()
  }, [refetchMultibooking])

  const onMultibookingHandler = () => {
    if (!expandRow) {
      fetchBookingsByTimetableId(0)
    }
    onMultibooking(id)
  }

  const columns: ColumnsType<ClientsBookingsTableBaseDataItem> = useMemo(
    () =>
      genClientsBookingsTableActiveColumns({
        onVisit: onVisitHandler,
        onBarcode,
        onCancel,
        onBooking,
        onHistory,
        onChangePaymentType: onChangePaymentTypeHandler,
        onCancelMultibooking: onCancelMultibookingHandler,
      }),
    [onVisitHandler, onBarcode, onCancel, onBooking, onHistory, onChangePaymentTypeHandler, onCancelMultibookingHandler]
  )

  const paginationConfig = useMemo(
    (): TablePaginationConfig => ({
      current: page,
      pageSize: 25,
      total: total,
      position: ['bottomCenter'],
      showSizeChanger: false,
      onChange: onChangePageHandler,
    }),
    [onChangePageHandler, page, total]
  )

  const getRowClassName = (record: ClientsBookingsTableBaseDataItem, index: number) => {
    return clsx('clients-bookings-table-multibooking__row', {
      'clients-bookings-table-multibooking__row--first': page === 1 && index === 0,
    })
  }

  return (
    <div>
      <Table
        rowKey="id"
        dataSource={bookings}
        loading={isLoading}
        columns={columns}
        pagination={paginationConfig}
        rowClassName={getRowClassName}
        className={clsx('clients-bookings-table-multibooking__table', {
          'clients-bookings-table-multibooking__table--expand': expandRow,
        })}
      />
      <div
        className={clsx('clients-bookings-table-multibooking__button', {
          'clients-bookings-table-multibooking__button--expand': expandRow,
        })}
        onClick={onMultibookingHandler}
      >
        {expandRow ? 'Свернуть длинную запись' : 'Посмотреть все записи'}
      </div>
    </div>
  )
}

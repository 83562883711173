import { useScheduleGroupPageParams } from '@pages/schedule-group-page/schedule-group-page-hooks/schedule-group-page-params.hook'
import {
  genScheduleGroupPageExerciseLinked,
  genScheduleGroupPageListIsLoading,
} from '@store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { useSelector } from 'react-redux'
export function useScheduleGroupPageRecordTable() {
  const exerciseLinked = useSelector(genScheduleGroupPageExerciseLinked)
  const isLoading = useSelector(genScheduleGroupPageListIsLoading)
  const { studioId, scheduleId } = useScheduleGroupPageParams()

  return {
    exerciseLinked,
    isLoading,
    studioId,
    scheduleId,
  }
}

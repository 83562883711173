import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ClientsOverviewHistoryPeekProps } from './clients-overview-history-peek.types'

export const ClientsOverviewHistoryPeek: FC<ClientsOverviewHistoryPeekProps> = props => {
  const { isLoading, clientHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(clientHistory) ? (
        <HistoryList
          title="Активность с карточкой клиента"
          type={HistoryListType.CLIENT}
          history={clientHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

import { useState } from 'react'

import { TillsApi } from '../../api/types/tills-api.types'

// Types
type TSetOfflineTills = (rowTills: TillsApi.OfflineTillDTO[], tillsFromBD: TillsApi.OfflineTillDTO[]) => void

// Hook
export const useNotConnectedOfflineTills = (): [
  setOfflineTills: TSetOfflineTills,
  notConnectedOfflineTills: string[]
] => {
  const [notConnectedOfflineTills, setNotConnectedOfflineTills] = useState<string[]>([])

  const setOfflineTills: TSetOfflineTills = (rowTills, tillsFromBD) => {
    const offlineTillsFromPC = rowTills.map(till => till.idDevice)
    const offlineTillsOnlyInBD = tillsFromBD.filter(till => !offlineTillsFromPC.includes(till.idDevice))

    setNotConnectedOfflineTills(offlineTillsOnlyInBD.map(till => till.id))
  }

  return [setOfflineTills, notConnectedOfflineTills]
}

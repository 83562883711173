import * as React from 'react'
import { Flex, Typography } from 'antd'
import { TableCellTagProductType } from '@components/table-cells/table-cell-tag-product-type/table-cell-tag-product-type.component'
import { ProductType } from '@api/types/api.types'

import { isDef, NString } from '../../../../../types/lang.types'
import { DEFAULT_EMPTY_SYMBOL } from '../../../../../format/text.format'
import './transactions-products-modal-table-name.styles.less'

interface Props {
  text: NString
  productType: ProductType
}

export const TransactionsProductsModalTableName: React.FC<Props> = props => {
  const { text, productType } = props
  if (isDef(text)) {
    return (
      <Flex vertical className="transactions-products-modal-table-name">
        <TableCellTagProductType productType={productType} />
        <Typography.Text ellipsis={{ tooltip: text }}>{text}</Typography.Text>
      </Flex>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

import { modalActions } from '@store/common/modal/modal.slice'
import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'rc-field-form'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppModal } from '../../../../types/modal.types'

export const useFranchiseFormPrintingReceipts = ({ form }: { form: FormInstance }) => {
  const dispatch = useDispatch()

  // online
  const onlineTillValue = useWatch('onlineTillEnabled', form)
  const onlineTillId = useWatch('onlineTill', form)
  const [onlineTillEnabledSwitchValue, setOnlineTillEnabledSwitchValue] = useState(false)
  const onChangeOnlineTillValueEnabledSwitchValue = () => {
    form.setFieldValue('onlineTillEnabled', !onlineTillValue)
    setOnlineTillEnabledSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (onlineTillValue && !onlineTillEnabledSwitchValue) {
      form.setFieldValue('onlineTillEnabled', true)
      setOnlineTillEnabledSwitchValue(prev => !prev)
    }
  }, [onlineTillValue, form])

  // offline
  const offlineTillValue = useWatch('offlineTillEnabled', form)
  const [offlineTillEnabledSwitchValue, setOfflineTillEnabledSwitchValue] = useState(false)
  const onChangeOfflineTillValueEnabledSwitchValue = () => {
    form.setFieldValue('offlineTillEnabled', !offlineTillValue)
    setOfflineTillEnabledSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (offlineTillValue && !offlineTillEnabledSwitchValue) {
      form.setFieldValue('offlineTillEnabled', true)
      setOfflineTillEnabledSwitchValue(prev => !prev)
    }
  }, [offlineTillValue, form])

  const paymentOptions = [
    {
      label: 'Наличные',
      value: 'CASH',
    },
    {
      label: 'Онлайн платежи',
      value: 'ONLINE',
    },
    {
      label: 'По карте',
      value: 'CARD',
    },
  ]
  const openModalCreateTill = () => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TILL_FORM,
      })
    )
  }

  const openModalEditTill = () => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TILL_FORM,
        props: {
          idEditedTill: onlineTillId,
        },
      })
    )
  }

  const vatTypeOptions = [
    {
      label: 'БЕЗ НДС',
      value: 'NONE',
    },
    {
      label: '0%',
      value: 'VAT0',
    },
    {
      label: '10%',
      value: 'VAT10',
    },
    {
      label: '20%',
      value: 'VAT20',
    },
  ]
  const snoTypeOptions = [
    {
      label: 'Общая система налогообложения (ОСНО)',
      value: 'osn',
    },
    {
      label: 'Упрощенная система налогообложения (УСН)',
      value: 'usn_income',
    },
    {
      label: 'Единый сельскохозяйственный налог (ЕСХН)',
      value: 'esn',
    },
    {
      label: 'Патентная система налогообложения (ПСН)',
      value: 'patent',
    },
  ]
  return {
    paymentOptions,
    openModalCreateTill,
    openModalEditTill,
    onChangeOnlineTillValueEnabledSwitchValue,
    onlineTillEnabledSwitchValue,
    onChangeOfflineTillValueEnabledSwitchValue,
    offlineTillEnabledSwitchValue,
    onlineTillValue,
    vatTypeOptions,
    snoTypeOptions,
  }
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { ExercisesTypesFormValues } from '../../../components/exercises-types/exercises-types-form/exercises-types-form.types'
import { exercisesTypesCreatePageActions } from '../../../store/pages/exercises-types-create-page/exercises-types-create-page.slice'
import { genColorsOptions } from '../../../utils/colors.utils'
import {
  genExercisesTypesCreatePageIsLoading,
  getExercisesTypesCreatePageDirectionsOptions,
} from '../../../store/pages/exercises-types-create-page/exercises-types-create-page.selectors'

export function useExercisesTypesCreatePageForm() {
  const [form] = Form.useForm<ExercisesTypesFormValues>()
  const colorsOptions = React.useMemo(genColorsOptions, [])

  const dispatch = useDispatch()

  const isLoading = useSelector(genExercisesTypesCreatePageIsLoading)
  const directionsOptions = useSelector(getExercisesTypesCreatePageDirectionsOptions)

  const onFinishHandler = React.useCallback(
    (values: ExercisesTypesFormValues): void => {
      dispatch(exercisesTypesCreatePageActions.createExercisesType(values))
    },
    [dispatch]
  )

  return {
    form,
    isLoading,
    directionsOptions,
    colorsOptions,
    onFinishHandler,
  }
}

import { ProductSubscriptionForm } from '../../../components/products/products-subscriptions-form/products-subscriptions-form.component'
import { useProductSubscriptionsCreatePageForm } from './products-subscriptions-сreate-page.hook'

export const ProductSubscriptionCreatePageForm = () => {
  const { form, isLoading, directionsOptions, studiosOptions, exercisesOptions, onFinishHandler } =
    useProductSubscriptionsCreatePageForm()

  return (
    <ProductSubscriptionForm
      disabledVariants={false}
      directionsOptions={directionsOptions}
      studiosOptions={studiosOptions}
      freezingValue={null}
      activationDaysValue={null}
      // @ts-ignore
      exercisesOptions={exercisesOptions}
      form={form}
      isLoading={isLoading}
      submitText="Создать"
      onFinish={onFinishHandler}
    />
  )
}

import { FC, useMemo } from 'react'
import { Form, FormInstance, Typography } from 'antd'
import { useSelector } from 'react-redux'
import {
  getTransactionsCreatePageTablePaymentMethod,
  getTransactionsCreatePageTotal,
} from '@store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { TransactionsFormValues } from '@components/transactions/transactions-form/transactions-form.types'

import './transactions-create-page-total.styles.less'

interface Props {
  className?: string
  depositSum: number
  form: FormInstance<TransactionsFormValues>
}

export const TransactionsCreatePageTotal: FC<Props> = ({ className, depositSum, form }) => {
  const price = useSelector(getTransactionsCreatePageTotal)
  const paymentMethod = useSelector(getTransactionsCreatePageTablePaymentMethod)

  const hasDeposit = Form.useWatch('hasDeposit', form)

  const sumForPay = useMemo(() => {
    let sum = price

    if (hasDeposit) {
      sum = Math.max(price - depositSum, 0)
    }

    return formatRublesPenniesCurrency(sum ?? 0)
  }, [price, depositSum, hasDeposit, paymentMethod])

  return (
    <Typography.Title className="transactions-create-page-total" level={3}>
      К оплате {sumForPay}
    </Typography.Title>
  )
}

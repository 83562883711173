import { AxiosResponse } from 'axios'
import { SearchApi } from '@api/types/search-api.types'

import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { Pagination, PaginationParamsDTO } from '../types/api.types'
import { PositionsApi } from '../types/positions-api.types'

export class PositionsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<PositionsApi.Position>>> => {
    return this.httpConnector.get<Pagination<PositionsApi.Position>>('/employees/positions', {
      params,
    })
  }

  public search = (value: string): Promise<AxiosResponse<Pagination<SearchApi.SearchItem>>> => {
    return this.httpConnector.get(`/search/positions?q=${value}`)
  }

  public fetchPositionById = (id: string): Promise<AxiosResponse<PositionsApi.Position>> => {
    return this.httpConnector.get<PositionsApi.Position>(`/employees/positions/${id}`)
  }

  public createPosition = (data: PositionsApi.PositionDTO): Promise<AxiosResponse<PositionsApi.Position>> => {
    return this.httpConnector.post<PositionsApi.Position>('/employees/positions', data)
  }

  public createGrade = (data: PositionsApi.GradeCreateDTO): Promise<AxiosResponse<PositionsApi.Position>> => {
    return this.httpConnector.post<PositionsApi.Position>('/employees/positions', data)
  }

  public updatePosition = (
    id: string,
    data: PositionsApi.PositionDTO
  ): Promise<AxiosResponse<PositionsApi.Position>> => {
    return this.httpConnector.patch<PositionsApi.Position>(`/employees/positions/${id}`, data)
  }

  public updateGrade = (
    id: string,
    data: PositionsApi.GradeUpdateDTO
  ): Promise<AxiosResponse<PositionsApi.Position>> => {
    return this.httpConnector.patch<PositionsApi.Position>(`/employees/positions/${id}`, data)
  }

  public removePosition = (id: string, data: PositionsApi.PositionRemoveDTO) => {
    return this.httpConnector.put(`/employees/positions/${id}`, data)
  }

  public removeGrade = (id: string): Promise<void> => {
    return this.httpConnector.put(`/employees/positions/${id}`, {})
  }
}

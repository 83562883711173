import { Dispatch, useEffect, useState } from 'react'

import { Nullable } from '../../../../../types/lang.types'
import { ICurrentStudioSlice, IFigurePreset, IWorkTimeScheduleStudio } from '../work-time-schedule.types'
import { IStudiosCollapsesDefaults } from '../ui/studios-collapses/studios-collapses.types'
import { IFigure } from '../ui/hour-cell/hour-cell.types'

export const useInitDefaultValues = (
  figures: IFigure[],
  defaultFigure: Nullable<IFigure>,
  defaultFigurePreset: IFigurePreset,
  defaultStudio: Nullable<ICurrentStudioSlice>,
  defaultScheduleStudios: IWorkTimeScheduleStudio[],
  setSelectedStudio: Dispatch<React.SetStateAction<Nullable<ICurrentStudioSlice>>>,
  setSelectedFigure: Dispatch<React.SetStateAction<Nullable<IFigure>>>,
  setFigurePreset: Dispatch<React.SetStateAction<IFigurePreset>>,
  setDefaultsCollapsesStudios: Dispatch<React.SetStateAction<IStudiosCollapsesDefaults | null>>,
  setSchedulesStudios: Dispatch<React.SetStateAction<IWorkTimeScheduleStudio[]>>
) => {
  const [isInitDefaults, setIsInitDefaults] = useState(false)

  useEffect(() => {
    if (!defaultStudio) return
    if (!defaultFigure) return
    if (!figures) return
    if (isInitDefaults) return

    if (defaultStudio.selectedRoom) {
      setDefaultsCollapsesStudios({
        activeKeys: [defaultStudio.id],
        currentTagId: defaultStudio.selectedRoom.id,
      })
    }

    setSelectedStudio(defaultStudio)
    setSelectedFigure(defaultFigure)
    setFigurePreset(defaultFigurePreset)
    setSchedulesStudios(defaultScheduleStudios)

    setIsInitDefaults(true)
  }, [
    figures,
    defaultStudio,
    defaultFigure,
    isInitDefaults,
    setSelectedStudio,
    setSelectedFigure,
    setDefaultsCollapsesStudios,
    setFigurePreset,
    defaultFigurePreset,
    setSchedulesStudios,
    defaultScheduleStudios,
  ])
}

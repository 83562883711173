import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import { Pagination } from '../types/api.types'
import { AuditApi } from '../types/audit.types'

export class AuditService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchById = (
    entity: string,
    id: string,
    params?: Nullable<Partial<AuditApi.AuditFetchByIdParams>>
  ): Promise<AxiosResponse<Pagination<AuditApi.Audit>>> => {
    return this.httpConnector.get<Pagination<AuditApi.Audit>>(`/audit/${entity}/${id}`, {
      params,
    })
  }
}

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

import { CustomFieldsPageParams } from '../studios-feilds-page.types'
import { genCustomFieldsPageParams } from '../custom-fields-page.utils'
import { getCustomFieldsResource } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'

export function useCustomFieldsPageParams(): CustomFieldsPageParams {
  const { search } = useLocation()
  const resource = useSelector(getCustomFieldsResource)
  const history = useHistory()
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('resource', resource)
    const updatedUrl = `${window.location.pathname}?${searchParams.toString()}`

    history.replace(updatedUrl)
  }, [search, resource, history])

  return genCustomFieldsPageParams(resource)
}

import Typography from 'antd/es/typography/Typography'

import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

interface Props {
  type: CustomFieldsApi.CustomFieldsType
}
const getTypeName = (type: CustomFieldsApi.CustomFieldsType) => {
  switch (type) {
    case 'INPUT':
      return 'Текстовое поле'
    case 'MULTILINE_INPUT':
      return 'Многострочное текстовое поле'
    case 'SELECT':
      return 'Выпадающий список'
    case 'CHECKBOX_GROUP':
      return 'Чекбокс'
    default:
      return 'Неизвестный тип'
  }
}

export const TableCellTypeField = ({ type }: Props) => {
  const text = getTypeName(type)

  return <Typography>{text}</Typography>
}

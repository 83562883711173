import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTransactionsCreatePageTableProductsTableDataItems } from '../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { transactionsCreatePageTableActions } from '../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'
import { NString } from '../../../types/lang.types'

export function useTransactionsCreatePageTable() {
  const dispatch = useDispatch()

  const products = useSelector(getTransactionsCreatePageTableProductsTableDataItems)

  const onRemoveHandler = React.useCallback(
    (productUuid: string): void => {
      dispatch(transactionsCreatePageTableActions.removeProduct(productUuid))
    },
    [dispatch]
  )

  const onChangeAmountHandler = React.useCallback(
    (productUuid: string, amount: number) => {
      dispatch(transactionsCreatePageTableActions.changeAmount({ productUuid, amount }))
    },
    [dispatch]
  )

  const onChangeMarkCodesHandler = React.useCallback(
    (productUuid: string, markCodes: string[]) => {
      dispatch(transactionsCreatePageTableActions.changeMarkCodes({ productUuid, markCodes }))
    },
    [dispatch]
  )

  const onChangeActiveRecordHandler = (productUuid: string, exerciseId: string) => {
    dispatch(transactionsCreatePageTableActions.changeActiveRecord({ productUuid, exerciseId }))
  }

  const onChangeDiscountHandler = React.useCallback(
    (productUuid: string, discount: number) => {
      dispatch(transactionsCreatePageTableActions.changeDiscount({ productUuid, discount }))
    },
    [dispatch]
  )

  const onChangeTrialHandler = React.useCallback(
    (clientId: NString) => {
      dispatch(transactionsCreatePageTableActions.changeTrial(clientId))
    },
    [dispatch]
  )

  return {
    products,
    onRemoveHandler,
    onChangeAmountHandler,
    onChangeMarkCodesHandler,
    onChangeDiscountHandler,
    onChangeTrialHandler,
    onChangeActiveRecordHandler,
  }
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { ExercisesTypesFormValues } from '../../../components/exercises-types/exercises-types-form/exercises-types-form.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { Pagination } from '../../../api/types/api.types'

export interface ExercisesTypesCreatePageState {
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ExercisesTypesCreatePageState = {
  directions: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: exercisesTypesCreatePageActions, reducer: exercisesTypesCreatePageReducer } = createSlice({
  name: '@@exercises-types-create-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<ExercisesTypesCreatePageState>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<ExercisesTypesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>>
    ) => {
      state.directions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<ExercisesTypesCreatePageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createExercisesType: (state: Draft<ExercisesTypesCreatePageState>, _: PayloadAction<ExercisesTypesFormValues>) => {
      state.isLoading = true
    },
    createExercisesTypeSuccess: (state: Draft<ExercisesTypesCreatePageState>) => {
      state.isLoading = false
    },
    createExercisesTypeError: (state: Draft<ExercisesTypesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import qs from 'qs'

import { NString, isString, isDef, isNumber } from '../../types/lang.types'
import { CustomFieldsPageParams } from './studios-feilds-page.types'
import { CustomFieldsApi } from '../../api/types/custom-fields-api.types'

export function genCustomFieldsPageParams(search: NString): CustomFieldsPageParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null
  const resource = queryParams?.type as CustomFieldsApi.CustomFieldResource
  return {
    resource,
  }
}

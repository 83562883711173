import APIHelpers from './APIHelpers'
import { type IAPIRequests } from './types'

const APIRequests: IAPIRequests = {
  get: async (url, params?) => {
    // Create query
    const paramsWithoutUndefined = params ? JSON.parse(JSON.stringify(params)) : undefined
    const queryString = new URLSearchParams(paramsWithoutUndefined).toString().trim()

    // Request
    const result = await fetch(APIHelpers.BASE_URL + url + (queryString ? `?${queryString}` : ''), {
      ...APIHelpers.REQUEST_PARAMS,
      method: 'GET',
    })

    return result
  },

  post: async (url, body) => await APIHelpers.requestMutate('POST', url, body),

  patch: async (url, body) => await APIHelpers.requestMutate('PATCH', url, body),

  delete: async (url, body) => await APIHelpers.requestMutate('DELETE', url, body),
}

export default Object.freeze(APIRequests)

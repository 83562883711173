import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'
import { FC, useEffect, useState } from 'react'
import { Typography, Flex } from 'antd'
import { useLocation } from 'react-router-dom'
import { api } from '@api/api'

import { ITransactionsCreatePageVisitsCardsProps } from './transactions-create-page-visits-cards.types'
import { VisitCard } from './ui/visit-card/visit-card.component'
import { ITransactionCreateDefaultValues } from '../transactions-create-page.types'
import { transformBookingToRecord } from './ui/visit-card/visit-card.utils'

export const TransactionsCreatePageVisitsCards: FC<ITransactionsCreatePageVisitsCardsProps> = ({
  addProduct,
  removeUnpaidRecord,
  selectedUnpaidRecords,
  clientId,
  handleAddUnpaidRecord,
}) => {
  let location = useLocation<ITransactionCreateDefaultValues>()

  const [hasRedirectDefaultValues, setHasRedirectDefaultValues] = useState<null | boolean>(null)
  const [defaultValues, setDefaultValues] = useState<ITransactionCreateDefaultValues | null>(null)
  const [booking, setBooking] = useState<ClientsBookingsApi.ClientBooking | null>(null)

  useEffect(() => {
    const initDefaultValues = async () => {
      if (!location?.state?.clientId) return
      const defaults: ITransactionCreateDefaultValues = {
        phone: location.state?.phone,
        clientId: location.state?.clientId,
        exerciseId: location.state?.exerciseId,
      }

      if (location.state?.exerciseId) {
        const bookingResponse = await api.clientsBookingsService.fetchBookingByExerciseId(
          location?.state?.clientId,
          location.state?.exerciseId
        )
        defaults.bookingId = bookingResponse?.data?.id
        setBooking(bookingResponse.data)

        handleAddUnpaidRecord(transformBookingToRecord(bookingResponse.data) as ClientsBookingsApi.UnpaidRecord)
      }

      setDefaultValues(defaults)
    }

    initDefaultValues()

    if (location?.state?.clientId) {
      setHasRedirectDefaultValues(true)
    } else {
      setHasRedirectDefaultValues(false)
    }
  }, [])

  if (!clientId) return null
  if (!selectedUnpaidRecords.length) return null

  return (
    <Flex vertical gap="small" className="transactions-create-page-visits-cards">
      <Typography.Title className="transactions-create-page-visits-cards__title" level={4}>
        Оплата посещений
      </Typography.Title>

      {selectedUnpaidRecords.map(record => (
        <VisitCard
          key={record.responseId}
          addProduct={addProduct}
          record={record}
          removeUnpaidRecord={removeUnpaidRecord}
          defaultValues={defaultValues}
          hasRedirectDefaultValues={hasRedirectDefaultValues}
          setHasRedirectDefaultValues={setHasRedirectDefaultValues}
          booking={booking}
          clientId={clientId}
        />
      ))}
    </Flex>
  )
}

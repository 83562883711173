import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Flex, Switch } from 'antd'

import { TableCellVisitEvents } from './table-cell-visit.types'

interface Props extends TableCellVisitEvents {
  id: string
  isChecked: boolean
}

export const TableCellVisit = (props: Props) => {
  const { id, isChecked } = props
  const { onVisit } = props

  const onChangeHandler = (checked: boolean): void => {
    onVisit(id, checked)
  }

  return (
    <Switch
      checkedChildren={
        <Flex gap={2} style={{ fontSize: 8 }}>
          <CheckOutlined />
          <span>ПРИШЁЛ</span>
        </Flex>
      }
      unCheckedChildren={
        <Flex gap={2} style={{ fontSize: 8 }}>
          <span>ПРИШЁЛ</span>
          <CloseOutlined />
        </Flex>
      }
      checked={isChecked}
      onChange={onChangeHandler}
    />
  )
}

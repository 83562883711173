import * as React from 'react'
import { InputRef } from 'antd'

import { AutocompleteInputProps } from '../../../controls/autocomplete/autocomplete-input/autocomplete-input.types'
import { AutocompleteInput } from '../../../controls/autocomplete/autocomplete-input/autocomplete-input.component'

const EmployeesAutocompleteInputInternal = (props: AutocompleteInputProps, ref: React.Ref<InputRef>) => {
  const { value, placeholder, disabled, onChange, size, suffix, formatValue } = props

  return (
    <AutocompleteInput
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      ref={ref}
      size={size}
      suffix={suffix}
      formatValue={formatValue}
    />
  )
}

export const ClientsAutocompleteInput = React.forwardRef(
  EmployeesAutocompleteInputInternal
) as typeof EmployeesAutocompleteInputInternal

export enum EMPLOYEE_SCHEDULE_STATUS {
  ACTIVE = 'ACTIVE',
  ARCHIVE = 'ARCHIVE',
  DRAFT = 'DRAFT',
  PAUSED = 'PAUSED',
}

export enum EMPLOYEE_SCHEDULE_FOR_ENTITY {
  EMPLOYEE = 'EMPLOYEE',
  POSITION = 'POSITION',
}

export enum EMPLOYEE_SCHEDULE_EDIT_MODE {
  CREATE = 'CREATE',
  COPY = 'COPY',
}

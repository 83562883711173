import { Table } from 'antd'

import { ExercisesGroupBookingsTableLinkedDataItem } from './exercises-group-linked-table.types'
import { genExercisesGroupTableLinkedColumns } from './exercises-group-linked-table.utils'

interface Props {
  className?: string
  data: ExercisesGroupBookingsTableLinkedDataItem[]
  loading?: boolean
  studioId: string
  scheduleId: string
}

export const ExercisesGroupLinkedTable = ({ className, data, loading, studioId, scheduleId }: Props) => {
  const columns = genExercisesGroupTableLinkedColumns({ studioId, scheduleId, data })

  return (
    <Table
      style={{ width: '100%' }}
      pagination={false}
      className={className}
      dataSource={data}
      rowKey="id"
      columns={columns}
      loading={loading}
    />
  )
}

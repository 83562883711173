import { Flex, Typography } from 'antd'
import * as React from 'react'

import { ClientsBookingsApi } from '../../../../../api/types/clients-bookings-api.types'
import { Nullable } from '../../../../../types/lang.types'
import { TableCellVisit } from '../../../../table-cells/table-cell-visit/table-cell-visit.component'
import { ClientsBookingsTableActiveVisitEvents } from './clients-bookings-table-active-visit.types'
import './clients-bookings-table-active-visit.styles.less'
const { Text } = Typography

interface Props extends ClientsBookingsTableActiveVisitEvents {
  exerciseId: string
  bookingId: string
  isChecked: boolean
  multibooking: Nullable<ClientsBookingsApi.ClientMultibooking>
}

export const ClientsBookingsTableActiveVisit: React.FC<Props> = props => {
  const { exerciseId, bookingId, isChecked, multibooking } = props
  const { onVisit } = props

  const onVisitHandler = React.useCallback(
    (bookingId: string, checked: boolean): void => {
      onVisit(bookingId, exerciseId, checked)
    },
    [exerciseId, onVisit]
  )

  if (multibooking) {
    return (
      <Flex vertical>
        <Text className="clients-bookings-table-active-visit__text">
          Посещено <span>{multibooking.visitedExercises}</span>
        </Text>
        <Text className="clients-bookings-table-active-visit__text">
          Осталось <span>{multibooking.totalExercises - multibooking.visitedExercises}</span>
        </Text>
      </Flex>
    )
  }
  return <TableCellVisit id={bookingId} isChecked={isChecked} onVisit={onVisitHandler} />
}

import { FC } from 'react'

import { ExerciseTypeFormat } from '../../../api/types/api.types'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ScheduleHistoryPeekProps } from './schedule-history-peek.types'

export const ScheduleHistoryPeek: FC<ScheduleHistoryPeekProps> = props => {
  const { format, isLoading, scheduleHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(scheduleHistory) ? (
        <HistoryList
          title={`История действий с ${format === ExerciseTypeFormat.PERSONAL ? 'длинной записью' : 'расписанием'}`}
          type={format === ExerciseTypeFormat.PERSONAL ? HistoryListType.MULTIBOOKING : HistoryListType.TIMETABLE}
          history={scheduleHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

import { Form, Space, ColorPicker, Typography, Row, Col, Slider, InputNumber, Select, FormInstance } from 'antd'

import { NNumber } from '../../../../../types/lang.types'
import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { DeviceMode } from '../../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'
import {
  MasterServicesWidgetShadowFormValues,
  MasterServicesWidgetShadowSliderValues,
} from './master-services-widget-form-popover-shadow.types'
import { getInsetOptions } from './master-services-widget-form-popover-shadow.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetShadowFormValues>
  deviceMode: DeviceMode
  sliderValues: MasterServicesWidgetShadowSliderValues
  initialValues?: MasterServicesWidgetShadowFormValues
  handleSliderValuesChange: (
    property: string,
    mode: keyof MasterServicesWidgetShadowSliderValues,
    value: NNumber
  ) => void
  handleFormValuesChange: (_changedField: any, allFields: MasterServicesWidgetShadowFormValues) => void
}

export const MasterServicesWidgetFormPopoverShadow: React.FC<Props> = props => {
  const { form, initialValues, deviceMode, sliderValues } = props
  const { handleSliderValuesChange, handleFormValuesChange } = props

  return (
    <Form
      form={form}
      initialValues={initialValues}
      autoComplete="off"
      layout="vertical"
      style={{ width: '310px' }}
      onValuesChange={handleFormValuesChange}
    >
      {getStrEnumValues<DeviceMode>(DeviceMode).map(mode => {
        const shadowDeviceMode = mode.toLowerCase() as keyof typeof sliderValues

        return (
          <Space
            direction="vertical"
            size="middle"
            key={mode}
            style={{ width: '100%', display: mode === deviceMode ? 'flex' : 'none' }}
          >
            <Form.Item label="Цвет тени" name={[shadowDeviceMode, 'color']}>
              <ColorPicker showText />
            </Form.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Горизонтальное смещение</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('hOffset', shadowDeviceMode, value)}
                    value={
                      typeof sliderValues[shadowDeviceMode].hOffset === 'number'
                        ? sliderValues[shadowDeviceMode].hOffset
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[shadowDeviceMode].hOffset}
                    onChange={value => handleSliderValuesChange('hOffset', shadowDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Вертикальное смещение</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('vOffset', shadowDeviceMode, value)}
                    value={
                      typeof sliderValues[shadowDeviceMode].vOffset === 'number'
                        ? sliderValues[shadowDeviceMode].vOffset
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[shadowDeviceMode].vOffset}
                    onChange={value => handleSliderValuesChange('vOffset', shadowDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Размытие</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('blur', shadowDeviceMode, value)}
                    value={
                      typeof sliderValues[shadowDeviceMode].blur === 'number' ? sliderValues[shadowDeviceMode].blur : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[shadowDeviceMode].blur}
                    onChange={value => handleSliderValuesChange('blur', shadowDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Размах</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('spread', shadowDeviceMode, value)}
                    value={
                      typeof sliderValues[shadowDeviceMode].spread === 'number'
                        ? sliderValues[shadowDeviceMode].spread
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[shadowDeviceMode].spread}
                    onChange={value => handleSliderValuesChange('spread', shadowDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Form.Item label="Положение" name={[shadowDeviceMode, 'inset']}>
              <Select options={getInsetOptions()} placeholder="Выберите" />
            </Form.Item>
          </Space>
        )
      })}
    </Form>
  )
}

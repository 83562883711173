import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Form, FormInstance, Popover, Space, Typography } from 'antd'

import { MasterServicesWidgetFormPopoverTypography } from '../../master-services-widget-form-popovers/master-services-widget-form-popover-typography/master-services-widget-form-popover-typography.component'
import { MasterServicesWidgetTypographyFormValues } from '../../master-services-widget-form-popovers/master-services-widget-form-popover-typography/master-services-widget-form-popover-typography.types'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { MasterServicesWidgetFormUiDeviceMode } from '../master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.component'
import { useMasterServicesWidgetFormUiTypography } from './master-services-widget-form-ui-typography.hook'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  title?: string
  element: string[]
  initialValues: MasterServicesWidgetTypographyFormValues
}

export const MasterServicesWidgetFormUiTypography: React.FC<Props> = props => {
  const { form, title = 'Типографика', element, initialValues } = props
  const {
    typographyForm,
    deviceMode,
    sliderValues,
    openPopover,
    setDeviceMode,
    handleSliderValuesChange,
    hidePopover,
    handlePopoverOpenChange,
  } = useMasterServicesWidgetFormUiTypography({ form, element, initialValues })

  return (
    <Space direction="vertical">
      <Typography.Text>{title}</Typography.Text>
      <Popover
        content={
          <MasterServicesWidgetFormPopoverTypography
            form={typographyForm}
            initialValues={initialValues}
            deviceMode={deviceMode}
            element={element}
            sliderValues={sliderValues}
            handleSliderValuesChange={handleSliderValuesChange}
          />
        }
        placement="bottom"
        title={
          <Flex justify="space-between" align="center">
            {title}
            <Flex align="center">
              <MasterServicesWidgetFormUiDeviceMode mode={deviceMode} onChaneMode={setDeviceMode} />
              <Button onClick={hidePopover} icon={<CloseOutlined />} type="text" />
            </Flex>
          </Flex>
        }
        trigger="click"
        open={openPopover}
        onOpenChange={handlePopoverOpenChange}
      >
        <Button type="dashed">Редактировать</Button>
      </Popover>
      <Form.Item noStyle name={element}></Form.Item>
    </Space>
  )
}

import { Form, Input, Button, FormInstance, Select, Col, Flex, Typography } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { ChangeEvent, useCallback } from 'react'
import { TeamOutlined } from '@ant-design/icons'

import { ImageUploader } from '../../image-uploader/image-uploader.component'
import { DirectionsFormValues } from './directions-form.types'
import { isDef, NString } from '../../../types/lang.types'

interface Props {
  form: FormInstance<DirectionsFormValues>
  isLoading: boolean
  exercisesTypesOptions: DefaultOptionType[] | undefined
  submitText: string
  onFinish: (values: DirectionsFormValues) => void
}

export const DirectionsForm = (props: Props) => {
  const { form, isLoading, exercisesTypesOptions, submitText, onFinish } = props

  const photo = Form.useWatch('photo', form)

  const onChangeImageHandler = useCallback(
    (photo: NString): void => {
      if (isDef(photo)) {
        const formValues = form.getFieldsValue()

        form.setFieldsValue({
          ...formValues,
          photo,
        })
      }
    },
    [form]
  )

  const onChangeDuration = (e: ChangeEvent<HTMLInputElement>) => {
    const formValues = form.getFieldsValue()
    const newValue = e.target.value
    form.setFieldsValue({
      ...formValues,
      duration: newValue,
    })
  }
  return (
    <Form
      form={form}
      name="userCreate"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      disabled={isLoading}
    >
      <Col>
        <Form.Item name="photo" className="avatar_item">
          <ImageUploader image={photo} onChange={onChangeImageHandler} />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название' }]}>
          <Input placeholder="Название" />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item label="Длительность" name="duration">
          <Input type="time" value={form.getFieldValue('duration')} onChange={onChangeDuration} />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item
          label="Что взять с собой"
          name="whatToTake"
          rules={[{ required: true, message: 'Укажите что нужно взять с собой' }]}
        >
          <Input.TextArea maxLength={255} style={{ height: 60 }} placeholder="Что взять с собой" />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item
          label="Описание"
          name="description"
          rules={[{ required: true, message: 'Необходимо оставить описание' }]}
        >
          <Input.TextArea maxLength={2000} style={{ height: 60 }} placeholder="Описание направления" />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item name="types" label="Категории записей">
          <Select
            placeholder="Выбери категорию"
            options={
              exercisesTypesOptions &&
              exercisesTypesOptions
                .filter(item => item.format === 'GROUP')
                .map(option => ({
                  value: option.value,
                  label: (
                    <Flex align="center" gap={6}>
                      <div>
                        <TeamOutlined style={{ fontSize: 16, color: '#000' }} />
                      </div>
                      <Typography>{option.label}</Typography>
                    </Flex>
                  ),
                }))
            }
            mode="multiple"
            showSearch={true}
            filterOption={(input: string, option) =>
              option && typeof option.label === 'string'
                ? // @ts-ignore
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                : false
            }
            dropdownClassName="SelectRecordType"
          />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitText}
          </Button>
        </Form.Item>
      </Col>
    </Form>
  )
}

import * as React from 'react'

import { formatDateToHumanize } from '../../../../format/date.format'
import { TableCellTooltip } from '../../../table-cells/table-cell-tooltip/table-cell-tooltip.component'

interface Props {
  purchaseDate: string
  id: string
}

export const ClientsSubscriptionsTablePurchase: React.FC<Props> = props => {
  const { purchaseDate, id } = props

  const text = `${formatDateToHumanize(purchaseDate)}`

  return <TableCellTooltip title={id} text={text} />
}

import { put, takeLatest } from 'redux-saga/effects'

import { isDef } from '../../../../types/lang.types'
import { api } from '../../../../api/api'
import { callApi } from '../../../../utils/sagas.utils'
import { transactionsCreatePageTableActions } from './transactions-create-page-table.slice'

export function* changeTrial(action: ReturnType<typeof transactionsCreatePageTableActions.changeTrial>) {
  try {
    if (isDef(action.payload)) {
      const response: Awaited<ReturnType<typeof api.clientsService.fetchById>> = yield callApi(
        api.clientsService.fetchById,
        action.payload
      )

      const { trialUsed } = response.data

      yield put(transactionsCreatePageTableActions.changeTrialSuccess(trialUsed))
    } else {
      yield put(transactionsCreatePageTableActions.changeTrialSuccess(true))
    }
  } catch (e) {
    yield put(transactionsCreatePageTableActions.changeTrialError(new Error()))
  }
}

export function* transactionsCreatePageTableSagas() {
  yield takeLatest(transactionsCreatePageTableActions.changeTrial, changeTrial)
}

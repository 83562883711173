import React from 'react'

import { useMasterServicesEditPageForm } from './master-services-edit-page-form.hook'
import { MasterServicesForm } from '../../../components/master-services/master-services-form/master-services-form.component'

export const MasterServicesEditPageForm = () => {
  const {
    form,
    isLoading,
    trainers,
    positions,
    selectedPricing,
    exercisesTypesOptions,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    pricingOptions,
    positionsOptions,
    serviceCategoriesOptions,
    timeStepMinutesOptions,
    onAddServiceCategoryHandler,
    onAddTimeStepHandler,
    onSaveSubServiceHandler,
    onRemoveSubServiceHandler,
    onCopyPricingHandler,
    onRemovePricingHandler,
    onSavePricingHandler,
    onSelectPricingHandler,
    onClosePricingModalHandler,
    onFinishHandler,
  } = useMasterServicesEditPageForm()

  return (
    <MasterServicesForm
      isEdit
      form={form}
      isLoading={isLoading}
      trainers={trainers}
      positions={positions}
      selectedPricing={selectedPricing}
      exercisesTypesOptions={exercisesTypesOptions}
      trainersOptions={trainersOptions}
      studiosOptions={studiosOptions}
      studiosRoomsOptions={studiosRoomsOptions}
      pricingOptions={pricingOptions}
      positionsOptions={positionsOptions}
      serviceCategoriesOptions={serviceCategoriesOptions}
      timeStepMinutesOptions={timeStepMinutesOptions}
      onAddServiceCategory={onAddServiceCategoryHandler}
      onAddTimeStepHandler={onAddTimeStepHandler}
      onSaveSubServiceHandler={onSaveSubServiceHandler}
      onRemoveSubServiceHandler={onRemoveSubServiceHandler}
      onCopyPricingHandler={onCopyPricingHandler}
      onRemovePricingHandler={onRemovePricingHandler}
      onSavePricingHandler={onSavePricingHandler}
      onSelectPricingHandler={onSelectPricingHandler}
      onClosePricingModalHandler={onClosePricingModalHandler}
      onFinish={onFinishHandler}
    />
  )
}

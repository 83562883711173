import { Button, Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import * as React from 'react'
import { CustomFieldsSettingsGenInput } from '@components/custom-fields-settings/custom-fields-settings.component'
import { CustomFieldsApi } from '@api/types/custom-fields-api.types'
import { useCustomFieldsSettings } from '@components/custom-fields-settings/custom-fields-settings.hook'
import { customFieldsSettingsActions } from '@store/common/custom-fields-settings/custom-fields-settings.slice'
import { useDispatch } from 'react-redux'

import { genDefaultDateFormat } from '../../../format/date.format'
import { ClientSex } from '../../../types/clients.types'
import { isDef, NString } from '../../../types/lang.types'
import { ImageUploader } from '../../image-uploader/image-uploader.component'
import { ClientsFormValues, ClientsFormValuesDTO } from './clients-form.types'
import {
  ClientsFormValidateDeposit,
  genClientSexOptions,
  genClientsFormPhoneValidationRules,
  genClientsFormValuesDTO,
} from './clients-form.utils'
import { InputPhoneNumber } from '../../controls/input-phone-number/input-phone-number.component'

interface Props {
  form: FormInstance<ClientsFormValues>
  categoriesOptions?: DefaultOptionType[]
  isLoading?: boolean
  isSaving?: boolean
  onSubmit: (values: ClientsFormValuesDTO) => void
  customFields?: CustomFieldsApi.CustomField[] | null
}

export const ClientsForm: React.FC<Props> = props => {
  // SECTION: Props
  const { form, categoriesOptions, onSubmit, customFields } = props
  const { isLoading = false, isSaving = false } = props

  // SECTION: Hooks
  const photo = Form.useWatch('photo', form)
  const dispatch = useDispatch()

  // SECTION: Custom hooks
  const {
    handleInputChange,
    formValues,
    setFormValues,
    hasEmptyRequiredField,
    customFieldsWithValue,
    findEmptyRequiredFields,
  } = useCustomFieldsSettings({
    customFields: customFields || null,
  })

  // SECTION: Computed
  const clientsSexOptions = React.useMemo(genClientSexOptions, [])

  // SECTION: Actions
  const onChangePhoneHandler = (value: string) => {
    form.setFieldValue('phone', value)
  }

  const onChangeImageHandler = React.useCallback(
    (photo: NString): void => {
      if (isDef(photo)) {
        const formValues = form.getFieldsValue()

        form.setFieldsValue({
          ...formValues,
          photo,
        })
      }
    },
    [form]
  )

  const onFinishHandler = (values: ClientsFormValues): void => {
    if (hasEmptyRequiredField) {
      const errorFields = findEmptyRequiredFields(formValues)
      Object.entries(errorFields).forEach(([name, fieldErrors]) => {
        form.setFields([{ name, errors: fieldErrors.errors }])
      })
    } else {
      const clientsFormValuesDTO = genClientsFormValuesDTO(values)
      onSubmit(clientsFormValuesDTO)
      dispatch(customFieldsSettingsActions.setCustomFields([...customFieldsWithValue]))
    }
  }

  // SECTION: Render
  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinishHandler}
      disabled={isLoading}
      autoComplete="off"
      layout="vertical"
      initialValues={{
        sex: ClientSex.U,
        deposit: 0,
      }}
    >
      <Col>
        <Form.Item name="photo" className="avatar_item">
          <ImageUploader image={photo} onChange={onChangeImageHandler} disabled={isLoading || isSaving} />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item label="Фамилия" name="lastName" rules={[{ required: true, message: 'Введите фамилию' }]}>
          <Input placeholder="Фамилия" />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item label="Имя" name="firstName" rules={[{ required: true, message: 'Введите имя' }]}>
          <Input placeholder="Имя" />
        </Form.Item>
      </Col>
      <Col style={{ paddingTop: 15 }}>
        <Form.Item label="Отчество" name="middleName">
          <Input placeholder="Отчество" />
        </Form.Item>
      </Col>
      <Row justify="start" gutter={16} style={{ paddingTop: 15 }}>
        <Col span={8}>
          <Form.Item label="Дата рождения" name="birthDate">
            <DatePicker style={{ width: '100%' }} placeholder="Дата рождения" format={genDefaultDateFormat()} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Пол" name="sex">
            <Select placeholder="Пол клиента" options={clientsSexOptions} loading={isLoading} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Email" name="email">
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="start" gutter={16} style={{ paddingTop: 15 }}>
        <Col span={8}>
          <Form.Item
            label="Номер телефона"
            name="phone"
            rules={genClientsFormPhoneValidationRules()}
            validateTrigger={['onBlur']}
          >
            <InputPhoneNumber
              value={form.getFieldValue('phone')}
              onChange={onChangePhoneHandler}
              onBlur={() => form.validateFields(['phone'])}
              onFocus={() =>
                form.setFields([
                  {
                    name: 'phone',
                    errors: [],
                  },
                ])
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Номер карты лояльности" name="loyaltyCard">
            <Input placeholder="Номер карты лояльности" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Депозит"
            name="deposit"
            rules={[{ required: false }, { validator: ClientsFormValidateDeposit }]}
          >
            <InputNumber style={{ width: '100%' }} placeholder="Депозит" suffix="₽" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="start" gutter={16} style={{ paddingTop: 15 }}>
        <Col span={12}>
          <Form.Item label="Источник привлечения" name="source">
            <Select placeholder="Источник привлечения" loading={isLoading} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Категория клиента"
            name="clientCategoryId"
            required
            rules={[{ required: true, message: 'Выберите категорию клиента' }]}
          >
            <Select placeholder="Категория" options={categoriesOptions} loading={isLoading} />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{ paddingTop: 15 }} justify="start" gutter={16}>
        <Col span={24}>
          <Form.Item label="Комментарий о клиенте" name="comment">
            <Input.TextArea maxLength={1000} rows={4} placeholder="Комментарий о клиенте" />
          </Form.Item>
        </Col>
      </Row>
      <CustomFieldsSettingsGenInput customFields={customFields} handleInputChange={handleInputChange} />
      <Col style={{ paddingTop: 15 }}>
        <Button htmlType="submit" type="primary" disabled={isLoading || isSaving} loading={isSaving}>
          Сохранить
        </Button>
      </Col>
    </Form>
  )
}

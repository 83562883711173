import {
  Button,
  Col,
  Collapse,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd'
import { CalendarOutlined, DownOutlined, InfoCircleFilled } from '@ant-design/icons'

import { CustomCollapsePanel } from '../../../../custom-collapse-panel/custom-collapse-panel.component'
import { useStudiosFormRoom } from './studios-form-room.hook'
import { StudioRoomScheme, StudiosFormRoomProps } from './studios-form-room.types'
import { CustomFieldsSettingsGenInput } from '../../../../custom-fields-settings/custom-fields-settings.component'
import { ImageUploadGallery } from '../../../../image-upload-gallery/image-upload-gallery'
import { StudiosFormRoomPlaces } from './studios-form-room-places/studios-form-room-places.component'
import { TimeRangePicker } from '../../../../ui/time-range-picker/time-range-picker.component'
import { StudiosFormRoomParts } from './studios-form-room-parts/studios-form-room-parts.component'
import { StudiosFormLocation } from '../../studios-form-location/studios-form-location.component'
import { StudiosFormAmenities } from '../../studios-form-amenities/studios-form-amenities.component'
import { InputSelectColor } from '../../../../controls/input-select-color/input-select-color.component'
import { StudiosFormWorkTimeModal } from '../../studios-form-work-time-modal/studios-form-work-time-modal.component'
import './studios-form-room.styles.less'

export const StudiosFormRoom: React.FC<StudiosFormRoomProps> = props => {
  const {
    isEdit,
    fields,
    field,
    form,
    index,
    activeKeys,
    directionsOptions,
    colorsOptions,
    onCollapseChange,
    onRemoveRoomHandler,
    onSaveRoomHandler,
    remove,
    ...rest
  } = props

  const {
    timeToValidationRules,
    panelHeader,
    photos,
    unifiedTime,
    timeDisabled,
    isModalOpen,
    scheme,
    customFields,
    tags,
    handleCloseTag,
    toggleScheme,
    handleInputChange,
    onOpenModal,
    onModalConfirm,
    onModalCancel,
    onChangeTimeHandler,
    onChangePhotos,
    onEditHandler,
    onRemoveHandler,
    onCancelHandler,
    onSaveHandler,
  } = useStudiosFormRoom({
    form,
    field,
    fields,
    index,
    activeKeys,
    onCollapseChange,
    onRemoveRoomHandler,
    onSaveRoomHandler,
    remove,
  })

  return (
    <CustomCollapsePanel
      {...rest}
      index={`${index}`}
      panelHeader={panelHeader}
      isActive={activeKeys.includes(index.toString())}
      customClassName="studios-form-room"
      onEditHandler={onEditHandler}
      onRemoveHandler={onRemoveHandler}
    >
      <Flex gap="large" vertical>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Название"
              name={[field.name, 'name']}
              rules={[{ required: true, message: 'Введите название зала' }]}
            >
              <Input size="large" placeholder="Название зала" />
            </Form.Item>
          </Col>
          <Col flex="1 1 90px">
            <Form.Item
              label="Количество мест"
              name={[field.name, 'totalCapacity']}
              rules={[{ required: true, message: 'Укажите количество мест' }]}
            >
              <InputNumber size="large" placeholder="Вместимость" min={1} style={{ width: '100%' }} controls={false} />
            </Form.Item>
          </Col>
          <Col flex="2 1 0">
            <Form.Item label="Цвет" name={[field.name, 'color']} rules={[{ required: true, message: 'Выберите цвет' }]}>
              <InputSelectColor
                size="large"
                placeholder="Цвет зала"
                colorsOptions={colorsOptions}
                value={form.getFieldValue(['rooms', field.name, 'color'])}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="О зале" name={[field.name, 'description']}>
              <Input.TextArea size="large" rows={2} placeholder="Описание вашего зала" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start">
          <Col span={24}>
            <Form.Item label="Направления" name={[field.name, 'directionsIds']}>
              <Select size="large" labelInValue mode="multiple" placeholder="Выбрать" options={directionsOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Flex gap="small">
          <Form.Item
            label="Часы работы"
            name={[field.name, 'unifiedTime']}
            rules={!timeDisabled ? timeToValidationRules : undefined}
          >
            <TimeRangePicker times={unifiedTime} disabled={timeDisabled} onChange={onChangeTimeHandler} />
          </Form.Item>
          <Button icon={<CalendarOutlined />} style={{ marginTop: '30px' }} onClick={onOpenModal}>
            Настроить каждый день
          </Button>
          <Form.Item noStyle name={[field.name, 'workTime']}></Form.Item>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex vertical align="center" gap="32px">
          <Radio.Group onChange={toggleScheme} value={scheme} size="large">
            <Radio.Button value={StudioRoomScheme.SINGLE}>Единое пространство</Radio.Button>
            <Radio.Button value={StudioRoomScheme.PLACES}>Выбор места</Radio.Button>
            <Radio.Button value={StudioRoomScheme.PARTS}>Разделить на части</Radio.Button>
          </Radio.Group>
          {scheme === StudioRoomScheme.SINGLE && tags.includes(StudioRoomScheme.SINGLE) && (
            <Tag
              className="studios-form__tag studios-form__tag--room"
              icon={<InfoCircleFilled style={{ fontSize: '16px' }} />}
              color="blue"
              closable
              onClose={() => handleCloseTag(StudioRoomScheme.SINGLE)}
            >
              <p>Ваш зал — единое пространство без каких-либо делений и выбора мест.</p>
            </Tag>
          )}
        </Flex>
        {scheme === StudioRoomScheme.PLACES && (
          <Flex vertical align="center" gap="32px">
            {tags.includes(StudioRoomScheme.PLACES) && (
              <Tag
                className="studios-form__tag studios-form__tag--room"
                icon={<InfoCircleFilled style={{ fontSize: '16px' }} />}
                color="blue"
                closable
                onClose={() => handleCloseTag(StudioRoomScheme.PLACES)}
              >
                <p>Этот вариант позволяет дать выбор места вашим гостям при записи</p>
              </Tag>
            )}
            <StudiosFormRoomPlaces form={form} field={field} />
          </Flex>
        )}
        {scheme === StudioRoomScheme.PARTS && (
          <Flex vertical align="center" gap="32px">
            {tags.includes(StudioRoomScheme.PARTS) && (
              <Tag
                className="studios-form__tag studios-form__tag--room"
                icon={<InfoCircleFilled style={{ fontSize: '16px' }} />}
                color="blue"
                closable
                onClose={() => handleCloseTag(StudioRoomScheme.PARTS)}
              >
                <p>
                  Этот вариант позволяет делить ваш зал на части. Объединяя части вы дадите возможность бронировать не
                  только одну часть, а половину, или даже целый зал
                </p>
              </Tag>
            )}
            <StudiosFormRoomParts form={form} field={field} />
          </Flex>
        )}

        <Divider style={{ margin: 0 }} />
        <Collapse
          size="large"
          items={[
            {
              key: '1',
              label: (
                <Typography.Title level={4} className="studios-form__title">
                  Кастомные параметры
                </Typography.Title>
              ),
              children: (
                <Row>
                  <Col span={12}>
                    <CustomFieldsSettingsGenInput
                      customFields={customFields}
                      handleInputChange={handleInputChange}
                      hideTitle
                      size="large"
                    />
                  </Col>
                </Row>
              ),
            },
          ]}
          className="studios-form-room__collapse"
          expandIcon={({ isActive }) => (
            <DownOutlined style={{ fontSize: '150%', opacity: 0.45 }} rotate={isActive ? -90 : 0} />
          )}
          bordered={false}
        />
        <Collapse
          size="large"
          items={[
            {
              key: '1',
              label: (
                <Typography.Title level={4} className="studios-form__title">
                  Как найти зал?
                </Typography.Title>
              ),
              children: <StudiosFormLocation form={form} field={field} />,
            },
          ]}
          className="studios-form-room__collapse"
          expandIcon={({ isActive }) => (
            <DownOutlined style={{ fontSize: '24px', opacity: 0.45 }} rotate={isActive ? -90 : 0} />
          )}
          bordered={false}
        />
        <Collapse
          size="large"
          items={[
            {
              key: '1',
              label: (
                <Typography.Title level={4} className="studios-form__title">
                  Сервис в зале
                </Typography.Title>
              ),
              children: <StudiosFormAmenities form={form} field={field} />,
            },
          ]}
          className="studios-form-room__collapse"
          expandIcon={({ isActive }) => (
            <DownOutlined style={{ fontSize: '24px', opacity: 0.45 }} rotate={isActive ? -90 : 0} />
          )}
          bordered={false}
        />
        <Divider style={{ margin: 0 }} />
        <Flex gap="middle" vertical>
          <Typography.Title level={4} className="studios-form__title">
            Галерея
          </Typography.Title>
          <Form.Item name={[field.name, 'photos']}>
            <ImageUploadGallery fileList={photos} onChange={onChangePhotos} />
          </Form.Item>
        </Flex>
        <Space size="middle">
          <Button disabled={fields.length === 1} onClick={onCancelHandler}>
            Отменить
          </Button>
          <Button type="primary" onClick={onSaveHandler}>
            Сохранить
          </Button>
        </Space>
      </Flex>
      <StudiosFormWorkTimeModal
        values={form.getFieldValue(['rooms', field.name, 'workTime'])}
        offset={form.getFieldValue('offset')}
        open={isModalOpen}
        onOk={onModalConfirm}
        onCancel={onModalCancel}
      />
    </CustomCollapsePanel>
  )
}

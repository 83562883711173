import { clsx } from 'clsx'
import { Button, Flex, Form, FormInstance, FormListFieldData, Typography } from 'antd'
import { useCallback, useEffect } from 'react'

import { CustomCard } from '../../../../../custom-card/custom-card.component'
import { MasterServicesPricingFormValues } from '../../../master-services-form.types'
import {
  genPricingImpactsAddButtonSubtitle,
  genPricingImpactsAddButtonTitle,
} from './master-services-form-pricing-impacts-add.utils'
import './master-services-form-pricing-impacts-add.styles.less'
import { Theme } from '../../../../../../types/theme.types'

interface Props {
  form: FormInstance<MasterServicesPricingFormValues>
  theme: Theme
  activeKeys: string[]
  fields: FormListFieldData[]
  addImpact: (defaultValue?: any, insertIndex?: number | undefined) => void
  onCollapseChange: (key: string[]) => void
}
export const MasterServicesFormPricingImpactsAdd: React.FC<Props> = props => {
  const { form, theme, activeKeys, fields, addImpact, onCollapseChange } = props

  const conditionTypes = ['TIME', 'GRADE', 'DURATION', 'WITH_TRAINER']

  const priceType = Form.useWatch('priceType', form)
  const priceImpacts = Form.useWatch('priceImpacts', form)

  const handleButtonAddClick = useCallback(
    (type: string) => {
      addImpact({ type: type })
      onCollapseChange([...activeKeys, fields.length.toString()])
    },
    [activeKeys, addImpact, fields.length, onCollapseChange]
  )

  return (
    <Flex vertical gap="10px">
      {conditionTypes
        .filter(type => !priceImpacts?.find(impact => impact.type === type))
        .sort(function (a, b) {
          return priceType === a ? 0 : -1
        })
        .map(type => {
          const title = genPricingImpactsAddButtonTitle(type)
          const subtitle = genPricingImpactsAddButtonSubtitle(type)
          const disabled = priceType === type
          return (
            <CustomCard
              key={type}
              customClassName={clsx(
                `master-services-form-pricing-impacts-add__impact-card master-services-form-pricing-impacts-add__impact-card--${theme}`,
                {
                  'master-services-form-pricing-impacts-add__impact-card--disabled': disabled,
                }
              )}
            >
              <Flex gap="small" justify="space-between" align="center">
                <Flex vertical gap="small">
                  <Typography.Text strong>{title}</Typography.Text>
                  <p style={{ margin: 0 }}>{subtitle}</p>
                </Flex>
                <Button size="small" disabled={disabled} onClick={() => handleButtonAddClick(type)}>
                  Добавить
                </Button>
              </Flex>
            </CustomCard>
          )
        })}
    </Flex>
  )
}

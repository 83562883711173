import { api } from '@api/api'
import { callApi } from '@utils/sagas.utils'
import { put, takeLatest } from 'redux-saga/effects'

import { tillEntityActions } from './till.slice'

export function* fetchAllTills(_: ReturnType<typeof tillEntityActions.fetchAllTills>) {
  try {
    const tills: Awaited<ReturnType<typeof api.tillsService.getAllOnline>> = yield callApi(
      api.tillsService.getAllOnline
    )
    yield put(tillEntityActions.fetchAllTillsSuccess({ tills: tills.data }))
  } catch (e) {
    yield put(tillEntityActions.fetchAllTillsError(new Error()))
  }
}
export function* createTerminal(action: ReturnType<typeof tillEntityActions.createTill>) {
  try {
    const data = action.payload
    yield callApi(api.tillsService.createOnline, { ...data })
    yield put(tillEntityActions.createTillSuccess())
    yield put(tillEntityActions.fetchAllTills())
  } catch (e) {
    console.error(e)
    yield put(tillEntityActions.createTillError(new Error()))
  }
}
export function* updateTerminal(action: ReturnType<typeof tillEntityActions.updateTill>) {
  try {
    const { id, data } = action.payload
    yield callApi(api.tillsService.update, id, data)
    yield put(tillEntityActions.updateTillSuccess())
    yield put(tillEntityActions.fetchAllTills())
  } catch (e) {
    console.error(e)
    yield put(tillEntityActions.updateTillError(new Error()))
  }
}
export function* deleteTerminal(action: ReturnType<typeof tillEntityActions.deleteTill>) {
  try {
    const { id } = action.payload
    yield callApi(api.tillsService.delete, id)
    yield put(tillEntityActions.deleteTillSuccess())
    yield put(tillEntityActions.fetchAllTills())
  } catch (e) {
    console.error(e)
    yield put(tillEntityActions.deleteTillError(new Error()))
  }
}
export function* tillEntitySagas() {
  yield takeLatest(tillEntityActions.fetchAllTills, fetchAllTills)
  yield takeLatest(tillEntityActions.createTill, createTerminal)
  yield takeLatest(tillEntityActions.updateTill, updateTerminal)
  yield takeLatest(tillEntityActions.deleteTill, deleteTerminal)
}

import { ExercisesTypesApi } from '@api/types/exercises-types-api.types'

import { NBoolean, NNumber } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface DirectionsPageParams {
  page?: NNumber
  size?: NNumber
  format?: ExercisesTypesApi.ExerciseTypeFormat
}

export const DIRECTIONS_PAGE_SIZE: number = DEFAULT_PAGE_SIZE

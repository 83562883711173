import { ScheduleHistoryPeek } from '../../../components/schedule/schedule-history-peek/schedule-history-peek.component'
import { useScheduleManagementPagePeekHistory } from './schedule-management-page-peek-history.hook'
import { ScheduleManagementPagePeekHistoryProps } from './schedule-management-page-peek-history.types'

export const ScheduleManagementPagePeekHistory: React.FC<ScheduleManagementPagePeekHistoryProps> = props => {
  const { id, format } = props

  const { scheduleHistory, trainersOptions, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useScheduleManagementPagePeekHistory(id, format)

  return (
    <ScheduleHistoryPeek
      scheduleHistory={scheduleHistory}
      trainers={trainersOptions}
      format={format}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { Form, FormInstance, FormListFieldData } from 'antd'
import { useState, useEffect } from 'react'

import { api } from '../../../../../api/api'
import { isDef } from '../../../../../types/lang.types'
import { StudiosFormTypes } from '../../studios-form-types'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field?: FormListFieldData
  subField: FormListFieldData
}

export function useStudiosFormAmenity(props: Props) {
  const { form, field, subField } = props

  const [imageUrl, setImageUrl] = useState('')

  const iconUrl = Form.useWatch([...(field ? ['rooms', field.name] : []), 'amenities', subField.name, 'iconUrl'], form)

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const onChangeIcon = (info: any) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as any, url => {
        setImageUrl(url)
      })
    }
  }

  const token = api.getAccessToken()

  useEffect(() => {
    if (imageUrl === '' || !isDef(imageUrl)) {
      setImageUrl(form.getFieldValue([...(field ? ['rooms', field.name] : []), 'amenities', subField.name, 'iconUrl']))
    }
  }, [form, field, subField, imageUrl, iconUrl])

  return {
    token,
    imageUrl,
    onChangeIcon,
  }
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genExerciseAuditInternal = (state: AppState) =>
  state.clientsEditPage.clientsEditPagePeekExerciseHistory.exerciseAudit

export const genClientsEditPagePeekExerciseHistoryIsLoading = (state: AppState) =>
  state.clientsEditPage.clientsEditPagePeekExerciseHistory.isLoading

export const genClientsEditPagePeekExerciseHistoryTotalElements = createSelector(
  genExerciseAuditInternal,
  audit => audit?.totalElements
)

export const genClientsEditPagePeekExerciseHistoryExerciseHistory = createSelector(
  genExerciseAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

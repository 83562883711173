import { FranchisesForm } from '../../../components/franchises/franchises-form/franchises-form.component'
import { useFranchisesCreatePageForm } from './franchises-create-page-form.hook'

export const FranchisesCreatePageForm = () => {
  const { form, customersOptions, onFinishHandler, terminals, tills } = useFranchisesCreatePageForm()

  return (
    <FranchisesForm
      form={form}
      tills={tills}
      terminals={terminals}
      customersOptions={customersOptions}
      submitText="Создать компанию"
      onFinish={onFinishHandler}
    />
  )
}

import { Dispatch, useMemo, useState } from 'react'
import { TillsApi } from '@api/types/tills-api.types'

export const useCurrentOfflineTill = (
  currentOfflineTillId: string | null
): [Dispatch<React.SetStateAction<TillsApi.OfflineTillDTO[]>>, TillsApi.OfflineTillDTO | undefined] => {
  const [offlineTills, setOfflineTills] = useState<TillsApi.OfflineTillDTO[]>([])

  const currentOfflineTill = useMemo(
    () => offlineTills.find(till => till.id === currentOfflineTillId),
    [offlineTills, currentOfflineTillId]
  )

  return [setOfflineTills, currentOfflineTill]
}

import { formatNameColorsToHex } from '@utils/colors.utils'

import { IFigureDetails } from '../hour-cell.types'

export const DEFAULT_FIGURE_DETAILS: IFigureDetails = {
  roomName: 'Неизвестная комната',
  studioName: 'Неизвестный студия',
  timeRange: {
    start: '??',
    end: '??',
  },
  color: formatNameColorsToHex('blue', 1),
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'
import { CustomFieldsApi } from '@api/types/custom-fields-api.types'

import { Nullable } from '../../../../types/lang.types'
import { ClientsApi } from '../../../../api/types/clients-api.types'
import {
  ClientsEditPageCommonFetchPageDataSuccessPayload,
  ClientsEditPageCommonUpdateManagerPayload,
} from './clients-edit-page-common.types'
import { Pagination } from '../../../../api/types/api.types'
import { EmployeesApi } from '../../../../api/types/employees-api.types'

export interface ClientsEditPageCommonState {
  client: Nullable<ClientsApi.Client>
  managers: Nullable<Pagination<EmployeesApi.Employee>>
  isLoading: boolean
  isUpdating: boolean
  isLoaded: boolean
  error: Nullable<Error>
  customFields: Nullable<CustomFieldsApi.CustomField[]>
}

const initialState: ClientsEditPageCommonState = {
  client: null,
  managers: null,
  isLoading: false,
  isUpdating: false,
  isLoaded: false,
  error: null,
  customFields: null,
}

export const { actions: clientsEditPageCommonActions, reducer: clientsEditPageCommonReducer } = createSlice({
  name: '@@clients-edit-page-common',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<ClientsEditPageCommonState>, _action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<ClientsEditPageCommonState>,
      action: PayloadAction<ClientsEditPageCommonFetchPageDataSuccessPayload>
    ) => {
      state.client = action.payload.client
      state.managers = action.payload.managers
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<ClientsEditPageCommonState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    updateManager: (
      state: Draft<ClientsEditPageCommonState>,
      _: PayloadAction<ClientsEditPageCommonUpdateManagerPayload>
    ) => {
      state.isUpdating = true
    },
    updateManagerSuccess: (state: Draft<ClientsEditPageCommonState>, action: PayloadAction<string>) => {
      const updatedClient = state.client
        ? {
            ...state.client,
            managerId: action.payload,
          }
        : null

      state.client = updatedClient
      state.isUpdating = false
    },
    updateManagerError: (state: Draft<ClientsEditPageCommonState>, action: PayloadAction<Error>) => {
      state.isUpdating = false
      state.error = action.payload
    },

    reset: () => initialState,
  },
})

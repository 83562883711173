import { FC, useEffect, useMemo, useState } from 'react'
import { Card, Flex, Input, Form, Collapse, Typography, Space, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import './offline-till-card.styles.less'
import { FieldData, IOfflineTillCardProps } from './offline-till-card.types'

export const OfflineTillCard: FC<IOfflineTillCardProps> = ({
  offlineTill,
  handleChange,
  validatorOfflineTillName,
  setHasError,
}) => {
  // Hooks
  const [form] = Form.useForm()

  // State
  const [oldOfflineTillName, setOldOfflineTillName] = useState<string | undefined>(offlineTill?.nameDevice)
  const [status, setStatus] = useState<'Сохранено' | 'Обновлено, не сохранено'>('Сохранено')
  // Constants
  const isFromBD = offlineTill?.isFromBD ?? false

  // Computed values
  const defaultValues = useMemo(
    () => ({
      name: offlineTill?.nameDevice ?? 'Оффлайн касса без имени',
      id: offlineTill.idDevice,
      kktNumber: offlineTill.kktNumber,
    }),
    [offlineTill]
  )

  // Actions
  const onFieldsChange = (_: any, allFields: FieldData[]) => {
    const hasErrors = allFields.some(field => !!field.errors?.length)
    setHasError(hasErrors)
  }

  const handleChangeOfflineTill = () => {
    handleChange({
      idDevice: form.getFieldValue('id'),
      nameDevice: form.getFieldValue('name'),
      kktNumber: form.getFieldValue('kktNumber'),
    })
    setStatus('Обновлено, не сохранено')
  }

  // Lifecycle
  useEffect(() => {
    setOldOfflineTillName(offlineTill?.nameDevice)
  }, [offlineTill])

  useEffect(() => {
    const offlineTillName = form.getFieldValue('name')
    if (oldOfflineTillName === offlineTillName) {
      setStatus('Сохранено')
    }
  }, [offlineTill, oldOfflineTillName, form.getFieldValue('name')])

  const OfflineTillContent = (
    <Form
      onChange={handleChangeOfflineTill}
      form={form}
      className="offline-till-card__form"
      initialValues={defaultValues}
      onFieldsChange={onFieldsChange}
    >
      {isFromBD && <p className="offline-till-card__status">{status}</p>}
      <Flex vertical gap="middle">
        <Form.Item
          className="offline-till-card__form-item"
          label="Названия места где стоит касса"
          name="name"
          required
          rules={[{ validator: (_, value) => validatorOfflineTillName(value, oldOfflineTillName) }]}
        >
          <Input placeholder="Касса 45858" maxLength={70} />
        </Form.Item>
        <Space size="middle">
          <Button>Переподключить кассу</Button>
          <Button type="dashed" style={{ borderColor: '#0958D9', color: '#0958D9' }}>
            Касса подключена, соединение установлено
          </Button>
        </Space>
        <Form.Item className="offline-till-card__form-item" label="ID кассы" name="id" required>
          <Input placeholder="ID кассы" disabled />
        </Form.Item>
        <Form.Item className="offline-till-card__form-item" label="Номер кассы" name="kktNumber" required>
          <Input placeholder="Номер кассы" disabled />
        </Form.Item>
      </Flex>
    </Form>
  )

  const OfflineTillWrapper = isFromBD ? (
    <Collapse
      className="offline-till-card__collapse"
      items={[
        {
          key: '1',
          label: (
            <Typography.Text strong className="offline-till-card__collapse-title">
              {defaultValues.name}
            </Typography.Text>
          ),
          children: OfflineTillContent,
        },
      ]}
      bordered={false}
      size="middle"
      expandIcon={({ isActive }) => (
        <DownOutlined style={{ fontSize: '150%', opacity: 0.45 }} rotate={isActive ? -90 : 0} />
      )}
    />
  ) : (
    <Card className="offline-till-card">{OfflineTillContent}</Card>
  )

  return OfflineTillWrapper
}

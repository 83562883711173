import { DefaultOptionType } from 'antd/lib/select'

import { OrganizationsApi } from '../api/types/organizations-api.types'
import { FranchisesFormValues } from '../components/franchises/franchises-form/franchises-form.types'
import { FranchisesTableRow } from '../components/franchises/franchises-table/franchises-table.types'
import { formatFormValueToPhoneNumber } from '../format/phone.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'

export function mapFranchisesToFranchisesTableRowList(
  organizations: Nullable<OrganizationsApi.Organization[]>
): Nullable<FranchisesTableRow[]> {
  if (isDefAndNotEmpty(organizations)) {
    return organizations.map(
      (organization: OrganizationsApi.Organization): FranchisesTableRow => ({
        id: organization.id,
        name: organization.name,
        customerName: organization.customerName,
        email: organization.email,
        phone: organization.phone,
        studiosCount: organization.studiosCount,
      })
    )
  }

  return null
}

export function mapFranchisesToCardList(organizations: Nullable<OrganizationsApi.Organization[]>) {
  if (isDefAndNotEmpty(organizations)) {
    return organizations.map((organization: OrganizationsApi.Organization) => ({
      id: organization.id,
      name: organization.name,
      customerName: organization.customerName,
      email: organization.email,
      phone: organization.phone?.replace(/\D/g, ''),
      onlinePayment: organization.onlinePaymentEnabled,
      offlinePayment: organization.offlinePaymentEnabled,
    }))
  }

  return null
}

function getProductTypeTaxationMap(data: FranchisesFormValues, receiptsEnabled: boolean) {
  const defaultValueProductTypeTaxation = { snoType: 'usn_income', vatType: 'NONE' }

  if (receiptsEnabled) {
    return {
      SERVICE: { ...data.SERVICE },
      FULL_PAYMENT_SERVICE: { ...data.FULL_PAYMENT_SERVICE },
      INSTANT_SUB_SERVICE: { ...data.INSTANT_SUB_SERVICE },
      SUBSCRIPTION: { ...data.SUBSCRIPTION },
      ADVANCE_SUB_SERVICE: { ...data.INSTANT_SUB_SERVICE },
      GOODS: { ...data.GOODS },
      COMMISSION: { vatType: '0', snoType: '0' },
    }
  } else {
    return {
      SERVICE: defaultValueProductTypeTaxation,
      FULL_PAYMENT_SERVICE: defaultValueProductTypeTaxation,
      INSTANT_SUB_SERVICE: defaultValueProductTypeTaxation,
      SUBSCRIPTION: defaultValueProductTypeTaxation,
      ADVANCE_SUB_SERVICE: defaultValueProductTypeTaxation,
      GOODS: defaultValueProductTypeTaxation,
      COMMISSION: defaultValueProductTypeTaxation,
    }
  }
}

export function genOrganizationDTO(data: FranchisesFormValues): OrganizationsApi.OrganizationDTO {
  return {
    name: data.name,
    customerName: data.customerName,
    email: data.email,
    phone: formatFormValueToPhoneNumber(data.phone),
    inn: data.inn,
    currency: data.currency,
    country: data.country,
    sellCommission: data.sellCommission ? data.sellCommission * 100 : null,
    refundCommission: data.refundCommission ? data.refundCommission * 100 : null,
    taxation: {
      mainForOnlinePayments: data.mainForOnlinePayments,
      productTypeTaxationMap: getProductTypeTaxationMap(data, data.receiptsEnabled),
    },
    receiptsConfig: {
      paymentAddress: {
        online: data.paymentAddressOnline || '',
        offline: data.paymentAddressOffline || '',
      },
      visitReceiptNamePrefix: data.visitReceiptNamePrefix || '',
    },
    receiptsEnabled: data.receiptsEnabled,
    terminalId: data.terminal || null,
    onlineTillId: data.onlineTill,
    onlinePaymentEnabled: data.onlinePaymentEnabled,
    offlinePaymentEnabled: data.offlinePaymentEnabled,
    onlineTillEnabled: data.onlineTillEnabled,
    offlineTillEnabled: data.offlineTillEnabled,
    offlineTillSupportedPaymentMethodCategories: data.offlineTillSupportedPaymentMethodCategories,
    onlineTillSupportedPaymentMethodCategories: data.onlineTillSupportedPaymentMethodCategories,
    customerType: data.customerType,
  }
}

export function genFranchiseFormValues(data: Nullable<OrganizationsApi.Organization>): Nullable<FranchisesFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      customerName: data.customerName,
      email: data.email || '',
      phone: `+${data.phone?.replace(/\D/g, '')}`,
      inn: data.inn,
      currency: data.currency,
      country: data.country || 'RU',
      customerType: data.customerType,
      mainForOnlinePayments: data.taxation.mainForOnlinePayments,
      isShowComission: data.refundCommission === null && data.sellCommission === null ? false : true,
      // isShowComission: false,
      sellCommission: data.sellCommission ? data.sellCommission / 100 : 0,
      refundCommission: data.refundCommission ? data.refundCommission / 100 : 0,
      onlinePaymentEnabled: data.onlinePaymentEnabled,
      offlinePaymentEnabled: data.offlinePaymentEnabled,
      onlineTillEnabled: data.onlineTillEnabled,
      offlineTillEnabled: data.offlineTillEnabled,
      terminal: data?.terminal?.id || null,
      onlineTill: data?.onlineTill?.id || null,
      receiptsEnabled: data.receiptsEnabled,
      paymentAddressOnline: data.receiptsConfig?.paymentAddress.online || '',
      paymentAddressOffline: data.receiptsConfig?.paymentAddress.offline || '',
      visitReceiptNamePrefix: data.receiptsConfig?.visitReceiptNamePrefix || '',
      onlineTillSupportedPaymentMethodCategories: data.onlineTillSupportedPaymentMethodCategories || [],
      offlineTillSupportedPaymentMethodCategories: data.offlineTillSupportedPaymentMethodCategories || [],
      // taxation
      GOODS: data.taxation.productTypeTaxationMap.GOODS || {},
      SUBSCRIPTION: data.taxation.productTypeTaxationMap.SUBSCRIPTION || {},
      SERVICE: data.taxation.productTypeTaxationMap.SERVICE || {},
      FULL_PAYMENT_SERVICE: data.taxation.productTypeTaxationMap.FULL_PAYMENT_SERVICE || {},
      INSTANT_SUB_SERVICE: data.taxation.productTypeTaxationMap.INSTANT_SUB_SERVICE || {},
    }
  }

  return null
}

export function mapFranchisesToOptions(
  franchises: Nullable<OrganizationsApi.Organization[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(franchises)) {
    return franchises.map(
      (franchise: OrganizationsApi.Organization): DefaultOptionType => ({
        value: franchise.id,
        label: franchise.name,
      })
    )
  }
}

import { Col, Flex } from 'antd'

import { FranchisesPageCard } from '../franchises-page-card/franchises-page-card.component'
import { useFranchisesPageLists } from './franchises-page-lists.hook'

export const FranchisesPageLists = () => {
  const { franchises, isLoading, onEditHandler } = useFranchisesPageLists()
  return (
    <Col style={{ paddingBottom: 20 }}>
      <Flex wrap="wrap" gap={20}>
        {!isLoading &&
          franchises?.map(franchise => (
            <FranchisesPageCard
              key={franchise.id}
              onlinePayment={franchise.onlinePayment}
              offlinePayment={franchise.offlinePayment}
              id={franchise.id}
              onEditHandler={onEditHandler}
              name={franchise.name}
              phone={franchise.phone}
              customerName={franchise.customerName}
            />
          ))}
      </Flex>
    </Col>
  )
}

import { clsx } from 'clsx'
import { FC, useState } from 'react'
import { Flex, Tooltip, Typography } from 'antd'

import { Circle } from '../svg/circle/circle.component'
import { Square } from '../svg/square/square.component'
import { FIGURE_BUTTON_EVENT_TYPE, FIGURE_DIRECTION, FIGURE_MODE, FIGURE_TYPE } from '../../constants/figure'
import { IFigureButtonProps } from './figure-button.types'
import { Nullable } from '../../../../../../types/lang.types'

import './figure-button.styles.less'

export const FigureButton: FC<IFigureButtonProps> = ({ figure, onClick, getIsBlockClick, isMoreOneItem }) => {
  // SECTION: State
  const [visible, setVisible] = useState(false)

  // SECTION: Actions
  const triggerClickEvent = (
    event: Nullable<React.MouseEvent<HTMLButtonElement>>,
    mode: FIGURE_MODE,
    type: FIGURE_BUTTON_EVENT_TYPE,
    direction = FIGURE_DIRECTION.LEFT
  ) => {
    if (!figure.id) return

    onClick?.({
      syntheticEvent: event,
      direction,
      mode,
      type,
      id: figure.id,
    })
  }

  const onFigureClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const isBlockClick = getIsBlockClick?.({ id: figure.id }) ?? false
    if (isBlockClick) return

    switch (figure.mode) {
      case FIGURE_MODE.FULL:
        triggerClickEvent(event, FIGURE_MODE.HALF, FIGURE_BUTTON_EVENT_TYPE.UPDATE, FIGURE_DIRECTION.LEFT)
        break
      case FIGURE_MODE.HALF:
        if (figure.direction === FIGURE_DIRECTION.LEFT) {
          triggerClickEvent(event, FIGURE_MODE.HALF, FIGURE_BUTTON_EVENT_TYPE.UPDATE, FIGURE_DIRECTION.RIGHT)
        } else {
          triggerClickEvent(event, FIGURE_MODE.DELETED, FIGURE_BUTTON_EVENT_TYPE.DELETE, FIGURE_DIRECTION.LEFT)
        }
        break
    }
  }

  // SECTION: Render
  return (
    <Tooltip
      visible={visible}
      placement="top"
      title={
        <Flex vertical justify="center" align="center" className="tooltip-details">
          <Typography.Text className="tooltip-details__text">
            {figure.details.studioName}
            {figure.details.roomName && ','} {figure.details.roomName}
          </Typography.Text>
          <Typography.Text className="tooltip-details__text">
            {`${figure.details.timeRange?.start} - ${figure.details.timeRange?.end}`}
          </Typography.Text>
        </Flex>
      }
      color={figure.details.color}
    >
      <button
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        className={clsx('figure-button', {
          'figure-button--half': figure.mode === FIGURE_MODE.HALF,
          'figure-button--full': figure.mode === FIGURE_MODE.FULL,
        })}
        onClick={onFigureClick}
      >
        {figure.type === FIGURE_TYPE.CIRCLE ? (
          <Circle
            color={figure.color}
            size={isMoreOneItem ? 'small' : 'large'}
            mode={figure.mode}
            direction={figure.direction}
          />
        ) : (
          <Square
            color={figure.color}
            size={isMoreOneItem ? 'small' : 'large'}
            mode={figure.mode}
            direction={figure.direction}
          />
        )}
      </button>
    </Tooltip>
  )
}

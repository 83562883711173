import { useRouteMatch } from 'react-router-dom'

import { StudiosEditPageParams } from '../studios-edit-page.types'

export function useStudioEditPageParams() {
  const match = useRouteMatch<StudiosEditPageParams>()
  const { params } = match
  const { id } = params

  return {
    id,
  }
}

import { Flex, Modal, Spin, Typography } from 'antd'
import { QrReader } from 'react-qr-reader'
import { LoadingOutlined } from '@ant-design/icons'

import { ExercisesGroupQrReaderModalProps } from './exercises-group-qr-reader-modal.types'
import { CheckCircle } from './ui/svg/check-circle.component'
import { CloseCircle } from './ui/svg/close-circle.component'
import { ExclamationCircle } from './ui/svg/exclamation-circle.component'
import { formatPluralized } from '../../../format/text.format'
import './exercises-group-qr-reader-modal.styles.less'
import { useExercisesGroupQrReaderModal } from './exercises-group-qr-reader-modal.hook'

export const ExercisesGroupQrReaderModal: React.FC<ExercisesGroupQrReaderModalProps> = props => {
  const { scheduleId, clientsCount, confirmedCount, onCancel } = props

  const {
    isLoading,
    confirmSuccess,
    notFoundError,
    alreadyRegisteredError,
    qrNotValidError,
    statusText,
    exerciseConfirmedCount,
    exerciseClientsCount,
    handleNext,
    handleScan,
  } = useExercisesGroupQrReaderModal({ scheduleId, clientsCount, confirmedCount })

  return (
    <Modal
      centered
      footer={false}
      open={true}
      onCancel={onCancel}
      className="exercises-group-qr-reader-modal"
      width={'100%'}
    >
      <QrReader
        scanDelay={1000}
        onResult={(result, error) => {
          if (!!result) {
            handleScan(result?.getText())
          }
        }}
        constraints={{ facingMode: 'environment', aspectRatio: { ideal: 1 } }}
        containerStyle={{ width: '100%', height: '100%' }}
        className="exercises-group-qr-reader-modal__qr-reader"
      />
      <Flex vertical align="center" className="exercises-group-qr-reader-modal__wrapper" gap="36px">
        {isLoading ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 69 }} spin />} />
        ) : (
          <>
            <Flex vertical align="center" gap="14px">
              {confirmSuccess && <CheckCircle />}
              {notFoundError && <CloseCircle />}
              {(alreadyRegisteredError || qrNotValidError) && <ExclamationCircle />}
              {statusText && <span className="exercises-group-qr-reader-modal__status-text">{statusText}</span>}
            </Flex>
            <button onClick={() => handleNext()} className="exercises-group-qr-reader-modal__button">
              Следующий
            </button>
          </>
        )}
      </Flex>
      <Typography.Text className="exercises-group-qr-reader-modal__clients-сount">
        {exerciseConfirmedCount} из {formatPluralized(exerciseClientsCount, ['гостя', 'гостей', 'гостей'])}
      </Typography.Text>
    </Modal>
  )
}

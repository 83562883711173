import { FranchisesFormModalTerminalCreate } from './franchises-form-modal-terminal-create/franchises-form-modal-terminal-create.component'
import { FranchisesFormModalTerminalEdit } from './franchises-form-modal-terminal-edit/franchises-form-modal-terminal-edit.component'
import { FranchisesPageModalTerminal } from './franchises-form-modal-terminal.props'

export const FranchisesFormModalTerminal = (props: FranchisesPageModalTerminal) => {
  const { idEditedTerminal } = props
  return (
    <>
      {idEditedTerminal ? (
        <FranchisesFormModalTerminalEdit id={idEditedTerminal} />
      ) : (
        <FranchisesFormModalTerminalCreate />
      )}
    </>
  )
}

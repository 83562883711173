import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genScheduleAuditInternal = (state: AppState) => state.employeesSchedulePeekHistory.scheduleAudit
const genStudiosInternal = (state: AppState) => state.employeesSchedulePeekHistory.studios

export const genEmployeesSchedulePeekHistoryIsLoading = (state: AppState) =>
  state.employeesSchedulePeekHistory.isLoading

export const genEmployeesSchedulePeekHistoryTotalElements = createSelector(
  genScheduleAuditInternal,
  audit => audit?.totalElements
)

export const genEmployeesSchedulePeekHistoryScheduleHistory = createSelector(
  genScheduleAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

export const genEmployeesSchedulePeekHistoryStudiosOptions = createSelector(genStudiosInternal, studios =>
  mapStudiosToOptions(studios?.content)
)

export const genEmployeesSchedulePeekHistoryStudiosRoomsOptions = createSelector(genStudiosInternal, studios =>
  mapStudiosToRoomsOptions(studios?.content)
)

import { clsx } from 'clsx'
import { FC, useMemo, useState } from 'react'
import { Button, Dropdown, Flex } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import './online-till-shift-status.styles.less'

import { IOnlineTillShiftStatusProps } from './online-till-shift-status.types'
import { getAdditionalActionsItems } from './online-till-shift-status.utils'

export const OnlineTillShiftStatus: FC<IOnlineTillShiftStatusProps> = ({ shifts }) => {
  // State
  const [isForceShowOpenActions, setIsForceShowOpenActions] = useState(false)

  const handleOpenDropdown = (open: boolean) => {
    setIsForceShowOpenActions(open)
  }

  // Computed
  const additionalActionsItems = useMemo(() => getAdditionalActionsItems(shifts), [shifts])

  // Render
  return (
    <Flex
      vertical
      justify="center"
      align="center"
      className={clsx(`shift-status`, {
        'shift-status--force-show': isForceShowOpenActions,
      })}
    >
      <p>{isForceShowOpenActions}</p>
      <div className="shift-status__bg" />

      <Flex gap="small">
        <Dropdown menu={{ items: additionalActionsItems }} placement="bottom" onOpenChange={handleOpenDropdown}>
          <Button className="shift-status__button" type="dashed" shape="round">
            <Flex gap="small" justify="between" align="center">
              Действия
              <DownOutlined />
            </Flex>
          </Button>
        </Dropdown>
      </Flex>
    </Flex>
  )
}

import { notification } from 'antd'

export const notificationsXReport = Object.freeze({
  errorWithPrintXReport: () => {
    notification.error({
      message: `Не удалось выгрузить отчёт`,
    })
  },

  successWithPrintXReport: () => {
    notification.success({
      message: `Отчёт выгружен`,
    })
  },
})

import { all, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { employeesSchedulePeekHistoryActions } from './employees-schedule-peek-history.slice'

function* fetchAudit(action: ReturnType<typeof employeesSchedulePeekHistoryActions.fetchAudit>) {
  try {
    const { page, size, id } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const [scheduleAudit, studios]: [
      Awaited<ReturnType<typeof api.auditService.fetchById>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>
    ] = yield all([
      callApi(api.auditService.fetchById, 'schedule', id, { ...params, sort: 'time,desc' }),
      callApi(api.studiosService.fetchAll, {
        size: 100,
      }),
    ])
    yield put(
      employeesSchedulePeekHistoryActions.fetchAuditSuccess({
        scheduleAudit: scheduleAudit.data,
        studios: studios.data,
      })
    )
  } catch (e) {
    yield put(employeesSchedulePeekHistoryActions.fetchAuditError(new Error()))
  }
}

export function* employeesSchedulePeekHistorySagas() {
  yield takeLatest(employeesSchedulePeekHistoryActions.fetchAudit.type, fetchAudit)
}

import { put, select, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { UPDATE_CLIENT_PURCHASES_BROADCAST } from '@constants/broadcast'

import { api } from '../../../../api/api'
import { modalActions } from '../../../common/modal/modal.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { transactionsPageModalRefundActions } from './transactions-page-modal-refund.slice'
import { getCurrentStudioId } from '../../../common/layout/layout.selectors'
import { isDef } from '../../../../types/lang.types'
import { genTransactionsPagePath } from '../../../../format/path.format'

export function* fetchRefundSum(action: ReturnType<typeof transactionsPageModalRefundActions.fetchRefundSum>) {
  try {
    const studioId: ReturnType<typeof getCurrentStudioId> = yield select(getCurrentStudioId)

    const { transactionId } = action.payload

    const response: Awaited<ReturnType<typeof api.transactionsService.fetchRefundSum>> = yield callApi(
      api.transactionsService.fetchRefundSum,
      transactionId
    )

    if (isDef(studioId)) {
      yield put(transactionsPageModalRefundActions.fetchRefundSumSuccess(response.data))
    }
  } catch (e) {
    yield put(transactionsPageModalRefundActions.fetchRefundSumError(new Error()))
  }
}

export function* refundTransaction(action: ReturnType<typeof transactionsPageModalRefundActions.refundTransaction>) {
  try {
    const { transactionId, kkmResponses, fromPage } = action.payload

    const studioId: ReturnType<typeof getCurrentStudioId> = yield select(getCurrentStudioId)

    yield callApi(api.transactionsService.refund, transactionId, kkmResponses)

    yield put(transactionsPageModalRefundActions.refundTransactionSuccess())
    yield put(modalActions.closeLast())
    if (isDef(studioId)) {
      switch (fromPage) {
        case 'transactions':
          yield put(push(genTransactionsPagePath(studioId)))
          break
        case 'clients':
          const bc = new BroadcastChannel(UPDATE_CLIENT_PURCHASES_BROADCAST)
          bc.postMessage(
            JSON.stringify({
              command: 'update',
            })
          )
      }
    }
  } catch (e) {
    yield put(transactionsPageModalRefundActions.refundTransactionError(new Error()))
  }
}

export function* transactionsPageModalRefundSagas() {
  yield takeLatest(transactionsPageModalRefundActions.fetchRefundSum.type, fetchRefundSum)
  yield takeLatest(transactionsPageModalRefundActions.refundTransaction.type, refundTransaction)
}

import * as React from 'react'
import { EmployeesAutocompleteOptionDataItem } from '@components/employees/employees-autocomplete-option/employees-autocomplete-option.types'

import { Autocomplete } from '../../controls/autocomplete/autocomplete.component'
import { ClientsAutocompleteInput } from './positions-autocomplete-input/employees-autocomplete-input.component'
import { genClientsAutocompleteItemToString } from './positions-autocomplete.utils'
import { PositionAutocompleteProps } from './positions-autocomplete.types'
import { PositionsAutocompleteOption } from './positions-autocomplete-option/positions-autocomplete-option.component'

export const PositionAutocomplete: React.FC<PositionAutocompleteProps> = props => {
  const { className, size, placeholder, suffix } = props
  const { value, defaultValue, options } = props
  const { disabled, loading } = props
  const { onInputChange, onChange, onSelect } = props

  const onInputChangeHandler = (value: string): void => {
    onInputChange?.(value)
  }

  return (
    <Autocomplete
      className={className}
      value={value}
      defaultValue={defaultValue}
      options={options as unknown as EmployeesAutocompleteOptionDataItem[]}
      onChange={onChange}
      onInputChange={onInputChangeHandler}
      onSelect={onSelect}
      loading={loading}
      disabled={disabled}
      itemToStringFn={genClientsAutocompleteItemToString}
      clearInputAfterSelect={false}
      InputComponent={ClientsAutocompleteInput}
      OptionComponent={PositionsAutocompleteOption}
      size={size}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

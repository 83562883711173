import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { PositionsPageTable } from './positions-page-table/positions-page-table.component'
import { PositionsPageTopBar } from './positions-page-top-bar/positions-page-top-bar.component'
import { usePositionsPage } from './positions-page.hook'

export const PositionsPage: React.FC = () => {
  const { isLoaded, isLoading } = usePositionsPage()

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <PositionsPageTopBar />
      <PositionsPageTable />
    </>
  )
}

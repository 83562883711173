import { Button } from 'antd'
import * as React from 'react'

import { useTransactionsPageFilter } from './transactions-page-filter.hook'
import './transactions-page-filter.styles.less'
import { ITransactionsPageFilterProps } from './transactions-page-filter.types'

export const TransactionsPageFilter: React.FC<ITransactionsPageFilterProps> = () => {
  const { onCreateHandler } = useTransactionsPageFilter()

  return (
    <div className="transactions-page-filter">
      <Button className="transactions-page-filter__create" type="primary" onClick={onCreateHandler}>
        Создать транзакцию
      </Button>
    </div>
  )
}

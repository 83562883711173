import { FC } from 'react'
import { Button, Flex, Modal, Typography } from 'antd'
import { formatRublesPenniesCurrency } from '@format/number.format'
import OneClickButton from '@components/ui/one-click-button/one-click-button.component'

import { TransactionsConfirmModalProps } from './transactions-confirm-modal.types'

import './transactions-confirm-modal.styles.less'

export const TransactionsConfirmModal: FC<TransactionsConfirmModalProps> = props => {
  const { isLoading, transactionId, transactionTotal } = props
  const { onCancel, onSubmit } = props

  const title = `Подтверждение оплаты`
  const priceTitle = `на сумму `
  const price = `${formatRublesPenniesCurrency(transactionTotal)}`
  const description = `Подтвердите оплату покупки`

  const onSubmitHandler = () => onSubmit(transactionId)

  return (
    <Modal open={true} width={520} title={null} footer={null} onCancel={onCancel}>
      <Flex gap="middle" vertical justify="center" align="center" className="transactions-confirm-modal">
        <Typography.Title level={3} className="transactions-confirm-modal__title">
          {title} <br />
          <div>
            {priceTitle}
            <span className="transactions-confirm-modal__title-price">{price}</span>
          </div>
        </Typography.Title>
        <Typography.Text className="transactions-confirm-modal__description ">{description}</Typography.Text>

        <Flex gap="middle">
          <Button onClick={onCancel} loading={isLoading} type="default">
            Отменить
          </Button>
          <OneClickButton onClick={onSubmitHandler} loading={isLoading} type="primary">
            Принять
          </OneClickButton>
        </Flex>
      </Flex>
    </Modal>
  )
}

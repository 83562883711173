import { Form } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ProductsSubscriptionsFormValue } from '../../../components/products/products-subscriptions-form/products-subscriptions-form.types'
import {
  genProductsSubscriptionsEditPageIsLoading,
  getDirectionsOptions,
  getExercisesOptions,
  getProductsServicesFormValues,
  getStudiosOptions,
} from '../../../store/pages/products-subscriptions-edit-page/products-subscriptions-edit-page.selectors'
import { productSubscriptionsEditPageActions } from '../../../store/pages/products-subscriptions-edit-page/products-subscriptions-edit-page.slice'
import { isDef } from '../../../types/lang.types'
import { useSubscriptionsEditPageParams } from '../products-subscriptions-edit-page-hooks/products-subscriptions-edit-page.hook'

export function useProductServicesEditPageForm() {
  const { type, id } = useSubscriptionsEditPageParams()

  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const isLoading = useSelector(genProductsSubscriptionsEditPageIsLoading)
  const subscriptionsFormValues = useSelector(getProductsServicesFormValues)
  const directionsOptions = useSelector(getDirectionsOptions)
  const studiosOptions = useSelector(getStudiosOptions)
  const exercisesOptions = useSelector(getExercisesOptions)

  const freezingValue = subscriptionsFormValues?.freezingDays
  const activationDaysValue = subscriptionsFormValues?.activationDays
  const onFinishHandler = React.useCallback(
    (values: ProductsSubscriptionsFormValue): void => {
      const dataForm = {
        ...values,
        timeLimination: values.timeLimitation,
        hasTypeLimitation: true,
        hasDirectionLimitation: values.availableDirections?.length > 0 ? true : false,
        hasStudioLimitation: values.availableStudios?.length > 0 ? true : false,
      }
      if (isDef(id)) {
        dispatch(productSubscriptionsEditPageActions.updateSubscription({ id, data: { ...dataForm, type } }))
      }
    },
    [dispatch, id, type]
  )

  React.useEffect(() => {
    if (isDef(subscriptionsFormValues)) {
      form.setFieldsValue(subscriptionsFormValues)
    }
  }, [form, subscriptionsFormValues])

  return {
    form,
    isLoading,
    directionsOptions,
    studiosOptions,
    exercisesOptions,
    onFinishHandler,
    freezingValue,
    activationDaysValue,
  }
}

import React, { ReactNode } from 'react'
import { ConfigProvider, theme } from 'antd'
import ruRu from 'antd/lib/locale/ru_RU'

import { store } from '../store/app.store'
import { layoutActions } from '../store/common/layout/layout.slice'
import './app-root.styles.less'
import { useTheme } from '../hooks/use-theme.hook'

store.dispatch(layoutActions.initializeTheme())

export const AppUI: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { theme: appTheme } = useTheme()

  return (
    <ConfigProvider
      locale={ruRu}
      theme={{
        algorithm: appTheme === 'light' ? theme.defaultAlgorithm : theme.darkAlgorithm,
      }}
    >
      {children}
    </ConfigProvider>
  )
}

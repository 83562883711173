import { type IAPIHelpers } from './types'

const APIHelpers: IAPIHelpers = {
  BASE_URL: 'http://localhost:5894',

  REQUEST_PARAMS: {
    crossDomain: true,
    dataType: 'json',
    contentType: 'application/json; charset=UTF-8',
    processData: false,
  },

  requestHandler: async res => {
    if (res.status === 500) throw new Error('Server status 500')

    return res.ok ? await res.json() : await Promise.reject(await res.json())
  },

  requestMutate: async (method, url, body) => {
    const result = await fetch(APIHelpers.BASE_URL + url, {
      method,
      ...APIHelpers.REQUEST_PARAMS,
      body: JSON.stringify(body),
    })

    return await APIHelpers.requestHandler(result)
  },
}

export default Object.freeze(APIHelpers)

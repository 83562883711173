import { FormInstance, Form } from 'antd'
import { useEffect, useState } from 'react'

import { NNumber } from '../../../../../types/lang.types'
import { MasterServicesWidgetTypographyFormValues } from '../../master-services-widget-form-popovers/master-services-widget-form-popover-typography/master-services-widget-form-popover-typography.types'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { DeviceMode } from '../master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'

declare const _smBookingWidget: any

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string[]
  initialValues: MasterServicesWidgetTypographyFormValues
}

export function useMasterServicesWidgetFormUiTypography(props: Props) {
  const { form, element, initialValues } = props
  const [typographyForm] = Form.useForm<MasterServicesWidgetTypographyFormValues>()

  const accentColor = Form.useWatch(['design', 'accentColor'], form)

  const [deviceMode, setDeviceMode] = useState(DeviceMode.DESKTOP)
  const [openPopover, setPopoverOpen] = useState(false)

  const [sliderValues, setSliderValues] = useState({
    desktop: {
      fontSize: initialValues.desktop.fontSize,
      lineHeight: initialValues.desktop.lineHeight,
      letterSpacing: initialValues.desktop.letterSpacing,
      wordSpacing: initialValues.desktop.wordSpacing,
    },
    mobile: {
      fontSize: initialValues.mobile.fontSize,
      lineHeight: initialValues.mobile.lineHeight,
      letterSpacing: initialValues.mobile.letterSpacing,
      wordSpacing: initialValues.mobile.wordSpacing,
    },
  })

  const handleSliderValuesChange = (property: string, mode: keyof typeof sliderValues, value: NNumber) => {
    setSliderValues({
      ...sliderValues,
      [mode]: {
        ...sliderValues[mode],
        [property]: value,
      },
    })

    _smBookingWidget('event', 'setStyles', undefined, [...element, mode, property].slice(1).join('.'), value)
  }

  const hidePopover = () => {
    handlePopoverOpenChange(false)
  }

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      const values = typographyForm.getFieldsValue()

      form.setFieldValue(element, {
        desktop: {
          color: typeof values.desktop.color === 'string' ? values.desktop.color : values.desktop.color?.toHexString(),
          fontSize: sliderValues.desktop.fontSize,
          fontWeight: values.desktop.fontWeight,
          textTransform: values.desktop.textTransform,
          fontStyle: values.desktop.fontStyle,
          textDecoration: values.desktop.textDecoration,
          lineHeight: sliderValues.desktop.lineHeight,
          letterSpacing: sliderValues.desktop.letterSpacing,
          wordSpacing: sliderValues.desktop.wordSpacing,
        },
        mobile: {
          color: typeof values.mobile.color === 'string' ? values.mobile.color : values.mobile.color?.toHexString(),
          fontSize: sliderValues.mobile.fontSize,
          fontWeight: values.mobile.fontWeight,
          textTransform: values.mobile.textTransform,
          fontStyle: values.mobile.fontStyle,
          textDecoration: values.mobile.textDecoration,
          lineHeight: sliderValues.mobile.lineHeight,
          letterSpacing: sliderValues.mobile.letterSpacing,
          wordSpacing: sliderValues.mobile.wordSpacing,
        },
      })
    }
    setPopoverOpen(newOpen)
  }

  useEffect(() => {
    if (
      (element[1] === 'serviceStyles' && element[2] !== 'active' && element[3] === 'priceFontStyles') ||
      (element[1] === 'confirmInputStyles' && element[3] === 'fontStyles')
    ) {
      if (!typographyForm.isFieldTouched(['desktop', 'color'])) {
        typographyForm.setFieldValue(['desktop', 'color'], accentColor)
      }
      if (!typographyForm.isFieldTouched(['mobile', 'color'])) {
        typographyForm.setFieldValue(['mobile', 'color'], accentColor)
      }
    }
  }, [accentColor, element, typographyForm])

  return {
    typographyForm,
    deviceMode,
    sliderValues,
    openPopover,
    setDeviceMode,
    handleSliderValuesChange,
    hidePopover,
    handlePopoverOpenChange,
  }
}

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { studiosPagePeekHistoryActions } from './studios-page-peek-history.slice'

function* fetchAudit(action: ReturnType<typeof studiosPagePeekHistoryActions.fetchAudit>) {
  try {
    const { page, size, id } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      'studio',
      id,
      { ...params, sort: 'time,desc' }
    )
    yield put(studiosPagePeekHistoryActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(studiosPagePeekHistoryActions.fetchAuditError(new Error()))
  }
}

export function* studiosPagePeekHistorySagas() {
  yield takeLatest(studiosPagePeekHistoryActions.fetchAudit.type, fetchAudit)
}

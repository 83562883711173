import * as React from 'react'

import { useMasterServicesPage } from './master-services-page.hook'
import { MasterServicesPageTopBar } from './master-services-page-top-bar/master-services-page-top-bar.component'
import { MasterServicesPageTable } from './master-services-page-table/master-services-page-table.component'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'

export const MasterServicesPage: React.FC = () => {
  const { isLoaded, isLoading } = useMasterServicesPage()

  if (!isLoaded && isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <MasterServicesPageTopBar />
      <MasterServicesPageTable />
    </>
  )
}

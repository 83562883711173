import { FC, useEffect, useMemo, useState } from 'react'
import { Button } from 'antd'
import { CheckOutlined, WarningOutlined } from '@ant-design/icons'

import { IButtonStatusProps } from './async-button.types'

export const AsyncButton: FC<IButtonStatusProps> = ({ onClick = async () => {}, icon, ...props }) => {
  // State
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState<'default' | 'success' | 'error'>('default')

  // Actions
  const handleOnClick = async (event: React.MouseEvent) => {
    if (isLoading) return
    let initialStatus = status

    setIsLoading(true)

    await onClick(event).catch(error => {
      initialStatus = 'error'
    })

    setIsLoading(false)

    if (initialStatus === 'default') {
      initialStatus = 'success'
    }

    setStatus(initialStatus)
  }

  // Life cycles
  useEffect(() => {
    if (status !== 'default') {
      setTimeout(() => setStatus('default'), 500)
    }
  }, [status])

  // Render
  const statusIcon = useMemo(() => {
    if (status !== 'default') {
      switch (status) {
        case 'success':
          return <CheckOutlined />
        case 'error':
          return <WarningOutlined />
      }
    }

    return icon
  }, [status, icon])

  return <Button loading={isLoading} onClick={handleOnClick} icon={statusIcon} {...props} />
}

import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ProductsApi } from '@api/types/products-api.types'

import { api } from '../../../../api/api'
import { transactionsCreatePageModalProductsActions } from './transactions-create-page-modal-products.slice'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { getTransactionsCreatePageModalProductsMapById } from './transactions-create-page-modal-products.selectors'
import { Nullable, isDef } from '../../../../types/lang.types'
import { transactionsCreatePageTableActions } from '../transactions-create-page-table/transactions-create-page-table.slice'
import { mapProductToCountable } from '../../../../mapping/products.mapping'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { getTransactionsCreatePageTablePaymentMethod } from '../transactions-create-page-table/transactions-create-page-table.selectors'
import { PaymentMethod } from '../../../../types/payment.types'

export function* fetchProducts(action: ReturnType<typeof transactionsCreatePageModalProductsActions.fetchProducts>) {
  try {
    const { page, size, name } = action.payload

    const params = genPaginationParamsDTO(page, size)
    const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string

    const response: Awaited<ReturnType<typeof api.productService.fetchAll>> = yield callApi(
      api.productService.fetchAll,
      {
        ...params,
        name,
        studioId,
      }
    )

    yield put(transactionsCreatePageModalProductsActions.fetchProductsSuccess(response.data))
  } catch (e) {
    yield put(transactionsCreatePageModalProductsActions.fetchProductsError(new Error()))
  }
}

export function* handleProduct(action: ReturnType<typeof transactionsCreatePageModalProductsActions.handleProduct>) {
  try {
    const paymentMethod: Nullable<PaymentMethod> = yield select(getTransactionsCreatePageTablePaymentMethod)
    const { product, successCallback } = action.payload

    if (product.productType === 'SUBSCRIPTION') {
      const response: Awaited<ReturnType<typeof api.productsSubscriptionsService.fetchById>> = yield callApi(
        api.productsSubscriptionsService.fetchById,
        product.id
      )
      const countableProduct = mapProductToCountable(
        { ...response.data, onlyUnpaidRecord: product?.onlyUnpaidRecord ?? false },
        paymentMethod,
        product?.bookingIds
      )
      yield put(transactionsCreatePageTableActions.addProduct(countableProduct))
      if (successCallback) successCallback(countableProduct)
    } else if (product.productType === 'SERVICE' || product.productType === 'FULL_PAYMENT_SERVICE') {
      const response: Awaited<ReturnType<typeof api.productsServicesService.fetchById>> = yield callApi(
        api.productsServicesService.fetchById,
        product.id
      )
      const countableProduct = mapProductToCountable(
        { ...response.data, onlyUnpaidRecord: product?.onlyUnpaidRecord ?? false },
        paymentMethod,
        product?.bookingIds
      )
      yield put(transactionsCreatePageTableActions.addProduct(countableProduct))
      if (successCallback) successCallback(countableProduct)
    } else if (product.productType === 'GOODS') {
      const response: Awaited<ReturnType<typeof api.productsGoodsService.fetchById>> = yield callApi(
        api.productsGoodsService.fetchById,
        product.id
      )
      const countableProduct = mapProductToCountable(
        { ...response.data, onlyUnpaidRecord: product?.onlyUnpaidRecord ?? false },
        paymentMethod,
        product?.bookingIds
      )
      yield put(transactionsCreatePageTableActions.addProduct(countableProduct))
      if (successCallback) successCallback(countableProduct)
    } else {
      const countableProduct = mapProductToCountable(product, paymentMethod, product?.bookingIds)
      yield put(transactionsCreatePageTableActions.addProduct(countableProduct))
      if (successCallback) successCallback(countableProduct)
    }
  } catch (e) {
    console.error(e)
  }
}

export function* addProduct(action: ReturnType<typeof transactionsCreatePageModalProductsActions.addProduct>) {
  const productsMap: ReturnType<typeof getTransactionsCreatePageModalProductsMapById> = yield select(
    getTransactionsCreatePageModalProductsMapById
  )

  try {
    if (isDef(productsMap)) {
      const product = productsMap[action.payload]

      if (isDef(product)) {
        yield put(transactionsCreatePageModalProductsActions.handleProduct({ product: product }))
      }
    }
    yield put(modalActions.closeLast())
  } catch (e) {
    console.error(e)
  }
}

export function* transactionsCreatePageModalProductsSagas() {
  yield takeLatest(transactionsCreatePageModalProductsActions.fetchProducts.type, fetchProducts)
  yield takeLatest(transactionsCreatePageModalProductsActions.addProduct.type, addProduct)
  yield takeLatest(transactionsCreatePageModalProductsActions.handleProduct.type, handleProduct)
}

import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { Pagination } from '../types/api.types'

export class GoodsConsignmentNotesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public create = (data: any): Promise<AxiosResponse<any>> => {
    return this.httpConnector.post<any>('/products/goods/consignment-notes', data)
  }

  public fetchAll = (params: any): Promise<AxiosResponse<Pagination<any>>> => {
    return this.httpConnector.get<Pagination<any>>('/products/goods/consignment-notes', { params })
  }

  public fetchById = (id: string): Promise<AxiosResponse<any>> => {
    return this.httpConnector.get<any>(`/products/goods/consignment-notes/${id}`)
  }

  public update = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return this.httpConnector.patch<any>(`/products/goods/consignment-notes/${id}`, data)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/products/goods/consignment-notes/${id}`)
  }

  public writeOff = (id: string, params: any): Promise<void> => {
    return this.httpConnector.put(`/products/goods/consignments/${id}/writeoff`, null, { params })
  }

  public transfer = (id: string, studioId: string, params: any): Promise<void> => {
    return this.httpConnector.put(`/products/goods/consignments/${id}/transfer/${studioId}`, null, { params })
  }
}

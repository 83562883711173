export function genLinearDoubledList(initialValue: number, amount: number): number[] {
  const result: number[] = []

  for (let i = 0; i < amount; i++) {
    if (i === 0) {
      result.push(initialValue)
    } else {
      result.push(result[i - 1] * 2)
    }
  }

  return result
}

export function genRangeNumbers(start: number, end: number, step: number) {
  return Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), x => start + x * step)
}

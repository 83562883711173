import { PositionAutocomplete } from '@components/positions/positions-autocomplete/positions-autocomplete.component'

import { usePositionsAutocomplete } from './positions-autocomplete.hook'
import { PositionsAutocompleteProps } from './positions-autocomplete.types'

export const PositionsAutocompleteContainer = <Form,>(props: PositionsAutocompleteProps<Form>) => {
  const { className, size, placeholder, suffix } = props
  const { form, name = 'position', disabled, defaultValue } = props
  const { transactionsForm = false } = props
  const { onChange, setPositionId } = props

  const { value, options, loading, onChangeHandler, onSearchHandler, onSelectHandler } = usePositionsAutocomplete({
    name,
    form,
    transactionsForm,
    onChange,
    setPositionId,
  })

  return (
    <PositionAutocomplete
      className={className}
      value={value}
      options={options ?? []}
      defaultValue={defaultValue}
      loading={loading}
      disabled={disabled}
      onChange={onChangeHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
      size={size ?? 'middle'}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

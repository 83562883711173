import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  FormInstance,
  FormListFieldData,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { useState } from 'react'

import { isDefAndNotEmpty } from '../../../../../../types/lang.types'
import { StudiosFormTypes } from '../../../studios-form-types'
import './studios-form-room-parts.styles.less'
import { genCombinePartsOptions } from './studios-form-room-parts.utils'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field: FormListFieldData
}

export const StudiosFormRoomParts: React.FC<Props> = props => {
  const { form, field } = props

  const [parts, setParts] = useState(1)

  const allowCombineParts = Form.useWatch(['rooms', field.name, 'allowCombineParts'], form)
  const combiningParts = Form.useWatch(['rooms', field.name, 'combiningParts'], form)

  const addParts = () => {
    setParts(function (prevCount) {
      return (prevCount += 1)
    })
  }

  const removeParts = () => {
    setParts(function (prevCount) {
      return (prevCount = prevCount - 1)
    })
  }

  const handleAllowCombineParts = (event: CheckboxChangeEvent) => {
    form.setFieldValue(['rooms', field.name, 'allowCombineParts'], event.target.checked)
    form.setFieldValue(['rooms', field.name, 'combiningParts'], event.target.checked ? [{}] : [])
  }

  const handleRemoveCombiningParts = (name: number, remove: (index: number | number[]) => void) => {
    remove(name)
    if (name === 0) {
      form.setFieldValue(['rooms', field.name, 'allowCombineParts'], false)
    }
  }

  return (
    <Flex vertical align="center" justify="center" className="studios-form-room-parts" gap="32px">
      <Flex vertical align="center" gap="middle">
        <Flex gap={16}>
          {[...Array(parts)]
            .map((_, i) => {
              return i + 1
            })
            .map(index => (
              <div key={index} className="studios-form-room-parts__part">
                {index}
              </div>
            ))}
        </Flex>
        <Space size="middle">
          <Button type="primary" ghost onClick={addParts}>
            Добавить часть
          </Button>
          <Button danger disabled={parts <= 1} onClick={removeParts}>
            Удалить часть
          </Button>
        </Space>
        <Form.Item name={[field.name, 'allowCombineParts']} valuePropName="checked">
          <Checkbox disabled={parts <= 1} onChange={handleAllowCombineParts}>
            Разрешить бронирование объединяя части
          </Checkbox>
        </Form.Item>
      </Flex>
      {allowCombineParts && parts > 1 && (
        <Form.List name={[field.name, 'combiningParts']} initialValue={[{}]}>
          {(subFields, { add, remove }) => {
            return (
              isDefAndNotEmpty(combiningParts) && (
                <Flex gap="8px" vertical>
                  <Row gutter={10}>
                    <Col flex="1 1 0px">
                      <Typography.Text>Название объединения</Typography.Text>
                    </Col>
                    <Col flex="1 1 0px">
                      <Typography.Text>Объединять в расписании</Typography.Text>
                    </Col>
                    <Col flex="0 1 74px" />
                  </Row>
                  <Flex gap="large" vertical>
                    {subFields.map(subField => (
                      <Row gutter={10}>
                        <Col flex="1 1 0px">
                          <Form.Item
                            name={[subField.name, 'name']}
                            rules={[{ required: true, message: 'Введите название' }]}
                          >
                            <Input placeholder="Название" style={{ width: '230px' }} />
                          </Form.Item>
                        </Col>
                        <Col flex="1 1 0px">
                          <Form.Item
                            name={[subField.name, 'combining']}
                            rules={[{ required: true, message: 'Выберите объединение' }]}
                          >
                            <Select
                              placeholder="Выбрать"
                              options={genCombinePartsOptions()}
                              style={{ width: '230px' }}
                            />
                          </Form.Item>
                        </Col>
                        <Col flex="1 1 74px">
                          <Space size={10}>
                            <Button
                              danger
                              icon={<DeleteOutlined />}
                              onClick={() => handleRemoveCombiningParts(subField.name, remove)}
                            />
                            {subField.name === combiningParts?.length - 1 && (
                              <Button type="primary" ghost icon={<PlusOutlined />} onClick={add} />
                            )}
                          </Space>
                        </Col>
                      </Row>
                    ))}
                  </Flex>
                </Flex>
              )
            )
          }}
        </Form.List>
      )}
    </Flex>
  )
}

import { useState, useMemo, useEffect } from 'react'

import { api } from '../../../api/api'
import { ExercisesGroupQrReaderModalProps } from './exercises-group-qr-reader-modal.types'

export function useExercisesGroupQrReaderModal(props: ExercisesGroupQrReaderModalProps) {
  const { scheduleId, clientsCount, confirmedCount } = props

  const [exerciseConfirmedCount, setExerciseConfirmedCount] = useState(0)
  const [exerciseClientsCount, setExerciseClientsCount] = useState(0)
  const [pauseScanning, setPauseScanning] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [confirmSuccess, setConfirmSuccess] = useState<boolean>(false)
  const [notFoundError, setNotFoundError] = useState<boolean>(false)
  const [alreadyRegisteredError, setAlreadyRegisteredError] = useState<boolean>(false)
  const [qrNotValidError, setQrNotValidError] = useState<boolean>(false)

  const statusText = useMemo(() => {
    if (confirmSuccess) {
      return 'Гость найден и отмечен как пришедший'
    } else if (notFoundError) {
      return 'Гость не найден в группе'
    } else if (alreadyRegisteredError) {
      return 'Гость уже отмечен'
    } else if (qrNotValidError) {
      return 'Этот QR-код принадлежит к другой записи'
    }
  }, [alreadyRegisteredError, confirmSuccess, notFoundError, qrNotValidError])

  const resetErrors = () => {
    setAlreadyRegisteredError(false)
    setNotFoundError(false)
    setQrNotValidError(false)
  }

  const handleConfirmBooking = async (bookingId: string) => {
    setIsLoading(true)
    try {
      const response = await api.exercisesService.confirmBookingVisiting(scheduleId, bookingId)
      if (response?.data) {
        setConfirmSuccess(true)
        setExerciseConfirmedCount(response.data.confirmedCount)
        setExerciseClientsCount(response.data.clientsCount)
      }
    } catch (error: any) {
      if (error?.response?.status === 409) {
        setAlreadyRegisteredError(true)
      } else if (error?.response?.status === 400) {
        setQrNotValidError(true)
      } else {
        setNotFoundError(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleNext = () => {
    setPauseScanning(false)
    setConfirmSuccess(false)
    resetErrors()
  }

  const handleScan = (text: string) => {
    setPauseScanning(prevState => {
      if (!prevState) {
        handleConfirmBooking(text)
        return true
      }
      return prevState
    })
  }

  useEffect(() => {
    if (clientsCount) {
      setExerciseClientsCount(clientsCount)
    }
    if (confirmedCount) {
      setExerciseConfirmedCount(confirmedCount)
    }
  }, [clientsCount, confirmedCount])

  return {
    isLoading,
    confirmSuccess,
    notFoundError,
    alreadyRegisteredError,
    qrNotValidError,
    statusText,
    exerciseConfirmedCount,
    exerciseClientsCount,
    handleNext,
    handleScan,
  }
}

import { createSlice } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { AppPeekQueueItem } from '../../../types/peek.types'
import { show, close, closeLast, replaceAll } from './peek.utils'

export interface PeekState {
  activePeek: Nullable<AppPeekQueueItem>
  peeksQueue: Nullable<AppPeekQueueItem[]>
}

export const initialState: PeekState = {
  activePeek: null,
  peeksQueue: null,
}

export const { actions: peekActions, reducer: peekReducer } = createSlice({
  name: '@@peek',
  initialState,
  reducers: {
    show,
    close,
    closeLast,
    replaceAll,
  },
})

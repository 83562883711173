import { forwardRef, Ref } from 'react'
import { Row, Typography } from 'antd'
import { formatText } from '@format/text.format'

import './positions-autocomplete-option.styles.less'

import { PositionsAutocompleteOptionProps } from './positions-autocomplete-option.types'

export const PositionsAutocompleteOptionInternal = (
  props: PositionsAutocompleteOptionProps,
  ref: Ref<HTMLLIElement>
) => {
  const { label, details, ...restProps } = props
  const labelText = label.split(',')[0]
  const emailMatch = label.match(/почта:\s*([^,]+)/)
  const email = emailMatch ? emailMatch[1].trim() : ''
  return (
    <li
      className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child clients-autocomplete-option"
      ref={ref}
      {...restProps}
    >
      <Typography className="clients-autocomplete-option__fullName">
        {labelText === 'ФИО не задано' ? 'Имя не задано' : labelText}
      </Typography>
      <Row className="clients-autocomplete-option__footer">
        <Typography className="ant-dropdown-menu-title-content clients-autocomplete-option__phone">
          {formatText(details ?? restProps?.phone)}
        </Typography>
        {email && (
          <>
            <div className="clients-autocomplete-option__circle"></div>
            <Typography className="ant-dropdown-menu-title-content clients-autocomplete-option__email">
              {email}
            </Typography>
          </>
        )}
      </Row>
    </li>
  )
}

export const PositionsAutocompleteOption = forwardRef(
  PositionsAutocompleteOptionInternal
) as typeof PositionsAutocompleteOptionInternal

import { useEffect, useState } from 'react'

interface Props {
  isEdit?: boolean
}

export function useStudiosFormRooms(props: Props) {
  const { isEdit } = props

  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  useEffect(() => {
    if (!isEdit) {
      setActiveKeys(['0'])
    }
  }, [isEdit])

  return {
    activeKeys,
    onCollapseChange,
  }
}

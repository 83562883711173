import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { mapExercisesTypesToExercisesTypesTableRowList } from '../../../mapping/exercises-types.mapping'

const getExercisesTypesInternal = (state: AppState): Nullable<Pagination<ExercisesTypesApi.ExerciseType>> =>
  state.exercisesTypesPage.exercisesTypes

export const genExercisesTypesIsLoading = (state: AppState): boolean => state.exercisesTypesPage.isLoading

export const genExercisesTypesTableRowList = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToExercisesTypesTableRowList(exercisesTypes?.content)
)

export const genExercisesTypesTotalElements = createSelector(
  getExercisesTypesInternal,
  directionsTypes => directionsTypes?.totalElements
)

import { Form } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ServiceFormValues } from '../../../components/products/products-services-form/product-services-form.types'
import {
  getExercisesOptions,
  getStudiosOptions,
} from '../../../store/pages/products-services-create-page/products-services-create-page.selectors'
import { productsServicesCreatePageActions } from '../../../store/pages/products-services-create-page/products-services-create-page.slice'

export function useProductServicesCreatePageForm() {
  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const studiosOptions = useSelector(getStudiosOptions)
  const exercisesOptions = useSelector(getExercisesOptions)
  const onFinishHandler = React.useCallback(
    (values: ServiceFormValues): void => {
      const formData = {
        ...values,
        hasStudioLimitation: values.availableStudios?.length > 0 ? true : false,
        hasTypeLimitation: values.availableTypes?.length > 0 ? true : false,
        hasDirectionLimitation: values.availableDirections?.length > 0 ? true : false,
      }
      dispatch(productsServicesCreatePageActions.createService(formData))
    },
    [dispatch]
  )

  return {
    form,
    onFinishHandler,
    studiosOptions,
    exercisesOptions,
  }
}

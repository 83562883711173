import { AxiosResponse } from 'axios'

import { api } from '../../../../../api/api'
import { KkmAPI } from '../../../../../kkm-api/kkm'
import { IToggleShiftResponse } from '../../../../../kkm-api/types'
import { IOpenShiftInternalResult } from '../../types/utils'
import { closeShiftOnPC } from './close-shift'
import { notificationsShifts } from './notification-shifts'
import { TillsApi } from '../../../../../api/types/tills-api.types'
import { getCurrentUser } from '../user/get-current-user'

export const openShiftOnPC = async (numDevice: number, idDevice: string) => {
  const [currentUserName, currentUserINN] = await getCurrentUser()

  let result: IOpenShiftInternalResult<IToggleShiftResponse> = {
    hasError: false,
    response: null,
  }

  let hasError = false
  result.response = await KkmAPI.openShift(numDevice, idDevice, currentUserName, currentUserINN).catch(err => {
    hasError = true
    console.error(err)
  })

  if (hasError) notificationsShifts.errorOpenShiftOnPC()

  return result
}

const openShiftInDB = async (tillId: string, kkmResponse: IToggleShiftResponse) => {
  let result: IOpenShiftInternalResult<AxiosResponse<TillsApi.IToggleShistResponse, any>> = {
    hasError: false,
    response: null,
  }

  result.response = await api.tillsService.openShift(tillId, kkmResponse).catch(err => {
    result.hasError = true
    console.error(err)
  })

  if (result.hasError) notificationsShifts.errorOpenShiftOnPC()

  return result
}

export const openShift = async (
  tillId: string | null,
  numDevice: number | undefined,
  idDevice: string | undefined,
  successCallback?: (shiftId: string) => void
) => {
  if (!tillId || !numDevice || !idDevice) {
    notificationsShifts.errorOpenShift()
    return
  }

  const kkmResponse = await openShiftOnPC(numDevice, idDevice)

  if (!kkmResponse.hasError && kkmResponse?.response) {
    const result = await openShiftInDB(tillId, kkmResponse.response)
    if (result.hasError || !result?.response?.data?.id) {
      closeShiftOnPC(numDevice, idDevice)
    } else {
      successCallback && successCallback(result?.response?.data?.id)
    }
  }
}

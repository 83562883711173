import * as React from 'react'

import { ExercisesGroupQrReaderModal } from '../../../components/exercises-group/exercises-group-qr-reader-modal/exercises-group-qr-reader-modal.component'
import { useScheduleGroupPageModalQrReader } from './schedule-group-page-modal-qr-reader.hook'
import { ScheduleGroupPageModalQrReaderProps } from './schedule-group-page-modal-qr-reader.types'

export const ScheduleGroupPageModalQrReader: React.FC<ScheduleGroupPageModalQrReaderProps> = props => {
  const { scheduleId } = props

  const { onCancelHandler } = useScheduleGroupPageModalQrReader(scheduleId)

  return <ExercisesGroupQrReaderModal {...props} scheduleId={scheduleId} onCancel={onCancelHandler} />
}

import { useState, ChangeEventHandler, useEffect, useRef } from 'react'

import { CustomFieldsApi } from '../../api/types/custom-fields-api.types'

interface Props {
  customFields: CustomFieldsApi.CustomField[] | null
}

export const useCustomFieldsSettings = ({ customFields }: Props) => {
  const [formValues, setFormValues] = useState<{ [key: string]: { required: boolean; value: string[] } }>({})

  const handleInputChange = (name: string, value: string | string[], isRequired: boolean) => {
    // @ts-ignore
    setFormValues(prevState => ({
      ...prevState,
      [name]: { required: isRequired, value: value },
    }))
  }

  useEffect(() => {
    const initialFormValues: { [key: string]: { required: boolean; value: string[] } } = {}
    customFields?.forEach(item => {
      const name = item.id
      let parsedValue: string[] = []

      if (Array.isArray(item.value) && item.value.length > 0) {
        parsedValue = item.value
      } else if (typeof item.value === 'string' && item.value !== '') {
        // @ts-ignore
        parsedValue = item.value.split('\n')
      } else if (item.attributes && Array.isArray(item.attributes.options) && item.attributes.options.length > 0) {
        // @ts-ignore
        parsedValue = [item.attributes.options[0].id]
      } else if (item.attributes && typeof item.attributes.default === 'string' && item.attributes.default !== '') {
        parsedValue = item.attributes.default.split('\n')
      }

      initialFormValues[name] = { required: item.required || false, value: parsedValue }
    })
    setFormValues(initialFormValues)
  }, [customFields])

  const hasEmptyRequiredField = Object.values(formValues).some(field => {
    if (field.required) {
      if (!field.value || field.value.length === 0) {
        return true
      }
      if (Array.isArray(field.value) && field.value.length === 1 && field.value[0].trim() === '') {
        return true
      }
    }
    return false
  })

  const customFieldsWithValue = Object.entries(formValues).map(([id, { value }]) => ({
    id,
    value: value || [],
  }))

  function findEmptyRequiredFields(formValues: { [key: string]: { required: boolean; value: string[] } }) {
    const errorFields: { [name: string]: { errors: string[] } } = {}

    for (const key in formValues) {
      if (formValues.hasOwnProperty(key) && formValues[key].required) {
        const value = formValues[key].value
        const isEmptyRequiredField =
          !value ||
          (Array.isArray(value) &&
            (value.length === 0 || (value.length === 1 && (value[0] === '' || value[0].trim() === ''))))
        if (isEmptyRequiredField) {
          errorFields[key] = {
            errors: ['Это поле обязательное'],
          }
        }
      }
    }

    return errorFields
  }

  return {
    handleInputChange,
    formValues,
    setFormValues,
    hasEmptyRequiredField,
    customFieldsWithValue,
    findEmptyRequiredFields,
  }
}

import { FC } from 'react'
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons'
import { Collapse, Flex, Tag } from 'antd'
import { TAG_COLORS, TAG_TEXTS, TAG_TYPES } from '@components/employees/schedule/work-time-schedule/constants/tag'
import { StudiosRoomsApi } from '@api/types/studios-rooms-api.types'
import { formatNameColorsToHex } from '@utils/colors.utils'

import { IStudioCollapseProps } from './studio-collapse.types'

import './studio-collapse.styles.less'

export const StudioCollapse: FC<IStudioCollapseProps> = ({
  studio,
  handleCurrentStudio,
  currentTagId,
  activeKeys,
  addActiveKey,
}) => {
  // SECTION: Helpers
  const getTagColor = (room: StudiosRoomsApi.StudioRoom, currentTagId: string | null) => {
    if (!currentTagId) return room.color
    return room.id === currentTagId ? formatNameColorsToHex(room.color) : room.color
  }

  const getTagAllRoomsColor = (currentTagId: string | null) => {
    if (!currentTagId) return TAG_COLORS.ALL_ROOMS
    return currentTagId === studio.id ? TAG_COLORS.ALL_ROOMS_ACTIVE : TAG_COLORS.ALL_ROOMS
  }

  // SECTION: Render
  return (
    <Collapse
      className="studio-collapse"
      ghost
      expandIcon={panelProps => (panelProps.isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />)}
      activeKey={activeKeys ?? undefined}
      onChange={key => addActiveKey(studio.id ?? '')}
    >
      <Collapse.Panel header={studio.name} key={studio.id} className="studio-collapse__panel">
        <Flex gap="small" wrap="wrap" className="studio-collapse__panel-content">
          <Tag
            color={getTagAllRoomsColor(currentTagId)}
            onClick={() => handleCurrentStudio({ tagType: TAG_TYPES.ALL_ROOMS, studio })}
          >
            {TAG_TEXTS.ALL_ROOMS}
          </Tag>
          {studio.rooms &&
            studio.rooms.map(room => (
              <Tag
                key={room.id}
                color={getTagColor(room, currentTagId).toLowerCase()}
                onClick={() => handleCurrentStudio({ studio, room })}
              >
                {room.name}
              </Tag>
            ))}
        </Flex>
      </Collapse.Panel>
    </Collapse>
  )
}

import { Rule } from 'antd/lib/form'

import { isDefAndMoreThenZero } from '../../../types/lang.types'

export function genSalariesFormGradesValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите грейд' }]
}

export function genSalariesFormStudiosValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите студию' }]
}

export function genSalariesFormDirectionsValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите групповое направление' }]
}

export function genSalariesFormExerciseTypesValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите категорию' }]
}

export function genSalariesFormRateValidationRules(): Rule[] {
  return [
    () => ({
      validator(rule, value) {
        if (!isDefAndMoreThenZero(value)) {
          return Promise.reject('Недопустимое значение')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

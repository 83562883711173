import { FormInstance, Space } from 'antd'

import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { ElementStates } from '../master-services-widget-form-design-settings.types'
import {
  genStudioNameFontStyles,
  genStudioAddressFontStyles,
} from './master-services-widget-form-design-settings-studios.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string
  elementState?: string
}

export const MasterServicesWidgetFormDesignSettingsStudios: React.FC<Props> = props => {
  const { form, element, elementState = ElementStates.DEFAULT } = props

  return (
    <Space direction="vertical" size="middle">
      <MasterServicesWidgetFormUiTypography
        form={form}
        title="Типографика названия"
        element={['design', element, elementState, 'nameFontStyles']}
        initialValues={genStudioNameFontStyles()}
      />
      <MasterServicesWidgetFormUiTypography
        form={form}
        title="Типографика адреса"
        element={['design', element, elementState, 'addressFontStyles']}
        initialValues={genStudioAddressFontStyles()}
      />
      <MasterServicesWidgetFormUiShadow form={form} element={['design', element, elementState, 'shadow']} />
      {elementState?.toUpperCase() === ElementStates.HOVER && (
        <MasterServicesWidgetFormUiColor
          label="Цвет затемнения"
          name={['design', element, elementState, 'shadeColor']}
        />
      )}
      <MasterServicesWidgetFormUiBorderRadius element={element} state={elementState} property="borderRadius" />
      <MasterServicesWidgetFormUiBorder element={element} state={elementState} />
    </Space>
  )
}

import * as React from 'react'

import {
  genExercisesGroupBookFromWaitingFormPlaceValidationRules,
  genExercisesGroupBookFromWaitingFormPaymentTypeValidationRules,
} from './exercises-group-book-from-waiting-form.utils'

export function useExercisesGroupBookFromWaitingForm() {
  const placeValidationRules = React.useMemo(genExercisesGroupBookFromWaitingFormPlaceValidationRules, [])
  const paymentTypeValidationRules = React.useMemo(genExercisesGroupBookFromWaitingFormPaymentTypeValidationRules, [])

  return {
    placeValidationRules,
    paymentTypeValidationRules,
  }
}

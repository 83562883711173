import { FormInstance, RadioChangeEvent } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useEffect, useState } from 'react'

import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

declare const _smBookingWidget: any

export function useMasterServicesWidgetFormDesignSettingsGeneral(props: Props) {
  const { form } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isDefaultFont, setIsDefaultFont] = useState(false)

  const [fontFamily, setFontFamily] = useState('')
  const [fontFamilyOptions, setFontFamilyOptions] = useState<DefaultOptionType[] | undefined>()

  const handleFontSelect = (value: string) => {
    setFontFamily(value)
  }

  const toggleDefaultFont = (e: RadioChangeEvent): void => {
    setIsDefaultFont(e.target.value)
    if (e.target.value) {
      _smBookingWidget('event', 'setStyles', undefined, 'fontFamily', 'inherit')
    } else {
      form.setFieldValue(['design', 'fontFamily'], fontFamily)
      _smBookingWidget('event', 'setStyles', undefined, 'fontFamily', fontFamily)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    fetch(
      'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDhsdFdkhqqomS6vxaoHi2ut3Yr7jjaC7k&sort=alpha&capability=WOFF2'
    )
      .then(res => {
        return res.json()
      })
      .then(res => {
        setFontFamilyOptions(
          res.items?.map((font: any) => ({
            label: font.family,
            value: font.family,
          }))
        )
        setFontFamily('Onest')
        form.setFieldValue(['design', 'fontFamily'], 'Onest')
      })
      .finally(() => setIsLoading(false))
  }, [form])

  return {
    isLoading,
    isDefaultFont,
    fontFamilyOptions,
    handleFontSelect,
    toggleDefaultFont,
  }
}

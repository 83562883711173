import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import {
  DirectionsEditPageDataSuccessPayload,
  DirectionsEditPageUpdateDirectionPayload,
} from './directions-edit-page.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { Pagination } from '../../../api/types/api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'

export interface DirectionsEditPageState {
  direction: Nullable<ExercisesDirectionsApi.ExerciseDirection>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: DirectionsEditPageState = {
  direction: null,
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: directionsEditPageActions, reducer: directionsEditPageReducer } = createSlice({
  name: '@@directions-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<DirectionsEditPageState>, _: PayloadAction<number>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<DirectionsEditPageState>,
      action: PayloadAction<DirectionsEditPageDataSuccessPayload>
    ) => {
      const { direction, exercisesTypes } = action.payload
      state.direction = direction
      state.exercisesTypes = exercisesTypes
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<DirectionsEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateDirection: (
      state: Draft<DirectionsEditPageState>,
      _: PayloadAction<DirectionsEditPageUpdateDirectionPayload>
    ) => {
      state.isLoading = true
    },
    updateDirectionSuccess: (state: Draft<DirectionsEditPageState>) => {
      state.isLoading = false
    },
    updateDirectionError: (state: Draft<DirectionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

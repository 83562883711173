import { Divider, FormInstance, Space, Typography } from 'antd'

import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { MasterServicesWidgetFormDesignSettingsTimeslot } from '../master-services-widget-form-design-settings-timeslot/master-services-widget-form-design-settings-timeslot.component'
import {
  genExecutorNameFontStyles,
  genExecutorPositionFontStyles,
  genExecutorFontStyles,
} from './master-services-widget-form-design-settings-executors.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export const MasterServicesWidgetFormDesignSettingsExecutors: React.FC<Props> = props => {
  const { form } = props

  return (
    <MasterServicesWidgetFormUiCollapse title="Блок исполнителя">
      <Space direction="vertical" size="middle">
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика имени"
          element={['design', 'executorBlockStyles', 'nameFontStyles']}
          initialValues={genExecutorNameFontStyles()}
        />
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика должности"
          element={['design', 'executorBlockStyles', 'positionFontStyles']}
          initialValues={genExecutorPositionFontStyles()}
        />
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика текста"
          element={['design', 'executorBlockStyles', 'fontStyles']}
          initialValues={genExecutorFontStyles()}
        />
        <MasterServicesWidgetFormUiColor
          label="Фон основной"
          name={['design', 'executorBlockStyles', 'backgroundColor']}
        />
        <MasterServicesWidgetFormUiColor label="Цвет иконок" name={['design', 'executorBlockStyles', 'iconsColor']} />
        <MasterServicesWidgetFormUiShadow form={form} element={['design', 'executorBlockStyles', 'shadow']} />
        <MasterServicesWidgetFormUiBorderRadius element="executorBlockStyles" property="borderRadius" />
        <MasterServicesWidgetFormUiBorder element="executorBlockStyles" />
        <Divider orientation="left" style={{ margin: 0 }}>
          <Typography.Text type="secondary">Временной слот</Typography.Text>
        </Divider>
        <MasterServicesWidgetFormDesignSettingsTimeslot
          form={form}
          element="timeSlot"
          elementGroup="executorBlockStyles"
        />
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

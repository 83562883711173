import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { api } from '../../../../api/api'
import { genDirectionsPageParams } from '../../../../pages/directions-page/directions-page.utils'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { getRouterState } from '../../../common/router/router.selectors'
import { employeesPageListActions } from './employees-page-list.slice'
import { callApi } from '../../../../utils/sagas.utils'

export function* fetchAllEmployees(action: ReturnType<typeof employeesPageListActions.fetchAllEmployees>) {
  try {
    const { page, size, type } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.employeesService.fetchAll>> = yield callApi(
      api.employeesService.fetchAll,
      params,
      type
    )

    yield put(employeesPageListActions.fetchAllEmployeesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(employeesPageListActions.fetchAllEmployeesError(new Error()))
  }
}

export function* removeEmployee(action: ReturnType<typeof employeesPageListActions.removeEmployee>) {
  try {
    yield callApi(api.employeesService.remove, action.payload)

    yield put(employeesPageListActions.removeEmployeeSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genDirectionsPageParams(search)

    yield put(employeesPageListActions.fetchAllEmployees(params))
  } catch (e) {
    console.error(e)
    yield put(employeesPageListActions.removeEmployeeError(new Error()))
  }
}

export function* employeesPageListSagas() {
  yield takeLatest(employeesPageListActions.fetchAllEmployees, fetchAllEmployees)
  yield takeLatest(employeesPageListActions.removeEmployee, removeEmployee)
}

import * as React from 'react'
import Modal from 'antd/lib/modal/Modal'

import { ModalConfirm } from '../../modals/modal-confirm/modal-confirm.component'
import { TransactionsRefundModalProps } from './transactions-refund-modal.types'
import { isDef } from '../../../types/lang.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { formatRefundTextByPaymentMethod } from '../../../format/text.format'

export const TransactionsRefundModal: React.FC<TransactionsRefundModalProps> = props => {
  const { loading, loaded } = props
  const { onCancel, onRefund } = props
  const { transactionId, refundSum, paymentMethod } = props

  const onRefundHandler = React.useCallback((): void => {
    onRefund(transactionId)
  }, [transactionId, onRefund])

  return (
    <>
      {!loaded && loading ? (
        <Modal />
      ) : (
        <>
          {isDef(refundSum) ? (
            <ModalConfirm
              type="info"
              title="Подтверждение возврата"
              loading={loading}
              description={formatRefundTextByPaymentMethod(paymentMethod, refundSum)}
              onSubmit={onRefundHandler}
              onCancel={onCancel}
            />
          ) : (
            <PageEmpty description="Товары не найдены" />
          )}
        </>
      )}
    </>
  )
}

import { notification } from 'antd'

export const notificationsXReport = Object.freeze({
  errorWithPrintTerminalReport: () => {
    notification.error({
      message: `Не удалось выгрузить отчёт эквайринга`,
    })
  },

  successWithPrintTerminalReport: () => {
    notification.success({
      message: `Отчёт эквайринга выгружен`,
    })
  },
})

import * as React from 'react'
import { Button, Flex, Typography } from 'antd'
import { formatTextToStrikeText } from '@format/text.format'
import { formatRubleCurrency, formatRubleDecimal } from '@format/number.format'

import { NNumber } from '../../../../../types/lang.types'
import { TransactionsProductsModalTableActionsEvents } from './transactions-products-modal-table-actions.types'

interface Props extends TransactionsProductsModalTableActionsEvents {
  productId: string
  price: number
  discountPrice: NNumber
}

const TransactionsProductsModalTableActionsInternal: React.FC<Props> = props => {
  const { productId, onAdd, price, discountPrice } = props

  const onAddHandler = React.useCallback((): void => {
    onAdd(productId)
  }, [onAdd, productId])

  const totalPrice = price
    ? discountPrice
      ? formatTextToStrikeText(formatRubleDecimal(price)) + ' ' + formatRubleCurrency(discountPrice)
      : formatRubleCurrency(price)
    : null

  return (
    <Flex gap="middle" justify="flex-end">
      <Typography.Text style={{ fontWeight: 'bold', margin: '0', fontSize: '16px', whiteSpace: 'nowrap' }}>
        {totalPrice}
      </Typography.Text>
      <Button size="middle" onClick={onAddHandler}>
        Добавить
      </Button>
    </Flex>
  )
}

export const TransactionsProductsModalTableActions = React.memo(TransactionsProductsModalTableActionsInternal)

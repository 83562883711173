import * as React from 'react'
import { FormInstance } from 'antd'

import { ExercisesGroupBookingFormValues } from './exercises-group-booking-form.types'
import {
  genExercisesGroupBookingFormPhoneValidationRules,
  genExercisesGroupBookingFormPlaceValidationRules,
  genExercisesGroupBookingFormPaymentTypeValidationRules,
} from './exercises-group-booking-form.utils'

interface Props {
  form: FormInstance<ExercisesGroupBookingFormValues>
  onSave: (values: ExercisesGroupBookingFormValues) => void
}

export function useExercisesGroupBookingForm(props: Props) {
  const { form, onSave } = props

  const phoneValidationRules = React.useMemo(genExercisesGroupBookingFormPhoneValidationRules, [])
  const placeValidationRules = React.useMemo(genExercisesGroupBookingFormPlaceValidationRules, [])
  const paymentTypeValidationRules = React.useMemo(genExercisesGroupBookingFormPaymentTypeValidationRules, [])

  const onFinishHandler = React.useCallback((): void => {
    const values = form.getFieldsValue()
    onSave(values)
  }, [form, onSave])

  return {
    phoneValidationRules,
    placeValidationRules,
    paymentTypeValidationRules,
    onFinishHandler,
  }
}

import { ExercisesGroupLinkedTable } from '@components/exercises-group/exercises-group-table-linked/exercises-group-linked-table.component'
import { PageEmpty } from '@components/page/page-empty/page-empty.component'

import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { useScheduleGroupPageRecordTable } from './schedule-group-page-table.hook'

export const ScheduleGroupPageRecordTable = () => {
  const { exerciseLinked, isLoading, studioId, scheduleId } = useScheduleGroupPageRecordTable()
  const data = Array.isArray(exerciseLinked) ? exerciseLinked : []

  if (isDefAndNotEmpty(data)) {
    return <ExercisesGroupLinkedTable scheduleId={scheduleId} studioId={studioId} data={data} loading={isLoading} />
  }

  return <PageEmpty description="Нет длинных записей" />
}

import * as React from 'react'

import { EmployeesForm } from '../../../components/employees/employees-form/employees-form.component'
import { useEmployeesEditPageForm } from './employees-edit-page-form.hook'

export const EmployeesEditPageForm = () => {
  const {
    form,
    positionsOptions,
    franchisesOptions,
    gradesOptions,
    directionsOptions,
    exercisesTypesOptions,
    employeePositionType,
    customFields,
    onFinishHandler,
    onChangePositionHandler,
  } = useEmployeesEditPageForm()

  return (
    <EmployeesForm
      submitText="Редактирование пользователя"
      form={form}
      franchisesOptions={franchisesOptions}
      positionsOptions={positionsOptions}
      gradesOptions={gradesOptions}
      directionsOptions={directionsOptions}
      exercisesTypesOptions={exercisesTypesOptions}
      employeePositionType={employeePositionType}
      customFields={customFields}
      onFinish={onFinishHandler}
      onChangePosition={onChangePositionHandler}
    />
  )
}

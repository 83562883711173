import * as React from 'react'
import { Form } from 'antd'
import { SearchApi } from '@api/types/search-api.types'

import { api } from '../../api/api'
import { isDef, Nullable } from '../../types/lang.types'
import { ProductsAutocompleteProps } from './products-autocomplete.types'
import { mapProductsToProductsAutocompleteOptionDataItems } from '../../mapping/products.mapping'
import { AutocompleteOptionDataItem } from '../../components/controls/autocomplete/autocomplete-option/autocomplete-option.types'

export function useProductsAutocomplete<FormValues>(props: ProductsAutocompleteProps<FormValues>) {
  const { form, name, onChange, setProductId } = props

  const value = Form.useWatch(name, form)

  const [products, setProducts] = React.useState<Nullable<SearchApi.SearchItem[]>>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  const options = React.useMemo(() => mapProductsToProductsAutocompleteOptionDataItems(products), [products])

  const onChangeHandler = React.useCallback(
    (value?: string): void => {
      if (onChange) onChange(value)
      form.setFieldValue(name, value)
    },
    [form, name, onChange]
  )

  const onSelectHandler = React.useCallback(
    (option: Nullable<AutocompleteOptionDataItem>): void => {
      if (isDef(option)) {
        form.setFieldValue(name, option.label)

        form.setFieldValue('productId', option.slug)

        if (setProductId) {
          setProductId(option.slug)
        }
      }
    },
    [form, name]
  )

  const onSearchHandler = React.useCallback((value?: string): void => {
    setLoading(true)

    api.productService
      .search(value ?? '')
      .then((response): void => setProducts(response?.data.content ?? []))
      .catch(error => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  return {
    value,
    options,
    loading,
    onChangeHandler,
    onSearchHandler,
    onSelectHandler,
  }
}

import { Divider, Form } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'

import { CategoryForm } from '../components/category-form'

export const EditCategory = (props: any) => {
  const { onFinish, category, isOpen, onCancel } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(category)
  })

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Редактирование категории">
      <Divider />
      <CategoryForm form={form} onFinish={onFinish} />
    </Modal>
  )
}

import { put, takeLatest } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'

import { employeesScheduleActions } from './employees-schedule.slice'
import { employeesScheduleEditActions } from '../employees-schedule-edit/employees-schedule-edit.slice'

export function* fetchAll() {
  try {
    const response: Awaited<ReturnType<typeof api.employeesScheduleService.fetchAll>> = yield callApi(
      api.employeesScheduleService.fetchAll
    )

    yield put(employeesScheduleEditActions.reset())
    yield put(employeesScheduleActions.fetchAllSuccess({ schedules: response?.data?.content ?? [] }))
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleActions.fetchAllError(new Error()))
  }
}

export function* copy(action: ReturnType<typeof employeesScheduleActions.copy>) {
  try {
    const { scheduleId } = action.payload

    yield callApi(api.employeesScheduleService.copy, scheduleId)

    yield put(employeesScheduleActions.copySuccess())
    yield put(employeesScheduleActions.fetchAll())
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleActions.copyError(new Error()))
  }
}

export function* active(action: ReturnType<typeof employeesScheduleActions.active>) {
  try {
    const { scheduleId } = action.payload

    yield callApi(api.employeesScheduleService.active, scheduleId)

    yield put(employeesScheduleActions.activeSuccess())
    yield put(employeesScheduleActions.fetchAll())
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleActions.activeError(new Error()))
  }
}

export function* stop(action: ReturnType<typeof employeesScheduleActions.stop>) {
  try {
    const { scheduleId } = action.payload

    yield callApi(api.employeesScheduleService.stop, scheduleId)

    yield put(employeesScheduleActions.stopSuccess())
    yield put(employeesScheduleActions.fetchAll())
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleActions.stopError(new Error()))
  }
}

export function* deleteItem(action: ReturnType<typeof employeesScheduleActions.delete>) {
  try {
    const { scheduleId } = action.payload

    yield callApi(api.employeesScheduleService.delete, scheduleId)

    yield put(employeesScheduleActions.deleteSuccess())
    yield put(employeesScheduleActions.fetchAll())
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleActions.deleteError(new Error()))
  }
}

export function* employeesScheduleSagas() {
  yield takeLatest(employeesScheduleActions.fetchAll, fetchAll)
  yield takeLatest(employeesScheduleActions.copy, copy)
  yield takeLatest(employeesScheduleActions.delete, deleteItem)
  yield takeLatest(employeesScheduleActions.active, active)
  yield takeLatest(employeesScheduleActions.stop, stop)
}

import { Radio, RadioChangeEvent, Space } from 'antd'
import clsx from 'clsx'
import { Children, cloneElement, isValidElement, useState } from 'react'

import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { ElementStates } from '../master-services-widget-form-design-settings.types'

interface Props {
  title: string
}

export const MasterServicesWidgetFormDesignSettingsElement: React.FC<React.PropsWithChildren<Props>> = props => {
  const { title } = props
  const { children } = props

  const [fieldState, setFieldState] = useState(ElementStates.DEFAULT)

  const onChangeFieldState = (e: RadioChangeEvent): void => {
    setFieldState(e.target.value)
  }

  return (
    <MasterServicesWidgetFormUiCollapse title={title}>
      <Space direction="vertical" size="middle">
        <Radio.Group value={fieldState} onChange={onChangeFieldState}>
          <Radio.Button value={ElementStates.DEFAULT}>Обычный</Radio.Button>
          <Radio.Button value={ElementStates.HOVER}>Наведение</Radio.Button>
        </Radio.Group>
        {getStrEnumValues<ElementStates>(ElementStates).map((state, index) => {
          const elementState = state.toLowerCase()
          const childrenWithProps = Children.map(children, child => {
            if (isValidElement(child)) {
              return cloneElement(child, { elementState })
            }
            return child
          })
          return (
            <Space
              direction="vertical"
              key={index}
              className={clsx({ 'master-services-widget-form-fields_hidden': state !== fieldState })}
              size="middle"
            >
              {childrenWithProps}
            </Space>
          )
        })}
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

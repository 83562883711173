import { DefaultOptionType } from 'antd/es/select'

import { TillsApi } from '../../api/types/tills-api.types'
import { IRowOfflineTill } from '../../kkm-api/types'
import { getOfflineTillEmptyBalance } from './get-offline-till-slices.utils'

export const transformRowOfflineTillsToOfflineTillsDTO = (
  offlineTills: IRowOfflineTill[]
): TillsApi.OfflineTillDTO[] => {
  if (!offlineTills) return []
  const emptyBalance = getOfflineTillEmptyBalance()
  // @ts-ignore
  return offlineTills.map(till => ({
    id: till.IdDevice,
    isChanged: true,
    isFromBD: false,
    login: 'string',
    password: 'string',
    name: 'string',
    numDevice: till.NumDevice,
    idDevice: till.IdDevice,
    onOff: till.OnOff,
    active: till.Active,
    typeDevice: 'FISCAL_REGISTRAR',
    idTypeDevice: till.IdTypeDevice,
    firmwareVersion: till.Firmware_Version,
    ip: till.IP,
    port: Number(till.Port),
    nameDevice: till.NameDevice,
    unitName: till.UnitName,
    kktNumber: till.KktNumber,
    inn: till.INN,
    regNumber: till.RegNumber,
    fnNumber: till.FnNumber,
    innOfd: till.InnOfd,
    nameOrganization: till.NameOrganization,
    addressSettle: till.AddressSettle,
    taxVariant: till.TaxVariant.split(',').map(Number),
    addDate: till.AddDate,
    bsoMode: till.BSOMode,
    serviceMode: till.ServiceMode,
    ofdError: till.OFD_Error,
    ofdNumErrorDoc: till.OFD_NumErrorDoc,
    ofdDateErrorDoc: till.OFD_DateErrorDoc,
    fnDateEnd: till.FN_DateEnd,
    fnMemOverflow: till.FN_MemOverflowl,
    fnIsFiscal: till.FN_IsFiscal,
    paperOver: till.PaperOver,
    ffdVersion: till.FFDVersion,
    ffdVersionFN: till.FFDVersionFN,
    ffdVersionKKT: till.FFDVersionKKT,
    registerCheck: till.IsRegisterCheck,
    tillShiftOpened: false,
    lastTillShift: {
      balance: emptyBalance,
    },
  }))
}

export const transformOfflineTillsFromBDToOfflineTillsDTO = (
  offlineTillsFromBD: TillsApi.OfflineTillBD[]
): TillsApi.OfflineTillDTO[] => {
  if (!offlineTillsFromBD) return []
  return offlineTillsFromBD.map(tills => {
    const emptyBalance = getOfflineTillEmptyBalance()
    const balance = tills.lastTillShift && tills.lastTillShift.balance ? tills.lastTillShift.balance : emptyBalance

    return {
      id: tills.id,
      isFromBD: true,
      login: tills.login,
      password: tills.password,
      name: tills.name,
      studioId: tills.studio.id,
      numDevice: tills.numDevice,
      idDevice: tills.idDevice,
      onOff: tills.onOff,
      active: tills.active,
      typeDevice: tills.typeDevice,
      idTypeDevice: tills.idTypeDevice,
      firmwareVersion: tills.firmwareVersion,
      ip: tills.ip,
      port: tills.port,
      nameDevice: tills.nameDevice,
      unitName: tills.unitName,
      kktNumber: tills.kktNumber,
      inn: tills.inn,
      regNumber: tills.regNumber,
      fnNumber: tills.fnNumber,
      innOfd: tills.innOfd,
      nameOrganization: tills.nameOrganization,
      addressSettle: tills.addressSettle,
      taxVariant: tills.taxVariant,
      addDate: tills.addDate,
      bsoMode: tills.bsoMode,
      serviceMode: tills.serviceMode,
      ofdError: tills.ofdError,
      ofdNumErrorDoc: tills.ofdNumErrorDoc,
      ofdDateErrorDoc: tills.ofdDateErrorDoc,
      fnDateEnd: tills.fnDateEnd,
      fnMemOverflow: tills.fnMemOverflow,
      fnIsFiscal: tills.fnIsFiscal,
      paperOver: tills.paperOver,
      ffdVersion: tills.ffdVersion,
      ffdVersionFN: tills.ffdVersionFN,
      ffdVersionKKT: tills.ffdVersionKKT,
      registerCheck: tills.registerCheck,
      balance: balance,
      tillShiftOpened: tills.tillShiftOpened,
      lastTillShift: tills?.lastTillShift,
    }
  })
}

export const transformOfflineTillsDTOToOptions = (offlineTills: TillsApi.OfflineTillDTO[]): DefaultOptionType[] => {
  let date = new Date()
  let formattedDate = date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long' })
  const offlineTillsOptions = offlineTills.reduce((acc: DefaultOptionType[], till) => {
    if (till.isFromBD) {
      const shiftStatus = till.tillShiftOpened ? 'Смена открыта' : 'Смена закрыта'

      acc.push({
        value: till.id,
        label: `${shiftStatus}, ${formattedDate}, ${till.nameDevice}`,
      })
    }
    return acc
  }, [])

  return offlineTillsOptions
}

import * as React from 'react'
import { Empty } from 'antd'
import { clsx } from 'clsx'

import './page-empty.styles.less'
import { useTheme } from '../../../hooks/use-theme.hook'

interface Props {
  className?: string
  description: string
  children?: React.ReactNode
}

export const PageEmpty: React.FC<Props> = props => {
  const { className } = props
  const { children } = props
  const { description } = props
  const { theme } = useTheme()

  return (
    <Empty
      className={clsx(`page-empty ${theme === 'light' ? 'page-empty-light' : 'page-empty-dark'}`, className)}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={description}
    >
      {children}
    </Empty>
  )
}

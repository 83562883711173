import * as React from 'react'

import { useTransactionsPageModalRefund } from './transactions-page-modal-refund.hook'
import { TransactionsPageModalRefundProps } from './transactions-page-modal-refund.types'
import { TransactionsRefundModal } from '../../../components/transactions/transactions-refund-modal/transactions-refund-modal.component'

export const TransactionsPageModalRefund: React.FC<TransactionsPageModalRefundProps> = props => {
  const { transactionId, paymentMethod, fromPage } = props

  const { refundSum, loading, loaded, onCancelHandler, onRefundHandler } = useTransactionsPageModalRefund({
    transactionId,
    fromPage,
  })

  return (
    <TransactionsRefundModal
      transactionId={transactionId}
      refundSum={refundSum}
      paymentMethod={paymentMethod}
      loading={loading}
      loaded={loaded}
      onCancel={onCancelHandler}
      onRefund={onRefundHandler}
    />
  )
}

import * as React from 'react'
import { Flex } from 'antd'
import { useSelector } from 'react-redux'

import { NNumber } from '../../../../types/lang.types'
import { TableCellPrice } from '../../../table-cells/table-cell-price/table-cell-price.component'
import { getTransactionsCreatePageTableTrial } from '../../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'

import './transactions-products-table-sum.styles.less'

interface Props {
  count: number
  price: number
  discountPrice: NNumber
  discount: number
  trialCost?: number
}

export const TransactionsProductsTableSum: React.FC<Props> = props => {
  const { count, price, discountPrice, discount, trialCost = 0 } = props

  const isTrial = useSelector(getTransactionsCreatePageTableTrial)

  const sum = React.useMemo(() => {
    if (!isTrial && trialCost >= 0) {
      return discountPrice ? (discountPrice - discount) * count : (trialCost / 100 - discount) * count
    }
    return discountPrice ? (discountPrice - discount) * count : (price - discount) * count
  }, [discountPrice, discount, count, price, count, isTrial, trialCost])

  return (
    <Flex vertical className="transactions-products-table-sum">
      <TableCellPrice value={price ?? 0} discountValue={discountPrice ?? 0} postfix="за шт" />
      <TableCellPrice value={sum ?? 0} />
    </Flex>
  )
}

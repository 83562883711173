import React, { FC, MouseEventHandler, useState } from 'react'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'

const OneClickButton: FC<ButtonProps> = ({ onClick, disabled = false, children, ...props }) => {
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false)

  const handleClick: MouseEventHandler<HTMLElement> = event => {
    if (!isButtonDisabled) {
      setButtonDisabled(true)
      setTimeout(() => {
        setButtonDisabled(false)
      }, 1000)
      onClick?.(event)
    }
  }

  return (
    <Button type="primary" onClick={handleClick} disabled={disabled || isButtonDisabled} {...props}>
      {children}
    </Button>
  )
}

export default OneClickButton

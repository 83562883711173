import { FormInstance } from 'antd'

import { MasterServicesWidgetFormValues } from '../master-services-widget-form.types'

export interface MasterServicesWidgetFormDesignSettingsProps {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export enum ElementStates {
  DEFAULT = 'DEFAULT',
  HOVER = 'HOVER',
  ACTIVE = 'ACTIVE',
}

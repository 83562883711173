import { api } from '@api/api'
import { downloadFile } from '@utils/api/download-file.utils'
import { TillsApi } from '@api/types/tills-api.types'

import { Nullable } from '../../../../../types/lang.types'
import { notificationsSellsReport } from './notification-sells-report'

export const sellsReport = async (shift: Nullable<TillsApi.IOfflineTillShift>, formattedDate: string) => {
  if (!shift) return
  if (!shift?.id) return

  const fileName = `Отчёт кассира ${formattedDate} смена ${shift?.sessionNumber ?? '0'}.xlsx`
  const getFile = async () => await api.reportsService.getOfflineSells(String(shift.id))
  await downloadFile(getFile, fileName).catch(err => {
    notificationsSellsReport.errorWithReport()
  })
}

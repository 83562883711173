import { useHistory, useRouteMatch } from 'react-router-dom'

import { EmployeesEditPageParams } from '../employees-edit-page.types'
import { genEmployeesPageParams } from '../../employees-page/employees-page.utils'
import { isDef } from '../../../types/lang.types'

export function useEmployeesEditPageParams() {
  const history = useHistory<{ search: string }>()

  const state = history.location.state
  const pageParams = isDef(state) && genEmployeesPageParams(state.search)
  const type = pageParams ? pageParams.type : null

  const match = useRouteMatch<EmployeesEditPageParams>()
  const { params } = match
  const { id } = params

  return {
    id,
    type,
  }
}

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { masterServicesCreatePageActions } from '../../store/pages/master-services-create-page/master-services-create-page.slice'

export function useMasterServicesCreatePage() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(masterServicesCreatePageActions.fetchPageData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(masterServicesCreatePageActions.reset())
    }
  }, [dispatch])
}

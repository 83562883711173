import { createSelector } from '@reduxjs/toolkit'

import { mapTillsToOptions } from '../../../../mapping/tills.mapping'
import { AppState } from '../../../app.store'

export const getTillsIsLoading = (state: AppState) => state.tillEntity.isLoading
export const getTillsError = (state: AppState) => state.tillEntity.error
const getTillsInternal = (state: AppState) => state.tillEntity.tills

export const getTillsWithOptions = createSelector([getTillsInternal], tills => mapTillsToOptions(tills))

import dayjs from 'dayjs'

import {
  ICurrentRoomSlice,
  ICurrentStudioSlice,
  IWorkTimeScheduleStudio,
} from '../../work-time-schedule/work-time-schedule.types'
import { IFinalScheduleRoom, IFinalScheduleStudio } from '../final-schedule-table.types'
import { TAG_TYPES } from '../../work-time-schedule/constants/tag'

const initializeStudio = (studio: ICurrentStudioSlice): IFinalScheduleStudio => ({
  ...studio,
  rooms: [],
})

const initializeRoom = (room: ICurrentRoomSlice): IFinalScheduleRoom => ({
  color: room.color,
  name: room.name,
  id: room.id,
  workTime: room.workTime,
  figures: [],
})

const buildFigure = (schedule: IWorkTimeScheduleStudio) => {
  return {
    cellId: schedule.cellId,
    day: schedule.day,
    start: schedule.time.start ?? '00:00',
    end: schedule.time.end ?? '23:59',
    id: schedule.id,
  }
}

const reduceFigures = (studios: IFinalScheduleStudio[]): void => {
  studios.forEach(studio =>
    studio.rooms.forEach(room => {
      const newRoomFigures = room.figures
        .sort((a, b) => a.day - b.day || a.start.localeCompare(b.start))
        .reduce((acc, curr, i, self) => {
          const prev = self[i - 1]
          if (i > 0 && curr.start === prev.end && curr.day === prev.day) {
            acc[acc.length - 1].end = curr.end
          } else {
            acc.push(curr)
          }
          return acc
        }, [] as IFinalScheduleRoom['figures'])

      room.figures = newRoomFigures

      return room
    })
  )
}

export const transformScheduleStudioToFinalScheduleStudio = (
  schedulesStudios: IWorkTimeScheduleStudio[]
): IFinalScheduleStudio[] => {
  const studiosMap = new Map<string, IFinalScheduleStudio>()

  schedulesStudios
    .sort((a, b) => {
      return dayjs(a.time.start, 'HH:mm').unix() - dayjs(b.time.start, 'HH:mm').unix()
    })
    .forEach(schedule => {
      let studio = studiosMap.get(schedule.studio.id)

      if (!studio) {
        let studioId = schedule.tagType === TAG_TYPES.ALL_STUDIOS ? TAG_TYPES.ALL_STUDIOS : schedule?.studio?.id

        studio = initializeStudio(schedule.studio)
        studiosMap.set(studioId, studio)
      }

      let room = studio.rooms.find(r => r.id === schedule?.studio?.selectedRoom?.id)
      if (!room && !!schedule?.studio?.selectedRoom) {
        room = initializeRoom(schedule.studio.selectedRoom)
        studio.rooms.push(room)
      }

      room?.figures.push(buildFigure(schedule))
    })

  const studiosArray = Array.from(studiosMap.values())
  reduceFigures(studiosArray)

  return studiosArray
}

export const generateDataSource = (
  finalScheduleStudios: IFinalScheduleStudio[],
  defaultDataSource: { key: string; day: string }[]
): any[] => {
  return defaultDataSource.map(defaultItem => {
    const record: Record<string, any> = { key: defaultItem.key, day: defaultItem.day }

    finalScheduleStudios.forEach(studio => {
      studio.rooms.forEach(room => {
        const figures = room.figures.filter(f => String(f.day) === defaultItem.key)
        const times: string[] = []
        figures.forEach((figure, index) => {
          if (!figure) return
          times.push(`${figure.start}-${figure.end}`)
        })
        record[`room-${room.id}`] = times.join(', ')
      })
    })

    return record
  })
}

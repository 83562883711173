import React, { useState } from 'react'
import { Button, Form, FormInstance, Row } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

import { SalariesApi } from '../../../api/types/salaries-api.types'
import { SalariesFormValues, keysOfSalariesFormValues } from './salaries-form-types'
import { SalariesTable } from '../salaries-table/salaries-table.component'
import { useSalariesForm } from './salaries-form-hook'
import {
  genDeletedSalaryFormValuesDTO,
  genDeselectedSalaryFormValueDTO,
  genSalariesFormValuesDTO,
} from './salaries-form-utils'

interface Props {
  form: FormInstance<SalariesFormValues>
  loading: boolean
  studiosOptions?: DefaultOptionType[]
  directionsOptions?: DefaultOptionType[]
  exercisesTypeOptions?: DefaultOptionType[]
  gradesOptions?: DefaultOptionType[]
  onFinish: (values: SalariesApi.SalaryDTO[]) => void
  onRemove: (salary: SalariesApi.DeletedSalaryDTO[]) => void
  onDeselect: (salary: SalariesApi.DeletedSalaryDTO) => void
}

export const SalariesForm: React.FC<Props> = props => {
  const {
    form,
    loading,
    studiosOptions,
    directionsOptions,
    exercisesTypeOptions,
    gradesOptions,
    onFinish,
    onRemove,
    onDeselect,
  } = props

  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined)

  const onFieldsChangeHandler = React.useCallback(setIsValid, [setIsValid])
  const onFieldsChange = useSalariesForm({ form, onFieldsChangeHandler })

  const onSaveHandler = React.useCallback(() => {
    setEditingIndex(undefined)
    const values = form.getFieldsValue()
    const salasriesFormValuesDTO = genSalariesFormValuesDTO(values)

    onFinish(salasriesFormValuesDTO)
    setIsValid(false)
  }, [form, onFinish])

  const onRemoveHandler = React.useCallback(
    (index: number) => {
      const values = form.getFieldsValue()
      const salasriesFormDeletedValueDTO = genDeletedSalaryFormValuesDTO(values.salaries[index])
      onRemove(salasriesFormDeletedValueDTO)
    },
    [form, onRemove]
  )

  const onDeselectHandler = React.useCallback(
    (value: string | number, index: number, key: keysOfSalariesFormValues) => {
      if (index !== editingIndex) {
        const values = form.getFieldsValue()
        const salasriesFormDeselectedValueDTO = genDeselectedSalaryFormValueDTO(values.salaries[index], key, value)
        onDeselect(salasriesFormDeselectedValueDTO)
      }
    },
    [editingIndex, form, onDeselect]
  )

  return (
    <Form form={form} name="salariesEdit" onFieldsChange={onFieldsChange}>
      <Form.List name="salaries">
        {(salaries, { add, remove }) => (
          <SalariesTable
            editingIndex={editingIndex}
            rates={salaries}
            gradesOptions={gradesOptions}
            studiosOptions={studiosOptions}
            directionsOptions={directionsOptions}
            exercisesTypeOptions={exercisesTypeOptions}
            loading={loading}
            setEditingIndex={setEditingIndex}
            add={add}
            onRemove={onRemoveHandler}
            remove={remove}
            onDeselectHandler={onDeselectHandler}
          />
        )}
      </Form.List>
      <br />
      <Row>
        <Form.Item>
          <Button type="primary" size="middle" htmlType="submit" disabled={!isValid} onClick={onSaveHandler}>
            Сохранить
          </Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { genListPaginationConfig } from '../../../utils/pagination.utils'
import {
  genTransactionsPagePeekHistoryIsLoading,
  genTransactionsPagePeekHistoryTotalElements,
  genTransactionsPagePeekHistoryTransactionHistory,
} from '../../../store/pages/transactions-page/transactions-page-peek-history/transactions-page-peek-history.selectors'
import { transactionsPagePeekHistoryActions } from '../../../store/pages/transactions-page/transactions-page-peek-history/transactions-page-peek-history.slice'

const TRANSACTION_PAGE_PEEK_HISTORY_INITIAL_PAGE: number = 0
const TRANSACTION_PAGE_PEEK_HISTORY_INITIAL_SIZE: number = 25

export function useTransactionsPagePeekHistory(id: string) {
  const dispatch = useDispatch()

  const transactionHistory = useSelector(genTransactionsPagePeekHistoryTransactionHistory)
  const isLoading = useSelector(genTransactionsPagePeekHistoryIsLoading)
  const totalElements = useSelector(genTransactionsPagePeekHistoryTotalElements)

  const [page, setPage] = useState(TRANSACTION_PAGE_PEEK_HISTORY_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(TRANSACTION_PAGE_PEEK_HISTORY_INITIAL_SIZE)

  const pagination = useMemo(
    () => genListPaginationConfig(page, pageSize, totalElements),
    [page, pageSize, totalElements]
  )

  const onChangePageHandler = useCallback((page: number, pageSize: number): void => {
    setPage(page)
    setPageSize(pageSize)
  }, [])

  const onChangePageSizeHandler = useCallback((pageSize: number): void => {
    setPageSize(pageSize)
  }, [])

  useEffect(() => {
    dispatch(
      transactionsPagePeekHistoryActions.fetchAudit({
        id,
        page,
        size: pageSize,
      })
    )
  }, [dispatch, id, page, pageSize])

  useEffect(() => {
    return () => {
      dispatch(transactionsPagePeekHistoryActions.reset())
    }
  }, [dispatch])

  return {
    transactionHistory,
    isLoading,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../../../store/common/modal/modal.slice'
import { AppModalBaseProps } from '../../../../../types/modal.types'
import { employeesScheduleEditActions } from '../../../../../store/pages/employees/employees-schedule-edit/employees-schedule-edit.slice'

export interface EmployeesScheduleEditModalConfirmProps extends AppModalBaseProps {
  scheduleId: string
  dayOffId: string
  removeDayOff: () => void
}

export const EmployeesScheduleEditModalConfirm: React.FC<EmployeesScheduleEditModalConfirmProps> = props => {
  const { scheduleId, dayOffId, removeDayOff } = props

  const dispatch = useDispatch()

  const onSubmitHandler = React.useCallback((): void => {
    dispatch(employeesScheduleEditActions.removeDayOff({ scheduleId, dayOffId }))
    removeDayOff()
  }, [dispatch, scheduleId, dayOffId, removeDayOff])

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return (
    <Modal
      title="Отмена перерыва"
      visible
      onCancel={onCancelHandler}
      footer={[
        <Button key="back" onClick={onCancelHandler}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={onSubmitHandler} icon={<DeleteOutlined />}>
          Удалить
        </Button>,
      ]}
    >
      <p>Вы уверены, что хотите отменить перерыв?</p>
    </Modal>
  )
}

import Keycloak, { KeycloakInitOptions } from 'keycloak-js'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import { API_V1_1_URL } from '../app/app.config'
import {
  removeAccessToken,
  removeRefreshToken,
  removeSelectedStudioId,
  setAccessToken,
  setRefreshToken,
} from '../utils/local-storage.utils'
import { NString } from '../types/lang.types'
import { KeycloakProvider } from './providers/keycloak.provider'
import { HttpConnector } from './connectors/http.connector'
import { ClientsBookingsService } from './services/clients-bookings.service'
import { keycloakProvider } from './keycloak'

export class ApiV1_1 {
  // providers
  private readonly keycloakProvider: KeycloakProvider

  // connectors
  private readonly httpConnectorInternal: HttpConnector

  // services
  public readonly clientsBookingsService: ClientsBookingsService

  constructor() {
    // provider
    this.keycloakProvider = keycloakProvider

    // connectors
    this.httpConnectorInternal = new HttpConnector(this.keycloakProvider, API_V1_1_URL)

    // services
    this.clientsBookingsService = new ClientsBookingsService(this.httpConnectorInternal)
  }

  public getAccessToken = (): NString => {
    return this.keycloakProvider.getToken()
  }

  public getKeycloakInstance = (): Keycloak => {
    return this.keycloakProvider.getInstance()
  }

  public getKeycloakInitOptions = (): KeycloakInitOptions => {
    return this.keycloakProvider.getInitOptions()
  }

  public setTokens = (tokens: AuthClientTokens): void => {
    const { token, refreshToken } = tokens

    setAccessToken(token)
    setRefreshToken(refreshToken)
  }

  public logOut = (): void => {
    removeAccessToken()
    removeRefreshToken()
    removeSelectedStudioId()
    this.httpConnectorInternal.clearToken()
    this.keycloakProvider.logOut()
  }
}

export const apiV1_1 = new ApiV1_1()

import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'

export class ReportsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public getOfflineSells = (shiftId: string): Promise<AxiosResponse<string>> => {
    return this.httpConnector.get(`/reports/accounting/offline/sell?tillShiftId=${shiftId}`)
  }

  public getOfflineRefunds = (shiftId: string): Promise<AxiosResponse<string>> => {
    return this.httpConnector.get(`/reports/accounting/offline/refund?tillShiftId=${shiftId}`)
  }
}

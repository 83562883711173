import { PlusCircleOutlined } from '@ant-design/icons'
import { ExercisesApi } from '@api/types/exercises-api.types'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { Button, Flex, Tag } from 'antd'
import { useHistory } from 'react-router-dom'

interface Props {
  linkedProducts: ExercisesApi.ExerciseLinkedLinkedProducts[]
  phone: string
  clientId: string
}
export const TableCellServices = ({ linkedProducts, phone, clientId }: Props) => {
  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)
  const history = useHistory()

  const handleClick = () => {
    history.push({
      pathname: `/transactions/${studioId}/create`,
      state: { phone, clientId },
    })
  }

  return (
    <Flex style={{ maxWidth: 266, overflow: 'hidden' }}>
      <Flex gap={8} style={{ flexWrap: 'wrap' }}>
        {linkedProducts.length > 0
          ? linkedProducts.map(item => (
              <Tag color="green" style={{ width: 'fit-content', height: 'fit-content' }}>
                {item.name}
              </Tag>
            ))
          : ''}
        <Button size="small" onClick={handleClick} icon={<PlusCircleOutlined />} />
      </Flex>
    </Flex>
  )
}

import { DefaultOptionType } from 'antd/lib/select'
import { presetPalettes } from '@ant-design/colors'

import { Colors } from '../types/colors.types'
import { validateStrEnumValue } from './enum.utils'
import { NString, isDef } from '../types/lang.types'
import { formatColors } from '../format/text.format'

export function genColorsOptions(): DefaultOptionType[] {
  return Object.keys(Colors).reduce<DefaultOptionType[]>((acc: DefaultOptionType[], key: string) => {
    const color = validateStrEnumValue<Colors>(Colors, key)

    if (isDef(color)) {
      acc.push({
        value: color,
        label: formatColors(color),
      })
    }

    return acc
  }, [])
}

export const formatNameColorsToHex = (name: NString, colorAccent: number = 5): string => {
  if (!name) return '#000000'

  const colorName = name.toLowerCase()
  const palette = presetPalettes[colorName]
  return palette ? palette[colorAccent] : name
}

import { Alert, Button, Flex, Form, Input, Modal, Select } from 'antd'
import { FormInstance } from 'antd/lib'

interface Props {
  titleText: string
  btnTitle: string
  onCancel: () => void
  onFinish: (values: any) => void
  onRemoveHandler?: () => void
  form: FormInstance
}
export const FranchisesFormModalTillComponent = ({
  titleText,
  btnTitle,
  onCancel,
  onFinish,
  onRemoveHandler,
  form,
}: Props) => {
  const ofdOptions = [
    {
      label: '1.05',
      value: 'v4',
    },
    {
      label: '1.2',
      value: 'v5',
    },
  ]

  const fiscalServiceProviderOptions = [
    {
      label: 'АТОЛ',
      value: 'ATOL',
    },
    {
      label: 'Бизнес касса',
      value: 'BUSINESS_KASSA',
    },
  ]

  return (
    <Modal title={titleText} onCancel={onCancel} open={true} footer={null}>
      <Form initialValues={{ ofdVersion: 'v5' }} onFinish={onFinish} form={form} autoComplete="off" layout="vertical">
        <Flex
          gap={15}
          style={{
            flexDirection: 'column',
            borderRadius: 6,
          }}
        >
          <Alert closable message="Читайте в нашей базе знаний как подключить онлайн-кассу" type="info" showIcon />
          <Form.Item name="fiscalServiceProvider" rules={[{ required: true }]} label="Сервис обслуживания онлайн-кассы">
            <Select options={fiscalServiceProviderOptions} size="large" />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true }]} label="Имя кассы">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="groupCode" rules={[{ required: true }]} label="Код группы">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="ofdVersion" rules={[{ required: true }]} label="Версия ФФД">
            <Select options={ofdOptions} size="large" />
          </Form.Item>
          <Form.Item name="login" rules={[{ required: true }]} label="Логин">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} label="Пароль">
            <Input size="large" />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            {btnTitle}
          </Button>
          {onRemoveHandler && (
            <Button type="default" onClick={onRemoveHandler}>
              Удалить
            </Button>
          )}
        </Flex>
      </Form>
    </Modal>
  )
}

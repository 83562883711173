import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { isDef } from '../../../types/lang.types'
import { ExercisesTypesFormValues } from '../../../components/exercises-types/exercises-types-form/exercises-types-form.types'
import { useExercisesTypesEditPageParams } from '../exercises-types-edit-page-hooks/exercises-types-edit-page-hooks.hook'
import {
  genExercisesTypesEditPageIsLoading,
  getExercisesTypesEditPageDirectionsOptions,
  getExercisesTypesFormValues,
} from '../../../store/pages/exercises-types-edit-page/exercises-types-edit-page.selectors'
import { exercisesTypesEditPageActions } from '../../../store/pages/exercises-types-edit-page/exercises-types-edit-page.slice'
import { genColorsOptions } from '../../../utils/colors.utils'

export function useExercisesTypesEditPageForm() {
  const [form] = Form.useForm<ExercisesTypesFormValues>()

  const { id } = useExercisesTypesEditPageParams()

  const dispatch = useDispatch()

  const isLoading = useSelector(genExercisesTypesEditPageIsLoading)
  const exercisesTypeFormValues = useSelector(getExercisesTypesFormValues)
  const directionsOptions = useSelector(getExercisesTypesEditPageDirectionsOptions)
  const colorsOptions = React.useMemo(genColorsOptions, [])

  React.useEffect(() => {
    if (isDef(exercisesTypeFormValues)) {
      form.setFieldsValue(exercisesTypeFormValues)
    }
  }, [form, exercisesTypeFormValues])

  const onFinishHandler = React.useCallback(
    (values: ExercisesTypesFormValues): void => {
      if (isDef(id)) {
        dispatch(exercisesTypesEditPageActions.updateExercisesType({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  return {
    form,
    isLoading,
    directionsOptions,
    colorsOptions,
    onFinishHandler,
  }
}

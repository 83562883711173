import * as React from 'react'
import { Form, Input } from 'antd'

import { ExercisesGroupCommentsFormProps } from './exercises-group-comments-form.types'

export const ExercisesGroupCommentsForm: React.FC<ExercisesGroupCommentsFormProps> = props => {
  const { form } = props

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} autoComplete="off" layout="vertical">
      <Form.Item name="comment">
        <Input.TextArea placeholder="Комментарий о клиенте" />
      </Form.Item>
    </Form>
  )
}

import { useSelector } from 'react-redux'

import { genScheduleGroupPageExercise } from '../../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'

export function useScheduleGroupPageHeader() {
  const schedule = useSelector(genScheduleGroupPageExercise)

  return {
    schedule,
  }
}

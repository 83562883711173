import { Button, Space, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { FC } from 'react'

interface Props {
  forbidRemove?: boolean
  isActive: boolean
  onEdit: () => void
  onRemove: () => void
  onPause: () => void
}

export const TableCellActionsCustomFields: FC<Props> = props => {
  const { forbidRemove, onEdit, onRemove, onPause, isActive } = props

  return (
    <Space size="middle">
      <Tooltip title="Изменить статус">
        <Button icon={isActive ? <PauseCircleOutlined /> : <PlayCircleOutlined />} size="middle" onClick={onPause} />
      </Tooltip>
      <Tooltip title="Редактировать">
        <Button icon={<EditOutlined />} size="middle" onClick={onEdit} />
      </Tooltip>
      <Tooltip title="Удалить">
        <Button icon={<DeleteOutlined />} size="middle" danger onClick={onRemove} disabled={forbidRemove} />
      </Tooltip>
    </Space>
  )
}

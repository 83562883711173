import { useLocation } from 'react-router-dom'

import { genExercisesTypesPageParams } from '../exercises-types-page.utils'
import { ExercisesTypesPageParams } from '../exercises-types-page.types'

export function useExercisesTypesPageParams(): ExercisesTypesPageParams {
  const { search } = useLocation()

  return genExercisesTypesPageParams(search)
}

import React from 'react'
import { FormInstance, FormListFieldData } from 'antd'

import { NString, isDefAndNotEmpty, isDef } from '../../../../../../../types/lang.types'
import {
  genMasterServicesFormSubservicesStudiosRoomsValidationRules,
  genMasterServicesFormSubservicesStudiosValidationRules,
} from './master-services-form-subservices-available-studio-room.utils'
import { MasterServicesFormValues } from '../../../../master-services-form.types'

interface useMasterServicesFormSubservicesAvailableStudioRoomProps {
  form: FormInstance<MasterServicesFormValues>
  field: FormListFieldData
  subField: FormListFieldData
}

export function useMasterServicesFormSubservicesAvailableStudioRoom(
  props: useMasterServicesFormSubservicesAvailableStudioRoomProps
) {
  const { field, subField, form } = props

  const studiosValidationRules = genMasterServicesFormSubservicesStudiosValidationRules()
  const studiosRoomsValidationRules = genMasterServicesFormSubservicesStudiosRoomsValidationRules()

  const [selectedStudio, setSelectedStudio] = React.useState<NString>(undefined)

  const onChangeStudioHandler = React.useCallback((value: string): void => {
    setSelectedStudio(value)
  }, [])

  React.useEffect(() => {
    const subServices = form.getFieldValue('subServices')
    const availableStudioRooms = subServices[field.name].availableStudioRooms[subField.name]
    if (isDefAndNotEmpty(subServices) && isDef(availableStudioRooms)) {
      setSelectedStudio(availableStudioRooms.studioId)
    }
  }, [field, subField, form])

  return {
    selectedStudio,
    onChangeStudioHandler,
    studiosValidationRules,
    studiosRoomsValidationRules,
  }
}

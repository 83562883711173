import { useMasterServicesPageTable } from './master-services-page-table.hook'
import { MasterServicesTable } from '../../../components/master-services/master-services-table/master-services-table.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'

export const MasterServicesPageTable = () => {
  const {
    masterServices,
    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  } = useMasterServicesPageTable()

  if (isDefAndNotEmpty(masterServices)) {
    return (
      <MasterServicesTable
        data={masterServices}
        pagination={pagination}
        loading={isLoading}
        onEdit={onEditHandler}
        onHistory={onHistoryHandler}
        onRemove={onRemoveHandler}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
      />
    )
  }

  return <PageEmpty description="Персональные услуги не найдены" />
}

import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { genPaginationParamsDTO } from '../../../utils/pagination.utils'
import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { exercisesTypesPageActions } from './exercises-types-page.slice'
import { getRouterState } from '../../common/router/router.selectors'
import { genExercisesTypesPageParams } from '../../../pages/exercises-types-page/exercises-types-page.utils'

export function* fetchAllExercisesTypes(action: ReturnType<typeof exercisesTypesPageActions.fetchAllExercisesTypes>) {
  try {
    const { page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>> = yield callApi(
      api.exercisesTypesService.fetchAll,
      params
    )

    yield put(exercisesTypesPageActions.fetchAllExercisesTypesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesPageActions.fetchAllExercisesTypesError(new Error()))
  }
}

export function* removeExercisesType(action: ReturnType<typeof exercisesTypesPageActions.removeExercisesType>) {
  try {
    yield callApi(api.exercisesTypesService.remove, action.payload)

    yield put(exercisesTypesPageActions.removeExercisesTypeSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genExercisesTypesPageParams(search)

    yield put(exercisesTypesPageActions.fetchAllExercisesTypes(params))
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesPageActions.removeExercisesTypeError(new Error()))
  }
}

export function* exercisesTypesPageSagas() {
  yield takeLatest(exercisesTypesPageActions.fetchAllExercisesTypes, fetchAllExercisesTypes)
  yield takeLatest(exercisesTypesPageActions.removeExercisesType, removeExercisesType)
}

import { Form, FormInstance, Radio, Select, Space } from 'antd'

import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { useMasterServicesWidgetFormDesignSettingsGeneral } from './master-services-widget-form-design-settings-general.hook'
import { genMasterServicesWidgetFormFontStyles } from './master-services-widget-form-design-settings-general.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export const MasterServicesWidgetFormDesignSettingsGeneral: React.FC<Props> = props => {
  const { form } = props

  const { isLoading, isDefaultFont, fontFamilyOptions, handleFontSelect, toggleDefaultFont } =
    useMasterServicesWidgetFormDesignSettingsGeneral({
      form,
    })

  return (
    <MasterServicesWidgetFormUiCollapse title="Общие">
      <Space direction="vertical" size="middle">
        <Radio.Group value={isDefaultFont} onChange={toggleDefaultFont}>
          <Space direction="vertical">
            <Radio value={true}>По-умолчанию</Radio>
            <Radio value={false}>Google Font</Radio>
          </Space>
        </Radio.Group>
        {!isDefaultFont && (
          <>
            <Form.Item name={['design', 'fontFamily']}>
              <Select
                showSearch
                placeholder="Шрифт"
                options={fontFamilyOptions}
                disabled={isLoading}
                onChange={handleFontSelect}
              />
            </Form.Item>
          </>
        )}
        <MasterServicesWidgetFormUiTypography
          form={form}
          element={['design', 'fontStyles']}
          initialValues={genMasterServicesWidgetFormFontStyles()}
        />
        <MasterServicesWidgetFormUiColor label="Цвет акцентный" name={['design', 'accentColor']} />
        <MasterServicesWidgetFormUiColor label="Второй акцентный" name={['design', 'secondColor']} />
        <MasterServicesWidgetFormUiColor label="Цвет загрузчика" name={['design', 'loaderColor']} />
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

import { put, takeLatest } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'

import { employeesScheduleCreateActions } from './employees-schedule-create.slice'
import { mapEmployeesScheduleDaysOffFormValuesToDTO } from '../../../../mapping/employees.mapping'

export function* create(action: ReturnType<typeof employeesScheduleCreateActions.create>) {
  try {
    const { schedule, daysOff, successCallback } = action.payload

    const daysOffDTO = mapEmployeesScheduleDaysOffFormValuesToDTO(daysOff)

    yield callApi(api.employeesScheduleService.create, {
      ...schedule,
      daysOff: daysOffDTO ?? [],
    })

    yield put(employeesScheduleCreateActions.createSuccess())
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleCreateActions.createError(new Error()))
  }
}

export function* employeesScheduleCreateSagas() {
  yield takeLatest(employeesScheduleCreateActions.create, create)
}

export const ExclamationCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
      <path
        d="M34.5 4.3125C17.8295 4.3125 4.3125 17.8295 4.3125 34.5C4.3125 51.1705 17.8295 64.6875 34.5 64.6875C51.1705 64.6875 64.6875 51.1705 64.6875 34.5C64.6875 17.8295 51.1705 4.3125 34.5 4.3125ZM34.5 59.5664C20.6596 59.5664 9.43359 48.3404 9.43359 34.5C9.43359 20.6596 20.6596 9.43359 34.5 9.43359C48.3404 9.43359 59.5664 20.6596 59.5664 34.5C59.5664 48.3404 48.3404 59.5664 34.5 59.5664Z"
        fill="#FFEA30"
      />
      <path
        opacity="0.1"
        d="M34.5 9.4375C20.6596 9.4375 9.43359 20.6635 9.43359 34.5039C9.43359 48.3443 20.6596 59.5703 34.5 59.5703C48.3404 59.5703 59.5664 48.3443 59.5664 34.5039C59.5664 20.6635 48.3404 9.4375 34.5 9.4375ZM32.3438 19.9492C32.3438 19.6527 32.5863 19.4102 32.8828 19.4102H36.1172C36.4137 19.4102 36.6562 19.6527 36.6562 19.9492V38.2773C36.6562 38.5738 36.4137 38.8164 36.1172 38.8164H32.8828C32.5863 38.8164 32.3438 38.5738 32.3438 38.2773V19.9492ZM34.5 49.5977C33.6536 49.5804 32.8477 49.232 32.2553 48.6273C31.6628 48.0227 31.331 47.2098 31.331 46.3633C31.331 45.5167 31.6628 44.7039 32.2553 44.0992C32.8477 43.4945 33.6536 43.1462 34.5 43.1289C35.3464 43.1462 36.1523 43.4945 36.7447 44.0992C37.3372 44.7039 37.669 45.5167 37.669 46.3633C37.669 47.2098 37.3372 48.0227 36.7447 48.6273C36.1523 49.232 35.3464 49.5804 34.5 49.5977Z"
        fill="#FFEA30"
      />
      <path
        d="M32.8828 38.8125H36.1172C36.4137 38.8125 36.6562 38.5699 36.6562 38.2734V19.9453C36.6562 19.6488 36.4137 19.4062 36.1172 19.4062H32.8828C32.5863 19.4062 32.3438 19.6488 32.3438 19.9453V38.2734C32.3438 38.5699 32.5863 38.8125 32.8828 38.8125ZM31.2656 46.3594C31.2656 47.2172 31.6064 48.0399 32.213 48.6464C32.8195 49.253 33.6422 49.5938 34.5 49.5938C35.3578 49.5938 36.1805 49.253 36.787 48.6464C37.3936 48.0399 37.7344 47.2172 37.7344 46.3594C37.7344 45.5016 37.3936 44.6789 36.787 44.0723C36.1805 43.4658 35.3578 43.125 34.5 43.125C33.6422 43.125 32.8195 43.4658 32.213 44.0723C31.6064 44.6789 31.2656 45.5016 31.2656 46.3594Z"
        fill="#FFEA30"
      />
    </svg>
  )
}

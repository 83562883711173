import * as React from 'react'

import { ScheduleGroupPageModalBookFromWaitingProps } from './schedule-group-page-modal-book-from-waiting.types'
import { useScheduleGroupPageModalBookFromWaiting } from './schedule-group-page-modal-book-from-waiting.hook'
import { ExercisesGroupBookFromWaitingModal } from '../../../components/exercises-group/exercises-group-book-from-waiting-modal/exercises-group-book-from-waiting-modal.component'

export const ScheduleGroupPageModalBookFromWaiting: React.FC<ScheduleGroupPageModalBookFromWaitingProps> = props => {
  const { scheduleId, phone, name } = props

  const { form, loading, paymentTypesOptions, placesOptions, onSaveHandler, onCancelHandler } =
    useScheduleGroupPageModalBookFromWaiting(scheduleId, phone)

  return (
    <ExercisesGroupBookFromWaitingModal
      form={form}
      loading={loading}
      paymentTypesOptions={paymentTypesOptions}
      placesOptions={placesOptions}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
      name={name}
    />
  )
}

import { FormInstance, Space } from 'antd'

import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import {
  genAboutExecutorFontStyles,
  genAboutExecutorNameFontStyles,
  genAboutExecutorTagFontStyles,
} from './master-services-widget-form-design-settings-executor.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export const MasterServicesWidgetFormDesignSettingsExecutor: React.FC<Props> = props => {
  const { form } = props

  return (
    <MasterServicesWidgetFormUiCollapse title="Карточка исполнителя и отзывов">
      <Space direction="vertical" size="middle">
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика текста"
          element={['design', 'aboutExecutorStyles', 'fontStyles']}
          initialValues={genAboutExecutorFontStyles()}
        />
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика заголовка"
          element={['design', 'aboutExecutorStyles', 'nameFontStyles']}
          initialValues={genAboutExecutorNameFontStyles()}
        />
        <MasterServicesWidgetFormUiTypography
          form={form}
          title="Типографика тэгов"
          element={['design', 'aboutExecutorStyles', 'tagFontStyles']}
          initialValues={genAboutExecutorTagFontStyles()}
        />
        <MasterServicesWidgetFormUiColor
          label="Фон тэгов"
          name={['design', 'aboutExecutorStyles', 'tagBackgroundColor']}
        />
        <MasterServicesWidgetFormUiColor
          label="Фон основной"
          name={['design', 'aboutExecutorStyles', 'backgroundColor']}
        />
        <MasterServicesWidgetFormUiShadow form={form} element={['design', 'aboutExecutorStyles', 'shadow']} />
        <MasterServicesWidgetFormUiBorderRadius element="aboutExecutorStyles" property="borderRadius" />
        <MasterServicesWidgetFormUiBorder element="aboutExecutorStyles" />
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

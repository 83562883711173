import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import { NString } from '../../../../types/lang.types'
import { CustomFieldsTableActions } from '../custom-fields-table.types'
import { TableCellActionsCustomFields } from '../../../table-cells/table-cells-actions-fields/table-cell-actions-fields.component'
import { getCustomFieldsResource } from '../../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { CustomFieldsApi } from '../../../../api/types/custom-fields-api.types'

const { confirm } = Modal

interface Props extends CustomFieldsTableActions {
  id: string
  name: NString
  isActive: boolean
}

export const CustomFieldsTableColumnActions: FC<Props> = props => {
  const { id, name, isActive } = props
  const { onEdit, onRemove, onPause } = props
  const onEditHandler = () => {
    onEdit(id)
  }
  const onPauseHandler = () => {
    onPause(id, isActive)
  }
  const onRemoveHandler = () => {
    confirm({
      title: `Подтвердите удаление`,
      icon: <ExclamationCircleOutlined />,
      content: `Эту операцию отменить не получится. Поле ${name} будет удалено.`,
      onOk: () => onRemove(id),
    })
  }

  return (
    <TableCellActionsCustomFields
      isActive={isActive}
      onPause={onPauseHandler}
      onEdit={onEditHandler}
      onRemove={onRemoveHandler}
    />
  )
}

import * as React from 'react'
import { Button, Form, Input } from 'antd'

import { PositionsFormProps, PositionsFormValues } from './positions-form.types'

export const PositionsForm: React.FC<React.PropsWithChildren<PositionsFormProps>> = props => {
  const { form, isLoading, submitText } = props
  const { children } = props
  const { onFinish } = props

  const onFinishHandler = React.useCallback(
    (values: PositionsFormValues): void => {
      onFinish(values)
    },
    [onFinish]
  )

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      onFinish={onFinishHandler}
      autoComplete="off"
      layout="vertical"
      disabled={isLoading}
    >
      <Form.Item
        label="Название должности"
        name="name"
        rules={[{ required: true, message: 'Введите название должности' }]}
      >
        <Input placeholder="Название должности" />
      </Form.Item>
      {children}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}

import { Checkbox, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'
import { isDef } from '../../../types/lang.types'

const { Option } = Select

interface Props {
  type: CustomFieldsApi.CustomFieldsType
  options?: CustomFieldsApi.OptionsAttributes[] | []
  placeholder: string
  value: string
}

export const TableCellCustomFieldValue = ({ type, placeholder, value, options }: Props) => {
  switch (type) {
    case 'INPUT':
      return <Input placeholder={placeholder} value={value} />
    case 'MULTILINE_INPUT':
      return <TextArea autoSize={true} style={{ paddingBottom: '30px' }} placeholder={placeholder} value={value} />
    case 'SELECT':
      return (
        <Select className="select" bordered={false} value={options ? options[0].name : ''}>
          {options &&
            options.map(option => (
              <Option key={option.id} value={option.name}>
                {option.name}
              </Option>
            ))}
        </Select>
      )
    case 'CHECKBOX_GROUP':
      return <Checkbox checked={options ? options[0].default : false}>{options ? options[0].name : ''}</Checkbox>
    default:
      return null
  }
}

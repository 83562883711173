import { MasterServicesWidgetCreatePageForm } from './master-services-widget-create-page-form/master-services-widget-create-page-form.component'
import { MasterServicesWidgetCreatePageTopBar } from './master-services-widget-create-page-top-bar/master-services-widget-create-page-top-bar.component'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'
import { useMasterServicesWidgetCreatePage } from './master-services-widget-create-page.hook'

export const MasterServicesWidgetCreatePage = () => {
  const { isLoading, isLoaded } = useMasterServicesWidgetCreatePage()

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <MasterServicesWidgetCreatePageTopBar />
      <MasterServicesWidgetCreatePageForm />
    </>
  )
}

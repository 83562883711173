import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit'

import { Pagination } from '../../../api/types/api.types'
import { MasterServicesApi } from '../../../api/types/master-services-api.types'
import { Nullable } from '../../../types/lang.types'

export interface MasterServicesWidgetCreatePageState {
  masterServices: Nullable<Pagination<MasterServicesApi.MasterService>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: MasterServicesWidgetCreatePageState = {
  masterServices: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: masterServicesWidgetCreatePageActions, reducer: masterServicesWidgetCreatePageReducer } =
  createSlice({
    name: '@@master-services-widget-create-page',
    initialState,
    reducers: {
      fetchPageData: (state: Draft<MasterServicesWidgetCreatePageState>) => {
        state.isLoading = true
      },
      fetchPageDataSuccess: (
        state: Draft<MasterServicesWidgetCreatePageState>,
        action: PayloadAction<Nullable<Pagination<MasterServicesApi.MasterService>>>
      ) => {
        state.masterServices = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      fetchPageDataError: (state: Draft<MasterServicesWidgetCreatePageState>, action: PayloadAction<Error>) => {
        state.isLoading = false
        state.error = action.payload
      },
      reset: () => initialState,
    },
  })

import { api } from '@api/api'
import { useState } from 'react'
import { Table } from 'antd'

import { genCategoriesTableColumns } from '../goods-columns.utils'
import { EditCategory } from '../modals/edit-category'
import { DeleteModal } from '../modals/delete-modal'

export const CategoriesTable = (props: any) => {
  const { categories, isLoading, getAllCategories } = props
  const [category, setCategory] = useState({} as any)
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false)

  const onOpenModal = (row: any, method: 'delete' | 'edit') => {
    setCategory(row)
    if (method === 'delete') {
      setIsDeleteModalOpened(true)
    } else {
      setIsEditModalOpened(true)
    }
  }

  const onDelete = async () => {
    await api.goodsCategoriesService.remove(category.id)
    await getAllCategories()
    setIsDeleteModalOpened(false)
  }

  const columns = genCategoriesTableColumns(onOpenModal)

  const updateCategory = async (event: any) => {
    await api.goodsCategoriesService.update(category.id, event)
    await getAllCategories()
    setIsEditModalOpened(false)
  }

  return (
    <>
      <EditCategory
        onFinish={updateCategory}
        category={category}
        isOpen={isEditModalOpened}
        onCancel={() => {
          setIsEditModalOpened(false)
        }}
      />
      <DeleteModal onCancel={() => setIsDeleteModalOpened(false)} isOpen={isDeleteModalOpened} onDelete={onDelete} />
      <Table columns={columns} dataSource={categories.content} loading={isLoading} />
    </>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { MasterServicesApi } from '../../../../api/types/master-services-api.types'
import { mapMasterServicesToMasterServicesTableRowList } from '../../../../mapping/master-services.mapping'

const getMasterServicesInternal = (state: AppState): Nullable<Pagination<MasterServicesApi.MasterService>> =>
  state.masterServicesPage.masterServicesPageList.masterServices

export const genMasterServicesPageListIsLoading = (state: AppState): boolean =>
  state.masterServicesPage.masterServicesPageList.isLoading
export const genMasterServicesPageListIsLoaded = (state: AppState): boolean =>
  state.masterServicesPage.masterServicesPageList.isLoaded

export const genMasterServicesTableRowList = createSelector(getMasterServicesInternal, masterServices =>
  mapMasterServicesToMasterServicesTableRowList(masterServices?.content)
)

export const genMasterServicesPageListTotalElements = createSelector(
  getMasterServicesInternal,
  masterServices => masterServices?.totalElements
)

import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { usePaginationPagesLimit } from '@hooks/use-pagination-pages-limit'

import { genPaginationConfig } from '../../../utils/pagination.utils'
import { genEmployeesEditPagePath, genEmployeesPagePath } from '../../../format/path.format'
import { useEmployeesPageParams } from '../employees-page-hooks/employees-page-params.hook'
import { employeesPageListActions } from '../../../store/pages/employees-page/employees-page-list/employees-page-list.slice'
import {
  genEmployeesListIsLoading,
  genEmployeesListTotalElements,
  genEmployeesTableRowList,
} from '../../../store/pages/employees-page/employees-page-list/employees-page-list.selectors'
import { AppPeek } from '../../../types/peek.types'
import { peekActions } from '../../../store/common/peek/peek.slice'

export function useEmployeesPageTable() {
  const { push } = useHistory()
  const location = useLocation()

  const { page, size } = useEmployeesPageParams()

  const dispatch = useDispatch()

  const employees = useSelector(genEmployeesTableRowList)
  const totalElements = useSelector(genEmployeesListTotalElements)
  const isLoading = useSelector(genEmployeesListIsLoading)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])
  usePaginationPagesLimit(pagination, totalElements, genEmployeesPagePath)

  const onEditHandler = React.useCallback(
    (id: string): void => {
      push(genEmployeesEditPagePath({ id: id.toString() }), location)
    },
    [location, push]
  )

  const onRemoveHandler = React.useCallback(
    (id: string): void => {
      dispatch(employeesPageListActions.removeEmployee(id))
    },
    [dispatch]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genEmployeesPagePath({ page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genEmployeesPagePath({ page, size: pageSize }))
    },
    [page, push]
  )

  const onHistoryHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.EMPLOYEES_PAGE_PEEK_HISTORY,
          props: { id },
        })
      )
    },
    [dispatch]
  )

  return {
    employees,
    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onHistoryHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}

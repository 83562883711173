import { Days, Weekdays, Weekend } from '../types/days.types'
import { getStrEnumValues } from './enum.utils'
import { Range } from '../api/types/api.types'

export function genDays(): Days[] {
  return getStrEnumValues<Days>(Days)
}

export function genWeekdays(): Weekdays[] {
  return getStrEnumValues<Weekdays>(Weekdays)
}

export function genWeekend(): Weekend[] {
  return getStrEnumValues<Weekend>(Weekend)
}

export function isContainArrayDaysWithSameTime(arr: Range<string>[]): boolean {
  const duplicates = arr.filter(element => element.from === arr[0].from && element.to === arr[0].to)
  return duplicates.length === 7 && arr.length === 7
}

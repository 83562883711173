import { UserOutlined } from '@ant-design/icons'
import { useTheme } from '@hooks/use-theme.hook'
import { Avatar, Col, Flex, Typography } from 'antd'

import { formatPhoneNumber } from '../../../format/phone.format'
import { IFranchisesPageCard } from './franchises-page-card.props'
import './franchises-page-card.styles.less'
export const FranchisesPageCard = ({
  onlinePayment,
  offlinePayment,
  name,
  phone,
  customerName,
  id,
  onEditHandler,
}: IFranchisesPageCard) => {
  const { theme } = useTheme()
  let textPayment = ''
  switch (true) {
    case onlinePayment && offlinePayment:
      textPayment = 'Платёжи включены'
      break
    case onlinePayment:
      textPayment = 'Включён онлайн платёж'
      break
    case offlinePayment:
      textPayment = 'Включён офлайн платёж'
      break
    default:
      textPayment = 'Платёжи выключены'
  }
  return (
    <Col className={`franchises-page-card ${theme === 'light' ? 'light' : 'dark'}`} onClick={() => onEditHandler(id)}>
      <Flex gap={16}>
        <Col>
          <Avatar icon={<UserOutlined />} />
        </Col>
        <Col>
          <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
            {name}
          </Typography.Title>
          <Typography.Text style={{ color: 'grey' }}>{formatPhoneNumber(`+${phone}`)}</Typography.Text>
        </Col>
      </Flex>
      <Flex className="franchises-page-card__footer">
        <Typography.Text>{customerName}</Typography.Text>
        <Typography.Text>{textPayment}</Typography.Text>
      </Flex>
    </Col>
  )
}

import * as React from 'react'
import { FormInstance } from 'antd'
import { useSelector } from 'react-redux'

import { Countable, isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { TransactionsFormValues } from '../../../components/transactions/transactions-form/transactions-form.types'
import {
  getTransactionsCreatePageTotal,
  getTransactionsCreatePageTableProducts,
  genTransactionsCreatePageTableIsLoading,
} from '../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { useTransactionsCreatePageParams } from '../transactions-create-page-hooks/transactions-create-page-params.hook'
import { ProductsApi } from '../../../api/types/products-api.types'
import { genTransactionIsLoading } from '../../../store/pages/transactions-create-page/transactions-create-page-submit/transactions-create-page-submit.selectors'

export function useTransactionsCreatePageSubmit(form: FormInstance<TransactionsFormValues>) {
  const { studioId } = useTransactionsCreatePageParams()

  const isLoadingTransactionsCreatePage = useSelector(genTransactionIsLoading)
  const isLoadingTransactionsCreatePageTable = useSelector(genTransactionsCreatePageTableIsLoading)

  const totalPrice = useSelector(getTransactionsCreatePageTotal)
  const products = useSelector(getTransactionsCreatePageTableProducts)

  const haveProductsDiscount = React.useMemo(() => products && products.find(product => product.discount), [products])

  const [isProductsTransactionAvailable, setIsProductsTransactionAvailable] = React.useState(true)

  React.useEffect(() => {
    let unavailableProducts: Countable<ProductsApi.Product>[] = []
    if (isDef(products)) {
      products.forEach(product => {
        if (isDefAndNotEmpty(product.entity.availableStudios)) {
          const studio = product.entity.availableStudios.find(studio => studio.id === studioId)
          studio === undefined && unavailableProducts.push(product)
        }
      })
    }

    if (isDefAndNotEmpty(unavailableProducts)) {
      setIsProductsTransactionAvailable(false)
    } else setIsProductsTransactionAvailable(true)
  }, [products])

  const isValid = isProductsTransactionAvailable
    ? haveProductsDiscount
      ? totalPrice >= 0
      : isDefAndNotEmpty(products)
    : false

  return {
    isLoadingTransactionsCreatePage,
    isLoadingTransactionsCreatePageTable,
    isValid,
    isProductsTransactionAvailable,
  }
}

import { FIGURE_DIRECTION, FIGURE_MODE } from '../../../constants/figure'
import { IFigure, IFigureDetails } from '../hour-cell.types'

export const updateFigureItem = (
  figure: IFigure,
  figureDetails: IFigureDetails,
  direction?: FIGURE_DIRECTION,
  mode?: FIGURE_MODE
): IFigure => {
  return {
    ...figure,
    details: figureDetails,
    direction: direction ?? FIGURE_DIRECTION.LEFT,
    mode: mode ?? FIGURE_MODE.FULL,
  }
}

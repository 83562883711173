import { TillsApi } from '@api/types/tills-api.types'
import { getTillsWithOptions } from '@store/common/entity/till/till.selectors'
import { tillEntityActions } from '@store/common/entity/till/till.slice'
import { modalActions } from '@store/common/modal/modal.slice'
import { Form } from 'antd'
import confirm from 'antd/es/modal/confirm'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useFranchisesModalTillEditForm({ id }: { id: string }) {
  const [form] = Form.useForm()
  const titleText = 'Редактирование онлайн-кассы'
  const btnTitle = 'Обновить кассу'

  const tills = useSelector(getTillsWithOptions)
  const tillId = tills?.find(item => item.id === id)
  const dispatch = useDispatch()
  const onCancel = (): void => {
    dispatch(modalActions.closeLast())
  }
  const onFinish = (values: TillsApi.IOnlineTill): void => {
    dispatch(tillEntityActions.updateTill({ id, data: values }))
    onCancel()
  }
  useEffect(() => {
    form.setFieldsValue({ ...tillId })
  }, [tillId])
  const onRemove = () => {
    dispatch(tillEntityActions.deleteTill({ id }))
    onCancel()
  }

  const onRemoveHandler = () => {
    confirm({
      title: `Подтвердите удаление ${tillId?.name}`,
      content: `Эту операцию отменить не получится.`,
      onOk: () => onRemove(),
    })
  }

  return { form, titleText, onFinish, onCancel, btnTitle, onRemoveHandler }
}

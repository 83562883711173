import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { TransactionsApi } from '../types/transactions-api.types'
import { Pagination } from '../types/api.types'
import { IPrintCheckResponse } from '../../kkm-api/types'

export class TransactionsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params: Nullable<Partial<TransactionsApi.TransactionsFetchAllParams>>
  ): Promise<AxiosResponse<Pagination<TransactionsApi.Transaction>>> => {
    return this.httpConnector.get('/transactions', { params })
  }

  public create = (data: TransactionsApi.TransactionDTO): Promise<AxiosResponse<TransactionsApi.Transaction>> => {
    return this.httpConnector.post('/transactions', data)
  }

  public paid = (
    id: string,
    kkmResponses?: IPrintCheckResponse[]
  ): Promise<AxiosResponse<TransactionsApi.Transaction>> => {
    return this.httpConnector.put(`/transactions/${id}/paid`, {
      kkmCommandResponses: kkmResponses,
    })
  }

  public fetchRefundSum = (id: string): Promise<AxiosResponse<TransactionsApi.TransactionRefundSum>> => {
    return this.httpConnector.get(`/transactions/${id}/refund`)
  }

  public refund = (id: string, kkmResponses: IPrintCheckResponse[]): Promise<void> => {
    return this.httpConnector.put(`/transactions/${id}/refund`, {
      kkmCommandResponses: kkmResponses,
    })
  }
}

import { Divider, Form } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'

import { ProviderForm } from '../components/provider-form'

export const AddProvider = (props: any) => {
  const { onFinish, onCancel, isOpen } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({})
  })

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Добавление поставщика" zIndex={10000}>
      <Divider />
      <ProviderForm form={form} onFinish={onFinish} />
    </Modal>
  )
}

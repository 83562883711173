import { Rule } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { isValidPhoneNumber } from 'libphonenumber-js'

import { DictionaryItem } from '../../../api/types/api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { genDefaultTimeFormat } from '../../../format/date.format'
import { NString, Nullable, isDef, isDefAndNotEmpty } from '../../../types/lang.types'
import { sortOptions } from '../../../utils/options.utils'
import {
  ExercisesFormExercisesType,
  ExercisesFormTrainers,
  ExercisesFormValues,
  ExercisesFormValuesDTO,
} from './exercises-form.types'

export function genExercisesFormTypeValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите категорию записи' }]
}

export function genExercisesFormMasterServiceValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите мастер-услугу' }]
}

export function genExercisesFormSubServiceValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите под-услугу' }]
}

export function genExercisesFormDirectionValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите групповое направление' }]
}

export function genExercisesFormTimeFromValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите время начала' }]
}

export function genExercisesFormMaxClientsCountValidationRules(): Rule[] {
  return [
    {
      validator(_, value) {
        if (value > 10_000) {
          return Promise.reject('Вместимость не может быть больше 10 000')
        }
        if (value < 0) {
          return Promise.reject('Вместимость не может быть меньше 0')
        }

        return Promise.resolve()
      },
    },
  ]
}

export function genExercisesFormTimeToValidationRules(
  date: string | undefined,
  studioOffset = 0,
  currentTimetable: 'weekly' | 'daily'
): Rule[] {
  return [
    ({ getFieldValue }) => ({
      validator(rule, value: { start: string; end: string }) {
        const startTime = dayjs(value.start, 'HH:mm')
        const endTime = dayjs(value.end, 'HH:mm')

        if (startTime.isAfter(endTime)) {
          return Promise.reject('Недопустимое время окончания')
        } else if (startTime.isSame(endTime)) {
          return Promise.reject('Время начала не может быть равно времени окончания')
        } else if (endTime.diff(startTime, 'minute') <= 29) {
          return Promise.reject('Временной диапазон между временем начала и окончания должен быть больше 30 минут')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function genExercisesFormPhoneValidationRules(): Rule[] {
  return [
    { required: true, message: 'Выберите гостя' },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value.length && !isValidPhoneNumber(value, 'RU')) {
          if (!value.match(/^\d/) || value.includes('+') || value.substring(0, 1) === '8') {
            return Promise.reject('Недопустимое значение')
          }
          return Promise.reject('Кажется это международный номер, начните с +')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function genExercisesFormPaymentTypeValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите метод оплаты' }]
}

export function genExercisesFormValuesDTO(values: ExercisesFormValues, studioOffset: number): ExercisesFormValuesDTO {
  const { time, direction, subService, type, trainers, maxClientsCount, paymentType, phone, hasGuest, clientId } =
    values

  const timeFromDTO = isDef(time.start)
    ? dayjs(time.start, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()
    : ''

  const timeToDTO =
    isDef(time.end) && time.end !== '00:00'
      ? dayjs(time.end, genDefaultTimeFormat()).utcOffset(studioOffset, true).format()
      : dayjs(time.start, genDefaultTimeFormat()).add(1, 'hours').utcOffset(studioOffset, true).format()

  return {
    direction,
    subService,
    type,
    trainers,
    maxClientsCount: maxClientsCount ?? 1,
    paymentType,
    phone,
    time: {
      start: timeFromDTO,
      end: timeToDTO,
    },
    hasGuest,
    clientId,
  }
}

export function mapTrainersToExercisesFormTrainersOptions(
  trainers: Nullable<ExercisesFormTrainers[]>,
  typeId: number
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(trainers)) {
    const options = trainers.reduce<DefaultOptionType[]>((acc, trainer) => {
      if (!trainer.exerciseTypes || trainer.exerciseTypes.length === 0) {
        acc.push({
          value: trainer.id,
          label: trainer.name,
          filteredOut: trainer.available,
        })
      } else {
        trainer.exerciseTypes.forEach(exerciseType => {
          if (exerciseType.id === typeId) {
            acc.push({
              value: trainer.id,
              label: trainer.name,
              filteredOut: !trainer.available,
            })
          }
        })
      }
      return acc
    }, [])
    return sortOptions(options)
  }

  return undefined
}

export function mapSubServicesTrainersToExercisesFormTrainersOptions(
  typeId: number,
  trainers: Nullable<ExercisesFormTrainers[]>,
  subServicesTrainers: Nullable<DictionaryItem[]>
): DefaultOptionType[] | undefined {
  const trainersOptions = mapTrainersToExercisesFormTrainersOptions(trainers, typeId)

  if (isDefAndNotEmpty(trainersOptions)) {
    if (isDefAndNotEmpty(subServicesTrainers)) {
      let subServicesTrainersOptions: DefaultOptionType[] = []

      subServicesTrainers.forEach(subServicesTrainer => {
        if (!trainersOptions.find(option => option.value === subServicesTrainer.id)) {
          subServicesTrainersOptions.push({
            value: subServicesTrainer.id,
            label: subServicesTrainer.name,
          })
        }
      })

      return [...trainersOptions, ...sortOptions(subServicesTrainersOptions)]
    }
    return trainersOptions
  }
}

export function mapExercisesTypeToExercisesFormDirectionsOptions(
  exercisesType: Nullable<ExercisesFormExercisesType>
): DefaultOptionType[] | undefined {
  if (isDef(exercisesType)) {
    const options = exercisesType.directions.map(direction => ({
      value: direction.id,
      label: direction.name,
    }))

    return sortOptions(options)
  }
}

export function mapExercisesTypeToExercisesFormMasterServicesOptions(
  exercisesType: Nullable<ExercisesFormExercisesType>
): DefaultOptionType[] | undefined {
  if (isDef(exercisesType) && isDefAndNotEmpty(exercisesType.masterServices)) {
    const options = exercisesType.masterServices.map(masterService => ({
      value: masterService.id,
      label: masterService.name,
      filteredOut: masterService.filteredOut,
    }))

    return sortOptions(options)
  }
}

export function mapExercisesTypesToExercisesFormExercisesTypesOptions(
  exercisesTypes: Nullable<ExercisesFormExercisesType[]>
): DefaultOptionType[] | undefined {
  if (isDef(exercisesTypes)) {
    const options = exercisesTypes.map(exercisesType => ({
      value: exercisesType.id,
      label: exercisesType.name,
      format: exercisesType.format,
      filteredOut: exercisesType.filteredOut,
    }))

    return sortOptions(options)
  }
}

export function mapMasterServiceToExercisesFormSubServicesOptions(
  masterService: Nullable<ExercisesTypesApi.ExerciseTypeMasterService>
): DefaultOptionType[] | undefined {
  if (isDef(masterService)) {
    const options = masterService.subServices.map(subService => ({
      value: subService.direction.id,
      label: subService.direction.name,
      filteredOut: subService.filteredOut,
    }))

    return sortOptions(options)
  }
}

export function getExercisesFormExerciseTimeTo(timeFrom: string, duration: NString): string {
  if (!isDef(duration)) return timeFrom

  let dayjsTime = dayjs(timeFrom, 'HH:mm')
  let dayjsDuration = dayjs.duration(duration)

  let updatedTime = dayjsTime.add(dayjsDuration.asHours(), 'hour')

  return updatedTime.format('HH:mm') ?? '00:00'
}

import { CopyOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Row, Col, Form, Input, Space, Button, FormListFieldData, Upload, FormInstance } from 'antd'

import { UPLOAD_URL } from '../../../../../app/app.config'
import { genTokenHeader } from '../../../../../utils/http.utils'
import { StudiosFormTypes } from '../../studios-form-types'
import { useStudiosFormAmenity } from './studios-form-amenity.hook'
import './studios-form-amenity.styles.less'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field?: FormListFieldData
  subField: FormListFieldData
  onCopy: (subField: FormListFieldData) => void
  onRemove: (index: number | number[]) => void
}

export const StudiosFormAmenity: React.FC<Props> = props => {
  const { form, field, subField, onCopy, onRemove } = props

  const { token, imageUrl, onChangeIcon } = useStudiosFormAmenity({ form, field, subField })

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </div>
  )

  return (
    <Row gutter={24} className="studios-form-amenity">
      <Col span="auto">
        <Form.Item
          name={[subField.name, 'iconUrl']}
          rules={[{ required: true, message: 'Загрузите фото' }]}
          getValueFromEvent={file => {
            return file.file.response
          }}
        >
          <Upload
            method="PUT"
            action={UPLOAD_URL}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={onChangeIcon}
            maxCount={1}
            headers={{ Authorization: genTokenHeader(token) || '' }}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Form.Item>
      </Col>
      <Col flex="1 1 0px">
        <Form.Item
          label="Заголовок"
          name={[subField.name, 'name']}
          rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
        >
          <Input.TextArea size="large" style={{ height: 54 }} />
        </Form.Item>
      </Col>
      <Col flex="1 1 0px">
        <Form.Item
          label="Описание"
          name={[subField.name, 'description']}
          rules={[{ required: true, message: 'Поле обязательно к заполнению' }]}
        >
          <Input.TextArea size="large" style={{ height: 54 }} />
        </Form.Item>
      </Col>
      <Col flex="0 1 32px">
        <Space style={{ marginTop: '30px' }}>
          <Button icon={<CopyOutlined />} onClick={() => onCopy(subField)} />
          <Button danger icon={<DeleteOutlined />} onClick={() => onRemove(subField.name)} />
        </Space>
      </Col>
    </Row>
  )
}

import { Button, Form, FormInstance, Input, Radio } from 'antd'
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons'

import { PositionsForm } from '../positions-form.component'
import { PositionsFormValues } from '../positions-form.types'
import { getPositionsOptions } from '../positions-form.utils'

interface Props {
  form: FormInstance<PositionsFormValues>
  isLoading: boolean
  onFinish: (values: PositionsFormValues) => void
}

export const PositionsCreateForm: React.FC<Props> = props => {
  const { form, isLoading, onFinish } = props

  return (
    <PositionsForm form={form} isLoading={isLoading} submitText="Создать должность" onFinish={onFinish}>
      <Form.List name="grades">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item label={index === 0 ? 'Грейды' : ''} required={false} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Введите название грейда',
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Название грейда" style={{ width: '60%' }} />
                </Form.Item>
                <Button icon={<MinusCircleOutlined />} size="large" type="link" onClick={() => remove(field.name)} />
              </Form.Item>
            ))}
            <Form.Item>
              <Button onClick={() => add()} icon={<PlusOutlined />}>
                Добавить грейд
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item name="positionType">
        <Radio.Group options={getPositionsOptions()}></Radio.Group>
      </Form.Item>
    </PositionsForm>
  )
}

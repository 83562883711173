import { all, spawn } from 'redux-saga/effects'

import { transactionsPageCommonSagas } from './transactions-page-common/transactions-page-common.sagas'
import { transactionsPageModalRefundSagas } from './transactions-page-modal-refund/transactions-page-modal-refund.sagas'
import { transactionsPagePeekHistorySagas } from './transactions-page-peek-history/transactions-page-peek-history.sagas'

export function* transactionsPageSagas() {
  yield all(
    [transactionsPageCommonSagas, transactionsPageModalRefundSagas, transactionsPagePeekHistorySagas].map(saga =>
      spawn(saga)
    )
  )
}

import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { genEmployeesCreatePagePath } from '../../../format/path.format'

export function useEmployeesPageTopBar() {
  const { push } = useHistory()
  const location = useLocation()

  const onCreateEmployeeHandler = React.useCallback((): void => {
    push(genEmployeesCreatePagePath(), location)
  }, [location, push])

  return {
    onCreateEmployeeHandler,
  }
}

import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { PageEmpty } from '@components/page/page-empty/page-empty.component'
import { clsx } from 'clsx'

import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { genTransactionsProductsModalTableColumns } from './transactions-products-modal-table.utils'
import { TransactionsProductsModalTableProps } from './transactions-products-modal-table.types'

import './transactions-products-modal-table.styles.less'

export const TransactionsProductsModalTable: React.FC<TransactionsProductsModalTableProps> = props => {
  const { data, loading, pagination } = props
  const { onAdd, onChangePage, onChangePageSize } = props

  const columns = React.useMemo(
    () =>
      genTransactionsProductsModalTableColumns({
        onAdd,
      }),
    [onAdd]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )
  return (
    <div className="transactions-products-modal-table__wrapper">
      {isDefAndNotEmpty(data) ? (
        <Table
          className="transactions-products-modal-table"
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={paginationConfig}
          rowKey="id"
          showHeader={false}
        />
      ) : (
        <PageEmpty description="Товары не найдены" />
      )}
    </div>
  )
}

import { forwardRef, Ref } from 'react'
import { Row, Typography } from 'antd'

import { ClientsAutocompleteOptionProps } from './clients-autocomplete-option.types'
import './clients-autocomplete-option.styles.less'
import { formatPhoneNumber } from '../../../../format/phone.format'

export const ClientsAutocompleteOptionInternal = (props: ClientsAutocompleteOptionProps, ref: Ref<HTMLLIElement>) => {
  const { label, phone, isSelected, isHighlighted, ...restProps } = props
  const labelText = label.split(',')[0]
  const emailMatch = label.match(/почта:\s*([^,]+)/)
  const email = emailMatch ? emailMatch[1].trim() : ''
  return (
    <li
      className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child clients-autocomplete-option"
      ref={ref}
      {...restProps}
    >
      <Typography className="clients-autocomplete-option__fullName">
        {labelText === 'ФИО не задано' ? 'Имя не задано' : labelText}
      </Typography>
      {/* <span className="ant-dropdown-menu-title-content">{formatText(label)}</span> */}
      <Row className="clients-autocomplete-option__footer">
        <Typography className="ant-dropdown-menu-title-content clients-autocomplete-option__phone">
          {formatPhoneNumber(phone)}
        </Typography>
        {email && (
          <>
            <div className="clients-autocomplete-option__circle"></div>
            <Typography className="ant-dropdown-menu-title-content clients-autocomplete-option__email">
              {email}
            </Typography>
          </>
        )}
      </Row>
    </li>
  )
}

export const ClientsAutocompleteOption = forwardRef(
  ClientsAutocompleteOptionInternal
) as typeof ClientsAutocompleteOptionInternal

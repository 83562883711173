import { Card, Form, Space } from 'antd'

import { MasterServicesWidgetFormDesignSettingsProps } from './master-services-widget-form-design-settings.types'
import { MasterServicesWidgetFormDesignSettingsButtons } from './master-services-widget-form-design-settings-buttons/master-services-widget-form-design-settings-buttons.component'
import { MasterServicesWidgetFormDesignSettingsElement } from './master-services-widget-form-design-settings-element/master-services-widget-form-design-settings-element.component'
import { MasterServicesWidgetFormDesignSettingsEnvelope } from './master-services-widget-form-design-settings-envelope/master-services-widget-form-design-settings-envelope.component'
import { MasterServicesWidgetFormDesignSettingsFields } from './master-services-widget-form-design-settings-fields/master-services-widget-form-design-settings-fields.component'
import { MasterServicesWidgetFormDesignSettingsGeneral } from './master-services-widget-form-design-settings-general/master-services-widget-form-design-settings-general.component'
import { MasterServicesWidgetFormDesignSettingsSteps } from './master-services-widget-form-design-settings-steps/master-services-widget-form-design-settings-steps.component'
import { MasterServicesWidgetFormDesignSettingsServices } from './master-services-widget-form-design-settings-services/master-services-widget-form-design-settings-services.component'
import { MasterServicesWidgetFormDesignSettingsCategories } from './master-services-widget-form-design-settings-categories/master-services-widget-form-design-settings-categories.component'
import { MasterServicesWidgetFormDesignSettingsSwitcher } from './master-services-widget-form-design-settings-switcher/master-services-widget-form-design-settings-switcher.component'
import { MasterServicesWidgetFormDesignSettingsStudios } from './master-services-widget-form-design-settings-studios/master-services-widget-form-design-settings-studios.component'
import { MasterServicesWidgetFormDesignSettingsMap } from './master-services-widget-form-design-settings-map/master-services-widget-form-design-settings-map.component'
import { MasterServicesWidgetFormDesignSettingsDatepicker } from './master-services-widget-form-design-settings-datepicker/master-services-widget-form-design-settings-datepicker.component'
import { MasterServicesWidgetFormDesignSettingsExecutors } from './master-services-widget-form-design-settings-executors/master-services-widget-form-design-settings-executors.component'
import { MasterServicesWidgetFormDesignSettingsTimeslot } from './master-services-widget-form-design-settings-timeslot/master-services-widget-form-design-settings-timeslot.component'
import { MasterServicesWidgetFormDesignSettingsExecutor } from './master-services-widget-form-design-settings-executor/master-services-widget-form-design-settings-executor.component'
import { MasterServicesWidgetFormUiCollapse } from '../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import './master-services-widget-form-design-settings.styles.less'

export const MasterServicesWidgetFormDesignSettings: React.FC<MasterServicesWidgetFormDesignSettingsProps> = props => {
  const { form } = props

  const staticWidgetMode = Form.useWatch(['general', 'staticWidgetMode'], form)

  const designSettingsProps = { form }

  return (
    <Card title="Настройки дизайна" bordered={false} className="master-services-widget-form-styles-settings__card">
      <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
        <MasterServicesWidgetFormDesignSettingsGeneral {...designSettingsProps} />
        <MasterServicesWidgetFormDesignSettingsEnvelope {...designSettingsProps} />
        <MasterServicesWidgetFormDesignSettingsSteps {...designSettingsProps} />

        {!staticWidgetMode && (
          <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: закрыть попап">
            <MasterServicesWidgetFormDesignSettingsButtons
              element="buttonCloseModal"
              hideSelectBorderRadius
              hideSelectTypography
              {...designSettingsProps}
            />
          </MasterServicesWidgetFormDesignSettingsElement>
        )}

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: Назад">
          <MasterServicesWidgetFormDesignSettingsButtons element="buttonBack" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: Акцентная">
          <MasterServicesWidgetFormDesignSettingsButtons element="buttonFillColor" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: Вторичная">
          <MasterServicesWidgetFormDesignSettingsButtons element="buttonOutline" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: добавить услугу">
          <MasterServicesWidgetFormDesignSettingsButtons
            element="buttonAddService"
            hideSelectBorderRadius
            hideSelectTypography
            {...designSettingsProps}
          />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: продолжить">
          <MasterServicesWidgetFormDesignSettingsButtons
            element="buttonContinue"
            hideSelectIconColor
            {...designSettingsProps}
          />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: выбрать студию">
          <MasterServicesWidgetFormDesignSettingsButtons
            element="buttonSelectStudio"
            hideSelectIconColor
            {...designSettingsProps}
          />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Кнопка: о студии">
          <MasterServicesWidgetFormDesignSettingsButtons
            element="buttonAboutStudio"
            hideSelectIconColor
            {...designSettingsProps}
          />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Поля ввода: обычные">
          <MasterServicesWidgetFormDesignSettingsFields element="inputStyles" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Поле для ввода: поиск">
          <MasterServicesWidgetFormDesignSettingsFields element="searchInputStyles" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsElement title="Поле для ввода: финальный шаг">
          <MasterServicesWidgetFormDesignSettingsFields element="confirmInputStyles" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        <MasterServicesWidgetFormDesignSettingsServices {...designSettingsProps} />
        <MasterServicesWidgetFormDesignSettingsCategories {...designSettingsProps} />
        <MasterServicesWidgetFormDesignSettingsSwitcher {...designSettingsProps} />

        <MasterServicesWidgetFormDesignSettingsElement title="Дизайн вывода залов/студий">
          <MasterServicesWidgetFormDesignSettingsStudios element="studioCardStyles" {...designSettingsProps} />
        </MasterServicesWidgetFormDesignSettingsElement>

        {/* <MasterServicesWidgetFormDesignSettingsMap /> */}
        <MasterServicesWidgetFormDesignSettingsDatepicker {...designSettingsProps} />

        <MasterServicesWidgetFormUiCollapse title="Временной слот">
          <MasterServicesWidgetFormDesignSettingsTimeslot element="timeSlotStyles" {...designSettingsProps} />
        </MasterServicesWidgetFormUiCollapse>

        <MasterServicesWidgetFormDesignSettingsExecutors {...designSettingsProps} />
        <MasterServicesWidgetFormDesignSettingsExecutor {...designSettingsProps} />
      </Space>
    </Card>
  )
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { masterServicesWidgetCreatePageActions } from '../../store/pages/master-services-widget-create-page/master-services-widget-create-page.slice'
import {
  genMasterServicesWidgetCreatePageIsLoading,
  genMasterServicesWidgetCreatePageIsLoaded,
} from '../../store/pages/master-services-widget-create-page/master-services-widget-create-page.selectors'

export function useMasterServicesWidgetCreatePage() {
  const isLoading = useSelector(genMasterServicesWidgetCreatePageIsLoading)
  const isLoaded = useSelector(genMasterServicesWidgetCreatePageIsLoaded)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(masterServicesWidgetCreatePageActions.fetchPageData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(masterServicesWidgetCreatePageActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
  }
}

import { Collapse, Flex, Form, FormInstance, Typography } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { useState } from 'react'

import { EmployeesPositionsApi } from '../../../../../api/types/employees-positions-api.types'
import { Nullable } from '../../../../../types/lang.types'
import { Theme } from '../../../../../types/theme.types'
import { CustomCard } from '../../../../custom-card/custom-card.component'
import { MasterServicesPricingFormValues } from '../../master-services-form.types'
import { MasterServicesFormPricingImpact } from './master-services-form-pricing-impact/master-services-form-pricing-impact.component'
import { MasterServicesFormPricingImpactsAdd } from './master-services-form-pricing-impacts-add/master-services-form-pricing-impacts-add.component'

interface Props {
  theme: Theme
  form: FormInstance<MasterServicesPricingFormValues>
  positions?: Nullable<EmployeesPositionsApi.EmployeePosition[]>
  positionsOptions?: DefaultOptionType[]
}

export const MasterServicesFormPricingImpacts: React.FC<Props> = props => {
  const { theme, form } = props
  const { positions, positionsOptions } = props

  const [activeKeys, setActiveKeys] = useState<string[]>([])

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  return (
    <CustomCard customClassName={`master-services-form-pricing__card master-services-form-pricing__card--${theme}`}>
      <Flex vertical gap="10px">
        <Typography.Title className="master-services-form-pricing__title" level={5}>
          Влияния на цену
        </Typography.Title>

        <Form.List name="priceImpacts">
          {(fields, { add, remove }) => {
            return (
              <Flex vertical>
                <Collapse
                  bordered={false}
                  activeKey={activeKeys}
                  onChange={key => {
                    if (Array.isArray(key)) {
                      onCollapseChange(key)
                    }
                  }}
                  style={{ marginBottom: '10px' }}
                >
                  {fields.map((field, i) => {
                    return (
                      <MasterServicesFormPricingImpact
                        theme={theme}
                        form={form}
                        field={field}
                        index={i}
                        key={field.key}
                        activeKeys={activeKeys}
                        onCollapseChange={onCollapseChange}
                        onRemove={remove}
                        positions={positions}
                        positionsOptions={positionsOptions}
                      />
                    )
                  })}
                </Collapse>

                <MasterServicesFormPricingImpactsAdd
                  form={form}
                  theme={theme}
                  activeKeys={activeKeys}
                  fields={fields}
                  onCollapseChange={onCollapseChange}
                  addImpact={add}
                />
              </Flex>
            )
          }}
        </Form.List>
      </Flex>
    </CustomCard>
  )
}

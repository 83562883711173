import { Flex } from 'antd'

import { MasterServicesCreatePageForm } from './master-services-create-page-form/master-services-create-page-form.component'
import { MasterServicesCreatePageTopBar } from './master-services-create-page-top-bar/master-services-create-page-top-bar.component'
import { useMasterServicesCreatePage } from './master-services-create-page.hook'

export const MasterServicesCreatePage = () => {
  useMasterServicesCreatePage()

  return (
    <Flex gap="large" align="strech" vertical>
      <MasterServicesCreatePageTopBar />
      <MasterServicesCreatePageForm />
    </Flex>
  )
}

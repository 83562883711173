import { Button, Tabs, Typography } from 'antd'
import { api } from '@api/api'
import { useEffect, useMemo, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { formatRublesToPennies } from '@format/number.format'

import { GoodsTable } from './tabs/goods-table.component'
import { AddGood } from './modals/add-good'
import { ProvidersTable } from './tabs/providers-table.component'
import { ConsignmentsTable } from './tabs/consignments-table.component'
import { CategoriesTable } from './tabs/categories-table.component'
import { AddCategory } from './modals/add-category'
import { AddProvider } from './modals/add-provider'
import { WriteOffModal } from './modals/writeoff-modal'
import { TransferModal } from './modals/transfer-modal'

export const GoodsPage = () => {
  const [categories, setCategories] = useState([] as any)
  const [isLoadingCategories, setIsLoadingCategories] = useState(true)
  const getAllCategories = async () => {
    setCategories((await api.goodsCategoriesService.fetchAll({ size: 1000 })).data)
    setIsLoadingCategories(false)
  }

  const [isOpenWriteOff, setIsOpenWriteOff] = useState(false)
  const [isOpenTransfer, setIsOpenTransfer] = useState(false)

  const [consignment, setConsignment] = useState({} as any)

  const [goods, setGoods] = useState([] as any)
  const [isLoadingGoods, setIsLoadingGoods] = useState(true)
  const getAllGoods = async () => {
    const resGoods = (await api.productsGoodsService.fetchAll({ size: 1000 })).data
    resGoods.content = (resGoods.content as any).map((good: any) => ({
      ...good,
      consignments: {
        list: [],
        isLoading: false,
        onOpenModal: async (row: any, action: 'move' | 'writeOff') => {
          setConsignment(row)
          setStudios(
            (await api.studiosService.fetchAll({ size: 1000 })).data.content?.map(studio => ({
              value: studio.id,
              label: studio.name,
            }))
          )
          if (action === 'move') {
            setIsOpenTransfer(true)
          } else {
            setIsOpenWriteOff(true)
          }
        },
      },
    }))
    setGoods(resGoods)
    setIsLoadingGoods(false)
  }

  const onExpandGoods = async (isExpand: boolean, selectedGood: any) => {
    const updatedGoodsContent = await Promise.all(
      goods.content.map(async (goodItem: any) => {
        if (selectedGood.id === goodItem.id) {
          goodItem.consignments.isLoading = true

          const consignmentNotesResponse = await api.goodsConsignmentNotesService.fetchAll({ goodsId: goodItem.id })
          const consignmentNotes = consignmentNotesResponse.data.content

          goodItem.consignments.list = consignmentNotes
            ?.map((consignmentItem: any) => {
              const matchingConsignments = consignmentItem.goodsConsignments.filter(
                (goodsConsignment: any) => goodsConsignment.goods.id === selectedGood.id
              )

              return (matchingConsignments ?? []).map((goodsConsignment: any) => ({
                ...consignmentItem,
                ...(goodsConsignment || {}),
                cost: goodItem.cost,
              }))
            })
            .flat()

          goodItem.consignments.isLoading = false
        }

        return goodItem
      })
    )

    setGoods({ ...goods, content: updatedGoodsContent })
  }

  const [providers, setProviders] = useState([] as any)
  const [isLoadingProviders, setIsLoadingProviders] = useState(true)
  const getAllProviders = async () => {
    setProviders((await api.goodsProvidersService.fetchAll({ size: 1000 })).data)
    setIsLoadingProviders(false)
  }

  const [consignments, setConsignments] = useState([] as any)
  const [isLoadingConsignments, setIsLoadingConsignments] = useState(true)
  const getAllConsignments = async () => {
    setConsignments((await api.goodsConsignmentNotesService.fetchAll({ size: 1000 })).data)
    setIsLoadingConsignments(false)
  }

  const categoriesOptions = useMemo(() => {
    return (
      categories?.content?.map((category: any) => ({
        label: category.name,
        value: category.id,
      })) ?? []
    )
  }, [categories])

  const tabs = [
    {
      key: 'goods',
      label: 'Список товаров',
      children: (
        <GoodsTable
          categories={categoriesOptions}
          goods={goods}
          isLoading={isLoadingGoods}
          getAllGoods={getAllGoods}
          onExpandGoods={onExpandGoods}
        />
      ),
    },
    {
      key: 'categories',
      label: 'Категории товаров',
      children: (
        <CategoriesTable isLoading={isLoadingCategories} categories={categories} getAllCategories={getAllCategories} />
      ),
    },
    {
      key: 'providers',
      label: 'Поставщики',
      children: (
        <ProvidersTable isLoading={isLoadingProviders} providers={providers} getAllProviders={getAllProviders} />
      ),
    },
    {
      key: 'consignments',
      label: 'Накладные',
      children: (
        <ConsignmentsTable
          isLoading={isLoadingConsignments}
          consignments={consignments}
          getAllConsignments={getAllConsignments}
        />
      ),
    },
  ]

  useEffect(() => {
    getAllCategories()
    getAllGoods()
    getAllProviders()
    getAllConsignments()
  }, [])

  const [isGoodModalOpened, setIsGoodModalOpened] = useState(false)
  const createGood = async (event: any) => {
    await api.productsGoodsService.create({ ...event, cost: formatRublesToPennies(event.cost) })
    await getAllGoods()
    setIsGoodModalOpened(false)
  }

  const [studios, setStudios] = useState([] as any)

  const [isCategoryModalOpened, setIsCategoryModalOpened] = useState(false)
  const createCategory = async (event: any) => {
    await api.goodsCategoriesService.create(event)
    await getAllCategories()
    setIsCategoryModalOpened(false)
  }

  const [isProviderModalOpened, setIsProviderModalOpened] = useState(false)
  const createProvider = async (event: any) => {
    await api.goodsProvidersService.create(event)
    await getAllProviders()
    setIsProviderModalOpened(false)
  }

  const onWriteOff = async (e: any) => {
    await api.goodsConsignmentNotesService.writeOff(consignment.id, { count: e.count })
    await onExpandGoods(true, { id: consignment.goods.id })
    setIsOpenWriteOff(false)
  }

  const onTransfer = async (e: any) => {
    await api.goodsConsignmentNotesService.transfer(consignment.id, e.studioId, { count: e.count })
    await onExpandGoods(true, { id: consignment.goods.id })
    setIsOpenTransfer(false)
  }

  return (
    <>
      <TransferModal
        maxNumber={consignment.countLeft}
        studios={studios}
        onFinish={onTransfer}
        isOpen={isOpenTransfer}
        onCancel={() => setIsOpenTransfer(false)}
      />
      <WriteOffModal
        maxNumber={consignment.countLeft}
        onFinish={onWriteOff}
        isOpen={isOpenWriteOff}
        onCancel={() => setIsOpenWriteOff(false)}
      />
      <AddGood
        categories={categoriesOptions}
        onFinish={createGood}
        isOpen={isGoodModalOpened}
        onCancel={() => setIsGoodModalOpened(false)}
      />
      <AddCategory
        onFinish={createCategory}
        isOpen={isCategoryModalOpened}
        onCancel={() => setIsCategoryModalOpened(false)}
      />
      <AddProvider
        onFinish={createProvider}
        isOpen={isProviderModalOpened}
        onCancel={() => setIsProviderModalOpened(false)}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', justifyItems: 'start' }}>
        <Typography.Title level={1}>Товары</Typography.Title>
        <div style={{ display: 'flex', justifyItems: 'center', gap: '8px' }}>
          <Link to="/consignment-create">
            <Button className="menu-item" type="primary" icon={<PlusOutlined></PlusOutlined>}>
              Накладная
            </Button>
          </Link>
          <Button
            className="menu-item"
            icon={<PlusOutlined></PlusOutlined>}
            onClick={() => setIsProviderModalOpened(true)}
          >
            Поставщик
          </Button>
          <Button className="menu-item" icon={<PlusOutlined></PlusOutlined>} onClick={() => setIsGoodModalOpened(true)}>
            Товар
          </Button>
          <Button
            className="menu-item"
            icon={<PlusOutlined></PlusOutlined>}
            onClick={() => setIsCategoryModalOpened(true)}
          >
            Категория
          </Button>
        </div>
      </div>
      <Tabs defaultActiveKey="1" items={tabs} type="card" />
    </>
  )
}

import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PositionsTable } from '../../../components/positions/positions-table/positions-table.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { usePositionsPageTable } from './positions-page-table.hook'

export const PositionsPageTable = () => {
  const {
    positions,
    positionsOptions,
    pagination,
    isLoading,
    onEditHandler,
    onRemoveHandler,
    onChangePageHandler,
    onChangePageSizeHandler,
  } = usePositionsPageTable()

  if (isDefAndNotEmpty(positions)) {
    return (
      <PositionsTable
        data={positions}
        positionsOptions={positionsOptions}
        pagination={pagination}
        loading={isLoading}
        onEdit={onEditHandler}
        onRemove={onRemoveHandler}
        onChangePage={onChangePageHandler}
        onChangePageSize={onChangePageSizeHandler}
      />
    )
  }

  return <PageEmpty description="Должности не найдены" />
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { EmployeesApi } from '../../../api/types/employees-api.types'
import { EmployeesPositionsApi } from '../../../api/types/employees-positions-api.types'
import { OrganizationsApi } from '../../../api/types/organizations-api.types'
import { Pagination } from '../../../api/types/api.types'
import { NString, Nullable } from '../../../types/lang.types'
import {
  EmployeesEditPageDataSuccessPayload,
  EmployeesEditPageUpdateEmployeePayload,
} from './employees-edit-page.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'

export interface EmployeesEditPageState {
  employee: Nullable<EmployeesApi.Employee>
  franchises: Nullable<Pagination<OrganizationsApi.Organization>>
  positions: Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
  position: NString
  customFields: Nullable<CustomFieldsApi.CustomField[]>
}

const initialState: EmployeesEditPageState = {
  employee: null,
  franchises: null,
  positions: null,
  directions: null,
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  position: null,
  customFields: null,
}

export const { actions: employeesEditPageActions, reducer: employeesEditPageReducer } = createSlice({
  name: '@@employees-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<EmployeesEditPageState>, action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<EmployeesEditPageState>,
      action: PayloadAction<EmployeesEditPageDataSuccessPayload>
    ) => {
      const { employee, franchises, positions, directions, exercisesTypes } = action.payload
      state.employee = employee
      state.customFields = employee?.customFields
      state.franchises = franchises
      state.positions = positions
      state.directions = directions
      state.exercisesTypes = exercisesTypes
      state.isLoading = false
      state.isLoaded = true

      state.position = employee?.position.id
    },
    fetchPageDataError: (state: Draft<EmployeesEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updatePosition: (state: Draft<EmployeesEditPageState>, action) => {
      state.position = action.payload
    },
    updateEmployee: (
      state: Draft<EmployeesEditPageState>,
      _: PayloadAction<EmployeesEditPageUpdateEmployeePayload>
    ) => {
      state.isLoading = true
    },
    updateEmployeeSuccess: (state: Draft<EmployeesEditPageState>) => {
      state.isLoading = false
    },
    updateEmployeeError: (state: Draft<EmployeesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

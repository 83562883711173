import { Table } from 'antd'

import { genConsignmentsGoodsColumns } from '../goods-columns.utils'

export const ConsignmentsSubtable = (props: any) => {
  const columns = genConsignmentsGoodsColumns(props.consignments.onOpenModal)

  return (
    <Table
      columns={columns}
      dataSource={props.consignments.list}
      loading={props.consignments.isLoading}
      pagination={false}
    />
  )
}

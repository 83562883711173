import { Tabs } from 'antd'

import { genCustomFieldsTabs } from './custom-fields-tabs.utils'
interface Props {
  onChangeHandler: (key: string) => void
}
export const CustomFieldsTabs = (props: Props) => {
  const { onChangeHandler } = props
  const itemsTabs = genCustomFieldsTabs()

  return <Tabs onChange={onChangeHandler} defaultActiveKey="1" className="tabs" type="card" items={itemsTabs} />
}

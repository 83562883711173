import * as React from 'react'
import { Typography } from 'antd'

export const SettingsPage: React.FC = () => {
  return (
    <>
      <Typography.Text>Настройки</Typography.Text>
    </>
  )
}

import { ITerminalDeleteDTO, ITerminalDTO, ITerminalUpdateDTO } from '@api/types/terminals-api.types'
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NTerminalEntity } from './terminal.types'

const initialState: NTerminalEntity.IState = {
  terminals: null,
  isLoading: false,
  error: null,
}

export const { actions: terminalEntityActions, reducer: terminalEntityReducer } = createSlice({
  name: '@@terminal-entity',
  initialState,
  reducers: {
    fetchAllTerminals: (state: Draft<NTerminalEntity.IState>) => {
      state.isLoading = true
    },
    fetchAllTerminalsSuccess: (
      state: Draft<NTerminalEntity.IState>,
      action: PayloadAction<NTerminalEntity.IFetchAllTerminalsSuccessPayload>
    ) => {
      state.isLoading = false
      state.terminals = action.payload.terminals
    },
    fetchAllTerminalsError: (state: Draft<NTerminalEntity.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createTerminal: (state: Draft<NTerminalEntity.IState>, _: PayloadAction<ITerminalDTO>) => {
      state.isLoading = true
    },
    createTerminalSuccess: (state: Draft<NTerminalEntity.IState>) => {
      state.isLoading = false
    },
    createTerminalError: (state: Draft<NTerminalEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateTerminal: (state: Draft<NTerminalEntity.IState>, _: PayloadAction<ITerminalUpdateDTO>) => {
      state.isLoading = true
    },
    updateTerminalSuccess: (state: Draft<NTerminalEntity.IState>) => {
      state.isLoading = false
    },
    updateTerminalError: (state: Draft<NTerminalEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    deleteTerminal: (state: Draft<NTerminalEntity.IState>, _: PayloadAction<ITerminalDeleteDTO>) => {
      state.isLoading = true
    },
    deleteTerminalSuccess: (state: Draft<NTerminalEntity.IState>) => {
      state.isLoading = false
    },
    deleteTerminalError: (state: Draft<NTerminalEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import { LOCAL_STORAGE_SELECTED_DATE_KEY } from '@constants/local-storage'
import { useTheme } from '@hooks/use-theme.hook'
import { genSchedulePageListTimetable } from '@store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { Calendar, Col, Row, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import './sidebar-calendar.styles.less'

interface ISidebarCalendarProps {
  onChangeDateHandler: (value: Dayjs) => void
}

export const SidebarCalendar: FC<ISidebarCalendarProps> = ({ onChangeDateHandler }) => {
  // Hooks
  const { theme } = useTheme()

  // Store
  const currentTimetable = useSelector(genSchedulePageListTimetable)

  // Init dayjs
  dayjs.locale('ru')

  const getSelectedDateLC = localStorage.getItem(LOCAL_STORAGE_SELECTED_DATE_KEY)
  const initialSelectedDate = getSelectedDateLC ? dayjs(getSelectedDateLC) : dayjs()
  const handleDateClick = (date: Dayjs) => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_DATE_KEY, date.format('YYYY-MM-DD'))
    onChangeDateHandler(date)
  }

  return (
    <div
      className={`sidebar-calendar__wrapper sidebar-calendar__wrapper--${theme} sidebar-calendar__wrapper--${currentTimetable}`}
    >
      <Calendar
        fullscreen={false}
        value={initialSelectedDate}
        onSelect={handleDateClick}
        dateFullCellRender={date => (
          <div className="ant-picker-cell-inner__wrapper" onClick={() => handleDateClick(date)}>
            <div className="ant-picker-cell-inner">{date.date()}</div>
          </div>
        )}
        headerRender={({ value, onChange }) => {
          const start = 0
          const end = 12
          const monthOptions = []

          let current = value.clone()
          const localeData = (value as any).localeData()
          const months = []
          for (let i = 0; i < 12; i++) {
            current = current.month(i)
            months.push(localeData.months(current))
          }

          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>
            )
          }

          const yearOptions = []
          const year = value.year()
          for (let i = year - 10; i < year + 10; i += 1) {
            yearOptions.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>
            )
          }

          const month = value.month()
          return (
            <div style={{ padding: 8 }}>
              <Row gutter={8}>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={year}
                    className="sidebar-calendar__year-selector"
                    onChange={newYear => {
                      const now = value.clone().year(newYear)
                      onChange(now)
                    }}
                  >
                    {yearOptions}
                  </Select>
                </Col>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    className="sidebar-calendar__month-selector"
                    onChange={newMonth => {
                      const now = value.clone().month(newMonth)
                      onChange(now)
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
              </Row>
            </div>
          )
        }}
      />
    </div>
  )
}

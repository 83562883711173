import { BreaksApi } from '../api/types/breaks.types'
import { TimetableDataItem } from '../components/exercises/exercises-table/exercises-table.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { TimetableItemType } from '../types/timetable.types'
import { mapColoredDictionaryItemToEntityItem } from './api.mapping'

export function mapBreaksToTimetableDataItems(breaks: Nullable<BreaksApi.Break[]>): Nullable<TimetableDataItem[]> {
  if (isDefAndNotEmpty(breaks)) {
    return breaks.reduce<TimetableDataItem[]>((acc, timetableBreak) => {
      const { timeFrom, timeTo } = timetableBreak

      const studiosRoom = mapColoredDictionaryItemToEntityItem(timetableBreak.room)
      if (isDef(timeFrom) && isDef(timeTo) && isDef(studiosRoom)) {
        acc.push({
          id: timetableBreak.id,
          timeFrom,
          timeTo,
          studiosRoom,
          comment: timetableBreak.comment,
          isFilled: timetableBreak?.isFilled ?? false,
          isHidden: timetableBreak?.isHidden ?? false,
          timetableItemType: TimetableItemType.TECHNICAL_BREAK,
          title: timetableBreak.title,
        })
      }
      return acc
    }, [])
  }
  return null
}

export function genBreakCreateDTO(data: TimetableDataItem): BreaksApi.BreakCreateDTO {
  const { timeFrom, timeTo, studiosRoom, comment } = data
  return {
    title: '',
    timeFrom,
    timeTo,
    roomId: studiosRoom.id,
    comment,
  }
}

export function genBreakEditDTO(data: TimetableDataItem): BreaksApi.BreakEditDTO {
  const { id, title, timeFrom, timeTo, studiosRoom, comment } = data
  return {
    id,
    title: title ?? '',
    timeFrom,
    timeTo,
    roomId: studiosRoom.id,
    comment,
  }
}

export function mapBreaksToUpdatedBreaks(
  originalBreaks: Nullable<TimetableDataItem[]>,
  modifiedBreaks: TimetableDataItem[]
) {
  if (!isDefAndNotEmpty(originalBreaks)) {
    return {
      changedBreaks: [],
      newBreaks: modifiedBreaks ? [...modifiedBreaks.map(b => genBreakCreateDTO(b))] : [],
      removedBreakIds: [],
    }
  }

  const changedBreaks: BreaksApi.BreakEditDTO[] = []
  const newBreaks: BreaksApi.BreakCreateDTO[] = []
  const removedBreakIds: string[] = []

  originalBreaks?.forEach(item => {
    const modifiedItem = modifiedBreaks.find(mod => mod.id === item.id)

    if (modifiedItem) {
      if (JSON.stringify(item) !== JSON.stringify(modifiedItem)) {
        changedBreaks.push(genBreakEditDTO(modifiedItem))
      }
    } else {
      removedBreakIds.push(item.id)
    }
  })

  modifiedBreaks.forEach(item => {
    if (!originalBreaks?.find(orig => orig.id === item.id)) {
      newBreaks.push(genBreakCreateDTO(item))
    }
  })

  return {
    changedBreaks,
    newBreaks,
    removedBreakIds,
  }
}

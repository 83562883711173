import { MasterServicesHistoryPeek } from '../../../components/master-services/master-services-history-peek/master-services-history-peek.component'
import { useMasterServicesPagePeekHistory } from './master-services-page-peek-history.hook'
import { MasterServicesPagePeekHistoryProps } from './master-services-page-peek-history.types'

export const MasterServicesPagePeekHistory: React.FC<MasterServicesPagePeekHistoryProps> = props => {
  const { id, name } = props

  const { masterServicesHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useMasterServicesPagePeekHistory(id)

  return (
    <MasterServicesHistoryPeek
      name={name}
      masterServicesHistory={masterServicesHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

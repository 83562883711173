import { ProductsAutocomplete } from '@components/products/products-autocomplete/products-autocomplete.component'

import { useProductsAutocomplete } from './products-autocomplete.hook'
import { ProductsAutocompleteProps } from './products-autocomplete.types'

export const ProductsAutocompleteContainer = <Form,>(props: ProductsAutocompleteProps<Form>) => {
  const { className, size, placeholder, suffix } = props
  const { form, name = 'product', disabled, defaultValue } = props
  const { onChange, setProductId } = props

  const { value, options, loading, onChangeHandler, onSearchHandler, onSelectHandler } = useProductsAutocomplete({
    name,
    form,
    onChange,
    setProductId,
  })

  return (
    <ProductsAutocomplete
      className={className}
      value={value}
      options={options ?? []}
      defaultValue={defaultValue}
      loading={loading}
      disabled={disabled}
      onChange={onChangeHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
      size={size ?? 'middle'}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

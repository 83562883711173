import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { clsx } from 'clsx'

import './side-peek.styles.less'
import { peekActions } from '../../store/common/peek/peek.slice'
import { getTheme } from '../../store/common/layout/layout.selectors'

interface Props {
  children: React.ReactNode
}

export const SidePeek: React.FC<Props> = props => {
  const { children } = props

  const dispatch = useDispatch()

  const theme = useSelector(getTheme)

  const onCancelHandler = useCallback((): void => {
    dispatch(peekActions.closeLast())
  }, [dispatch])

  return (
    <Flex
      justify="end"
      className={clsx(`side-peek ${theme !== 'light' && 'side-peek--dark'}`)}
      onClick={e => e.currentTarget === e.target && onCancelHandler()}
    >
      <Flex className="side-peek__wrapper" vertical gap="middle">
        <Button icon={<CloseOutlined />} onClick={onCancelHandler} />
        <Flex className="side-peek__content" vertical>
          {children}
        </Flex>
      </Flex>
    </Flex>
  )
}

import { CloseOutlined } from '@ant-design/icons'
import { Button, Flex, Form, FormInstance, Popover, Space, Typography } from 'antd'

import { MasterServicesWidgetFormPopoverShadow } from '../../master-services-widget-form-popovers/master-services-widget-form-popover-shadow/master-services-widget-form-popover-shadow.component'
import { MasterServicesWidgetShadowFormValues } from '../../master-services-widget-form-popovers/master-services-widget-form-popover-shadow/master-services-widget-form-popover-shadow.types'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { MasterServicesWidgetFormUiDeviceMode } from '../master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.component'
import { useMasterServicesWidgetFormUiShadow } from './master-services-widget-form-ui-shadow.hook'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string[]
  initialValues?: MasterServicesWidgetShadowFormValues
}

export const MasterServicesWidgetFormUiShadow: React.FC<Props> = props => {
  const { form, element, initialValues } = props

  const {
    shadowForm,
    deviceMode,
    sliderValues,
    openPopover,
    setDeviceMode,
    handleSliderValuesChange,
    hidePopover,
    handlePopoverOpenChange,
    handleFormValuesChange,
  } = useMasterServicesWidgetFormUiShadow({ form, element, initialValues })

  return (
    <Space direction="vertical">
      <Typography.Text>Тень объекта</Typography.Text>
      <Popover
        content={
          <MasterServicesWidgetFormPopoverShadow
            form={shadowForm}
            deviceMode={deviceMode}
            sliderValues={sliderValues}
            initialValues={initialValues}
            handleSliderValuesChange={handleSliderValuesChange}
            handleFormValuesChange={handleFormValuesChange}
          />
        }
        placement="bottom"
        title={
          <Flex justify="space-between" align="center">
            Тень объекта
            <Flex align="center">
              <MasterServicesWidgetFormUiDeviceMode mode={deviceMode} onChaneMode={setDeviceMode} />
              <Button onClick={hidePopover} icon={<CloseOutlined />} type="text" />
            </Flex>
          </Flex>
        }
        trigger="click"
        open={openPopover}
        onOpenChange={handlePopoverOpenChange}
      >
        <Button type="dashed">Редактировать</Button>
      </Popover>
      <Form.Item noStyle name={element}></Form.Item>
    </Space>
  )
}

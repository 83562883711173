import { Divider, Form } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'
import { formatPenniesToRubles } from '@format/number.format'

import { GoodForm } from '../components/good-form'

export const EditGood = (props: any) => {
  const { categories, onFinish, good, isOpen, onCancel } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({ ...good, cost: formatPenniesToRubles(good.cost) })
    if (good?.category?.id) form.setFieldValue('categoryId', good.category.id)
  })

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Редактирование товара">
      <Divider />
      <GoodForm form={form} categories={categories} onFinish={onFinish} />
    </Modal>
  )
}

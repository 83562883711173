import * as React from 'react'

import { EmployeesAutocompleteOptionProps } from './employees-autocomplete-option.types'
import { formatText } from '../../../format/text.format'
import './employees-autocomplete-option.styles.less'

export const EmployeesAutocompleteOptionInternal = (
  props: EmployeesAutocompleteOptionProps,
  ref: React.Ref<HTMLLIElement>
) => {
  const { label, phone, isSelected, isHighlighted, ...restProps } = props

  return (
    <li
      className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child employees-autocomplete-option"
      ref={ref}
      {...restProps}
    >
      <span className="ant-dropdown-menu-title-content employees-autocomplete-option__phone">{formatText(phone)}</span>
      <span className="ant-dropdown-menu-title-content">{formatText(label)}</span>
    </li>
  )
}

export const EmployeesAutocompleteOption = React.forwardRef(
  EmployeesAutocompleteOptionInternal
) as typeof EmployeesAutocompleteOptionInternal

import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { useEffect } from 'react'

import {
  getEmployeesCreatePagePositionsOptions,
  getEmployeesCreatePageFranchisesOptions,
  getEmployeesCreatePageGradesOptions,
  getEmployeesCreatePageDirectionsOptions,
  getEmployeesPositionType,
  getEmployeesCreatePageExercisesTypesOptions,
} from '../../../store/pages/employees-create-page/employees-create-page.selectors'
import {
  EmployeesFormValues,
  EmployeesFormValuesDTO,
} from '../../../components/employees/employees-form/employees-form.types'
import { employeesCreatePageActions } from '../../../store/pages/employees-create-page/employees-create-page.slice'
import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { getCustomFieldsWithValue } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'

export function useEmployeesCreatePageForm() {
  const [form] = Form.useForm<EmployeesFormValues>()

  const dispatch = useDispatch()

  const employeePositionType = useSelector(getEmployeesPositionType)

  const franchisesOptions = useSelector(getEmployeesCreatePageFranchisesOptions)
  const positionsOptions = useSelector(getEmployeesCreatePagePositionsOptions)
  const gradesOptions = useSelector(getEmployeesCreatePageGradesOptions)
  const directionsOptions = useSelector(getEmployeesCreatePageDirectionsOptions)
  const exercisesTypesOptions = useSelector(getEmployeesCreatePageExercisesTypesOptions)

  const onChangePositionHandler = (positionId: string) => {
    dispatch(employeesCreatePageActions.updatePosition(positionId))
  }

  const onFinishHandler = (values: EmployeesFormValuesDTO): void => {
    dispatch(employeesCreatePageActions.createEmployee(values))
  }

  useEffect(() => {
    // @ts-ignore
    dispatch(customFieldsPageActions.fetchAllCustomFields('EMPLOYEE'))
  }, [])

  const customFields = useSelector(getCustomFieldsWithValue)

  return {
    form,
    franchisesOptions,
    positionsOptions,
    gradesOptions,
    directionsOptions,
    customFields,
    exercisesTypesOptions,
    employeePositionType,
    onFinishHandler,
    onChangePositionHandler,
  }
}

import { ClientsOverviewHistoryPeek } from '../../../components/clients/clients-overview-history-peek/clients-overview-history-peek.component'
import { useClientsEditPagePeekOverviewHistory } from './clients-edit-page-peek-overview-history.hook'
import { ClientsEditPagePeekOverviewHistoryProps } from './clients-edit-page-peek-overview-history.types'

export const ClientsEditPagePeekOverviewHistory: React.FC<ClientsEditPagePeekOverviewHistoryProps> = props => {
  const { id } = props

  const { clientHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useClientsEditPagePeekOverviewHistory(id)

  return (
    <ClientsOverviewHistoryPeek
      clientHistory={clientHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

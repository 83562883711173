import { StudiosApi } from '@api/types/studios-api.types'
import { StudiosRoomsApi } from '@api/types/studios-rooms-api.types'

import { ICurrentRoomSlice, ICurrentStudioSlice } from '../work-time-schedule.types'

export const convertStudioRoomToCurrentRoomSlice = (room: StudiosRoomsApi.StudioRoom): ICurrentRoomSlice => {
  const currentRoomSlice: ICurrentRoomSlice = {
    id: room.id,
    name: room.name,
    color: room.color,
    workTime: room.workTime || null,
  }

  return currentRoomSlice
}

export const convertStudioToCurrentStudioSlice = (
  studio: StudiosApi.Studio,
  selectedRoomId: string
): ICurrentStudioSlice => {
  const selectedRoom = studio.rooms?.find(room => room.id === selectedRoomId)
  const currentRoomSlice = selectedRoom ? convertStudioRoomToCurrentRoomSlice(selectedRoom) : null

  let currentStudioSlice: ICurrentStudioSlice = {
    id: studio.id,
    name: studio.name,
    selectedRoom: currentRoomSlice,
    workTime: studio.workTime?.dailyWorkTime || null,
  }

  return currentStudioSlice
}

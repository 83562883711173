import * as React from 'react'
import { Button, Modal, Tooltip } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { MinusOutlined, CloseOutlined } from '@ant-design/icons'

const { confirm } = Modal

interface Props {
  index: number
  onRemove: (index: number) => void
  onCancel: (index: number) => void
  editingIndex: number | undefined
}

export const SalariesTableActions: React.FC<Props> = props => {
  const { index, onRemove, onCancel, editingIndex } = props

  const onRemoveHandler = React.useCallback(() => {
    confirm({
      title: `Подтвердите удаление`,
      icon: <ExclamationCircleOutlined />,
      content: `Вы уверены что хотите удалить ставку`,
      onOk: () => onRemove(index),
    })
  }, [index, onRemove])

  if (index === editingIndex) {
    return (
      <Tooltip title="Отменить">
        <Button icon={<CloseOutlined />} shape={'circle'} onClick={() => onCancel(index)} />
      </Tooltip>
    )
  } else {
    return (
      <Tooltip title="Удалить ставку">
        <Button icon={<MinusOutlined />} size="middle" danger onClick={onRemoveHandler} />
      </Tooltip>
    )
  }
}

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { useExercisesTypesPageParams } from './exercises-types-page-hooks/exercises-types-page-params.hook'
import { exercisesTypesPageActions } from '../../store/pages/exercises-types-page/exercises-types-page.slice'

export function useExercisesTypesPage() {
  const params = useExercisesTypesPageParams()

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(exercisesTypesPageActions.fetchAllExercisesTypes(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(exercisesTypesPageActions.reset())
    }
  }, [dispatch])
}

import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { genExercisesTypesPagePath } from '../../../format/path.format'
import { callApi } from '../../../utils/sagas.utils'
import { exercisesTypesCreatePageActions } from './exercises-types-create-page.slice'
import { genExercisesTypeDTO } from '../../../mapping/exercises-types.mapping'

export function* fetchPageData(_: ReturnType<typeof exercisesTypesCreatePageActions.fetchPageData>) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>> = yield callApi(
      api.exercisesDirectionsService.fetchAll,
      {
        size: 200,
      }
    )

    yield put(exercisesTypesCreatePageActions.fetchPageDataSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesCreatePageActions.fetchPageDataError(new Error()))
  }
}

export function* createExercisesType(action: ReturnType<typeof exercisesTypesCreatePageActions.createExercisesType>) {
  try {
    const exercisesTypeDTO = genExercisesTypeDTO(action.payload)

    yield callApi(api.exercisesTypesService.create, exercisesTypeDTO)

    yield put(exercisesTypesCreatePageActions.createExercisesTypeSuccess())
    yield put(push(genExercisesTypesPagePath()))
  } catch (e) {
    console.error(e)
    yield put(exercisesTypesCreatePageActions.createExercisesTypeError(new Error()))
  }
}

export function* exercisesTypesCreatePageSagas() {
  yield takeLatest(exercisesTypesCreatePageActions.fetchPageData, fetchPageData)
  yield takeLatest(exercisesTypesCreatePageActions.createExercisesType, createExercisesType)
}

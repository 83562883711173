import { FC } from 'react'

import { ProductsPageSection } from '../../../pages/products-page/products-page.types'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ProductsHistoryPeekProps } from './products-history-peek.types'

export const ProductsHistoryPeek: FC<ProductsHistoryPeekProps> = props => {
  const { name, section, isLoading, productHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(productHistory) ? (
        <HistoryList
          title={`История действий с ${name}`}
          type={
            section === ProductsPageSection.SERVICES
              ? HistoryListType.ONE_TIME_PRODUCT
              : HistoryListType.SUBSCRIPTION_PRODUCT
          }
          history={productHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

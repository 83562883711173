import React from 'react'

import { StudiosEditPageFormComponent } from './studios-edit-page-form/studios-edit-page-form.component'
import { StudiosEditPageTopBar } from './studios-edit-page-top-bar/studios-edit-page-top-bar.component'
import { useStudiosEditPage } from './studios-edit-page.hook'

export const StudiosEditPageComponent = () => {
  useStudiosEditPage()
  return (
    <>
      <StudiosEditPageTopBar />
      <StudiosEditPageFormComponent />
    </>
  )
}

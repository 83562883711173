import { ColumnsType } from 'antd/lib/table'

import { TableCellCustomFieldValue } from '../../table-cells/table-cell-custom-field-value/table-cell-custom-field.component'
import { TableCellTypeField } from '../../table-cells/table-cell-type-field/table-cell-type-field.component'
import { TableCellStatus } from '../../table-cells/table-cell-status/table-cell-status.component'
import { TableCellRequired } from '../../table-cells/table-cell-required/table-cell-required.component'
import { CustomFieldsTableActions, CustomFieldsTableRow } from './custom-fields-table.types'
import { CustomFieldsTableColumnActions } from './custom-fields-table-column-actions/custom-fields-table-column-actions.component'

export function genCustomFieldsTableColumns(params: CustomFieldsTableActions): ColumnsType<CustomFieldsTableRow> {
  const { onEdit, onRemove, onPause, data } = params
  const uniqueNames: string[] = data.reduce((names: string[], record: CustomFieldsTableRow) => {
    if (!names.includes(record.name)) {
      names.push(record.name)
    }
    return names
  }, [])
  const filters = uniqueNames.map(name => ({ text: name, value: name }))
  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      filters,
      onFilter: (value, record) => record.name.indexOf(value as string) === 0,
      render: (_, record) => <TableCellRequired required={record.required} title={record.name} />,
    },
    {
      title: 'Вид',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <TableCellTypeField type={record.type} />,
      width: '15%',
      sorter: {
        compare: (a, b) => a.type.localeCompare(b.type),
        multiple: 2,
      },
    },
    {
      title: 'Значения',
      dataIndex: 'default',
      key: 'default',
      width: '15%',
      render: (_, record) => (
        <TableCellCustomFieldValue
          options={record?.options || []}
          type={record.type}
          placeholder={record.placeholder}
          value={record.default}
        />
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'enabled',
      key: 'enabled',
      width: '15%',
      render: (_, record) => <TableCellStatus isActive={record.enabled} />,
    },
    {
      title: 'Действия',
      key: 'action',
      width: '5%',
      render: (_, record) => (
        <CustomFieldsTableColumnActions
          {...record}
          data={data}
          isActive={record.enabled}
          onPause={onPause}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ),
    },
  ]
}

import { RadioChangeEvent, Space, Radio, Typography, Divider, FormInstance } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'

import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { ElementStates } from '../master-services-widget-form-design-settings.types'
import { genSwitcherFontStyles } from './master-services-widget-form-design-settings-switcher.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}
export const MasterServicesWidgetFormDesignSettingsSwitcher: React.FC<Props> = props => {
  const { form } = props

  const [fieldState, setFieldState] = useState(ElementStates.ACTIVE)

  const onChangeFieldState = (e: RadioChangeEvent): void => {
    setFieldState(e.target.value)
  }

  return (
    <MasterServicesWidgetFormUiCollapse title="Переключатель список">
      <Space direction="vertical" size="middle">
        <MasterServicesWidgetFormUiColor label="Цвет фона" name={['design', 'switcherStyles', 'backgroundColor']} />
        <MasterServicesWidgetFormUiTypography
          form={form}
          element={['design', 'switcherStyles', 'fontStyles']}
          initialValues={genSwitcherFontStyles()}
        />
        <Divider orientation="left" style={{ margin: 0 }}>
          <Typography.Text type="secondary">Кнопки</Typography.Text>
        </Divider>
        <Radio.Group value={fieldState} onChange={onChangeFieldState}>
          <Radio.Button value={ElementStates.HOVER}>Наведение</Radio.Button>
          <Radio.Button value={ElementStates.ACTIVE}>Активный</Radio.Button>
        </Radio.Group>
        {getStrEnumValues<ElementStates>(ElementStates).map((state, index) => {
          const elementState = state.toLowerCase()
          return (
            <Space
              direction="vertical"
              key={index}
              className={clsx({ 'master-services-widget-form-fields_hidden': state !== fieldState })}
              size="middle"
            >
              <MasterServicesWidgetFormUiTypography
                form={form}
                element={['design', 'switcherStyles', elementState, 'fontStyles']}
                initialValues={genSwitcherFontStyles(state)}
              />
              <MasterServicesWidgetFormUiShadow
                form={form}
                element={['design', 'switcherStyles', elementState, 'buttonShadow']}
              />
              <MasterServicesWidgetFormUiColor
                label="Цвет кнопки"
                name={['design', 'switcherStyles', elementState, 'buttonBackgroundColor']}
              />
              <MasterServicesWidgetFormUiBorderRadius
                element="switcherStyles"
                state={elementState}
                property="borderRadius"
              />
              <MasterServicesWidgetFormUiBorder element="switcherStyles" state={elementState} />
            </Space>
          )
        })}
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { ProductsApi } from '../../../../api/types/products-api.types'
import { MarkCodesAndCountableEntityItem, isDefAndNotEmpty, Nullable, NString } from '../../../../types/lang.types'
import {
  TransactionsCreatePageTableChangeActiveRecordPayload,
  TransactionsCreatePageTableChangeAmountPayload,
  TransactionsCreatePageTableChangeDiscountPayload,
  TransactionsCreatePageTableChangeMarkCodesPayload,
} from './transactions-create-page-table.types'
import { PaymentMethod } from '../../../../types/payment.types'

export interface TransactionsCreatePageTableState {
  products: Nullable<MarkCodesAndCountableEntityItem<ProductsApi.Product>[]>
  trial: boolean
  paymentMethod: Nullable<PaymentMethod>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: TransactionsCreatePageTableState = {
  products: null,
  trial: true,
  paymentMethod: null,
  isLoading: false,
  error: null,
}

export const { actions: transactionsCreatePageTableActions, reducer: transactionsCreatePageTableReducer } = createSlice(
  {
    name: '@@transactions-create-page-table',
    initialState,
    reducers: {
      addProduct: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<MarkCodesAndCountableEntityItem<ProductsApi.Product>>
      ) => {
        if (isDefAndNotEmpty(state.products)) {
          state.products.push(action.payload)
        } else {
          state.products = [action.payload]
        }
      },
      removeProduct: (state: Draft<TransactionsCreatePageTableState>, action: PayloadAction<string>) => {
        state.products = state.products?.filter(product => product.uuid !== action.payload)
      },
      changeAmount: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<TransactionsCreatePageTableChangeAmountPayload>
      ) => {
        const { productUuid, amount } = action.payload

        state.products = state.products?.map(product => {
          if (product.uuid === productUuid) {
            return { ...product, count: amount }
          }

          return product
        })
      },
      changeMarkCodes: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<TransactionsCreatePageTableChangeMarkCodesPayload>
      ) => {
        const { productUuid, markCodes } = action.payload

        state.products = state.products?.map(product => {
          if (product.uuid === productUuid) {
            return { ...product, markCodes: markCodes }
          }

          return product
        })
      },
      changeActiveRecord: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<TransactionsCreatePageTableChangeActiveRecordPayload>
      ) => {
        const { productUuid, exerciseId } = action.payload

        state.products = state.products?.map(product => {
          if (product.uuid === productUuid) {
            return { ...product, exerciseId }
          }

          return product
        })
      },
      changeDiscount: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<TransactionsCreatePageTableChangeDiscountPayload>
      ) => {
        const { productUuid, discount } = action.payload

        state.products = state.products?.map(product => {
          if (product.uuid === productUuid) {
            return { ...product, discount: discount }
          }

          return product
        })
      },
      changeTrial: (state: Draft<TransactionsCreatePageTableState>, action: PayloadAction<NString>) => {
        state.isLoading = true
      },
      changeTrialSuccess: (state: Draft<TransactionsCreatePageTableState>, action: PayloadAction<boolean>) => {
        state.isLoading = false
        state.trial = action.payload
      },
      changeTrialError: (state: Draft<TransactionsCreatePageTableState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
        state.trial = true
      },
      setDiscount: (
        state: Draft<TransactionsCreatePageTableState>,
        action: PayloadAction<TransactionsCreatePageTableChangeDiscountPayload>
      ) => {
        const { productUuid, discount } = action.payload

        state.products = state.products?.map(product => {
          if (product.uuid === productUuid) {
            return { ...product, discount: discount }
          }

          return product
        })
      },
      setPaymentMethod: (state: Draft<TransactionsCreatePageTableState>, action: PayloadAction<PaymentMethod>) => {
        state.paymentMethod = action.payload
      },
      setProductsNoPayment: (state: Draft<TransactionsCreatePageTableState>) => {
        state.products = state.products?.map(product => {
          const oldDiscount = product.discount
          return {
            ...product,
            discount: product.entity.cost,
            oldDiscount,
          }
        })
      },
      setOldDiscount: (state: Draft<TransactionsCreatePageTableState>) => {
        state.products = state.products?.map(product => {
          return {
            ...product,
            discount: product.oldDiscount ?? 0,
          }
        })
      },
      reset: () => initialState,
    },
  }
)

import { RadioChangeEvent, Space, Radio, FormInstance } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'

import { isDef } from '../../../../../types/lang.types'
import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { ElementStates } from '../master-services-widget-form-design-settings.types'
import { genTimeslotFontStyles } from './master-services-widget-form-design-settings-timeslot.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string
  elementGroup?: string
}

export const MasterServicesWidgetFormDesignSettingsTimeslot: React.FC<Props> = props => {
  const { form, element, elementGroup } = props
  const [fieldState, setFieldState] = useState(ElementStates.DEFAULT)

  const onChangeFieldState = (e: RadioChangeEvent): void => {
    setFieldState(e.target.value)
  }

  return (
    <Space direction="vertical" size="middle">
      <Radio.Group value={fieldState} onChange={onChangeFieldState} size="small">
        <Radio.Button value={ElementStates.DEFAULT}>Обычный</Radio.Button>
        <Radio.Button value={ElementStates.HOVER}>Наведение</Radio.Button>
        <Radio.Button value={ElementStates.ACTIVE}>Активный</Radio.Button>
      </Radio.Group>
      {getStrEnumValues<ElementStates>(ElementStates).map((state, index) => {
        const elementState = state.toLowerCase()
        return (
          <Space
            direction="vertical"
            key={index}
            className={clsx({ 'master-services-widget-form-fields_hidden': state !== fieldState })}
            size="middle"
          >
            <MasterServicesWidgetFormUiTypography
              form={form}
              element={['design', ...(isDef(elementGroup) ? [elementGroup] : []), element, elementState, 'fontStyles']}
              initialValues={genTimeslotFontStyles(state)}
            />
            <MasterServicesWidgetFormUiShadow
              form={form}
              element={['design', ...(isDef(elementGroup) ? [elementGroup] : []), element, elementState, 'shadow']}
            />
            <MasterServicesWidgetFormUiColor
              label="Фон основной"
              name={[
                'design',
                ...(isDef(elementGroup) ? [elementGroup] : []),
                element,
                elementState,
                'backgroundColor',
              ]}
            />
            <MasterServicesWidgetFormUiBorderRadius
              element={element}
              elementGroup={elementGroup}
              state={elementState}
              property="borderRadius"
            />
            <MasterServicesWidgetFormUiBorder element={element} elementGroup={elementGroup} state={elementState} />
          </Space>
        )
      })}
    </Space>
  )
}

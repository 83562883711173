import { ExercisesTimetableApi } from '../../../../api/types/exercises-timetable-api.types'
import {
  mapDictionaryItemToEntityItem,
  mapPaymentTypeToClient,
  mapDictionaryItemsListToEntityItemsList,
} from '../../../../mapping/api.mapping'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../types/lang.types'
import { ClientsBookingsTableBaseDataItem } from '../clients-bookings-table-base/clients-bookings-table-base.types'

export function mapExercisesTimetableBookingToClientsBookingsTableBaseDataItems(
  bookings: Nullable<ExercisesTimetableApi.ExercisesTimetableBooking[]>
): ClientsBookingsTableBaseDataItem[] | undefined {
  if (isDefAndNotEmpty(bookings)) {
    return bookings.reduce<ClientsBookingsTableBaseDataItem[]>(
      (acc: ClientsBookingsTableBaseDataItem[], booking: ExercisesTimetableApi.ExercisesTimetableBooking) => {
        const paymentType = mapPaymentTypeToClient(booking.paymentType)
        const exerciseDirection = mapDictionaryItemToEntityItem(booking.exerciseDirection)
        const studio = mapDictionaryItemToEntityItem(booking.studio)
        const trainers = mapDictionaryItemsListToEntityItemsList(booking.trainers)

        if (isDef(paymentType) && isDef(exerciseDirection) && isDef(studio) && isDef(trainers)) {
          const clientsBookingsTableBaseDataItem: ClientsBookingsTableBaseDataItem = {
            id: booking.id,
            createDate: booking.createDate,
            createdBy: booking.createdBy,
            exerciseDate: booking.exerciseDate,
            visitConfirmed: booking.visitConfirmed,
            canceled: booking.isCancelled,
            exerciseId: booking.exerciseId,
            cancelledBy: booking.cancelledBy,
            canceledAt: booking.cancelledAt,
            isCanceled: booking.isCancelled,
            exerciseDirection,
            exerciseDuration: booking.exerciseDuration,
            studio,
            paymentType,
            client: booking.client,
            clientSubscriptionId: booking.clientSubscriptionId,
            trainers: trainers,
            transactionId: booking.transactionId,
            exerciseType: booking.exerciseType,
            multibooking: null,
          }

          acc.push(clientsBookingsTableBaseDataItem)
        }

        return acc
      },
      []
    )
  }
}

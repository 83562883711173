export enum TransactionStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  REFUND = 'REFUND',
  WAITING = 'WAITING',
}

export interface TransactionSeller {
  id: string
  firstName: string
  lastName: string
}

export interface TransactionRefaunder {
  id: string
  name: string
}

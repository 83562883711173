import { Button, Form, Row, Typography, notification } from 'antd'
import { api } from '@api/api'
import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatPenniesToRubles, formatRublesToPennies } from '@format/number.format'

import { ConsignmentForm } from './consignment-form'

export const ConsignmentEditPage = () => {
  const history = useHistory()
  const { params } = useRouteMatch()
  const { id } = params as any

  const [providers, setProviders] = useState([] as any)
  const getAllProviders = async () => {
    setProviders((await api.goodsProvidersService.fetchAll({ size: 1000 })).data)
  }

  const [organizations, setOrganizations] = useState([] as any)
  const getAllOrganizations = async () => {
    setOrganizations((await api.organizationsService.fetchAll({ size: 1000 })).data)
  }

  const [categories, setCategories] = useState([] as any)
  const getAllCategories = async () => {
    setCategories((await api.goodsCategoriesService.fetchAll({ size: 1000 })).data)
  }

  const [goods, setGoods] = useState([] as any)

  const [allGoods, setAllGoods] = useState([] as any)
  const getAllGoods = async () => {
    setAllGoods((await api.productsGoodsService.fetchAll({ size: 100 })).data)
  }

  const [form] = Form.useForm()
  const handleConsignment = async () => {
    const res = (await api.goodsConsignmentNotesService.fetchById(id)).data
    form.setFieldsValue({
      ...res,
      providerId: res.provider.id,
      organizationId: res.organization.id,
      studioId: res.studio.id,
      consignmentNoteDate: dayjs(res.consignmentNoteDate),
    })
    setGoods(
      await Promise.all(
        res.goodsConsignments.map(async (goodsConsignment: any) => {
          const good = (await api.productsGoodsService.fetchById(goodsConsignment.goods.id)).data
          return {
            ...goodsConsignment,
            ...good,
            goodsId: good.id,
            productionDate: dayjs(goodsConsignment.productionDate),
            expirationDate: dayjs(goodsConsignment.expirationDate),
            categoryId: good.category.id,
            measureUnit: 'Шт.',
            hasDates: goodsConsignment.productionDate || goodsConsignment.expirationDate,
            cost: formatPenniesToRubles(good.cost),
            purchaseProductCost: formatPenniesToRubles(goodsConsignment.purchaseProductCost),
          }
        })
      )
    )
    getCurrentStudios()
  }

  const [studios, setStudios] = useState([] as any)
  const onChangeOrganization = async () => {
    form.setFieldValue('studioId', null)
    setStudios((await api.studiosService.fetchAll({ orgId: form.getFieldValue('organizationId'), size: 1000 })).data)
  }
  const getCurrentStudios = async () => {
    setStudios((await api.studiosService.fetchAll({ orgId: form.getFieldValue('organizationId'), size: 1000 })).data)
  }

  useEffect(() => {
    handleConsignment()
  }, [id])
  useEffect(() => {
    form.setFieldsValue({})
    getAllProviders()
    getAllOrganizations()
    getAllCategories()
    getAllGoods()
  }, [])

  const onSave = async () => {
    const fieldsFormValues = form.getFieldsValue()

    await api.goodsConsignmentNotesService
      .update(id, {
        ...fieldsFormValues,
        consignmentNoteDate: fieldsFormValues.consignmentNoteDate.utcOffset(0, true).format('YYYY-MM-DD'),
        goodsConsignments: goods.map((goodItem: any) => {
          return {
            ...goodItem,
            purchaseProductCost: formatRublesToPennies(goodItem.purchaseProductCost),
          }
        }),
      })
      .then(() => history.push('/goods'))
      .catch(e => {
        notification.error({
          message: e.response.data.message,
        })
      })
  }

  return (
    <>
      <ConsignmentForm
        form={form}
        providers={providers}
        organizations={organizations}
        categories={categories}
        goods={goods}
        setGoods={setGoods}
        allGoods={allGoods}
        getAllCategories={getAllCategories}
        getAllProviders={getAllProviders}
        isEdit={true}
        studios={studios}
        onChangeOrganization={onChangeOrganization}
        onSave={onSave}
        title="Редактирование накладной"
      />
    </>
  )
}

import { Button, Modal, Row } from 'antd'

export const DeleteModal = (props: any) => {
  const { isOpen, onCancel, onDelete } = props
  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Вы уверены, что хотите удалить?">
      <Row justify="end">
        <Button type="primary" danger onClick={() => onDelete()}>
          Да
        </Button>
      </Row>
    </Modal>
  )
}

import dayjs from 'dayjs'

import { formatDayjsToDate } from '../../../format/date.format'
import { ScheduleGroupPageExercise } from '../schedule-group-page.types'
import { Nullable, isDef } from '../../../types/lang.types'

export function genDateOfStartFromScheduleGroupPageExercise(exercise: ScheduleGroupPageExercise): string {
  const date = dayjs(exercise.timeFrom)

  return formatDayjsToDate(date)
}

export function genDateOfEndFromScheduleGroupPageExercise(exercise: ScheduleGroupPageExercise): string {
  const date = dayjs(exercise.timeTo)

  return formatDayjsToDate(date)
}

export function isScheduleGroupPageExerciseCompleted(schedule: Nullable<ScheduleGroupPageExercise>): boolean {
  if (isDef(schedule)) {
    return dayjs().isAfter(new Date(schedule.timeTo))
  }
  return true
}

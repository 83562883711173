import { DefaultOptionType } from 'antd/lib/select'

import { ServiceCategoriesApi } from '../api/types/service-categories-api.types'
import { Nullable, isDefAndNotEmpty } from '../types/lang.types'

export function mapServiceCategoriesToServiceCategoriesOptions(
  serviceCategories: Nullable<ServiceCategoriesApi.ServiceCategory[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(serviceCategories)) {
    return serviceCategories.reduce<DefaultOptionType[]>(
      (acc: DefaultOptionType[], serviceCategory: ServiceCategoriesApi.ServiceCategory) => {
        acc.push({
          value: serviceCategory.id,
          label: serviceCategory.name,
        })
        acc.sort((optionA, optionB) =>
          typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
            ? -1
            : 1
        )
        return acc
      },
      []
    )
  }
}

import { FranchisesPageLists } from './franchises-page-lists/franchises-page-lists.component'
import { FranchisesPageTopBar } from './franchises-page-top-bar/franchises-page-top-bar.component'
import { useFranchisesPage } from './franchises-page.hook'

export const FranchisesPage = () => {
  useFranchisesPage()

  return (
    <>
      <FranchisesPageTopBar />
      <FranchisesPageLists />
      {/* <FranchisesPageTable /> */}
    </>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'
import { mapTrainersToOptions, mapTrainersToTrainersWithGrades } from '../../../mapping/trainers.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../mapping/studios.mapping'
import { mapServiceCategoriesToServiceCategoriesOptions } from '../../../mapping/service-categories.mapping'
import { genPricingFormValues, mapPricingToOptions } from '../../../mapping/pricing.mapping'
import { mapEmployeesPositionsToOptions } from '../../../mapping/employees-positions.mapping'
import { ExerciseTypeFormat } from '../../../api/types/api.types'

const getExercisesTypesInternal = (state: AppState) => state.masterServicesCreatePage.exercisesTypes
const getTrainersInternal = (state: AppState) => state.masterServicesCreatePage.trainers
const getStudiosInternal = (state: AppState) => state.masterServicesCreatePage.studios
const getPricingInternal = (state: AppState) => state.masterServicesCreatePage.pricing
const getPositionsInternal = (state: AppState) => state.masterServicesCreatePage.positions
const getSelectedPricingInternal = (state: AppState) => state.masterServicesCreatePage.selectedPricing
const getServiceCategoriesInternal = (state: AppState) => state.masterServicesCreatePage.serviceCategories

export const genMasterServicesCreatePageIsLoading = (state: AppState): boolean =>
  state.masterServicesCreatePage.isLoading

export const getMasterServicesCreatePagePricing = (state: AppState) => state.masterServicesCreatePage.pricing?.content

export const getMasterServicesCreatePageExercisesTypesOptions = createSelector(
  getExercisesTypesInternal,
  exercisesTypes =>
    mapExercisesTypesToOptions(exercisesTypes?.content?.filter(type => type.format === ExerciseTypeFormat.PERSONAL))
)

export const getMasterServicesCreatePageTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToOptions(trainers?.content)
)

export const getMasterServicesCreatePageStudiosOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToOptions(studios?.content)
)

export const getMasterServicesCreatePageStudiosRoomsOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToRoomsOptions(studios?.content)
)

export const getMasterServicesCreatePagePricingOptions = createSelector(getPricingInternal, pricing =>
  mapPricingToOptions(pricing?.content)
)

export const getMasterServicesCreatePageSelectedPricing = createSelector(
  getSelectedPricingInternal,
  getPositionsInternal,
  (pricing, positions) => genPricingFormValues(pricing, positions?.content)
)

export const getMasterServicesCreatePageServiceCategoriesOptions = createSelector(
  getServiceCategoriesInternal,
  mapServiceCategoriesToServiceCategoriesOptions
)

export const getMasterServicesCreatePagePositions = (state: AppState) =>
  state.masterServicesCreatePage.positions?.content

export const getMasterServicesCreatePagePositionsOptions = createSelector(getPositionsInternal, positions =>
  mapEmployeesPositionsToOptions(positions?.content)
)

export const getMasterServicesCreatePageTrainers = createSelector(getTrainersInternal, trainers =>
  mapTrainersToTrainersWithGrades(trainers?.content)
)

import { put, select, takeLatest } from 'redux-saga/effects'

import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { salariesPageActions } from './salaries-page.slice'
import { genDeselectedSalaries } from './salaries-page.selectors'
import { isDefAndNotEmpty } from '../../../types/lang.types'

export function* fetchAllSalaries(action: ReturnType<typeof salariesPageActions.fetchAllSalaries>) {
  try {
    const response: Awaited<ReturnType<typeof api.salariesService.fetchAll>> = yield callApi(
      api.salariesService.fetchAll
    )

    yield put(salariesPageActions.fetchAllSalariesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.fetchAllSalariesError(new Error()))
  }
}

export function* fetchAllStudios(_: ReturnType<typeof salariesPageActions.fetchAllStudios>) {
  try {
    const response: Awaited<ReturnType<typeof api.studiosService.fetchAll>> = yield callApi(
      api.studiosService.fetchAll,
      {
        size: 100,
      }
    )
    yield put(salariesPageActions.fetchAllStudiosSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.fetchStudiosError(new Error()))
  }
}

export function* fetchAllDirections(_: ReturnType<typeof salariesPageActions.fetchAllDirections>) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>> = yield callApi(
      api.exercisesDirectionsService.fetchAll,
      {
        size: 100,
      }
    )
    yield put(salariesPageActions.fetchAllDirectionsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.fetchDirectionsError(new Error()))
  }
}

export function* fetchAllExercises(_: ReturnType<typeof salariesPageActions.fetchAllExercises>) {
  try {
    const response: Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>> = yield callApi(
      api.exercisesTypesService.fetchAll,
      {
        size: 100,
      }
    )
    yield put(salariesPageActions.fetchAllExercisesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.fetchExercisesError(new Error()))
  }
}

export function* fetchAllGrades(action: ReturnType<typeof salariesPageActions.fetchAllGrades>) {
  try {
    const response: Awaited<ReturnType<typeof api.employeesPositionsService.fetchAll>> = yield callApi(
      api.employeesPositionsService.fetchAll,
      {
        size: 100,
      }
    )
    yield put(salariesPageActions.fetchAllGradesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.fetchGradesError(new Error()))
  }
}

export function* updateSalaries(action: ReturnType<typeof salariesPageActions.updateSalaries>) {
  const deselectedSalaries: ReturnType<typeof genDeselectedSalaries> = yield select(genDeselectedSalaries)
  try {
    if (isDefAndNotEmpty(deselectedSalaries)) {
      yield callApi(api.salariesService.remove, deselectedSalaries)
    }
    yield callApi(api.salariesService.update, action.payload)

    yield put(salariesPageActions.updateSalariesSuccess())
    yield put(salariesPageActions.fetchAllSalaries())
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.updateSalariesError(new Error()))
  }
}

export function* removeSalaries(action: ReturnType<typeof salariesPageActions.removeSalaries>) {
  try {
    yield callApi(api.salariesService.remove, action.payload)

    yield put(salariesPageActions.removeSalariesSuccess())

    yield put(salariesPageActions.fetchAllSalaries())
  } catch (e) {
    console.error(e)
    yield put(salariesPageActions.removeSalariesError(new Error()))
  }
}

export function* salariesPageSagas() {
  yield takeLatest(salariesPageActions.fetchAllSalaries, fetchAllSalaries)
  yield takeLatest(salariesPageActions.fetchAllStudios, fetchAllStudios)
  yield takeLatest(salariesPageActions.fetchAllDirections, fetchAllDirections)
  yield takeLatest(salariesPageActions.fetchAllExercises, fetchAllExercises)
  yield takeLatest(salariesPageActions.fetchAllGrades, fetchAllGrades)
  yield takeLatest(salariesPageActions.updateSalaries, updateSalaries)
  yield takeLatest(salariesPageActions.removeSalaries, removeSalaries)
}

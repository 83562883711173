import { push } from 'connected-react-router'
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects'
import { ADD_PRICING_IN_SUBSERVICES } from '@constants/broadcast'

import { api } from '../../../api/api'
import { genMasterServicesPagePath } from '../../../format/path.format'
import { genMasterServicesCreateDTO } from '../../../mapping/master-services.mapping'
import { genPricingDTO } from '../../../mapping/pricing.mapping'
import { callApi } from '../../../utils/sagas.utils'
import { masterServicesCreatePageActions } from './master-services-create-page.slice'

export function* fetchPageData(_: ReturnType<typeof masterServicesCreatePageActions.fetchPageData>) {
  try {
    yield all([
      put(masterServicesCreatePageActions.fetchExercisesTypes()),
      put(masterServicesCreatePageActions.fetchTrainers()),
      put(masterServicesCreatePageActions.fetchStudios()),
      put(masterServicesCreatePageActions.fetchPricing()),
      put(masterServicesCreatePageActions.fetchPositions()),
    ])

    yield all([
      take(masterServicesCreatePageActions.fetchExercisesTypesSuccess.type),
      take(masterServicesCreatePageActions.fetchTrainersSuccess.type),
      take(masterServicesCreatePageActions.fetchStudiosSuccess.type),
      take(masterServicesCreatePageActions.fetchPricingSuccess.type),
      take(masterServicesCreatePageActions.fetchPositionsSuccess.type),
    ])

    yield put(masterServicesCreatePageActions.fetchPageDataSuccess())
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchPageDataError(new Error()))
  }
}

export function* fetchExercisesTypes(_: ReturnType<typeof masterServicesCreatePageActions.fetchExercisesTypes>) {
  try {
    const exercisesTypes: Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>> = yield callApi(
      api.exercisesTypesService.fetchAll,
      {
        size: 100,
      }
    )

    yield put(masterServicesCreatePageActions.fetchExercisesTypesSuccess(exercisesTypes.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchExercisesTypesError(new Error()))
  }
}

export function* fetchTrainers(_: ReturnType<typeof masterServicesCreatePageActions.fetchTrainers>) {
  try {
    const trainers: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      {
        size: 300,
      }
    )

    yield put(masterServicesCreatePageActions.fetchTrainersSuccess(trainers.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchTrainersError(new Error()))
  }
}

export function* fetchStudios(_: ReturnType<typeof masterServicesCreatePageActions.fetchStudios>) {
  try {
    const studios: Awaited<ReturnType<typeof api.studiosService.fetchAll>> = yield callApi(
      api.studiosService.fetchAll,
      {
        size: 100,
        sort: 'name',
      }
    )

    yield put(masterServicesCreatePageActions.fetchStudiosSuccess(studios.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchStudiosError(new Error()))
  }
}

export function* fetchPricing(_: ReturnType<typeof masterServicesCreatePageActions.fetchPricing>) {
  try {
    const pricing: Awaited<ReturnType<typeof api.pricingService.fetchAll>> = yield callApi(
      api.pricingService.fetchAll,
      {
        size: 500,
      }
    )

    yield put(masterServicesCreatePageActions.fetchPricingSuccess(pricing.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchPricingError(new Error()))
  }
}

export function* fetchPricingById(action: ReturnType<typeof masterServicesCreatePageActions.fetchPricingById>) {
  try {
    const pricing: Awaited<ReturnType<typeof api.pricingService.fetchById>> = yield callApi(
      api.pricingService.fetchById,
      action.payload
    )

    yield put(masterServicesCreatePageActions.fetchPricingByIdSuccess(pricing.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchPricingByIdError(new Error()))
  }
}

export function* fetchPositions(_: ReturnType<typeof masterServicesCreatePageActions.fetchPositions>) {
  try {
    const positions: Awaited<ReturnType<typeof api.employeesPositionsService.fetchAll>> = yield callApi(
      api.employeesPositionsService.fetchAll,
      {
        size: 100,
      }
    )

    yield put(masterServicesCreatePageActions.fetchPositionsSuccess(positions.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchPositionsError(new Error()))
  }
}

export function* fetchServiceCategories(_: ReturnType<typeof masterServicesCreatePageActions.fetchServiceCategories>) {
  try {
    const serviceCategories: Awaited<ReturnType<typeof api.serviceCategoriesService.fetchAll>> = yield callApi(
      api.serviceCategoriesService.fetchAll
    )

    yield put(masterServicesCreatePageActions.fetchServiceCategoriesSuccess(serviceCategories.data))
  } catch (e) {
    yield put(masterServicesCreatePageActions.fetchServiceCategoriesError(new Error()))
  }
}

export function* reFetchServiceCategories() {
  yield put(masterServicesCreatePageActions.fetchServiceCategories())

  yield race([
    take(masterServicesCreatePageActions.fetchServiceCategoriesSuccess.type),
    take(masterServicesCreatePageActions.fetchServiceCategoriesError.type),
  ])
}

export function* reFetchPricing() {
  yield put(masterServicesCreatePageActions.fetchPricing())

  yield race([
    take(masterServicesCreatePageActions.fetchPricingSuccess.type),
    take(masterServicesCreatePageActions.fetchPricingError.type),
  ])
}

export function* createServiceCategory(
  action: ReturnType<typeof masterServicesCreatePageActions.createServiceCategory>
) {
  try {
    yield callApi(api.serviceCategoriesService.create, action.payload)

    yield put(masterServicesCreatePageActions.createServiceCategorySuccess())
    yield call(reFetchServiceCategories)
  } catch (e) {
    console.error(e)
    yield put(masterServicesCreatePageActions.createServiceCategoryError(new Error()))
  }
}

export function* createPricing(action: ReturnType<typeof masterServicesCreatePageActions.createPricing>) {
  try {
    const pricingCreateDTO = genPricingDTO(action.payload)

    const pricing: Awaited<ReturnType<typeof api.pricingService.create>> = yield callApi(
      api.pricingService.create,
      pricingCreateDTO
    )

    yield put(masterServicesCreatePageActions.createPricingSuccess())
    yield call(reFetchPricing)

    yield delay(0)
    const bc = new BroadcastChannel(ADD_PRICING_IN_SUBSERVICES)
    if (pricing.data.id) {
      bc.postMessage(
        JSON.stringify({
          pricingId: pricing.data.id,
        })
      )
    }
    bc.close()
  } catch (e) {
    console.error(e)
    yield put(masterServicesCreatePageActions.createPricingError(new Error()))
  }
}

export function* updatePricing(action: ReturnType<typeof masterServicesCreatePageActions.updatePricing>) {
  const { pricingId, data } = action.payload
  try {
    const pricingCreateDTO = genPricingDTO(data)
    yield callApi(api.pricingService.update, pricingId, pricingCreateDTO)

    yield put(masterServicesCreatePageActions.updatePricingSuccess())
    yield call(reFetchPricing)
  } catch (e) {
    console.error(e)
    yield put(masterServicesCreatePageActions.updatePricingError(new Error()))
  }
}

export function* createMasterService(action: ReturnType<typeof masterServicesCreatePageActions.createMasterService>) {
  try {
    const masterServiceCreateDTO = genMasterServicesCreateDTO(action.payload)
    yield callApi(api.masterServicesService.create, masterServiceCreateDTO)

    yield put(masterServicesCreatePageActions.createMasterServiceSuccess())
    yield put(push(genMasterServicesPagePath()))
  } catch (e) {
    console.error(e)
    yield put(masterServicesCreatePageActions.createMasterServiceError(new Error()))
  }
}

export function* masterServicesCreatePageSagas() {
  yield takeLatest(masterServicesCreatePageActions.fetchPageData.type, fetchPageData)
  yield takeLatest(masterServicesCreatePageActions.fetchExercisesTypes.type, fetchExercisesTypes)
  yield takeLatest(masterServicesCreatePageActions.fetchTrainers.type, fetchTrainers)
  yield takeLatest(masterServicesCreatePageActions.fetchStudios.type, fetchStudios)
  yield takeLatest(masterServicesCreatePageActions.fetchPricing.type, fetchPricing)
  yield takeLatest(masterServicesCreatePageActions.fetchPricingById.type, fetchPricingById)
  yield takeLatest(masterServicesCreatePageActions.fetchPositions.type, fetchPositions)
  yield takeLatest(masterServicesCreatePageActions.fetchServiceCategories.type, fetchServiceCategories)

  yield takeLatest(masterServicesCreatePageActions.createServiceCategory, createServiceCategory)
  yield takeLatest(masterServicesCreatePageActions.createPricing, createPricing)
  yield takeLatest(masterServicesCreatePageActions.updatePricing, updatePricing)
  yield takeLatest(masterServicesCreatePageActions.createMasterService, createMasterService)
}

import { CustomFieldsModalEdit } from '../../../components/custom-fields/custom-fields-modal-edit/custom-fields-modal-edit.component'
import { useCustomFieldsPageModalEdit } from './custom-fields-page-modal-edit.hook'
import { CustomFieldsPageModalEditProps } from './custom-fields-page-modal-edit.types'

export const CustomFieldsPageModalEdit = ({ id }: CustomFieldsPageModalEditProps) => {
  const { onCancelHandler, form, isLoading } = useCustomFieldsPageModalEdit()

  return (
    <CustomFieldsModalEdit
      id={id}
      loading={isLoading}
      form={form}
      onCancel={onCancelHandler}
      title="Редактирование кастомного поля"
    />
  )
}

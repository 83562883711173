import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { ProductsGoodsApi } from '../types/products-goods-api.types'
import { Pagination } from '../types/api.types'

export class GoodsProvidersService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public create = (data: ProductsGoodsApi.ProductGoodsDTO): Promise<AxiosResponse<any>> => {
    return this.httpConnector.post<any>('/products/goods/providers', data)
  }

  public fetchAll = (params: any): Promise<AxiosResponse<Pagination<any>>> => {
    return this.httpConnector.get<Pagination<any>>('/products/goods/providers', { params })
  }

  public fetchById = (id: string): Promise<AxiosResponse<any>> => {
    return this.httpConnector.get<any>(`/products/goods/providers/${id}`)
  }

  public update = (id: string, data: any): Promise<AxiosResponse<any>> => {
    return this.httpConnector.patch<any>(`/products/goods/providers/${id}`, data)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/products/goods/providers/${id}`)
  }
}

import { DEFAULT_EMPTY_SYMBOL } from '@format/text.format'
import { Typography } from 'antd'
import dayjs from 'dayjs'

import { isDef, NString } from '../../../../types/lang.types'
import './schedule-table-period.styles.less'

interface Props {
  dateFrom: NString
  dateTo: NString
}

export const ScheduleTablePeriod = (props: Props) => {
  const { dateFrom, dateTo } = props
  if (isDef(dateFrom) && isDef(dateTo)) {
    const from = `с: ${dayjs(dateFrom).format('D MMMM')}`
    const to = `по: ${dayjs(dateTo).format('D MMMM')}`

    return (
      <>
        <Typography.Paragraph className="schedule-table-period" ellipsis>
          {from}
        </Typography.Paragraph>

        <Typography.Paragraph className="schedule-table-period" ellipsis>
          {to}
        </Typography.Paragraph>
      </>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

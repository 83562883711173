import * as React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Row, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import { AppPath } from '../../types/path.types'
import { formatPathName } from '../../format/text.format'
import { genExercisesTypesPagePath } from '../../format/path.format'
import { ExercisesTypesEditPageForm } from './exercises-types-edit-page-form/exercises-types-edit-page-form.component'
import { useExercisesTypesEditPage } from './exercises-types-edit-page.hook'

export const ExercisesTypesEditPage = () => {
  useExercisesTypesEditPage()

  return (
    <Row>
      <Col>
        <Button type="text" icon={<LeftOutlined />} size="small">
          <Link to={genExercisesTypesPagePath()}>
            <Typography.Text className="backBtnTitle">{formatPathName(AppPath.EXERCISES_TYPES)}</Typography.Text>
          </Link>
        </Button>

        <Typography.Title level={2}>Редактирование категории записей</Typography.Title>

        <ExercisesTypesEditPageForm />
      </Col>
    </Row>
  )
}

import { isDef, Nullable } from '../../../../types/lang.types'
import { AppPeekQueueItem } from '../../../../types/peek.types'
import { genAppPeekComponent } from '../../../../utils/side-peek.utils'

interface Props {
  activePeek: Nullable<AppPeekQueueItem>
}

export const AppLayoutPeekController: React.FC<Props> = props => {
  const { activePeek } = props

  if (isDef(activePeek)) {
    const Peek = genAppPeekComponent(activePeek.peek)

    if (isDef(Peek)) {
      return <Peek {...activePeek.props} />
    }
  }

  return null
}

import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import './schedule-form-slot-add.styles.less'

interface Props {
  onAdd: () => void
  disabled: boolean
}

export const ScheduleFormSlotAdd = (props: Props) => {
  const { onAdd, disabled } = props

  return (
    <Form.Item className="schedule-form-slot-add">
      <Button
        disabled={disabled}
        type="primary"
        size="middle"
        icon={<PlusCircleOutlined />}
        onClick={() => onAdd()}
        block
        ghost
      >
        Добавить
      </Button>
    </Form.Item>
  )
}

import { FC } from 'react'
import { Button, Flex, Table, Typography, Avatar } from 'antd'
import { PlusOutlined, UserOutlined } from '@ant-design/icons'

import { IEmployeeCellProps } from './employee-cell.types'

import './employee-cell.styles.less'

export const EmployeeCell: FC<IEmployeeCellProps> = ({ employee, employeePosition }) => {
  return (
    <Flex vertical className="employee-cell">
      {employee?.name && (
        <Flex gap="small" align="center">
          <Avatar size={16} icon={<UserOutlined />} />
          <p>{employee.name}</p>
        </Flex>
      )}
      {employeePosition?.name && <p>{employeePosition?.name}</p>}
    </Flex>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { scheduleGroupPageListActions } from '../../../store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.slice'
import { useScheduleGroupPageParams } from '../schedule-group-page-hooks/schedule-group-page-params.hook'

export function useScheduleGroupPageModalQrReader(scheduleId: string) {
  const { page, size, productCategoryId } = useScheduleGroupPageParams()

  const dispatch = useDispatch()

  const onCancelHandler = useCallback((): void => {
    dispatch(scheduleGroupPageListActions.fetchExercise(scheduleId))
    dispatch(
      scheduleGroupPageListActions.fetchBookings({
        id: scheduleId,
        params: {
          page,
          size,
          productCategoryId,
        },
      })
    )
    dispatch(modalActions.closeLast())
  }, [dispatch, page, productCategoryId, scheduleId, size])

  return {
    onCancelHandler,
  }
}

export function genCurrencyOptions() {
  return [
    { label: 'Рубли', value: 'RUB' },
    { label: 'Доллары', value: 'USD' },
  ]
}

export function genLanguageOptions() {
  return [
    { label: 'Русский', value: 'ru' },
    { label: 'Английский', value: 'en' },
  ]
}

export function genWidgetModeOptions() {
  return [
    { label: 'Модальное окно', value: false },
    { label: 'На странице', value: true },
  ]
}

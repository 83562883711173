import * as React from 'react'

import { EmployeesSearch } from '../../components/employees/employees-search/employees-search.component'
import { useEmployeesSearch } from './employees-search.hook'

export function EmployeesSearchContainer() {
  const { searchValue, options, loading, onChangeHandler, onSearchHandler, onSelectHandler } = useEmployeesSearch()

  return (
    <EmployeesSearch
      value={searchValue}
      options={options}
      loading={loading}
      onChange={onChangeHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
    />
  )
}

import * as React from 'react'

import { NNumber } from '../../../types/lang.types'
import { formatRubleCurrency, formatRubleDecimal } from '../../../format/number.format'
import { TableCellText } from '../table-cell-text/table-cell-text.component'
import { formatTextToStrikeText } from '../../../format/text.format'

interface Props {
  value: NNumber
  discountValue?: NNumber
  postfix?: string
}

export const TableCellPrice: React.FC<Props> = props => {
  const { value, discountValue, postfix } = props

  const price = value
    ? discountValue
      ? formatTextToStrikeText(formatRubleDecimal(value)) + ' ' + formatRubleCurrency(discountValue)
      : formatRubleCurrency(value)
    : formatRubleCurrency(0)

  const text = `${price} ${postfix ?? ''}`.trim()

  return <TableCellText text={text} />
}

export function genFieldsFontStyles() {
  return {
    desktop: {
      color: '#03010D',
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: '#03010D',
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

export function genPlaceholderFontStyles() {
  return {
    desktop: {
      color: '#A9AAAD',
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: '#A9AAAD',
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

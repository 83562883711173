import { formatPluralized } from '@format/text.format'
import { Typography } from 'antd'

import { ClientSubscriptionStatus } from '../../../../types/clients-subscriptions.types'
import { genClientsSubscriptionsTableVisitsTextType } from './clients-subscriptions-table-visits.utils'

interface Props {
  left: number
  total: number
  status: ClientSubscriptionStatus
  variant: string
  minutes: number
  availableMinutes: number
  duration: string
}

export const ClientsSubscriptionsTableVisits = ({
  left,
  total,
  status,
  minutes,
  availableMinutes,
  variant,
  duration,
}: Props) => {
  let text = ''
  switch (true) {
    case variant === 'BY_VISITS':
      text = `${left} из ${formatPluralized(total, ['занятия', 'занятий', 'занятий'])}`
      break
    case variant === 'BY_TIME':
      text = `${availableMinutes / 60} из ${formatPluralized(minutes / 60, ['часы', 'часов', 'часов'])}`
      break
    case variant === 'BY_DURATION':
      // text = `${formatPluralized(dayjs.duration(duration).asDays(), ['дни', 'дней', 'дней'])}`
      text = 'Свободный доступ'
      break
  }
  const type = genClientsSubscriptionsTableVisitsTextType(status)

  return (
    <Typography.Text type={type} strong>
      {text}
    </Typography.Text>
  )
}

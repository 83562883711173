import { DefaultOptionType } from 'antd/lib/select'
import { notificationsTills } from '@utils/notifications/notifications-tills.utils'

import { api } from '../../../../../api/api'
import { KkmAPI } from '../../../../../kkm-api/kkm'
import { IFetchOfflineTillsInternalResult } from '../../types/utils'
import { TillsApi } from '../../../../../api/types/tills-api.types'
import {
  transformOfflineTillsDTOToOptions,
  transformOfflineTillsFromBDToOfflineTillsDTO,
  transformRowOfflineTillsToOfflineTillsDTO,
} from '../../../../../utils/tills/transform-tills.utils'
import { sortUniqOfflineTillsDTO } from '../../../../../utils/tills/sorted-offline-tills.utils'

const fetchOfflineTillsFromBD = async (studioId: string): Promise<IFetchOfflineTillsInternalResult> => {
  let result: IFetchOfflineTillsInternalResult = {
    hasError: false,
    offlineTills: [],
  }

  let hasError = false
  const response = await api.tillsService.getOffline(studioId).catch(err => {
    notificationsTills.fetchErrorFromBD(err.message)
    hasError = true
  })
  const offlineTillsFromBD = response?.data ?? []

  if (hasError) {
    result.hasError = true
    return result
  }
  if (offlineTillsFromBD.length === 0) {
    notificationsTills.notFoundInBD()
    return result
  }

  result.offlineTills = transformOfflineTillsFromBDToOfflineTillsDTO(offlineTillsFromBD)
  return result
}

const fetchOfflineTillsFromDevice = async (): Promise<IFetchOfflineTillsInternalResult> => {
  let result: IFetchOfflineTillsInternalResult = {
    hasError: false,
    offlineTills: [],
  }

  let hasError = false
  const response = await KkmAPI.getRowOfflineTills().catch(err => {
    console.error(err)
    hasError = true
  })
  const rowOfflineTills = response?.ListUnit ?? []

  if (hasError) {
    notificationsTills.fetchErrorOnPC()
    result.hasError = true
    return result
  }
  if (rowOfflineTills.length === 0) {
    notificationsTills.notFoundOnPC()
    return result
  }

  result.offlineTills = transformRowOfflineTillsToOfflineTillsDTO(rowOfflineTills.filter(till => till.KktNumber))
  return result
}

// TODO: Make global fetch for offline tills and offline till options
export const fetchOfflineTills = async (
  setOfflineTills: (tills: TillsApi.OfflineTillDTO[]) => void,
  setOfflineTillsOptions: (tills: DefaultOptionType[]) => void,
  setIsLoading: (status: boolean) => void,
  setHasError: (status: boolean) => void,
  studioId: string | null,
  successCallback?: (rowTills: TillsApi.OfflineTillDTO[], tillsFromBD: TillsApi.OfflineTillDTO[]) => void
) => {
  if (!studioId) {
    setHasError(true)
    return
  }

  setIsLoading(true)
  const offlineTillsFromBD = await fetchOfflineTillsFromBD(studioId)
  const offlineTillsFromDevice = await fetchOfflineTillsFromDevice()

  setHasError(offlineTillsFromBD.hasError || offlineTillsFromDevice.hasError)

  const offlineTills = sortUniqOfflineTillsDTO([
    ...offlineTillsFromBD.offlineTills,
    ...offlineTillsFromDevice.offlineTills,
  ])

  const hasNotAddedTill = offlineTills.some(till => !till.isFromBD)

  setOfflineTills(offlineTills)
  setOfflineTillsOptions(transformOfflineTillsDTOToOptions(offlineTills))

  setIsLoading(false)

  if (successCallback) successCallback(offlineTillsFromDevice.offlineTills, offlineTillsFromBD.offlineTills)

  return {
    hasNotAddedTill: hasNotAddedTill,
  }
}

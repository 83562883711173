import {
  genScheduleGroupPageBookings,
  genScheduleGroupPageExercise,
} from '@store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'

export const useScheduleGroupPageGuests = () => {
  const dispatch = useDispatch()

  const schedule = useSelector(genScheduleGroupPageExercise)
  const [searchTerm, setSearchTerm] = useState('')

  // state button
  const [showCancelled, setShowCancelled] = useState(false)
  // state visible button
  const [showButtonShowCancelled, setShowButtonCancelled] = useState(false)

  const bookings = useSelector(genScheduleGroupPageBookings)

  useEffect(() => {
    if (bookings?.some(booking => booking.isCancelled)) {
      setShowButtonCancelled(true)
    } else {
      setShowButtonCancelled(false)
    }
  }, [bookings])
  const changeShowCancelled = () => {
    setShowCancelled(prev => !prev)
  }
  const handleSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleQrCode = useCallback(() => {
    const scheduleId = schedule?.id
    const confirmedCount = schedule?.confirmedCount
    const clientsCount = schedule?.clientsCount

    if (scheduleId) {
      dispatch(
        modalActions.show({
          modal: AppModal.SCHEDULE_GROUP_PAGE_MODAL_QR_READER,
          props: { scheduleId, confirmedCount, clientsCount },
        })
      )
    }
  }, [dispatch, schedule])

  return {
    schedule,
    handleSearch,
    searchTerm,
    showCancelled,
    changeShowCancelled,
    showButtonShowCancelled,
    handleQrCode,
  }
}

import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { api } from '../../../api/api'
import { genPaginationParamsDTO } from '../../../utils/pagination.utils'
import { getRouterState } from '../../common/router/router.selectors'
import { callApi } from '../../../utils/sagas.utils'
import { positionsPageActions } from './positions.slice'
import { genPositionsPageParams } from '../../../pages/positions-page/positions-page.utils'

export function* fetchAllPositions(action: ReturnType<typeof positionsPageActions.fetchAllPositions>) {
  try {
    const { page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.positionsService.fetchAll>> = yield callApi(
      api.positionsService.fetchAll,
      params
    )

    yield put(positionsPageActions.fetchAllPositionsSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(positionsPageActions.fetchAllPositionsError(new Error()))
  }
}

export function* removePosition(action: ReturnType<typeof positionsPageActions.removePosition>) {
  try {
    const { id, substitutePositionId } = action.payload
    yield callApi(api.positionsService.removePosition, id, { substitutePositionId })

    yield put(positionsPageActions.removePositionSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genPositionsPageParams(search)

    yield put(positionsPageActions.fetchAllPositions(params))
  } catch (e) {
    console.error(e)
    yield put(positionsPageActions.removePositionError(new Error()))
  }
}

export function* positionsPageSagas() {
  yield takeLatest(positionsPageActions.fetchAllPositions, fetchAllPositions)
  yield takeLatest(positionsPageActions.removePosition, removePosition)
}

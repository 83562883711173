import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapStudiosToOptions } from '../../../../mapping/studios.mapping'

export const getStudiosIsLoading = (state: AppState) => state.studioEntity.isLoading
export const getStudiosError = (state: AppState) => state.studioEntity.error
export const getStudios = (state: AppState) => state.studioEntity.studios

export const getEntityStudiosOptions = createSelector(getStudios, studios => mapStudiosToOptions(studios))

export function genIsGoogleFontFamilyOptions() {
  return [
    { label: 'По-умолчанию', value: false },
    { label: 'Google Font', value: true },
  ]
}

export function genMasterServicesWidgetFormFontStyles() {
  return {
    desktop: {
      color: '#333333',
      fontSize: 18,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 25,
      letterSpacing: 0,
      wordSpacing: 0,
    },
    mobile: {
      color: '#333333',
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 21,
      letterSpacing: 0,
      wordSpacing: 0,
    },
  }
}

import * as React from 'react'
import { useState } from 'react'
import { FormInstance } from 'antd'

import { MasterServicesFormValues } from './master-services-form.types'

export function useMasterServicesForm(form: FormInstance<MasterServicesFormValues>) {
  const [currentTab, setCurrentTab] = useState('1')

  const onChangeCurrentTab = (key: string) => {
    setCurrentTab(key)
  }

  const onNextTab = React.useCallback(async () => {
    form
      .validateFields([
        'name',
        'exerciseTypeIds',
        'timeStepMinutes',
        'minHoursForBooking',
        'minMinutesForBooking',
        'restrictionStartTime',
        'restrictionMinutesDuration',
      ])
      .then((values: any) => {
        onChangeCurrentTab((+currentTab + 1).toString())
      })
      .catch((errorInfo: any) => {
        if ('values' in errorInfo) {
          console.error('error', JSON.stringify(errorInfo))
        } else {
          onChangeCurrentTab((+currentTab + 1).toString())
        }
      })
  }, [currentTab, form])

  return {
    currentTab,
    onChangeCurrentTab,
    onNextTab,
  }
}

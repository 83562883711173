import { DictionaryItem } from '../../api/types/api.types'
import { EntityItem, NNumber, NString, Nullable } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface ScheduleGroupPageUrlParams {
  studioId: string
  scheduleId: string
  section?: string
}

export interface ScheduleGroupPageQueryParams {
  page?: NNumber
  size?: NNumber
  productCategoryId?: NString
  date?: string | undefined
}

export type ScheduleGroupPageParams = ScheduleGroupPageUrlParams & ScheduleGroupPageQueryParams

export interface ScheduleGroupPageExercise {
  id: string
  timeFrom: string
  timeTo: string
  direction: EntityItem<number>
  trainers: EntityItem<string>[]
  type: {
    id: number
    name: string
    color: string
    format: 'PERSONAL' | 'GROUP'
  }
  organizationId: string
  studiosRoom: EntityItem<string>
  studio: DictionaryItem
  comment: NString
  commentModifiedAt: NString
  commentModifiedBy: Nullable<DictionaryItem>
  createdAt: NString
  maxClientsCount: number
  clientsCount: number
  confirmedCount: number
  createdBy: Nullable<DictionaryItem>
}

export enum ScheduleGroupPageSection {
  OVERVIEW = 'overview',
  EDIT = 'edit',
  LONG_RECORD = 'record',
  HISTORY = 'history',
}

export const SCHEDULE_GROUP_PAGE_SIZE: number = 50

import { TabsProps } from 'antd/es/tabs'

import { CustomFieldsPageTable } from '../../../pages/custom-fields-page/custom-fields-page-table/custom-fields-page-table.component'

export function genCustomFieldsTabs(): TabsProps['items'] {
  return [
    {
      key: '1',
      label: 'Студии',
      children: <CustomFieldsPageTable />,
    },
    {
      key: '2',
      label: 'Залы',
      children: <CustomFieldsPageTable />,
    },
    {
      key: '3',
      label: 'Клиенты',
      children: <CustomFieldsPageTable />,
    },
    {
      key: '4',
      label: 'Сотрудники',
      children: <CustomFieldsPageTable />,
    },
  ]
}

import { mapTerminalsToOptions } from '@mapping/terminals.mapping'
import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'

export const getTerminalsIsLoading = (state: AppState) => state.terminalEntity.isLoading
export const getTerminalsError = (state: AppState) => state.terminalEntity.error
const getTerminalsInternal = (state: AppState) => state.terminalEntity.terminals

export const getTerminalsWithOptions = createSelector([getTerminalsInternal], terminals =>
  mapTerminalsToOptions(terminals)
)

export const CheckCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
      <path
        d="M34.5 4.3125C17.8295 4.3125 4.3125 17.8295 4.3125 34.5C4.3125 51.1705 17.8295 64.6875 34.5 64.6875C51.1705 64.6875 64.6875 51.1705 64.6875 34.5C64.6875 17.8295 51.1705 4.3125 34.5 4.3125ZM34.5 59.5664C20.6596 59.5664 9.43359 48.3404 9.43359 34.5C9.43359 20.6596 20.6596 9.43359 34.5 9.43359C48.3404 9.43359 59.5664 20.6596 59.5664 34.5C59.5664 48.3404 48.3404 59.5664 34.5 59.5664Z"
        fill="#1677FF"
      />
      <path
        opacity="0.1"
        d="M34.5 9.4375C20.6596 9.4375 9.43359 20.6635 9.43359 34.5039C9.43359 48.3443 20.6596 59.5703 34.5 59.5703C48.3404 59.5703 59.5664 48.3443 59.5664 34.5039C59.5664 20.6635 48.3404 9.4375 34.5 9.4375ZM47.5318 24.6458L33.341 44.3216C33.1427 44.5984 32.8812 44.824 32.5783 44.9796C32.2754 45.1352 31.9397 45.2164 31.5992 45.2164C31.2586 45.2164 30.923 45.1352 30.6201 44.9796C30.3171 44.824 30.0557 44.5984 29.8573 44.3216L21.4614 32.6778C21.2054 32.3207 21.4614 31.8221 21.8994 31.8221H25.0597C25.7537 31.8221 26.4006 32.159 26.8049 32.7183L31.6025 39.3757L42.1951 24.6862C42.5994 24.1202 43.253 23.79 43.9403 23.79H47.1006C47.5386 23.79 47.7946 24.2887 47.5318 24.6458Z"
        fill="#1677FF"
      />
      <path
        d="M47.098 23.7891H43.9378C43.2504 23.7891 42.5968 24.1192 42.1925 24.6853L31.5999 39.3747L26.8022 32.7173C26.3979 32.158 25.7511 31.8211 25.057 31.8211H21.8968C21.4588 31.8211 21.2027 32.3197 21.4588 32.6769L29.8547 44.3206C30.053 44.5974 30.3145 44.823 30.6174 44.9786C30.9203 45.1342 31.256 45.2154 31.5965 45.2154C31.9371 45.2154 32.2727 45.1342 32.5757 44.9786C32.8786 44.823 33.1401 44.5974 33.3384 44.3206L47.5293 24.6448C47.7921 24.2877 47.536 23.7891 47.098 23.7891Z"
        fill="#1677FF"
      />
    </svg>
  )
}

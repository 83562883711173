import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { EmployeesPagePeekHistoryFetchAuditPayload } from './employees-page-peek-history.types'

export interface EmployeesPagePeekHistoryState {
  employeeAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: EmployeesPagePeekHistoryState = {
  employeeAudit: null,
  isLoading: false,
  error: null,
}

export const { actions: employeesPagePeekHistoryActions, reducer: employeesPagePeekHistoryReducer } = createSlice({
  name: '@@employees-page-peek-history',
  initialState,
  reducers: {
    fetchAudit: (
      state: Draft<EmployeesPagePeekHistoryState>,
      _: PayloadAction<EmployeesPagePeekHistoryFetchAuditPayload>
    ): void => {
      state.isLoading = true
    },
    fetchAuditSuccess: (
      state: Draft<EmployeesPagePeekHistoryState>,
      action: PayloadAction<Pagination<AuditApi.Audit>>
    ): void => {
      state.employeeAudit = action.payload
      state.isLoading = false
    },
    fetchAuditError: (state: Draft<EmployeesPagePeekHistoryState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isLoading = false
    },
    reset: () => initialState,
  },
})

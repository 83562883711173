import { CopyOutlined, SearchOutlined } from '@ant-design/icons'
import { TableCellTagProductType } from '@components/table-cells/table-cell-tag-product-type/table-cell-tag-product-type.component'
import { formatRublesPenniesCurrency } from '@format/number.format'
import { formatClientsPaymentMethod } from '@format/text.format'
import { Button, Col, Dropdown, Flex, Form, FormInstance, message, Table, Tag, Tooltip, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Dayjs } from 'dayjs'
import { ExpandedRowRender } from 'rc-table/lib/interface'
import CopyToClipboard from 'react-copy-to-clipboard'

import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { EmployeesAutocompleteContainer } from '../../../containers/employees-autocomplete/employees-autocomplete.container'
import { ProductsAutocompleteContainer } from '../../../containers/products-autocomplete/products-autocomplete.container'
import { formatPhoneNumber } from '../../../format/phone.format'
import { isDefAndNotEmpty, NString, Nullable } from '../../../types/lang.types'
import { firstItem } from '../../../utils/list.utils'
import { TableCellDateTimeTransaction } from '../../table-cells/table-cell-date-transaction/table-cell-date-transaction.component'
import { TableCellPrice } from '../../table-cells/table-cell-price/table-cell-price.component'
import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { TransactionsTableActions } from './transactions-table-actions/transactions-table-actions.component'
import { TransactionsTableActionsEvents } from './transactions-table-actions/transactions-table-actions.types'
import { TransactionsTablePeriodFilter } from './transactions-table-period-filter/transactions-table-period-filter.component'
import { TransactionsTableSearch } from './transactions-table-search/transactions-table-search.component'
import { TransactionsTableStatus } from './transactions-table-status/transactions-table-status.component'
import {
  TransactionsTableDataItem,
  TransactionsTableDataItemProduct,
  TransactionsTableSearchFormValues,
} from './transactions-table.types'

export function genTransactionsTableColumns(
  events: TransactionsTableActionsEvents,
  filters: {
    paymentMethod: NString
    status: NString
  },
  productsSearchForm: FormInstance<TransactionsTableSearchFormValues>,
  clientsSearchForm: FormInstance<TransactionsTableSearchFormValues>,
  employeesSearchForm: FormInstance<TransactionsTableSearchFormValues>,
  filterProductsOpen: boolean,
  setFilterProductsOpen: (open: boolean) => void,
  onChangePeriodHandler: (dates?: [Nullable<Dayjs>, Nullable<Dayjs>]) => void,
  onSearchHandler: () => void,
  onPhoneClick: (clientId: string) => void,
  period?: [Nullable<Dayjs>, Nullable<Dayjs>]
): ColumnsType<TransactionsTableDataItem> {
  const { onBarcode, onRefundSum, onHistory } = events

  return [
    {
      title: () => {
        const filtered = productsSearchForm.getFieldValue('productId')

        return (
          <Flex justify="space-between" gap={16}>
            <Typography.Text strong style={{ whiteSpace: 'nowrap' }}>
              Продажа
            </Typography.Text>
            <Flex justify="space-between" gap={8}>
              <Dropdown
                overlayClassName="transactions-table__dropdown"
                placement="bottomRight"
                open={filterProductsOpen}
                onOpenChange={(nextOpen, info) => {
                  if (info.source === 'trigger' || nextOpen) {
                    setFilterProductsOpen(nextOpen)
                  }
                }}
                dropdownRender={() => {
                  return (
                    <TransactionsTableSearch
                      form={productsSearchForm}
                      onSubmit={() => {
                        setFilterProductsOpen(false)
                        onSearchHandler()
                      }}
                      onCancel={() => {
                        productsSearchForm.setFieldsValue({
                          productId: null,
                          product: null,
                        })
                        setFilterProductsOpen(false)
                        onSearchHandler()
                      }}
                    >
                      <Form.Item name="product">
                        <ProductsAutocompleteContainer
                          placeholder="Поиск товара"
                          name="product"
                          form={productsSearchForm}
                        />
                      </Form.Item>
                    </TransactionsTableSearch>
                  )
                }}
                trigger={['click']}
              >
                <SearchOutlined style={{ fontSize: '12px', color: filtered ? '#1890ff' : 'rgba(0, 0, 0, 0.29)' }} />
              </Dropdown>
              <TransactionsTablePeriodFilter
                period={period}
                onChangePeriodHandler={onChangePeriodHandler}
                onSearchHandler={onSearchHandler}
              />
            </Flex>
          </Flex>
        )
      },
      key: 'products',
      width: 80,

      render: (_, transaction) => {
        const { products } = transaction

        if (products.length > 1) {
          const text = `Товар, ${products.length} шт`

          return (
            <>
              <TableCellDateTimeTransaction date={transaction.date} format="HH:mm" />
              <TableCellText text={text} />
              <Col style={{ paddingTop: 4 }}>
                <Tag color="pink">Товары</Tag>
              </Col>
            </>
          )
        }

        return (
          <>
            <TableCellDateTimeTransaction date={transaction.date} format="HH:mm" />
            <Typography>{firstItem(products)?.name}</Typography>
            <Col style={{ paddingTop: 4 }}>
              <TableCellTagProductType productType={transaction.products[0].type} />
            </Col>
          </>
        )
      },
    },
    {
      title: 'Сотрудник',
      key: 'seller',
      width: 50,
      render: (_, transaction) => {
        const sellerName = transaction.seller ? `${transaction.seller.lastName} ${transaction.seller.firstName}` : '-'
        return <TableCellText text={sellerName} />
      },
      filterIcon: () => {
        const filtered = employeesSearchForm.getFieldValue('employeeId')
        return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      },
      filterDropdown: ({ close }) => (
        <TransactionsTableSearch
          form={employeesSearchForm}
          onSubmit={() => {
            close()
            onSearchHandler()
          }}
          onCancel={() => {
            employeesSearchForm.setFieldsValue({
              employeeId: null,
              seller: null,
            })
            close()
            onSearchHandler()
          }}
        >
          <Form.Item name="seller">
            <EmployeesAutocompleteContainer placeholder="Поиск сотрудника" name="seller" form={employeesSearchForm} />
          </Form.Item>
        </TransactionsTableSearch>
      ),
    },
    {
      title: 'Клиент',
      dataIndex: 'phone',
      key: 'phone',
      width: 190,
      filterIcon: () => {
        const filtered = clientsSearchForm.getFieldValue('clientId')
        return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      },
      filterDropdown: ({ close }) => (
        <TransactionsTableSearch
          form={clientsSearchForm}
          onSubmit={() => {
            close()
            onSearchHandler()
          }}
          onCancel={() => {
            clientsSearchForm.setFieldsValue({
              clientId: null,
              phone: null,
            })
            close()
            onSearchHandler()
          }}
        >
          <Form.Item name="phone">
            <ClientsAutocompleteContainer
              placeholder="Поиск клиента"
              name="phone"
              form={clientsSearchForm}
              showButtonAddClient={false}
            />
          </Form.Item>
        </TransactionsTableSearch>
      ),
      render: (_, transaction) => {
        const phone = `+${transaction.phone}`
        return (
          <Flex gap={8} align="center">
            <Typography
              onClick={() => {
                onPhoneClick(transaction.client.id)
              }}
              style={{ color: '#1677ff', cursor: 'pointer' }}
            >
              {formatPhoneNumber(phone)}
            </Typography>
            <Tooltip title="Скопировать номер телефона">
              <CopyToClipboard text={phone}>
                <Button
                  onClick={() => {
                    message.success({
                      content: 'Номер успешно скопирован',
                      className: 'transactions-table__message',
                    })
                  }}
                  icon={<CopyOutlined />}
                  size="middle"
                />
              </CopyToClipboard>
            </Tooltip>
          </Flex>
        )
      },
    },

    {
      title: 'Стоимость',
      dataIndex: 'toPay',
      key: 'toPay',
      width: 60,
      render: (_, transaction) => (
        <Col>
          {!!transaction.discount && (
            <Tooltip title={<span>{transaction.discountReason}</span>}>
              <span
                style={{
                  color: '#FF7875',
                  fontSize: 12,
                  textDecoration: 'line-through',
                }}
              >
                {formatRublesPenniesCurrency(transaction.sum)}
              </span>
            </Tooltip>
          )}
          <div>
            <TableCellPrice value={transaction.toPay} />
          </div>
        </Col>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      fixed: 'right',
      width: 60,
      filters: [
        { text: 'Оплачено', value: 'PAID' },
        { text: 'Не оплачено', value: 'UNPAID' },
        { text: 'Ожидание', value: 'WAITING' },
        { text: 'Возврат', value: 'REFUND' },
      ],
      defaultFilteredValue: filters.status ? [filters.status] : [],
      filterMultiple: false,
      render: (_, transaction) => <TransactionsTableStatus value={transaction.status} />,
    },
    {
      title: 'Метод оплаты',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      width: 50,
      filters: [
        { text: 'Оплата картой', value: 'CARD' },
        { text: 'Наличные', value: 'CASH' },
        { text: 'СМС / Ссылка', value: 'SMS' },
        { text: 'Через приложение', value: 'APP' },
        { text: 'Виджет', value: 'WIDGET' },
        { text: 'Без оплаты', value: 'NO_PAYMENT' },
      ],
      defaultFilteredValue: filters.paymentMethod ? [filters.paymentMethod] : [],
      filterMultiple: false,
      render: (_, transaction) => {
        return <TableCellText text={formatClientsPaymentMethod(transaction.paymentMethod)} />
      },
    },
    {
      title: 'Операции',
      key: 'action',
      width: 60,
      render: (_, transaction) => (
        <TransactionsTableActions
          transaction={transaction}
          onBarcode={onBarcode}
          onRefundSum={onRefundSum}
          onHistory={onHistory}
        />
      ),
    },
  ]
}

export function genTransactionsTableProductsColumns(): ColumnsType<TransactionsTableDataItemProduct> {
  return [
    {
      title: 'Наименование',
      key: 'products',
      render: (_, product) => <TableCellText text={product.name} />,
    },
    {
      title: 'Стоимость',
      dataIndex: 'sum',
      key: 'sum',
      render: (_, product) => <TableCellPrice value={product.cost} />,
    },
    {
      title: 'Скидка',
      dataIndex: 'discount',
      key: 'discount',
      render: (_, product) => <TableCellText text={String(product.discount)} />,
    },
    {
      title: 'Кол-во',
      dataIndex: 'count',
      key: 'count',
      render: (_, product) => <TableCellText text={String(product.count)} />,
    },
    {
      title: 'Сумма',
      key: 'total',
      render: (_, product) => {
        const total = (product.cost - product.discount) * product.count

        return <TableCellPrice value={total} />
      },
    },
  ]
}

export const genTransactionsTableExpandedRowRender: ExpandedRowRender<TransactionsTableDataItem> = (
  record: TransactionsTableDataItem
) => {
  const columns = genTransactionsTableProductsColumns()

  return <Table dataSource={record.products} columns={columns} pagination={false} loading={false} />
}

export const genTransactionsTableRowExpandable = (transaction: TransactionsTableDataItem): boolean => {
  return isDefAndNotEmpty(transaction.products) && transaction.products.length > 1
}

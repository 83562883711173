import * as React from 'react'
import { Button, Form, Input } from 'antd'
import { MinusCircleOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'

import { PositionsFormGradeProps } from './positions-form-grade.types'
import { usePositionsFormGrade } from './positions-form-grade.hook'

export const PositionsFormGrade: React.FC<PositionsFormGradeProps> = props => {
  const { index, field } = props

  const { disabled, onEditHandler, onSaveHandler, onDeleteHandler } = usePositionsFormGrade(props)

  return (
    <Form.Item label={index === 0 ? 'Грейды' : ''} required={false} key={field.key}>
      <Form.Item
        {...field}
        validateTrigger={['onChange', 'onBlur']}
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Введите название грейда',
          },
        ]}
        noStyle
      >
        <Input placeholder="Название грейда" style={{ width: '60%' }} disabled={disabled} />
      </Form.Item>
      {disabled ? (
        <Button icon={<EditOutlined />} size="large" type="link" onClick={onEditHandler} />
      ) : (
        <Button icon={<SaveOutlined />} size="large" type="link" onClick={onSaveHandler} />
      )}
      <Button icon={<MinusCircleOutlined />} size="large" type="link" onClick={onDeleteHandler} />
    </Form.Item>
  )
}

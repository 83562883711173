import * as React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { isDef } from '../../../../../../types/lang.types'
import { PositionsFormGradeProps } from './positions-form-grade.types'

const { confirm } = Modal

interface Props extends PositionsFormGradeProps {}

export function usePositionsFormGrade(props: Props) {
  const { index, form, positionGrades } = props
  const { onUpdateGrade, onCreateGrade, onRemoveGrade, remove } = props

  const [disabled, setDisabled] = React.useState<boolean>(false)

  const gradeId = React.useMemo(() => {
    return positionGrades && positionGrades[index] && positionGrades[index].id
  }, [index, positionGrades])

  React.useEffect(() => {
    if (isDef(gradeId)) {
      setDisabled(true)
    }
  }, [gradeId])

  const onEditHandler = () => {
    setDisabled(false)
  }

  const onSaveGradeHandler = React.useCallback(() => {
    const value = form.getFieldValue('grades')[index]
    const positionType = form.getFieldValue('positionType')

    if (isDef(gradeId)) {
      onUpdateGrade({
        id: gradeId,
        name: value,
        isMasterService: false,
      })
    } else {
      onCreateGrade(value, positionType)
    }
    setDisabled(true)
  }, [form, gradeId, index, onCreateGrade, onUpdateGrade])

  const onSaveHandler = React.useCallback(async () => {
    form
      .validateFields()
      .then((values: any) => {
        onSaveGradeHandler()
      })
      .catch((errorInfo: any) => {
        if ('values' in errorInfo) {
          console.error('error', JSON.stringify(errorInfo))
        } else {
          onSaveGradeHandler()
        }
      })
  }, [form, onSaveGradeHandler])

  const onRemoveGradeHandler = React.useCallback(
    (gradeId: string) => {
      const value = form.getFieldValue('grades')[index]
      confirm({
        title: `Подтвердите удаление грейда ${value}`,
        icon: <ExclamationCircleOutlined />,
        content: `Вы уверены что хотите удалить грейд: ${value}`,
        onOk: () => onRemoveGrade(gradeId),
      })
    },
    [form, index, onRemoveGrade]
  )

  const onDeleteHandler = React.useCallback(() => {
    if (isDef(gradeId)) {
      onRemoveGradeHandler(gradeId)
    } else remove(index)
  }, [gradeId, index, onRemoveGradeHandler, remove])

  return {
    disabled,
    onEditHandler,
    onSaveHandler,
    onDeleteHandler,
  }
}

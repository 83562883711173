import { PlusOutlined } from '@ant-design/icons'
import { MasterServicesWidgetForm } from '@components/master-services-widget/master-services-widget-form/master-services-widget-form.component'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { genMasterServicesCreatePagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { AppPath } from '../../../types/path.types'
import { useMasterServicesWidgetCreatePageForm } from './master-services-widget-create-page-form.hook'

export const MasterServicesWidgetCreatePageForm = () => {
  const { form, masterServicesOptions, tenantKey } = useMasterServicesWidgetCreatePageForm()

  if (!isDefAndNotEmpty(masterServicesOptions)) {
    return (
      <PageEmpty description="Нет доступных персональных услуг">
        <Button type="primary" icon={<PlusOutlined />}>
          <Link to={genMasterServicesCreatePagePath()}>{formatPathName(AppPath.MASTER_SERVICES_CREATE)}</Link>
        </Button>
      </PageEmpty>
    )
  }

  return <MasterServicesWidgetForm form={form} masterServicesOptions={masterServicesOptions} tenantKey={tenantKey} />
}

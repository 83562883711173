import { SalariesForm } from '../../../components/salaries/salaries-form/salaries-form.component'
import { useSalariesPageFormHook } from './salaries-page-form.hook'

export const SalariesPageForm = () => {
  const {
    form,
    isLoading,
    studiosOptions,
    directionsOptions,
    exercisesTypeOptions,
    gradesOptions,
    onFinishHandler,
    onRemoveHandler,
    onDeselectHandler,
  } = useSalariesPageFormHook()

  return (
    <SalariesForm
      form={form}
      loading={isLoading}
      studiosOptions={studiosOptions}
      directionsOptions={directionsOptions}
      exercisesTypeOptions={exercisesTypeOptions}
      gradesOptions={gradesOptions}
      onFinish={onFinishHandler}
      onRemove={onRemoveHandler}
      onDeselect={onDeselectHandler}
    />
  )
}

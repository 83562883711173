import { call, CallEffect, SagaReturnType } from 'redux-saga/effects'
import { notification } from 'antd'
import axios from 'axios'

import { isDef, isDefAndNotEmpty } from '../types/lang.types'
import { mapViolationsErrorsToNotificationErrors } from '../mapping/api.mapping'

export function* callApi<Fn extends (...args: any[]) => any>(
  fn: Fn,
  ...args: Parameters<Fn>
): Generator<CallEffect<SagaReturnType<Fn>>> {
  try {
    return yield call(fn, ...args)
  } catch (error) {
    if (axios.isAxiosError(error) && isDef(error.response)) {
      const { status } = error.response

      if (status >= 400 && status < 500) {
        notification.error({
          message: error.response.data.message,
          description: isDefAndNotEmpty(error.response.data.violations)
            ? mapViolationsErrorsToNotificationErrors(error.response.data.violations).join(';')
            : '',
        })
      } else {
        notification.error({
          message: 'Внутренняя ошибка сервера',
        })
      }
    }

    throw error
  }
}

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../api/api'
import { masterServicesWidgetCreatePageActions } from './master-services-widget-create-page.slice'
import { callApi } from '../../../utils/sagas.utils'

export function* fetchPageData(_action: ReturnType<typeof masterServicesWidgetCreatePageActions.fetchPageData>) {
  try {
    const response: Awaited<ReturnType<typeof api.masterServicesService.fetchAll>> = yield callApi(
      api.masterServicesService.fetchAll,
      {
        size: 100,
      }
    )

    yield put(masterServicesWidgetCreatePageActions.fetchPageDataSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(masterServicesWidgetCreatePageActions.fetchPageDataError(new Error()))
  }
}

export function* masterServicesWidgetCreatePageSagas() {
  yield takeLatest(masterServicesWidgetCreatePageActions.fetchPageData, fetchPageData)
}

import * as React from 'react'
import { Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'

import { formatDate, formatDurationText, formatIsoStringTOHours, formatToUTCHours } from '../../../format/date.format'
import { isDef, NString, Nullable } from '../../../types/lang.types'
import { DEFAULT_EMPTY_SYMBOL, formatPluralized } from '../../../format/text.format'
import { DictionaryItem } from '../../../api/types/api.types'
import './table-cell-date-time-v2.styles.less'

interface Props {
  date: NString
  format: string
  isShort?: boolean
  duration: string
  createdDate: string
  createdBy: Nullable<DictionaryItem>
}

export const TableCellDateTimeV2: React.FC<Props> = props => {
  const { date, format, isShort, duration, createdDate, createdBy } = props

  const dateFormat = isShort ? 'DD.MM' : 'DD.MM.YY'

  const durationText = formatIsoStringTOHours(duration)

  if (isDef(date)) {
    return (
      <div className="table-cell-date-time-v2">
        <Tooltip
          title={
            <div className="table-cell-date-time-v2__tooltip">
              <div>
                <Typography.Text className="table-cell-date-time-v2__tooltip-text" type="secondary">
                  Забронировано {formatDate(createdDate, 'DD MMMM YYYY в HH:mm')}
                </Typography.Text>
              </div>
              {createdBy && (
                <div>
                  <Typography.Text className="table-cell-date-time-v2__tooltip-text" type="secondary">
                    Создал запись {createdBy?.name}
                  </Typography.Text>
                </div>
              )}
              <div>
                <Typography.Text className="table-cell-date-time-v2__tooltip-text" type="secondary">
                  Запись до {formatToUTCHours(dayjs(date).add(durationText, 'hour').format(), format)}
                </Typography.Text>
              </div>
            </div>
          }
        >
          <Typography.Text>{formatDate(date, dateFormat)}</Typography.Text>
        </Tooltip>
        <Typography.Text className="table-cell-date-time-v2__hint" type="secondary">
          в {formatToUTCHours(date, format)}
        </Typography.Text>
        <Typography.Text className="table-cell-date-time-v2__hint" type="secondary">
          {formatDurationText(duration)}
        </Typography.Text>
      </div>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

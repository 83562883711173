import { FC } from 'react'
import { Button, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { IModalDeleteConfirmProps } from './modal-confirm-delete.types'

export const ModalConfirmDelete: FC<IModalDeleteConfirmProps> = ({ isModalOpen, handleOk, handleCancel }) => {
  return (
    <Modal
      title="Удаление записи"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={handleOk} icon={<DeleteOutlined />}>
          Удалить
        </Button>,
      ]}
    >
      <p>Вы уверены что хотите удалить запись?</p>
    </Modal>
  )
}

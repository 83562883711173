import { Form, FormInstance } from 'antd'
import { useEffect, useState } from 'react'

import { isDef, NNumber } from '../../../../../types/lang.types'
import {
  MasterServicesWidgetShadowFormValues,
  MasterServicesWidgetShadowSliderValues,
} from '../../master-services-widget-form-popovers/master-services-widget-form-popover-shadow/master-services-widget-form-popover-shadow.types'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { DeviceMode } from '../master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'

declare const _smBookingWidget: any

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string[]
  initialValues?: MasterServicesWidgetShadowFormValues
}

export function useMasterServicesWidgetFormUiShadow(props: Props) {
  const { form, element, initialValues } = props
  const [shadowForm] = Form.useForm<MasterServicesWidgetShadowFormValues>()

  const [deviceMode, setDeviceMode] = useState(DeviceMode.DESKTOP)
  const [openPopover, setPopoverOpen] = useState(false)

  const [sliderValues, setSliderValues] = useState<MasterServicesWidgetShadowSliderValues>({
    desktop: {
      blur: 0,
      spread: 0,
      hOffset: 0,
      vOffset: 0,
    },
    mobile: {
      blur: 0,
      spread: 0,
      hOffset: 0,
      vOffset: 0,
    },
  })

  const setWidgetStyles = (
    formValues: MasterServicesWidgetShadowFormValues,
    sliderValues: MasterServicesWidgetShadowSliderValues
  ) => {
    const mode = deviceMode.toLowerCase() as keyof MasterServicesWidgetShadowFormValues
    const color = isDef(formValues[mode].color)
      ? mode.toUpperCase() === DeviceMode.DESKTOP
        ? typeof formValues.desktop.color === 'string'
          ? formValues.desktop.color
          : formValues.desktop.color.toHexString()
        : typeof formValues.mobile.color === 'string'
        ? formValues.mobile.color
        : formValues.mobile.color.toHexString()
      : '#FFF'

    const shadow = `${sliderValues[mode].hOffset}px ${sliderValues[mode].vOffset}px ${sliderValues[mode].blur}px ${sliderValues[mode].spread}px ${color}`
    const inset = formValues[mode].inset ? ' inset' : ''

    _smBookingWidget('event', 'setStyles', undefined, [...element, mode].slice(1).join('.'), shadow + inset)
  }

  const handleSliderValuesChange = (property: string, mode: keyof typeof sliderValues, value: NNumber) => {
    const values = shadowForm.getFieldsValue()

    const newSliderValues = {
      ...sliderValues,
      [mode]: {
        ...sliderValues[mode],
        [property]: value,
      },
    }
    setSliderValues(newSliderValues)
    setWidgetStyles(values, newSliderValues)
  }

  const handleFormValuesChange = (_changedField: any, allFields: MasterServicesWidgetShadowFormValues) => {
    setWidgetStyles(allFields, sliderValues)
  }

  const hidePopover = () => {
    handlePopoverOpenChange(false)
  }

  const handlePopoverOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      const values = shadowForm.getFieldsValue()

      form.setFieldValue(element, {
        desktop: `${sliderValues.desktop.hOffset}px ${sliderValues.desktop.vOffset}px ${sliderValues.desktop.blur}px ${
          sliderValues.desktop.spread
        }px ${typeof values.desktop.color === 'string' ? values.desktop.color : values.desktop.color?.toHexString()} ${
          values.desktop.inset ? ' inset' : ''
        }`,
        mobile: `${sliderValues.mobile.hOffset}px ${sliderValues.mobile.vOffset}px ${sliderValues.mobile.blur}px ${
          sliderValues.mobile.spread
        }px ${typeof values.mobile.color === 'string' ? values.mobile.color : values.mobile.color?.toHexString()} ${
          values.mobile.inset ? ' inset' : ''
        }`,
      })
    }
    setPopoverOpen(newOpen)
  }

  useEffect(() => {
    if (initialValues) {
      setSliderValues({
        desktop: {
          blur: initialValues.desktop?.blur ? initialValues.desktop.blur : 0,
          spread: initialValues.desktop?.spread ? initialValues.desktop.spread : 0,
          hOffset: initialValues.desktop?.hOffset ? initialValues.desktop.hOffset : 0,
          vOffset: initialValues.desktop?.vOffset ? initialValues.desktop.vOffset : 0,
        },
        mobile: {
          blur: initialValues.mobile?.blur ? initialValues.mobile.blur : 0,
          spread: initialValues.mobile?.spread ? initialValues.mobile.spread : 0,
          hOffset: initialValues.mobile?.hOffset ? initialValues.mobile.hOffset : 0,
          vOffset: initialValues.mobile?.vOffset ? initialValues.mobile.vOffset : 0,
        },
      })
    }
  }, [initialValues])

  return {
    shadowForm,
    deviceMode,
    sliderValues,
    openPopover,
    setDeviceMode,
    handleSliderValuesChange,
    hidePopover,
    handlePopoverOpenChange,
    handleFormValuesChange,
  }
}

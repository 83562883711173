import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../api/types/api.types'
import { Nullable } from '../../../types/lang.types'
import { ExercisesTypesPageParams } from '../../../pages/exercises-types-page/exercises-types-page.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'

export interface ExercisesTypesPageState {
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ExercisesTypesPageState = {
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: exercisesTypesPageActions, reducer: exercisesTypesPageReducer } = createSlice({
  name: '@@exercises-types-page',
  initialState,
  reducers: {
    fetchAllExercisesTypes: (state: Draft<ExercisesTypesPageState>, _: PayloadAction<ExercisesTypesPageParams>) => {
      state.isLoading = true
    },
    fetchAllExercisesTypesSuccess: (
      state: Draft<ExercisesTypesPageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercisesTypes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllExercisesTypesError: (state: Draft<ExercisesTypesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeExercisesType: (state: Draft<ExercisesTypesPageState>, _: PayloadAction<number>) => {
      state.isLoading = true
    },
    removeExercisesTypeSuccess: (state: Draft<ExercisesTypesPageState>) => {
      state.isLoading = false
    },
    removeExercisesTypeError: (state: Draft<ExercisesTypesPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

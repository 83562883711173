import { combineReducers } from '@reduxjs/toolkit'

import { transactionsPageModalRefundReducer } from './transactions-page-modal-refund/transactions-page-modal-refund.slice'
import { transactionsPageCommonReducer } from './transactions-page-common/transactions-page-common.slice'
import { transactionsPagePeekHistoryReducer } from './transactions-page-peek-history/transactions-page-peek-history.slice'

export const transactionsPageReducer = combineReducers({
  transactionsPageModalRefund: transactionsPageModalRefundReducer,
  transactionsPageCommon: transactionsPageCommonReducer,
  transactionsPagePeekHistory: transactionsPagePeekHistoryReducer,
})

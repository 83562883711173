import { Tooltip, Typography } from 'antd'

interface Props {
  title: string
  text: string
}
export const TableCellTooltip: React.FC<Props> = props => {
  const { title, text } = props
  return (
    <Tooltip title={title}>
      <Typography.Text>{text}</Typography.Text>
    </Tooltip>
  )
}

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { isDef } from '../../types/lang.types'
import { positionsEditPageActions } from '../../store/pages/positions-edit-page/positions-edit-page.slice'
import { usePositionsEditPageParams } from './positions-edit-page-hooks/positions-edit-page-params.hook'

export function usePositionsEditPage() {
  const { id } = usePositionsEditPageParams()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDef(id)) {
      dispatch(positionsEditPageActions.fetchPageData(id))
    }
  }, [dispatch, id])

  React.useEffect(() => {
    return () => {
      dispatch(positionsEditPageActions.reset())
    }
  }, [dispatch])
}

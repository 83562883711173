import { Table, TablePaginationConfig } from 'antd'
import * as React from 'react'

import './exercises-group-bookings-table.styles.less'

import {
  ExercisesGroupBookingsTableDataItem,
  ExercisesGroupBookingsTableEvents,
} from './exercises-group-bookings-table.types'
import { genExercisesGroupBookingsTableColumns } from './exercises-group-bookings-table.utils'

interface Props extends ExercisesGroupBookingsTableEvents {
  scheduleId: string
  className?: string
  data: ExercisesGroupBookingsTableDataItem[]
  pagination?: TablePaginationConfig
  loading?: boolean
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export const ExercisesGroupBookingsTable: React.FC<Props> = props => {
  const { className } = props
  const { data, pagination, loading, onNumber } = props
  const { onVisit, onComment, onCancel, onChangePage, onChangePageSize, onChangePaymentType } = props
  const { scheduleId } = props

  const columns = React.useMemo(
    () =>
      genExercisesGroupBookingsTableColumns({
        onVisit,
        onComment,
        onCancel,
        onNumber,
        onChangePaymentType,
        scheduleId,
      }),
    [onVisit, onComment, onCancel, onNumber, onChangePaymentType, scheduleId]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <Table
      className={className}
      rowKey="id"
      columns={columns}
      dataSource={(data ?? []).sort((a, b) => {
        if (a.paymentType === 'RESERVED' && b.paymentType !== 'RESERVED') return 1
        else if (a.paymentType !== 'RESERVED' && b.paymentType === 'RESERVED') return -1
        return 0
      })}
      pagination={pagination?.total && pagination.total >= 50 ? paginationConfig : false}
      loading={loading}
      rowClassName={record => {
        if (record.isCancelled) {
          return 'exercises-group-bookings-table__disabled-row'
        }
        if (record.clientComment) {
          return 'exercises-group-bookings-table__row-with-comment'
        }
        return ''
      }}
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: record => <div>{record.clientComment}</div>,
        rowExpandable: record => record.clientComment !== null,
        showExpandColumn: false,
        expandedRowClassName: record => 'exercises-group-bookings-table__user-comment',
      }}
    />
  )
}

import { Typography } from 'antd'

import './custom-fields-page-top-bar.styles.less'

export const CustomFieldsPageTopBar = () => {
  return (
    <div className="custom-fields-top-bar">
      <Typography.Title level={2}>Кастомизация данных</Typography.Title>
    </div>
  )
}

import { CustomFieldsTableRow } from '@components/custom-fields/custom-fields-table/custom-fields-table.types'

import { CustomFieldsApi } from '../api/types/custom-fields-api.types'
import { isDefAndNotEmpty, Nullable } from '../types/lang.types'

export function mapCustomFieldsToDirectionsTableRowList(
  customFields: Nullable<CustomFieldsApi.CustomField[]>
): Nullable<CustomFieldsTableRow[]> {
  if (isDefAndNotEmpty(customFields)) {
    return customFields.map((fields: CustomFieldsApi.CustomField) => ({
      id: fields.id,
      name: fields.name,
      description: fields.description,
      type: fields.type,
      placeholder: fields.attributes.placeholder,
      default: fields.attributes.default || '',
      enabled: fields.enabled,
      required: fields.required,
      options: fields?.attributes?.options || [],
    }))
  }

  return null
}

export const mapCustomFieldsFilterEnabled = (
  customFields: Nullable<CustomFieldsApi.CustomField[]>
): Nullable<CustomFieldsApi.CustomField[]> =>
  isDefAndNotEmpty(customFields) ? customFields.filter(field => field.enabled) : null

export function genCustomFieldDTO(data: CustomFieldsApi.CustomField): Nullable<CustomFieldsApi.CustomField> {
  return {
    ...data,
  }
}

export const mapCustomFieldsEmptyValueToFields = (
  customFields: Nullable<CustomFieldsApi.CustomField[]>
): Nullable<CustomFieldsApi.CustomField[]> => {
  return isDefAndNotEmpty(customFields)
    ? customFields.map(field => ({
        ...field,
        value: [],
      }))
    : null
}

import { useEffect, useState } from 'react'
import { Flex, Tag } from 'antd'
import { EMPLOYEE_SCHEDULE_STATUS } from '@constants/employees/schedule'

import { IStatusCellProps } from './status-cell.types'

export const StatusCell: React.FC<IStatusCellProps> = ({ status }) => {
  const [tagParams, setTagParams] = useState({
    color: 'default',
    text: 'Неизвестный статус',
  })

  useEffect(() => {
    switch (status) {
      case EMPLOYEE_SCHEDULE_STATUS.ACTIVE:
        setTagParams({
          color: 'green',
          text: 'Установлено',
        })
        break
      case EMPLOYEE_SCHEDULE_STATUS.ARCHIVE:
        setTagParams({
          color: 'default',
          text: 'Архив',
        })
        break
      case EMPLOYEE_SCHEDULE_STATUS.DRAFT:
        setTagParams({
          color: 'blue',
          text: 'Черновик',
        })
        break
      case EMPLOYEE_SCHEDULE_STATUS.PAUSED:
        setTagParams({
          color: 'orange',
          text: 'Приостановлено',
        })
        break
    }
  }, [])

  return (
    <Flex>
      <Tag color={tagParams.color}>{tagParams.text}</Tag>
    </Flex>
  )
}

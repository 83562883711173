import { AxiosResponse } from 'axios'

import { api } from '../../../../../api/api'
import { TillsApi } from '../../../../../api/types/tills-api.types'
import { KkmAPI } from '../../../../../kkm-api/kkm'
import { IToggleShiftResponse } from '../../../../../kkm-api/types'
import { IOpenShiftInternalResult } from '../../types/utils'
import { notificationsShifts } from './notification-shifts'
import { openShiftOnPC } from './open-shift'
import { getCurrentUser } from '../user/get-current-user'

export const closeShiftOnPC = async (numDevice: number, idDevice: string) => {
  const [currentUserName, currentUserINN] = await getCurrentUser()

  let result: IOpenShiftInternalResult<IToggleShiftResponse> = {
    hasError: false,
    response: null,
  }

  let hasError = false
  result.response = await KkmAPI.closeShift(numDevice, idDevice, currentUserName, currentUserINN).catch(err => {
    hasError = true
    console.error(err)
  })

  if (hasError) notificationsShifts.errorCloseShiftOnPC()

  return result
}

const closeShiftInDB = async (tillId: string, tillShiftId: string, kkmResponse: IToggleShiftResponse) => {
  let result: IOpenShiftInternalResult<AxiosResponse<TillsApi.IToggleShistResponse, any>> = {
    hasError: false,
    response: null,
  }

  result.response = await api.tillsService.closeShift(tillId, tillShiftId, kkmResponse).catch(err => {
    result.hasError = true
    console.error(err)
  })

  if (result.hasError) notificationsShifts.errorCloseShiftOnPC()

  return result
}

export const closeShift = async (
  tillId: string | null,
  tillShiftId: string | null | undefined,
  numDevice: number | undefined,
  idDevice: string | undefined,
  successCallback: () => void
) => {
  if (!tillId || !numDevice || !idDevice || !tillShiftId) {
    notificationsShifts.errorCloseShift()
    return
  }

  const kkmResponse = await closeShiftOnPC(numDevice, idDevice)

  if (!kkmResponse.hasError && kkmResponse?.response) {
    const result = await closeShiftInDB(tillId, tillShiftId, kkmResponse.response)
    if (result.hasError) {
      openShiftOnPC(numDevice, idDevice)
    } else {
      successCallback()
    }
  }
}

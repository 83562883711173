import * as React from 'react'
import { Button, Layout, Typography } from 'antd'
import { useHistory } from 'react-router-dom'

import { Logo } from '../../icons/logo.component'
import { genHomePagePath } from '../../../format/path.format'
import './auth-layout.styles.less'

interface Props {
  onLogin: () => void
}

export const AuthLayout: React.FC<Props> = props => {
  const { push } = useHistory()
  const { onLogin } = props

  const login = () => {
    push(genHomePagePath())
    onLogin()
  }

  return (
    <Layout className="auth-layout">
      <Logo />

      <Typography.Text>Необходимо войти в систему</Typography.Text>

      <Button size="large" type="primary" onClick={login}>
        Вход
      </Button>
    </Layout>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genEmployeeAuditInternal = (state: AppState) => state.employeesPage.employeesPagePeekHistory.employeeAudit

export const genEmployeePagePeekHistoryIsLoading = (state: AppState) =>
  state.employeesPage.employeesPagePeekHistory.isLoading

export const genEmployeePagePeekHistoryTotalElements = createSelector(
  genEmployeeAuditInternal,
  audit => audit?.totalElements
)

export const genEmployeePagePeekHistoryEmployeeHistory = createSelector(
  genEmployeeAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

import React from 'react'

import { genMasterServicesFormSubservicesCategoryValidationRules } from './master-services-form-subservices-service-category.utils'

export const useMasterServicesFormSubservicesCategory = () => {
  const categoryValidationRules = React.useMemo(genMasterServicesFormSubservicesCategoryValidationRules, [])

  return {
    categoryValidationRules,
  }
}

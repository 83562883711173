import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Checkbox, DatePicker, Form, Space, TimePicker } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { FC } from 'react'

import { EmployeeScheduleDaysOffFormProps } from './days-off-form.types'

export const EmployeeScheduleDaysOffForm: FC<EmployeeScheduleDaysOffFormProps> = props => {
  const { form, isEdit, loading, onCreateDayOff, onEditDayOff, onRemoveDayOff } = props

  const onCreateDayOffHandler = (name: number) => {
    form
      .validateFields([
        ['daysOff', name, 'dates'],
        ['daysOff', name, 'time'],
      ])
      .then((_values: any) => {
        const formValues = form.getFieldsValue()
        const dayOff = formValues.daysOff && formValues.daysOff[name] ? formValues.daysOff[name] : null

        if (dayOff && onCreateDayOff) {
          onCreateDayOff(dayOff)
        }
      })
      .catch(errors => {})
  }

  const onEditDayOffHandler = (name: number) => {
    if (onEditDayOff) {
      const formValues = form.getFieldsValue()
      const dayOff = formValues.daysOff && formValues.daysOff[name] ? formValues.daysOff[name] : null

      if (dayOff?.id) {
        onEditDayOff(dayOff)
      }
    }
  }

  const onRemoveDayOffHandler = (name: number, remove: (index: number | number[]) => void) => {
    const formValues = form.getFieldsValue()
    const dayOffId = formValues.daysOff && formValues.daysOff[name] ? formValues.daysOff[name].id : null

    const removeDayOff = () => {
      remove(name)
    }

    if (dayOffId && onRemoveDayOff) {
      onRemoveDayOff(dayOffId, removeDayOff)
    } else {
      remove(name)
    }
  }

  const handleIsAllDayCheckboxChange = (checked: boolean, name: number) => {
    if (checked) {
      onEditDayOffHandler(name)
    }
  }

  return (
    <Form form={form}>
      <Form.List name="daysOff">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'dates']}
                    rules={[{ required: true, message: 'Выберите даты' }]}
                  >
                    <DatePicker.RangePicker
                      placeholder={['Дата начала', 'Дата окончания']}
                      style={{ width: '320px' }}
                      format="YYYY-MM-DD"
                      allowClear={false}
                      disabled={loading}
                      onChange={() => onEditDayOffHandler(name)}
                    />
                  </Form.Item>

                  <Form.Item name={[name, 'isAllDay']} valuePropName="checked" noStyle>
                    <Checkbox
                      disabled={loading}
                      onChange={(e: CheckboxChangeEvent) => handleIsAllDayCheckboxChange(e.target.checked, name)}
                    >
                      Весь день
                    </Checkbox>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues['daysOff']?.[name]?.isAllDay !== curValues['daysOff']?.[name]?.isAllDay
                    }
                  >
                    {({ getFieldValue }) =>
                      !getFieldValue(['daysOff', name, 'isAllDay']) ? (
                        <Form.Item
                          {...restField}
                          name={[name, 'time']}
                          rules={[{ required: true, message: 'Укажите время' }]}
                        >
                          <TimePicker.RangePicker
                            placeholder={['Время от', 'Время до']}
                            format="HH:mm"
                            allowClear={false}
                            disabled={loading}
                            onChange={() => onEditDayOffHandler(name)}
                          />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>

                  {isEdit && (
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) =>
                        prevValues['daysOff']?.[name]?.id !== curValues['daysOff']?.[name]?.id
                      }
                    >
                      {({ getFieldValue }) =>
                        !getFieldValue(['daysOff', name, 'id']) ? (
                          <Button
                            disabled={loading}
                            style={{ borderColor: '#1677FF' }}
                            icon={<SaveOutlined style={{ color: '#1677FF' }} />}
                            onClick={() => onCreateDayOffHandler(name)}
                          />
                        ) : null
                      }
                    </Form.Item>
                  )}
                  <Button
                    disabled={loading}
                    icon={<DeleteOutlined />}
                    onClick={() => onRemoveDayOffHandler(name, remove)}
                    danger
                  />
                </Space>
              )
            })}
            <Form.Item>
              <Button
                type="dashed"
                disabled={loading}
                onClick={() =>
                  add({
                    isAllDay: true,
                  })
                }
              >
                Добавить условие
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}

export function genEnvelopeShadowStyles() {
  return {
    desktop: {
      color: 'rgba(0, 0, 0, 0.12)',
      blur: 24,
      spread: 0,
      hOffset: 0,
      vOffset: 4,
      inset: false,
    },
    mobile: {
      color: 'rgba(0, 0, 0, 0.12)',
      blur: 24,
      spread: 0,
      hOffset: 0,
      vOffset: 4,
      inset: false,
    },
  }
}

import { CustomFieldsModalCreate } from '../../../components/custom-fields/custom-fields-modal-create/custom-fields-modal.component'
import { useCustomFieldsPageModalCreate } from './custom-fields-page-modal-create.hook'
import { CustomFieldsPageModalCreateProps } from './custom-fields-page-modal-create.types'

export const CustomFieldsPageModalCreate = (props: CustomFieldsPageModalCreateProps) => {
  const { onCancelHandler, form, isLoading } = useCustomFieldsPageModalCreate()

  return (
    <CustomFieldsModalCreate
      loading={isLoading}
      form={form}
      onCancel={onCancelHandler}
      title="Создание кастомного поля"
    />
  )
}

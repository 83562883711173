import React from 'react'
import { Button, Col, Divider, Flex, Form, Input, InputNumber, Row, Space, Switch, Tag, Typography } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'

import { MasterServicesFormSubservicesServiceCategory } from './master-services-form-subservices-service-category/master-services-form-subservices-service-category.component'
import { useMasterServicesFormSubservice } from './master-services-form-subservice.hook'
import { MasterServicesFormSubserviceProps } from './master-services-form-subservice.types'
import { MasterServicesFormSubservicesAvailableStudioRooms } from './master-services-form-subservices-available-studio-rooms/master-services-form-subservices-available-studio-rooms.component'
import { ImageUploadGallery } from '../../../../image-upload-gallery/image-upload-gallery'
import { MasterServicesFormSubservicePricing } from './master-services-form-subservice-pricing/master-services-form-subservice-pricing.component'
import { MasterServicesFormSubserviceExecutors } from './master-services-form-subservice-executors/master-services-form-subservice-executors.component'
import { CustomCollapsePanel } from '../../../../custom-collapse-panel/custom-collapse-panel.component'
import { CustomCard } from '../../../../custom-card/custom-card.component'
import './master-services-form-subservice.styles.less'

export const MasterServicesFormSubservice: React.FC<MasterServicesFormSubserviceProps> = props => {
  const {
    isEdit,
    fields,
    field,
    form,
    index,
    activeKeys,
    trainers,
    studiosOptions,
    studiosRoomsOptions,
    serviceCategoriesOptions,
    trainersOptions,
    pricingOptions,
    onCollapseChange,
    remove,
    onAddServiceCategory,
    onSaveSubServiceHandler,
    onRemoveSubServiceHandler,
    onCopyPricingHandler,
    onRemovePricingHandler,
    onOpenPricingModal,
    ...rest
  } = props

  const {
    onlineSubServiceWatcher,
    linkWatcher,
    panelHeader,
    nameValidationRules,
    durationHoursValidationRules,
    durationMinutesValidationRules,
    technicalBreakDurationValidationRules,
    photos,
    isReviewHidden,
    isTechnicalBreakEnabled,
    toggleReviewHidden,
    toggleTechnicalBreakEnabled,
    onChangePhotos,
    onEditHandler,
    onCancelHandler,
    onSaveHandler,
    onRemoveHandler,
  } = useMasterServicesFormSubservice({
    form,
    fields,
    index,
    activeKeys,
    onCollapseChange,
    onSaveSubServiceHandler,
    onRemoveSubServiceHandler,
    remove,
  })

  const toggleIsNotRequiredTrainer = (value: boolean) => {
    if (!value) {
      const trainers: string[] = form.getFieldValue(['subServices', index, 'trainers']) ?? []
      if (trainers.includes('withoutTrainer')) {
        form.setFieldValue(
          ['subServices', index, 'trainers'],
          trainers.filter(trainer => trainer !== 'withoutTrainer')
        )
      }
    }
  }

  return (
    <CustomCollapsePanel
      index={`${index}`}
      panelHeader={panelHeader}
      isActive={activeKeys.includes(index.toString())}
      customClassName="master-services-form-subservice"
      onEditHandler={onEditHandler}
      onRemoveHandler={onRemoveHandler}
      {...rest}
    >
      <Flex vertical gap="large">
        <Flex gap="5px" vertical>
          <Form.Item
            name={[field.name, 'selectSpace']}
            label="Выбор места"
            tooltip="Активируйте это свойство, если хотите, чтобы клиенты могли выбирать место при записи на услугу. Убедитесь, что карта мест настроена в настройках зала или студии, где будет предоставляться эта услуга."
            className="master-services-form-subservice__custom-form-item master-services-form-subservice__custom-form-item--disabled"
          >
            <Switch size="small" disabled />
          </Form.Item>
          <Form.Item
            name={[field.name, 'onlineSubService']}
            label="Услуга предоставляется онлайн"
            tooltip="Активируется автоматическая генерация ссылки через Google Meet или Zoom. Эта ссылка будет отправлена за 2 часа до начала записи клиента."
            className="master-services-form-subservice__custom-form-item master-services-form-subservice__custom-form-item--disabled"
          >
            <Switch size="small" disabled />
          </Form.Item>
          <Form.Item
            name={[field.name, 'limitedSales']}
            label="Ограниченное количество продаж"
            tooltip="Такая услуга автоматически исчезнет из клиентских виджетов и выбора в разделе транзакций для менеджеров после продажи последней единицы. Благодаря этому свойству вы сможете ограничить количество продаж."
            className="master-services-form-subservice__custom-form-item master-services-form-subservice__custom-form-item--disabled"
          >
            <Switch size="small" disabled />
          </Form.Item>
          <Form.Item
            name={[field.name, 'allowsMultiBooking']}
            label="Разрешить длинную запись"
            tooltip="Активируйте это свойство, и клиенты смогут выбирать в виджетах, до какой даты повторять запись в выбранный день недели. Это позволит вам получать предоплату сразу за множество записей вперед. При необходимости вы сможете отменять конкретные записи и возвращать оплату не за все записи сразу."
            className="master-services-form-subservice__custom-form-item master-services-form-subservice__custom-form-item--disabled"
          >
            <Switch size="small" disabled />
          </Form.Item>
          <Form.Item
            name={[field.name, 'allowsContinuousBooking']}
            label="Разрешить бронировать несколько слотов подряд"
            tooltip="Активируйте это свойство, чтобы клиенты могли бронировать сразу несколько слотов подряд. Условие цены сформируется автоматически согласно ценообразованию для этой услуги."
            className="master-services-form-subservice__custom-form-item"
          >
            <Switch size="small" />
          </Form.Item>
          <Form.Item
            name={[field.name, 'isNotRequiredTrainer']}
            label="Выбор исполнителя не обязателен"
            tooltip="На этапе выбора исполнителя клиент сможет выбрать «без исполнителя». Это свойство полезно, когда исполнитель не обязателен. Например: аренда спортивного поля, а тренер как полезная но необязательная услуга."
            className="master-services-form-subservice__custom-form-item"
          >
            <Switch size="small" onChange={toggleIsNotRequiredTrainer} />
          </Form.Item>
          <Form.Item
            name={[field.name, 'hideInWidget']}
            label="Только для внутреннего использования"
            tooltip="Активируйте это свойство, чтобы скрыть услугу из клиентских виджетов. Эту услугу смогут использовать только сотрудники."
            className="master-services-form-subservice__custom-form-item"
          >
            <Switch size="small" />
          </Form.Item>
          <Flex gap="small" align="baseline">
            <Form.Item name={[field.name, 'technicalBreakEnabled']}>
              <Switch size="small" onChange={toggleTechnicalBreakEnabled} />
            </Form.Item>
            <Flex vertical gap="small">
              <Typography.Text
                className="master-services-form-subservice__toggled-label"
                onClick={toggleTechnicalBreakEnabled}
              >
                Включить технический перерыв между бронированиями
              </Typography.Text>
              {isTechnicalBreakEnabled && (
                <CustomCard customClassName="master-services-form-subservice__card">
                  <Flex gap="middle">
                    <Form.Item
                      name={[field.name, 'technicalBreakBeforeDuration']}
                      label="До брони"
                      rules={technicalBreakDurationValidationRules}
                    >
                      <InputNumber size="large" suffix="мин" placeholder="0" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'technicalBreakAfterDuration']}
                      label="После брони"
                      rules={technicalBreakDurationValidationRules}
                    >
                      <InputNumber size="large" suffix="мин" placeholder="0" />
                    </Form.Item>
                  </Flex>
                </CustomCard>
              )}
            </Flex>
            <QuestionTooltip title="Включите это свойство и вы сможете установить автоматический перерыв до или после записи гостя, например, для подготовки рабочего места." />
          </Flex>
        </Flex>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={[field.name, 'name']}
              label="Название под-услуги"
              rules={nameValidationRules}
              tooltip="Это название будете видеть не только вы, но и клиенты."
            >
              <Input size="large" placeholder="Название" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <MasterServicesFormSubservicesServiceCategory
              options={serviceCategoriesOptions}
              onAdd={onAddServiceCategory}
              formItemName={[field.name, 'serviceCategoryId']}
            />
          </Col>
          <Col span={6}>
            <Flex vertical gap="small">
              <Typography.Text className="master-services-form-subservice__label">Длительность</Typography.Text>
              <Flex gap="middle">
                <Form.Item name={[field.name, 'duration', 'hours']} rules={durationHoursValidationRules}>
                  <InputNumber
                    size="large"
                    suffix="ч"
                    placeholder="0"
                    className="master-services-form-subservice__input-number"
                    onChange={() => form.validateFields([['subServices', index, 'duration', 'minutes']])}
                  />
                </Form.Item>
                <Form.Item name={[field.name, 'duration', 'minutes']} rules={durationMinutesValidationRules}>
                  <InputNumber
                    size="large"
                    suffix="мин"
                    placeholder="0"
                    className="master-services-form-subservice__input-number"
                  />
                </Form.Item>
              </Flex>
            </Flex>
          </Col>
        </Row>
        <Form.Item
          name={[field.name, 'description']}
          label="Описание под-услуги"
          tooltip="Это будут видеть ваши клиенты. Чем подробнее распишите, тем понятнее будет вашим клиентам."
        >
          <Input.TextArea size="large" rows={2} placeholder="Описание" />
        </Form.Item>
        <Divider className="master-services-form-subservice__divider" />
        <Typography.Title level={4} className="master-services-form-subservice__title">
          Где и кем будет выполняться услуга
        </Typography.Title>
        <MasterServicesFormSubservicesAvailableStudioRooms
          field={field}
          form={form}
          studiosOptions={studiosOptions}
          studiosRoomsOptions={studiosRoomsOptions}
        />

        <MasterServicesFormSubserviceExecutors form={form} field={field} trainersOptions={trainersOptions} />

        <Row gutter={16}>
          <Col span={18}>
            <Tag
              className="master-services-form-subservice__tag"
              icon={<InfoCircleFilled style={{ fontSize: '16px' }} />}
              color="blue"
              closable
            >
              <p>
                С помощью графика сотрудников вы сможете распределять конкретных исполнителей по залам и студиям,
                указывая не только их рабочие места, но и время работы в этих местах.
              </p>
            </Tag>
          </Col>
        </Row>
        {onlineSubServiceWatcher && (
          <Form.Item name={[field.name, 'link']} label="Ссылка">
            <Input
              suffix={
                <CopyToClipboard text={linkWatcher}>
                  <Button type="link">Скопировать</Button>
                </CopyToClipboard>
              }
            />
          </Form.Item>
        )}
        <Divider className="master-services-form-subservice__divider" />
        <Typography.Title level={4} className="master-services-form-subservice__title">
          Стоимость услуги
        </Typography.Title>

        <MasterServicesFormSubservicePricing
          isEdit={isEdit}
          form={form}
          field={field}
          pricingOptions={pricingOptions}
          onOpenPricingModal={onOpenPricingModal}
          onCopyPricing={onCopyPricingHandler}
          onRemovePricing={onRemovePricingHandler}
        />

        <Divider className="master-services-form-subservice__divider" />
        <Typography.Title level={4} className="master-services-form-subservice__title">
          Дополнительная продажа
        </Typography.Title>
        <Input.Search size="large" />
        <Divider className="master-services-form-subservice__divider" />
        <Flex vertical gap="small">
          <Typography.Title level={4} className="master-services-form-subservice__title">
            Галерея
          </Typography.Title>
          <Typography.Text className="master-services-form-subservice__label">
            Загрузите фото или видео. Допустимые форматы: GIF, JPG, MOV, GIF, MP4, MPEG, MKV
          </Typography.Text>
        </Flex>
        <Form.Item name={[field.name, 'photos']}>
          <ImageUploadGallery fileList={photos} onChange={onChangePhotos} />
        </Form.Item>
        <Form.Item name={[field.name, 'reviewHidden']}>
          <Space align="center" size="small">
            <Switch size="small" value={isReviewHidden} onChange={toggleReviewHidden} />
            <Typography.Text className="master-services-form-subservice__toggled-label" onClick={toggleReviewHidden}>
              Скрывать отображение отзывов об услуге
            </Typography.Text>
          </Space>
        </Form.Item>
        <Divider className="master-services-form-subservice__divider" />
        <Space size="middle">
          <Button disabled={fields.length === 1} onClick={onCancelHandler}>
            Отменить
          </Button>
          <Button type="primary" onClick={onSaveHandler}>
            Сохранить
          </Button>
        </Space>
      </Flex>
    </CustomCollapsePanel>
  )
}

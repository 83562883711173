import * as React from 'react'
import { Button, Space, Tooltip } from 'antd'
import CopyToClipboard from 'react-copy-to-clipboard'
import { CloseOutlined, HistoryOutlined, LinkOutlined, QrcodeOutlined } from '@ant-design/icons'
import OneClickButton from '@components/ui/one-click-button/one-click-button.component'

import { TransactionsTableDataItem } from '../transactions-table.types'
import { TransactionsTableActionsEvents } from './transactions-table-actions.types'
import { TransactionStatus } from '../../../../types/transactions.types'
import { checkIsOnline } from '../../../../utils/status.utils'

interface Props extends TransactionsTableActionsEvents {
  transaction: TransactionsTableDataItem
}

export const TransactionsTableActions: React.FC<Props> = props => {
  const { transaction } = props
  const { onBarcode } = props
  const { onRefundSum } = props
  const { onHistory } = props

  const onBarcodeHandler = React.useCallback((): void => {
    onBarcode(transaction)
  }, [onBarcode, transaction])

  const onRefundSumHandler = React.useCallback((): void => {
    onRefundSum(transaction)
  }, [onRefundSum, transaction])

  const onHistoryHandler = React.useCallback(() => onHistory(transaction.id), [transaction.id, onHistory])

  const isPaid = transaction.status === TransactionStatus.PAID
  const isRefunded = transaction.status === TransactionStatus.REFUND
  const isUnpaidorWaiting =
    transaction.status === TransactionStatus.UNPAID || transaction.status === TransactionStatus.WAITING

  const paymentLink = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl

  const isOnline = checkIsOnline(transaction.paymentMethod)

  return (
    <Space size="middle">
      <Tooltip title="Посмотреть историю изменений">
        <Button icon={<HistoryOutlined />} size="middle" onClick={onHistoryHandler} />
      </Tooltip>
      {(isPaid || isRefunded) && (
        <Tooltip title="Чеки по транзакции">
          <Button icon={<QrcodeOutlined />} size="middle" onClick={onBarcodeHandler} />
        </Tooltip>
      )}
      {paymentLink && isUnpaidorWaiting && isOnline && (
        <Tooltip title="Скопировать ссылку на оплату">
          <CopyToClipboard text={paymentLink}>
            <Button icon={<LinkOutlined />} size="middle" />
          </CopyToClipboard>
        </Tooltip>
      )}
      {isPaid && (
        <Tooltip title="Возврат">
          <OneClickButton icon={<CloseOutlined />} size="middle" onClick={onRefundSumHandler} danger ghost />
        </Tooltip>
      )}
    </Space>
  )
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import {
  ExercisesTypesEditPageDataSuccessPayload,
  ExercisesTypesEditPageUpdateExercisesTypePayload,
} from './exercises-types-edit-page.types'
import { Pagination } from '../../../api/types/api.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'

export interface ExercisesTypesEditPageState {
  exercisesType: Nullable<ExercisesTypesApi.ExerciseType>
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ExercisesTypesEditPageState = {
  exercisesType: null,
  directions: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: exercisesTypesEditPageActions, reducer: exercisesTypesEditPageReducer } = createSlice({
  name: '@exercises-type-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<ExercisesTypesEditPageState>, _: PayloadAction<number>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<ExercisesTypesEditPageState>,
      action: PayloadAction<ExercisesTypesEditPageDataSuccessPayload>
    ) => {
      const { exercisesType, directions } = action.payload
      state.exercisesType = exercisesType
      state.directions = directions
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<ExercisesTypesEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updateExercisesType: (
      state: Draft<ExercisesTypesEditPageState>,
      _: PayloadAction<ExercisesTypesEditPageUpdateExercisesTypePayload>
    ) => {
      state.isLoading = true
    },
    updateExercisesTypeSuccess: (state: Draft<ExercisesTypesEditPageState>) => {
      state.isLoading = false
    },
    updateExercisesTypeError: (state: Draft<ExercisesTypesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import { PlusOutlined } from '@ant-design/icons'
import { Col, Flex, Form, Input, Row, Upload } from 'antd'

import { UPLOAD_URL } from '../../../../app/app.config'
import { genTokenHeader } from '../../../../utils/http.utils'
import { useStudiosFormLocation } from './studios-form-location.hook'
import { StudiosFormLocationTypes } from './studios-form-location.types'

export const StudiosFormLocation: React.FC<StudiosFormLocationTypes> = props => {
  const { form, field } = props

  const { token, locationMapUrlValidationRules, imageUrl, onChangeLocationPhotoUrl } = useStudiosFormLocation({
    form,
    field,
  })

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Загрузить</div>
    </div>
  )

  return (
    <Flex gap="middle" vertical>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Описание" name={[...(field ? [field.name] : []), 'locationDescription']}>
            <Input.TextArea size="large" style={{ height: 100 }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Схема-проезда"
            name={[...(field ? [field.name] : []), 'locationPhotoUrl']}
            getValueFromEvent={file => {
              return file.file.response
            }}
          >
            <Upload
              method="PUT"
              action={UPLOAD_URL}
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              onChange={onChangeLocationPhotoUrl}
              maxCount={1}
              headers={{ Authorization: genTokenHeader(token) || '' }}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Ссылка на точку в картах"
            extra="Google, Yandex, 2gis — вы можете указать любую ссылку"
            name={[...(field ? [field.name] : []), 'locationMapUrl']}
            rules={locationMapUrlValidationRules}
          >
            <Input size="large" placeholder="https://..." />
          </Form.Item>
        </Col>
      </Row>
    </Flex>
  )
}

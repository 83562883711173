import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { employeesCreatePageActions } from '../../store/pages/employees-create-page/employees-create-page.slice'
import { isDef } from '../../types/lang.types'
import { genEmployeesPageParams } from '../employees-page/employees-page.utils'

export function useEmployeesCreatePage() {
  const history = useHistory<{ search: string }>()
  const state = history.location.state
  const pageParams = isDef(state) && genEmployeesPageParams(state.search)
  const type = pageParams ? pageParams.type : null

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(employeesCreatePageActions.fetchPageData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(employeesCreatePageActions.reset())
    }
  }, [dispatch])

  return {
    type,
  }
}

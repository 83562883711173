import React from 'react'

import { StudiosCreatePageFormComponent } from './studios-create-page-form/studios-create-page-form.component'
import { StudiosCreatePageTopBar } from './studios-create-page-top-bar/studios-create-page-top-bar.component'
import { useStudiosCreatePage } from './studios-create-page.hook'

export const StudiosCreatePageComponent = () => {
  useStudiosCreatePage()
  return (
    <>
      <StudiosCreatePageTopBar />
      <StudiosCreatePageFormComponent />
    </>
  )
}

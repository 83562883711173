import * as React from 'react'
import { PositionsAutocompleteOption } from '@components/positions/positions-autocomplete/positions-autocomplete-option/positions-autocomplete-option.component'

import { Autocomplete } from '../../controls/autocomplete/autocomplete.component'
import { ClientsAutocompleteInput } from './employees-autocomplete-input/employees-autocomplete-input.component'
import { genClientsAutocompleteItemToString } from './employees-autocomplete.utils'
import { EmployeesAutocompleteProps } from './employees-autocomplete.types'

export const EmployeesAutocomplete: React.FC<EmployeesAutocompleteProps> = props => {
  const { className, size, placeholder, suffix } = props
  const { value, defaultValue, options } = props
  const { disabled, loading } = props
  const { onInputChange, onChange, onSelect } = props

  const onInputChangeHandler = (value: string): void => {
    onInputChange?.(value)
  }

  return (
    <Autocomplete
      className={className}
      value={value}
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      onInputChange={onInputChangeHandler}
      onSelect={onSelect}
      loading={loading}
      disabled={disabled}
      itemToStringFn={genClientsAutocompleteItemToString}
      clearInputAfterSelect={false}
      InputComponent={ClientsAutocompleteInput}
      OptionComponent={PositionsAutocompleteOption}
      size={size}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

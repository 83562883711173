import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { ProductsPagePeekHistoryFetchAuditPayload } from './products-page-peek-history.types'

export interface ProductsPagePeekHistoryState {
  productAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ProductsPagePeekHistoryState = {
  productAudit: null,
  isLoading: false,
  error: null,
}

export const { actions: productsPagePeekHistoryActions, reducer: productsPagePeekHistoryReducer } = createSlice({
  name: '@@products-page-peek-history',
  initialState,
  reducers: {
    fetchAudit: (
      state: Draft<ProductsPagePeekHistoryState>,
      _: PayloadAction<ProductsPagePeekHistoryFetchAuditPayload>
    ): void => {
      state.isLoading = true
    },
    fetchAuditSuccess: (
      state: Draft<ProductsPagePeekHistoryState>,
      action: PayloadAction<Pagination<AuditApi.Audit>>
    ): void => {
      state.productAudit = action.payload
      state.isLoading = false
    },
    fetchAuditError: (state: Draft<ProductsPagePeekHistoryState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isLoading = false
    },
    reset: () => initialState,
  },
})

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { EmployeesApi } from '../../../../api/types/employees-api.types'
import {
  mapEmployeesToEmployeesOptions,
  mapEmployeesToEmployeesTableRowList,
} from '../../../../mapping/employees.mapping'

const getEmployeesInternal = (state: AppState): Nullable<Pagination<EmployeesApi.Employee>> =>
  state.employeesPage.employeesPageList.employees

export const genEmployeesListIsLoading = (state: AppState): boolean => state.employeesPage.employeesPageList.isLoading

export const genEmployeesTableRowList = createSelector(getEmployeesInternal, employees =>
  mapEmployeesToEmployeesTableRowList(employees?.content)
)

export const genEmployeesOptions = createSelector(getEmployeesInternal, employees =>
  mapEmployeesToEmployeesOptions(employees?.content)
)

export const genEmployeesListTotalElements = createSelector(getEmployeesInternal, employees => employees?.totalElements)

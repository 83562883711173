import { ClientPaymentMethod } from '../types/payment.types'
import { ProductType } from '../types/products.types'

const SERVICES_TYPES = [
  ProductType.FULL_PAYMENT_SERVICE,
  ProductType.ADVANCE_SUB_SERVICE,
  ProductType.INSTANT_SUB_SERVICE,
  ProductType.SERVICE,
]

export const checkIsServiceType = (value: ProductType) => SERVICES_TYPES.includes(value)

export const checkIsOnline = (method: ClientPaymentMethod) =>
  ![ClientPaymentMethod.CARD, ClientPaymentMethod.CASH, ClientPaymentMethod.NO_PAYMENT].includes(method)

import { SalariesApi } from '../api/types/salaries-api.types'
import { SalariesFormValue } from '../components/salaries/salaries-form/salaries-form-types'
import { formatPenniesToRubles } from '../format/number.format'
import { Nullable, isDefAndNotEmpty } from '../types/lang.types'

export function mapSalariesToSalariesTableDataItems(
  salaries: Nullable<SalariesApi.Salary[]>
): Nullable<SalariesFormValue[]> {
  if (isDefAndNotEmpty(salaries)) {
    return salaries.reduce<SalariesFormValue[]>((acc: SalariesFormValue[], salary: SalariesApi.Salary) => {
      acc.push({
        studioIds: salary.studioIds?.map(studio => studio.id),
        directionIds: salary.directionIds?.map(direction => direction.id),
        exerciseTypeIds: salary.exerciseTypeIds?.map(exerciseType => exerciseType.id),
        rate: formatPenniesToRubles(salary.rate),
        gradeIds: salary.gradeIds?.map(gradeIds => gradeIds.id),
      })
      acc.sort((a, b) => (a.rate < b.rate ? 1 : -1))
      return acc
    }, [])
  }
}

import { PositionsEditPageForm } from './positions-edit-page-form/positions-edit-page-form.component'
import { PositionsEditPageTopBar } from './positions-edit-page-top-bar/positions-edit-page-top-bar.component'
import { usePositionsEditPage } from './positions-edit-page.hook'

export const PositionsEditPage = () => {
  usePositionsEditPage()

  return (
    <>
      <PositionsEditPageTopBar />
      <PositionsEditPageForm />
    </>
  )
}

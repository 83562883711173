import { ColumnsType } from 'antd/lib/table'

import { TransactionsProductsModalTableActions } from './transactions-products-modal-table-actions/transactions-products-modal-table-actions.component'
import { TransactionsProductsModalTableName } from './transactions-products-modal-table-name/transactions-products-modal-table-name.component'
import {
  TransactionsProductsModalTableDataItem,
  TransactionsProductsModalTableEvents,
} from './transactions-products-modal-table.types'

export function genTransactionsProductsModalTableColumns(
  events: TransactionsProductsModalTableEvents
): ColumnsType<TransactionsProductsModalTableDataItem> {
  const { onAdd } = events

  return [
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, product) => (
        <TransactionsProductsModalTableName text={product.name} productType={product.productType} />
      ),
    },
    {
      key: 'actions',
      className: 'transactions-products-modal-table-cell__actions',
      width: 120,
      render: (_, product) => (
        <TransactionsProductsModalTableActions
          price={product.price}
          discountPrice={product.discountPrice}
          productId={product.id}
          onAdd={onAdd}
        />
      ),
    },
  ]
}

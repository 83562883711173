import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { scheduleGroupPageModalCommentsActions } from './schedule-group-page-modal-comments.slice'
import { callApi } from '../../../../utils/sagas.utils'
import { modalActions } from '../../../common/modal/modal.slice'
import { isDef } from '../../../../types/lang.types'
import { reFetchBooking } from '../schedule-group-page-list/schedule-group-page-list.sagas'

export function* fetchComments(action: ReturnType<typeof scheduleGroupPageModalCommentsActions.fetchComments>) {
  try {
    const { exerciseId, bookingId } = action.payload

    const response: Awaited<ReturnType<typeof api.exercisesService.fetchBookingComments>> = yield callApi(
      api.exercisesService.fetchBookingComments,
      exerciseId,
      bookingId
    )

    yield put(scheduleGroupPageModalCommentsActions.fetchCommentsSuccess(response.data))
  } catch (e) {
    yield put(scheduleGroupPageModalCommentsActions.fetchCommentsError(new Error()))
  }
}

export function* createComment(action: ReturnType<typeof scheduleGroupPageModalCommentsActions.createComment>) {
  try {
    const { exerciseId, bookingId, exercisesGroupCommentsFormValues } = action.payload

    if (isDef(exercisesGroupCommentsFormValues)) {
      yield callApi(api.exercisesService.createBookingComment, exerciseId, bookingId, exercisesGroupCommentsFormValues)
      yield put(scheduleGroupPageModalCommentsActions.createCommentSuccess())

      yield call(reFetchBooking)

      yield put(modalActions.closeLast())
    } else {
      yield put(scheduleGroupPageModalCommentsActions.createCommentError(new Error()))
    }
  } catch (e) {
    yield put(scheduleGroupPageModalCommentsActions.createCommentError(new Error()))
  }
}

export function* scheduleGroupPageModalCommentsSagas() {
  yield takeLatest(scheduleGroupPageModalCommentsActions.fetchComments, fetchComments)
  yield takeLatest(scheduleGroupPageModalCommentsActions.createComment, createComment)
}

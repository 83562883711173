import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import {
  Flex,
  Typography,
  Row,
  Col,
  Form,
  Select,
  Space,
  InputNumber,
  FormInstance,
  Switch,
  Table,
  Button,
  Input,
  Tooltip,
} from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { ConditionTypes } from '../../../../../api/types/api.types'
import { EmployeesPositionsApi } from '../../../../../api/types/employees-positions-api.types'
import { DEFAULT_EMPTY_SYMBOL } from '../../../../../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../../../types/lang.types'
import { Theme } from '../../../../../types/theme.types'
import { CustomCard } from '../../../../custom-card/custom-card.component'
import { QuestionTooltip } from '../../../../ui/question-tooltip/question-tooltip.component'
import { MasterServicesPricingFormValues } from '../../master-services-form.types'
import { genDaysOptions, genPricingTypeOptions } from '../master-services-form-pricing-modal.utils'
import { useMasterServicesFormPricingBasePrice } from './master-services-form-pricing-base-price.hook'
import './master-services-form-pricing-base-price.styles.less'

interface Props {
  form: FormInstance<MasterServicesPricingFormValues>
  theme: Theme
  positions?: Nullable<EmployeesPositionsApi.EmployeePosition[]>
  positionsOptions?: DefaultOptionType[]
}

export const MasterServicesFormPricingBasePrice: React.FC<Props> = props => {
  const { form, theme, positions, positionsOptions } = props

  const { costValidationRules, isHolidayAsWeekend, toggleHolidayAsWeekend, handlePricingTypeChange, handleTimeChange } =
    useMasterServicesFormPricingBasePrice({
      form,
    })

  const priceType = Form.useWatch('priceType', form)
  const basePrice = Form.useWatch(['basePrice'], form)

  return (
    <CustomCard customClassName={`master-services-form-pricing__card master-services-form-pricing__card--${theme}`}>
      <Flex vertical gap="small">
        <Typography.Title className="master-services-form-pricing__title" level={5}>
          Условие ценообразования
        </Typography.Title>
        <Row>
          <Col span={12}>
            <Form.Item name="priceType">
              <Select size="large" options={genPricingTypeOptions()} onChange={handlePricingTypeChange} />
            </Form.Item>
          </Col>
        </Row>
        {priceType === ConditionTypes.TIME && (
          <Space align="center" size="small">
            <Form.Item name="holidayAsWeekend">
              <Switch size="small" value={isHolidayAsWeekend} onChange={toggleHolidayAsWeekend} />
            </Form.Item>
            <Typography.Text style={{ cursor: 'pointer' }} onClick={toggleHolidayAsWeekend}>
              Государственные выходные считать по цене выходных дней
            </Typography.Text>
          </Space>
        )}
        {priceType === ConditionTypes.NONE ? (
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                className="master-services-form-pricing__form-item"
                label="Цена"
                colon={false}
                name="value"
                rules={costValidationRules}
              >
                <InputNumber
                  size="large"
                  placeholder="0"
                  suffix="₽"
                  style={{ width: '100%' }}
                  className=".master-services-form-base-pricing__value"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="master-services-form-pricing__form-item"
                label="Название"
                colon={false}
                name="nameInReceipt"
                tooltip="Заполнив это поле, вы зададите собственное наименование номенклатуры для этого условия. Именно это наименование будет отображаться в отчётах и чеках."
              >
                <Input size="large" />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Flex vertical gap="middle">
            <Form.List name="basePrice">
              {(prices, { add, remove }) => (
                <CustomCard>
                  <Table
                    dataSource={prices}
                    pagination={false}
                    footer={() => (
                      <Button
                        type="link"
                        size="middle"
                        style={{ padding: 0 }}
                        icon={<PlusCircleOutlined />}
                        onClick={() => add()}
                      >
                        Добавить {isDefAndNotEmpty(prices) ? 'еще' : ''} условие
                      </Button>
                    )}
                  >
                    {priceType === ConditionTypes.TIME && (
                      <>
                        <Table.Column
                          dataIndex={'days'}
                          title={'Дни недели'}
                          width={'25%'}
                          render={(_v, _r, i) => (
                            <Form.Item name={[i, 'days']}>
                              <Select size="large" options={genDaysOptions()} mode="multiple" placeholder="Выбрать" />
                            </Form.Item>
                          )}
                        />
                        <Table.Column
                          dataIndex={'time'}
                          title={'Время'}
                          width={'30%'}
                          render={(_v, _r, i) => (
                            <Space size="middle">
                              <Form.Item name={[i, 'timeStart']}>
                                <Flex align="center" gap="small">
                                  с{' '}
                                  <Input
                                    size="large"
                                    type="time"
                                    value={form.getFieldValue(['basePrice', i, 'timeStart'])}
                                    onChange={e => handleTimeChange(e, i, 'timeStart')}
                                  />
                                </Flex>
                              </Form.Item>
                              <Form.Item name={[i, 'timeEnd']}>
                                <Flex align="center" gap="small">
                                  по{' '}
                                  <Input
                                    size="large"
                                    type="time"
                                    value={form.getFieldValue(['basePrice', i, 'timeEnd'])}
                                    onChange={e => handleTimeChange(e, i, 'timeEnd')}
                                  />
                                </Flex>
                              </Form.Item>
                            </Space>
                          )}
                        />
                      </>
                    )}
                    {priceType === ConditionTypes.GRADE && (
                      <>
                        <Table.Column
                          dataIndex={'positionId'}
                          title={'Должность'}
                          width={'30%'}
                          render={(_v, _r, i) => {
                            const onPositionChange = () => {
                              form.setFieldValue(['basePrice', i, 'gradeId'], undefined)
                            }

                            return (
                              <Form.Item name={[i, 'positionId']}>
                                <Select
                                  size="large"
                                  options={positionsOptions}
                                  placeholder="Выбрать"
                                  onChange={onPositionChange}
                                />
                              </Form.Item>
                            )
                          }}
                        />
                        <Table.Column
                          dataIndex={'gradeId'}
                          title={'Грейд'}
                          width={'30%'}
                          render={(_v, _r, i) => {
                            const positionId = form.getFieldValue(['basePrice', i, 'positionId'])
                            const grades =
                              isDef(positions) &&
                              isDef(positionId) &&
                              positions.find(position => position.id === positionId)?.grades

                            const gradeOptions = grades
                              ? grades?.map(employeesGrade => ({
                                  value: employeesGrade.id,
                                  label: employeesGrade.name,
                                }))
                              : undefined

                            if (isDefAndNotEmpty(gradeOptions)) {
                              return (
                                <Form.Item name={[i, 'gradeId']}>
                                  <Select
                                    size="large"
                                    placeholder="Выбрать"
                                    options={gradeOptions}
                                    disabled={!isDefAndNotEmpty(gradeOptions)}
                                  />
                                </Form.Item>
                              )
                            }
                            return DEFAULT_EMPTY_SYMBOL
                          }}
                        />
                      </>
                    )}
                    {priceType === ConditionTypes.DURATION && (
                      <Table.Column
                        dataIndex={'durationMinutes'}
                        title={'Длительность'}
                        width={'50%'}
                        render={(_v, _r, i) => (
                          <Form.Item name={[i, 'durationMinutes']}>
                            <InputNumber size="large" addonAfter="мин" placeholder="0" style={{ width: '100%' }} />
                          </Form.Item>
                        )}
                      />
                    )}
                    <Table.Column
                      dataIndex={'value'}
                      title={priceType === ConditionTypes.TIME ? 'Условие' : 'Цена'}
                      width={'auto'}
                      render={(_v, _r, i) => (
                        <Form.Item name={[i, 'value']}>
                          <InputNumber
                            size="large"
                            placeholder="0"
                            suffix="₽"
                            style={{ width: '100%' }}
                            className="master-services-form-base-pricing__value"
                          />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      dataIndex={'nameInReceipt'}
                      width={'170px'}
                      title={() => (
                        <Flex gap="4px">
                          <Typography.Text strong>Название</Typography.Text>
                          <QuestionTooltip title="Заполнив это поле, вы зададите собственное наименование номенклатуры для этого условия. Именно это наименование будет отображаться в отчётах и чеках." />
                        </Flex>
                      )}
                      render={(_v, _r, i) => (
                        <Form.Item name={[i, 'nameInReceipt']}>
                          <Input size="large" />
                        </Form.Item>
                      )}
                    />
                    <Table.Column
                      dataIndex={'actions'}
                      width={'64px'}
                      render={(_v, _r, i) => (
                        <Tooltip title="Удалить">
                          <Button icon={<DeleteOutlined />} size="middle" onClick={e => remove(i)} />
                        </Tooltip>
                      )}
                    />
                  </Table>
                </CustomCard>
              )}
            </Form.List>
          </Flex>
        )}
      </Flex>
    </CustomCard>
  )
}

import React from 'react'
import { Dayjs } from 'dayjs'
import { Form } from 'antd'

import { genWeekdays, genWeekend, isContainArrayDaysWithSameTime } from '../../../../utils/days.utils'
import { isDef } from '../../../../types/lang.types'
import { Range } from '../../../../api/types/api.types'
import { StudiosWorkTimeFormTypes } from '../studios-form-types'
import {
  containsArrayDaysWithSameTime,
  mapStudiosWorkTimeFormDayjsValuesToStudiosWorkTime,
  mapStudiosWorkTimeToStudiosWorkTimeFormDayjsValues,
} from './studios-form-work-time-modal.utils'
import { StudiosFormWorkTimeModalProps } from './studios-form-work-time-modal.types'

export interface Props extends StudiosFormWorkTimeModalProps {}

export function useStudiosFormWorkTimeModal(props: Props) {
  const { values, offset, open, onCancel, onOk } = props

  const [form] = Form.useForm<StudiosWorkTimeFormTypes<Dayjs>>()

  const weekdays = React.useMemo(genWeekdays, [])
  const weekend = React.useMemo(genWeekend, [])

  const [activeKeys, setActiveKeys] = React.useState<string[]>([])

  const onCollapseChange = (key: string[]) => {
    setActiveKeys(key)
  }

  const [weekdaysTime, setWeekdaysTime] = React.useState<Range<Dayjs | undefined>>({
    from: undefined,
    to: undefined,
  })

  const [weekendTime, setWeekendTime] = React.useState<Range<Dayjs | undefined>>({
    from: undefined,
    to: undefined,
  })

  const resetFields = React.useCallback(() => {
    setActiveKeys([])
    form.setFieldsValue({
      weekdays: null,
      weekend: null,
    })
    setWeekdaysTime({
      from: undefined,
      to: undefined,
    })
    setWeekendTime({
      from: undefined,
      to: undefined,
    })
  }, [form])

  const onCloseModal = () => {
    resetFields()
    onCancel()
  }

  const onSubmitForm = () => {
    const formValues = form.getFieldsValue(true)
    const values = mapStudiosWorkTimeFormDayjsValuesToStudiosWorkTime(formValues, offset)
    const unifiedTime = isDef(values) ? isContainArrayDaysWithSameTime(Object.values(values)) : false

    resetFields()
    onOk(values, unifiedTime)
  }

  const onChangeWeekdaysTimeFrom = (time: Dayjs | null) => {
    if (isDef(time)) {
      setWeekdaysTime({ ...weekdaysTime, from: time })
    } else {
      setWeekdaysTime({ ...weekdaysTime, from: undefined })
    }
  }

  const onChangeWeekdaysTimeTo = (time: Dayjs | null) => {
    if (isDef(time)) {
      setWeekdaysTime({ ...weekdaysTime, to: time })
    } else {
      setWeekdaysTime({ ...weekdaysTime, to: undefined })
    }
  }

  const onChangeWeekendTimeFrom = (time: Dayjs | null) => {
    if (isDef(time)) {
      setWeekendTime({ ...weekendTime, from: time })
    } else {
      setWeekendTime({ ...weekendTime, from: undefined })
    }
  }

  const onChangeWeekendTimeTo = (time: Dayjs | null) => {
    if (isDef(time)) {
      setWeekendTime({ ...weekendTime, to: time })
    } else {
      setWeekendTime({ ...weekendTime, to: undefined })
    }
  }

  React.useEffect(() => {
    if (isDef(values)) {
      const formValues = mapStudiosWorkTimeToStudiosWorkTimeFormDayjsValues(values, offset)
      if (isDef(formValues)) {
        form.setFieldsValue(formValues)
      }
    } else {
      resetFields()
    }
  }, [values, form, open, resetFields, offset])

  React.useEffect(() => {
    const values = form.getFieldsValue(true)
    const weekdaysArray: any = {}

    weekdays.forEach(weekday => {
      weekdaysArray[weekday] = {
        from: weekdaysTime.from ? weekdaysTime.from : values && values.weekdays && values.weekdays[weekday].from,
        to: weekdaysTime.to ? weekdaysTime.to : values && values.weekdays && values.weekdays[weekday].to,
      }
    })

    form.setFieldsValue({
      weekend: values.weekend,
      weekdays: weekdaysArray,
    })
  }, [form, weekdays, weekdaysTime])

  React.useEffect(() => {
    const values = form.getFieldsValue(true)
    const weekendArray: any = {}

    weekend.forEach(day => {
      weekendArray[day] = {
        from: weekendTime.from ? weekendTime.from : values && values.weekend && values.weekend[day].from,
        to: weekendTime.to ? weekendTime.to : values && values.weekend && values.weekend[day].to,
      }
    })

    form.setFieldsValue({
      weekdays: values.weekdays,
      weekend: weekendArray,
    })
  }, [form, weekend, weekendTime])

  React.useEffect(() => {
    const values = form.getFieldsValue(true)
    if (isDef(values) && isDef(values.weekdays)) {
      const weekdaysTime: Range<Dayjs>[] = Object.values(values.weekdays)

      if (containsArrayDaysWithSameTime(weekdaysTime)) {
        setWeekdaysTime({
          from: weekdaysTime[0].from,
          to: weekdaysTime[0].to,
        })
      } else {
        if (!activeKeys.find(key => key === '1')) {
          setActiveKeys([...activeKeys, '1'])
        }
        setWeekdaysTime({
          from: undefined,
          to: undefined,
        })
      }
    }
  }, [form, open])

  React.useEffect(() => {
    const values = form.getFieldsValue(true)
    if (isDef(values) && isDef(values.weekend)) {
      const formWeekendTime: Range<Dayjs>[] = Object.values(values.weekend)

      if (containsArrayDaysWithSameTime(formWeekendTime)) {
        setWeekendTime({
          from: formWeekendTime[0].from,
          to: formWeekendTime[0].to,
        })
      } else {
        if (!activeKeys.find(key => key === '2')) {
          setActiveKeys([...activeKeys, '2'])
        }
        setWeekendTime({
          from: undefined,
          to: undefined,
        })
      }
    }
  }, [form, open])

  return {
    form,
    activeKeys,
    weekdays,
    weekend,
    weekdaysTime,
    weekendTime,
    onChangeWeekdaysTimeFrom,
    onChangeWeekdaysTimeTo,
    onChangeWeekendTimeFrom,
    onChangeWeekendTimeTo,
    onCollapseChange,
    onCloseModal,
    onSubmitForm,
  }
}

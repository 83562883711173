import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { ExercisesGroupBookingFormValues } from '../../../components/exercises-group/exercises-group-booking-form/exercises-group-booking-form.types'
import { genPaymentTypesOptions } from '../../../utils/payment.utils'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { ExercisesGroupBookFromWaitingFormValues } from '../../../components/exercises-group/exercises-group-book-from-waiting-form/exercises-group-book-from-waiting-form.types'
import { scheduleGroupPageModalBookFromWaitingActions } from '../../../store/pages/schedule-group-page/schedule-group-page-modal-book-from-waiting/schedule-group-page-modal-book-from-waiting.slice'
import {
  genScheduleGroupPageModalBookFromWaitingIsLoading,
  genScheduleGroupPageModalBookFromWaitingExercisesSpots,
} from '../../../store/pages/schedule-group-page/schedule-group-page-modal-book-from-waiting/schedule-group-page-modal-book-from-waiting.selectors'

export function useScheduleGroupPageModalBookFromWaiting(scheduleId: string, phone: string) {
  const [form] = Form.useForm<ExercisesGroupBookingFormValues>()

  const dispatch = useDispatch()

  const loading = useSelector(genScheduleGroupPageModalBookFromWaitingIsLoading)
  const placesOptions = useSelector(genScheduleGroupPageModalBookFromWaitingExercisesSpots)

  const paymentTypesOptions = React.useMemo(genPaymentTypesOptions, [])

  React.useEffect((): void => {
    dispatch(scheduleGroupPageModalBookFromWaitingActions.fetchExercisesSpots(scheduleId))
  }, [dispatch, scheduleId])

  React.useEffect(() => {
    return () => {
      dispatch(scheduleGroupPageModalBookFromWaitingActions.reset())
    }
  }, [dispatch])

  const onSaveHandler = React.useCallback(
    (exercisesGroupBookingFormValues: ExercisesGroupBookFromWaitingFormValues): void => {
      dispatch(
        scheduleGroupPageModalBookFromWaitingActions.createBooking({
          exerciseId: scheduleId,
          exercisesGroupBookingFormValues,
          phone,
        })
      )
    },
    [dispatch, phone, scheduleId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    form,
    loading,

    paymentTypesOptions,
    placesOptions,

    onSaveHandler,
    onCancelHandler,
  }
}

import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'

import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { ExercisesDirectionsApi } from '../api/types/exercises-directions-api.types'
import { DirectionsTableRow } from '../components/directions/directions-table/directions-table.types'
import { DirectionsFormValues } from '../components/directions/directions-form/directions-form.types'
import { formatHhMmToIsoString, formatIsoStringTOHhMm } from '../format/date.format'

export function mapDirectionsToDirectionsTableRowList(
  directions: Nullable<ExercisesDirectionsApi.ExerciseDirection[]>
): Nullable<DirectionsTableRow[]> {
  if (isDefAndNotEmpty(directions)) {
    return directions.map((direction: ExercisesDirectionsApi.ExerciseDirection) => ({
      id: direction.id,
      name: direction.name,
      description: direction.description,
      whatToTake: direction.whatToTake,
      photo: direction.photo,
    }))
  }

  return null
}

export function mapDirectionsToOptions(
  directions: Nullable<ExercisesDirectionsApi.ExerciseDirection[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(directions)) {
    return directions.reduce((acc: DefaultOptionType[], direction: ExercisesDirectionsApi.ExerciseDirection) => {
      acc.push({
        value: direction.id,
        label: direction.name,
      })
      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function genDirectionsFormValues(
  data: Nullable<ExercisesDirectionsApi.ExerciseDirection>
): Nullable<DirectionsFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      description: data.description,
      whatToTake: data.whatToTake,
      photo: data.photo,
      duration: data.duration ? formatIsoStringTOHhMm(data.duration) : '00:00',
      types: data.types.map(type => type.id),
    }
  }

  return null
}

export function genDirectionDTO(data: DirectionsFormValues): ExercisesDirectionsApi.ExerciseDirectionDTO {
  const durationDate = dayjs(data.duration, 'HH:mm')
  const diff = durationDate.diff(dayjs('00:00', 'HH:mm'), 'minute')
  const durationPT = dayjs
    .duration(diff, 'minute')
    .toISOString()
    .replace('PT', 'PT')
    .replace('H', 'H')
    .replace('M', 'M')
  return {
    name: data.name,
    description: data.description,
    whatToTake: data.whatToTake,
    photo: data.photo,
    duration: data.duration !== '00:00' ? durationPT : null,
    typeIds: data.types,
  }
}

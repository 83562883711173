import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LOCAL_STORAGE_ROOMS_KEY } from '@constants/local-storage'

import { api } from '../../api/api'
import { getActiveModal } from '../../store/common/modal/modal.selectors'
import { getLayoutStudios } from '../../store/common/layout/layout.selectors'
import { useStudio } from '../../hooks/use-studios.hook'
import { genAppLayoutSidebarMenu, genAppLayoutTopBarMenu, genAppLayoutUserMenu } from './app-layout.utils'
import { getActivePeek } from '../../store/common/peek/peek.selectors'
import { useSchedulePageParams } from '../../pages/schedule-page/schedule-page-hooks/schedule-page-params.hook'

export const useAppLayout = () => {
  const { studioId } = useStudio()

  const params = useSchedulePageParams()
  const { date } = params

  const activeModal = useSelector(getActiveModal)
  const activePeek = useSelector(getActivePeek)
  const topBarStudios = useSelector(getLayoutStudios)

  const logOut = async () => {
    localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, '[]')
    await api.logOut()
  }

  const topBarMenu = useMemo(() => genAppLayoutTopBarMenu(), [])
  const userMenu = useMemo(() => genAppLayoutUserMenu(logOut), [])
  const sidebarMenuItems = useMemo(() => genAppLayoutSidebarMenu(studioId), [studioId, date])

  return {
    topBarMenu,
    userMenu,
    sidebarMenuItems,
    topBarStudios,
    selectedStudioId: studioId,
    activeModal,
    activePeek,
  }
}

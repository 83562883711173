export const matchKeyByDay = (key: number) => {
  switch (key) {
    case 1:
      return 'MONDAY'
    case 2:
      return 'TUESDAY'
    case 3:
      return 'WEDNESDAY'
    case 4:
      return 'THURSDAY'
    case 5:
      return 'FRIDAY'
    case 6:
      return 'SATURDAY'
    case 7:
      return 'SUNDAY'
    default:
      return 'MONDAY'
  }
}

export const matchDayByKey = (day: string) => {
  switch (day.toUpperCase()) {
    case 'MONDAY':
      return 1
    case 'TUESDAY':
      return 2
    case 'WEDNESDAY':
      return 3
    case 'THURSDAY':
      return 4
    case 'FRIDAY':
      return 5
    case 'SATURDAY':
      return 6
    case 'SUNDAY':
      return 7
    default:
      return 1
  }
}

import { Modal } from 'antd'

import { ICustomFieldsModalCreateProps } from './custom-fields-modal.types'
import { CustomFieldsForm } from '../custom-fields-form/custom-fields-form.component'
import { useCustomFieldsModalCreate } from './custom-fields-modal-create.hook'

export const CustomFieldsModalCreate = (props: ICustomFieldsModalCreateProps) => {
  const data = useCustomFieldsModalCreate(props)
  return (
    <Modal title={props.title} onCancel={props.onCancel} open={true} footer={null}>
      <CustomFieldsForm
        {...data}
        showSelectType={true}
        onSave={props.onSave}
        onCancel={props.onCancel}
        form={props.form}
      />
    </Modal>
  )
}

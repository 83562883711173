export enum Colors {
  RED = 'RED',
  VOLCANO = 'VOLCANO',
  ORANGE = 'ORANGE',
  GOLD = 'GOLD',
  YELLOW = 'YELLOW',
  LIME = 'LIME',
  GREEN = 'GREEN',
  CYAN = 'CYAN',
  BLUE = 'BLUE',
  GEEKBLUE = 'GEEKBLUE',
  PURPLE = 'PURPLE',
  MAGENTA = 'MAGENTA',
}

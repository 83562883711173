import { CheckboxOptionType } from 'antd'

import { isDef } from '../types/lang.types'
import { formatPositionType } from '../format/text.format'
import { PositionType } from '../types/positions.types'
import { validateStrEnumValue } from './enum.utils'

export function genPositionTypesOptions() {
  return Object.keys(PositionType).reduce<CheckboxOptionType[]>((acc: CheckboxOptionType[], key: string) => {
    const positionType = validateStrEnumValue<PositionType>(PositionType, key)

    if (isDef(positionType)) {
      acc.push({
        value: positionType,
        label: formatPositionType(positionType),
      })
    }

    return acc
  }, [])
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { genDirectionsFormValues } from '../../../mapping/directions.mapping'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'

const getDirectionInternal = (state: AppState) => state.directionsEditPage.direction
const getExercisesTypesInternal = (state: AppState) => state.directionsEditPage.exercisesTypes

export const genDirectionsEditPageIsLoading = (state: AppState): boolean => state.directionsEditPage.isLoading

export const getDirectionsFormValues = createSelector(getDirectionInternal, genDirectionsFormValues)
export const getDirectionsEditPageExercisesTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToOptions(exercisesTypes?.content)
)

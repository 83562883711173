import { DefaultOptionType } from 'antd/lib/select'

import { ExercisesTypesApi } from '../api/types/exercises-types-api.types'
import { ExercisesTypesFormValues } from '../components/exercises-types/exercises-types-form/exercises-types-form.types'
import { ExercisesTypesTableRow } from '../components/exercises-types/exercises-types-table/exercises-types-table.types'
import { ExercisesFormExercisesType } from '../components/exercises/exercises-form/exercises-form.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { gerStrUpperCase } from '../utils/enum.utils'

export function mapExercisesTypesToOptions(
  exercisesTypes: Nullable<ExercisesTypesApi.ExerciseType[]>
): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(exercisesTypes)) {
    return exercisesTypes.reduce((acc: DefaultOptionType[], exerciseType: ExercisesTypesApi.ExerciseType) => {
      acc.push({
        value: exerciseType.id,
        label: exerciseType.name,
        format: exerciseType.format,
      })

      acc.sort((optionA, optionB) =>
        typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
          ? -1
          : 1
      )
      return acc
    }, [])
  }
}

export function mapExercisesTypesToExercisesTypesTableRowList(
  exercisesTypes: Nullable<ExercisesTypesApi.ExerciseType[]>
): Nullable<ExercisesTypesTableRow[]> {
  if (isDefAndNotEmpty(exercisesTypes)) {
    return exercisesTypes.map((exercisesType: ExercisesTypesApi.ExerciseType) => ({
      id: exercisesType.id,
      name: exercisesType.name,
      description: exercisesType.description,
      showToUser: exercisesType.showToUser,
      personal: exercisesType.personal,
      format: exercisesType.format,
    }))
  }

  return null
}

export function mapExercisesTypesToExercisesForm(
  exercisesTypes: Nullable<ExercisesTypesApi.ExerciseType[]>
): Nullable<ExercisesFormExercisesType[]> {
  if (isDefAndNotEmpty(exercisesTypes)) {
    return exercisesTypes.map((exercisesType: ExercisesTypesApi.ExerciseType) => ({
      id: exercisesType.id,
      name: exercisesType.name,
      value: exercisesType.id,
      label: exercisesType.name,
      directions: exercisesType.directions.map(direction => ({
        id: direction.id,
        name: direction.name,
        value: direction.id,
        label: direction.name,
        duration: direction.duration,
      })),
      format: exercisesType.format,
      filteredOut: exercisesType.filteredOut,
      masterServices: exercisesType.masterServices,
    }))
  }

  return null
}

export function genExercisesTypesFormValues(
  data: Nullable<ExercisesTypesApi.ExerciseType>
): Nullable<ExercisesTypesFormValues> {
  if (isDef(data)) {
    return {
      name: data.name,
      description: data.description,
      showToUser: data.showToUser,
      color: data.color ? gerStrUpperCase(data.color) : null,
      directions: data.directions.map(direction => direction.id),
      personal: data.personal,
      format: data.format === 'UNKNOWN' ? 'GROUP' : data.format,
    }
  }

  return null
}

export function genExercisesTypeDTO(data: ExercisesTypesFormValues): ExercisesTypesApi.ExerciseTypeDTO {
  return {
    name: data.name,
    description: data.description,
    capacity: null,
    showToUser: data.showToUser,
    color: data.color ? data.color : null,
    directionIds: data.directions,
    personal: data.personal,
    format: data.format,
  }
}

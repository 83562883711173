import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { MasterServicesApi } from '../../../api/types/master-services-api.types'
import { mapMasterServicesToOptions } from '../../../mapping/master-services.mapping'

const getMasterServicesInternal = (state: AppState): Nullable<Pagination<MasterServicesApi.MasterService>> =>
  state.masterServicesWidgetCreatePage.masterServices

export const genMasterServicesWidgetCreatePageIsLoading = (state: AppState): boolean =>
  state.masterServicesWidgetCreatePage.isLoading
export const genMasterServicesWidgetCreatePageIsLoaded = (state: AppState): boolean =>
  state.masterServicesWidgetCreatePage.isLoaded

export const getMasterServicesWidgetCreatePageMasterServicesOptions = createSelector(
  getMasterServicesInternal,
  masterServices => mapMasterServicesToOptions(masterServices?.content)
)

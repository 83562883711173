import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'antd'

import {
  getSalariesTableRowList,
  genSalariesIsLoading,
  getStudiosOptions,
  getDirectionsOptions,
  getExercisesOptions,
  getGradesOptions,
} from '../../../store/pages/salaries-page/salaries-page.selectors'
import { SalariesApi } from '../../../api/types/salaries-api.types'
import { salariesPageActions } from '../../../store/pages/salaries-page/salaries-page.slice'

export function useSalariesPageFormHook() {
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const salaries = useSelector(getSalariesTableRowList)
  const isLoading = useSelector(genSalariesIsLoading)

  const studiosOptions = useSelector(getStudiosOptions)
  const directionsOptions = useSelector(getDirectionsOptions)
  const exercisesTypeOptions = useSelector(getExercisesOptions)
  const gradesOptions = useSelector(getGradesOptions)

  const onFinishHandler = React.useCallback(
    (values: SalariesApi.SalaryDTO[]): void => {
      dispatch(salariesPageActions.updateSalaries(values))
    },
    [dispatch]
  )

  const onRemoveHandler = React.useCallback(
    (salary: SalariesApi.DeletedSalaryDTO[]): void => {
      dispatch(salariesPageActions.removeSalaries(salary))
    },
    [dispatch]
  )

  const onDeselectHandler = React.useCallback(
    (salary: SalariesApi.DeletedSalaryDTO): void => {
      dispatch(salariesPageActions.onDeselectSalary(salary))
    },
    [dispatch]
  )

  const salariesFormData = React.useMemo(() => {
    return { salaries: salaries }
  }, [salaries])

  React.useEffect(() => {
    form.setFieldsValue(salariesFormData)
  }, [salariesFormData, form])

  return {
    form,
    isLoading,
    studiosOptions,
    directionsOptions,
    exercisesTypeOptions,
    gradesOptions,
    onFinishHandler,
    onRemoveHandler,
    onDeselectHandler,
  }
}

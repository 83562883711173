import React from 'react'

import { MasterServicesEditPageTopBar } from './master-services-edit-page-top-bar/master-services-edit-page-top-bar.component'
import { useMasterServicesEditPage } from './master-services-edit-page.hook'
import { MasterServicesEditPageForm } from './master-services-edit-page-form/master-services-edit-page-form.component'
import { PageLoader } from '../../components/page/page-loader/page-loader.component'

export const MasterServicesEditPage = () => {
  const { isLoading, isLoaded } = useMasterServicesEditPage()

  if (!isLoaded) {
    return <PageLoader />
  }

  return (
    <>
      <MasterServicesEditPageTopBar />
      <MasterServicesEditPageForm />
    </>
  )
}

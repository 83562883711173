export const CloseCircle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="69" height="69" viewBox="0 0 69 69" fill="none">
      <path
        d="M34.5 4.3125C17.8295 4.3125 4.3125 17.8295 4.3125 34.5C4.3125 51.1705 17.8295 64.6875 34.5 64.6875C51.1705 64.6875 64.6875 51.1705 64.6875 34.5C64.6875 17.8295 51.1705 4.3125 34.5 4.3125ZM34.5 59.5664C20.6596 59.5664 9.43359 48.3404 9.43359 34.5C9.43359 20.6596 20.6596 9.43359 34.5 9.43359C48.3404 9.43359 59.5664 20.6596 59.5664 34.5C59.5664 48.3404 48.3404 59.5664 34.5 59.5664Z"
        fill="#E95959"
      />
      <path
        opacity="0.1"
        d="M34.5 9.4375C20.6596 9.4375 9.43359 20.6635 9.43359 34.5039C9.43359 48.3443 20.6596 59.5703 34.5 59.5703C48.3404 59.5703 59.5664 48.3443 59.5664 34.5039C59.5664 20.6635 48.3404 9.4375 34.5 9.4375ZM46.0764 44.955C46.1572 45.0561 46.2044 45.1773 46.2044 45.3054C46.2044 45.6086 45.9618 45.8444 45.6653 45.8444L41.2181 45.8242L34.527 37.8461L27.8358 45.831L23.3818 45.8512C23.0854 45.8512 22.8428 45.6086 22.8428 45.3121C22.8428 45.1841 22.8899 45.0628 22.9708 44.9617L31.7373 34.5241L22.9708 24.0798C22.8894 23.981 22.8442 23.8574 22.8428 23.7294C22.8428 23.4262 23.0854 23.1903 23.3818 23.1903L27.8358 23.2105L34.527 31.1887L41.2248 23.2038L45.6721 23.1836C45.9686 23.1836 46.2111 23.4262 46.2111 23.7227C46.2111 23.8507 46.1707 23.9787 46.0898 24.073L37.3233 34.5174L46.0764 44.955Z"
        fill="#E95959"
      />
      <path
        d="M46.2111 23.7188C46.2111 23.4223 45.9686 23.1797 45.6721 23.1797L41.2248 23.1999L34.527 31.1848L27.8358 23.2066L23.3818 23.1864C23.0854 23.1864 22.8428 23.4223 22.8428 23.7255C22.8428 23.8535 22.8899 23.9748 22.9708 24.0759L31.7373 34.5202L22.9708 44.9578C22.8894 45.0566 22.8442 45.1802 22.8428 45.3082C22.8428 45.6047 23.0854 45.8473 23.3818 45.8473L27.8358 45.827L34.527 37.8422L41.2181 45.8203L45.6653 45.8405C45.9618 45.8405 46.2044 45.6047 46.2044 45.3015C46.2044 45.1734 46.1572 45.0521 46.0764 44.9511L37.3233 34.5135L46.0898 24.0691C46.1707 23.9748 46.2111 23.8468 46.2111 23.7188Z"
        fill="#E95959"
      />
    </svg>
  )
}

import { Rule } from 'antd/lib/form'
import dayjs from 'dayjs'

import { isDef } from '../../../../types/lang.types'

export function genStudiosFormTimeToValidationRules(): Rule[] {
  return [
    { required: true, message: 'Укажите время' },
    ({ getFieldValue }) => ({
      validator(rule, value: { start: string; end: string }) {
        const startTime = dayjs(value.start, 'HH:mm')
        const endTime = dayjs(value.end, 'HH:mm')

        if (!isDef(value.start)) {
          return Promise.reject('Заполните время начала')
        } else if (!isDef(value.end)) {
          return Promise.reject('Заполните время окончания')
        } else if (startTime.isAfter(endTime)) {
          return Promise.reject('Недопустимое время окончания')
        } else if (startTime.isSame(endTime)) {
          return Promise.reject('Время начала не может быть равно времени окончания')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getTransactionsPageIsLoaded,
  getTransactionsPageIsLoading,
} from '../../store/pages/transactions-page/transactions-page-common/transactions-page-common.selectors'
import { transactionsPageActions } from '../../store/pages/transactions-page/transactions-page-common/transactions-page-common.slice'
import { useTransactionsPageParams } from './transactions-page-hooks/transactions-page-params.hook'

export function useTransactionsPage() {
  const dispatch = useDispatch()

  const params = useTransactionsPageParams()

  const isLoaded = useSelector(getTransactionsPageIsLoaded)
  const isLoading = useSelector(getTransactionsPageIsLoading)

  const [isShowShiftTransactions, setIsShowShiftTransactions] = React.useState(true)

  const getCurrentStudio = async () => {
    const studioID = localStorage.getItem('APP_SELECTED_STUDIO_ID')
    if (!studioID) return

    // const response = await api.studiosService.fetchById(studioID)
    // setIsShowShiftTransactions(!!response.data.organization.offlineTillSupportedPaymentMethodCategories.length)
  }

  React.useEffect(() => {
    getCurrentStudio()
  })

  React.useEffect((): void => {
    dispatch(transactionsPageActions.fetchAllTransactions(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(transactionsPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
    isShowShiftTransactions,
  }
}

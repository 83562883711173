import { FC, useEffect } from 'react'
import { Flex, Radio, Form, DatePicker, Typography } from 'antd'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'
import { EMPLOYEE_SCHEDULE_FOR_ENTITY } from '@constants/employees/schedule'
import { SearchOutlined } from '@ant-design/icons'

import {
  EmployeeScheduleDateRangeRules,
  EmployeeScheduleEmployeeRules,
  EmployeeSchedulePositionRules,
} from './main-info-form.rules'
import { IEmployeeScheduleMainInfoFormProps } from './main-info-form.types'
import './main-info-form.styles.less'
import { NString } from '../../../../types/lang.types'
import { EmployeesAutocompleteContainer } from '../../../../containers/employees-autocomplete/employees-autocomplete.container'
import { PositionsAutocompleteContainer } from '../../../../containers/positions-autocomplete/positions-autocomplete.container'

export const EmployeeScheduleMainInfoForm: FC<IEmployeeScheduleMainInfoFormProps> = ({ form }) => {
  // SECTION: Hooks
  const scheduleForEntity = Form.useWatch('scheduleForEntity', form)

  // SECTION: Actions
  const setEmployeeId = (employeeId: NString) => {
    form.setFieldValue('employeeId', employeeId)
  }

  const setPositionId = (positionId: NString) => {
    form.setFieldValue('employeePositionId', positionId)
  }

  // SECTION: Effects
  useEffect(() => {
    // Set default values
    form.setFieldValue('scheduleForEntity', EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE)
  }, [])

  return (
    <Form layout="vertical" form={form} className="employee-schedule-form">
      <Flex vertical gap="large">
        <Form.Item name="scheduleForEntity">
          <Radio.Group size="large">
            <Radio.Button
              className="employee-schedule-form__radio-button"
              value={EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION}
            >
              Для должности
            </Radio.Button>
            <Radio.Button
              className="employee-schedule-form__radio-button"
              value={EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE}
            >
              Для сотрудника
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item style={{ display: 'none' }} name="employeeId" />
        {scheduleForEntity === EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE && (
          <Form.Item colon={false} label="Сотрудник" name="employee" rules={EmployeeScheduleEmployeeRules}>
            <EmployeesAutocompleteContainer
              size="large"
              form={form}
              name="employee"
              suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
              setEmployeeId={setEmployeeId}
            />
          </Form.Item>
        )}
        <Form.Item style={{ display: 'none' }} name="employeePositionId" />
        {scheduleForEntity === EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION && (
          <Form.Item colon={false} label="Должность" name="position" rules={EmployeeSchedulePositionRules}>
            <PositionsAutocompleteContainer
              size="large"
              form={form}
              name="position"
              suffix={<SearchOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />}
              setPositionId={setPositionId}
            />
          </Form.Item>
        )}

        <Form.Item
          colon={false}
          rules={EmployeeScheduleDateRangeRules}
          name="schedulePeriod"
          label={
            <Flex gap="small" align="center">
              <Typography.Paragraph className="employee-schedule-form__label">Период расписания</Typography.Paragraph>
              <QuestionTooltip title="До начала и после окончания указанного периода будет считаться, что сотрудник работает в соответствии с общим графиком работы вашей студии." />
            </Flex>
          }
        >
          <DatePicker.RangePicker size="large" placeholder={['Дата начала', 'Дата действия']} />
        </Form.Item>
      </Flex>
    </Form>
  )
}

// @ts-nocheck
import { notification } from 'antd'

import { Api, api } from '../../../../api/api'
import { ISyncUtilResult } from '../sync-kkm-with-backend.types'

export const getKkmCommands = async (getKKMCommandsCallback: (api: Api) => Promise<any>): ISyncUtilResult<any> => {
  let hasError = false

  const response = await getKKMCommandsCallback(api).catch(e => {
    hasError = true
    notification.error({
      message: 'Не удалось запросить команды ККМ',
      description: 'Причина: ' + e.message,
    })
  })

  return {
    response: response.data,
    hasError,
  }
}

import React from 'react'
import { useDispatch } from 'react-redux'

import { salariesPageActions } from '../../store/pages/salaries-page/salaries-page.slice'

export function useSalariesPage() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(salariesPageActions.fetchAllSalaries())
    dispatch(salariesPageActions.fetchAllStudios())
    dispatch(salariesPageActions.fetchAllDirections())
    dispatch(salariesPageActions.fetchAllExercises())
    dispatch(salariesPageActions.fetchAllGrades())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(salariesPageActions.reset())
    }
  }, [dispatch])
}

import * as React from 'react'
import { Divider, Input, Select, Space, Button, Form } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

import { useMasterServicesFormSubservicesCategory } from './master-services-form-subservices-service-category.hook'

interface Props {
  options: DefaultOptionType[] | undefined
  formItemName: (string | number)[]
  onAdd: (value: string) => void
}

export const MasterServicesFormSubservicesServiceCategory: React.FC<Props> = props => {
  const { formItemName, options, onAdd } = props
  const { categoryValidationRules } = useMasterServicesFormSubservicesCategory()

  const [name, setName] = React.useState<string>('')

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    onAdd(name)
    setName('')
  }

  return (
    <Form.Item
      name={formItemName}
      label="Категория"
      tooltip="Создавайте простые категории. Это позволит сделать умную сортировку множества услуг в клиентском виджете."
      rules={categoryValidationRules}
    >
      <Select
        size="large"
        placeholder="Выбрать"
        optionLabelProp="label"
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <Input placeholder="Введите название" value={name} onChange={onNameChange} />
              <Button type="text" onClick={addItem} disabled={name.length === 0 ? true : false}>
                Добавить
              </Button>
            </Space>
          </>
        )}
        showSearch
        options={options}
        filterOption={(input, option) => (option?.label as string).toLowerCase().includes(input.toLowerCase())}
      />
    </Form.Item>
  )
}

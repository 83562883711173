import dayjs from 'dayjs'

import { NString } from '../../../../../types/lang.types'
import { FIGURE_DIRECTION, FIGURE_MODE } from '../constants/figure'

export const getIsWithin = (hour: number, timeStart: NString, timeEnd: NString) => {
  if (!timeStart || !timeEnd) return false

  const timeToCheck = dayjs().hour(hour).minute(0)
  const fromTime = dayjs(timeStart, 'HH:mm')
  const toTime = dayjs(timeEnd, 'HH:mm')

  return timeToCheck.isBetween(fromTime, toTime)
}

export const getFigureTimeRange = (
  hour: number | string,
  direction: FIGURE_DIRECTION,
  mode: FIGURE_MODE
): { start: string | null; end: string | null } => {
  const time = {
    start: dayjs().hour(Number(hour)).minute(0).format('HH:mm'),
    end: dayjs().hour(Number(hour)).minute(0).add(1, 'hour').format('HH:mm'),
  }

  if (mode === FIGURE_MODE.HALF) {
    if (direction === FIGURE_DIRECTION.LEFT) {
      time.end = dayjs().hour(Number(hour)).minute(30).format('HH:mm')
    }
    if (direction === FIGURE_DIRECTION.RIGHT) {
      time.start = dayjs().hour(Number(hour)).minute(30).format('HH:mm')
    }
  }

  return time
}

export const checkHalfTime = (time: string) => {
  const minutes = dayjs.duration(dayjs(time, 'HH:mm').minute(), 'minutes').asMinutes()
  return minutes === 30
}

export const checkHalfStartOrEnd = (start: string, end: string): 'start' | 'end' | null => {
  const isHalf = checkHalfTime(start) || checkHalfTime(end)
  if (isHalf) return checkHalfTime(start) ? 'start' : 'end'

  return null
}

const getAdditionalZero = (value: string) => {
  const hourValue = value.split(':')[0]
  return hourValue.length === 1 ? `0${value}` : value
}

export const getTimeRangesByHour = (start: string, end: string) => {
  let startHour = parseInt(start.split(':')[0])
  const startMinutes = parseInt(start.split(':')[1])
  const endHour = parseInt(end.split(':')[0])
  const endMinutes = parseInt(end.split(':')[1])

  const timeRanges = []

  // If start time is not at the beginning of the hour, add the first time range
  if (startMinutes !== 0) {
    timeRanges.push({
      from: getAdditionalZero(start),
      to: `${getAdditionalZero(`${startHour + 1}:00`)}`,
    })
    startHour++
  }

  // Add the middle time ranges
  for (let i = startHour; i < endHour; i++) {
    timeRanges.push({
      from: `${getAdditionalZero(`${i}:00`)}`,
      to: `${getAdditionalZero(`${i + 1}:00`)}`,
    })
  }

  // If end time is not at the beginning of the hour, add the last time range
  if (endMinutes !== 0) {
    timeRanges.push({
      from: `${getAdditionalZero(`${endHour}:00`)}`,
      to: getAdditionalZero(end),
    })
  }

  return timeRanges
}

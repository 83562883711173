import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { ClientsEditPagePeekExerciseHistoryFetchAuditPayload } from './clients-edit-page-peek-exercise-history.types'

export interface ClientsEditPagePeekExerciseHistoryState {
  exerciseAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: ClientsEditPagePeekExerciseHistoryState = {
  exerciseAudit: null,
  isLoading: false,
  error: null,
}

export const {
  actions: clientsEditPagePeekExerciseHistoryActions,
  reducer: clientsEditPagePeekExerciseHistoryReducer,
} = createSlice({
  name: '@@clients-edit-page-peek-exercise-history',
  initialState,
  reducers: {
    fetchAudit: (
      state: Draft<ClientsEditPagePeekExerciseHistoryState>,
      _: PayloadAction<ClientsEditPagePeekExerciseHistoryFetchAuditPayload>
    ): void => {
      state.isLoading = true
    },
    fetchAuditSuccess: (
      state: Draft<ClientsEditPagePeekExerciseHistoryState>,
      action: PayloadAction<Pagination<AuditApi.Audit>>
    ): void => {
      state.exerciseAudit = action.payload
      state.isLoading = false
    },
    fetchAuditError: (state: Draft<ClientsEditPagePeekExerciseHistoryState>, action: PayloadAction<Error>): void => {
      state.error = action.payload
      state.isLoading = false
    },
    reset: () => initialState,
  },
})

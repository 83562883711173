import { Button } from 'antd'

interface Props {
  showCancelled: boolean
  changeShowCancelled: () => void
}

export const ScheduleGroupPageGuestsButton = ({ showCancelled, changeShowCancelled }: Props) => {
  const buttonText = showCancelled ? 'Скрыть отменивших запись' : 'Показать отменивших запись'
  return <Button onClick={changeShowCancelled}>{buttonText}</Button>
}

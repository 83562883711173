import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { CustomFieldFormValues } from '@components/custom-fields/custom-fields-form/custom-fields-form.types'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { genCustomFieldsIsLoading } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'

export function useCustomFieldsPageModalCreate() {
  const [form] = Form.useForm<CustomFieldFormValues>()

  const dispatch = useDispatch()

  const isLoading = useSelector(genCustomFieldsIsLoading)
  const onCancelHandler = (): void => {
    dispatch(modalActions.closeLast())
  }

  return {
    form,
    isLoading,
    onCancelHandler,
  }
}

import { put, select, takeEvery } from 'redux-saga/effects'

import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { customFieldsSettingsActions } from './custom-fields-settings.slice'
import { getCustomFieldsSettings } from './custom-fields-settings.selectors'

export function* updateEmployeeCustomFields(
  action: ReturnType<typeof customFieldsSettingsActions.updateEmployeeCustomFields>
) {
  try {
    const { id } = action.payload
    // @ts-ignore
    const fields = yield select(getCustomFieldsSettings)
    for (const item of fields.customFields) {
      const { id: fieldId, value } = item
      const valueToSend = Array.isArray(value) ? value : [value]
      yield callApi(api.customFieldsService.updateCustomFieldEmployee, id, fieldId, valueToSend)
    }
    yield put(customFieldsSettingsActions.updateEmployeeCustomFieldsSuccess())
    yield put(customFieldsSettingsActions.reset())
  } catch (e) {
    console.error(e)
    yield put(customFieldsSettingsActions.updateEmployeeCustomFieldsError(new Error()))
  }
}

export function* updateStudioCustomFields(
  action: ReturnType<typeof customFieldsSettingsActions.updateStudioCustomFields>
) {
  try {
    const { id } = action.payload
    // @ts-ignore
    const fields = yield select(getCustomFieldsSettings)
    for (const item of fields.customFields) {
      const { id: fieldId, value } = item
      const valueToSend = Array.isArray(value) ? value : [value]
      yield callApi(api.customFieldsService.updateCustomFieldStudio, id, fieldId, valueToSend)
    }
    yield put(customFieldsSettingsActions.updateEmployeeCustomFieldsSuccess())
    yield put(customFieldsSettingsActions.reset())
  } catch (e) {
    console.error(e)
    yield put(customFieldsSettingsActions.updateStudioCustomFieldsError(new Error()))
  }
}

export function* updateRoomCustomFields(action: ReturnType<typeof customFieldsSettingsActions.updateRoomCustomFields>) {
  try {
    const { id } = action.payload
    // @ts-ignore
    const fields = yield select(getCustomFieldsSettings)
    for (const item of fields.customFields) {
      const { id: fieldId, value } = item
      const valueToSend = Array.isArray(value) ? value : [value]
      yield callApi(api.customFieldsService.updateCustomFieldRoom, id, fieldId, valueToSend)
    }
    yield put(customFieldsSettingsActions.updateRoomCustomFieldsSuccess())
    yield put(customFieldsSettingsActions.reset())
  } catch (e) {
    console.error(e)
    yield put(customFieldsSettingsActions.updateRoomCustomFieldsError(new Error()))
  }
}

export function* updateClientCustomFields(
  action: ReturnType<typeof customFieldsSettingsActions.updateClientCustomFields>
) {
  try {
    const { id } = action.payload
    // @ts-ignore
    const fields = yield select(getCustomFieldsSettings)
    for (const item of fields.customFields) {
      const { id: fieldId, value } = item
      const valueToSend = Array.isArray(value) ? value : [value]
      yield callApi(api.customFieldsService.updateCustomFieldClient, id, fieldId, valueToSend)
    }
    yield put(customFieldsSettingsActions.updateClientCustomFieldsSuccess())
    yield put(customFieldsSettingsActions.reset())
  } catch (e) {
    console.error(e)
    yield put(customFieldsSettingsActions.updateClientCustomFieldsError(new Error()))
  }
}

export function* customFieldsSettingsSagas() {
  yield takeEvery(customFieldsSettingsActions.updateEmployeeCustomFields, updateEmployeeCustomFields)
  yield takeEvery(customFieldsSettingsActions.updateStudioCustomFields, updateStudioCustomFields)
  yield takeEvery(customFieldsSettingsActions.updateRoomCustomFields, updateRoomCustomFields)
  yield takeEvery(customFieldsSettingsActions.updateClientCustomFields, updateClientCustomFields)
}

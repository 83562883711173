import { Receipt } from '../api/types/api.types'
import { ProductsApi } from '../api/types/products-api.types'
import { TransactionsApi } from '../api/types/transactions-api.types'
import {
  ClientsTransactionsTableDataItem,
  ClientsTransactionsTableDataItemProduct,
} from '../components/clients/clients-transactions-table/clients-transactions-table.types'
import { ReceiptsListItemData } from '../components/receipts/receipts-list/receipts-list-item/receipts-list-item.types'
import { TransactionsFormValues } from '../components/transactions/transactions-form/transactions-form.types'
import {
  TransactionsTableDataItem,
  TransactionsTableDataItemProduct,
} from '../components/transactions/transactions-table/transactions-table.types'
import { formatPenniesToRubles } from '../format/number.format'
import { formatFormValueToPhoneNumber } from '../format/phone.format'
import { isDef, isDefAndNotEmpty, MarkCodesAndCountableEntityItem, NString, Nullable } from '../types/lang.types'
import { ClientPaymentMethod, PaymentMethod } from '../types/payment.types'
import { TransactionStatus } from '../types/transactions.types'
import { validateStrEnumValue } from '../utils/enum.utils'
import { mapProductTypeToApi } from './products.mapping'

export function mapTransactionsToTransactionsTableDataItemsList(
  transactions: Nullable<TransactionsApi.Transaction[]>
): TransactionsTableDataItem[] | undefined {
  if (isDefAndNotEmpty(transactions)) {
    return transactions.reduce<TransactionsTableDataItem[]>(
      (acc: TransactionsTableDataItem[], transaction: TransactionsApi.Transaction) => {
        const status = mapTransactionStatusToClient(transaction.status)
        const paymentMethod = mapPaymentMethodToClient(transaction.paymentMethod)

        if (isDef(status) && isDef(paymentMethod)) {
          const products = mapTransactionsProductsToTransactionsTableDataItemsProducts(transaction.products)
          const toPay = isDef(transaction.toPay) ? formatPenniesToRubles(transaction.toPay) : 0
          const sum = transaction.sum || 0
          const transactionsTableDataItem: TransactionsTableDataItem = {
            id: transaction.id,
            date: transaction.createDate,
            phone: transaction.client.phone,
            count: transaction.productsCount || 0,
            toPay,
            products,
            status,
            sum,
            discount: transaction.discount,
            discountReason: transaction.discountReason,
            client: transaction.client,
            paymentMethod,
            cardPaymentInfo: transaction.cardPaymentInfo,
            kkmCommandData: transaction.kkmCommandData,
            seller: transaction.seller,
          }

          acc.push(transactionsTableDataItem)
        }

        return acc
      },
      []
    )
  }
}

export function mapTransactionsProductsToTransactionsTableDataItemsProducts(
  products: TransactionsApi.TransactionProduct[]
): TransactionsTableDataItemProduct[] {
  return products.map(
    (product: TransactionsApi.TransactionProduct): TransactionsTableDataItemProduct => ({
      id: product.id,
      name: product.name,
      cost: formatPenniesToRubles(product.cost),
      count: product.count,
      discount: formatPenniesToRubles(product.discount),
      type: product.type,
    })
  )
}

export function mapTransactionStatusToClient(value: TransactionsApi.TransactionStatus): Nullable<TransactionStatus> {
  return validateStrEnumValue<TransactionStatus>(TransactionStatus, value)
}

export function mapPaymentMethodToClient(value: TransactionsApi.PaymentMethod): Nullable<ClientPaymentMethod> {
  return validateStrEnumValue<ClientPaymentMethod>(ClientPaymentMethod, value)
}

export function genTransactionDTO(
  values: TransactionsFormValues,
  studioId: NString,
  products: Nullable<MarkCodesAndCountableEntityItem<ProductsApi.Product>[]>
): Nullable<TransactionsApi.TransactionDTO> {
  if (isDef(studioId) && isDefAndNotEmpty(products)) {
    const transactionProductDTOList = genTransactionProductDTO(products, values.paymentMethod)

    if (isDefAndNotEmpty(transactionProductDTOList)) {
      const result = {
        clientPhone: formatFormValueToPhoneNumber(values.phone),
        paymentMethod: values.paymentMethod,
        products: transactionProductDTOList,
        studioId,
        discountReason: values.discountReason,
        offlineTillId: values.isSendOfflineTillId ? values.offlineTillId : null,
        deposit: values.deposit,
        exerciseId: values.exerciseId,
      }

      return result
    }
  }

  return null
}

function genTransactionProductDTO(
  products: Nullable<MarkCodesAndCountableEntityItem<ProductsApi.Product>[]>,
  paymentMethod: PaymentMethod
): Nullable<TransactionsApi.TransactionProductDTO[]> {
  if (isDefAndNotEmpty(products)) {
    return products.reduce<TransactionsApi.TransactionProductDTO[]>((acc, product) => {
      const type = mapProductTypeToApi(product.entity.productType)

      if (isDef(type)) {
        const transactionProductDTO: TransactionsApi.TransactionProductDTO = {
          id: product.entity.id,
          count: product.count,
          type,
          discount: paymentMethod !== PaymentMethod.NO_PAYMENT && product.discount ? product.discount : 0,
          exerciseId: product?.exerciseId,
        }

        if (product.entity.bookingIds) {
          transactionProductDTO.bookingIds = product.entity.bookingIds
        }

        if (product.markCodes?.length) {
          if (product.markCodes.length > product.count) {
            transactionProductDTO.markCodes = product.markCodes.slice(0, product.count)
          } else {
            transactionProductDTO.markCodes = product.markCodes
          }
        }

        acc.push(transactionProductDTO)
      }

      return acc
    }, [])
  }

  return null
}

export function mapTransactionsToClientsTransactionsTableDataItems(
  transactions: Nullable<TransactionsApi.Transaction[]>
): Nullable<ClientsTransactionsTableDataItem[]> {
  if (isDefAndNotEmpty(transactions)) {
    return transactions.reduce<ClientsTransactionsTableDataItem[]>(
      (acc: ClientsTransactionsTableDataItem[], transaction: TransactionsApi.Transaction) => {
        const status = mapTransactionStatusToClient(transaction.status)
        const paymentMethod = mapPaymentMethodToClient(transaction.paymentMethod)

        if (isDef(status) && isDef(paymentMethod)) {
          const products = mapTransactionsProductsToClientsTransactionsTableDataItemsProducts(transaction.products)

          const transactionsTableDataItem: ClientsTransactionsTableDataItem = {
            id: transaction.id,
            createDate: transaction.createDate,
            status,
            paymentMethod,
            paymentStudio: transaction?.paymentStudio?.name ?? '',
            paymentStudioId: transaction.paymentStudio.id ?? '',
            products,
            toPay: transaction.toPay,
            sum: transaction.sum,
            cardPaymentInfo: transaction.cardPaymentInfo,
            seller: transaction.seller,
            discount: transaction.discount,
            discountReason: transaction.discountReason,
          }

          acc.push(transactionsTableDataItem)
        }
        acc.sort((a, b) => (a.createDate < b.createDate ? 1 : -1))
        return acc
      },
      []
    )
  }

  return null
}

export function mapTransactionsProductsToClientsTransactionsTableDataItemsProducts(
  products: TransactionsApi.TransactionProduct[]
): ClientsTransactionsTableDataItemProduct[] {
  return products.map(
    (product: TransactionsApi.TransactionProduct): ClientsTransactionsTableDataItemProduct => ({
      id: product.id,
      name: product.name,
      cost: formatPenniesToRubles(product.cost),
      count: product.count,
      discount: formatPenniesToRubles(product.discount),
      type: product.type,
      used: product.used,
      refunded: product.refunded,
      refaunder: product.refaunder,
      linkedExerciseId: product.linkedExerciseId,
      originalExerciseId: product.originalExerciseId,
      paymentBookingIds: product.paymentBookingIds,
    })
  )
}

export function mapTransactionsReceiptsToReceiptsListItemDataList(
  receipts: Nullable<Receipt[]>
): Nullable<ReceiptsListItemData[]> {
  if (isDefAndNotEmpty(receipts)) {
    return receipts.reduce<ReceiptsListItemData[]>((acc, receipt) => {
      const { id, url, number, createDate } = receipt

      if (isDef(url) && isDef(number) && isDef(createDate)) {
        const receiptsListItemData: ReceiptsListItemData = {
          id,
          createdDate: createDate,
          link: url,
          number: number,
        }

        acc.push(receiptsListItemData)
      }

      return acc
    }, [])
  }

  return null
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isDef } from '../../types/lang.types'
import { useMasterServicesEditPageParams } from './master-services-edit-page-hooks/master-services-edit-page-params.hook'
import { masterServicesEditPageActions } from '../../store/pages/master-services-edit-page/master-services-edit-page.slice'
import {
  genMasterServicesEditPageIsLoaded,
  genMasterServicesEditPageIsLoading,
} from '../../store/pages/master-services-edit-page/master-services-edit-page.selectors'

export function useMasterServicesEditPage() {
  const { id } = useMasterServicesEditPageParams()

  const isLoading = useSelector(genMasterServicesEditPageIsLoading)
  const isLoaded = useSelector(genMasterServicesEditPageIsLoaded)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDef(id)) {
      dispatch(masterServicesEditPageActions.fetchPageData(id))
    }
  }, [dispatch, id])

  React.useEffect(() => {
    return () => {
      dispatch(masterServicesEditPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
  }
}

import { createSelector } from '@reduxjs/toolkit'

import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'
import { mapExercisesTypesToExercisesForm } from '../../../mapping/exercises-types.mapping'
import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { AppState } from '../../app.store'

const getDirectionsInternal = (state: AppState) => state.productsSubscriptionsCreatePage.directions
const getStudiosInternal = (state: AppState) => state.productsSubscriptionsCreatePage.studios
const getExercisesInternal = (state: AppState) => state.productsSubscriptionsCreatePage.exercises

export const genProductsSubscriptionsCreatePageIsLoading = (state: AppState): boolean =>
  state.productsSubscriptionsCreatePage.isLoading

export const getDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)
export const getStudiosOptions = createSelector(getStudiosInternal, studios => mapStudiosToOptions(studios?.content))
export const getExercisesOptions = createSelector(getExercisesInternal, exercise =>
  mapExercisesTypesToExercisesForm(exercise?.content)
)

import { all, put, takeLatest, select, take, race, call } from 'redux-saga/effects'
import { push, createMatchSelector } from 'connected-react-router'
import { match } from 'react-router-dom'

import { api } from '../../../api/api'
import { genStudiosPagePath } from '../../../format/path.format'
import { studiosEditPageActions } from './studios-edit-page.slice'
import { genStudiosEditDTO } from '../../../mapping/studios.mapping'
import { callApi } from '../../../utils/sagas.utils'
import { genStudioRoomDTO } from '../../../mapping/studios-rooms.mapping'
import { customFieldsSettingsActions } from '../../common/custom-fields-settings/custom-fields-settings.slice'
import { customFieldsPageActions } from '../custom-fields-page/custom-fields-page.slice'
import { AppPath } from '../../../types/path.types'
import { AppState } from '../../app.store'
import { StudiosEditPageParams } from '../../../pages/studios-edit-page/studios-edit-page.types'

export function* fetchPageData(action: ReturnType<typeof studiosEditPageActions.fetchPageData>) {
  try {
    const [studio, franchises, directions]: [
      Awaited<ReturnType<typeof api.studiosService.fetchById>>,
      Awaited<ReturnType<typeof api.organizationsService.fetchAll>>,
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchAll>>
    ] = yield all([
      callApi(api.studiosService.fetchById, action.payload),
      callApi(api.organizationsService.fetchAll, {
        size: 100,
      }),
      callApi(api.exercisesDirectionsService.fetchAll, {
        size: 100,
      }),
    ])
    yield put(
      studiosEditPageActions.fetchPageDataSuccess({
        studio: studio.data,
        franchises: franchises.data,
        directions: directions.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* fetchStudio(action: ReturnType<typeof studiosEditPageActions.fetchStudio>) {
  try {
    const studio: Awaited<ReturnType<typeof api.studiosService.fetchById>> = yield callApi(
      api.studiosService.fetchById,
      action.payload
    )

    yield put(studiosEditPageActions.fetchStudioSuccess(studio.data))
  } catch (e) {
    yield put(studiosEditPageActions.fetchStudioError(new Error()))
  }
}

export function* reFetchStudio() {
  const { params }: match<StudiosEditPageParams> = yield select(
    createMatchSelector<AppState, StudiosEditPageParams>(AppPath.STUDIOS_EDIT)
  )

  const { id } = params

  yield put(studiosEditPageActions.fetchStudio(id))

  yield race([take(studiosEditPageActions.fetchStudioSuccess.type), take(studiosEditPageActions.fetchStudioError.type)])
}

export function* updateStudio(action: ReturnType<typeof studiosEditPageActions.updateStudio>) {
  try {
    const { id, data } = action.payload
    const studioDTO = genStudiosEditDTO(data)

    yield callApi(api.studiosService.update, id, studioDTO)

    yield put(studiosEditPageActions.updateStudioSuccess())
    yield put(
      customFieldsSettingsActions.updateStudioCustomFields({
        id: id,
      })
    )
    yield put(push(genStudiosPagePath()))
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.updateStudioError(new Error()))
  }
}

export function* createStudioRoom(action: ReturnType<typeof studiosEditPageActions.createStudioRoom>) {
  try {
    const { studioId, data } = action.payload
    const studioDTO = genStudioRoomDTO(data)

    const response: Awaited<ReturnType<typeof api.studiosRoomsService.create>> = yield callApi(
      api.studiosRoomsService.create,
      studioId,
      studioDTO
    )
    const updateCustomFieldsAction = customFieldsSettingsActions.updateRoomCustomFields({ id: response.data.id })
    yield put(updateCustomFieldsAction)

    yield put(studiosEditPageActions.createStudioRoomSuccess())
    yield call(reFetchStudio)
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.createStudioRoomError(new Error()))
  }
}

export function* updateStudioRoom(action: ReturnType<typeof studiosEditPageActions.updateStudioRoom>) {
  try {
    const { studioId, id, data } = action.payload
    const studioDTO = genStudioRoomDTO(data)
    const updateCustomFieldsAction = customFieldsSettingsActions.updateRoomCustomFields({ id })
    yield put(updateCustomFieldsAction)

    yield callApi(api.studiosRoomsService.update, studioId, id, studioDTO)

    yield put(studiosEditPageActions.updateStudioRoomSuccess())
    yield call(reFetchStudio)
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.updateStudioRoomError(new Error()))
  }
}

export function* removeStudioRoom(action: ReturnType<typeof studiosEditPageActions.removeStudioRoom>) {
  try {
    const { studioId, id } = action.payload

    yield callApi(api.studiosRoomsService.remove, studioId, id)

    yield put(studiosEditPageActions.removeStudioRoomSuccess())
    yield call(reFetchStudio)
  } catch (e) {
    console.error(e)
    yield put(studiosEditPageActions.removeStudioRoomError(new Error()))
  }
}

export function* studiosEditPageSagas() {
  yield takeLatest(studiosEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(studiosEditPageActions.fetchStudio, fetchStudio)
  yield takeLatest(studiosEditPageActions.updateStudio, updateStudio)
  yield takeLatest(studiosEditPageActions.updateStudioRoom, updateStudioRoom)
  yield takeLatest(studiosEditPageActions.removeStudioRoom, removeStudioRoom)
  yield takeLatest(studiosEditPageActions.createStudioRoom, createStudioRoom)
}

import { notification } from 'antd'

export const notificationsShifts = Object.freeze({
  errorOpenShiftOnPC: () => {
    notification.error({
      message: `Не удалось открыть смену для подключенной кассе к компьютеру`,
    })
  },

  errorOpenShiftOnBD: () => {
    notification.error({
      message: `Ошибка отправки команды открытия смены на сервер`,
    })
  },

  errorCloseShiftOnPC: () => {
    notification.error({
      message: `Не удалось закрыть смену для подключенной кассе к компьютеру`,
    })
  },

  errorCloseShiftOnBD: () => {
    notification.error({
      message: `Ошибка отправки команды закрытия смены на сервер`,
    })
  },

  errorOpenShift: () => {
    notification.error({
      message: `Не удалось открыть смену`,
    })
  },

  errorCloseShift: () => {
    notification.error({
      message: `Не удалось закрыть смену`,
    })
  },

  errorWithUserInn: () => {
    notification.error({
      message: `Не удалось открыть смену из-за отсутствия ИНН, пожалуйста укажите свой ИНН:`,
    })
  },
})

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

import { genPaginationConfig } from '../../../utils/pagination.utils'
import { genTransactionsPagePath } from '../../../format/path.format'
import {
  TransactionsTableDataItem,
  TransactionsTableSearchFormValues,
} from '../../../components/transactions/transactions-table/transactions-table.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import {
  getTransactionsPageIsLoading,
  getTransactionsPageTableItemsList,
  getTransactionsPageTableTotalElements,
} from '../../../store/pages/transactions-page/transactions-page-common/transactions-page-common.selectors'
import { useTransactionsPageParams } from '../transactions-page-hooks/transactions-page-params.hook'
import { Nullable } from '../../../types/lang.types'
import { peekActions } from '../../../store/common/peek/peek.slice'
import { AppPeek } from '../../../types/peek.types'

export function useTransactionsPageTable() {
  const { push } = useHistory()
  const dispatch = useDispatch()

  const {
    studioId,
    page,
    size,
    paymentMethod,
    status,
    clientId,
    clientPhone,
    seller,
    sellerId,
    product,
    productId,
    dateFrom,
    dateTo,
  } = useTransactionsPageParams()

  const transactions = useSelector(getTransactionsPageTableItemsList)
  const totalElements = useSelector(getTransactionsPageTableTotalElements)
  const isLoading = useSelector(getTransactionsPageIsLoading)

  const [productsSearchForm] = Form.useForm<TransactionsTableSearchFormValues>()
  const [clientsSearchForm] = Form.useForm<TransactionsTableSearchFormValues>()
  const [employeesSearchForm] = Form.useForm<TransactionsTableSearchFormValues>()

  const [period, setPeriod] = React.useState<[Nullable<Dayjs>, Nullable<Dayjs>]>()

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])
  const filters = React.useMemo(
    () => ({
      paymentMethod,
      status,
    }),
    []
  )

  const onChangePeriodHandler = React.useCallback((dates?: [Nullable<Dayjs>, Nullable<Dayjs>]) => {
    setPeriod(dates)
  }, [])

  const onFilterHandler = React.useCallback(
    (pagination: any, filters: any): void => {
      const clientId = clientsSearchForm.getFieldValue('clientId')
      const clientPhone = clientsSearchForm.getFieldValue('phone')?.replace(/\D/g, '')

      const seller = employeesSearchForm.getFieldValue('seller')
      const sellerId = employeesSearchForm.getFieldValue('employeeId')

      const productId = productsSearchForm.getFieldValue('productId')
      const product = productsSearchForm.getFieldValue('product')

      push(
        genTransactionsPagePath(studioId, {
          page: pagination.current,
          size: pagination.pageSize,
          clientId,
          clientPhone,
          seller,
          sellerId,
          product,
          productId,
          dateFrom: period?.[0] ? `${period?.[0].format('YYYY-MM-DD')}T00:00` : null,
          dateTo: period?.[1] ? `${period?.[1].format('YYYY-MM-DD')}T23:59` : null,
          paymentMethod: Array.isArray(filters.paymentMethod) ? filters.paymentMethod[0] : null,
          status: Array.isArray(filters.status) ? filters.status[0] : null,
        })
      )
    },
    [clientsSearchForm, employeesSearchForm, period, productsSearchForm, push, studioId]
  )

  const onSearchHandler = React.useCallback(
    (resetPeriod?: boolean): void => {
      const clientId = clientsSearchForm.getFieldValue('clientId')
      const clientPhone = clientsSearchForm.getFieldValue('phone')?.replace(/\D/g, '')

      const sellerId = employeesSearchForm.getFieldValue('employeeId')
      const seller = employeesSearchForm.getFieldValue('seller')

      const productId = productsSearchForm.getFieldValue('productId')
      const product = productsSearchForm.getFieldValue('product')

      push(
        genTransactionsPagePath(studioId, {
          page: 0,
          size,
          clientId,
          clientPhone,
          seller,
          sellerId,
          productId,
          product,
          dateFrom: period?.[0] && !resetPeriod ? `${period?.[0].format('YYYY-MM-DD')}T00:00` : null,
          dateTo: period?.[1] && !resetPeriod ? `${period?.[1].format('YYYY-MM-DD')}T23:59` : null,
          paymentMethod,
          status,
        })
      )
    },
    [clientsSearchForm, employeesSearchForm, paymentMethod, period, productsSearchForm, push, size, status, studioId]
  )

  const onBarcodeHandler = React.useCallback(
    (transaction: TransactionsTableDataItem): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.TRANSACTIONS_PAGE_RECEIPTS_MODAL,
          props: { transactionId: transaction.id },
        })
      )
    },
    [dispatch]
  )

  const onRefundSumHandler = React.useCallback(
    (transaction: TransactionsTableDataItem): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.TRANSACTIONS_PAGE_REFUND_MODAL,
          props: {
            transactionId: transaction.id,
            paymentMethod: transaction.paymentMethod,
            fromPage: 'transactions',
          },
        })
      )
    },
    [dispatch, studioId]
  )

  const onHistoryHandler = React.useCallback(
    (id: string): void => {
      dispatch(
        peekActions.show({
          peek: AppPeek.TRANSACTIONS_PAGE_PEEK_HISTORY,
          props: { id },
        })
      )
    },
    [dispatch]
  )

  React.useEffect(() => {
    if (clientId && clientPhone) {
      clientsSearchForm.setFieldsValue({ clientId, phone: `+${clientPhone}` })
    }
    if (seller && sellerId) {
      employeesSearchForm.setFieldsValue({ seller, employeeId: sellerId })
    }
    if (product && productId) {
      productsSearchForm.setFieldsValue({ product, productId })
    }
    if (dateFrom && dateTo) {
      setPeriod([dayjs(dateFrom, 'YYYY-MM-DDTHH-mm'), dayjs(dateTo, 'YYYY-MM-DDTHH-mm')])
    }
  }, [])

  return {
    isLoading,
    transactions,
    pagination,
    filters,
    period,
    productsSearchForm,
    clientsSearchForm,
    employeesSearchForm,
    onChangePeriodHandler,
    onBarcodeHandler,
    onRefundSumHandler,
    onHistoryHandler,
    onFilterHandler,
    onSearchHandler,
  }
}

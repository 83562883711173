import { EmployeesAutocomplete } from '@components/employees/employees-autocomplete/employees-autocomplete.component'

import { useEmployeesAutocomplete } from './employees-autocomplete.hook'
import { EmployeesAutocompleteProps } from './employees-autocomplete.types'

export const EmployeesAutocompleteContainer = <Form,>(props: EmployeesAutocompleteProps<Form>) => {
  const { className, size, placeholder, suffix } = props
  const { form, name = 'employee', disabled, defaultValue } = props
  const { transactionsForm = false } = props
  const { onChange, setEmployeeId } = props

  const { value, options, loading, onChangeHandler, onSearchHandler, onSelectHandler } = useEmployeesAutocomplete({
    name,
    form,
    transactionsForm,
    onChange,
    setEmployeeId,
  })

  return (
    <EmployeesAutocomplete
      className={className}
      value={value}
      options={options}
      defaultValue={defaultValue}
      loading={loading}
      disabled={disabled}
      onChange={onChangeHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
      size={size ?? 'middle'}
      placeholder={placeholder}
      suffix={suffix}
    />
  )
}

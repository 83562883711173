import { PlusOutlined } from '@ant-design/icons'
import { ProductsSubscriptionsApi } from '@api/types/products-subscriptions-api.types'
import { genProductServicesCreatePagePath, genProductSubscriptionCreatePagePath } from '@format/path.format'
import { Button, Flex, Typography } from 'antd'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { CustomCard } from '../../components/custom-card/custom-card.component'
import { ProductsPageMenu } from './products-page-menu/products-page-menu.component'
import { ProductsPageSectionGroup } from './products-page-section-group/products-page-section-group.component'
import { ProductsPageSectionServices } from './products-page-section-services/products-page-section-services.component'
import { useProductsPage } from './products-page.hook'
import './products-page.styles.less'
import { ProductsPageSection } from './products-page.types'

export const ProductsPage: React.FC = () => {
  const { section } = useProductsPage()

  return (
    <>
      <Flex justify="space-between" align="center">
        <Typography.Title level={2}>Абонементы и разовые услуги</Typography.Title>
        <Flex gap={10}>
          <Link
            to={genProductSubscriptionCreatePagePath({ type: ProductsSubscriptionsApi.ProductSubscriptionType.GROUP })}
            className="menu-item"
          >
            <Button icon={<PlusOutlined />} type="primary">
              Создать абонемент
            </Button>
          </Link>
          <Link to={genProductServicesCreatePagePath()}>
            <Button icon={<PlusOutlined />} type="primary">
              Создать разовую услугу
            </Button>
          </Link>
        </Flex>
      </Flex>
      <ProductsPageMenu />
      <CustomCard customClassName="products-page-card">
        {section === ProductsPageSection.GROUP_SUBSCRIPTIONS && <ProductsPageSectionGroup />}
        {/* {section === ProductsPageSection.INDIVIDUAL_SUBSCRIPTIONS && <ProductsPageSectionIndividual />} */}
        {section === ProductsPageSection.SERVICES && <ProductsPageSectionServices />}
      </CustomCard>
    </>
  )
}

import * as React from 'react'

import { ScheduleGroupPageModalCommentsProps } from './schedule-group-page-modal-comments.types'
import { useScheduleGroupPageModalComments } from './schedule-group-page-modal-waiting.hook'
import { ExercisesGroupCommentsModal } from '../../../components/exercises-group/exercises-group-comments-modal/exercises-group-comments-modal.component'

export const ScheduleGroupPageModalComments: React.FC<ScheduleGroupPageModalCommentsProps> = props => {
  const { exerciseId, bookingId, fullName } = props
  const { form, loading, comments, onSaveHandler, onCancelHandler } = useScheduleGroupPageModalComments(
    exerciseId,
    bookingId
  )

  return (
    <ExercisesGroupCommentsModal
      form={form}
      onSave={onSaveHandler}
      onCancel={onCancelHandler}
      fullName={fullName}
      loading={loading}
      comments={comments}
    />
  )
}

import { modalActions } from '@store/common/modal/modal.slice'
import { RadioChangeEvent } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'antd/lib'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { AppModal } from '../../../../types/modal.types'
import { FranchisesFormValues } from './../franchises-form.types'

export const useFranchiseFormAcceptingPayments = ({ form }: { form: FormInstance<FranchisesFormValues> }) => {
  const dispatch = useDispatch()
  if (!form) {
    throw new Error('Form instance is not provided')
  }
  const [paymentMethod, setPaymentMethod] = useState<'online' | 'offline'>('online')
  const handlePaymentMethodChange = (e: RadioChangeEvent) => {
    const value = e.target.value
    setPaymentMethod(value)
    form.setFieldsValue({ paymentMethod: value })
  }

  const terminalId = useWatch('terminal', form)

  // online
  const onlinePaymentValue = useWatch('onlinePaymentEnabled', form)
  const [onlinePaymentEnabledSwitchValue, setOnlinePaymentEnabledSwitchValue] = useState(false)
  useEffect(() => {
    if (onlinePaymentValue && !onlinePaymentEnabledSwitchValue) {
      form.setFieldValue('onlinePaymentEnabled', true)
      setOnlinePaymentEnabledSwitchValue(prev => !prev)
    }
  }, [onlinePaymentValue, form])
  const onChangeOnlinePaymentEnabledSwitchValue = () => {
    if (!onlinePaymentEnabledSwitchValue) {
      form.setFieldValue('terminal', null)
    }
    form.setFieldValue('onlinePaymentEnabled', !onlinePaymentEnabledSwitchValue)
    setOnlinePaymentEnabledSwitchValue(prev => !prev)
  }

  // offline
  const offlinePaymentValue = useWatch('offlinePaymentEnabled', form)
  const [offlinePaymentEnabledSwitchValue, setOfflinePaymentEnabledSwitchValue] = useState(false)
  const onChangeOfflinePaymentEnabledSwitchValue = () => {
    form.setFieldValue('offlinePaymentEnabled', !offlinePaymentEnabledSwitchValue)
    setOfflinePaymentEnabledSwitchValue(prev => !prev)
  }
  useEffect(() => {
    if (offlinePaymentValue && !offlinePaymentEnabledSwitchValue) {
      form.setFieldValue('offlinePaymentEnabled', true)
      setOfflinePaymentEnabledSwitchValue(prev => !prev)
    }
  }, [offlinePaymentValue, form])

  // modal
  const onClickOpenModalTerminal = () => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TERMINAL_FORM,
      })
    )
  }
  const onClickOpenChangeModalTerminal = (id: string) => {
    dispatch(
      modalActions.show({
        modal: AppModal.FRANCHISES_PAGE_TERMINAL_FORM,
        props: {
          idEditedTerminal: id,
        },
      })
    )
  }
  return {
    onlinePaymentEnabledSwitchValue,
    setOnlinePaymentEnabledSwitchValue,
    onChangeOnlinePaymentEnabledSwitchValue,
    offlinePaymentEnabledSwitchValue,
    onChangeOfflinePaymentEnabledSwitchValue,
    onClickOpenModalTerminal,
    onClickOpenChangeModalTerminal,
    handlePaymentMethodChange,
    paymentMethod,
    terminalId,
  }
}

import { Breadcrumb } from 'antd'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { EMPLOYEE_SCHEDULE_EDIT_MODE } from '@constants/employees/schedule'

import { IBreadcrumbsProps } from './breadcrumbs.types'

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ mode }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to="/employees/schedule">График расписания</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        {mode === EMPLOYEE_SCHEDULE_EDIT_MODE.COPY ? 'Копирование расписания' : 'Создание расписания'}
      </Breadcrumb.Item>
    </Breadcrumb>
  )
}

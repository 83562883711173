import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { PositionsFormValues } from '../../../components/positions/positions-form/positions-form.types'
import { PositionsCreatePageCreateGradePayload } from './positions-create-page.types'

export interface PositionsCreatePageState {
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: PositionsCreatePageState = {
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: positionsCreatePageActions, reducer: positionsCreatePageReducer } = createSlice({
  name: '@@positions-create-page',
  initialState,
  reducers: {
    createPosition: (state: Draft<PositionsCreatePageState>, _: PayloadAction<PositionsFormValues>) => {
      state.isLoading = true
    },
    createPositionSuccess: (state: Draft<PositionsCreatePageState>) => {
      state.isLoading = false
    },
    createPositionError: (state: Draft<PositionsCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    createGrade: (state: Draft<PositionsCreatePageState>, _: PayloadAction<PositionsCreatePageCreateGradePayload>) => {
      state.isLoading = true
    },
    createGradeSuccess: (state: Draft<PositionsCreatePageState>) => {
      state.isLoading = false
    },
    createGradeError: (state: Draft<PositionsCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Tooltip } from 'antd'
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { employeesScheduleActions } from '@store/pages/employees/employees-schedule/employees-schedule.slice'
import { EMPLOYEE_SCHEDULE_EDIT_MODE, EMPLOYEE_SCHEDULE_STATUS } from '@constants/employees/schedule'
import { ModalConfirmDelete } from '@components/modals/modal-confirm-delete/modal-confirm-delete.component'
import { useModal } from '@hooks/use-modal.hook'

import { IActionsCellProps } from './actions-cell.types'
import './actions-cell.styles.less'
import { peekActions } from '../../../../../store/common/peek/peek.slice'
import { AppPeek } from '../../../../../types/peek.types'

export const ActionsCell: FC<IActionsCellProps> = ({ schedule }) => {
  // SECTION: Hooks
  const dispatch = useDispatch()
  const history = useHistory()

  // SECTION: Actions
  const copy = () =>
    history.push({
      pathname: '/employees/schedule/create',
      state: { schedule, mode: EMPLOYEE_SCHEDULE_EDIT_MODE.COPY },
    })
  const edit = () => history.push(`/employees/schedule/edit/${schedule.id}`)
  const deleteSchedule = () => dispatch(employeesScheduleActions.delete({ scheduleId: schedule.id }))

  const viewHistory = () => {
    dispatch(
      peekActions.show({
        peek: AppPeek.EMPLOYEES_SCHEDULE_PEEK_HISTORY,
        props: { id: schedule.id, name: schedule.employee?.name, position: schedule.employeePosition?.name },
      })
    )
  }

  // Status update
  const stop = () => dispatch(employeesScheduleActions.stop({ scheduleId: schedule.id }))
  const active = () => dispatch(employeesScheduleActions.active({ scheduleId: schedule.id }))

  // SECTION: Custom hooks
  const [
    isShowModalConfirmDelete,
    handleOpenModalConfirmDelete,
    handleCloseModalConfirmDelete,
    handleOkModalConfirmDelete,
  ] = useModal(deleteSchedule)

  // SECTION: Render
  return (
    <div className="actions-cell">
      {schedule.status === EMPLOYEE_SCHEDULE_STATUS.ACTIVE && <Button icon={<PauseOutlined />} onClick={stop} />}

      {[EMPLOYEE_SCHEDULE_STATUS.DRAFT, EMPLOYEE_SCHEDULE_STATUS.PAUSED].some(status => schedule.status === status) && (
        <Button icon={<PlayCircleOutlined />} onClick={active} />
      )}

      <Tooltip title="Посмотреть историю изменений">
        <Button icon={<HistoryOutlined />} size="middle" onClick={viewHistory} />
      </Tooltip>

      {[EMPLOYEE_SCHEDULE_STATUS.DRAFT, EMPLOYEE_SCHEDULE_STATUS.PAUSED, EMPLOYEE_SCHEDULE_STATUS.ACTIVE].some(
        status => schedule.status === status
      ) && <Button icon={<EditOutlined />} onClick={edit} />}

      <Button icon={<CopyOutlined />} onClick={copy} />
      <Button danger icon={<DeleteOutlined />} onClick={handleOpenModalConfirmDelete} />

      <ModalConfirmDelete
        isModalOpen={isShowModalConfirmDelete}
        handleOk={handleOkModalConfirmDelete}
        handleCancel={handleCloseModalConfirmDelete}
      />
    </div>
  )
}

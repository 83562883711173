import { CustomCard } from '../../../components/custom-card/custom-card.component'
import { CustomFieldsTable } from '../../../components/custom-fields/custom-fields-table/custom-fields-table.component'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { isDefAndNotEmpty } from '../../../types/lang.types'
import { CustomFieldsPageButton } from '../custom-fields-page-button/custom-fields-page-button.component'
import { useCustomFieldsPageTable } from './custom-fields-page-table.hook'

export const CustomFieldsPageTable = () => {
  const { fields, isLoading, onEditHandler, onRemoveHandler, onPauseHandler } = useCustomFieldsPageTable()

  if (isDefAndNotEmpty(fields)) {
    return (
      <CustomFieldsTable
        data={fields}
        loading={isLoading}
        onEdit={onEditHandler}
        onPause={onPauseHandler}
        onRemove={onRemoveHandler}
      />
    )
  }

  return (
    <CustomCard>
      <PageEmpty description="Кастомные поля не найдены" />
      <CustomFieldsPageButton />
    </CustomCard>
  )
}

import * as React from 'react'
import { useDispatch } from 'react-redux'

import { exercisesTypesCreatePageActions } from '../../store/pages/exercises-types-create-page/exercises-types-create-page.slice'

export function useExercisesTypesCreatePage() {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(exercisesTypesCreatePageActions.fetchPageData())
  }, [dispatch])

  React.useEffect(() => {
    return () => {
      dispatch(exercisesTypesCreatePageActions.reset())
    }
  }, [dispatch])
}

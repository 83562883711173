import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { Avatar, Button, Flex, message, Select, Tooltip, Typography } from 'antd'
import { CopyOutlined, HistoryOutlined, UserOutlined } from '@ant-design/icons'

import { ClientsInfoProps } from './clients-info.types'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '../../../constants/local-storage'
import { genTransactionsCreatePagePath } from '../../../format/path.format'
import './clients-info.styles.less'
import { formatPhoneNumber } from '../../../format/phone.format'

export const ClientsInfo: React.FC<ClientsInfoProps> = props => {
  const { push } = useHistory()

  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  const { isUpdating, clientInfo, managersOptions, onChangeManager, onHistory } = props
  const { name, photo, phone, id, managerId } = clientInfo

  return (
    <div className="clients-info-wrapper">
      <div className="clients-info">
        <Avatar className="clients-info__avatar" src={photo} icon={<UserOutlined />} alt={name} size={64} />

        <Typography.Title level={3} className="clients-info__name">
          {name}
        </Typography.Title>

        <Flex gap="small" align="center">
          <Typography.Text className="clients-info__phone">{formatPhoneNumber(phone)}</Typography.Text>
          <Tooltip title="Скопировать номер телефона">
            <CopyToClipboard text={phone}>
              <Button
                onClick={() => {
                  message.success({
                    content: 'Номер успешно скопирован',
                    className: 'transactions-table__message',
                  })
                }}
                icon={<CopyOutlined />}
                size="middle"
              />
            </CopyToClipboard>
          </Tooltip>
          <Select
            className="clients-info__manager"
            size="small"
            placeholder="менеджер"
            options={managersOptions}
            value={managerId}
            disabled={isUpdating}
            loading={isUpdating}
            onChange={onChangeManager}
          />
        </Flex>
      </div>
      <Flex vertical gap="small" align="flex-end">
        <Button
          disabled={!studioId}
          type="primary"
          onClick={() =>
            studioId &&
            push({
              pathname: genTransactionsCreatePagePath(studioId),
              state: { phone, clientId: id },
            })
          }
        >
          Быстрая продажа
        </Button>
        <Tooltip title="Посмотреть историю изменений">
          <Button
            icon={<HistoryOutlined />}
            size="middle"
            type="dashed"
            style={{ width: '48px' }}
            onClick={onHistory}
          />
        </Tooltip>
      </Flex>
    </div>
  )
}

export const EmployeeSchedulePositionRules = [
  {
    required: true,
    message: 'Выберите должность',
  },
]

export const EmployeeScheduleEmployeeRules = [
  {
    required: true,
    message: 'Выберите сотрудника',
  },
]

export const EmployeeScheduleDateRangeRules = [
  {
    required: true,
    message: 'Выберите период расписания',
  },
]

import * as React from 'react'

import { TransactionsTable } from '../../../components/transactions/transactions-table/transactions-table.component'
import { useTransactionsPageTable } from './transactions-page-table.hook'

export const TransactionsPageTable: React.FC = () => {
  const {
    isLoading,
    transactions,
    pagination,
    filters,
    period,
    productsSearchForm,
    clientsSearchForm,
    employeesSearchForm,
    onChangePeriodHandler,
    onBarcodeHandler,
    onRefundSumHandler,
    onHistoryHandler,
    onFilterHandler,
    onSearchHandler,
  } = useTransactionsPageTable()

  return (
    <TransactionsTable
      loading={isLoading}
      data={transactions}
      pagination={pagination}
      filters={filters}
      period={period}
      productsSearchForm={productsSearchForm}
      clientsSearchForm={clientsSearchForm}
      employeesSearchForm={employeesSearchForm}
      onChangePeriodHandler={onChangePeriodHandler}
      onBarcode={onBarcodeHandler}
      onRefundSum={onRefundSumHandler}
      onHistory={onHistoryHandler}
      onFilter={onFilterHandler}
      onSearchHandler={onSearchHandler}
    />
  )
}

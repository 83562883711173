import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { mapFranchisesToOptions } from '../../../mapping/franchises.mapping'
import {
  mapEmployeesGradesToOptions,
  mapEmployeesPositionsToOptions,
} from '../../../mapping/employees-positions.mapping'
import { isDef } from '../../../types/lang.types'
import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'

const getFranchisesInternal = (state: AppState) => state.employeesCreatePage.franchises
const getEmployeesPositionsInternal = (state: AppState) => state.employeesCreatePage.positions
const getDirectionsInternal = (state: AppState) => state.employeesCreatePage.directions
const getExercisesTypesInternal = (state: AppState) => state.employeesCreatePage.exercisesTypes

const getEmployeesPosition = (state: AppState) => state.employeesCreatePage.position

export const getEmployeesCreatePageFranchisesOptions = createSelector(getFranchisesInternal, franchises =>
  mapFranchisesToOptions(franchises?.content)
)

export const getEmployeesCreatePagePositionsOptions = createSelector(
  getEmployeesPositionsInternal,
  employeesPositions => mapEmployeesPositionsToOptions(employeesPositions?.content)
)

export const getEmployeesCreatePageGradesOptions = createSelector(
  getEmployeesPositionsInternal,
  getEmployeesPosition,
  (employeesPositions, employeesPosition) => {
    if (isDef(employeesPositions) && isDef(employeesPositions.content)) {
      const position = employeesPositions.content.find(position => position.id === employeesPosition)
      return mapEmployeesGradesToOptions(position?.grades)
    } else {
      return undefined
    }
  }
)

export const getEmployeesCreatePageDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

export const getEmployeesCreatePageExercisesTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToOptions(exercisesTypes?.content)
)

export const getEmployeesPositionType = createSelector(
  getEmployeesPositionsInternal,
  getEmployeesPosition,
  (employeesPositions, employeesPosition) => {
    if (isDef(employeesPositions) && isDef(employeesPositions.content)) {
      return employeesPositions?.content?.find(position => position.id === employeesPosition)?.positionType
    }
  }
)

export enum UserType {
  All = 'all',
  Administrators = 'administrators',
  Trainers = 'trainers',
  Managers = 'managers',
}

export enum UserActionSource {
  APP = 'APP',
  WEB = 'WEB',
  ADMIN = 'ADMIN',
  SERVER = 'SERVER',
  WIDGET = 'WIDGET',
}

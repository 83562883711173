import { Modal } from 'antd'

import { ICustomFieldsModalEditProps } from './custom-fields-modal-edit.types'
import { CustomFieldsForm } from '../custom-fields-form/custom-fields-form.component'
import { useCustomFieldsModalEdit } from './custom-fields-modal-edit.hook'

export const CustomFieldsModalEdit = (props: ICustomFieldsModalEditProps) => {
  const data = useCustomFieldsModalEdit(props)
  return (
    <Modal title={props.title} onCancel={props.onCancel} open={true} footer={null}>
      <CustomFieldsForm
        {...data}
        showSelectType={false}
        onSave={props.onSave}
        onCancel={props.onCancel}
        form={props.form}
      />
    </Modal>
  )
}

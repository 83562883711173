import { FormListFieldData } from 'antd'

import { StudiosFormRoomsProps } from '../studios-form-rooms.types'

export interface StudiosFormRoomProps extends StudiosFormRoomsProps {
  fields: FormListFieldData[]
  field: FormListFieldData
  index: number
  activeKeys: string[]
  onCollapseChange: (key: string[]) => void
  remove: (index: number | number[]) => void
}

export enum StudioRoomScheme {
  SINGLE = 'SINGLE',
  PLACES = 'PLACES',
  PARTS = 'PARTS',
}

import { FC, useMemo } from 'react'
import { Layout, Menu, Typography, Dropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Link, useLocation, matchPath, useHistory, useRouteMatch } from 'react-router-dom'
import { clsx } from 'clsx'
import { Dayjs } from 'dayjs'
import {
  LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY,
  LOCAL_STORAGE_ROOMS_KEY,
  LOCAL_STORAGE_TAGS_KEY,
} from '@constants/local-storage'

import { AppLayoutSidebarProps } from './app-layout-sidebar.types'
import { isDef, isDefAndNotEmpty } from '../../../../types/lang.types'
import { genHomePagePath, genSchedulePagePath } from '../../../../format/path.format'
import { Logo } from '../../../icons/logo.component'
import { formatDayjsToDate } from '../../../../format/date.format'
import { useSchedulePageParams } from '../../../../pages/schedule-page/schedule-page-hooks/schedule-page-params.hook'
import { SidebarCalendar } from '../../../ui/sidebar-calendar/sidebar-calendar.component'
import './app-layout-sidebar.styles.less'

export const AppLayoutSidebar: FC<AppLayoutSidebarProps> = props => {
  const { className, items, selectedStudioId, isCollapsed, studios, theme } = props

  const { studioId, ...params } = useSchedulePageParams()
  const { push } = useHistory()
  const { pathname } = useLocation()

  const selectedMenuKeys = useMemo(() => {
    const matched = items.find(item => matchPath(pathname, item.path))

    if (matched) {
      return [matched.path]
    }

    return undefined
  }, [items, pathname])

  const selectedStudioKeys = useMemo(() => {
    if (isDef(selectedStudioId)) {
      return [selectedStudioId]
    }
  }, [selectedStudioId])

  const onChangeDateHandler = (value: Dayjs): void => {
    const date = isDef(value) ? formatDayjsToDate(value) : undefined

    if (isDef(selectedStudioId)) {
      push(genSchedulePagePath(selectedStudioId, { ...params, date }))
    }
  }

  const selectedStudio = useMemo(() => {
    if (isDefAndNotEmpty(studios)) {
      const studio = studios.find(s => s.id === selectedStudioId)
      return isDef(studio) ? studio : null
    }
  }, [selectedStudioId, studios])

  const isSchedulePath = useRouteMatch('/schedule')

  const clearLocalStorage = () => {
    localStorage.setItem(LOCAL_STORAGE_TAGS_KEY, '[]')
    localStorage.setItem(LOCAL_STORAGE_ROOMS_KEY, '[]')
    localStorage.setItem(LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY, '')
  }

  return (
    <Layout.Sider
      className={clsx('app-layout-sidebar', { 'app-layout-sidebar_dark': theme === 'dark' }, className)}
      collapsible
      collapsed={isCollapsed}
      collapsedWidth={72}
      trigger={null}
      width={300}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <header className={`app-layout-sidebar__header ${theme === 'dark' ? 'app-layout-sidebar__header_dark' : ''}`}>
        <Link className="app-layout-sidebar__logo" to={genHomePagePath()}>
          <Logo />
          {!isCollapsed && (
            <>
              <div className="app-layout-sidebar__logo-text">
                <Typography.Title level={4}>{selectedStudio?.organization ?? 'Название компании'}</Typography.Title>
              </div>
              <div className="app-layout-sidebar__logo-gradient" />
            </>
          )}
        </Link>
        {isDefAndNotEmpty(studios) && !isCollapsed && (
          <Dropdown
            overlay={
              <Menu selectedKeys={selectedStudioKeys}>
                {studios.map(studio => (
                  <Menu.Item key={studio.id}>
                    <Link onClick={clearLocalStorage} to={genSchedulePagePath(studio.id)}>
                      <Typography.Text>{studio.title}</Typography.Text>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            }
          >
            <Button className="app-layout-sidebar__studio">
              <div className="app-layout-sidebar__studio-text">
                <Typography.Title level={5}>{selectedStudio?.title || 'Выберите студию'}</Typography.Title>
              </div>
              <div className="app-layout-sidebar__studio-icon">
                <DownOutlined />
              </div>
            </Button>
          </Dropdown>
        )}
      </header>

      {isSchedulePath && !isCollapsed && <SidebarCalendar onChangeDateHandler={onChangeDateHandler} />}

      <Menu
        className="app-layout-sidebar__menu"
        mode="inline"
        style={{ flex: 1, borderRight: 0 }}
        selectedKeys={selectedMenuKeys}
        disabledOverflow={true}
      >
        {items.map(item => {
          const { Icon } = item

          return (
            <Menu.Item key={item.path} className="app-layout-sidebar__menu-item">
              <Link to={item.path} className="app-layout-sidebar__menu-item-link" style={{ color: 'inherit' }}>
                <Icon />
                <Typography.Text style={{ color: 'inherit' }}>{item.title}</Typography.Text>
              </Link>
              {(() => {
                if (isDef(item.items)) {
                  const { ItemIcon } = item.items
                  return (
                    <Link to={item.items.path} className="app-layout-sidebar__menu-item-icon">
                      <ItemIcon />
                    </Link>
                  )
                }
              })()}
            </Menu.Item>
          )
        })}
      </Menu>
    </Layout.Sider>
  )
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  genMasterServicesPageListIsLoaded,
  genMasterServicesPageListIsLoading,
} from '../../store/pages/master-services-page/master-services-page-list/master-services-page-list.selectors'
import { masterServicesPageListActions } from '../../store/pages/master-services-page/master-services-page-list/master-services-page-list.slice'
import { useMasterServicesPageParams } from './master-services-page-hooks/master-services-page-params.hook'

export function useMasterServicesPage() {
  const params = useMasterServicesPageParams()

  const isLoading = useSelector(genMasterServicesPageListIsLoading)
  const isLoaded = useSelector(genMasterServicesPageListIsLoaded)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(masterServicesPageListActions.fetchAllMasterServices(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(masterServicesPageListActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
  }
}

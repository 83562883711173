import * as React from 'react'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

import { isDef, NString, Nullable } from '../../../../types/lang.types'
import { ClientSubscriptionStatus } from '../../../../types/clients-subscriptions.types'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { formatDateToHumanize } from '../../../../format/date.format'
import { ClientsSubscriptionsTableDataItemActivationBooking } from '../clients-subscriptions-table.types'
import './clients-subscriptions-table-activation.styles.less'

interface Props {
  activationDate: NString
  autoActivationDate: NString
  status: ClientSubscriptionStatus
  activationBooking: Nullable<ClientsSubscriptionsTableDataItemActivationBooking>
  onBooking: (studioId: string, scheduleId: string) => void
}

export const ClientsSubscriptionsTableActivation: React.FC<Props> = props => {
  const { activationDate, autoActivationDate, activationBooking } = props
  const { status } = props
  const { onBooking } = props

  const handleGroupLinkClick = () => {
    if (isDef(activationBooking)) {
      onBooking(activationBooking.studio.id, activationBooking.exerciseId)
    }
  }

  if (status === ClientSubscriptionStatus.NEW && isDef(autoActivationDate)) {
    const text = `Автоактивация ${formatDateToHumanize(autoActivationDate)}`
    return <TableCellText text={text} />
  }

  if (isDef(activationDate)) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TableCellText text={formatDateToHumanize(`${activationDate}Z`)} />
        {isDef(autoActivationDate) && (
          <Tooltip
            title={
              activationBooking ? (
                <Button
                  type="link"
                  className="clients-subscriptions-table-activation__link"
                  onClick={handleGroupLinkClick}
                >
                  {activationBooking.exerciseDate}, {activationBooking.studio.name},{' '}
                  {activationBooking.exerciseDirection}, {activationBooking.trainers}
                </Button>
              ) : (
                `Активирован автоматически ${formatDateToHumanize(autoActivationDate)}`
              )
            }
          >
            <div className="clients-subscriptions-table-activation__button-info">
              <InfoCircleTwoTone />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  return <TableCellText text="-" />
}

import { Button, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Typography } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'

export const TransferModal = (props: any) => {
  const { maxNumber, studios, onFinish, onCancel, isOpen } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({})
  })

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Перемещение товара">
      <Divider />
      <Form form={form} autoComplete="off" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Количество товара к перемещению"
          name="count"
          rules={[{ required: true, message: 'Введите количество товара' }]}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder=""
            suffix={`макс. ${maxNumber}`}
            max={maxNumber || Number.MAX_SAFE_INTEGER}
            controls={false}
          ></InputNumber>
        </Form.Item>
        <Form.Item label="Дата списания" name="writeOffDate" style={{ paddingTop: '8px' }}>
          <DatePicker placeholder="" style={{ width: '100%' }}></DatePicker>
        </Form.Item>
        <Form.Item
          label="Студия"
          name="studioId"
          style={{ paddingTop: '8px' }}
          rules={[{ required: true, message: 'Введите студию' }]}
        >
          <Select placeholder="" options={studios}></Select>
        </Form.Item>
        <Form.Item label="Комментарий/основание" name="comment" style={{ paddingTop: '8px' }}>
          <Input.TextArea placeholder=""></Input.TextArea>
        </Form.Item>
        <Row justify="end">
          <Button htmlType="submit" style={{ marginTop: '12px' }} type="primary">
            Переместить
          </Button>
        </Row>
      </Form>
    </Modal>
  )
}

import * as React from 'react'
import { Avatar, Button, Flex, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { ExercisesGroupWaitingClientProps } from './exercises-group-waiting-client.types'
import './exercises-group-waiting-client.styles.less'
import { getTheme } from '../../../store/common/layout/layout.selectors'

export const ExercisesGroupWaitingClient: React.FC<ExercisesGroupWaitingClientProps> = props => {
  const { phone, photo, name } = props
  const { onBook } = props

  const theme = useSelector(getTheme)

  const onBookHandler = React.useCallback((): void => {
    onBook(phone, name)
  }, [onBook, phone, name])

  return (
    <Flex
      className={`exercises-group-waiting-client exercises-group-waiting-client--${theme}`}
      align="center"
      justify="space-between"
    >
      <Flex gap="middle" align="center">
        <Avatar src={photo} icon={<UserOutlined />} alt={name} size={45} shape="square" />
        <Typography.Text>{name}</Typography.Text>
      </Flex>

      <Button onClick={onBookHandler}>Записать</Button>
    </Flex>
  )
}

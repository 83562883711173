import { ClientsSubscriptionsApi } from '../api/types/clients-subscriptions-api.types'
import { ClientsSubscriptionsPauseFormValues } from '../components/clients/clients-subscriptions-pause-form/clients-subscriptions-pause-form.types'
import {
  ClientsSubscriptionsTableDataItem,
  ClientsSubscriptionsTableDataItemActivationBooking,
  ClientsSubscriptionsTableDataItemBooking,
} from '../components/clients/clients-subscriptions-table/clients-subscriptions-table.types'
import { formatDateToHumanize } from '../format/date.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import {
  mapDictionaryItemsListToEntityItemsList,
  mapDictionaryItemToEntityItem,
  mapTimeLimitationToClient,
} from './api.mapping'
import { mapPaymentMethodToClient } from './transactions.mapping'

export function mapClientsSubscriptionsToClientsSubscriptionsTableDataItems(
  subscriptions: Nullable<ClientsSubscriptionsApi.ClientSubscription[]>
): Nullable<ClientsSubscriptionsTableDataItem[]> {
  if (isDefAndNotEmpty(subscriptions)) {
    return subscriptions.reduce<ClientsSubscriptionsTableDataItem[]>((acc, subscription) => {
      const timeLimitation = mapTimeLimitationToClient(subscription.timeLimitation)
      const paymentMethod = mapPaymentMethodToClient(subscription.paymentMethod)
      const bookings = mapClientSubscriptionBookingsToClientSubscriptionsTableDataItemBookings(subscription.bookings)

      if (isDef(timeLimitation) && isDef(paymentMethod)) {
        const clientsSubscriptionsTableDataItem: ClientsSubscriptionsTableDataItem = {
          id: subscription.subscriptionId,
          name: subscription.product.name,
          expirationDate: subscription.expirationDate,
          purchaseDate: subscription.purchaseDate,
          visitsLeft: subscription.visitsLeft,
          visitsTotal: subscription.visitsTotal,
          status: subscription.status,
          timeLimitation: subscription.timeLimitation,
          hasStudioLimitation: subscription.hasStudioLimitation,
          studios: mapDictionaryItemsListToEntityItemsList(subscription.availableStudios),
          hasTypeLimitation: subscription.hasTypeLimitation,
          availableTypes: mapDictionaryItemsListToEntityItemsList(subscription.availableTypes),
          activationDate: subscription.activationDate,
          autoActivationDate: subscription.autoActivationDate,
          variant: subscription.variant,
          minutes: subscription.minutes,
          duration: subscription.duration,
          availableMinutes: subscription.availableMinutes,
          activationBooking:
            mapClientsSubscriptionsActivationBookingToClientsSubscriptionsTableDataItemActivationBooking(
              subscription.activationBooking
            ),
          paymentMethod,
          bookings,
        }

        acc.push(clientsSubscriptionsTableDataItem)
      }

      return acc
    }, [])
  }

  return null
}

export function mapClientsSubscriptionsActivationBookingToClientsSubscriptionsTableDataItemActivationBooking(
  activationBooking: Nullable<ClientsSubscriptionsApi.ActivationBooking>
): Nullable<ClientsSubscriptionsTableDataItemActivationBooking> {
  if (isDef(activationBooking)) {
    return {
      id: activationBooking.id,
      exerciseDate: formatDateToHumanize(activationBooking.exerciseDate),
      exerciseDirection: activationBooking.exerciseDirection.name,
      exerciseId: activationBooking.exerciseId,
      studio: activationBooking.studio,
      trainers: activationBooking.trainers.map(trainer => trainer.name),
    }
  }
  return null
}

export function mapClientSubscriptionBookingsToClientSubscriptionsTableDataItemBookings(
  bookings: ClientsSubscriptionsApi.Booking[]
): ClientsSubscriptionsTableDataItemBooking[] {
  if (isDefAndNotEmpty(bookings)) {
    return bookings.reduce<ClientsSubscriptionsTableDataItemBooking[]>(
      (acc: ClientsSubscriptionsTableDataItemBooking[], booking: ClientsSubscriptionsApi.Booking) => {
        const exerciseDirection = mapDictionaryItemToEntityItem(booking.exerciseDirection)
        const studio = mapDictionaryItemToEntityItem(booking.studio)

        if (isDef(exerciseDirection) && isDef(studio)) {
          const clientsSubscriptionsTableDataItemBooking: ClientsSubscriptionsTableDataItemBooking = {
            id: booking.id,
            createDate: booking.createDate,
            exerciseDate: booking.exerciseDate,
            visitConfirmed: booking.visitConfirmed,
            canceled: booking.isCancelled,
            exerciseId: booking.exerciseId,
            exerciseDirection,
            studio,
          }

          acc.push(clientsSubscriptionsTableDataItemBooking)
        }

        return acc
      },
      []
    )
  }
  return []
}

export function genClientSubscriptionHoldDTO(
  clientsSubscriptionsPauseFormValues: ClientsSubscriptionsPauseFormValues
): ClientsSubscriptionsApi.ClientSubscriptionHoldDTO {
  const { freezingDays } = clientsSubscriptionsPauseFormValues

  return {
    freezingDays,
  }
}

export function genClientSubscriptionExpirationDTO(
  date: string
): ClientsSubscriptionsApi.ClientSubscriptionExpirationDTO {
  return {
    expirationDate: date,
  }
}

import { mapExercisesTypesToExercisesForm } from '@mapping/exercises-types.mapping'
import { createSelector } from '@reduxjs/toolkit'
import { mapTrainersToExercisesForm } from '@mapping/trainers.mapping'

import { mapDirectionsToOptions } from '../../../../mapping/directions.mapping'
import { AppState } from '../../../app.store'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../../mapping/studios.mapping'
import { genScheduleGroupPageExercise } from '../schedule-group-page-list/schedule-group-page-list.selectors'

const getTrainersInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.trainers
const getStudiosInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.studios
const getDirectionsInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.directions
const getExercisesTypesInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageEdit.exercisesTypes

const getFilteredStudios = createSelector(getStudiosInternal, genScheduleGroupPageExercise, (studios, exercise) =>
  studios?.content?.filter(
    studio => studio.organization.id === exercise?.organizationId || studio.id === exercise?.studio.id
  )
)

export const getScheduleGroupPageEditIsLoading = (state: AppState) =>
  state.scheduleGroupPage.scheduleGroupPageEdit.isLoading

export const getScheduleGroupPageEditIsLoaded = (state: AppState) =>
  state.scheduleGroupPage.scheduleGroupPageEdit.isLoaded

export const getScheduleGroupPageEditTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)

export const getScheduleGroupPageEditStudiosOptions = createSelector(getFilteredStudios, mapStudiosToOptions)

export const getScheduleGroupPageEditStudiosRoomsOptions = createSelector(getFilteredStudios, mapStudiosToRoomsOptions)

export const getScheduleGroupPageEditDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

export const getScheduleGroupPageEditTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes => {
  return mapExercisesTypesToExercisesForm(exercisesTypes?.content)
})

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { clientsEditPagePeekOverviewHistoryActions } from './clients-edit-page-peek-overview-history.slice'

function* fetchAudit(action: ReturnType<typeof clientsEditPagePeekOverviewHistoryActions.fetchAudit>) {
  try {
    const { page, size, id } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      'client',
      id,
      { ...params, sort: 'time,desc' }
    )
    yield put(clientsEditPagePeekOverviewHistoryActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(clientsEditPagePeekOverviewHistoryActions.fetchAuditError(new Error()))
  }
}

export function* clientsEditPagePeekOverviewHistorySagas() {
  yield takeLatest(clientsEditPagePeekOverviewHistoryActions.fetchAudit.type, fetchAudit)
}

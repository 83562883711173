import * as React from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { PositionsFormValues } from '../../../components/positions/positions-form/positions-form.types'
import { getPositionsCreatePageIsLoading } from '../../../store/pages/positions-create-page/positions-create-page.selectors'
import { positionsCreatePageActions } from '../../../store/pages/positions-create-page/positions-create-page.slice'

export function usePositionsCreatePageForm() {
  const [form] = Form.useForm<PositionsFormValues>()

  const dispatch = useDispatch()

  const isLoading = useSelector(getPositionsCreatePageIsLoading)

  const onFinishHandler = React.useCallback(
    (values: PositionsFormValues): void => {
      dispatch(positionsCreatePageActions.createPosition(values))
    },
    [dispatch]
  )

  return {
    form,
    isLoading,
    onFinishHandler,
  }
}

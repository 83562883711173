import * as React from 'react'
import { Avatar, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { ExercisesGroupCommentsModalProps } from './exercises-group-comment.types'
import './exercises-group-comment.styles.less'
import { formatDate, formatTime } from '../../../format/date.format'

export const ExercisesGroupComment: React.FC<ExercisesGroupCommentsModalProps> = props => {
  const { createDate, comment, employeeName, photo } = props
  const date = formatDate(createDate) + formatTime(createDate)

  return (
    <div className="exercises-group-comment">
      <Avatar src={photo} size="large" icon={<UserOutlined />} />
      <div className="exercises-group-comment-container">
        <Typography.Text>{employeeName}</Typography.Text>
        <Typography.Text type="secondary">{date}</Typography.Text>
        <Typography.Text>{comment}</Typography.Text>
      </div>
    </div>
  )
}

import { NNumber, NString } from '../../types/lang.types'
import { DEFAULT_PAGE_SIZE } from '../../utils/pagination.utils'

export interface TransactionsPageUrlParams {
  studioId: string
}

export interface TransactionsPageQueryParams {
  page?: NNumber
  size?: NNumber
  paymentMethod?: NString
  status?: NString
  clientId?: NString
  clientPhone?: NString
  sellerId?: NString
  seller?: NString
  productId?: NString
  product?: NString
  dateFrom?: NString
  dateTo?: NString
}

export type TransactionsPageParams = TransactionsPageUrlParams & TransactionsPageQueryParams

export const TRANSACTIONS_PAGE_SIZE: number = DEFAULT_PAGE_SIZE

import { takeEvery, put } from 'redux-saga/effects'
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router'
import { employeesScheduleActions } from '@store/pages/employees/employees-schedule/employees-schedule.slice'

function* fetchByRoutePath(action: LocationChangeAction) {
  const { pathname } = action.payload.location

  if (pathname === '/employees/schedule') {
    yield put(employeesScheduleActions.fetchAll())
  }
}

export default function* routerSagas() {
  yield takeEvery(LOCATION_CHANGE, fetchByRoutePath)
}

import { Form } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getTheme } from '../../../../store/common/layout/layout.selectors'
import { isDef, Nullable } from '../../../../types/lang.types'
import { MasterServicesPricingFormValues } from '../master-services-form.types'
import {
  genMasterServicesFormPricingValidationRules,
  genMasterServicesPricingFormInitialValues,
} from './master-services-form-pricing-modal.utils'

interface Props {
  open: boolean
  pricing: Nullable<MasterServicesPricingFormValues>
  onCancel: () => void
  onOk: (values: MasterServicesPricingFormValues) => void
}

export function useMasterServicesFormPricingModal(props: Props) {
  const { open, pricing, onCancel, onOk } = props

  const [form] = Form.useForm<MasterServicesPricingFormValues>()

  const theme = useSelector(getTheme)

  const nameValidationRules = useMemo(genMasterServicesFormPricingValidationRules, [])

  const onSubmitForm = () => {
    const values = form.getFieldsValue(true)
    form.resetFields()

    onOk(values)
  }

  const onClose = useCallback(() => {
    form.resetFields()
    onCancel()
  }, [form, onCancel])

  useEffect(() => {
    if (open) {
      const values = isDef(pricing) ? pricing : genMasterServicesPricingFormInitialValues()
      form.setFieldsValue(values)
    }
  }, [form, open, pricing])

  return {
    theme,
    form,
    nameValidationRules,
    onSubmitForm,
    onClose,
  }
}

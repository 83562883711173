import { ElementStates } from '../master-services-widget-form-design-settings.types'

export function genSwitcherFontStyles(state: ElementStates = ElementStates.DEFAULT) {
  return {
    desktop: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#333333',
      fontSize: 15,
      fontWeight: state === ElementStates.ACTIVE ? 500 : 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: state === ElementStates.ACTIVE ? '#FFF' : '#333333',
      fontSize: 14,
      fontWeight: state === ElementStates.ACTIVE ? 500 : 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

import * as React from 'react'
import { Flex, InputNumber } from 'antd'

import { TransactionsProductsTableDiscountEvents } from './transactions-products-table-discount.types'
import { formatRublesToPennies } from '../../../../format/number.format'

import './transactions-products-table-discount.styles.less'

interface Props extends TransactionsProductsTableDiscountEvents {
  productUuid: string
  value?: number
}

export const TransactionsProductsTableDiscount = (props: Props) => {
  const { productUuid, value, onChangeDiscount } = props

  const onChangeHandler = React.useCallback(
    (value: number | null): void => {
      onChangeDiscount(productUuid, formatRublesToPennies(value ?? 0))
    },
    [onChangeDiscount, productUuid]
  )

  return (
    <InputNumber
      style={{ width: '100px' }}
      className="transactions-products-table__discount"
      addonAfter="₽"
      value={value}
      onChange={onChangeHandler}
      placeholder="Скидка"
    />
  )
}

import * as React from 'react'
import { Button, Space, Tooltip } from 'antd'
import { BarcodeOutlined, DeleteOutlined, HistoryOutlined, LinkOutlined } from '@ant-design/icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import OneClickButton from '@components/ui/one-click-button/one-click-button.component'

import { TransactionStatus } from '../../../../types/transactions.types'
import { ClientsTransactionsTableActionsEvents } from './clients-transactions-table-actions.types'
import { ClientsTransactionsTableDataItem } from '../clients-transactions-table.types'
import { checkIsOnline } from '../../../../utils/status.utils'

interface Props extends ClientsTransactionsTableActionsEvents {
  transaction: ClientsTransactionsTableDataItem
  onBarcode: (transactionId: string) => void
}

export const ClientsTransactionsTableActions: React.FC<Props> = props => {
  const { transaction } = props
  const { onBarcode, onHistory, onRefundSumHandler } = props

  const onBarcodeHandler = React.useCallback((): void => {
    onBarcode(transaction.id)
  }, [onBarcode, transaction.id])

  const onHistoryHandler = React.useCallback(() => onHistory(transaction.id), [transaction.id, onHistory])

  const onRefundSum = () => onRefundSumHandler(transaction)

  const isPaid = transaction.status === TransactionStatus.PAID
  const isRefund = transaction.status === TransactionStatus.REFUND

  const url = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl

  const isUnpaidorWaiting =
    transaction.status === TransactionStatus.UNPAID || transaction.status === TransactionStatus.WAITING

  const paymentLink = transaction.cardPaymentInfo && transaction.cardPaymentInfo.paymentUrl

  const isOnline = checkIsOnline(transaction.paymentMethod)

  return (
    <Space size="middle">
      <Tooltip title="Посмотреть историю изменений">
        <Button icon={<HistoryOutlined />} size="middle" onClick={onHistoryHandler} />
      </Tooltip>
      {(isPaid || isRefund) && (
        <Tooltip title="Показать чеки">
          <Button icon={<BarcodeOutlined />} size="middle" onClick={onBarcodeHandler} />
        </Tooltip>
      )}
      {url && isUnpaidorWaiting && isOnline && (
        <Tooltip title="Скопировать ссылку на оплату">
          <CopyToClipboard text={paymentLink || ''}>
            <Button icon={<LinkOutlined />} size="middle" />
          </CopyToClipboard>
        </Tooltip>
      )}
      {isPaid && (
        <Tooltip title="Возврат">
          <OneClickButton icon={<DeleteOutlined />} size="middle" onClick={onRefundSum} danger ghost />
        </Tooltip>
      )}
    </Space>
  )
}

import { DefaultOptionType } from 'antd/lib/select'

export function sortOptions(options: DefaultOptionType[]): DefaultOptionType[] {
  return options.sort((optionA, optionB) =>
    typeof optionA['label'] === 'string' && typeof optionB['label'] === 'string' && optionA.label < optionB.label
      ? -1
      : 1
  )
}

export function sortOptionsByLocale(options: DefaultOptionType[]): DefaultOptionType[] {
  return options.sort((optionA, optionB) => {
    const labelA = typeof optionA.label === 'string' ? optionA.label : ''
    const labelB = typeof optionB.label === 'string' ? optionB.label : ''

    return labelA.localeCompare(labelB, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  })
}

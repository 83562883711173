import { TeamOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import { clsx } from 'clsx'

import { isDefAndMoreThenZero, NNumber } from '../../../types/lang.types'
import './exercises-group-actions.styles.less'

interface Props {
  className?: string
  clientsInWaitList: NNumber
  onBooking: () => void
  onWaitingList: () => void
}

export const ExercisesGroupActions = (props: Props) => {
  const { className } = props
  const { clientsInWaitList } = props
  const { onBooking, onWaitingList } = props

  const colorBtnAndText = clientsInWaitList ? '#1677ff' : 'grey'
  return (
    <div className={clsx('exercises-group-actions', className)}>
      <Button
        type="text"
        icon={<TeamOutlined style={{ color: colorBtnAndText }} />}
        onClick={onWaitingList}
        disabled={!isDefAndMoreThenZero(clientsInWaitList || 0)}
      >
        <Typography.Text style={{ color: colorBtnAndText }}>{`${
          clientsInWaitList || 0
        } в листе ожидания`}</Typography.Text>
      </Button>
      <Button type="primary" onClick={onBooking}>
        Добавить в группу
      </Button>
    </div>
  )
}

import * as React from 'react'
import { Form } from 'antd'
import { SearchApi } from '@api/types/search-api.types'
import { mapPositionsToPositionsAutocompleteOptionDataItems } from '@mapping/positions.mapping'

import { api } from '../../api/api'
import { isDef, Nullable } from '../../types/lang.types'
import { ClientsAutocompleteOptionDataItem } from '../../components/clients/clients-autocomplete/clients-autocomplete-option/clients-autocomplete-option.types'
import { PositionsAutocompleteProps } from './positions-autocomplete.types'
import { useTransactionsCreatePageTable } from '../../pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.hook'

export function usePositionsAutocomplete<FormValues>(props: PositionsAutocompleteProps<FormValues>) {
  const { form, name, transactionsForm, onChange, setPositionId } = props

  const { onChangeTrialHandler } = useTransactionsCreatePageTable()

  const value = Form.useWatch(name, form)

  const [positions, setPositions] = React.useState<Nullable<SearchApi.SearchItem[]>>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect((): void => {
    if (positions?.length === 0 && transactionsForm) {
      onChangeTrialHandler(null)
    }
  }, [positions])

  const options = React.useMemo(() => mapPositionsToPositionsAutocompleteOptionDataItems(positions), [positions])

  const onChangeHandler = React.useCallback(
    (value?: string): void => {
      if (onChange) onChange(value)
      form.setFieldValue(name, value)
    },
    [form, name, onChange]
  )

  const onSelectHandler = React.useCallback(
    (option: Nullable<ClientsAutocompleteOptionDataItem>): void => {
      if (isDef(option)) {
        form.setFieldValue(name, option.label)

        form.setFieldValue('employeePositionId', option.slug)

        if (setPositionId) {
          setPositionId(option.slug)
        }

        if (transactionsForm) {
          onChangeTrialHandler(option.slug)
        }
      }
    },
    [form, name]
  )

  const onSearchHandler = React.useCallback((value?: string): void => {
    setLoading(true)

    api.positionsService
      .search(value ?? '')
      .then((response): void => {
        setPositions(response?.data.content ?? [])
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  return {
    value,
    options,
    loading,
    onChangeHandler,
    onSearchHandler,
    onSelectHandler,
  }
}

import { NString, Nullable } from '../../types/lang.types'
import { DictionaryItem, PaginationParamsDTO } from './api.types'

export namespace ProductsApi {
  export enum ProductType {
    SUBSCRIPTION = 'SUBSCRIPTION',
    SERVICE = 'SERVICE',
    GOODS = 'GOODS',
    FULL_PAYMENT_SERVICE = 'FULL_PAYMENT_SERVICE',
    ADVANCE_SUB_SERVICE = 'ADVANCE_SUB_SERVICE',
    INSTANT_SUB_SERVICE = 'INSTANT_SUB_SERVICE',
  }

  export interface Product {
    id: string
    name: string
    cost: number
    productType: ProductType
    photo?: NString
    hasStudioLimitation?: boolean | undefined | string
    availableStudios?: Nullable<DictionaryItem[]>
    trialCost?: number
    discountPrice: number
    hasMarkCode?: boolean
    shouldBindToBooking?: boolean
    canBindToBooking?: boolean
    canPayForBooking?: boolean
    exerciseId?: string
    onlyUnpaidRecord?: boolean
    bookingIds?: string[]
  }

  export interface ProductFetchAllParams extends PaginationParamsDTO {
    name?: string
    studioId?: string
  }
}

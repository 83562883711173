import { api } from '@api/api'
import { callApi } from '@utils/sagas.utils'
import { put, takeLatest } from 'redux-saga/effects'

import { terminalEntityActions } from './terminal.slice'

export function* fetchAllTerminals(_: ReturnType<typeof terminalEntityActions.fetchAllTerminals>) {
  try {
    const terminals: Awaited<ReturnType<typeof api.terminalsService.fetchAll>> = yield callApi(
      api.terminalsService.fetchAll
    )

    yield put(terminalEntityActions.fetchAllTerminalsSuccess({ terminals: terminals.data }))
  } catch (e) {
    yield put(terminalEntityActions.fetchAllTerminalsError(new Error()))
  }
}
export function* createTerminal(action: ReturnType<typeof terminalEntityActions.createTerminal>) {
  try {
    const data = action.payload
    yield callApi(api.terminalsService.create, { ...data })
    yield put(terminalEntityActions.createTerminalSuccess())
    yield put(terminalEntityActions.fetchAllTerminals())
  } catch (e) {
    console.error(e)
    yield put(terminalEntityActions.createTerminalError(new Error()))
  }
}
export function* updateTerminal(action: ReturnType<typeof terminalEntityActions.updateTerminal>) {
  try {
    const { id, data } = action.payload
    yield callApi(api.terminalsService.update, id, data)
    yield put(terminalEntityActions.updateTerminalSuccess())
    yield put(terminalEntityActions.fetchAllTerminals())
  } catch (e) {
    console.error(e)
    yield put(terminalEntityActions.updateTerminalError(new Error()))
  }
}
export function* deleteTerminal(action: ReturnType<typeof terminalEntityActions.deleteTerminal>) {
  try {
    const { id } = action.payload
    yield callApi(api.terminalsService.delete, id)
    yield put(terminalEntityActions.deleteTerminalSuccess())
    yield put(terminalEntityActions.fetchAllTerminals())
  } catch (e) {
    console.error(e)
    yield put(terminalEntityActions.deleteTerminalError(new Error()))
  }
}
export function* terminalEntitySagas() {
  yield takeLatest(terminalEntityActions.fetchAllTerminals, fetchAllTerminals)
  yield takeLatest(terminalEntityActions.createTerminal, createTerminal)
  yield takeLatest(terminalEntityActions.updateTerminal, updateTerminal)
  yield takeLatest(terminalEntityActions.deleteTerminal, deleteTerminal)
}

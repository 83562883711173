import { Flex, Form, Space } from 'antd'

import { MasterServicesWidgetFormProps } from './master-services-widget-form.types'
import { MasterServicesWidgetFormGeneralSettings } from './master-services-widget-form-general-settings/master-services-widget-form-general-settings.component'
import { MasterServicesWidgetFormPreview } from './master-services-widget-form-preview/master-services-widget-form-preview.component'
import { MasterServicesWidgetFormDesignSettings } from './master-services-widget-form-design-settings/master-services-widget-form-design-settings.component'
import { useMasterServicesWidgetForm } from './master-services-widget-form.hook'

export const MasterServicesWidgetForm: React.FC<MasterServicesWidgetFormProps> = props => {
  const { form, masterServicesOptions, tenantKey } = props

  const { initialValues, onFieldsChange } = useMasterServicesWidgetForm({ masterServicesOptions, form })

  return (
    <Flex gap="large">
      <Form
        form={form}
        name="masterServicesWidgetCreate"
        autoComplete="off"
        layout="vertical"
        onFieldsChange={onFieldsChange}
        initialValues={initialValues}
      >
        <Space size="large" direction="vertical" style={{ display: 'flex' }}>
          <MasterServicesWidgetFormGeneralSettings masterServicesOptions={masterServicesOptions} form={form} />
          <MasterServicesWidgetFormDesignSettings form={form} />
        </Space>
      </Form>
      <MasterServicesWidgetFormPreview
        defaultMasterServiceId={String(masterServicesOptions[0].value)}
        form={form}
        tenantKey={tenantKey}
      />
    </Flex>
  )
}

import { FormInstance } from 'antd'

import { ExercisesGroupBookFromWaitingFormValues } from '../exercises-group-book-from-waiting-form/exercises-group-book-from-waiting-form.types'
import { isDef } from '../../../types/lang.types'

export function genExercisesGroupBookFromWaitingFormIsValid(
  form: FormInstance<ExercisesGroupBookFromWaitingFormValues>
) {
  const placeIsValid = isDef(form.getFieldValue('place'))
  const paymentTypeIsValid = isDef(form.getFieldValue('paymentType'))

  return placeIsValid && paymentTypeIsValid
}

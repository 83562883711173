import { all, spawn } from 'redux-saga/effects'

import { scheduleGroupPageListSagas } from './schedule-group-page-list/schedule-group-page-list.sagas'
import { scheduleGroupPageModalBookingSagas } from './schedule-group-page-modal-booking/schedule-group-page-modal-booking.sagas'
import { scheduleGroupPageModalWaiting } from './schedule-group-page-modal-waiting/schedule-group-page-modal-waiting.sagas'
import { scheduleGroupPageModalCommentsSagas } from './schedule-group-page-modal-comments/schedule-group-page-modal-comments.sagas'
import { scheduleGroupPageEditSagas } from './schedule-group-page-edit/schedule-group-page-edit.sagas'
import { scheduleGroupPageModalBookFromWaitingSagas } from './schedule-group-page-modal-book-from-waiting/schedule-group-page-modal-book-from-waiting.sagas'
import { scheduleGroupPageHistorySagas } from './schedule-group-page-history/schedule-group-page-history.sagas'

export function* scheduleGroupPageSagas() {
  yield all(
    [
      scheduleGroupPageListSagas,
      scheduleGroupPageModalBookingSagas,
      scheduleGroupPageModalBookFromWaitingSagas,
      scheduleGroupPageModalWaiting,
      scheduleGroupPageModalCommentsSagas,
      scheduleGroupPageEditSagas,
      scheduleGroupPageHistorySagas,
    ].map(saga => spawn(saga))
  )
}

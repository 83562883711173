import * as React from 'react'

import { ExercisesTypesPageTopBar } from './exercises-types-page-top-bar/exercises-types-page-top-bar.component'
import { useExercisesTypesPage } from './exercises-types-page.hook'
import { ExercisesTypesPageTable } from './exercises-types-page-table/exercises-types-page-table.component'

export const ExercisesTypesPage = () => {
  useExercisesTypesPage()

  return (
    <>
      <ExercisesTypesPageTopBar />
      <ExercisesTypesPageTable />
    </>
  )
}

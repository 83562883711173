export enum AppPeek {
  // region ---- schedule management page
  SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY = 'SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- master-service page
  MASTER_SERVICES_PAGE_PEEK_HISTORY = 'MASTER_SERVICES_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- clients edit page
  CLIENTS_EDIT_PAGE_PEEK_OVERVIEW_HISTORY = 'CLIENTS_EDIT_PAGE_PEEK_OVERVIEW_HISTORY',
  CLIENTS_EDIT_PAGE_PEEK_EXERCISE_HISTORY = 'CLIENTS_EDIT_PAGE_PEEK_EXERCISE_HISTORY',
  CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY = 'CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY',
  // endregion

  // region ---- transactions page
  TRANSACTIONS_PAGE_PEEK_HISTORY = 'TRANSACTIONS_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- employees page
  EMPLOYEES_PAGE_PEEK_HISTORY = 'EMPLOYEES_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- studios page
  STUDIOS_PAGE_PEEK_HISTORY = 'STUDIOS_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- products page
  PRODUCTS_PAGE_PEEK_HISTORY = 'PRODUCTS_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- goods page
  GOODS_PAGE_PEEK_HISTORY = 'GOODS_PAGE_PEEK_HISTORY',
  // endregion

  // region ---- employees schedule page
  EMPLOYEES_SCHEDULE_PEEK_HISTORY = 'EMPLOYEES_SCHEDULE_PEEK_HISTORY',
  // endregion
}

export type AppPeekQueueItem<Props = {}> = {
  peek: AppPeek
  props?: Props
}

import * as React from 'react'

import { DirectionsForm } from '../../../components/directions/directions-form/directions-form.component'
import { useDirectionsEditPageForm } from './directions-edit-page-form.hook'

export const DirectionsEditPageForm = () => {
  const { form, isLoading, exercisesTypesOptions, onFinishHandler } = useDirectionsEditPageForm()

  return (
    <DirectionsForm
      form={form}
      isLoading={isLoading}
      exercisesTypesOptions={exercisesTypesOptions}
      submitText="Редактирование направления"
      onFinish={onFinishHandler}
    />
  )
}

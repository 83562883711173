import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { genExercisesComments } from '../../../../mapping/exercises.mapping'
import { Pagination } from '../../../../api/types/api.types'

const genCommentsInternal = (state: AppState): Nullable<Pagination<ExercisesApi.ExerciseBookingComment>> =>
  state.scheduleGroupPage.scheduleGroupPageModalComments.comments

export const genScheduleGroupPageModalCommentsIsLoading = (state: AppState): boolean =>
  state.scheduleGroupPage.scheduleGroupPageModalComments.isLoading

export const genScheduleGroupPageModalBookingComments = createSelector(genCommentsInternal, exerciseComments =>
  genExercisesComments(exerciseComments?.content)
)

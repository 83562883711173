import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { ExercisesGroupHistoryProps } from './exercises-group-history.types'

export const ExercisesGroupHistory: FC<ExercisesGroupHistoryProps> = props => {
  const { isLoading, exerciseHistory, ...rest } = props

  if (isLoading) {
    return <PageLoader />
  }
  if (!isDefAndNotEmpty(exerciseHistory)) {
    return <PageEmpty description={'История не найдена'} />
  }
  return <HistoryList history={exerciseHistory} type={HistoryListType.EXERCISE} {...rest} />
}

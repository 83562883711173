import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import { ScheduleGroupPageModalBookFromWaitingCreateBookingPayload } from './schedule-group-page-modal-book-from-waiting.types'

export interface ScheduleGroupPageModalBookFromWaitingState {
  exercisesSpots: Nullable<ExercisesApi.ExerciseSpot[]>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ScheduleGroupPageModalBookFromWaitingState = {
  exercisesSpots: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const {
  actions: scheduleGroupPageModalBookFromWaitingActions,
  reducer: scheduleGroupPageModalBookFromWaitingReducer,
} = createSlice({
  name: '@@schedule-group-page-modal-book-from-waiting',
  initialState,
  reducers: {
    fetchExercisesSpots: (state: Draft<ScheduleGroupPageModalBookFromWaitingState>, _: PayloadAction<string>): void => {
      state.isLoading = true
    },
    fetchExercisesSpotsSuccess: (
      state: Draft<ScheduleGroupPageModalBookFromWaitingState>,
      action: PayloadAction<Nullable<ExercisesApi.ExerciseSpot[]>>
    ): void => {
      state.isLoading = false
      state.isLoaded = true
      state.exercisesSpots = action.payload
    },
    fetchExercisesSpotsError: (
      state: Draft<ScheduleGroupPageModalBookFromWaitingState>,
      action: PayloadAction<Error>
    ): void => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },

    createBooking: (
      state: Draft<ScheduleGroupPageModalBookFromWaitingState>,
      _: PayloadAction<ScheduleGroupPageModalBookFromWaitingCreateBookingPayload>
    ): void => {
      state.isLoading = true
    },
    createBookingSuccess: (state: Draft<ScheduleGroupPageModalBookFromWaitingState>): void => {
      state.isLoading = false
    },
    createBookingError: (
      state: Draft<ScheduleGroupPageModalBookFromWaitingState>,
      action: PayloadAction<Error>
    ): void => {
      state.isLoading = false
      state.error = action.payload
    },

    reset: () => initialState,
  },
})

import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { Pagination, PaginationParamsDTO } from '../types/api.types'
import { MasterServicesApi } from '../types/master-services-api.types'
import { MasterServicesSubServicesApi } from '../types/master-services-subservices-api.types'

export class MasterServicesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<MasterServicesApi.MasterService>>> => {
    return this.httpConnector.get<Pagination<MasterServicesApi.MasterService>>('/products/master-services', {
      params,
    })
  }

  public fetchById = (id: string): Promise<AxiosResponse<MasterServicesApi.MasterService>> => {
    return this.httpConnector.get<MasterServicesApi.MasterService>(`/products/master-services/${id}`)
  }

  public create = (
    data: MasterServicesApi.MasterServiceCreateDTO
  ): Promise<AxiosResponse<MasterServicesApi.MasterService>> => {
    return this.httpConnector.post<MasterServicesApi.MasterService>('/products/master-services', data)
  }

  public update = (
    id: string,
    data: MasterServicesApi.MasterServiceEditDTO
  ): Promise<AxiosResponse<MasterServicesApi.MasterService>> => {
    return this.httpConnector.patch<MasterServicesApi.MasterService>(`/products/master-services/${id}`, data)
  }

  public createSubService = (
    masterServiceId: string,
    data: MasterServicesSubServicesApi.MasterServiceSubServiceDTO
  ): Promise<AxiosResponse<MasterServicesSubServicesApi.MasterServicesSubService>> => {
    return this.httpConnector.post<MasterServicesSubServicesApi.MasterServicesSubService>(
      `/products/master-services/${masterServiceId}/sub-services`,
      data
    )
  }

  public updateSubService = (
    masterServiceId: string,
    subServiceId: string,
    data: MasterServicesSubServicesApi.MasterServiceSubServiceDTO
  ): Promise<AxiosResponse<MasterServicesSubServicesApi.MasterServicesSubService>> => {
    return this.httpConnector.patch<MasterServicesSubServicesApi.MasterServicesSubService>(
      `/products/master-services/${masterServiceId}/sub-services/${subServiceId}`,
      data
    )
  }

  public removeSubService = (masterServiceId: string, subServiceId: string): Promise<void> => {
    return this.httpConnector.delete(`/products/master-services/${masterServiceId}/sub-services/${subServiceId}`)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/products/master-services/${id}`)
  }
}

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { genSchedulePageListIsCanceling } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { schedulePageListActions } from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import { TimetableItemType } from '../../../types/timetable.types'
import { SchedulePageModalConfirmProps } from './schedule-page-modal-confirm.types'

export const SchedulePageModalConfirm: React.FC<SchedulePageModalConfirmProps> = props => {
  const { id, eventType } = props

  const dispatch = useDispatch()

  const isCanceling = useSelector(genSchedulePageListIsCanceling)

  const onSubmitHandler = React.useCallback((): void => {
    if (eventType === TimetableItemType.EXERCISE) {
      dispatch(schedulePageListActions.cancelExercise(id))
    } else if (eventType === TimetableItemType.TECHNICAL_BREAK) {
      dispatch(schedulePageListActions.cancelBreak(id))
    }
  }, [dispatch, eventType, id])

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return (
    <Modal
      title={eventType === TimetableItemType.EXERCISE ? 'Отмена занятия' : 'Отмена технического перерыва'}
      visible
      onCancel={onCancelHandler}
      footer={[
        <Button key="back" onClick={onCancelHandler}>
          Отменить
        </Button>,
        <Button key="submit" type="primary" danger onClick={onSubmitHandler} icon={<DeleteOutlined />}>
          Удалить
        </Button>,
      ]}
    >
      <p>
        {eventType === TimetableItemType.EXERCISE
          ? 'Вы уверены, что хотите отменить занятие?'
          : 'Вы уверены, что хотите отменить технический перерыв?'}
      </p>
    </Modal>
  )
}

import { Divider, Form, Typography } from 'antd'
import Modal from 'antd/es/modal/Modal'
import { useEffect } from 'react'

import { GoodForm } from '../components/good-form'

export const AddGood = (props: any) => {
  const { categories, onFinish, onCancel, isOpen } = props
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue({})
  })

  return (
    <Modal open={isOpen} onCancel={onCancel} footer={null} title="Добавление товара">
      <Divider />
      <GoodForm form={form} categories={categories} onFinish={onFinish} />
    </Modal>
  )
}

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { isDef } from '../../../types/lang.types'
import { useStudioEditPageParams } from '../studios-edit-page-hooks/studios-edit-page.hook'
import {
  getStudioFormValues,
  getDirectionsOptions,
  getFranchisesOptions,
  genStudioIsLoading,
  getStudioInternal,
} from '../../../store/pages/studios-edit-page/studios-edit-page.selectors'
import { studiosEditPageActions } from '../../../store/pages/studios-edit-page/studios-edit-page.slice'
import { StudiosRoomFormTypes } from '../../../components/studios/studios-form/studios-form-types'
import { customFieldsSettingsActions } from '../../../store/common/custom-fields-settings/custom-fields-settings.slice'
import { genColorsOptions } from '../../../utils/colors.utils'

export function useStudiosEditPageForm() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { id } = useStudioEditPageParams()

  const isLoading = useSelector(genStudioIsLoading)

  const studiosFormValues = useSelector(getStudioFormValues)
  const directionsOptions = useSelector(getDirectionsOptions)
  const franchisesOptions = useSelector(getFranchisesOptions)
  const colorsOptions = React.useMemo(genColorsOptions, [])

  const studioData = useSelector(getStudioInternal)
  const customFields = studioData?.customFields

  const onFinishHandler = React.useCallback((): void => {
    if (isDef(id)) {
      dispatch(studiosEditPageActions.updateStudio({ id, data: form.getFieldsValue(true) }))
    }
  }, [dispatch, id, form])

  const onSaveStudioRoomHandler = React.useCallback(
    (key: number): void => {
      const formValues = form.getFieldsValue()
      const data = formValues.rooms ? formValues.rooms[key] : null
      const studioRoomId = data ? data.id : null

      if (isDef(id) && isDef(studioRoomId) && isDef(data)) {
        dispatch(studiosEditPageActions.updateStudioRoom({ studioId: id, id: studioRoomId, data }))
      } else if (isDef(id) && isDef(data)) {
        dispatch(studiosEditPageActions.createStudioRoom({ studioId: id, data }))
      }
    },
    [dispatch, form, id]
  )

  const onRemoveStudioRoomHandler = React.useCallback(
    (key: number): void => {
      const formValues = form.getFieldsValue()
      const studioRoomId = formValues.rooms && formValues.rooms[key] ? formValues.rooms[key].id : null

      if (isDef(id) && isDef(studioRoomId)) {
        dispatch(studiosEditPageActions.removeStudioRoom({ studioId: id, id: studioRoomId }))
      }
    },
    [dispatch, form, id]
  )

  React.useEffect(() => {
    if (isDef(studiosFormValues)) {
      form.setFieldsValue(studiosFormValues)
    }
  }, [form, studiosFormValues])

  return {
    isLoading,
    form,
    directionsOptions,
    franchisesOptions,
    colorsOptions,
    onFinishHandler,
    onSaveStudioRoomHandler,
    onRemoveStudioRoomHandler,
    customFields,
    id,
  }
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../../app.store'
import { mapExercisesTypesToExercisesForm } from '../../../../mapping/exercises-types.mapping'
import { mapTrainersToExercisesForm } from '../../../../mapping/trainers.mapping'

const getExercisesTypesInternal = (state: AppState) => state.schedulePage.schedulePageModalCreate.exercisesTypes
const getTrainersInternal = (state: AppState) => state.schedulePage.schedulePageModalCreate.trainers

export const getSchedulePageModalCreateIsLoading = (state: AppState) =>
  state.schedulePage.schedulePageModalCreate.isLoading

export const getSchedulePageModalCreateExercisesTypes = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToExercisesForm(exercisesTypes?.content)
)

export const getSchedulePageModalCreateTrainers = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)

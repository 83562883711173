import * as React from 'react'
import { useSelector } from 'react-redux'
import { Form } from 'antd'

import {
  genSchedulePageListStudiosRoomsTags,
  getSchedulePageListDirectionOptions,
  getSchedulePageListTrainerOptions,
  genSchedulePageListStudiosRoomsOptions,
  genSchedulePageListStudiosRecordOptions,
  genSchedulePageListStudiosPaymentTypesOptions,
} from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { useSchedulePageParams } from '../schedule-page-hooks/schedule-page-params.hook'

export function useSchedulePageFilter() {
  const [form] = Form.useForm()
  const { studioId, ...params } = useSchedulePageParams()

  const { extended, roomId, trainerId, directionId } = params

  const studiosRoomsTags = useSelector(genSchedulePageListStudiosRoomsTags)

  const trainerOptions = useSelector(getSchedulePageListTrainerOptions)
  const directionOptions = useSelector(getSchedulePageListDirectionOptions)
  const roomOptions = useSelector(genSchedulePageListStudiosRoomsOptions)
  const recordOptions = useSelector(genSchedulePageListStudiosRecordOptions)
  const paymentTypesOptions = useSelector(genSchedulePageListStudiosPaymentTypesOptions)

  React.useEffect(() => {
    form.setFieldsValue({
      trainerId: trainerId,
      directionId: directionId && +directionId,
      roomId: roomId,
    })
  }, [directionId, form, trainerId])

  return {
    form,
    extended: extended || false,
    studiosRoomsTags,
    trainerOptions,
    directionOptions,
    roomId,
    trainerId,
    directionId,
    roomOptions,
    recordOptions,
    paymentTypesOptions,
  }
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genScheduleAuditInternal = (state: AppState) => state.scheduleGroupPage.scheduleGroupPageHistory.scheduleAudit

export const genScheduleGroupPageHistoryIsLoading = (state: AppState) =>
  state.scheduleGroupPage.scheduleGroupPageHistory.isLoading

export const genScheduleGroupPageHistoryTotalElements = createSelector(
  genScheduleAuditInternal,
  audit => audit?.totalElements
)

export const genScheduleGroupPageHistoryScheduleHistory = createSelector(
  genScheduleAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

import { FormInstance } from 'antd'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { MasterServicesPricingFormValues } from '../../master-services-form.types'
import { genMasterServicesFormCostValidationRules } from './master-services-form-pricing-base-price.utils'

interface Props {
  form: FormInstance<MasterServicesPricingFormValues>
}

export function useMasterServicesFormPricingBasePrice(props: Props) {
  const { form } = props
  const costValidationRules = useMemo(genMasterServicesFormCostValidationRules, [])

  const [isHolidayAsWeekend, setIsHolidayAsWeekend] = useState(false)

  const toggleHolidayAsWeekend = () => {
    form.setFieldValue('holidayAsWeekend', !isHolidayAsWeekend)
    setIsHolidayAsWeekend(!isHolidayAsWeekend)
  }

  const handlePricingTypeChange = (value: string) => {
    const values = form.getFieldsValue()
    const filteredPriceImpacts = values.priceImpacts?.filter(impact => impact.type !== value)

    form.setFieldsValue({
      ...values,
      basePrice: undefined,
      priceImpacts: filteredPriceImpacts,
    })
  }

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>, index: number, fieldName: string) => {
    const newValue = e.target.value
    form.setFieldValue(['basePrice', index, fieldName], newValue)
  }

  useEffect(() => {
    const holidayAsWeekend = form.getFieldValue('holidayAsWeekend')
    form.setFieldValue('holidayAsWeekend', holidayAsWeekend)
  }, [form])

  return {
    costValidationRules,
    isHolidayAsWeekend,
    toggleHolidayAsWeekend,
    handlePricingTypeChange,
    handleTimeChange,
  }
}

import { createSelector } from '@reduxjs/toolkit'
import { mapExercisesTypesToExercisesForm } from '@mapping/exercises-types.mapping'

import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'
import { genProductSubscriptionFormValues } from '../../../mapping/products-subscriptions.mapping'
import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { AppState } from '../../app.store'

const getDirectionsInternal = (state: AppState) => state.productsSubscriptionsEditPage.directions
const getStudiosInternal = (state: AppState) => state.productsSubscriptionsEditPage.studios
const getExercisesInternal = (state: AppState) => state.productsSubscriptionsEditPage.exercises
const getSubscriptionInternal = (state: AppState) => state.productsSubscriptionsEditPage.subscription

export const genProductsSubscriptionsEditPageIsLoading = (state: AppState): boolean =>
  state.productsSubscriptionsEditPage.isLoading

export const getDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)
export const getStudiosOptions = createSelector(getStudiosInternal, studios => mapStudiosToOptions(studios?.content))
export const getExercisesOptions = createSelector(getExercisesInternal, exercise =>
  mapExercisesTypesToExercisesForm(exercise?.content)
)

export const getProductsServicesFormValues = createSelector(getSubscriptionInternal, genProductSubscriptionFormValues)

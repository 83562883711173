import qs from 'qs'

import { isDef, isString, NString } from '../../types/lang.types'
import {
  TRANSACTIONS_PAGE_SIZE,
  TransactionsPageParams,
  TransactionsPageQueryParams,
  TransactionsPageUrlParams,
} from './transactions-page.types'

export function genTransactionsPageParams(params: TransactionsPageUrlParams, search: NString): TransactionsPageParams {
  return {
    ...params,
    ...genTransactionsPageQueryParams(search),
  }
}

function genTransactionsPageQueryParams(search: NString): TransactionsPageQueryParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const page = isString(queryParams?.page) ? Number(queryParams?.page) : null
  const size = isString(queryParams?.size) ? Number(queryParams?.size) : TRANSACTIONS_PAGE_SIZE

  const paymentMethod = isString(queryParams?.paymentMethod) ? String(queryParams?.paymentMethod) : null
  const status = isString(queryParams?.status) ? String(queryParams?.status) : null
  const clientId = isString(queryParams?.clientId) ? String(queryParams?.clientId) : null
  const clientPhone = isString(queryParams?.clientPhone) ? String(queryParams?.clientPhone) : null
  const seller = isString(queryParams?.seller) ? String(queryParams?.seller) : null
  const sellerId = isString(queryParams?.sellerId) ? String(queryParams?.sellerId) : null
  const product = isString(queryParams?.product) ? String(queryParams?.product) : null
  const productId = isString(queryParams?.productId) ? String(queryParams?.productId) : null
  const dateFrom = isString(queryParams?.dateFrom) ? String(queryParams?.dateFrom) : null
  const dateTo = isString(queryParams?.dateTo) ? String(queryParams?.dateTo) : null

  return {
    size,
    page,
    paymentMethod,
    status,
    clientId,
    clientPhone,
    seller,
    sellerId,
    product,
    productId,
    dateFrom,
    dateTo,
  }
}

import * as React from 'react'
import { clsx } from 'clsx'
import { useSelector } from 'react-redux'

import { Autocomplete } from '../autocomplete/autocomplete.component'
import { AutocompleteOptionDataItem } from '../autocomplete/autocomplete-option/autocomplete-option.types'
import { SearchAndQrInput } from './search-and-qr-input/search-and-qr-input.component'
import { SearchAndQrProps } from './search-and-qr.types'
import './search-and-qr.styles.less'
import { getTheme } from '../../../store/common/layout/layout.selectors'

export const SearchAndQR = <Option extends AutocompleteOptionDataItem>(props: SearchAndQrProps<Option>) => {
  const { className } = props
  const { value, options } = props
  const { loading = false, placeholder = 'Поиск...' } = props
  const { onChange, onInputChange, onSelect, onClear } = props
  const { OptionComponent } = props
  const theme = useSelector(getTheme)

  return (
    <div className={clsx(`search-and-qr ${theme !== 'light' && 'search-and-qr--dark'}`, className)}>
      <Autocomplete
        value={value}
        options={options}
        loading={loading}
        placeholder={placeholder}
        onChange={onChange}
        onInputChange={onInputChange}
        onSelect={onSelect}
        onClear={onClear}
        InputComponent={SearchAndQrInput}
        OptionComponent={OptionComponent}
        clearInputAfterSelect={true}
      />
    </div>
  )
}

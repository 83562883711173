import { ExclamationCircleOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Flex, Select, Tooltip, Typography } from 'antd'

import { SelectRecordTypeProps } from '../types/select-record-type.types'
interface Option {
  value: number
  label: string
  format: string
  title: string
  filteredOut: boolean
}
export const SelectRecordType = ({
  options,
  loading,
  onChangeSelect,
  disabled,
  mode,
  placeholder,
  value,
  showIcon,
}: SelectRecordTypeProps) => {
  const renderIcon = (format: string, filteredOut: boolean) => {
    const colorIcon = filteredOut ? 'grey' : '#000'
    switch (format) {
      case 'PERSONAL':
        return <UserOutlined style={{ color: colorIcon }} />
      case 'GROUP':
        return <TeamOutlined style={{ fontSize: 16, color: colorIcon }} />
      default:
        return <ExclamationCircleOutlined style={{ color: colorIcon }} />
    }
  }
  const sortedOptions = options?.sort((a: Option, b: Option) => {
    if (a.filteredOut && !b.filteredOut) {
      return 1
    } else if (!a.filteredOut && b.filteredOut) {
      return -1
    } else {
      return 0
    }
  })

  const transformedOptions = sortedOptions?.map((option: Option) => ({
    value: option.value,
    label: (
      <>
        {option.filteredOut ? (
          <Tooltip title="Категория недоступна для этой студии или зала">
            <Flex align="center" gap={6}>
              {showIcon && renderIcon(option.format, option.filteredOut)}
              <Typography style={{ color: 'grey' }}>{option.label}</Typography>
            </Flex>
          </Tooltip>
        ) : (
          <Flex align="center" gap={6}>
            {showIcon && renderIcon(option.format, option.filteredOut)}
            <Typography style={{ color: 'black' }}>{option.label}</Typography>
          </Flex>
        )}
        {/* <Flex align="center" gap={6}>
          {renderIcon(option.format, option.filteredOut)}
          <Typography style={{ color: option.filteredOut ? 'grey' : 'black' }}>{option.label}</Typography>
        </Flex> */}
      </>
    ),
    title: option.label,
    disabled: option.filteredOut,
  }))

  return (
    <Select
      mode={mode}
      placeholder={placeholder}
      options={transformedOptions}
      disabled={disabled || loading}
      loading={loading}
      onChange={onChangeSelect}
      showSearch={true}
      defaultValue={value}
      value={value}
      filterOption={(input: string, option) => option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      dropdownClassName="SelectRecordType"
    />
  )
}

import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, Flex, Form, FormInstance, FormListFieldData } from 'antd'

import { isDefAndNotEmpty } from '../../../../types/lang.types'
import { StudiosFormTypes } from '../studios-form-types'
import { StudiosFormAmenity } from './studios-form-amenity/studios-form-amenity.component'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field?: FormListFieldData
}

export const StudiosFormAmenities: React.FC<Props> = props => {
  const { form, field } = props

  return (
    <Flex gap="large" vertical>
      <Form.List name={[...(field ? [field.name] : []), 'amenities']}>
        {(subFields, { add, remove }) => {
          const onCopyAmenity = (subField: FormListFieldData) => {
            const values = form.getFieldValue([...(field ? [field.name] : []), 'amenities'])?.[subField.name]
            add(values)
          }

          return (
            <>
              {subFields.map(subField => (
                <StudiosFormAmenity
                  key={subField.name}
                  form={form}
                  field={field}
                  subField={subField}
                  onCopy={onCopyAmenity}
                  onRemove={remove}
                />
              ))}
              <Button
                onClick={() => add()}
                icon={<PlusCircleOutlined />}
                style={{ alignSelf: 'baseline', marginTop: '24px' }}
              >
                {isDefAndNotEmpty(subFields) ? 'Ещё сервис' : 'Добавить сервис'}
              </Button>
            </>
          )
        }}
      </Form.List>
    </Flex>
  )
}

import { Typography } from 'antd'
import * as React from 'react'

import { formatDate, formatToUTCHours } from '../../../format/date.format'
import { useStudio } from '../../../hooks/use-studios.hook'
import { DEFAULT_EMPTY_SYMBOL } from '../../../format/text.format'
import { isDef, NString } from '../../../types/lang.types'
import './table-cell-date-transaction.styles.less'

interface Props {
  date: NString
  format: string
}

export const TableCellDateTimeTransaction: React.FC<Props> = props => {
  const { date, format } = props

  const { studioOffset } = useStudio()

  if (isDef(date)) {
    return (
      <div className="table-cell-date-time">
        <Typography.Text type="secondary">
          {formatToUTCHours(date, format, studioOffset)}, {formatDate(date, 'DD MMMM YYYY')}
        </Typography.Text>
      </div>
    )
  }

  return <Typography.Text>{DEFAULT_EMPTY_SYMBOL}</Typography.Text>
}

import { ColorPicker, Form } from 'antd'

interface Props {
  label: string
  name: string[]
}

export const MasterServicesWidgetFormUiColor: React.FC<Props> = props => {
  const { label, name } = props

  return (
    <Form.Item
      label={label}
      name={name}
      getValueFromEvent={color => {
        return '#' + color.toHex()
      }}
    >
      <ColorPicker showText />
    </Form.Item>
  )
}

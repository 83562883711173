import { Button, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { PositionsFormGradesProps } from './positions-form-grades.types'
import { PositionsFormGrade } from './positions-form-grade/positions-form-grade.component'

export const PositionsFormGrades: React.FC<PositionsFormGradesProps> = props => {
  const { name, form, positionGrades } = props
  const { onUpdateGrade, onCreateGrade, onRemoveGrade } = props

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <PositionsFormGrade
              index={index}
              field={field}
              form={form}
              positionGrades={positionGrades}
              onUpdateGrade={onUpdateGrade}
              onCreateGrade={onCreateGrade}
              onRemoveGrade={onRemoveGrade}
              key={index}
              remove={remove}
            />
          ))}
          <Form.Item>
            <Button onClick={() => add()} icon={<PlusOutlined />}>
              Добавить грейд
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

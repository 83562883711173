import { createSelector } from '@reduxjs/toolkit'

import { mapDirectionsToOptions } from '../../../../mapping/directions.mapping'
import { mapExercisesTypesToExercisesForm } from '../../../../mapping/exercises-types.mapping'
import { mapStudiosRoomsToOptions } from '../../../../mapping/studios-rooms.mapping'
import { mapTrainersToExercisesForm } from '../../../../mapping/trainers.mapping'
import { AppState } from '../../../app.store'

const getExercisesTypesInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModal.exercisesTypes

const getStudiosRoomsInternal = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModal.studiosRooms

const getTrainersInternal = (state: AppState) => state.scheduleManagementPage.scheduleManagementPageModal.trainers

export const getScheduleManagementPageModalDirections = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModal.directions

export const getScheduleManagementPageModalIsLoading = (state: AppState) =>
  state.scheduleManagementPage.scheduleManagementPageModal.isLoading

export const getScheduleManagementPageModalDirectionsOptions = createSelector(
  getScheduleManagementPageModalDirections,
  directions => mapDirectionsToOptions(directions?.content)
)

// export const getScheduleManagementPageModalExercisesTypesOptions = createSelector(
//   getExercisesTypesInternal,
//   exercisesTypes => mapExercisesTypesToOptions(exercisesTypes?.content)
// )
export const getScheduleManagementPageModalExercisesTypesOptions = createSelector(
  getExercisesTypesInternal,
  exercisesTypes => mapExercisesTypesToExercisesForm(exercisesTypes?.content)
)

export const getScheduleManagementPageModalStudiosRoomsOptions = createSelector(getStudiosRoomsInternal, studiosRooms =>
  mapStudiosRoomsToOptions(studiosRooms?.content)
)

export const getScheduleManagementPageModalTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToExercisesForm(trainers?.content)
)

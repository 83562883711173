export enum CELL_MODE {
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export const CELL_BROADCAST_CHANNEL = 'HOUR_CELL_BROADCAST_CHANNEL'
export const enum CELL_BROADCAST_CHANNEL_COMMANDS {
  UPDATE = 'UPDATE',
}

export const SCHEDULE_BROADCAST_CHANNEL = 'SCHEDULE_BROADCAST_CHANNEL'
export const enum SCHEDULE_BROADCAST_CHANNEL_COMMANDS {
  NEXT = 'NEXT',
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'

const getDirectionsInternal = (state: AppState) => state.exercisesTypesCreatePage.directions

export const genExercisesTypesCreatePageIsLoading = (state: AppState): boolean =>
  state.exercisesTypesCreatePage.isLoading

export const getExercisesTypesCreatePageDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

import { AxiosResponse } from 'axios'
import qs from 'qs'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import { BreaksApi } from '../types/breaks.types'

export class BreaksService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (params?: Nullable<BreaksApi.BreaksFetchAllParams>): Promise<AxiosResponse<BreaksApi.Break[]>> => {
    const response = this.httpConnector.get<BreaksApi.Break[]>('/breaks', { params })
    return response
  }

  public cancel = (id: string): Promise<AxiosResponse<void>> => {
    return this.httpConnector.delete<void>(`/breaks/${id}`)
  }

  public updateBreaks = (data: BreaksApi.BreakEditDTO[]): Promise<AxiosResponse<void>> => {
    return this.httpConnector.put<void>('/breaks/batch', data)
  }

  public createBreaks = (data: BreaksApi.BreakCreateDTO[]): Promise<AxiosResponse<void>> => {
    return this.httpConnector.post<void>('/breaks/batch', data)
  }

  public removeBreaks = (deleteIds: string[]): Promise<AxiosResponse<void>> => {
    const queryString = qs.stringify({ ids: deleteIds }, { arrayFormat: 'brackets' })

    return this.httpConnector.delete(`/breaks/batch?${queryString}`)
  }
}

import React from 'react'
import { FormInstance } from 'antd'

import { ExercisesGroupBookFromWaitingFormValues } from '../exercises-group-book-from-waiting-form/exercises-group-book-from-waiting-form.types'
import { genExercisesGroupBookFromWaitingFormIsValid } from './exercises-group-book-from-waiting-modal.utils'

export function useExercisesGroupBookFromWaitingModal(form: FormInstance<ExercisesGroupBookFromWaitingFormValues>) {
  const [isValid, setIsValid] = React.useState<boolean>(false)

  const onFieldsChangeHandler = React.useCallback(setIsValid, [setIsValid])

  const onFieldsChange = React.useCallback((): void => {
    const isValid = genExercisesGroupBookFromWaitingFormIsValid(form)
    onFieldsChangeHandler(isValid)
  }, [form, onFieldsChangeHandler])

  return {
    isValid,
    onFieldsChange,
  }
}

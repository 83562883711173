import { Form } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import { api } from '../../../../api/api'
import { isDef } from '../../../../types/lang.types'
import { StudiosFormLocationTypes } from './studios-form-location.types'
import { genStudiosFormLocationMapUrlValidationRules } from './studios-form-location.utils'

interface Props extends StudiosFormLocationTypes {}

export function useStudiosFormLocation(props: Props) {
  const { form, field } = props

  const token = api.getAccessToken()

  const locationMapUrlValidationRules = useMemo(genStudiosFormLocationMapUrlValidationRules, [])

  const locationPhotoUrl = Form.useWatch([...(field ? ['rooms', field.name] : []), 'locationPhotoUrl'], form)

  const [imageUrl, setImageUrl] = useState('')

  const getBase64 = (img: any, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const onChangeLocationPhotoUrl = (info: any) => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as any, url => {
        setImageUrl(url)
      })
    }
  }

  useEffect(() => {
    if (imageUrl === '' || !isDef(imageUrl)) {
      setImageUrl(form.getFieldValue([...(field ? ['rooms', field.name] : []), 'locationPhotoUrl']))
    }
  }, [form, field, imageUrl, locationPhotoUrl])

  return {
    token,
    locationMapUrlValidationRules,
    imageUrl,
    onChangeLocationPhotoUrl,
  }
}

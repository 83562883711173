export function genAboutExecutorFontStyles() {
  return {
    desktop: {
      color: '#333333',
      fontSize: 15,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: '#333333',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

export function genAboutExecutorNameFontStyles() {
  return {
    desktop: {
      color: '#333333',
      fontSize: 25,
      fontWeight: 700,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 35,
      letterSpacing: -0.4,
      wordSpacing: 0,
    },
    mobile: {
      color: '#333333',
      fontSize: 19,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 24,
      letterSpacing: -0.45,
      wordSpacing: 0,
    },
  }
}

export function genAboutExecutorTagFontStyles() {
  return {
    desktop: {
      color: '#333333',
      fontSize: 15,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: '#333333',
      fontSize: 14,
      fontWeight: 400,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

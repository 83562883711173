import { Form, Space, ColorPicker, Typography, Row, Col, Slider, InputNumber, Select, FormInstance } from 'antd'

import { NNumber } from '../../../../../types/lang.types'
import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { DeviceMode } from '../../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'
import {
  MasterServicesWidgetTypographyFormValues,
  MasterServicesWidgetTypographySliderValues,
} from './master-services-widget-form-popover-typography.types'
import {
  getFontStyleOptions,
  getFontWeightOptions,
  getTextDecorationOptions,
  getTextTransformOptions,
} from './master-services-widget-form-popover-typography.utils'

declare const _smBookingWidget: any

interface Props {
  form: FormInstance<MasterServicesWidgetTypographyFormValues>
  element: string[]
  deviceMode: DeviceMode
  sliderValues: MasterServicesWidgetTypographySliderValues
  initialValues: MasterServicesWidgetTypographyFormValues
  handleSliderValuesChange: (property: string, mode: 'desktop' | 'mobile', value: NNumber) => void
}

export const MasterServicesWidgetFormPopoverTypography: React.FC<Props> = props => {
  const { form, deviceMode, element, sliderValues, initialValues } = props
  const { handleSliderValuesChange } = props

  const onFieldsChange = (field: any) => {
    const name = [...element, field[0].name.join('.')].slice(1).join('.')
    const value = typeof field[0].value === 'object' ? field[0].value.toHexString() : field[0].value

    _smBookingWidget('event', 'setStyles', undefined, name, value)
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      autoComplete="off"
      layout="vertical"
      style={{ width: '310px' }}
      onFieldsChange={onFieldsChange}
    >
      {getStrEnumValues<DeviceMode>(DeviceMode).map(mode => {
        const typographyDeviceMode = mode.toLowerCase() as keyof typeof sliderValues

        return (
          <Space
            direction="vertical"
            size="middle"
            key={mode}
            style={{ width: '100%', display: mode === deviceMode ? 'flex' : 'none' }}
          >
            <Form.Item label="Цвет" name={[typographyDeviceMode, 'color']}>
              <ColorPicker showText />
            </Form.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Размер шрифта</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('fontSize', typographyDeviceMode, value)}
                    value={
                      typeof sliderValues[typographyDeviceMode].fontSize === 'number'
                        ? sliderValues[typographyDeviceMode].fontSize
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[typographyDeviceMode].fontSize}
                    onChange={value => handleSliderValuesChange('fontSize', typographyDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Form.Item label="Насыщенность" name={[typographyDeviceMode, 'fontWeight']}>
              <Select options={getFontWeightOptions()} />
            </Form.Item>
            <Form.Item label="Преобразование" name={[typographyDeviceMode, 'textTransform']}>
              <Select options={getTextTransformOptions()} />
            </Form.Item>
            <Form.Item label="Стиль" name={[typographyDeviceMode, 'fontStyle']}>
              <Select options={getFontStyleOptions()} />
            </Form.Item>
            <Form.Item label="Оформление" name={[typographyDeviceMode, 'textDecoration']}>
              <Select options={getTextDecorationOptions()} />
            </Form.Item>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Интерлиньяж</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('lineHeight', typographyDeviceMode, value)}
                    value={
                      typeof sliderValues[typographyDeviceMode].lineHeight === 'number'
                        ? sliderValues[typographyDeviceMode].lineHeight
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[typographyDeviceMode].lineHeight}
                    onChange={value => handleSliderValuesChange('lineHeight', typographyDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Межсимвольный интервал</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('letterSpacing', typographyDeviceMode, value)}
                    value={
                      typeof sliderValues[typographyDeviceMode].letterSpacing === 'number'
                        ? sliderValues[typographyDeviceMode].letterSpacing
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[typographyDeviceMode].letterSpacing}
                    onChange={value => handleSliderValuesChange('letterSpacing', typographyDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Typography.Text>Интервал между словами</Typography.Text>
              <Row>
                <Col span={16}>
                  <Slider
                    max={40}
                    onChange={value => handleSliderValuesChange('wordSpacing', typographyDeviceMode, value)}
                    value={
                      typeof sliderValues[typographyDeviceMode].wordSpacing === 'number'
                        ? sliderValues[typographyDeviceMode].wordSpacing
                        : 0
                    }
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    max={40}
                    style={{ margin: '0 16px' }}
                    value={sliderValues[typographyDeviceMode].wordSpacing}
                    onChange={value => handleSliderValuesChange('wordSpacing', typographyDeviceMode, value)}
                    suffix="PX"
                  />
                </Col>
              </Row>
            </Space>
          </Space>
        )
      })}
    </Form>
  )
}

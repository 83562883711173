import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'

import { MasterServicesTableActions, MasterServicesTableDataItem } from './master-services-table.types'
import { genMasterServicesTableColumns } from './master-services-table.utils'
import { CustomCard } from '../../custom-card/custom-card.component'

interface Props extends MasterServicesTableActions {
  className?: string
  data: MasterServicesTableDataItem[]
  pagination?: TablePaginationConfig
  loading?: boolean
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export const MasterServicesTable: React.FC<Props> = props => {
  const { className } = props
  const { data, pagination, loading } = props
  const { onEdit, onRemove, onHistory, onChangePage, onChangePageSize } = props

  const columns = React.useMemo(
    () =>
      genMasterServicesTableColumns({
        onEdit,
        onRemove,
        onHistory,
      }),
    [onEdit, onRemove, onHistory]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <CustomCard>
      <Table
        className={className}
        rowKey="id"
        columns={columns}
        dataSource={data}
        pagination={paginationConfig}
        loading={loading}
        scroll={{ x: 1300 }}
      />
    </CustomCard>
  )
}

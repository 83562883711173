import { ClientsExerciseHistoryPeek } from '../../../components/clients/clients-exercise-history-peek/clients-exercise-history-peek.component'
import { useClientsEditPagePeekExerciseHistory } from './clients-edit-page-peek-exercise-history.hook'
import { ClientsEditPagePeekExerciseHistoryProps } from './clients-edit-page-peek-exercise-history.types'

export const ClientsEditPagePeekExerciseHistory: React.FC<ClientsEditPagePeekExerciseHistoryProps> = props => {
  const { exerciseHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useClientsEditPagePeekExerciseHistory(props)

  return (
    <ClientsExerciseHistoryPeek
      exerciseHistory={exerciseHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { useHistory } from 'react-router-dom'
import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'

import { isDef } from '../types/lang.types'

// Route guard if user delete item or redirect to invalid page
export const usePaginationPagesLimit = (
  pagination: TablePaginationConfig | undefined,
  totalElements: number | undefined,
  genPagePath: ({ page, size }: { page: number; size: number }) => string
) => {
  const { push } = useHistory()

  useEffect(() => {
    if (isDef(pagination)) {
      const maxPages = Math.ceil((pagination?.total ?? 1) / (pagination?.pageSize ?? 1))
      if ((pagination?.current ?? 1) > maxPages) {
        push(genPagePath({ page: maxPages, size: pagination?.pageSize ?? 1 }))
      }
    }
  }, [pagination, totalElements])
}

import { FormInstance } from 'antd'
import { Color } from 'antd/es/color-picker'
import { DefaultOptionType } from 'antd/es/select'

import { MasterServicesWidgetFormValues } from './master-services-widget-form.types'
import { genMasterServicesWidgetInitialValues } from './master-services-widget-form.utils'

interface FieldData {
  name: string | number | (string | number)[]
  value?: any
  touched?: boolean
  validating?: boolean
  errors?: string[]
}

declare const _smBookingWidget: any

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  masterServicesOptions: DefaultOptionType[]
}

export function useMasterServicesWidgetForm(props: Props) {
  const { form, masterServicesOptions } = props

  const initialValues = genMasterServicesWidgetInitialValues(String(masterServicesOptions[0].value))

  const onChangeAccentColor = (value: Color, allFields: FieldData[]) => {
    const color = value
    const fields = [
      ['design', 'stepsStyles', 'active', 'backgroundColor'],
      ['design', 'inputStyles', 'hover', 'border', 'color'],
      ['design', 'searchInputStyles', 'hover', 'border', 'color'],
      ['design', 'serviceStyles', 'active', 'backgroundColor'],
      ['design', 'serviceStyles', 'active', 'border', 'color'],
      ['design', 'timeSlotStyles', 'hover', 'backgroundColor'],
      ['design', 'timeSlotStyles', 'hover', 'border', 'color'],
      ['design', 'timeSlotStyles', 'active', 'backgroundColor'],
      ['design', 'timeSlotStyles', 'active', 'border', 'color'],
      ['design', 'executorBlockStyles', 'iconsColor'],
      ['design', 'executorBlockStyles', 'timeSlot', 'hover', 'backgroundColor'],
      ['design', 'executorBlockStyles', 'timeSlot', 'hover', 'border', 'color'],
      ['design', 'executorBlockStyles', 'timeSlot', 'active', 'backgroundColor'],
      ['design', 'executorBlockStyles', 'timeSlot', 'active', 'border', 'color'],
    ]

    fields.forEach(editableField => {
      if (!allFields.find(field => field.name.toString() === editableField.toString())?.touched) {
        form.setFieldValue(editableField, color)
      }
    })
  }

  const onChangeSecondColor = (value: Color, allFields: FieldData[]) => {
    const color = value
    const fields = [
      ['design', 'stepsStyles', 'default', 'backgroundColor'],
      ['design', 'buttonStyles', 'buttonBack', 'default', 'backgroundColor'],
      ['design', 'buttonStyles', 'buttonBack', 'default', 'border', 'color'],
      ['design', 'searchInputStyles', 'default', 'backgroundColor'],
      ['design', 'searchInputStyles', 'default', 'border', 'color'],
      ['design', 'searchInputStyles', 'hover', 'backgroundColor'],
      ['design', 'serviceStyles', 'default', 'backgroundColor'],
      ['design', 'serviceStyles', 'default', 'border', 'color'],
      ['design', 'serviceStyles', 'hover', 'backgroundColor'],
      ['design', 'serviceStyles', 'hover', 'border', 'color'],
      ['design', 'serviceCategoriesStyles', 'default', 'backgroundColor'],
      ['design', 'serviceCategoriesStyless', 'default', 'border', 'color'],
      ['design', 'switcherStyles', 'backgroundColor'],
      ['design', 'datePickerStyles', 'hover', 'backgroundColor'],
      ['design', 'datePickerStyles', 'hover', 'border', 'color'],
      ['design', 'timeSlotStyles', 'default', 'backgroundColor'],
      ['design', 'timeSlotStyles', 'default', 'border', 'color'],
      ['design', 'executorBlockStyles', 'backgroundColor'],
      ['design', 'executorBlockStyles', 'border', 'color'],
      ['design', 'aboutExecutorStyles', 'backgroundColor'],
      ['design', 'aboutExecutorStyles', 'border', 'color'],
    ]

    fields.forEach(editableField => {
      if (!allFields.find(field => field.name.toString() === editableField.toString())?.touched) {
        form.setFieldValue(editableField, color)
      }
    })
  }

  const onFieldsChange = (field: any, allFields: FieldData[]) => {
    const name = field[0].name.slice(1).join('.')
    const value = typeof field[0].value === 'object' ? field[0].value.toHexString() : field[0].value

    if (field[0].name[0] === 'design') {
      _smBookingWidget('event', 'setStyles', undefined, name, value)
      if (name === 'accentColor') {
        onChangeAccentColor(value, allFields)
      }
      if (name === 'secondColor') {
        onChangeSecondColor(value, allFields)
      }
    } else {
      _smBookingWidget('event', 'setSettings', undefined, name, value)
    }
  }

  return {
    initialValues,
    onFieldsChange,
  }
}

import { Table, TablePaginationConfig } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { ExpandableConfig } from 'rc-table/lib/interface'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { CustomCard } from '../../custom-card/custom-card.component'
import { TransactionsTableDataItem, TransactionsTableProps } from './transactions-table.types'
import {
  genTransactionsTableColumns,
  genTransactionsTableExpandedRowRender,
  genTransactionsTableRowExpandable,
} from './transactions-table.utils'
import './transactions-table.styles.less'

export const TransactionsTable: React.FC<TransactionsTableProps> = props => {
  const { push } = useHistory()

  const { data, loading, pagination, filters } = props
  const { onBarcode, onRefundSum, onHistory } = props
  const { period, onChangePeriodHandler, onFilter } = props

  const { productsSearchForm, clientsSearchForm, employeesSearchForm, onSearchHandler } = props

  const [filterProductsOpen, setFilterProductsOpen] = React.useState(false)

  const handlePhoneClick = React.useCallback(
    (clientId: string) => {
      push(`/clients/${clientId}`)
    },
    [push]
  )

  const columns: ColumnsType<TransactionsTableDataItem> = React.useMemo(
    () =>
      genTransactionsTableColumns(
        {
          onBarcode,
          onRefundSum,
          onHistory,
        },
        filters,
        productsSearchForm,
        clientsSearchForm,
        employeesSearchForm,
        filterProductsOpen,
        setFilterProductsOpen,
        onChangePeriodHandler,
        onSearchHandler,
        handlePhoneClick,
        period
      ),
    [
      onBarcode,
      onRefundSum,
      onHistory,
      filters,
      productsSearchForm,
      clientsSearchForm,
      employeesSearchForm,
      filterProductsOpen,
      onChangePeriodHandler,
      onSearchHandler,
      handlePhoneClick,
      period,
    ]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
    }),
    [pagination]
  )

  const expandable: ExpandableConfig<TransactionsTableDataItem> = React.useMemo(
    () => ({
      expandedRowRender: genTransactionsTableExpandedRowRender,
      rowExpandable: genTransactionsTableRowExpandable,
    }),
    []
  )

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    onFilter(pagination, {
      status: filters.status,
      paymentMethod: filters.paymentMethod,
    })
  }

  return (
    <CustomCard>
      <Table
        id="transactions-table"
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={paginationConfig}
        expandable={expandable}
        rowKey="id"
        onChange={handleTableChange}
        className="transactions-table"
        getPopupContainer={() => document.getElementById('transactions-table') || document.body}
      />
    </CustomCard>
  )
}

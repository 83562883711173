import { FC } from 'react'
import clsx from 'clsx'

import { ISquareProps } from './square.types'
import { FIGURE_DIRECTION, FIGURE_MODE } from '../../../constants/figure'

import './square.styles.less'

export const Square: FC<ISquareProps> = ({
  color = 'black',
  size = 'large',
  mode = FIGURE_MODE.FULL,
  direction = FIGURE_DIRECTION.LEFT,
}) => {
  const circleSize = size === 'large' ? 26 : 18
  const halfCircleSize = circleSize / 2

  if (mode === FIGURE_MODE.HALF) {
    return (
      <svg
        width={halfCircleSize}
        height={circleSize}
        viewBox={`0 0 ${halfCircleSize} ${circleSize}`}
        className={clsx('half-mask', {
          'half-square--left': direction === FIGURE_DIRECTION.LEFT,
          'half-square--right': direction === FIGURE_DIRECTION.RIGHT,
        })}
      >
        <path
          d={`M 0,0 H ${halfCircleSize - 5} A 5,5 0 0,1 ${halfCircleSize},5 V ${circleSize - 5} A 5,5 0 0,1 ${
            halfCircleSize - 5
          },${circleSize} H 0 Z`}
          fill={color}
        />
      </svg>
    )
  } else {
    return (
      <svg width={circleSize} height={circleSize} viewBox={`0 0 ${circleSize} ${circleSize}`}>
        <rect x="1" y="1" width={circleSize - 2} height={circleSize - 2} rx="5" fill={color} />
      </svg>
    )
  }
}

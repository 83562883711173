import { all, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { genDirectionsPagePath } from '../../../format/path.format'
import { directionsEditPageActions } from './directions-edit-page.slice'
import { genDirectionDTO } from '../../../mapping/directions.mapping'
import { callApi } from '../../../utils/sagas.utils'

export function* fetchPageData(action: ReturnType<typeof directionsEditPageActions.fetchPageData>) {
  try {
    const [direction, exercisesTypes]: [
      Awaited<ReturnType<typeof api.exercisesDirectionsService.fetchById>>,
      Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>>
    ] = yield all([
      callApi(api.exercisesDirectionsService.fetchById, action.payload),
      callApi(api.exercisesTypesService.fetchAll, {
        size: 100,
      }),
    ])

    yield put(
      directionsEditPageActions.fetchPageDataSuccess({
        direction: direction.data,
        exercisesTypes: exercisesTypes.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(directionsEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* updateDirection(action: ReturnType<typeof directionsEditPageActions.updateDirection>) {
  try {
    const { id, data } = action.payload
    const directionDTO = genDirectionDTO(data)

    yield callApi(api.exercisesDirectionsService.update, id, directionDTO)

    yield put(directionsEditPageActions.updateDirectionSuccess())
    yield put(push(genDirectionsPagePath()))
  } catch (e) {
    console.error(e)
    yield put(directionsEditPageActions.updateDirectionError(new Error()))
  }
}

export function* directionsEditPageSagas() {
  yield takeLatest(directionsEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(directionsEditPageActions.updateDirection, updateDirection)
}

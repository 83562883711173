import { useState, ChangeEventHandler } from 'react'
import { FormInstance } from 'antd/lib/form'
import { SwitchChangeEventHandler } from 'antd/es/switch'
import { useDispatch, useSelector } from 'react-redux'

import { customFieldsPageActions } from '../../../store/pages/custom-fields-page/custom-fields-page.slice'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { getCustomFieldsResource } from '../../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { CustomFieldFormValues } from '../custom-fields-form/custom-fields-form.types'
import { CustomFieldsOptionsSelect } from '../custom-fields-form/custom-fields-form.constants'
import { useCustomFieldsShowFields } from '../../../pages/custom-fields-page/custom-fields-page-hooks/custom-fields-page-select-type.hook'

interface Props {
  form: FormInstance<CustomFieldFormValues>
}

export const useCustomFieldsModalCreate = ({ form }: Props) => {
  const dispatch = useDispatch()

  const resource = useSelector(getCustomFieldsResource)
  const [nameValue, setNameValue] = useState('')
  const [selectType, setSelectType] = useState('INPUT')
  const [defaultValue, setDefaultValue] = useState('')
  const [placeholderValue, setPlaceholderValue] = useState('')
  const [textareaValue, setTextareaValue] = useState('')
  const [required, setRequired] = useState(false)
  const [visibleInWidget, setVisibleInWidget] = useState(false)
  const [alwaysAsk, setAlwaysAsk] = useState(false)

  const defaultValueForm = () => {
    form.setFieldValue('name', nameValue)
    form.setFieldValue('placeholder', placeholderValue)
    form.setFieldValue('type', selectType)
    form.setFieldValue('default', defaultValue)
    form.setFieldValue('textarea', textareaValue)
    form.setFieldValue('checkbox', required)
    form.setFieldValue('visibleInWidget', visibleInWidget)
    form.setFieldValue('alwaysAsk', alwaysAsk)
  }
  defaultValueForm()
  const onChangeValueHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('name', value)
    setDefaultValue(value)
  }
  const onChangeTitleHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('name', value)
    setNameValue(value)
  }
  const onChangePlaceholderHandler: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value
    form.setFieldValue('placeholder', value)
    setPlaceholderValue(value)
  }
  const onChangeTextareaHandler: ChangeEventHandler<HTMLTextAreaElement> = event => {
    const value = event.target.value
    form.setFieldValue('textarea', value)
    setTextareaValue(value)
  }
  const onChangeRequiredHandler = () => {
    setRequired(!required) // Инвертируем состояние флажка
    form.setFieldValue('checkbox', required)
  }
  const onChangeSelectHandler = (value: CustomFieldsApi.CustomFieldsType) => {
    form.setFieldValue('type', value)
    setSelectType(value)
  }
  const onChangeVisibleInWidgetHandler = () => {
    setVisibleInWidget(!visibleInWidget)
    form.setFieldValue('visibleInWidget', visibleInWidget)
  }
  const onChangeAlwaysAskHandler = () => {
    setAlwaysAsk(!alwaysAsk)
    form.setFieldValue('alwaysAsk', alwaysAsk)
  }
  const [fields, setFields] = useState([{ name: 'input-0', value: '' }])

  const handleAddField = () => {
    const newField = { name: `input-${fields.length}`, value: '' }
    setFields(prevFields => [...prevFields, newField])
  }

  const handleRemoveField = (index: number) => {
    if (fields.length > 1) {
      setFields(prevFields => prevFields.filter((_, i) => i !== index))
    }
  }

  const handleInputChange = (index: number, value: string) => {
    setFields(prevFields => {
      const updatedFields = [...prevFields]
      updatedFields[index] = { ...updatedFields[index], value }
      return updatedFields
    })
  }
  const { isShowPlaceholderInput, isShowSelectInput, isShowTextArea, isShowTextInput } = useCustomFieldsShowFields(
    selectType as CustomFieldsApi.CustomFieldsType
  )

  const genAttr = () => {
    if (selectType === 'INPUT') {
      return {
        placeholder: placeholderValue,
        default: defaultValue,
      }
    }
    if (selectType === 'MULTILINE_INPUT') {
      return {
        placeholder: placeholderValue,
        default: textareaValue,
      }
    }
    if (selectType === 'SELECT' || selectType === 'CHECKBOX_GROUP') {
      const options = fields.map(field => ({ default: false, name: field.value }))

      return {
        placeholder: placeholderValue,
        options,
      }
    }
    return {
      default: defaultValue,
      placeholder: placeholderValue,
    }
  }
  const dataFormCreate: CustomFieldsApi.CustomFieldDTO = {
    name: nameValue,
    required: required,
    resource: resource as unknown as CustomFieldsApi.CustomFieldResource,
    description: '',
    type: selectType as CustomFieldsApi.CustomFieldsType,
    attributes: genAttr(),
    settings: {
      ...(resource === 'CLIENT' && { alwaysAsk, visibleInWidget }),
    },
  }
  const onClose = () => {
    dispatch(modalActions.closeLast())
  }
  const onSaveHandler = () => {
    dispatch(customFieldsPageActions.createCustomField(dataFormCreate))
    onClose()
  }
  return {
    onChangeTitleHandler,
    onChangePlaceholderHandler,
    onChangeRequiredHandler,
    onChangeVisibleInWidgetHandler,
    onChangeAlwaysAskHandler,
    onChangeSelectHandler,
    onChangeValueHandler,
    onChangeTextareaHandler,
    handleAddField,
    handleRemoveField,
    handleInputChange,
    fields,
    CustomFieldsOptionsSelect,
    isShowPlaceholderInput,
    isShowSelectInput,
    isShowTextArea,
    isShowTextInput,
    onSaveHandler,
    required,
    visibleInWidget,
    alwaysAsk,
    resource,
  }
}

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { syncKkmWithBackend } from '../../../utils/api/sync-kkm-with-backend/sync-kkm-with-backend.utils'
import { genClientsEditPagePath, genScheduleGroupPagePath } from '../../../format/path.format'
import { genPaginationConfig } from '../../../utils/pagination.utils'
import { AppModal } from '../../../types/modal.types'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { clientsEditPageSubscriptionsActions } from '../../../store/pages/clients-edit-page/clients-edit-page-subscriptions/clients-edit-page-subscriptions.slice'
import {
  getClientsEditPageSubscriptionsIsLoaded,
  getClientsEditPageSubscriptionsIsLoading,
  getClientsEditPageSubscriptionsTableDataItems,
  getClientsEditPageSubscriptionsTotalElements,
} from '../../../store/pages/clients-edit-page/clients-edit-page-subscriptions/clients-edit-page-subscriptions.selectors'
import { useClientsEditPageParams } from '../clients-edit-page-hooks/clients-edit-page-params.hook'
import { sortClientsEditPageSubscriptions } from './clients-edit-page-subscriptions.utils'
import { ScheduleGroupPageUrlParams } from '../../schedule-group-page/schedule-group-page.types'
import { genSubscriptionStatusOptions } from '../../../utils/subscription-status.utils'

export function useClientsEditPageSubscriptions() {
  const { id, section, page, size, statuses } = useClientsEditPageParams()

  const { push } = useHistory()
  const dispatch = useDispatch()

  const subscriptionStatusOptions = React.useMemo(genSubscriptionStatusOptions, [])

  const data = useSelector(getClientsEditPageSubscriptionsTableDataItems)
  const sortedData = data ? sortClientsEditPageSubscriptions(data) : null

  const totalElements = useSelector(getClientsEditPageSubscriptionsTotalElements)

  const isLoading = useSelector(getClientsEditPageSubscriptionsIsLoading)
  const isLoaded = useSelector(getClientsEditPageSubscriptionsIsLoaded)

  const pagination = React.useMemo(() => genPaginationConfig(page, size, totalElements), [page, size, totalElements])

  const defaultValue = React.useMemo(
    () => (statuses && statuses.length > 0 ? statuses.split(',') : undefined),
    [statuses]
  )

  const updateClientSubscription = () =>
    dispatch(
      clientsEditPageSubscriptionsActions.fetchClientsSubscriptions({
        clientId: id,
        page,
        size,
        statuses,
      })
    )

  React.useEffect(() => {
    updateClientSubscription()
  }, [dispatch, id, page, size, statuses])

  React.useEffect(() => {
    return () => {
      dispatch(clientsEditPageSubscriptionsActions.reset())
    }
  }, [dispatch])

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genClientsEditPagePath({ id, section }, { page, size: pageSize, statuses }))
    },
    [id, push, section, statuses]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genClientsEditPagePath({ id, section }, { size: pageSize }))
    },
    [id, push, section]
  )

  const onBarcodeHandler = React.useCallback(
    (subscriptionId: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_RECEIPTS_MODAL,
          props: { clientId: id, subscriptionId },
        })
      )
    },
    [dispatch, id]
  )

  const onPauseHandler = React.useCallback(
    (subscriptionId: string): void => {
      dispatch(
        modalActions.show({
          modal: AppModal.CLIENTS_EDIT_PAGE_SUBSCRIPTIONS_PAUSE_MODAL,
          props: { clientId: id, subscriptionId },
        })
      )
    },
    [dispatch, id]
  )

  const onResumeHandler = React.useCallback(
    (subscriptionId: string): void => {
      dispatch(clientsEditPageSubscriptionsActions.resumeClientSubscription({ clientId: id, subscriptionId }))
    },
    [id, dispatch]
  )

  const onRefundHandler = async (subscriptionId: string) => {
    await syncKkmWithBackend(
      async api => await api.clientsSubscriptionsService.fetchRefundSum(id, subscriptionId),
      async (api, kkmCommandResponses) =>
        await api.clientsSubscriptionsService.refund(id, subscriptionId, kkmCommandResponses),
      'Успешно возвращено'
    )

    updateClientSubscription()
  }

  const onExpirationDateHandler = React.useCallback(
    (expirationDate: string, subscriptionId: string): void => {
      dispatch(
        clientsEditPageSubscriptionsActions.editExpirationClientSubscription({
          clientId: id,
          subscriptionId,
          expirationDate,
        })
      )
    },
    [dispatch, id]
  )

  const onBookingHandler = React.useCallback(
    (studioId: string, scheduleId: string): void => {
      const params: ScheduleGroupPageUrlParams = { studioId, scheduleId }
      push(genScheduleGroupPagePath(params))
    },
    [push]
  )

  const onSearchHandler = React.useCallback(
    (value: string[]): void => {
      push(genClientsEditPagePath({ id, section }, { page, size, statuses: value.toString() }))
    },
    [id, page, push, section, size]
  )

  return {
    data: sortedData,
    pagination,

    isLoading,
    isLoaded,

    subscriptionStatusOptions,
    defaultValue,

    onChangePageHandler,
    onChangePageSizeHandler,

    onBarcodeHandler,
    onPauseHandler,
    onResumeHandler,
    onRefundHandler,
    onExpirationDateHandler,

    onBookingHandler,
    onSearchHandler,
  }
}

import { Button, Flex, Form, FormInstance, FormListFieldData } from 'antd'
import Select, { DefaultOptionType } from 'antd/es/select'
import { useWatch } from 'antd/lib/form/Form'
import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { getMasterService } from '@store/pages/master-services-edit-page/master-services-edit-page.selectors'

import { MasterServicesFormValues } from '../../../master-services-form.types'

interface Props {
  form: FormInstance<MasterServicesFormValues>
  field: FormListFieldData
  trainersOptions?: DefaultOptionType[]
}
export const MasterServicesFormSubserviceExecutors: React.FC<Props> = props => {
  const { form, field, trainersOptions } = props

  const masterService = useSelector(getMasterService)

  const subservice = useWatch(['subServices', field.name])
  const isNotRequiredTrainer = useWatch(['subServices', field.name, 'isNotRequiredTrainer'], form)
  const trainers = useWatch(['subServices', field.name, 'trainers'], form)

  const hasWithoutTrainer = useMemo(() => !(trainers ?? [])?.includes('withoutTrainer'), [trainers])

  const onWithoutTrainerHandler = () => {
    form.setFieldValue(['subServices', field.name, 'trainers'], [...(trainers ?? []), 'withoutTrainer'])
    form.validateFields([['subServices', field.name, 'trainers']])
  }

  const onNoTrainerLimitationHandler = () => {
    form.setFieldValue(['subServices', field.name, 'trainers'], ['noTrainerLimitation'])
    form.validateFields([['subServices', field.name, 'trainers']])
  }

  const onTrainerSelectHandler = (value: string[]) => {
    const newValue = value.filter(el => el !== 'noTrainerLimitation')
    form.setFieldValue(['subServices', field.name, 'trainers'], newValue)
  }
  const filterOption = (input: string, option?: any) => {
    if (option && option.children) {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    return false
  }

  const deletedTrainers = useMemo(() => {
    if (!subservice) return []
    const fullSubservice = masterService?.subServices?.find(rowSubservice => subservice?.id === rowSubservice?.id)
    if (!fullSubservice) return []

    const filteredData = fullSubservice.trainers.filter(item => item?.deleted && trainers.includes(item.id))
    return filteredData ?? []
  }, [masterService, subservice])

  const trainersOptionsWithoutDeletedTrainers = useMemo(() => {
    if (!deletedTrainers) return trainersOptions

    const deletedIds = deletedTrainers.map(item => item.id)
    const filteredTrainersOptions = (trainersOptions ?? []).filter(option => !deletedIds.includes(String(option.value)))
    return filteredTrainersOptions ?? []
  }, [deletedTrainers])

  return (
    <Form.Item
      name={[field.name, 'trainers']}
      label="Исполнители"
      rules={[{ required: true, message: 'Выберите исполнителей' }]}
    >
      <Select
        size="large"
        optionLabelProp="label"
        mode="multiple"
        onChange={onTrainerSelectHandler}
        showSearch
        filterOption={filterOption}
        dropdownRender={menu => (
          <Flex vertical gap="2px">
            {isNotRequiredTrainer && hasWithoutTrainer && (
              <Button style={{ width: '100%' }} onClick={onWithoutTrainerHandler}>
                Без исполнителя
              </Button>
            )}
            <Button style={{ width: '100%' }} onClick={onNoTrainerLimitationHandler}>
              Все исполнители с этой категорией записи
            </Button>
            {menu}
          </Flex>
        )}
      >
        {isNotRequiredTrainer && (
          <Select.Option
            key="withoutTrainer"
            value="withoutTrainer"
            label="Без исполнителя"
            style={{ display: 'none' }}
          >
            Без исполнителя
          </Select.Option>
        )}
        <Select.Option
          key="noTrainerLimitation"
          value="noTrainerLimitation"
          label="Все исполнители с этой категорией записи"
          style={{ display: 'none' }}
        >
          Все исполнители с этой категорией записи
        </Select.Option>
        {deletedTrainers.map(item => (
          <Select.Option key={item.id} value={item.id} label={`${item.lastName} ${item.firstName} (Удалён)`}>
            {`${item.lastName} ${item.firstName} (Удалён)`}
          </Select.Option>
        ))}
        {trainersOptionsWithoutDeletedTrainers?.map(option => (
          <Select.Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}

import * as React from 'react'
import { Button } from 'antd'

import { useEmployeesPageTopBar } from './employees-page-top-bar.hook'
import { EmployeesSearchContainer } from '../../../containers/employees-search/employees-search.container'
import './employees-page-top-bar.styles.less'

export const EmployeesPageTopBar = () => {
  const { onCreateEmployeeHandler } = useEmployeesPageTopBar()

  return (
    <div className="employees-page-top-bar">
      <Button type="primary" onClick={onCreateEmployeeHandler}>
        Добавить сотрудника
      </Button>
      <EmployeesSearchContainer />
    </div>
  )
}

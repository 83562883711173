import { ColumnsType } from 'antd/es/table'

import { ClientsBookingsTableBaseDataItem } from '../clients-bookings-table-base/clients-bookings-table-base.types'
import { genClientsBookingsTableBaseColumns } from '../clients-bookings-table-base/clients-bookings-table-base.utils'
import { ClientsBookingsTableActiveVisit } from './clients-bookings-table-active-visit/clients-bookings-table-active-visit.component'
import { ClientsBookingsTableActiveActions } from './clients-bookings-table-active-actions/clients-bookings-table-active-actions.component'
import { ClientsBookingsTableActiveEvents } from './clients-bookings-table-active.types'

export function genClientsBookingsTableActiveColumns(
  events: ClientsBookingsTableActiveEvents
): ColumnsType<ClientsBookingsTableBaseDataItem> {
  const { onVisit, onBarcode, onCancel, onBooking, onHistory, onChangePaymentType, onCancelMultibooking } = events

  return [
    ...genClientsBookingsTableBaseColumns({
      onChangePaymentType,
    }),
    {
      title: 'Посещение',
      dataIndex: 'visitConfirmed',
      key: 'visitConfirmed',
      width: 140,
      render: (_, booking) => {
        const { id, exerciseId, visitConfirmed, multibooking } = booking

        return (
          <ClientsBookingsTableActiveVisit
            bookingId={id}
            exerciseId={exerciseId}
            isChecked={visitConfirmed}
            multibooking={multibooking}
            onVisit={onVisit}
          />
        )
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      width: 150,
      render: (_, booking) => {
        const { id, exerciseId, studio } = booking

        return (
          <ClientsBookingsTableActiveActions
            bookingId={id}
            exerciseId={exerciseId}
            paymentType={booking.paymentType}
            onBarcode={onBarcode}
            onCancel={onCancel}
            onBooking={onBooking}
            onHistory={onHistory}
            onCancelMultibooking={onCancelMultibooking}
            studioId={studio.id}
          />
        )
      },
    },
  ]
}

import { useHistory } from 'react-router-dom'

import { isDef } from '../../../types/lang.types'

export function useScheduleGroupBackward() {
  const history = useHistory<{ date: string }>()
  const scheduleDate =
    isDef(history.location.state) && isDef(history.location.state.date) ? history.location.state.date : undefined

  return {
    scheduleDate,
  }
}

import { Collapse, Typography } from 'antd'

interface Props {
  title: string
}
export const MasterServicesWidgetFormUiCollapse: React.FC<React.PropsWithChildren<Props>> = props => {
  const { title } = props

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      items={[
        {
          key: '1',
          label: (
            <Typography.Title level={5} style={{ margin: 0 }}>
              {title}
            </Typography.Title>
          ),
          children: props.children,
        },
      ]}
    />
  )
}

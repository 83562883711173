import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { isDef } from '../../../types/lang.types'
import { PositionsFormValues } from '../../../components/positions/positions-form/positions-form.types'
import { usePositionsEditPageParams } from '../positions-edit-page-hooks/positions-edit-page-params.hook'
import {
  getPositionEditPageIsLoading,
  getPositionEditPageFormValues,
  getPositionEditPageGrades,
} from '../../../store/pages/positions-edit-page/positions-edit-page.selectors'
import { positionsEditPageActions } from '../../../store/pages/positions-edit-page/positions-edit-page.slice'
import { DictionaryItem } from '../../../api/types/api.types'
import { PositionType } from '../../../types/positions.types'

export function usePositionsEditPageForm() {
  const [form] = Form.useForm<PositionsFormValues>()

  const isLoading = useSelector(getPositionEditPageIsLoading)
  const positionFormValues = useSelector(getPositionEditPageFormValues)
  const positionGrades = useSelector(getPositionEditPageGrades)

  const { id } = usePositionsEditPageParams()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDef(positionFormValues)) {
      form.setFieldsValue(positionFormValues)
    }
  }, [form, positionFormValues])

  const onUpdateGradeHandler = React.useCallback(
    (data: DictionaryItem): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.updateGrade({ positionId: id, grade: data }))
      }
    },
    [dispatch, id]
  )

  const onCreateGradeHandler = React.useCallback(
    (value: string, positionType: PositionType): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.createGrade({ positionId: id, name: value, positionType }))
      }
    },
    [dispatch, id]
  )

  const onRemoveGradeHandler = React.useCallback(
    (gradeId: string): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.removeGrade({ positionId: id, gradeId: gradeId }))
      }
    },
    [dispatch, id]
  )

  const onFinishHandler = React.useCallback(
    (values: PositionsFormValues): void => {
      if (isDef(id)) {
        dispatch(positionsEditPageActions.updatePosition({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  return {
    form,
    isLoading,
    positionGrades,
    onUpdateGradeHandler,
    onCreateGradeHandler,
    onRemoveGradeHandler,
    onFinishHandler,
  }
}

import * as React from 'react'
import clsx from 'clsx'
import { Card } from 'antd'

import './custom-card.styles.less'

import { useTheme } from '../../hooks/use-theme.hook'

interface Props {
  children: React.ReactNode
  customClassName?: string
}

export const CustomCard: React.FC<Props> = props => {
  const { children, customClassName } = props
  const { theme } = useTheme()

  return (
    <Card
      className={clsx('custom-card', customClassName, {
        'custom-card--dark': theme === 'dark',
      })}
      bordered={false}
    >
      {children}
    </Card>
  )
}

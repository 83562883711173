import React from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import './index.less'

import { App } from './app/app.component'

Sentry.init({
  dsn: 'https://19ea06bde37866790c7a07ef0e4f53c6@o4507095192961024.ingest.de.sentry.io/4507096284987472',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/^https:\/\/cabinet\.vivacrm\.ru/, /^https:\/\/dev\.cabinet\.vivacrm\.ru/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')

if (container) {
  const root = ReactDOM.createRoot(container)

  root.render(<App />)
}

import * as React from 'react'

import { AutocompleteDropdownProps } from './autocomplete-dropdown.types'
import './autocomplete-dropdown.styles.less'
import { useTheme } from '../../../../hooks/use-theme.hook'

const AutocompleteDropdownInternal = (props: AutocompleteDropdownProps, ref: React.Ref<HTMLDivElement>) => {
  const { children } = props
  const { isOpen } = props
  const { theme } = useTheme()

  return (
    <div
      className={`autocomplete-dropdown ${
        theme === 'light' ? 'autocomplete-dropdown-light' : 'autocomplete-dropdown-dark'
      }`}
      ref={ref}
    >
      <div className="autocomplete-dropdown-inner">{isOpen ? children : null}</div>
    </div>
  )
}

export const AutocompleteDropdown = React.forwardRef(
  AutocompleteDropdownInternal
) as typeof AutocompleteDropdownInternal

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { genPositionFormValues, genPositionGrades } from '../../../mapping/positions.mapping'

const getPositionInternal = (state: AppState) => state.positionsEditPage.position

export const getPositionEditPageIsLoading = (state: AppState) => state.positionsEditPage.isLoading

export const getPositionEditPageGrades = createSelector(getPositionInternal, genPositionGrades)
export const getPositionEditPageFormValues = createSelector(getPositionInternal, genPositionFormValues)

import { Form, Input, Button, FormInstance, Select, Col, Space, Radio, Typography } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useCallback, useEffect, useState } from 'react'
import { RadioChangeEvent } from 'antd/lib'

import { ExercisesTypesFormValues } from './exercises-types-form.types'
import { genShowToUserOptions } from './exercises-types-form.utils'
import { InputSelectColor } from '../../controls/input-select-color/input-select-color.component'

interface Props {
  form: FormInstance<ExercisesTypesFormValues>
  isLoading: boolean
  submitText: string
  directionsOptions: DefaultOptionType[] | undefined
  colorsOptions: DefaultOptionType[]
  onFinish: (values: ExercisesTypesFormValues) => void
}

export const ExercisesTypesForm: React.FC<Props> = props => {
  const { form, isLoading, submitText, directionsOptions, colorsOptions, onFinish } = props
  const showToUserOptions = genShowToUserOptions()

  const onChangeSelectColor = useCallback(
    (value: string): void => {
      const values = form.getFieldsValue()

      form.setFieldsValue({
        ...values,
        color: value,
      })
    },
    [form]
  )

  const formFormatValue = form.getFieldValue('format')
  const [valueRadio, setValueRadio] = useState<string>(formFormatValue)

  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value)
  }

  return (
    <Form
      form={form}
      initialValues={{
        format: 'GROUP',
      }}
      name="userCreate"
      labelCol={{ span: 16 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      disabled={isLoading}
    >
      <Form.Item
        style={{ paddingBottom: '15px' }}
        label="Название"
        name="name"
        rules={[{ required: true, message: 'Введите название' }]}
      >
        <Input placeholder="Название" />
      </Form.Item>
      <Form.Item
        label="Описание"
        name="description"
        rules={[{ required: true, message: 'Необходимо оставить описание' }]}
      >
        <Input.TextArea maxLength={255} style={{ height: 60 }} placeholder="Описание категории записей" />
      </Form.Item>
      <Col style={{ paddingBottom: '15px' }}>
        <Typography.Title level={4}>Формат</Typography.Title>

        <Form.Item name="format" rules={[{ required: true, message: 'Выберите формат' }]}>
          <Radio.Group onChange={onChangeRadio} value={valueRadio}>
            <Space direction="vertical">
              <Radio value="GROUP">Групповые записи</Radio>
              <Radio value="PERSONAL">Персональные услуги</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Form.Item
        style={{ paddingBottom: '15px' }}
        label="Показывать пользователям записи с этой категорией в расписании"
        name="showToUser"
        rules={[{ required: true, message: 'Необходимо выбрать опцию' }]}
      >
        <Select options={showToUserOptions} />
      </Form.Item>
      <Form.Item
        style={{ paddingBottom: '15px' }}
        name="color"
        label="Цвет категории записей"
        rules={[{ required: true, message: 'Необходимо выбрать цвет' }]}
      >
        <InputSelectColor
          placeholder="Цвет категории записей"
          colorsOptions={colorsOptions}
          value={form.getFieldValue('color')}
          onChange={onChangeSelectColor}
        />
      </Form.Item>
      {/* <Form.Item style={{ paddingBottom: '20px' }} name="directions" label="Направления">
        <Select
          placeholder="Выбери направления"
          options={directionsOptions}
          mode="multiple"
          filterOption={(input, option) => (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      </Form.Item> */}
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {submitText}
        </Button>
      </Form.Item>
    </Form>
  )
}

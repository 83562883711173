import * as React from 'react'

import { ExerciseTypeFormat } from '../../../../api/types/api.types'
import { TableCellActions } from '../../../table-cells/table-cell-actions/table-cell-actions.component'
import { ScheduleTableActionsEvents } from './schedule-table-actions.types'

interface Props extends ScheduleTableActionsEvents {
  id: string
  format: ExerciseTypeFormat
}

export const ScheduleTableActions: React.FC<Props> = props => {
  const { id, format } = props
  const { onEdit, onCancel, onHistory } = props

  const onEditHandler = React.useCallback(() => onEdit(id), [id, onEdit])

  const onRemoveHandler = React.useCallback(() => {
    onCancel(id)
  }, [id, onCancel])

  const onHistoryHandler = React.useCallback(() => onHistory(id, format), [id, format, onHistory])

  return <TableCellActions onEdit={onEditHandler} onRemove={onRemoveHandler} onHistory={onHistoryHandler} />
}

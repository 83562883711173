import * as React from 'react'
import { Button, Modal, Flex, Alert } from 'antd'

import { ScheduleForm } from '../schedule-form/schedule-form.component'
import { genScheduleFormValuesDTO } from '../schedule-form/schedule-form.utils'
import { ScheduleModalProps } from './schedule-modal.types'
import './schedule-modal.styles.less'

export const ScheduleModal: React.FC<ScheduleModalProps> = props => {
  const { form, title, studioOffset, loading, type } = props
  const { directions, directionsOptions, exercisesTypesOptions, studiosRoomsOptions, trainersOptions } = props
  const { onCancel, onSave } = props

  const [isValid, setIsValid] = React.useState<boolean>(false)
  const [errorText, setErrorText] = React.useState<null | string>(null)

  const isCheckSlotsEmpty = (data: any) => {
    const { slots } = data

    for (const day in slots) {
      if (slots.hasOwnProperty(day)) {
        const daySlots = slots[day]
        if (daySlots) {
          for (const slot of daySlots) {
            if (slot.time[0] !== null || slot.time[1] !== null || slot.room !== null || slot.totalCapacity !== null) {
              return false
            }
          }
        }
      }
    }

    return true
  }

  const onSaveHandler = React.useCallback(async () => {
    const values = form.getFieldsValue()
    const scheduleFormValuesDTO = genScheduleFormValuesDTO(values, studioOffset)

    let hasErrors = false
    await form.validateFields().catch(err => {
      hasErrors = true
    })

    const iSlotsEmpty = isCheckSlotsEmpty(values)
    if (iSlotsEmpty) {
      setErrorText('Заполните хотя бы один слот в днях недели')
    } else {
      setErrorText(null)
    }

    !hasErrors && onSave(scheduleFormValuesDTO)
  }, [form, onSave, studioOffset])

  const onFieldChangeHandler = React.useCallback(setIsValid, [setIsValid])

  return (
    <Modal
      className="schedule-modal"
      title={title}
      onCancel={onCancel}
      visible={true}
      width={704}
      footer={
        <Flex vertical gap="small">
          {errorText && <Alert showIcon message={errorText} type="error" />}
          <Button type="primary" loading={loading} onClick={onSaveHandler}>
            Сохранить
          </Button>
        </Flex>
      }
    >
      <ScheduleForm
        type={type}
        form={form}
        loading={loading}
        directions={directions}
        directionsOptions={directionsOptions}
        exercisesTypesOptions={exercisesTypesOptions}
        studiosRoomsOptions={studiosRoomsOptions}
        trainersOptions={trainersOptions}
        onFieldsChange={onFieldChangeHandler}
      />
    </Modal>
  )
}

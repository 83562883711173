import * as React from 'react'
import { Select } from 'antd'

import { isDef } from '../../../types/lang.types'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { ClientsSubscriptionsTable } from '../../../components/clients/clients-subscriptions-table/clients-subscriptions-table.component'
import { useClientsEditPageSubscriptions } from './clients-edit-page-subscriptions.hook'
import './clients-edit-page-subscriptions.styles.less'

export const ClientsEditPageSubscriptions: React.FC = () => {
  const {
    data,
    pagination,
    isLoading,
    isLoaded,
    subscriptionStatusOptions,
    defaultValue,
    onChangePageHandler,
    onChangePageSizeHandler,
    onBarcodeHandler,
    onPauseHandler,
    onResumeHandler,
    onRefundHandler,
    onExpirationDateHandler,
    onBookingHandler,
    onSearchHandler,
  } = useClientsEditPageSubscriptions()

  if (!isLoaded || isLoading) {
    return <PageLoader />
  }

  return (
    <>
      <Select
        mode="multiple"
        allowClear
        placeholder="Статус абонемента"
        className="clients-edit-page-subscriptions__filters"
        options={subscriptionStatusOptions}
        defaultValue={defaultValue}
        onChange={onSearchHandler}
      />
      {isDef(data) ? (
        <ClientsSubscriptionsTable
          data={data}
          pagination={pagination}
          loading={isLoading}
          onChangePage={onChangePageHandler}
          onChangePageSize={onChangePageSizeHandler}
          onBarcode={onBarcodeHandler}
          onPause={onPauseHandler}
          onResume={onResumeHandler}
          onRefund={onRefundHandler}
          onExpirationDate={onExpirationDateHandler}
          onBooking={onBookingHandler}
        />
      ) : (
        <PageEmpty description="Абонементы не найдены" />
      )}
    </>
  )
}

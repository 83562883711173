import { FC, useMemo } from 'react'
import { FormInstance, Typography, Flex } from 'antd'
import { TransactionsFormValues } from '@components/transactions/transactions-form/transactions-form.types'

import { Nullable, isDefAndNotEmpty } from '../../../types/lang.types'
import { TransactionsProductsTable } from '../../../components/transactions/transactions-products-table/transactions-products-table.component'
import { useTransactionsCreatePageTable } from './transactions-create-page-table.hook'

import './transactions-create-page-table.styles.less'

export interface ITransactionsCreatePageTableProps {
  clientId: Nullable<string>
  exerciseId: Nullable<string>
  form: FormInstance<TransactionsFormValues>
}

export const TransactionsCreatePageTable: FC<ITransactionsCreatePageTableProps> = ({ clientId, exerciseId, form }) => {
  const {
    products,
    onRemoveHandler,
    onChangeAmountHandler,
    onChangeMarkCodesHandler,
    onChangeDiscountHandler,
    onChangeActiveRecordHandler,
  } = useTransactionsCreatePageTable()

  const filteredProducts = useMemo(() => (products ?? []).filter(product => !product?.onlyUnpaidRecord), [products])

  if (isDefAndNotEmpty(filteredProducts)) {
    return (
      <Flex vertical className="transactions-create-page-table">
        <Typography.Title level={4} className="transactions-create-page-table__title">
          Дополнительные услуги и товары
        </Typography.Title>

        <TransactionsProductsTable
          data={filteredProducts}
          onRemove={onRemoveHandler}
          onChangeAmount={onChangeAmountHandler}
          onChangeMarkCodes={onChangeMarkCodesHandler}
          onChangeDiscount={onChangeDiscountHandler}
          onChangeActiveRecordHandler={onChangeActiveRecordHandler}
          clientId={clientId}
          exerciseId={exerciseId}
          form={form}
        />
      </Flex>
    )
  }

  return null
}

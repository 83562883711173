import * as React from 'react'
import { EmployeesAutocompleteOptionDataItem } from '@components/employees/employees-autocomplete-option/employees-autocomplete-option.types'

import { Autocomplete } from '../../controls/autocomplete/autocomplete.component'
import { ProductsAutocompleteInput } from './products-autocomplete-input/products-autocomplete-input.component'
import { ProductsAutocompleteProps } from './products-autocomplete.types'
import { ProductsAutocompleteOption } from './products-autocomplete-option/products-autocomplete-option.component'
import { genProductsAutocompleteItemToString } from './products-autocomplete.utils'

export const ProductsAutocomplete: React.FC<ProductsAutocompleteProps> = props => {
  const { className, size, placeholder, suffix } = props
  const { value, defaultValue, options } = props
  const { disabled, loading } = props
  const { onInputChange, onChange, onSelect } = props

  const onInputChangeHandler = (value: string): void => {
    onInputChange?.(value)
  }

  return (
    <Autocomplete
      className={className}
      value={value}
      defaultValue={defaultValue}
      options={options as unknown as EmployeesAutocompleteOptionDataItem[]}
      onChange={onChange}
      onInputChange={onInputChangeHandler}
      onSelect={onSelect}
      loading={loading}
      disabled={disabled}
      itemToStringFn={genProductsAutocompleteItemToString}
      clearInputAfterSelect={false}
      InputComponent={ProductsAutocompleteInput}
      OptionComponent={ProductsAutocompleteOption}
      size={size}
      placeholder={placeholder}
      suffix={suffix}
      formatValue={false}
    />
  )
}

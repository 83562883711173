import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { isDefAndNotEmpty, Nullable } from '../../../types/lang.types'
import { SalariesApi } from '../../../api/types/salaries-api.types'
import { Pagination } from '../../../api/types/api.types'
import { StudiosApi } from '../../../api/types/studios-api.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { EmployeesPositionsApi } from '../../../api/types/employees-positions-api.types'

export interface SalariesPageState {
  salaries: Nullable<SalariesApi.Salary[]>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  exercises: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  grades: Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
  deselectedSalaries: Nullable<SalariesApi.DeletedSalaryDTO[]>
}

const initialState: SalariesPageState = {
  salaries: null,
  studios: null,
  exercises: null,
  grades: null,
  directions: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  deselectedSalaries: null,
}

export const { actions: salariesPageActions, reducer: salariesPageReducer } = createSlice({
  name: '@@salaries-page',
  initialState,
  reducers: {
    fetchAllSalaries: (state: Draft<SalariesPageState>) => {
      state.isLoading = true
    },
    fetchAllSalariesSuccess: (state: Draft<SalariesPageState>, action: PayloadAction<SalariesApi.Salary[]>) => {
      state.salaries = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllSalariesError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    fetchAllStudios: (state: Draft<SalariesPageState>) => {
      state.isLoading = true
    },
    fetchAllStudiosSuccess: (
      state: Draft<SalariesPageState>,
      action: PayloadAction<Nullable<Pagination<StudiosApi.Studio>>>
    ) => {
      state.studios = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchStudiosError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    fetchAllDirections: (state: Draft<SalariesPageState>) => {
      state.isLoading = true
    },
    fetchAllDirectionsSuccess: (
      state: Draft<SalariesPageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>>
    ) => {
      state.directions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchDirectionsError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    fetchAllExercises: (state: Draft<SalariesPageState>) => {
      state.isLoading = true
    },
    fetchAllExercisesSuccess: (
      state: Draft<SalariesPageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercises = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchExercisesError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    fetchAllGrades: (state: Draft<SalariesPageState>) => {
      state.isLoading = true
    },
    fetchAllGradesSuccess: (
      state: Draft<SalariesPageState>,
      action: PayloadAction<Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>>
    ) => {
      state.grades = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchGradesError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    updateSalaries: (state: Draft<SalariesPageState>, _: PayloadAction<SalariesApi.SalaryDTO[]>) => {
      state.isLoading = true
    },
    updateSalariesSuccess: (state: Draft<SalariesPageState>) => {
      state.isLoading = false
      state.deselectedSalaries = null
    },
    updateSalariesError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    removeSalaries: (state: Draft<SalariesPageState>, _: PayloadAction<SalariesApi.DeletedSalaryDTO[]>) => {
      state.isLoading = true
    },
    removeSalariesSuccess: (state: Draft<SalariesPageState>) => {
      state.isLoading = false
    },
    removeSalariesError: (state: Draft<SalariesPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    onDeselectSalary: (state: Draft<SalariesPageState>, action: PayloadAction<SalariesApi.DeletedSalaryDTO>) => {
      if (isDefAndNotEmpty(state.deselectedSalaries)) {
        state.deselectedSalaries.push(action.payload)
      } else {
        state.deselectedSalaries = [action.payload]
      }
    },
    reset: () => initialState,
  },
})

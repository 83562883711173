import { api } from '@api/api'
import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { useEffect, useState } from 'react'
import { TillsApi } from '@api/types/tills-api.types'

import { Nullable } from '../../types/lang.types'

export const useOfflineTillShifts = (offlineTillId: Nullable<string>): [TillsApi.IOfflineTillShift[], boolean] => {
  // Constants
  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  // State
  const [isLoading, setIsLoading] = useState(false)
  const [offlineTillShifts, setOfflineTillsShifts] = useState<TillsApi.IOfflineTillShift[]>([])

  // Actions
  const updateOfflineTillShifts = async () => {
    if (!offlineTillId) return
    if (!studioId) return

    setIsLoading(true)
    const response = await api.tillsService.getOfflineTillShifts(offlineTillId, studioId)
    setOfflineTillsShifts(response?.data?.content ?? [])
    setIsLoading(false)
  }

  // Life cycle
  useEffect(() => {
    updateOfflineTillShifts()
  }, [offlineTillId])

  return [offlineTillShifts, isLoading]
}

import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { PositionsPageParams } from '../positions-page.types'
import { genPositionsPageParams } from '../positions-page.utils'

export function usePositionsPageParams(): PositionsPageParams {
  const { search } = useLocation()

  return React.useMemo(() => genPositionsPageParams(search), [search])
}

import { Radio } from 'antd'
import { MobileOutlined, DesktopOutlined } from '@ant-design/icons'

import { DeviceMode } from './master-services-widget-form-ui-device-mode.types'
import './master-services-widget-form-ui-device-mode.styles.less'

interface Props {
  mode: DeviceMode
  onChaneMode: (mode: DeviceMode) => void
}

export const MasterServicesWidgetFormUiDeviceMode: React.FC<Props> = props => {
  const { mode, onChaneMode } = props

  return (
    <div className="master-services-widget-form-ui__device-mode">
      <Radio.Group value={mode} onChange={e => onChaneMode(e.target.value)}>
        <Radio.Button value={DeviceMode.MOBILE}>
          <MobileOutlined />
        </Radio.Button>
        <Radio.Button value={DeviceMode.DESKTOP}>
          <DesktopOutlined />
        </Radio.Button>
      </Radio.Group>
    </div>
  )
}

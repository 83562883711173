import { put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { api } from '../../../api/api'
import { callApi } from '../../../utils/sagas.utils'
import { positionsEditPageActions } from './positions-edit-page.slice'
import { genPositionsPagePath } from '../../../format/path.format'
import { genGradeCreateDTO, genGradeUpdateDTO, genPositionDTO } from '../../../mapping/positions.mapping'

export function* fetchPageData(action: ReturnType<typeof positionsEditPageActions.fetchPageData>) {
  try {
    const response: Awaited<ReturnType<typeof api.positionsService.fetchPositionById>> = yield callApi(
      api.positionsService.fetchPositionById,
      action.payload
    )

    yield put(positionsEditPageActions.fetchPageDataSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* updatePosition(action: ReturnType<typeof positionsEditPageActions.updatePosition>) {
  try {
    const { id, data } = action.payload
    const positionDTO = genPositionDTO(data)

    yield callApi(api.positionsService.updatePosition, id, positionDTO)

    yield put(positionsEditPageActions.updatePositionSuccess())
    yield put(push(genPositionsPagePath()))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.updatePositionError(new Error()))
  }
}

export function* updateGrade(action: ReturnType<typeof positionsEditPageActions.updateGrade>) {
  try {
    const { positionId } = action.payload
    const { id, name } = action.payload.grade
    const gradeDTO = genGradeUpdateDTO(name)

    yield callApi(api.positionsService.updateGrade, id, gradeDTO)

    yield put(positionsEditPageActions.updateGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.updateGradeError(new Error()))
  }
}

export function* removeGrade(action: ReturnType<typeof positionsEditPageActions.removeGrade>) {
  try {
    const { positionId, gradeId } = action.payload
    yield callApi(api.positionsService.removeGrade, gradeId)

    yield put(positionsEditPageActions.removeGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.removeGradeError(new Error()))
  }
}

export function* createGrade(action: ReturnType<typeof positionsEditPageActions.createGrade>) {
  try {
    const { name, positionId, positionType } = action.payload
    const positionDTO = genGradeCreateDTO(name, positionId, positionType)

    yield callApi(api.positionsService.createGrade, positionDTO)

    yield put(positionsEditPageActions.createGradeSuccess())
    yield put(positionsEditPageActions.fetchPageData(positionId))
  } catch (e) {
    console.error(e)
    yield put(positionsEditPageActions.createGradeError(new Error()))
  }
}

export function* positionsEditPageSagas() {
  yield takeLatest(positionsEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(positionsEditPageActions.updatePosition, updatePosition)
  yield takeLatest(positionsEditPageActions.updateGrade, updateGrade)
  yield takeLatest(positionsEditPageActions.createGrade, createGrade)
  yield takeLatest(positionsEditPageActions.removeGrade, removeGrade)
}

import { RadioChangeEvent, Space, Radio, FormInstance } from 'antd'
import clsx from 'clsx'
import { useState } from 'react'

import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { ElementStates } from '../master-services-widget-form-design-settings.types'
import {
  genDescriptionFontStyles,
  genPriceFontStyles,
  genNameFontStyles,
} from './master-services-widget-form-design-settings-services.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export const MasterServicesWidgetFormDesignSettingsServices: React.FC<Props> = props => {
  const { form } = props

  const [fieldState, setFieldState] = useState(ElementStates.DEFAULT)

  const onChangeFieldState = (e: RadioChangeEvent): void => {
    setFieldState(e.target.value)
  }

  return (
    <MasterServicesWidgetFormUiCollapse title="Дизайн услуг">
      <Space direction="vertical" size="middle">
        <Radio.Group value={fieldState} onChange={onChangeFieldState} size="small">
          <Radio.Button value={ElementStates.DEFAULT}>Обычный</Radio.Button>
          <Radio.Button value={ElementStates.HOVER}>Наведение</Radio.Button>
          <Radio.Button value={ElementStates.ACTIVE}>Активный</Radio.Button>
        </Radio.Group>
        {getStrEnumValues<ElementStates>(ElementStates).map((state, index) => {
          const elementState = state.toLowerCase()
          return (
            <Space
              direction="vertical"
              key={index}
              size="middle"
              className={clsx({ 'master-services-widget-form-fields_hidden': state !== fieldState })}
            >
              <MasterServicesWidgetFormUiTypography
                form={form}
                title="Типографика описания"
                element={['design', 'serviceStyles', elementState, 'descriptionFontStyles']}
                initialValues={genDescriptionFontStyles(state)}
              />
              <MasterServicesWidgetFormUiTypography
                form={form}
                title="Типографика цены"
                element={['design', 'serviceStyles', elementState, 'priceFontStyles']}
                initialValues={genPriceFontStyles(state)}
              />
              <MasterServicesWidgetFormUiTypography
                form={form}
                title="Типографика названия"
                element={['design', 'serviceStyles', elementState, 'nameFontStyles']}
                initialValues={genNameFontStyles(state)}
              />
              <MasterServicesWidgetFormUiShadow
                form={form}
                element={['design', 'serviceStyles', elementState, 'shadow']}
              />
              <MasterServicesWidgetFormUiColor
                label="Цвет фона"
                name={['design', 'serviceStyles', elementState, 'backgroundColor']}
              />
              <MasterServicesWidgetFormUiBorderRadius
                element="serviceStyles"
                state={elementState}
                property="borderRadius"
              />
              <MasterServicesWidgetFormUiBorder element="serviceStyles" state={elementState} />
            </Space>
          )
        })}
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

import { Button, Form, Input, Row } from 'antd'

import { onKeyPressCheckNumber, onKeyPressCheckPhone } from '../utils/format'

export const ProviderForm = (props: any) => {
  const { form, onFinish } = props

  return (
    <Form form={form} autoComplete="off" layout="vertical" onFinish={onFinish}>
      <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название поставщика' }]}>
        <Input placeholder=""></Input>
      </Form.Item>
      <Form.Item
        label="ИНН"
        name="inn"
        rules={[{ required: true, message: 'Введите ИНН' }]}
        style={{ paddingTop: '8px' }}
      >
        <Input placeholder="" maxLength={13} onKeyDown={onKeyPressCheckNumber}></Input>
      </Form.Item>
      <Form.Item
        label="Номер телефона"
        name="phone"
        rules={[{ required: true, message: 'Введите номер телефона' }]}
        style={{ paddingTop: '8px' }}
      >
        <Input placeholder="" maxLength={12} onKeyDown={onKeyPressCheckPhone}></Input>
      </Form.Item>
      <Row justify="end">
        <Button htmlType="submit" style={{ marginTop: '12px' }} type="primary">
          Сохранить
        </Button>
      </Row>
    </Form>
  )
}

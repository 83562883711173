import { notificationsTills } from '@utils/notifications/notifications-tills.utils'
import { sortUniqOfflineTillsDTO } from '@utils/tills/sorted-offline-tills.utils'
import {
  transformOfflineTillsFromBDToOfflineTillsDTO,
  transformRowOfflineTillsToOfflineTillsDTO,
} from '@utils/tills/transform-tills.utils'
import { KkmAPI } from '@kkm-api/kkm'
import { api } from '@api/api'
import { TillsApi } from '@api/types/tills-api.types'

import { IFetchOfflineTillsInternalResult } from '../types/utils'

export const fetchOfflineTillsFromBD = async (studioId: string): Promise<IFetchOfflineTillsInternalResult> => {
  let result: IFetchOfflineTillsInternalResult = {
    hasError: false,
    offlineTills: [],
  }

  let hasError = false
  const response = await api.tillsService.getOffline(studioId).catch(err => {
    notificationsTills.fetchErrorFromBD(err.message)
    hasError = true
  })
  const offlineTillsFromBD = response?.data ?? []

  if (hasError) {
    result.hasError = true
    return result
  }
  if (offlineTillsFromBD.length === 0) {
    notificationsTills.notFoundInBD()
    return result
  }

  result.offlineTills = transformOfflineTillsFromBDToOfflineTillsDTO(offlineTillsFromBD)
  return result
}

const fetchOfflineTillsFromDevice = async (): Promise<IFetchOfflineTillsInternalResult> => {
  let result: IFetchOfflineTillsInternalResult = {
    hasError: false,
    offlineTills: [],
  }

  let hasError = false
  const response = await KkmAPI.getRowOfflineTills().catch(err => {
    hasError = true
  })
  const rowOfflineTills = response?.ListUnit ?? []

  if (hasError) {
    notificationsTills.fetchErrorOnPC()
    result.hasError = true
    return result
  }
  if (rowOfflineTills.length === 0) {
    notificationsTills.notFoundOnPC()
    return result
  }

  result.offlineTills = transformRowOfflineTillsToOfflineTillsDTO(rowOfflineTills.filter(till => till.KktNumber))
  return result
}

export const fetchTills = async (
  setTills: (tills: TillsApi.OfflineTillDTO[]) => void,
  setIsUpdating: (status: boolean) => void,
  studioId: string,
  withoutNotification?: boolean
) => {
  setIsUpdating(true)
  const offlineTillsFromBD = await fetchOfflineTillsFromBD(studioId)
  const offlineTillsFromDevice = await fetchOfflineTillsFromDevice()

  setTills(sortUniqOfflineTillsDTO([...offlineTillsFromBD.offlineTills, ...offlineTillsFromDevice.offlineTills]))

  if (!withoutNotification) {
    if (!offlineTillsFromBD.hasError && !offlineTillsFromDevice.hasError) {
      notificationsTills.fetched()
    }
  }

  setIsUpdating(false)
}

export const saveTills = async (offlineTills: TillsApi.OfflineTillDTO[], studioId: string) => {
  let hasError = false

  const changedOfflineTills = offlineTills.filter(till => till.isChanged)
  await api.tillsService.createOffline(studioId, changedOfflineTills).catch(err => {
    console.error(err)
    hasError = true
  })

  if (hasError) {
    notificationsTills.saveError()
    return
  }

  notificationsTills.saved()
}

import { Button, Divider, Form, InputNumber, Select, Space } from 'antd'
import { useState } from 'react'

interface Props {
  label: string
  element: string[]
}

export const MasterServicesWidgetFormUiSize: React.FC<Props> = props => {
  const { label, element } = props

  const [options, setOptions] = useState([
    { label: 'авто', value: 'auto' },
    { label: '600px', value: '600px' },
  ])
  const [name, setName] = useState<string | number | null>(null)

  const onNameChange = (value: number | string | null) => {
    setName(value)
  }

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault()
    setOptions([...options, { label: `${name}px`, value: `${name}px` }])
    setName(null)
  }

  return (
    <Form.Item name={element} label={label}>
      <Select
        placeholder="Выбрать"
        optionLabelProp="label"
        dropdownRender={menu => (
          <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
              <InputNumber
                placeholder="Введите значение"
                addonAfter="px"
                value={name}
                onChange={onNameChange}
                style={{ width: '120px' }}
              />
              <Button onClick={addItem} disabled={!name}>
                Добавить
              </Button>
            </Space>
          </>
        )}
        options={options}
      />
    </Form.Item>
  )
}

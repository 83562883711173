import React from 'react'

import {
  genSalariesFormDirectionsValidationRules,
  genSalariesFormExerciseTypesValidationRules,
  genSalariesFormGradesValidationRules,
  genSalariesFormStudiosValidationRules,
  genSalariesFormRateValidationRules,
} from './salaries-table-utils'

export function useSalariesTable() {
  const gradesValidationRules = React.useMemo(genSalariesFormGradesValidationRules, [])
  const studiosValidationRules = React.useMemo(genSalariesFormStudiosValidationRules, [])
  const directionsValidationRules = React.useMemo(genSalariesFormDirectionsValidationRules, [])
  const exerciseTypesValidationRules = React.useMemo(genSalariesFormExerciseTypesValidationRules, [])
  const rateValidationRules = React.useMemo(genSalariesFormRateValidationRules, [])

  return {
    gradesValidationRules,
    studiosValidationRules,
    directionsValidationRules,
    exerciseTypesValidationRules,
    rateValidationRules,
  }
}

import { SearchOutlined } from '@ant-design/icons'
import { Form, Flex, Button, FormInstance } from 'antd'

interface Props {
  form: FormInstance<any>
  onSubmit: () => void
  onCancel: () => void
}
export const TransactionsTableSearch: React.FC<React.PropsWithChildren<Props>> = props => {
  const { children } = props
  const { form, onSubmit, onCancel } = props

  return (
    <Form form={form}>
      <div style={{ padding: 8 }}>
        <Flex vertical gap={8}>
          {children}
          <Button
            htmlType="button"
            type="primary"
            onClick={onSubmit}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: '100%' }}
          >
            Поиск
          </Button>
          <Button htmlType="button" onClick={onCancel} size="small" style={{ width: '100%' }}>
            Сбросить
          </Button>
        </Flex>
      </div>
    </Form>
  )
}

import { PositionsEditForm } from '../../../components/positions/positions-form/positions-edit-form/positions-edit-form.component'
import { usePositionsEditPageForm } from './positions-edit-page-form.hook'

export const PositionsEditPageForm = () => {
  const {
    form,
    isLoading,
    positionGrades,
    onUpdateGradeHandler,
    onCreateGradeHandler,
    onRemoveGradeHandler,
    onFinishHandler,
  } = usePositionsEditPageForm()

  return (
    <PositionsEditForm
      form={form}
      isLoading={isLoading}
      positionGrades={positionGrades}
      onUpdateGrade={onUpdateGradeHandler}
      onCreateGrade={onCreateGradeHandler}
      onRemoveGrade={onRemoveGradeHandler}
      onFinish={onFinishHandler}
    />
  )
}

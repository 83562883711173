import { ITerminalDTO } from '@api/types/terminals-api.types'
import { terminalEntityActions } from '@store/common/entity/terminal/terminal.slice'
import { modalActions } from '@store/common/modal/modal.slice'
import { Form } from 'antd'
import { useDispatch } from 'react-redux'

export function useFranchisesModalTerminalCreateForm() {
  const [form] = Form.useForm()
  const titleText = 'Создание нового интернет-эквайринга'
  const btnTitle = 'Создать'

  const dispatch = useDispatch()
  const onCancel = (): void => {
    dispatch(modalActions.closeLast())
  }
  const onFinish = (values: ITerminalDTO): void => {
    dispatch(terminalEntityActions.createTerminal(values))
    onCancel()
  }

  return { form, titleText, onFinish, onCancel, btnTitle }
}

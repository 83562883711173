import { FC } from 'react'
import { clsx } from 'clsx'

import { ICircleProps } from './circle.types'
import { FIGURE_DIRECTION, FIGURE_MODE } from '../../../constants/figure'

import './circle.styles.less'

export const Circle: FC<ICircleProps> = ({
  color = 'black',
  size = 'large',
  mode = FIGURE_MODE.FULL,
  direction = FIGURE_DIRECTION.LEFT,
}) => {
  const circleSize = size === 'large' ? 26 : 18
  const circleRadius = circleSize / 2
  const halfCircleWidth = size === 'large' ? 13 : 9

  if (mode === FIGURE_MODE.HALF) {
    return (
      <svg
        height={circleSize}
        width={halfCircleWidth}
        className={clsx('half-mask', {
          'half-circle--left': direction === FIGURE_DIRECTION.LEFT,
          'half-circle--right': direction === FIGURE_DIRECTION.RIGHT,
        })}
      >
        <path
          d={`M${halfCircleWidth} 0 A${halfCircleWidth} ${halfCircleWidth} 0 0 0 ${halfCircleWidth} ${circleSize}`}
          fill={color}
        />
      </svg>
    )
  } else {
    return (
      <svg height={circleSize} width={circleSize}>
        <circle cx={circleRadius} cy={circleRadius} r={circleRadius} fill={color} />
      </svg>
    )
  }
}

import { TransactionsHistoryPeek } from '../../../components/transactions/transactions-history-peek/transactions-history-peek.component'
import { useTransactionsPagePeekHistory } from './transactions-page-peek-history.hook'
import { TransactionsPagePeekHistoryProps } from './transactions-page-peek-history.types'

export const TransactionsPagePeekHistory: React.FC<TransactionsPagePeekHistoryProps> = props => {
  const { id } = props

  const { transactionHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useTransactionsPagePeekHistory(id)

  return (
    <TransactionsHistoryPeek
      transactionHistory={transactionHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

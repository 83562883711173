import { ProductServicesForm } from '../../../components/products/products-services-form/product-services-form.component'
import { useProductServicesCreatePageForm } from './products-services-create-page.hook'

export const ProductServicesCreatePageForm = () => {
  const { form, onFinishHandler, studiosOptions, exercisesOptions } = useProductServicesCreatePageForm()

  return (
    <ProductServicesForm
      disabledFormat={false}
      studiosOptions={studiosOptions}
      // @ts-ignore
      exercisesOptions={exercisesOptions}
      form={form}
      submitText="Создать"
      onFinish={onFinishHandler}
    />
  )
}

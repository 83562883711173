import { DefaultOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'

import { Days } from '../../api/types/api.types'
import { AuditApi } from '../../api/types/audit.types'
import { EMPLOYEE_SCHEDULE_STATUS } from '../../constants/employees/schedule'
import {
  formatDate,
  formatIsoStringToDays,
  formatIsoStringTOMinutes,
  formatMinutesToHours,
} from '../../format/date.format'
import { formatRublesPenniesCurrency } from '../../format/number.format'
import { formatPhoneNumber } from '../../format/phone.format'
import {
  DEFAULT_EMPTY_SYMBOL,
  formatAdvanceSettlementReceiptStatus,
  formatBookingStatus,
  formatBooleanValue,
  formatClientSex,
  formatDays,
  formatEmployeeScheduleStatus,
  formatPaymentType,
  formatTimeLimitation,
  formatTransactionStatus,
  formatUserActionSource,
} from '../../format/text.format'
import { AuditAction } from '../../types/audit.types'
import { BookingStatus } from '../../types/bookings.types'
import { ClientSex } from '../../types/clients.types'
import { isDef, isDefAndNotEmpty, NNumber, NString } from '../../types/lang.types'
import { PaymentType } from '../../types/payment.types'
import { AdvanceSettlementReceiptStatus } from '../../types/receipt.types'
import { TimeLimitation } from '../../types/time-limitation.types'
import { TransactionStatus } from '../../types/transactions.types'
import { UserActionSource } from '../../types/users.types'
import { HistoryListType } from './history-list.types'

export function formatAuditAction(action: AuditAction, subjectType: string): string {
  switch (action) {
    case AuditAction.ADD:
      switch (subjectType) {
        case 'SERVER':
          return 'добавила'
        case 'CLIENT':
          return 'добавилcя в запись'
        default:
          return 'добавил(а)'
      }
    case AuditAction.CREATE:
      switch (subjectType) {
        case 'SERVER':
          return 'создала'
        default:
          return 'создал(а)'
      }
    case AuditAction.DELETE:
      switch (subjectType) {
        case 'SERVER':
          return 'удалила'
        default:
          return 'удалил(а)'
      }
    case AuditAction.REMOVE:
      switch (subjectType) {
        case 'SERVER':
          return 'отменила'
        case 'CLIENT':
          return 'отменил запись'
        default:
          return 'отменил(а)'
      }
    case AuditAction.UPDATE:
      switch (subjectType) {
        case 'SERVER':
          return 'изменила'
        default:
          return 'изменил(а)'
      }
  }
}

export function formatHistoryListItemParameterObjectType(type: string) {
  switch (type) {
    case 'TRAINER':
      return 'исполнителя'
    case 'BOOKING':
      return 'клиента'
    default:
      return
  }
}

function getStudioName(studioId?: NString, studios?: DefaultOptionType[]) {
  return studios?.find(studio => studio.value === studioId)?.label
}

function getRoomNames(roomIds?: string, rooms?: DefaultOptionType[]) {
  const idsArray = roomIds?.split(',').map(id => id.trim())

  if (isDefAndNotEmpty(idsArray) && isDefAndNotEmpty(rooms)) {
    const labels = rooms.filter(room => idsArray.includes(String(room.value))).map(room => room.label)

    return labels.join(', ')
  }
}

function formatDefaultValue(
  change: AuditApi.ChangeableValue,
  studioOffset: NNumber,
  studios?: DefaultOptionType[],
  rooms?: DefaultOptionType[]
) {
  switch (change.type) {
    case 'time': {
      const timezoneOffset = studioOffset ?? 0

      return dayjs
        .utc(change.time)
        .tz(`Etc/GMT${timezoneOffset > 0 ? '-' : '+'}${Math.abs(timezoneOffset)}`)
        .format('HH:mm DD MMMM')
    }
    case 'boolean':
      return formatBooleanValue(Boolean(change.flag))
    case 'named':
      return change.displayName
    case 'timetable':
      return `${change.fromTime} - ${change.toTime}`
    case 'schedule':
      const studio = getStudioName(change.studioId, studios)
      const roomNames = getRoomNames(change.roomIds, rooms)

      return `${change.fromTime} - ${change.toTime} ${studio ? `в студии ${studio}` : ''} ${
        roomNames ? `в залах ${roomNames}` : ''
      }`
    default:
      if (isDef(change.value) && change.value.length > 0 && change.value !== 'null') {
        return change.value
      }
      return DEFAULT_EMPTY_SYMBOL
  }
}

function formatExerciseValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'cost':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'paymentType':
      return formatPaymentType(change.value as PaymentType)
    case 'bookingStatus':
      return formatBookingStatus(change.value as BookingStatus)
    case 'userActionSource':
      return formatUserActionSource(change.value as UserActionSource)
    case 'advanceSettlementReceiptStatus':
      return formatAdvanceSettlementReceiptStatus(change.value as AdvanceSettlementReceiptStatus)
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatTimetableValue(
  field: string,
  change: AuditApi.ChangeableValue,
  studioOffset: NNumber,
  trainers?: DefaultOptionType[]
) {
  switch (field) {
    default:
      const parts = field.split('.')
      if (parts[0] === 'timeslots') {
        if (parts[2] === 'trainers') {
          return trainers?.find(trainer => trainer.value === change.value)?.label
        }
      }

      return formatDefaultValue(change, studioOffset)
  }
}

function formatMasterServiceValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'duration':
      return change.value !== 'null' ? formatIsoStringTOMinutes(String(change.value)) : DEFAULT_EMPTY_SYMBOL
    case 'restrictionMinutesDuration':
      return change.value !== 'null' ? formatMinutesToHours(Number(change.value)) : DEFAULT_EMPTY_SYMBOL
    case 'technicalBreakAfterDuration':
      return change.value !== 'null' ? formatIsoStringTOMinutes(String(change.value)) : DEFAULT_EMPTY_SYMBOL
    case 'technicalBreakBeforeDuration':
      return change.value !== 'null' ? formatIsoStringTOMinutes(String(change.value)) : DEFAULT_EMPTY_SYMBOL
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatTransactionValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'sum':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'discount':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'deposit':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'toPay':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'refundCommission':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'refundSum':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'status':
      return formatTransactionStatus(change.value as TransactionStatus)
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatClientValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'deposit':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'birthDate':
      return formatDate(String(change.time), 'DD.MM.YYYY', 'YYYY-MM-DD')
    case 'sex':
      return formatClientSex(change.value as ClientSex)
    case 'phone':
      return formatPhoneNumber(`+${change.value?.replace(/\D/g, '')}`)
    default:
      return formatExerciseValue(field, change, studioOffset)
  }
}

function formatEmployeeValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'birthDate':
      return formatDate(String(change.time), 'DD.MM.YYYY', 'YYYY-MM-DD')
    case 'sex':
      return formatClientSex(change.value as ClientSex)
    case 'phone':
      return formatPhoneNumber(`+${change.value?.replace(/\D/g, '')}`)
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatStudioValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatOneTimeProductValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'cost':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'trialCost':
      return formatRublesPenniesCurrency(Number(change.value))
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatSubscriptionProductValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'cost':
      return formatRublesPenniesCurrency(Number(change.value))
    case 'timeLimitation':
      return formatTimeLimitation(change.value as TimeLimitation)
    case 'minutes':
      return formatMinutesToHours(Number(change.value))
    case 'duration':
      return formatIsoStringToDays(String(change.value))
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatGoodsValue(field: string, change: AuditApi.ChangeableValue, studioOffset: NNumber) {
  switch (field) {
    case 'cost':
      return formatRublesPenniesCurrency(Number(change.value))
    default:
      return formatDefaultValue(change, studioOffset)
  }
}

function formatScheduleValue(
  field: string,
  change: AuditApi.ChangeableValue,
  studioOffset: NNumber,
  studios?: DefaultOptionType[],
  rooms?: DefaultOptionType[]
) {
  const formatValidTime = () => {
    const timezoneOffset = studioOffset ?? 0
    return dayjs
      .utc(change.time)
      .tz(`Etc/GMT${timezoneOffset > 0 ? '-' : '+'}${Math.abs(timezoneOffset)}`)
      .format('DD.MM.YYYY')
  }

  switch (field) {
    case 'status':
      return formatEmployeeScheduleStatus(change.value as EMPLOYEE_SCHEDULE_STATUS)
    case 'validFrom': {
      return formatValidTime()
    }
    case 'validTo': {
      return formatValidTime()
    }
    default:
      const parts = field.split('.')
      if (parts[0] === 'slots') {
        if (parts[2] === 'roomIds') {
          return getRoomNames(change.value, rooms)
        } else if (parts[2] === 'studioId') {
          return getStudioName(change.value, studios)
        }
      }
      return formatDefaultValue(change, studioOffset, studios, rooms)
  }
}

export function formatHistoryListItemChangesValue(
  field: string,
  type: HistoryListType,
  change: AuditApi.ChangeableValue,
  studioOffset: NNumber,
  studios?: DefaultOptionType[],
  rooms?: DefaultOptionType[],
  trainers?: DefaultOptionType[]
) {
  switch (type) {
    case HistoryListType.EXERCISE:
      return formatExerciseValue(field, change, studioOffset)
    case HistoryListType.TIMETABLE:
      return formatTimetableValue(field, change, studioOffset, trainers)
    case HistoryListType.MULTIBOOKING:
      return formatTimetableValue(field, change, studioOffset, trainers)
    case HistoryListType.MASTER_SERVICE:
      return formatMasterServiceValue(field, change, studioOffset)
    case HistoryListType.TRANSACTION:
      return formatTransactionValue(field, change, studioOffset)
    case HistoryListType.CLIENT:
      return formatClientValue(field, change, studioOffset)
    case HistoryListType.EMPLOYEE:
      return formatEmployeeValue(field, change, studioOffset)
    case HistoryListType.STUDIO:
      return formatStudioValue(field, change, studioOffset)
    case HistoryListType.ONE_TIME_PRODUCT:
      return formatOneTimeProductValue(field, change, studioOffset)
    case HistoryListType.SUBSCRIPTION_PRODUCT:
      return formatSubscriptionProductValue(field, change, studioOffset)
    case HistoryListType.GOODS:
      return formatGoodsValue(field, change, studioOffset)
    case HistoryListType.SCHEDULE:
      return formatScheduleValue(field, change, studioOffset, studios, rooms)
  }
}

function formatExerciseFieldName(field: string) {
  switch (field) {
    case 'direction':
      return 'направление'
    case 'type':
      return 'тип'
    case 'activeBookings':
      return 'активные бронирования'
    case 'visitsConfirmed':
      return 'подтверждение визита'
    case 'timetable':
      return 'расписание'
    case 'booking':
      return 'бронирования'
    case 'waitList':
      return 'лист ожидания'
    case 'trainers':
      return 'исполнителей'
    case 'maxClientsCount':
      return 'вместимость'
    case 'linkedTechnicalBreaks':
      return 'связанные технические перерывы'
    case 'timeFrom':
      return 'время начала'
    case 'timeTo':
      return 'время окончания'
    case 'room':
      return 'зал'
    case 'studio':
      return 'студию'
    case 'organization':
      return 'организацию'
    case 'cancellationReason':
      return 'причину отмены'
    case 'cancellationDate':
      return 'дату отмены'
    case 'comment':
      return 'комментарий'
    case 'cost':
      return 'стоимость'
    case 'oneTime':
      return 'услугу'
    case 'subscription':
      return 'абонемент'
    case 'paymentType':
      return 'способ оплаты'
    case 'transaction':
      return 'транзакцию'
    case 'bookingStatus':
      return 'статус бронирования'
    case 'notVisitedStatusChangeDate':
      return 'дату имзенения статуса "Не посещено"'
    case 'operator':
      return 'администратора'
    case 'userActionSource':
      return 'источник действия пользователя'
    case 'visitConfirmationDate':
      return 'дату подтверждения визита'
    case 'visitConfirmed':
      return 'подтверждение визита'
    case 'advanceSettlementReceiptStatus':
      return 'действие по отправке чека'
    case 'description':
      return 'описание'
    case 'exerciseType':
      return 'тип записи'
    case 'receiptSendingAttempt':
      return 'попытка отправки чека'
    case 'isCancelled':
      return 'отменено'
    default:
      return field
  }
}

function formatTimetableFieldName(field: string): string {
  switch (field) {
    case 'direction':
      return 'направление'
    case 'type':
      return 'тип'
    case 'dateFrom':
      return 'дату начала'
    case 'dateTo':
      return 'дату окончания'
    case 'timeslots':
      return 'временные слоты'
    case 'room':
      return 'зал'
    case 'studio':
      return 'студию'
    case 'organization':
      return 'организацию'
    case 'trainers':
      return 'исполнителей'
    case 'comment':
      return 'комментарий'
    case 'bookClient':
      return 'клиента'
    case 'bookingClients':
      return 'клиента'
    case 'maxClientsCount':
      return 'вместимость'
    case 'canceled':
      return 'отменено'
    default:
      const parts = field.split('.')
      if (parts[0] === 'timeslots') {
        const dayValue = parts[1] as keyof typeof Days
        if (dayValue in Days) {
          const dayName = formatDays(dayValue as Days)
          const additionalField = parts[2] ? formatTimetableFieldName(parts[2]) : ''
          return `временные слоты в ${dayName} ${additionalField.trim()}`
        }
      }
      return field
  }
}

function formatTransactionFieldName(field: string) {
  switch (field) {
    case 'createDate':
      return 'дату создания'
    case 'paymentDate':
      return 'дату платежа'
    case 'paymentDueDate':
      return 'дату платежа'
    case 'client':
      return 'клиента'
    case 'products':
      return 'товар'
    case 'sum':
      return 'сумму'
    case 'toPay':
      return 'к оплате'
    case 'discount':
      return 'скидку'
    case 'discountReason':
      return 'причину скидки'
    case 'deposit':
      return 'депозит'
    case 'paymentMethod':
      return 'метод оплаты'
    case 'status':
      return 'статус'
    case 'studio':
      return 'студию'
    case 'paymentStudio':
      return 'студию оплаты'
    case 'organization':
      return 'организацию'
    case 'cardPayment':
      return 'оплату картой'
    case 'cardRefund':
      return 'возврат'
    case 'receipts':
      return 'чек'
    case 'seller':
      return 'сотрудника оформившего продажу'
    case 'refunder':
      return 'сотрудника оформившего возврат'
    case 'withCommission':
      return 'с комиссией'
    case 'productType':
      return 'тип продукта'
    case 'refundCommission':
      return 'комиссию за возврат'
    case 'sellCommission':
      return 'комиссию за продажу'
    case 'refundDate':
      return 'дату возврата'
    case 'refundSum':
      return 'сумму возврата'
    case 'onlineTill':
      return 'онлайн кассу'
    case 'offlineTill':
      return 'офлайн кассу'
    case 'offlineTillShift':
      return 'смену'
    case 'refundOfflineTillShift':
      return 'смену'
    case 'terminal':
      return 'терминал'
    case 'purchasedServices':
      return 'приобретенные услуги'
    default:
      return field
  }
}

function formatMasterServiceFieldName(field: string) {
  switch (field) {
    case 'name':
      return 'название'
    case 'direction':
      return 'направление'
    case 'maxClientsCount':
      return 'вместимость'
    case 'exerciseType':
      return 'категорию записи'
    case 'timeStep':
      return 'длину одного слота для записи'
    case 'timeStepMinutes':
      return 'длину одного слота для записи (мин.)'
    case 'minMinutesForBooking':
      return 'минимальную длительность бронирования (мин.)'
    case 'serviceCategories':
      return 'категорию услуг'
    case 'subServices':
      return 'подуслугу'
    case 'hasBookingTimeRestriction':
      return 'ограничение по времени бронирования'
    case 'restrictionStartTime':
      return 'ограничение по времени бронирования (начало)'
    case 'restrictionMinutesDuration':
      return 'ограничение по времени бронирования (длительность в часах)'
    case 'onlinePaymentEnabled':
      return 'онлайн платеж'
    case 'roomRentEnabled':
      return 'бронирование залов'
    case 'description':
      return 'описание'
    case 'needTrainer':
      return 'выбор исполнителя обязателен'
    case 'hasTrainerLimitation':
      return 'имеет ограничение исполнителей'
    case 'trainers':
      return 'исполнителей'
    case 'availableStudios':
      return 'студии'
    case 'availableRooms':
      return 'залы'
    case 'serviceCategory':
      return 'категорию'
    case 'exerciseDirection':
      return 'направление'
    case 'allowsContinuousBooking':
      return 'бронирование нескольких слотов подряд'
    case 'allowsMultiBooking':
      return 'длинную запись'
    case 'hideInWidget':
      return 'для внутреннего использования'
    case 'productType':
      return 'тип'
    case 'cost':
      return 'стоимость'
    case 'showToUser':
      return 'показывать пользователям'
    case 'photo':
      return 'фото'
    case 'technicalBreakAfterDuration':
      return 'технический перерыв после брони (мин.)'
    case 'technicalBreakBeforeDuration':
      return 'технический перерыв до брони (мин.)'
    case 'pricingPlans':
      return 'ценообразование'
    case 'activeFrom':
      return 'дату начала'
    case 'duration':
      return 'длительность (мин.)'
    default:
      return field
  }
}

function formatClientFieldName(field: string) {
  switch (field) {
    case 'firstName':
      return 'имя'
    case 'lastName':
      return 'фамилию'
    case 'middleName':
      return 'отчество'
    case 'sex':
      return 'пол'
    case 'photo':
      return 'фото'
    case 'phone':
      return 'номер телефона'
    case 'birthDate':
      return 'дату рождения'
    case 'deposit':
      return 'депозит'
    case 'source':
      return 'источник'
    case 'loyaltyCard':
      return 'карту лояльности'
    case 'registrationDate':
      return 'дату регистрации'
    case 'lastLogin':
      return 'последний вход'
    case 'lastVisit':
      return 'последний визит'
    case 'comment':
      return 'комментарий'
    case 'clientCategory':
      return 'категорию'
    case 'subscriptions':
      return 'абонементы'
    case 'onesignalSubscriptions':
      return 'абонементы'
    case 'trialUsed':
      return 'пробное посещение'
    case 'manager':
      return 'менеджера'
    default:
      return formatExerciseFieldName(field)
  }
}

function formatEmployeeFieldName(field: string) {
  switch (field) {
    case 'firstName':
      return 'имя'
    case 'lastName':
      return 'фамилию'
    case 'middleName':
      return 'отчество'
    case 'sex':
      return 'пол'
    case 'photo':
      return 'фото'
    case 'phone':
      return 'номер телефона'
    case 'birthDate':
      return 'дату рождения'
    case 'position':
      return 'должность'
    case 'organization':
      return 'организацию'
    case 'studios':
      return 'студии'
    case 'inn':
      return 'ИНН'
    case 'bio':
      return 'биографию'
    default:
      return field
  }
}

function formatStudioFieldName(field: string) {
  switch (field) {
    case 'name':
      return 'название'
    case 'country':
      return 'страну'
    case 'city':
      return 'город'
    case 'address':
      return 'адрес'
    case 'description':
      return 'описание'
    case 'locationDescription':
      return 'как найти студию'
    case 'locationMapUrl':
      return 'ссылку на точку в картах'
    case 'locationPhotoUrl':
      return 'схему проезда'
    case 'schedule':
      return 'расписание'
    case 'mainPhoto':
      return 'фото'
    case 'organization':
      return 'организаию'
    case 'rooms':
      return 'зал'
    case 'directions':
      return 'направление'
    case 'directionsCount':
      return 'количество направлений'
    case 'photos':
      return 'фото'
    case 'amenities':
      return 'сервис студии'
    case 'workTimeRange':
      return 'время работы'
    case 'workTime':
      return 'время работы'
    case 'offset':
      return 'часовой пояс'
    case 'minRate':
      return 'мин. ставку'
    case 'terminal':
      return 'терминал'
    case 'girlsOnly':
      return 'только для девушек'
    case 'isMain':
      return 'главная студия'
    case 'offlineTills':
      return 'офлайн-кассы'
    case 'showToUser':
      return 'показывать пользователям'
    case 'onlinePaymentEnabled':
      return 'онлайн оплаты'
    case 'receiptsEnabled':
      return 'чеки включены'

    default:
      const parts = field.split('.')
      if (parts[0] === 'workTime') {
        const dayValue = parts[1] as keyof typeof Days
        if (dayValue in Days) {
          const dayName = formatDays(dayValue as Days)
          return `время работы в ${dayName}`
        }
      }
      return field
  }
}

function formatOneTimeProductFieldName(field: string) {
  switch (field) {
    case 'trialCost':
      return 'стоимость пробного'
    case 'hasStudioLimitation':
      return 'ограничение по студиям'
    case 'availableStudios':
      return 'студии'
    case 'hasDirectionLimitation':
      return 'ограничение по направлениям'
    case 'availableDirections':
      return 'направления'
    case 'hasTypeLimitation':
      return 'ограничение по категориям записей'
    case 'availableTypes':
      return 'категории записей'
    case 'canBindToBooking':
      return 'можно привязать к бронированию'
    case 'shouldBindToBooking':
      return 'следует привязать к бронированию'
    case 'canPayForBooking':
      return 'можно оплатить бронирование'
    case 'description':
      return 'описание'
    case 'productType':
      return 'тип'
    case 'name':
      return 'название'
    case 'cost':
      return 'стоимость'
    case 'showToUser':
      return 'показывать пользователям'
    case 'photo':
      return 'фото'
    default:
      return field
  }
}

function formatSubscriptionProductFieldName(field: string) {
  switch (field) {
    case 'daysUntilActivation':
      return 'количество дней до активации'
    case 'daysUntilExpiration':
      return 'срок действия (дн.)'
    case 'availableFreezeDays':
      return 'количество дней заморозки'
    case 'hasStudioLimitations':
      return 'ограничение по студиям'
    case 'availableStudios':
      return 'студии'
    case 'hasExerciseTypeLimitations':
      return 'ограничение по категориям записей'
    case 'availableExerciseTypes':
      return 'категории записей'
    case 'hasExerciseDirectionLimitations':
      return 'ограничение по направлениям'
    case 'availableExerciseDirections':
      return 'направления'
    case 'visits':
      return 'количество записей'
    case 'timeLimitation':
      return 'ограничение по времени'
    case 'minutes':
      return 'количество часов'
    case 'duration':
      return 'длительность (дн.)'
    case 'showToUser':
      return 'показывать пользователям'
    case 'photo':
      return 'фото'
    case 'productType':
      return 'тип'
    case 'name':
      return 'название'
    case 'cost':
      return 'стоимость'
    default:
      return field
  }
}

function formatGoodsFieldName(field: string): string {
  switch (field) {
    case 'category':
      return 'категорию'
    case 'consignments':
      return 'партию'
    case 'hasMarkCode':
      return 'маркировочный товар'
    case 'productType':
      return 'тип'
    case 'name':
      return 'название'
    case 'cost':
      return 'стоимость'
    case 'showToUser':
      return 'показывать пользователям'
    case 'photo':
      return 'фото'
    default:
      return field
  }
}

function formatScheduleFieldName(field: string): string {
  switch (field) {
    case 'employee':
      return 'сотрудника'
    case 'employeePosition':
      return 'должность'
    case 'status':
      return 'статус'
    case 'validFrom':
      return 'дату начала действия'
    case 'validTo':
      return 'дату окончания действия'
    case 'slots':
      return 'график'
    case 'hasRoomLimitation':
      return 'ограничение по залам'
    case 'hasStudioLimitation':
      return 'ограничение по студиям'
    case 'roomIds':
      return 'залы'
    case 'studioId':
      return 'студию'
    default:
      const parts = field.split('.')
      if (parts[0] === 'slots') {
        const dayValue = parts[1] as keyof typeof Days
        if (dayValue in Days) {
          const dayName = formatDays(dayValue as Days)
          const additionalField = parts[2] ? formatScheduleFieldName(parts[2]) : ''
          return `график в ${dayName} ${additionalField.trim()}`
        }
      }
      return field
  }
}

export function formatHistoryListItemChangesFieldName(field: string, type: HistoryListType) {
  switch (type) {
    case HistoryListType.EXERCISE:
      return formatExerciseFieldName(field)
    case HistoryListType.TIMETABLE:
      return formatTimetableFieldName(field)
    case HistoryListType.MULTIBOOKING:
      return formatTimetableFieldName(field)
    case HistoryListType.MASTER_SERVICE:
      return formatMasterServiceFieldName(field)
    case HistoryListType.TRANSACTION:
      return formatTransactionFieldName(field)
    case HistoryListType.CLIENT:
      return formatClientFieldName(field)
    case HistoryListType.EMPLOYEE:
      return formatEmployeeFieldName(field)
    case HistoryListType.STUDIO:
      return formatStudioFieldName(field)
    case HistoryListType.ONE_TIME_PRODUCT:
      return formatOneTimeProductFieldName(field)
    case HistoryListType.SUBSCRIPTION_PRODUCT:
      return formatSubscriptionProductFieldName(field)
    case HistoryListType.GOODS:
      return formatGoodsFieldName(field)
    case HistoryListType.SCHEDULE:
      return formatScheduleFieldName(field)
  }
}

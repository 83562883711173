import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { PositionsApi } from '../../../api/types/positions-api.types'
import {
  PositionsEditPageCreateGradePayload,
  PositionsEditPageRemoveGradePayload,
  PositionsEditPageUpdateGradePayload,
  PositionsEditPageUpdatePositionPayload,
} from './positions-edit-page.types'

export interface PositionsEditPageState {
  position: Nullable<PositionsApi.Position>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: PositionsEditPageState = {
  position: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: positionsEditPageActions, reducer: positionsEditPageReducer } = createSlice({
  name: '@@positions-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<PositionsEditPageState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<PositionsEditPageState>,
      action: PayloadAction<Nullable<PositionsApi.Position>>
    ) => {
      state.position = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updatePosition: (
      state: Draft<PositionsEditPageState>,
      _: PayloadAction<PositionsEditPageUpdatePositionPayload>
    ) => {
      state.isLoading = true
    },
    updatePositionSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    updatePositionError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageUpdateGradePayload>) => {
      state.isLoading = true
    },
    updateGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    updateGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    createGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageCreateGradePayload>) => {
      state.isLoading = true
    },
    createGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    createGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    removeGrade: (state: Draft<PositionsEditPageState>, _: PayloadAction<PositionsEditPageRemoveGradePayload>) => {
      state.isLoading = true
    },
    removeGradeSuccess: (state: Draft<PositionsEditPageState>) => {
      state.isLoading = false
    },
    removeGradeError: (state: Draft<PositionsEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import * as React from 'react'
import { Form } from 'antd'
import { mapEmployeesToEmployeesAutocompleteOptionDataItems } from '@mapping/employees.mapping'
import { AxiosResponse } from 'axios'
import { SearchApi } from '@api/types/search-api.types'

import { api } from '../../api/api'
import { isDef, Nullable } from '../../types/lang.types'
import { ClientsAutocompleteOptionDataItem } from '../../components/clients/clients-autocomplete/clients-autocomplete-option/clients-autocomplete-option.types'
import { EmployeesAutocompleteProps } from './employees-autocomplete.types'
import { useTransactionsCreatePageTable } from '../../pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.hook'

export function useEmployeesAutocomplete<FormValues>(props: EmployeesAutocompleteProps<FormValues>) {
  const { form, name, transactionsForm, onChange, setEmployeeId } = props

  const { onChangeTrialHandler } = useTransactionsCreatePageTable()

  const value = Form.useWatch(name, form)

  const [employees, setEmployees] = React.useState<Nullable<SearchApi.SearchItem[]>>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect((): void => {
    if (employees?.length === 0 && transactionsForm) {
      onChangeTrialHandler(null)
    }
  }, [employees])

  const options = React.useMemo(() => mapEmployeesToEmployeesAutocompleteOptionDataItems(employees), [employees])

  const onChangeHandler = React.useCallback(
    (value?: string): void => {
      if (onChange) onChange(value)
      form.setFieldValue(name, value)
    },
    [form, name, onChange]
  )

  const onSelectHandler = React.useCallback(
    (option: Nullable<ClientsAutocompleteOptionDataItem>): void => {
      if (isDef(option)) {
        form.setFieldValue(name, option.label)

        form.setFieldValue('employeeId', option.slug)

        if (setEmployeeId) {
          setEmployeeId(option.slug)
        }

        if (transactionsForm) {
          onChangeTrialHandler(option.slug)
        }
      }
    },
    [form, name]
  )

  const onSearchHandler = React.useCallback((value?: string): void => {
    setLoading(true)

    api.employeesService
      .search(value ?? '')
      .then((response: AxiosResponse<SearchApi.SearchItem[]>): void => setEmployees(response?.data ?? []))
      .catch(error => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  return {
    value,
    options,
    loading,
    onChangeHandler,
    onSearchHandler,
    onSelectHandler,
  }
}

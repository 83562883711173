import { IFigure } from '../ui/hour-cell/hour-cell.types'
import { ICurrentStudioSlice } from '../work-time-schedule.types'

export const buildScheduleStudio = (
  figure: IFigure,
  currentStudioSlice: ICurrentStudioSlice,
  numberKeyDay: number,
  from: string,
  to: string
) => {
  const scheduleStudio = {
    cellId: figure.cellId,
    studio: currentStudioSlice,
    day: numberKeyDay,
    time: {
      start: from,
      end: to,
    },
    tagType: figure.tag.type,
    id: figure.id,
  }

  return scheduleStudio
}

import React from 'react'
import Form, { FormInstance, FormListFieldData } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import { Row, Col, Typography, Button, Flex } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import { MasterServicesFormSubservicesAvailableStudioRoom } from './master-services-form-subservices-available-studio-room.component.tsx/master-services-form-subservices-available-studio-room.component'
import { MasterServicesFormValues } from '../../../master-services-form.types'
import { isDefAndNotEmpty } from '../../../../../../types/lang.types'
import './master-services-form-subservices-available-studio-rooms.styles.less'

interface Props {
  form: FormInstance<MasterServicesFormValues>
  studiosOptions?: DefaultOptionType[]
  studiosRoomsOptions?: DefaultOptionType[]
  field: FormListFieldData
}

export const MasterServicesFormSubservicesAvailableStudioRooms: React.FC<Props> = props => {
  const { field, form, studiosOptions, studiosRoomsOptions } = props

  const studios = Form.useWatch(['subServices', field.name, 'availableStudioRooms'], form)
  return (
    <Form.List name={[field.name, 'availableStudioRooms']} initialValue={[{}]}>
      {(subFields, { add, remove }) => (
        <>
          {isDefAndNotEmpty(studios) && (
            <Flex gap="small" vertical>
              <Row gutter={16}>
                <Col flex="1 1 0px">
                  <Typography.Text className="master-services-form-subservices-available-studio-rooms__text">
                    Студия
                  </Typography.Text>
                </Col>
                <Col flex="1 1 0px">
                  <Typography.Text className="master-services-form-subservices-available-studio-rooms__text">
                    Залы
                  </Typography.Text>
                </Col>
                <Col flex="0 1 32px" />
              </Row>
              {subFields.map(subField => (
                <MasterServicesFormSubservicesAvailableStudioRoom
                  form={form}
                  field={field}
                  subField={subField}
                  studiosOptions={studiosOptions}
                  studiosRoomsOptions={studiosRoomsOptions}
                  key={subField.key}
                  onRemove={remove}
                />
              ))}
            </Flex>
          )}
          <Button
            onClick={() => add()}
            type="link"
            icon={<PlusCircleOutlined />}
            size="small"
            style={{ alignSelf: 'baseline' }}
          >
            Добавить студию и залы
          </Button>
        </>
      )}
    </Form.List>
  )
}

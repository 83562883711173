import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { PositionsApi } from '../../../api/types/positions-api.types'
import {
  mapPositionsToRemovePositionOptions,
  mapPositionsToPositionsTableRowList,
} from '../../../mapping/positions.mapping'

const getPositionsInternal = (state: AppState): Nullable<Pagination<PositionsApi.Position>> =>
  state.positionsPage.positions

export const genPositionsIsLoading = (state: AppState): boolean => state.positionsPage.isLoading
export const genPositionsIsLoaded = (state: AppState): boolean => state.positionsPage.isLoaded

export const genPositionsTableRowList = createSelector(getPositionsInternal, positions =>
  mapPositionsToPositionsTableRowList(positions?.content)
)

export const genPositionsOptions = createSelector(getPositionsInternal, positions =>
  mapPositionsToRemovePositionOptions(positions?.content)
)

export const genPositionsTotalElements = createSelector(getPositionsInternal, positions => positions?.totalElements)

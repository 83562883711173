import { FC, useEffect, useMemo, useState } from 'react'
import { api } from '@api/api'
import { Form, Select, Table, Tooltip } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import clsx from 'clsx'
import dayjs from 'dayjs'

import { TransactionsProductsMarkCodes } from './transactions-products-table-mark-codes/transactions-products-table-mark-codes.component'
import { TransactionsFormProductsTableProps } from './transactions-products-table.types'
import { genTransactionsProductsTableColumns, getSpaceProduct } from './transactions-products-table.utils'

import './transactions-products-table.styles.less'

export const TransactionsProductsTable: FC<TransactionsFormProductsTableProps> = props => {
  // SECTION: Props
  const { data, clientId, form, exerciseId } = props
  const { onRemove, onChangeAmount, onChangeMarkCodes, onChangeDiscount, onChangeActiveRecordHandler } = props

  // SECTION: State
  const [recordsOptionsCache, setRecordsOptionsCache] = useState<{ [key: string]: DefaultOptionType[] }>({})
  const [selectedOptionsCache, setSelectedOptionsCache] = useState<{ [key: string]: string }>({})

  // SECTION: Computed
  const columns = useMemo(
    () => genTransactionsProductsTableColumns({ onRemove, onChangeAmount, onChangeDiscount }),
    [onRemove, onChangeAmount, onChangeDiscount]
  )

  // SECTION: Lifecycle
  useEffect(() => {
    const getActiveRecordsForProduct = async (productId: string, productType: string, canPayForBooking: boolean) => {
      if (clientId) {
        const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID') as string
        if (productType === 'SERVICE' && canPayForBooking) {
          const response = await api.clientsBookingsService.legacyFetchAllUnpaid(clientId, { productId, studioId })
          return response?.data ?? []
        } else if (productType === 'FULL_PAYMENT_SERVICE' && !canPayForBooking) {
          const response = await api.clientsBookingsService.fetchAllActive(clientId, productId, { studioId })
          return response?.data ?? []
        } else if (productType === 'INSTANT_SUB_SERVICE') {
          const response = await api.clientsBookingsService.fetchAllActive(clientId, productId, { studioId })
          return response?.data ?? []
        } else if (productType === 'ADVANCE_SUB_SERVICE') {
          const response = await api.clientsBookingsService.legacyFetchAllUnpaid(clientId, { productId, studioId })
          return response?.data ?? []
        }
      }
      return []
    }

    const updateOptionsForProduct = async (productId: string, productType: string, canPayForBooking: boolean) => {
      const records = await getActiveRecordsForProduct(productId, productType, canPayForBooking)

      const options = (records ?? []).map(record => ({
        value: record?.exerciseId,
        label: `${dayjs(record.exerciseDate).locale('ru').format('D MMMM, HH:mm')}, ${record.exerciseDirection.name}, ${
          record.studio.name
        }`,
      }))
      setRecordsOptionsCache(prevCache => ({ ...prevCache, [productId]: options }))
    }

    if (clientId && data?.length > 0) {
      ;(data ?? []).forEach(item => {
        if (!recordsOptionsCache[item.id]) {
          updateOptionsForProduct(item.id, item.productType, item.canPayForBooking)
        }
      })
    }
  }, [clientId, data, recordsOptionsCache])

  console.log('data = ', data)

  // SECTION: Render
  return (
    <Table
      className="transactions-products-table"
      dataSource={data.flatMap(item => [getSpaceProduct(), item])}
      columns={columns}
      pagination={false}
      key={data.length}
      rowKey="uuid"
      rowClassName={record =>
        clsx({
          'transactions-products-table__row-with-mark-codes': record.hasMarkCode,
          'transactions-products-table__row--has-expandable':
            record.hasMarkCode || (record.productType !== 'GOODS' && record.productType !== 'SUBSCRIPTION'),
          'transactions-products-table__row--space': record.isSpace,
        })
      }
      expandable={{
        defaultExpandAllRows: true,
        expandedRowRender: record => {
          if (record.productType !== 'GOODS' && record.productType !== 'SUBSCRIPTION') {
            if (clientId) {
              const options = recordsOptionsCache[record.id]
              const rules = true ? [{ required: true, message: 'Выберите активную запись' }] : []
              return (
                <Form.Item rules={rules}>
                  <Select
                    onChange={exerciseId => onChangeActiveRecordHandler(record?.uuid, exerciseId)}
                    value={selectedOptionsCache[record.id]}
                    style={{ width: '400px' }}
                    options={options}
                    placeholder="Выберите активную запись"
                    showSearch={true}
                    filterOption={(input, option) =>
                      option && typeof option.label === 'string'
                        ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        : false
                    }
                    dropdownClassName="customDropdown"
                    onSelect={(exerciseId, option) => {
                      setSelectedOptionsCache(prevCache => ({ ...prevCache, [record.id]: exerciseId }))
                    }}
                  />
                </Form.Item>
              )
            } else {
              return (
                <Tooltip title="Сначала выберите клиента">
                  <Select disabled style={{ width: '400px' }} placeholder="Выберите активную запись" />
                </Tooltip>
              )
            }
          }
          return (
            <div>
              {Array(record.count)
                .fill(true)
                .map((_, i) => (
                  <TransactionsProductsMarkCodes
                    productUuid={record.uuid}
                    key={i}
                    index={i}
                    value={record.markCodes}
                    onChangeMarkCodes={onChangeMarkCodes}
                  />
                ))}
            </div>
          )
        },
        rowExpandable: record =>
          record.hasMarkCode || (record.productType !== 'GOODS' && record.productType !== 'SUBSCRIPTION'),
        showExpandColumn: false,
        expandedRowClassName: record => 'transactions-products-table__mark-codes',
      }}
    />
  )
}

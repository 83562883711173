import * as React from 'react'
import { Button, Modal } from 'antd'

import { PageLoader } from '../../page/page-loader/page-loader.component'
import { ExercisesGroupCommentsModalProps } from './exercises-group-comments-modal.types'
import { ExercisesGroupCommentsForm } from '../exercises-group-comments-form/exercises-group-comments-form.component'
import { ExercisesGroupComment } from '../exercises-group-comment/exercises-group-comment.component'

export const ExercisesGroupCommentsModal: React.FC<ExercisesGroupCommentsModalProps> = props => {
  const { onSave, onCancel, fullName, form, loading, comments } = props

  const onSaveHandler = React.useCallback((): void => {
    const values = form.getFieldsValue()
    onSave(values)
  }, [form, onSave])

  return (
    <Modal
      title={`Комментарий к записи ${fullName ? fullName : `гостя`}`}
      open={true}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onSaveHandler}>
          Сохранить
        </Button>
      }
    >
      {loading ? (
        <PageLoader className="exercises-group-waiting-clients-modal__loader" />
      ) : (
        <>
          {comments?.map(comment => (
            <ExercisesGroupComment
              createDate={comment.createDate}
              comment={comment.comment}
              employeeName={comment.employeeName}
              photo={comment.photo}
              key={comment.id}
            />
          ))}
          <ExercisesGroupCommentsForm form={form} />
        </>
      )}
    </Modal>
  )
}

import { formatDate } from '@format/date.format'
import { genScheduleGroupPagePath } from '@format/path.format'
import { Tag, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

import { ExercisesGroupBookingsTableLinkedDataItem } from './exercises-group-linked-table.types'
interface Props {
  studioId: string
  scheduleId: string
  data: ExercisesGroupBookingsTableLinkedDataItem[]
}
export function genExercisesGroupTableLinkedColumns({
  studioId,
  scheduleId,
  data,
}: Props): ColumnsType<ExercisesGroupBookingsTableLinkedDataItem> {
  const currentDate = new Date()
  const uniqueDates = Array.from(new Set(data.map(item => formatDate(item.timeFrom, 'D MMMM, HH:mm')))).map(date => ({
    text: date,
    value: date,
  }))
  return [
    {
      title: 'Дата и время',
      dataIndex: 'date',
      key: 'date',
      filters: uniqueDates,
      onFilter: (value, record) => formatDate(record.timeFrom, 'D MMMM, HH:mm') === value,
      render: (_, record) => {
        const text = `${formatDate(record.timeFrom, 'D MMMM, HH:mm', 'YYYY-MM-DDTHH:mm')} - ${formatDate(
          record.timeTo,
          'HH:mm'
        )}`
        return (
          <div data-start-time={record.timeFrom} data-end-time={record.timeTo}>
            {record.id === scheduleId ? (
              <Typography.Text>{text}</Typography.Text>
            ) : (
              <Link to={genScheduleGroupPagePath({ studioId, scheduleId: record.id })}>
                <Typography.Link>{text}</Typography.Link>
              </Link>
            )}
          </div>
        )
      },
    },
    {
      title: 'Площадка, зал',
      dataIndex: 'room',
      key: 'room',
      render: (_, record) => <Typography.Text>{record.room.name}</Typography.Text>,
      sorter: (a, b) => a.room.name.localeCompare(b.room.name),
    },
    {
      title: 'Количество человек',
      dataIndex: 'maxClientsCount',
      key: 'maxClientsCount',
      render: (_, record) => <Typography.Text>{record.maxClientsCount}</Typography.Text>,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        const isPastEvent = currentDate > new Date(record.timeTo)
        return <Tag color={isPastEvent ? 'magenta' : 'blue'}>{isPastEvent ? 'Завершено' : 'План'}</Tag>
      },
    },
  ]
}

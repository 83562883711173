export function getFontWeightOptions() {
  return [
    { label: '100: Thin', value: 100 },
    { label: '200: Extra Light', value: 200 },
    { label: '300: Light', value: 300 },
    { label: '400: Normal', value: 400 },
    { label: '500: Medium', value: 500 },
    { label: '600: Semi Bold', value: 600 },
    { label: '700: Bold', value: 700 },
    { label: '800: Extra Bold', value: 800 },
    { label: '900: Black', value: 900 },
  ]
}

export function getTextTransformOptions() {
  return [
    { label: 'Обычный', value: 'none' },
    { label: 'Заглавные буквы', value: 'uppercase' },
    { label: 'Строчные буквы', value: 'lowercase' },
    { label: 'Первая буква в верхнем регистре', value: 'capitalize' },
  ]
}

export function getFontStyleOptions() {
  return [
    { label: 'Обычный', value: 'normal' },
    { label: 'Курсивное начертание', value: 'italic' },
    { label: 'Наклонное начертание', value: 'oblique' },
  ]
}

export function getTextDecorationOptions() {
  return [
    { label: 'Обычный', value: 'none' },
    { label: 'Подчёркнутый текст', value: 'underline' },
    { label: 'Надчёркнутый текст', value: 'overline' },
    { label: 'Перечёркнутый текст', value: 'line-through' },
  ]
}

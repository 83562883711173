import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import { modalActions } from '../../../store/common/modal/modal.slice'
import { ExercisesGroupCommentsFormValues } from '../../../components/exercises-group/exercises-group-comments-form/exercises-group-comments-form.types'
import { scheduleGroupPageModalCommentsActions } from '../../../store/pages/schedule-group-page/schedule-group-page-modal-comments/schedule-group-page-modal-comments.slice'
import {
  genScheduleGroupPageModalBookingComments,
  genScheduleGroupPageModalCommentsIsLoading,
} from '../../../store/pages/schedule-group-page/schedule-group-page-modal-comments/schedule-group-page-modal-comments.selectors'

export function useScheduleGroupPageModalComments(exerciseId: string, bookingId: string) {
  const [form] = Form.useForm<ExercisesGroupCommentsFormValues>()
  const dispatch = useDispatch()

  const loading = useSelector(genScheduleGroupPageModalCommentsIsLoading)
  const comments = useSelector(genScheduleGroupPageModalBookingComments)

  React.useEffect((): void => {
    dispatch(scheduleGroupPageModalCommentsActions.fetchComments({ exerciseId, bookingId }))
  }, [bookingId, dispatch, exerciseId])

  const onSaveHandler = React.useCallback(
    (exercisesGroupCommentsFormValues: ExercisesGroupCommentsFormValues): void => {
      dispatch(
        scheduleGroupPageModalCommentsActions.createComment({
          exerciseId,
          bookingId,
          exercisesGroupCommentsFormValues,
        })
      )
    },
    [bookingId, dispatch, exerciseId]
  )

  const onCancelHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  return {
    form,
    loading,
    comments,

    onSaveHandler,
    onCancelHandler,
  }
}

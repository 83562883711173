import { FC, useMemo } from 'react'
import { Table, Tag, Typography } from 'antd'
import { PageEmpty } from '@components/page/page-empty/page-empty.component'

import { IFinalScheduleTableProps } from './final-schedule-table.types'
import { generateDataSource } from './utils/transform'
import './final-schedule-table.styles.less'

export const FinalScheduleTable: FC<IFinalScheduleTableProps> = ({ finalScheduleStudios }) => {
  // SECTION: CONFIG
  const columns = useMemo(() => {
    const dayColumn = {
      title: '',
      dataIndex: 'day',
      key: 'day',
      className: 'day-cell',
      width: 45,
      render: (text: string) => <Typography.Paragraph>{text}</Typography.Paragraph>,
    }

    const newColumns = finalScheduleStudios.map(studio => {
      return {
        title: studio.name,
        children: studio?.rooms?.map(room => ({
          className: 'time-cell',
          title: <Tag color={room.color.toLowerCase()}>{room.name}</Tag>,
          dataIndex: `room-${room.id}`,
          key: `room-${room.id}`,
          width: 130,
        })),
      }
    })

    return [dayColumn, ...newColumns]
  }, [finalScheduleStudios])

  const dataSource = useMemo(() => {
    const DEFAULT_DATA_SOURCE = [
      { key: '1', day: 'пн' },
      { key: '2', day: 'вт' },
      { key: '3', day: 'ср' },
      { key: '4', day: 'чт' },
      { key: '5', day: 'пт' },
      { key: '6', day: 'сб' },
      { key: '7', day: 'вс' },
    ]

    const dataSource = generateDataSource(finalScheduleStudios, DEFAULT_DATA_SOURCE)
    return dataSource
  }, [finalScheduleStudios])

  return Boolean(finalScheduleStudios.length) ? (
    <Table bordered className="final-schedule-table" columns={columns} dataSource={dataSource} pagination={false} />
  ) : (
    <PageEmpty description="Расписание не найдено" />
  )
}

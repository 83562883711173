import { ProductSubscriptionForm } from '../../../components/products/products-subscriptions-form/products-subscriptions-form.component'
import { useProductServicesEditPageForm } from './products-subscriptions-edit-page-form.hook'

export const ProductSubscriptionsEditPageForm = () => {
  const {
    form,
    isLoading,
    directionsOptions,
    studiosOptions,
    exercisesOptions,
    onFinishHandler,
    freezingValue,
    activationDaysValue,
  } = useProductServicesEditPageForm()
  return (
    <ProductSubscriptionForm
      disabledVariants={true}
      directionsOptions={directionsOptions}
      studiosOptions={studiosOptions}
      freezingValue={freezingValue}
      activationDaysValue={activationDaysValue}
      // @ts-ignore
      exercisesOptions={exercisesOptions}
      form={form}
      isLoading={isLoading}
      submitText="Сохранить"
      onFinish={onFinishHandler}
    />
  )
}

import { Button, Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import { genMasterServicesPagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'

export const MasterServicesCreatePageTopBar = () => {
  return (
    <Flex gap="small" align="start" vertical>
      <Button type="text" icon={<LeftOutlined />} size="small">
        <Link to={genMasterServicesPagePath()}>
          <Typography.Text className="backBtnTitle">{formatPathName(AppPath.MASTER_SERVICES)}</Typography.Text>
        </Link>
      </Button>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Создание персональной услуги
      </Typography.Title>
    </Flex>
  )
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { StudiosApi } from '../../../../api/types/studios-api.types'
import { Nullable } from '../../../../types/lang.types'
import {
  EmployeesSchedulePeekHistoryFetchAuditPayload,
  EmployeesSchedulePeekHistoryFetchAuditSuccessPayload,
} from './employees-schedule-peek-history.types'

export interface EmployeesSchedulePeekHistoryState {
  scheduleAudit: Nullable<Pagination<AuditApi.Audit>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: EmployeesSchedulePeekHistoryState = {
  scheduleAudit: null,
  studios: null,
  isLoading: false,
  error: null,
}

export const { actions: employeesSchedulePeekHistoryActions, reducer: employeesSchedulePeekHistoryReducer } =
  createSlice({
    name: '@@employees-schedule-peek-history',
    initialState,
    reducers: {
      fetchAudit: (
        state: Draft<EmployeesSchedulePeekHistoryState>,
        _: PayloadAction<EmployeesSchedulePeekHistoryFetchAuditPayload>
      ): void => {
        state.isLoading = true
      },
      fetchAuditSuccess: (
        state: Draft<EmployeesSchedulePeekHistoryState>,
        action: PayloadAction<EmployeesSchedulePeekHistoryFetchAuditSuccessPayload>
      ): void => {
        state.scheduleAudit = action.payload.scheduleAudit
        state.studios = action.payload.studios
        state.isLoading = false
      },
      fetchAuditError: (state: Draft<EmployeesSchedulePeekHistoryState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })

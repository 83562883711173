import { Rule } from 'antd/lib/form'
import { isValidPhoneNumber } from 'libphonenumber-js'

export function genExercisesGroupBookingFormPhoneValidationRules(): Rule[] {
  return [
    { required: true, message: 'Введите номер телефона' },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value.length && !isValidPhoneNumber(value, 'RU')) {
          if (!value.match(/^\d/) || value.includes('+') || value.substring(0, 1) === '8') {
            return Promise.reject('Недопустимое значение')
          }
          return Promise.reject('Кажется это международный номер, начните с +')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}

export function genExercisesGroupBookingFormPlaceValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите место' }]
}

export function genExercisesGroupBookingFormPaymentTypeValidationRules(): Rule[] {
  return [{ required: true, message: 'Выберите способ оплаты' }]
}

import { ITerminalDTO } from '@api/types/terminals-api.types'
import { getTerminalsWithOptions } from '@store/common/entity/terminal/terminal.selectors'
import { terminalEntityActions } from '@store/common/entity/terminal/terminal.slice'
import { modalActions } from '@store/common/modal/modal.slice'
import { Form, Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
const { confirm } = Modal

export function useFranchisesModalTerminalEditForm(id: string) {
  const [form] = Form.useForm()
  const titleText = 'Редактирование интернет-эквайринга'
  const btnTitle = 'Обновить'

  const dispatch = useDispatch()

  const terminals = useSelector(getTerminalsWithOptions)
  const terminalById = terminals?.find(item => item.id === id)
  useEffect(() => {
    form.setFieldsValue({ ...terminalById })
  }, [terminalById])
  const onCancel = (): void => {
    dispatch(modalActions.closeLast())
  }
  const onFinish = (values: ITerminalDTO): void => {
    dispatch(terminalEntityActions.updateTerminal({ id, data: values }))
    onCancel()
  }
  const onRemove = () => {
    dispatch(terminalEntityActions.deleteTerminal({ id }))
    onCancel()
  }

  const onRemoveHandler = () => {
    confirm({
      title: `Подтвердите удаление ${terminalById?.name}`,
      content: `Эту операцию отменить не получится.`,
      onOk: () => onRemove(),
    })
  }

  return { form, titleText, onFinish, onRemoveHandler, onCancel, btnTitle }
}

import { FormInstance } from 'antd'

import { Nullable, isDef, isDefAndMoreThenZero, isDefAndNotEmpty } from '../../../types/lang.types'
import { SalariesFormValue, SalariesFormValues, keysOfSalariesFormValues } from './salaries-form-types'
import { SalariesApi } from '../../../api/types/salaries-api.types'
import { formatRublesToPennies } from '../../../format/number.format'

export function genSalariesFormIsValid(form: FormInstance<SalariesFormValues>) {
  const fieldsIsValid = isValidSalariesFormFields(form.getFieldValue('salaries'))

  return fieldsIsValid
}

export function isValidSalariesFormFields(fields: Nullable<SalariesFormValue[]>): boolean {
  if (isDefAndNotEmpty(fields)) {
    const filterFields = (item: SalariesFormValue) => {
      if (isDef(item)) {
        const gradesIsValid = isDefAndNotEmpty(item.gradeIds)
        const directionIsValid = isDefAndNotEmpty(item.directionIds)
        const typeIsValid = isDefAndNotEmpty(item.exerciseTypeIds)
        const studiosIsValid = isDefAndNotEmpty(item.studioIds)
        const ratesIsValid = isDefAndMoreThenZero(item.rate)
        if (!gradesIsValid || !directionIsValid || !typeIsValid || !studiosIsValid || !ratesIsValid) {
          return true
        }
        return false
      }
      return true
    }
    const fieldsMap = fields.filter(filterFields)
    return !fieldsMap.length
  }
  return false
}

export function genSalariesFormValuesDTO(values: SalariesFormValues): SalariesApi.SalaryDTO[] {
  const { salaries } = values
  return salaries.reduce((acc: SalariesApi.SalaryDTO[], salary) => {
    acc.push({
      rate: formatRublesToPennies(salary.rate),
      gradeIds: salary.gradeIds,
      directionIds: salary.directionIds,
      exerciseTypeIds: salary.exerciseTypeIds,
      studioIds: salary.studioIds,
    })
    return acc
  }, [])
}

export function genDeletedSalaryFormValuesDTO(value: SalariesFormValue): SalariesApi.DeletedSalaryDTO[] {
  let deletedSalary = []

  deletedSalary.push({
    studioIds: value.studioIds,
    gradeIds: value.gradeIds,
    exerciseTypeIds: value.exerciseTypeIds,
    directionIds: value.directionIds,
  })

  return deletedSalary
}

export function genDeselectedSalaryFormValueDTO(
  values: SalariesFormValue,
  key: keysOfSalariesFormValues,
  value: any
): SalariesApi.DeletedSalaryDTO {
  const { rate, ...deletedSalary } = values
  deletedSalary[key] = [value]

  return deletedSalary
}

import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Layout, Dropdown, Avatar, Button, Flex } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { clsx } from 'clsx'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'

import { genSelectedKeys } from './app-layout-top-bar.utils'
import { AppLayoutTopBarMenu, AppLayoutTopBarMenuItem } from './app-layout-top-bar.types'
import { genSchedulePagePath } from '../../../../format/path.format'
import { useStudio } from '../../../../hooks/use-studios.hook'
import { ClientsSearchContainer } from '../../../../containers/clients-search/clients-search.container'
import './app-layout-top-bar.styles.less'
import { Nullable } from '../../../../types/lang.types'
import { EmployeesApi } from '../../../../api/types/employees-api.types'

interface Props {
  className?: string
  menuItems: AppLayoutTopBarMenu[]
  userMenu: AppLayoutTopBarMenuItem[]
  theme: string
  currentEmployee: Nullable<EmployeesApi.CurrentEmployee>
  sidebarCollapse: () => void
  isCollapsed: boolean
}

export const AppLayoutTopBar: React.FC<Props> = props => {
  const { className } = props
  const { menuItems, userMenu } = props
  const { theme, currentEmployee } = props
  const { sidebarCollapse, isCollapsed } = props

  const { pathname, search } = useLocation()
  const { studioId } = useStudio()
  const isPageSchedulePage = studioId && genSchedulePagePath(studioId) === pathname

  return (
    <Layout.Header
      className={clsx(
        `app-layout-top-bar ${theme === 'dark' && 'app-layout-top-bar--dark'} ${
          isPageSchedulePage && 'app-layout-top-bar--schedule'
        }`,
        className
      )}
    >
      <Flex gap="small" align="center">
        <Button onClick={sidebarCollapse} type="text" className="app-layout-top-bar__shrinkButton">
          {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <ClientsSearchContainer />
      </Flex>

      <Flex gap="large" align="center">
        <Flex gap="middle" align="center" className="app-layout-top-bar-nav">
          {menuItems.map(menu => {
            const { items, title, icon: Icon } = menu
            const selectedKeys = genSelectedKeys(items, pathname, search)

            return (
              <Dropdown
                key={title}
                overlayStyle={{ width: 'max-content' }}
                placement="bottomLeft"
                className="app-layout-top-bar-nav__dropdown"
                overlay={
                  <Menu selectedKeys={selectedKeys} className="app-layout-top-bar-nav__menu">
                    {items.map(menuItem => (
                      <Menu.Item key={menuItem.title} onClick={menuItem.onClick}>
                        {menuItem.path ? <Link to={menuItem.path}>{menuItem.title}</Link> : menuItem.title}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Flex gap="small" align="center" className="app-layout-top-bar-nav__item">
                  <Icon />
                  <p>{title}</p>
                </Flex>
              </Dropdown>
            )
          })}
        </Flex>

        <Flex gap="small" align="center">
          {/* <Badge count={5}>
          <Button icon={<BellOutlined />} />
        </Badge>
        <Button type="text">
          <GlobalOutlined /> English
        </Button> */}

          {userMenu.map(item => {
            return (
              <Dropdown
                key={item.title}
                className="app-layout-top-bar-nav__dropdown"
                overlay={
                  <Menu>
                    <Menu.Item key={item.title} onClick={item.onClick}>
                      {item.title}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Flex gap="small" align="center">
                  <Avatar size={24} icon={<UserOutlined />} />
                  {currentEmployee && (
                    <p className="app-layout-top-bar__user">{`${currentEmployee.lastName ?? ''} ${
                      currentEmployee.firstName?.[0] ?? ''
                    }`}</p>
                  )}
                </Flex>
              </Dropdown>
            )
          })}
        </Flex>
      </Flex>
    </Layout.Header>
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { Nullable } from '../../../types/lang.types'
import { CustomFieldsApi } from '../../../api/types/custom-fields-api.types'
import {
  mapCustomFieldsEmptyValueToFields,
  mapCustomFieldsToDirectionsTableRowList,
} from '../../../mapping/custom-fields.mapping'

const getCustomFieldsInternal = (state: AppState): Nullable<CustomFieldsApi.CustomField[]> =>
  state.customFieldsPage.fields

export const getCustomFieldsResource = (state: AppState): string => state.customFieldsPage.resource
export const genCustomFieldsIsLoading = (state: AppState): boolean => state.customFieldsPage.isLoading

export const genCustomFieldsTableRowList = createSelector(getCustomFieldsInternal, fields =>
  mapCustomFieldsToDirectionsTableRowList(fields)
)

export const getCustomFieldsWithValue = createSelector(getCustomFieldsInternal, fields =>
  mapCustomFieldsEmptyValueToFields(fields)
)

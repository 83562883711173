import { DefaultOptionType } from 'antd/lib/select'

import { validateStrEnumValue } from './enum.utils'
import { isDef } from '../types/lang.types'
import { ClientSubscriptionStatus } from '../types/clients-subscriptions.types'
import { formatClientSubscriptionStatus } from '../format/text.format'

export function genSubscriptionStatusOptions(): DefaultOptionType[] {
  return Object.keys(ClientSubscriptionStatus).reduce<DefaultOptionType[]>((acc: DefaultOptionType[], key: string) => {
    const subscriptionStatus = validateStrEnumValue<ClientSubscriptionStatus>(ClientSubscriptionStatus, key)

    if (isDef(subscriptionStatus)) {
      acc.push({
        value: subscriptionStatus,
        label: formatClientSubscriptionStatus(subscriptionStatus),
      })
    }

    return acc
  }, [])
}

import { Button, Col, Divider, Flex, Form, Input, Modal, Row, Typography } from 'antd'

import { CustomCard } from '../../../custom-card/custom-card.component'
import { MasterServicesFormPricingImpacts } from './master-services-form-pricing-impacts/master-services-form-pricing-impacts.component'
import { useMasterServicesFormPricingModal } from './master-services-form-pricing-modal.hook'
import { isDef } from '../../../../types/lang.types'
import { MasterServicesFormPricingBasePrice } from './master-services-form-pricing-base-price/master-services-form-pricing-base-price.component'
import { MasterServicesFormPricingModalProps } from './master-services-form-pricing-modal.types'
import './master-services-form-pricing-modal.styles.less'

export const MasterServicesFormPricingModal: React.FC<MasterServicesFormPricingModalProps> = props => {
  const { open, pricing, onOk, onCancel } = props
  const { positions, positionsOptions } = props

  const { theme, form, nameValidationRules, onSubmitForm, onClose } = useMasterServicesFormPricingModal({
    open,
    pricing,
    onOk,
    onCancel,
  })

  return (
    <Modal open={open} onCancel={onClose} width={1000} title="Создание ценообразования" footer={null}>
      <Divider className="master-services-form-pricing__divider" />

      <Form form={form} onFinish={onSubmitForm}>
        <Flex vertical gap="middle">
          <CustomCard
            customClassName={`master-services-form-pricing__card master-services-form-pricing__card--${theme}`}
          >
            <Typography.Title className="master-services-form-pricing__title" level={5}>
              Основная информация
            </Typography.Title>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="master-services-form-pricing__form-item"
                  label="Название, его будете видеть только вы"
                  name="name"
                  rules={nameValidationRules}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>
          </CustomCard>

          <MasterServicesFormPricingBasePrice
            form={form}
            theme={theme}
            positions={positions}
            positionsOptions={positionsOptions}
          />

          <MasterServicesFormPricingImpacts
            theme={theme}
            form={form}
            positions={positions}
            positionsOptions={positionsOptions}
          />

          <Flex gap="middle" justify="flex-end">
            <Button onClick={onClose}>Закрыть</Button>
            <Button htmlType="submit" type="primary">
              {isDef(pricing) ? 'Сохранить' : 'Создать'}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  )
}

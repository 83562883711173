export const getOfflineTillEmptyBalance = () => {
  return {
    date: '',
    time: {
      hour: 0,
      minute: 0,
      second: 0,
      nano: 0,
    },
    cash: {
      income: 0,
      refund: 0,
      sum: 0,
    },
    card: {
      income: 0,
      refund: 0,
      sum: 0,
    },
    online: {
      income: 0,
      refund: 0,
      sum: 0,
    },
    total: {
      income: 0,
      refund: 0,
      sum: 0,
    },
  }
}

import { WorkTime } from '@api/types/api.types'
import { StudiosApi } from '@api/types/studios-api.types'
import { IFinalScheduleStudio } from '@components/employees/schedule/final-schedule-table/final-schedule-table.types'
import { TAG_TEXTS, TAG_TYPES } from '@components/employees/schedule/work-time-schedule/constants/tag'
import { matchKeyByDay } from '@components/employees/schedule/work-time-schedule/utils/match-key-by-day'
import { getStudios } from '@store/common/entity/studio/studio.selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const generateDailyWorkTime = (
  finalScheduleStudios: IFinalScheduleStudio[],
  studios: StudiosApi.Studio[]
): WorkTime => {
  const dailyWorkTime: Record<string, any[]> = {}

  finalScheduleStudios.forEach(studio => {
    studio.rooms.forEach(room => {
      room.figures.forEach(figure => {
        const dayKey = matchKeyByDay(figure.day)
        const studioId = studio?.id
        // const studioOffset = studios.find(s => s.id === studioId)?.offset ?? 0
        const figureTimeRange = {
          from: figure.start,
          to: figure.end,
        }

        if (!dailyWorkTime[dayKey]) {
          dailyWorkTime[dayKey] = []
        }

        if (studio.id === TAG_TYPES.ALL_STUDIOS) {
          dailyWorkTime[dayKey].push({
            timeRange: figureTimeRange,
            studioId: null,
            roomIds: [],
            hasStudioLimitation: false,
            hasRoomLimitation: false,
          })
        } else if (room.name === TAG_TEXTS.ALL_ROOMS) {
          dailyWorkTime[dayKey].push({
            timeRange: figureTimeRange,
            studioId: studioId,
            roomIds: [],
            hasRoomLimitation: false,
            hasStudioLimitation: true,
          })
        } else {
          dailyWorkTime[dayKey].push({
            timeRange: figureTimeRange,
            studioId: studioId,
            roomIds: [room.id],
          })
        }
      })
    })
  })

  return dailyWorkTime as unknown as WorkTime
}

export const useTimeSlotsFromFinalScheduleStudios = (finalScheduleStudios: IFinalScheduleStudio[]) => {
  const [timeRangeSlots, setTimeRangeSlots] = useState<WorkTime>()
  const rowStudios = useSelector(getStudios)

  useEffect(() => {
    setTimeRangeSlots(generateDailyWorkTime(finalScheduleStudios, rowStudios))
  }, [finalScheduleStudios, rowStudios])

  return timeRangeSlots
}

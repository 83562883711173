import * as React from 'react'
import { useDispatch } from 'react-redux'

import { isDef } from '../../types/lang.types'
import { useExercisesTypesEditPageParams } from './exercises-types-edit-page-hooks/exercises-types-edit-page-hooks.hook'
import { exercisesTypesEditPageActions } from '../../store/pages/exercises-types-edit-page/exercises-types-edit-page.slice'

export function useExercisesTypesEditPage() {
  const { id } = useExercisesTypesEditPageParams()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDef(id)) {
      dispatch(exercisesTypesEditPageActions.fetchPageData(id))
    }
  }, [dispatch, id])

  React.useEffect(() => {
    return () => {
      dispatch(exercisesTypesEditPageActions.reset())
    }
  }, [dispatch])
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { schedulePageListActions } from '../../store/pages/schedule-page/schedule-page-list/schedule-page-list.slice'
import {
  genSchedulePageListIsLoaded,
  genSchedulePageListIsLoading,
  genSchedulePageListTimetable,
} from '../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import { useStudio } from '../../hooks/use-studios.hook'
import { useSchedulePageParams } from './schedule-page-hooks/schedule-page-params.hook'

export function useSchedulePage() {
  const params = useSchedulePageParams()
  const { studioId: selectedStudioId } = useStudio()
  const { studioId } = params
  const { date, ...pageParams } = params
  const safeDate = date ? date : dayjs().format('YYYY-MM-DD')

  const dispatch = useDispatch()

  const isLoading = useSelector(genSchedulePageListIsLoading)
  const isLoaded = useSelector(genSchedulePageListIsLoaded)
  const currentTimetable = useSelector(genSchedulePageListTimetable)

  useEffect(() => {
    const dateParams = { dateFrom: safeDate, dateTo: safeDate }
    if (currentTimetable === 'weekly') {
      const targetDate = dayjs(safeDate)
      dateParams.dateFrom = targetDate.startOf('isoWeek').format('YYYY-MM-DD')
      dateParams.dateTo = targetDate.endOf('isoWeek').format('YYYY-MM-DD')
    }

    dispatch(schedulePageListActions.fetchPageData({ ...pageParams, ...dateParams }))
  }, [params, currentTimetable, safeDate])

  useEffect(() => {
    return () => {
      dispatch(schedulePageListActions.reset())
    }
  }, [dispatch])

  return {
    studioId,
    selectedStudioId,
    isLoading,
    isLoaded,
  }
}

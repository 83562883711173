import { formatNameColorsToHex } from '@utils/colors.utils'
import { useEffect, useState } from 'react'
import { StudiosApi } from '@api/types/studios-api.types'
import dayjs from 'dayjs'

import { Nullable } from '../../../../../types/lang.types'
import { FIGURE_DIRECTION, FIGURE_MODE, FIGURE_TYPE } from '../constants/figure'
import { convertStudioToCurrentStudioSlice } from '../utils/transform'
import { ICurrentStudioSlice, IFigurePreset, IWorkTimeScheduleStudio } from '../work-time-schedule.types'
import { checkHalfStartOrEnd, checkHalfTime, getTimeRangesByHour } from '../utils/time'
import { IFigure } from '../ui/hour-cell/hour-cell.types'
import { getFigureDetails } from '../ui/hour-cell/utils/figure-details'
import { getCellId } from '../utils/cell'
import { matchDayByKey } from '../utils/match-key-by-day'
import { DEFAULT_FIGURE_DETAILS } from '../ui/hour-cell/constants/figure'
import { TAG_COLORS, TAG_TEXTS, TAG_TYPES } from '../constants/tag'
import { buildFigurePresetByFigure, getDefaultFigurePreset } from '../utils/figure'
import { buildScheduleStudio } from '../utils/studio'

export const useDefaultValues = (
  defaultWorkTime: Nullable<Record<string, any[]>>,
  studios: StudiosApi.Studio[]
): [IFigure[], IFigure, IFigurePreset, Nullable<ICurrentStudioSlice>, IWorkTimeScheduleStudio[]] => {
  // SECTION: STATE
  const [isBuildDefaultValues, setIsBuildDefaultValues] = useState(false)

  const [figures, setFigures] = useState<IFigure[]>([])
  const [figurePreset, setFigurePreset] = useState<IFigurePreset>(getDefaultFigurePreset())
  const [defaultStudio, setDefaultStudio] = useState<Nullable<ICurrentStudioSlice>>(null)
  const [scheduleStudios, setScheduleStudios] = useState<IWorkTimeScheduleStudio[]>([])

  // SECTION: Lifecycle
  useEffect(() => {
    if (isBuildDefaultValues) return

    if (studios.length && defaultWorkTime) {
      const figuresBuffer: IFigure[] = []
      let figurePresetBuffer = getDefaultFigurePreset()
      let defaultStudioBuffer: ICurrentStudioSlice | null = null
      let scheduleStudiosBuffer: IWorkTimeScheduleStudio[] = []

      Object.keys(defaultWorkTime).forEach(key => {
        defaultWorkTime?.[key]?.forEach(slot => {
          if (!slot?.timeRange) return

          const hoursCollection = getTimeRangesByHour(slot?.timeRange?.from, slot?.timeRange?.to)

          hoursCollection.forEach(hourArray => {
            const studio = studios.find(studio => studio.id === slot?.studioId)
            const hour = dayjs(hourArray?.from, 'HH:mm').hour()
            const numberKeyDay = matchDayByKey(key)
            const isHalf = checkHalfTime(hourArray?.from) || checkHalfTime(hourArray?.to)

            if (slot.roomIds.length === 0) {
              let figure: IFigure | null = null
              let figurePreset: IFigurePreset | null = null

              if (!slot.hasStudioLimitation) {
                figure = {
                  cellId: getCellId(hour, numberKeyDay),
                  color: TAG_COLORS.ALL_STUDIOS,
                  type: FIGURE_TYPE.SQUARE,
                  id: TAG_TYPES.ALL_STUDIOS,
                  mode: isHalf ? FIGURE_MODE.HALF : FIGURE_MODE.FULL,
                  direction:
                    checkHalfStartOrEnd(hourArray?.from, hourArray?.to) === 'start'
                      ? FIGURE_DIRECTION.RIGHT
                      : FIGURE_DIRECTION.LEFT,
                  details: DEFAULT_FIGURE_DETAILS,
                  tag: {
                    name: TAG_TEXTS.ALL_ROOMS,
                    type: TAG_TYPES.ALL_ROOMS,
                  },
                }
                const fakeStudioSlice = {
                  name: TAG_TEXTS.ALL_STUDIOS,
                  selectedRoom: {
                    name: TAG_TEXTS.ALL_ROOMS,
                    id: figure.id,
                    color: figure.color,
                    workTime: {
                      from: hourArray?.from,
                      to: hourArray?.to,
                    },
                  },
                } as unknown as ICurrentStudioSlice
                figure.details = getFigureDetails(fakeStudioSlice, hour, figure)
                scheduleStudiosBuffer.push(
                  buildScheduleStudio(figure, fakeStudioSlice, numberKeyDay, hourArray.from, hourArray.to)
                )
                defaultStudioBuffer = fakeStudioSlice
              }

              if (!slot.hasRoomLimitation && studio?.id) {
                const currentStudioSlice = convertStudioToCurrentStudioSlice(studio, studio?.id)
                figure = {
                  cellId: getCellId(hour, numberKeyDay),
                  color: TAG_COLORS.ALL_ROOMS,
                  type: FIGURE_TYPE.SQUARE,
                  id: slot?.studioId,
                  mode: isHalf ? FIGURE_MODE.HALF : FIGURE_MODE.FULL,
                  direction:
                    checkHalfStartOrEnd(hourArray?.from, hourArray?.to) === 'start'
                      ? FIGURE_DIRECTION.RIGHT
                      : FIGURE_DIRECTION.LEFT,
                  details: DEFAULT_FIGURE_DETAILS,
                  tag: {
                    name: TAG_TEXTS.ALL_ROOMS,
                    type: TAG_TYPES.ALL_ROOMS,
                  },
                }
                currentStudioSlice.selectedRoom = {
                  name: TAG_TEXTS.ALL_ROOMS,
                  id: figure.id,
                  color: figure.color,
                  workTime: {
                    // @ts-ignore
                    from: hourArray?.from ?? '00:00',
                    to: hourArray?.to ?? '23:59',
                  },
                }
                figure.details = getFigureDetails(currentStudioSlice, hour, figure)
                scheduleStudiosBuffer.push(
                  buildScheduleStudio(figure, currentStudioSlice, numberKeyDay, hourArray.from, hourArray.to)
                )
                defaultStudioBuffer = currentStudioSlice
              }

              if (!figure) return
              figurePreset = buildFigurePresetByFigure(figure)
              figuresBuffer.push(figure)
              figurePresetBuffer = figurePreset
            }

            if (!studio) return
            slot.roomIds.forEach((roomId: string) => {
              const room = (studio?.rooms ?? []).find(room => room?.id === roomId)
              const currentStudioSlice = convertStudioToCurrentStudioSlice(studio, roomId)

              const figure = {
                cellId: getCellId(hour, numberKeyDay),
                color: formatNameColorsToHex(room?.color),
                type: FIGURE_TYPE.CIRCLE,
                id: roomId,
                mode: isHalf ? FIGURE_MODE.HALF : FIGURE_MODE.FULL,
                direction:
                  checkHalfStartOrEnd(hourArray?.from, hourArray?.to) === 'start'
                    ? FIGURE_DIRECTION.RIGHT
                    : FIGURE_DIRECTION.LEFT,
                details: DEFAULT_FIGURE_DETAILS,
                tag: {
                  name: currentStudioSlice?.selectedRoom?.name ?? 'Неизвестная комната',
                  type: TAG_TYPES.ONE_ROOM,
                },
              }
              figure.details = getFigureDetails(currentStudioSlice, hour, figure)

              const figurePreset = buildFigurePresetByFigure(figure)
              scheduleStudiosBuffer.push(
                buildScheduleStudio(figure, currentStudioSlice, numberKeyDay, hourArray?.from, hourArray?.to)
              )
              figuresBuffer.push(figure)
              figurePresetBuffer = figurePreset
              defaultStudioBuffer = currentStudioSlice
            })
          })
        })
      })

      setFigures(figuresBuffer)
      setScheduleStudios(scheduleStudiosBuffer)
      setFigurePreset(figurePresetBuffer)
      setDefaultStudio(defaultStudioBuffer)
      setIsBuildDefaultValues(true)
    }
  }, [defaultWorkTime, studios, isBuildDefaultValues])

  return [figures, figures[figures.length - 1], figurePreset, defaultStudio, scheduleStudios]
}

import { AxiosResponse } from 'axios'
import { NEmployeesScheduleApi } from '@api/types/employees-schedule-api.types'

import { HttpConnector } from '../connectors/http.connector'
import { Nullable } from '../../types/lang.types'
import { Pagination } from '../types/api.types'
import { EmployeesPositionsApi } from '../types/employees-positions-api.types'

export class EmployeesScheduleService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params?: Nullable<Partial<EmployeesPositionsApi.EmployeesPositionsFetchAllParams>>
  ): Promise<AxiosResponse<Pagination<NEmployeesScheduleApi.IEmployeeSchedule>>> => {
    return this.httpConnector.get('/schedules', { params: { ...params, size: 1000 } })
  }

  public fetchById = (scheduleId?: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.get(`/schedules/${scheduleId}`)
  }

  public delete = (id: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.delete(`/schedules/${id}`)
  }

  public copy = (id: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.put(`/schedules/${id}/copy`)
  }

  public active = (id: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.put(`/schedules/${id}/activate`)
  }

  public duration = (id: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.put(`/schedules/${id}/duration`)
  }

  public stop = (id: string): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.put(`/schedules/${id}/stop`)
  }

  public create = (
    params: Nullable<Partial<NEmployeesScheduleApi.IEmployeeScheduleDTO>>
  ): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.post('/schedules', params)
  }

  public edit = (
    id: string,
    params: Nullable<Partial<NEmployeesScheduleApi.IEmployeeSchedule>>
  ): Promise<AxiosResponse<NEmployeesScheduleApi.IEmployeeSchedule>> => {
    return this.httpConnector.patch(`/schedules/${id}`, params)
  }

  public fetchDaysOffById = (
    scheduleId: string
  ): Promise<AxiosResponse<Nullable<NEmployeesScheduleApi.IEmployeeScheduleDayOff[]>>> => {
    return this.httpConnector.get(`/schedules/${scheduleId}/days-off`)
  }

  public createDayOff = (scheduleId: string, data: NEmployeesScheduleApi.IEmployeeScheduleDayOffDTO): Promise<void> => {
    return this.httpConnector.post(`/schedules/${scheduleId}/days-off`, data)
  }

  public editDayOff = (
    scheduleId: string,
    dayOffId: string,
    data: NEmployeesScheduleApi.IEmployeeScheduleDayOffDTO
  ): Promise<void> => {
    return this.httpConnector.put(`/schedules/${scheduleId}/days-off/${dayOffId}`, data)
  }

  public deleteDayOff = (scheduleId: string, dayOffId: string): Promise<void> => {
    return this.httpConnector.delete(`/schedules/${scheduleId}/days-off/${dayOffId}`)
  }
}

import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { createMatchSelector, getSearch, push } from 'connected-react-router'
import * as H from 'history'
import { match } from 'react-router-dom'
import { all, put, select, take, takeLatest } from 'redux-saga/effects'
import dayjs from 'dayjs'

import { api } from '../../../../api/api'
import { genExerciseBookingCreateDTO, genExercisesCreateDTO } from '../../../../mapping/exercises.mapping'
import { SchedulePageUrlParams } from '../../../../pages/schedule-page/schedule-page.types'
import { genSchedulePageParams } from '../../../../pages/schedule-page/schedule-page.utils'
import { isDef } from '../../../../types/lang.types'
import { AppPath } from '../../../../types/path.types'
import { PaymentType } from '../../../../types/payment.types'
import { callApi } from '../../../../utils/sagas.utils'
import { AppState } from '../../../app.store'
import { modalActions } from '../../../common/modal/modal.slice'
import { schedulePageListActions } from '../schedule-page-list/schedule-page-list.slice'
import { schedulePageModalCreateActions } from './schedule-page-modal-create.slice'
import { formatFormValueToPhoneNumber } from '../../../../format/phone.format'

export function* createExercise(action: ReturnType<typeof schedulePageModalCreateActions.createExercise>) {
  try {
    const { exercisesFormValuesDTO, studioRoomId, date, timetableType } = action.payload
    const exercisesCreateDTO = genExercisesCreateDTO(studioRoomId, exercisesFormValuesDTO, date)

    if (isDef(exercisesCreateDTO)) {
      // @ts-ignore
      const result = yield callApi(api.exercisesService.create, exercisesCreateDTO)
      yield put(schedulePageModalCreateActions.createExerciseSuccess(result))

      const search: H.Search = yield select(getSearch)
      const { params }: match<SchedulePageUrlParams> = yield select(
        createMatchSelector<AppState, SchedulePageUrlParams>(AppPath.SCHEDULE)
      )

      const pageParams = genSchedulePageParams(params, search)

      if (exercisesFormValuesDTO.hasGuest) {
        try {
          const safePaymentType =
            exercisesFormValuesDTO.paymentType === 'CREATE_TRANSACTION'
              ? PaymentType.ON_PLACE
              : exercisesFormValuesDTO.paymentType
          const exerciseBookingCreateDTO = genExerciseBookingCreateDTO({
            place: 1,
            phone: exercisesFormValuesDTO.phone ? exercisesFormValuesDTO.phone : '',
            // @ts-ignore
            paymentType: safePaymentType ?? PaymentType.ON_PLACE,
          })
          if (isDef(exerciseBookingCreateDTO)) {
            yield callApi(api.exercisesService.createBooking, result.data.id, exerciseBookingCreateDTO)
            if (exercisesFormValuesDTO.paymentType === 'CREATE_TRANSACTION') {
              const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)
              yield put(
                push({
                  pathname: `/transactions/${studioId}/create`,
                  state: {
                    phone: exercisesFormValuesDTO.phone
                      ? formatFormValueToPhoneNumber(exercisesFormValuesDTO.phone)
                      : '',
                    exerciseId: result.data.id,
                    clientId: exercisesCreateDTO?.clientId,
                  },
                })
              )
            }
          }
        } catch (e) {
          console.error(e)
        }
      }

      const dateParams = { dateFrom: date, dateTo: date }
      if (timetableType === 'weekly') {
        const targetDate = dayjs(date)
        dateParams.dateFrom = targetDate.startOf('isoWeek').format('YYYY-MM-DD')
        dateParams.dateTo = targetDate.endOf('isoWeek').format('YYYY-MM-DD')
      }

      yield put(schedulePageListActions.fetchPageData({ studioId: pageParams.studioId, ...dateParams }))
      yield put(modalActions.closeLast())
    } else {
      yield put(schedulePageModalCreateActions.createExerciseError(new Error()))
    }
  } catch (e) {
    console.error(e)
    yield put(schedulePageModalCreateActions.createExerciseError(new Error()))
  }
}

export function* fetchDictionaries(action: ReturnType<typeof schedulePageModalCreateActions.fetchDictionaries>) {
  const { studioId, roomId, timeFrom, timeTo } = action.payload

  try {
    yield all([
      put(schedulePageModalCreateActions.fetchExercisesTypes({ studioId, roomId })),
      put(schedulePageModalCreateActions.fetchTrainers({ roomId, timeFrom, timeTo })),
    ])

    yield all([
      take(schedulePageModalCreateActions.fetchExercisesTypesSuccess.type),
      take(schedulePageModalCreateActions.fetchTrainersSuccess.type),
    ])

    yield put(schedulePageModalCreateActions.fetchDictionariesSuccess())
  } catch (e) {
    console.error(e)
    yield put(schedulePageModalCreateActions.fetchDictionariesError(new Error()))
  }
}

export function* fetchExercisesTypes(action: ReturnType<typeof schedulePageModalCreateActions.fetchExercisesTypes>) {
  const { studioId, roomId } = action.payload

  try {
    const exercisesTypes: Awaited<ReturnType<typeof api.exercisesTypesService.fetchAll>> = yield callApi(
      api.exercisesTypesService.fetchAll,
      { size: 200, studioId, roomId }
    )

    yield put(schedulePageModalCreateActions.fetchExercisesTypesSuccess(exercisesTypes.data))
  } catch (e) {
    yield put(schedulePageModalCreateActions.fetchExercisesTypesError(new Error()))
  }
}

export function* fetchTrainers(action: ReturnType<typeof schedulePageModalCreateActions.fetchTrainers>) {
  const { roomId, timeFrom, timeTo, successCallback } = action.payload

  try {
    const trainersResponse: Awaited<ReturnType<typeof api.trainersService.fetchAll>> = yield callApi(
      api.trainersService.fetchAll,
      {
        size: 300,
        ...(isDef(timeTo) &&
          isDef(timeFrom) && {
            includeAvailability: true,
            exerciseStartsAt: timeFrom,
            exerciseEndsAt: timeTo,
            roomId,
          }),
      }
    )

    yield put(schedulePageModalCreateActions.fetchTrainersSuccess(trainersResponse.data))
    if (successCallback) successCallback(trainersResponse.data.content)
  } catch (e) {
    yield put(schedulePageModalCreateActions.fetchTrainersError(new Error()))
  }
}

export function* schedulePageModalCreateSagas() {
  yield takeLatest(schedulePageModalCreateActions.createExercise, createExercise)
  yield takeLatest(schedulePageModalCreateActions.fetchDictionaries, fetchDictionaries)
  yield takeLatest(schedulePageModalCreateActions.fetchExercisesTypes, fetchExercisesTypes)
  yield takeLatest(schedulePageModalCreateActions.fetchTrainers, fetchTrainers)
}

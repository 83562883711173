import { Button, Col, Form, Input, Row, Space, Switch, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'

export const CategoryForm = (props: any) => {
  const { form, onFinish } = props

  return (
    <Form form={form} autoComplete="off" layout="vertical" onFinish={onFinish}>
      <Form.Item label="Название" name="name" rules={[{ required: true, message: 'Введите название категории' }]}>
        <Input placeholder=""></Input>
      </Form.Item>
      <Form.Item
        label="Описание"
        name="description"
        rules={[{ required: true, message: 'Введите описание' }]}
        style={{ paddingTop: '8px' }}
      >
        <TextArea placeholder=""></TextArea>
      </Form.Item>
      <Row align="middle" gutter={8} style={{ marginTop: '8px' }}>
        <Col flex="none">
          <Form.Item name="hasMarkCode" valuePropName="checked">
            <Switch></Switch>
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Typography.Text>Маркировочные товары</Typography.Text>
        </Col>
      </Row>
      <Row justify="end">
        <Button htmlType="submit" style={{ marginTop: '12px' }} type="primary">
          Сохранить
        </Button>
      </Row>
    </Form>
  )
}

import * as React from 'react'

import { EntityItem, Nullable, isDef } from '../../../../types/lang.types'
import { TableCellText } from '../../../table-cells/table-cell-text/table-cell-text.component'
import { TableCellTooltip } from '../../../table-cells/table-cell-tooltip/table-cell-tooltip.component'
import { formatPluralized } from '../../../../format/text.format'

interface Props {
  availableTypes: Nullable<EntityItem<number>[]>
  hasTypeLimitation: boolean
}

export const ClientsSubscriptionsTableExercisesTypes: React.FC<Props> = props => {
  const { availableTypes, hasTypeLimitation } = props

  if (hasTypeLimitation && isDef(availableTypes)) {
    const types = availableTypes.map(it => it.title).join(', ')
    const text = `${formatPluralized(availableTypes?.length, ['категория', 'категории', 'категорий'])}`

    return <TableCellTooltip title={types} text={text} />
  }

  return <TableCellText text="Все категории" />
}

import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { genExercisesTypesFormValues } from '../../../mapping/exercises-types.mapping'
import { mapDirectionsToOptions } from '../../../mapping/directions.mapping'

const getExercisesTypesInternal = (state: AppState) => state.exercisesTypesEditPage.exercisesType
const getDirectionsInternal = (state: AppState) => state.exercisesTypesEditPage.directions

export const genExercisesTypesEditPageIsLoading = (state: AppState): boolean => state.exercisesTypesEditPage.isLoading

export const getExercisesTypesFormValues = createSelector(getExercisesTypesInternal, genExercisesTypesFormValues)
export const getExercisesTypesEditPageDirectionsOptions = createSelector(getDirectionsInternal, directions =>
  mapDirectionsToOptions(directions?.content)
)

import { SearchOutlined, FilterFilled } from '@ant-design/icons'
import { Dropdown, Flex, DatePicker, Button } from 'antd'
import { Dayjs } from 'dayjs'
import { useState } from 'react'

import { genDefaultDateFormat } from '../../../../format/date.format'
import { Nullable } from '../../../../types/lang.types'

interface Props {
  period?: [Nullable<Dayjs>, Nullable<Dayjs>]
  onChangePeriodHandler: (dates?: [Nullable<Dayjs>, Nullable<Dayjs>]) => void
  onSearchHandler: (resetPeriod?: boolean) => void
}

export const TransactionsTablePeriodFilter: React.FC<Props> = props => {
  const { period, onChangePeriodHandler, onSearchHandler } = props

  const [periodDropdownOpen, setPeriodDropdownOpen] = useState(false)

  return (
    <Dropdown
      overlayClassName="transactions-table__dropdown"
      placement="bottomRight"
      open={periodDropdownOpen}
      onOpenChange={(nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
          setPeriodDropdownOpen(nextOpen)
        }
      }}
      dropdownRender={() => {
        return (
          <div style={{ padding: '8px', maxWidth: '240px' }}>
            <Flex vertical gap={8}>
              <DatePicker.RangePicker
                onClick={e => e.preventDefault()}
                value={period}
                onChange={dates => onChangePeriodHandler(dates)}
                placeholder={['начало периода', 'окончание']}
                format={genDefaultDateFormat()}
              />
              <Button
                htmlType="button"
                type="primary"
                onClick={() => {
                  setPeriodDropdownOpen(false)
                  onSearchHandler()
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: '100%' }}
              >
                Поиск
              </Button>
              <Button
                htmlType="button"
                onClick={() => {
                  onChangePeriodHandler()
                  setPeriodDropdownOpen(false)
                  onSearchHandler(true)
                }}
                size="small"
                style={{ width: '100%' }}
              >
                Сбросить
              </Button>
            </Flex>
          </div>
        )
      }}
      trigger={['click']}
    >
      <FilterFilled style={{ fontSize: '12px', color: period ? '#1890ff' : 'rgba(0, 0, 0, 0.29)' }} />
    </Dropdown>
  )
}

import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'

import { ClientsBookingsTableBaseDataItem } from '../clients-bookings-table-base/clients-bookings-table-base.types'
import { genClientsBookingsTableBaseColumns } from '../clients-bookings-table-base/clients-bookings-table-base.utils'
import { ClientsBookingsTableHistoryActions } from './clients-bookings-table-history-actions/clients-bookings-table-history-actions.component'
import { ClientsBookingsTableHistoryStatus } from './clients-bookings-table-history-status/clients-bookings-table-history-status.component'
import { ClientsBookingsTableHistoryEvents } from './clients-bookings-table-history.types'

export function genClientsBookingsTableHistoryColumns(
  events: ClientsBookingsTableHistoryEvents
): ColumnsType<ClientsBookingsTableBaseDataItem> {
  const { onBarcode, onBooking, onHistory, onChangePaymentType } = events

  return [
    ...genClientsBookingsTableBaseColumns({
      onChangePaymentType,
    }),
    {
      title: 'Статус',
      key: 'status',
      width: 150,
      render: (_, booking) => {
        const { visitConfirmed, canceled, cancelledBy, canceledAt } = booking

        return (
          <>
            {canceled ? (
              <Tooltip
                title={
                  cancelledBy && cancelledBy.name
                    ? `${cancelledBy.name} отменил(а) в ${dayjs(canceledAt).format('HH:mm DD MMMM YYYY')}`
                    : `Клиент отменил запись в ${dayjs(canceledAt).format('HH:mm DD MMMM YYYY')}`
                }
              >
                <div>
                  <ClientsBookingsTableHistoryStatus isCanceled={canceled} isVisitConfirmed={visitConfirmed} />
                </div>
              </Tooltip>
            ) : (
              <div>
                <ClientsBookingsTableHistoryStatus isCanceled={canceled} isVisitConfirmed={visitConfirmed} />
              </div>
            )}
          </>
        )
      },
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, booking) => {
        const { id, exerciseId, studio } = booking

        return (
          <ClientsBookingsTableHistoryActions
            bookingId={id}
            exerciseId={exerciseId}
            onBarcode={onBarcode}
            onBooking={onBooking}
            onHistory={onHistory}
            studioId={studio?.id}
          />
        )
      },
    },
  ]
}

import { LOCAL_STORAGE_CURRENT_STUDIO_KEY } from '@constants/local-storage'
import { useCurrentOfflineTill } from '@hooks/tills/use-current-offline-till.hook'
import { useNotConnectedOfflineTills } from '@hooks/tills/use-not-connected-offline-tills.hook'
import { useOfflineTillBalance } from '@hooks/tills/use-offline-till-balance.hook'
import { useOfflineTillShifts } from '@hooks/tills/use-offline-till-shifts.hook'
import { notificationsTills } from '@utils/notifications/notifications-tills.utils'
import { Card, Flex, Typography } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { StudiosApi } from '@api/types/studios-api.types'
import { api } from '@api/api'

import { OfflineTillShiftStatus } from './ui/offline-till-shift-status/offline-till-shift-status.component'
import { OnlineTillShiftStatus } from './ui/online-till-shift-status/online-till-shift-status.component'
import { ITransactionsShiftProps } from './transactions-shift.types'
import { TStatus } from './types/status'
import { ShiftSelect } from './ui/shift-select/shift-select.component'
import { closeShift } from './utils/shift/close-shift'
import { openShift } from './utils/shift/open-shift'
import { fetchOfflineTills } from './utils/tills/fetch-tills'
import { printXReport } from './utils/x-report/print-x-report'

import './transactions-shift.styles.less'

export const TransactionsShift: FC<ITransactionsShiftProps> = () => {
  // SECTION: State
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isBlockTillsUpdate, setIsBlockTillsUpdate] = useState(false)
  const hasFirstUpdate = useRef(false)

  const [status, setStatus] = useState<TStatus>('close')
  const [offlineTillsOptions, setOfflineTillsOptions] = useState<DefaultOptionType[]>([])
  const [selectedOfflineTill, setSelectedOfflineTill] = useState<string | null>(null)
  const [isNotConnectedOfflineTill, setIsNotConnectedOfflineTill] = useState(false)
  const [currentStudio, setCurrentStudio] = useState<null | undefined | StudiosApi.Studio>(null)

  // SECTION: Custom hooks
  const [setOfflineTills, currentOfflineTill] = useCurrentOfflineTill(selectedOfflineTill)
  const [currentOfflineTillBalance] = useOfflineTillBalance(currentOfflineTill)
  const [successCallback, notConnectedOfflineTills] = useNotConnectedOfflineTills()
  const [currentOfflineTillShifts] = useOfflineTillShifts(selectedOfflineTill)

  const studioId = localStorage.getItem(LOCAL_STORAGE_CURRENT_STUDIO_KEY)

  const connectedOfflineTillsOptions = useMemo(() => {
    return offlineTillsOptions.filter(till => !notConnectedOfflineTills.includes(String(till.value)))
  }, [offlineTillsOptions, notConnectedOfflineTills])

  // SECTION: Computed
  const isHasOfflineTill = useMemo(() => {
    if (!currentStudio) return false
    return currentStudio?.organization?.offlineTillEnabled
  }, [currentStudio])

  // SECTION: Actions
  const handleChangeValue = (value: string) => {
    setSelectedOfflineTill(value)
    if (notConnectedOfflineTills.includes(value)) {
      notificationsTills.notConnected()
      setIsNotConnectedOfflineTill(true)
      return
    }

    setIsNotConnectedOfflineTill(false)
  }

  const successOpenShift = () => setStatus('open')
  const successCloseShift = () => setStatus('close')

  const handleOpenShift = async () =>
    await openShift(selectedOfflineTill, currentOfflineTill?.numDevice, currentOfflineTill?.idDevice, successOpenShift)

  const handleCloseShift = async () =>
    await closeShift(
      selectedOfflineTill,
      currentOfflineTill?.lastTillShift?.id,
      currentOfflineTill?.numDevice,
      currentOfflineTill?.idDevice,
      successCloseShift
    )

  const handleXReport = async () => {
    await printXReport(currentOfflineTill?.numDevice)
  }

  const updateOfflineTills = async () => {
    const response = await fetchOfflineTills(
      setOfflineTills,
      setOfflineTillsOptions,
      setIsLoading,
      setHasError,
      studioId,
      successCallback
    )

    if (response?.hasNotAddedTill && offlineTillsOptions.length === 0) {
      setStatus('add-new')
    }
  }

  // SECTION: Life cycle
  useEffect(() => {
    // If offline tills disabled not updated tills
    if (!isHasOfflineTill) return

    // Only for offline till
    if (!isBlockTillsUpdate) {
      updateOfflineTills()
    } else {
      setTimeout(() => {
        setIsBlockTillsUpdate(false)
      }, 300)
    }

    if (!hasFirstUpdate.current) {
      setIsBlockTillsUpdate(true)
      hasFirstUpdate.current = true
    }
  }, [selectedOfflineTill, status, isHasOfflineTill])

  useEffect(() => {
    currentOfflineTill?.tillShiftOpened ? setStatus('open') : setStatus('close')
  }, [currentOfflineTill])

  useEffect(() => {
    const setStudioById = async () => {
      if (!studioId) return
      const studio = await api.studiosService.fetchById(studioId)
      setCurrentStudio(studio?.data)
    }

    setStudioById()
  }, [])

  return (
    <Flex align="center" justify="center" className="shift-card__wrapper" data-component-name="TransactionsShift">
      <Card className="shift-card">
        {offlineTillsOptions.length > 0 && isHasOfflineTill && (
          <ShiftSelect
            options={offlineTillsOptions}
            connectedOfflineTillsOptions={connectedOfflineTillsOptions}
            isLoading={isLoading}
            value={selectedOfflineTill}
            handleChangeValue={handleChangeValue}
            isDisabled={hasError || isLoading}
          />
        )}

        {isHasOfflineTill ? (
          <OfflineTillShiftStatus
            offlineTillsLength={offlineTillsOptions.length}
            isDisabledActions={hasError || isLoading || !selectedOfflineTill || isNotConnectedOfflineTill}
            status={status}
            handleOpenShift={handleOpenShift}
            handleCloseShift={handleCloseShift}
            handleXReport={handleXReport}
            shifts={currentOfflineTillShifts}
          />
        ) : (
          <OnlineTillShiftStatus shifts={currentOfflineTillShifts} />
        )}

        <Flex justify="center">
          <Flex vertical gap="small">
            <Flex gap="large" align="center">
              <Flex vertical>
                <Typography.Paragraph className="shift-card__suptitle">Наличные</Typography.Paragraph>
                <Typography.Title level={5} className="shift-card__title">
                  {currentOfflineTillBalance.cash}
                </Typography.Title>
              </Flex>
              <Flex vertical>
                <Typography.Paragraph className="shift-card__suptitle">Картой</Typography.Paragraph>
                <Typography.Title level={5} className="shift-card__title">
                  {currentOfflineTillBalance.card}
                </Typography.Title>
              </Flex>
              <Flex vertical>
                <Typography.Paragraph className="shift-card__suptitle">Онлайн-касса</Typography.Paragraph>
                <Typography.Title level={5} className="shift-card__title">
                  {currentOfflineTillBalance.online}
                </Typography.Title>
              </Flex>

              <div className="shift-card__divider" />

              <Flex vertical>
                <Typography.Paragraph className="shift-card__suptitle">Итого</Typography.Paragraph>
                <Typography.Title level={5} className="shift-card__title">
                  {currentOfflineTillBalance.total}
                </Typography.Title>
              </Flex>
            </Flex>

            <Flex gap="middle" align="center">
              <p className="shift-card__additional-info">Возвраты по кассе -{currentOfflineTillBalance.refund}</p>
              <p className="shift-card__additional-info">Денег в кассе {currentOfflineTillBalance.cash}</p>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  )
}

// @ts-nocheck
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FC, useEffect, useMemo, useState } from 'react'
import { Button, Flex, Typography, Card, Form, Collapse, Alert } from 'antd'
import { employeesScheduleCreateActions } from '@store/pages/employees/employees-schedule-create/employees-schedule-create.slice'
import { initEmployeeScheduleForm } from '@utils/form/init-employee-schedule-form'
import { IWorkTimeScheduleStudio } from '@components/employees/schedule/work-time-schedule/work-time-schedule.types'
import { WorkTimeSchedule } from '@components/employees/schedule/work-time-schedule/work-time-schedule.component'
import {
  EMPLOYEE_SCHEDULE_STATUS,
  EMPLOYEE_SCHEDULE_FOR_ENTITY,
  EMPLOYEE_SCHEDULE_EDIT_MODE,
} from '@constants/employees/schedule'
import { NEmployeesScheduleApi } from '@api/types/employees-schedule-api.types'
import { EmployeeScheduleMainInfoForm } from '@components/employees/schedule/main-info-form/main-info-form.component'
import { ModalConfirmBack } from '@components/modals/modal-confirm-back/modal-confirm-back.component'
import { useModal } from '@hooks/use-modal.hook'
import { FinalScheduleTable } from '@components/employees/schedule/final-schedule-table/final-schedule-table.component'
import { VideoCameraTwoTone } from '@ant-design/icons'
import { useTimeSlotsFromFinalScheduleStudios } from '@hooks/employees/schedule/use-time-slots-from-final-schedule-studios'
import { transformScheduleStudioToFinalScheduleStudio } from '@components/employees/schedule/final-schedule-table/utils/transform'

import { IEmployeeScheduleCreateLocation } from './employees-schedule-create.types'
import { Breadcrumbs } from './ui/breadcrumbs/breadcrumbs.component'
import './employees-schedule-create.styles.less'
import { EmployeeScheduleDaysOffForm } from '../../../components/employees/schedule/days-off-form/days-off-form.component'
import { getEmployeesScheduleCreateIsLoading } from '../../../store/pages/employees/employees-schedule-create/employees-schedule-create.selectors'

export const EmployeesScheduleCreatePage: FC = () => {
  // SECTION: Hooks
  const history = useHistory()
  const location = useLocation<IEmployeeScheduleCreateLocation>()
  const dispatch = useDispatch()

  const [form] = Form.useForm()
  const [daysOffForm] = Form.useForm()
  const employeeNameWatch = Form.useWatch('employee', form)
  const positionNameWatch = Form.useWatch('position', form)

  // SECTION: Store
  const loading = useSelector(getEmployeesScheduleCreateIsLoading)

  // SECTION: State
  const [schedule, setSchedule] = useState<NEmployeesScheduleApi.IEmployeeSchedule | null>(null)
  const [schedulesStudios, setSchedulesStudios] = useState<IWorkTimeScheduleStudio[]>([])
  const [error, setError] = useState<string | null>(null)

  // SECTION: Actions
  const handleChangeWorkTimeSchedule = (schedules: IWorkTimeScheduleStudio[]) => setSchedulesStudios(schedules)

  const createSchedule = async () => {
    let hasError = false
    await form.validateFields().catch(err => {
      setError('Заполните все поля!')
      hasError = true
    })
    await daysOffForm.validateFields().catch(err => {
      setError('Заполните все поля!')
      hasError = true
    })
    if (Object.keys(timeRangeSlot).length === 0 && !hasError) {
      setError('Заполните расписание!')
      hasError = true
    }

    if (hasError) return

    const formValues = form.getFieldsValue()

    const schedule = {
      employeeId: null,
      employeePositionId: null,
      validFrom: formValues.schedulePeriod[0].format('YYYY-MM-DD'),
      validTo: formValues.schedulePeriod[1].format('YYYY-MM-DD'),
      status: EMPLOYEE_SCHEDULE_STATUS.ACTIVE,
      slots: {
        dailyWorkTime: timeRangeSlot,
      },
    }

    const daysOffFormValues = daysOffForm.getFieldsValue()

    switch (formValues.scheduleForEntity) {
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE:
        schedule.employeeId = formValues.employeeId
        break
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION:
        schedule.employeePositionId = formValues.employeePositionId
        break
    }

    dispatch(
      employeesScheduleCreateActions.create({
        // @ts-ignore
        schedule,
        daysOff: daysOffFormValues.daysOff,
        successCallback: () => history.push('/employees/schedule'),
      })
    )
  }

  const back = () => history.go(-1)

  // SECTION: Computed
  const totalScheduleName = useMemo(() => {
    const formValues = form.getFieldsValue()
    let name = ''

    switch (formValues.scheduleForEntity) {
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.EMPLOYEE:
        name = formValues.employee
        break
      case EMPLOYEE_SCHEDULE_FOR_ENTITY.POSITION:
        name = formValues.position
        break
    }

    return `Итоговое расписание для ${name}`
  }, [employeeNameWatch, positionNameWatch])

  const finalScheduleStudios = useMemo(() => {
    return transformScheduleStudioToFinalScheduleStudio(schedulesStudios)
  }, [schedulesStudios])

  // SECTION: Custom hooks
  const [isShowModalConfirmBack, handleOpenModalConfirmBack, handleCloseModalConfirmBack, handleOkModalConfirmBack] =
    useModal(back)

  const timeRangeSlot = useTimeSlotsFromFinalScheduleStudios(finalScheduleStudios)

  // SECTION: Effects
  useEffect(() => {
    const schedule = location?.state?.schedule
    setSchedule(schedule)

    initEmployeeScheduleForm(schedule, form)
  }, [location])

  return (
    <Flex gap="large" vertical>
      <Breadcrumbs mode={location?.state?.mode} />
      <Flex gap="small" justify="space-between" align="center" className="employees-schedule-edit__header">
        <Typography.Title className="employees-schedule-edit__title" level={2}>
          {location?.state?.mode === EMPLOYEE_SCHEDULE_EDIT_MODE.COPY
            ? 'Копирование расписания'
            : 'Создание расписания'}
        </Typography.Title>
      </Flex>
      <Card>
        <Flex vertical gap="small">
          <Typography.Title level={4} className="employees-schedule-edit__title">
            Основная информация
          </Typography.Title>

          <EmployeeScheduleMainInfoForm form={form} />
        </Flex>
      </Card>
      <Card>
        <Flex vertical gap="middle">
          <Flex gap="small" align="center">
            <Typography.Title level={4} className="employees-schedule-edit__title">
              Настройка графика
            </Typography.Title>

            <Button icon={<VideoCameraTwoTone />} size="small" type="dashed">
              Как заполнять график?
            </Button>
          </Flex>

          <WorkTimeSchedule onChange={handleChangeWorkTimeSchedule} defaultWorkTime={schedule?.slots?.dailyWorkTime} />
        </Flex>
      </Card>
      <Collapse
        className="employees-schedule-edit__collapse"
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: (
              <Typography.Title level={5} className="employees-schedule-edit__title">
                {totalScheduleName}
              </Typography.Title>
            ),
            children: <FinalScheduleTable finalScheduleStudios={finalScheduleStudios} />,
          },
        ]}
      />
      <Card>
        <Flex vertical gap="10px">
          <Typography.Title level={4} className="employees-schedule-edit__title">
            Выходные дни и перерывы
          </Typography.Title>
          <EmployeeScheduleDaysOffForm form={daysOffForm} loading={loading} />
        </Flex>
      </Card>
      {error && <Alert message={error} type="error" showIcon />}
      <Flex gap="small" justify="flex-end">
        <Button onClick={handleOpenModalConfirmBack}>Закрыть</Button>
        <Button type="primary" onClick={createSchedule}>
          Создать
        </Button>
      </Flex>
      <ModalConfirmBack
        isModalOpen={isShowModalConfirmBack}
        handleOk={handleOkModalConfirmBack}
        handleCancel={handleCloseModalConfirmBack}
      />
    </Flex>
  )
}

import { AxiosResponse } from 'axios'
import { SearchApi } from '@api/types/search-api.types'

import { HttpConnector } from '../connectors/http.connector'
import { NString, Nullable } from '../../types/lang.types'
import { Pagination } from '../types/api.types'
import { EmployeesApi } from '../types/employees-api.types'

export class EmployeesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public create = (data: EmployeesApi.EmployeeCreateDTO): Promise<AxiosResponse<EmployeesApi.Employee>> => {
    return this.httpConnector.post<EmployeesApi.Employee>('/employees', data)
  }

  public fetchAll = (
    params: Nullable<Partial<EmployeesApi.EmployeesFetchAllParams>>,
    type?: NString
  ): Promise<AxiosResponse<Pagination<EmployeesApi.Employee>>> => {
    return this.httpConnector.get<Pagination<EmployeesApi.Employee>>(`${type ? `/employees/${type}` : '/employees'}`, {
      params,
    })
  }

  public search = (value: string): Promise<AxiosResponse<SearchApi.SearchItem[]>> => {
    return this.httpConnector.get(`/search/employees?q=${value}`)
  }

  public fetchById = (id: string): Promise<AxiosResponse<EmployeesApi.Employee>> => {
    return this.httpConnector.get<EmployeesApi.Employee>(`/employees/${id}`)
  }

  public update = (id: string, data: EmployeesApi.EmployeeUpdateDTO): Promise<AxiosResponse<EmployeesApi.Employee>> => {
    return this.httpConnector.patch<EmployeesApi.Employee>(`/employees/${id}`, data)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/employees/${id}`)
  }

  public getCurrent = (): Promise<AxiosResponse<EmployeesApi.CurrentEmployee>> => {
    return this.httpConnector.get(`/employees/current`)
  }

  public updateCurrent = (
    params: Partial<EmployeesApi.UpdateCurrentEmployee>
  ): Promise<AxiosResponse<EmployeesApi.CurrentEmployee>> => {
    return this.httpConnector.put(`/employees/current`, {
      ...params,
    })
  }
}

import { DictionaryItem } from '@api/types/api.types'
import { Flex, Tag } from 'antd'

import { Nullable } from '../../../../types/lang.types'

interface Props {
  availableTypes: Nullable<DictionaryItem[]>
}

export const ProductsSubscriptionsTableColumnTypes = ({ availableTypes }: Props) => {
  return (
    <Flex gap={8} style={{ flexDirection: 'column' }}>
      {availableTypes?.map(type => (
        <Tag style={{ width: 'fit-content' }} color="magenta" key={type.id}>
          {type.name}
        </Tag>
      ))}
    </Flex>
  )
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { customFieldsPageActions } from '../../store/pages/custom-fields-page/custom-fields-page.slice'
import { useCustomFieldsPageParams } from './custom-fields-page-hooks/custom-fields-page-params.hook'
import { getCustomFieldsResource } from '../../store/pages/custom-fields-page/custom-fields-page.selectors'
import { CustomFieldsApi } from '../../api/types/custom-fields-api.types'

export function useCustomFieldsPage() {
  const dispatch = useDispatch()
  const resource = useSelector(getCustomFieldsResource)
  useEffect(() => {
    dispatch(customFieldsPageActions.fetchAllCustomFields())
  }, [dispatch, resource])

  useEffect(() => {
    return () => {
      dispatch(customFieldsPageActions.reset())
    }
  }, [dispatch])
}

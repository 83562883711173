import * as React from 'react'
import { Spin, Tooltip } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import clsx from 'clsx'

import { ClientsTransactionsTableDataItem } from '../clients-transactions-table.types'
import { firstItem } from '../../../../utils/list.utils'
import '../clients-transactions-table.styles.less'
import { Nullable } from '../../../../types/lang.types'
import { ClientsBookingsApi } from '../../../../api/types/clients-bookings-api.types'
import { formatDate } from '../../../../format/date.format'

export const ClientsTransactionsTableUsedInfo: React.FC<{
  transaction: ClientsTransactionsTableDataItem
  onHoverUsed: (bookingId: string) => void
  isUsedBookingLoading: boolean
  usedBooking: Nullable<ClientsBookingsApi.ClientBooking>
}> = props => {
  const { transaction, onHoverUsed, isUsedBookingLoading, usedBooking } = props

  const [isOpen, setIsOpen] = React.useState(false)

  const ref = React.useRef()

  const bookingId = firstItem(transaction.products!)?.paymentBookingIds[0]
  const isUsed = firstItem(transaction.products)?.used

  React.useEffect(() => {
    if (bookingId && isOpen) {
      onHoverUsed(bookingId)
    }
  }, [bookingId, isOpen])

  if (!bookingId) {
    return (
      <span className={clsx('clients-transaction-table__used-label')}>
        {isUsed ? 'использовано' : 'не использовано'}
      </span>
    )
  }

  return (
    <Tooltip
      ref={ref}
      onOpenChange={setIsOpen}
      title={
        <div
          style={{
            width: 200,
          }}
        >
          {isUsedBookingLoading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 35 }} spin />} />
          ) : (
            <div>
              {formatDate(transaction.createDate, 'DD MMMM в HH:mm')} на запись {usedBooking?.exerciseDirection.name}{' '}
              {usedBooking?.exerciseDate ? formatDate(usedBooking?.exerciseDate!, 'DD MMMM в HH:mm') : ''}
            </div>
          )}
        </div>
      }
    >
      <span
        className={clsx(
          'clients-transaction-table__used-label',
          bookingId && 'clients-transaction-table__used-label_link'
        )}
      >
        {isUsed ? 'использовано' : 'не использовано'}
      </span>
    </Tooltip>
  )
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { MasterServicesPagePeekHistoryFetchAuditPayload } from './master-services-page-peek-history.types'

export interface MasterServicesPagePeekHistoryState {
  masterServiceAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: MasterServicesPagePeekHistoryState = {
  masterServiceAudit: null,
  isLoading: false,
  error: null,
}

export const { actions: masterServicesPagePeekHistoryActions, reducer: masterServicesPagePeekHistoryReducer } =
  createSlice({
    name: '@@master-services-page-peek-history',
    initialState,
    reducers: {
      fetchAudit: (
        state: Draft<MasterServicesPagePeekHistoryState>,
        _: PayloadAction<MasterServicesPagePeekHistoryFetchAuditPayload>
      ): void => {
        state.isLoading = true
      },
      fetchAuditSuccess: (
        state: Draft<MasterServicesPagePeekHistoryState>,
        action: PayloadAction<Pagination<AuditApi.Audit>>
      ): void => {
        state.masterServiceAudit = action.payload
        state.isLoading = false
      },
      fetchAuditError: (state: Draft<MasterServicesPagePeekHistoryState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  })

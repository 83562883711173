import * as React from 'react'
import { Button, Col, Row } from 'antd'
import { Link } from 'react-router-dom'

import { genClientsCreatePagePath } from '../../../format/path.format'

export const ClientsPageTopBar = () => {
  return (
    <Row justify="start" align="middle" style={{ marginBottom: 20 }}>
      <Col>
        <Link to={genClientsCreatePagePath()} className="menu-item">
          <Button type="primary">Добавить Клиента</Button>
        </Link>
      </Col>
    </Row>
  )
}

import { useEffect, FC } from 'react'
import { Form, Select } from 'antd'
import { useOfflineTillsOptions } from '@hooks/tills/use-offline-tills-options.hook.'
import { useLocalStorage } from 'react-use'
import { LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY } from '@constants/local-storage'

import { ITransactionsTillsSelectProps } from './transactions-tills-select.types'

export const TransactionsTillsSelect: FC<ITransactionsTillsSelectProps> = ({ form, setOfflineTills }) => {
  // State
  const [offlineTillsOptions, isOfflineTillsLoading] = useOfflineTillsOptions(true, setOfflineTills)
  const [valueFromLocalStorage] = useLocalStorage<string | null>(LOCAL_STORAGE_CURRENT_OFFLINE_TILL_KEY)

  // Set default offline till
  useEffect(() => {
    if (offlineTillsOptions?.length) {
      if (valueFromLocalStorage && offlineTillsOptions.some(tillOption => tillOption.value === valueFromLocalStorage)) {
        form.setFieldsValue({ offlineTillId: String(valueFromLocalStorage) })
        return
      }

      const defaultValue = offlineTillsOptions[0].value
      if (!defaultValue) return

      form.setFieldsValue({ offlineTillId: String(defaultValue) })
    }
  }, [offlineTillsOptions])

  if (!offlineTillsOptions?.length) return null
  return (
    <Form.Item
      className="transactions-form-fields__offline-tills"
      label="Название кассы для проведения оплаты"
      name="offlineTillId"
      required
    >
      <Select
        options={offlineTillsOptions}
        placeholder="Название кассы для проведения оплаты"
        loading={isOfflineTillsLoading}
      />
    </Form.Item>
  )
}

import { ContactsOutlined, EditOutlined, ThunderboltOutlined, UserOutlined } from '@ant-design/icons'
import { Flex, List, Typography } from 'antd'
import { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PaginationConfig } from 'antd/lib/pagination'
import clsx from 'clsx'
import dayjs from 'dayjs'

import { AuditAction } from '../../types/audit.types'
import { HistoryItem, HistoryListProps } from './history-list.types'
import './history-list.styles.less'
import { isDef, isDefAndNotEmpty, NString, Nullable } from '../../types/lang.types'
import { AuditApi } from '../../api/types/audit.types'
import { peekActions } from '../../store/common/peek/peek.slice'
import { formatPhoneNumber } from '../../format/phone.format'
import {
  formatAuditAction,
  formatHistoryListItemChangesFieldName,
  formatHistoryListItemChangesValue,
  formatHistoryListItemParameterObjectType,
} from './history-list.utils'
import { getCurrentStudioOffset } from '../../store/common/layout/layout.selectors'

export const HistoryList: FC<HistoryListProps> = props => {
  const { title, type, history, pagination, studios, rooms, trainers, onChangePage, onChangePageSize } = props

  const { push } = useHistory()
  const dispatch = useDispatch()

  dayjs.locale('ru')

  const studioOffset = useSelector(getCurrentStudioOffset)

  const paginationConfig = useMemo(
    (): PaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  const handleLink = (subjectType: string, id: NString) => {
    if (id) {
      dispatch(peekActions.closeLast())
      push(`/${subjectType}/${id}`)
    }
  }

  const genHistoryListItemIcon = (item: HistoryItem) => {
    if (item.subject.type === 'CLIENT') {
      return <UserOutlined />
    }
    if (item.action === AuditAction.CREATE) {
      return <ContactsOutlined />
    }
    if (
      item.subject.type === 'SERVER' ||
      item.changes.find(change => change.field === 'transaction' || change.field === 'paymentDate')
    ) {
      return <ThunderboltOutlined />
    }
    return <EditOutlined />
  }

  const genHistoryListItemSubject = (subject: AuditApi.Subject) => {
    if (subject.type === 'SERVER') {
      return <>Платформа</>
    }
    if (subject.type === 'CLIENT') {
      return (
        <span className="history-list__link" onClick={() => handleLink('clients', subject.subjectId)}>
          Клиент
        </span>
      )
    }
    if (subject.type === 'EMPLOYEE') {
      return (
        <span className="history-list__link" onClick={() => handleLink('employees', subject.subjectId)}>
          {subject.displayName}
        </span>
      )
    }
    return null
  }

  const genHistoryListItemParameterObject = (item: HistoryItem) => {
    const { parameterObject, subject } = item

    if (!isDef(parameterObject) || subject.type === 'CLIENT') return null

    return (
      <>
        {formatHistoryListItemParameterObjectType(parameterObject.type)}{' '}
        <span
          className={clsx({
            'history-list__link': parameterObject.clientId || parameterObject.type === 'TRAINER',
          })}
          onClick={() => {
            if (parameterObject.clientId) {
              handleLink('clients', parameterObject.clientId)
            } else if (parameterObject.type === 'TRAINER') {
              handleLink('employees', parameterObject.objectId)
            } else return
          }}
        >
          {parameterObject.clientId && parameterObject.displayName
            ? formatPhoneNumber(`+${parameterObject.displayName}`)
            : parameterObject.displayName}
        </span>
      </>
    )
  }

  const genHistoryListItemActionObject = (actionObject: Nullable<AuditApi.ActionObject>) => {
    if (!isDef(actionObject) || actionObject.type !== 'SUBSERVICEPRODUCT') return null

    return <>для {actionObject.displayName}</>
  }

  const genHistoryListItemChanges = (changes: AuditApi.Changes[]) => {
    if (isDefAndNotEmpty(changes)) {
      return changes.map(change => {
        const { field, oldValue, newValue } = change

        const fieldName = formatHistoryListItemChangesFieldName(field, type)
        const formattedOldValue = formatHistoryListItemChangesValue(
          field,
          type,
          oldValue,
          studioOffset,
          studios,
          rooms,
          trainers
        )
        const formattedNewValue = formatHistoryListItemChangesValue(
          field,
          type,
          newValue,
          studioOffset,
          studios,
          rooms,
          trainers
        )

        const imageFields = ['photo', 'mainPhoto', 'photos', 'locationPhotoUrl', 'qrUrl']

        if (field === 'commentModifiedBy' || field === 'commentModifiedAt') return null
        if (imageFields.includes(field)) {
          return (
            <>
              <span className="history-list__link" onClick={() => window.open(newValue.value, '_blank')}>
                {fieldName}
              </span>
              ,{' '}
            </>
          )
        }
        if (oldValue.value === 'null' || !isDef(oldValue)) {
          return (
            <>
              {fieldName} на {formattedNewValue},{' '}
            </>
          )
        }
        return (
          <>
            {fieldName} <span style={{ textDecoration: 'line-through' }}>{formattedOldValue}</span> →{' '}
            {formattedNewValue},{' '}
          </>
        )
      })
    }
    return null
  }

  return (
    <div className="history-list">
      {title && (
        <Typography.Title className="history-list__title" level={3}>
          {title}
        </Typography.Title>
      )}
      <List
        dataSource={history}
        pagination={paginationConfig}
        renderItem={(item, index) => {
          if (item.changes?.length === 1 && item.changes[0].field === 'commentModifiedAt') return null
          return (
            <List.Item className="history-list__item">
              <Flex gap="small" key={index}>
                {genHistoryListItemIcon(item)}
                <Typography.Text>
                  {genHistoryListItemSubject(item.subject)} {genHistoryListItemActionObject(item.actionObject)}{' '}
                  {formatAuditAction(item.action, item.subject.type)}{' '}
                  {item.action === AuditAction.UPDATE &&
                    item.parameterObject &&
                    item.subject.type !== 'CLIENT' &&
                    'для'}{' '}
                  {genHistoryListItemParameterObject(item)} {genHistoryListItemChanges(item.changes)}
                  {item.date} в {item.time}
                </Typography.Text>
              </Flex>
            </List.Item>
          )
        }}
      />
    </div>
  )
}

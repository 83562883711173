import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { PositionsTableActions, PositionsTableDataItem } from './positions-table.types'
import { PositionsTableColumnActions } from './positions-table-actions/positions-table-actions.component'

export function genPositionsTableColumns(events: PositionsTableActions): ColumnsType<PositionsTableDataItem> {
  const { positionsOptions, onEdit, onRemove } = events

  return [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_, position) => <TableCellText text={String(position.name)} />,
    },
    {
      title: 'Кол-во грейдов',
      dataIndex: 'gradesCount',
      key: 'gradesCount',
      render: (_, position) => <TableCellText text={position.gradesCount ? position.gradesCount.toString() : '-'} />,
    },
    {
      title: 'Операции',
      key: 'action',
      width: 120,
      render: (_, position) => (
        <PositionsTableColumnActions
          id={position.id}
          positionType={position.positionType}
          name={position.name}
          positionsOptions={positionsOptions}
          onEdit={onEdit}
          onRemove={onRemove}
        />
      ),
    },
  ]
}

export enum FIGURE_MODE {
  FULL = 'FULL',
  HALF = 'HALF',
  DELETED = 'DELETED',
}

export enum FIGURE_TYPE {
  CIRCLE = 'CIRCLE',
  SQUARE = 'SQUARE',
}

export enum FIGURE_DIRECTION {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum FIGURE_BUTTON_EVENT_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

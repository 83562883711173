import * as React from 'react'

import { isDef } from '../../../types/lang.types'
import { ClientsInfo } from '../../../components/clients/clients-info/clients-info.component'
import { useClientsEditPageInfo } from './clients-edit-page-info.hook'

export const ClientsEditPageInfo: React.FC = () => {
  const { isUpdating, clientInfo, managersOptions, onChangeManagerHandler, onHistoryHandler } = useClientsEditPageInfo()

  if (isDef(clientInfo)) {
    return (
      <ClientsInfo
        isUpdating={isUpdating}
        clientInfo={clientInfo}
        managersOptions={managersOptions}
        onChangeManager={onChangeManagerHandler}
        onHistory={onHistoryHandler}
      />
    )
  }

  return null
}

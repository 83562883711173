import { Tabs } from 'antd'
import * as React from 'react'

import { useScheduleGroupPageMenu } from './schedule-group-page-menu.hook'

export const ScheduleGroupPageMenu: React.FC = () => {
  const { section, menuItems, onChangeHandler } = useScheduleGroupPageMenu()

  return <Tabs type="card" items={menuItems} activeKey={section} onChange={onChangeHandler} />
}

import * as React from 'react'
import { Input } from 'antd'

import { TransactionsProductsTableMarkCodesEvents } from './transactions-products-table-mark-codes.types'

interface Props extends TransactionsProductsTableMarkCodesEvents {
  productUuid: string
  index: number
  value?: string[]
}

export const TransactionsProductsMarkCodes: React.FC<Props> = props => {
  const { productUuid, index, value, onChangeMarkCodes } = props

  const onChangeHandler = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (value) {
        const markCodes = structuredClone(value)
        markCodes[index] = event.target.value
        onChangeMarkCodes(productUuid, markCodes)
      } else {
        onChangeMarkCodes(productUuid, [event.target.value])
      }
    },
    [index, onChangeMarkCodes, productUuid, value]
  )

  return <Input value={value && value[index]} onChange={onChangeHandler} allowClear placeholder="Маркировка" />
}

import {
  genEmployeesFormBirthDateValidationRules,
  genEmployeesFormEmailValidationRules,
  genEmployeesFormFirstNameValidationRules,
  genEmployeesFormLastNameValidationRules,
  genEmployeesFormOrganisationValidationRules,
  genEmployeesFormPhoneValidationRules,
  genEmployeesFormPositionValidationRules,
} from './employees-form.utils'

export function useEmployeesForm() {
  const firstNameValidationRules = genEmployeesFormFirstNameValidationRules()
  const lastNameValidationRules = genEmployeesFormLastNameValidationRules()
  const birthDateValidationRules = genEmployeesFormBirthDateValidationRules()
  const phoneValidationRules = genEmployeesFormPhoneValidationRules()
  const emailValidationRules = genEmployeesFormEmailValidationRules()
  const positionValidationRules = genEmployeesFormPositionValidationRules()
  const organisationValidationRules = genEmployeesFormOrganisationValidationRules()

  return {
    firstNameValidationRules,
    lastNameValidationRules,
    birthDateValidationRules,
    phoneValidationRules,
    emailValidationRules,
    positionValidationRules,
    organisationValidationRules,
  }
}

import { formatNameColorsToHex } from '@utils/colors.utils'

import { Nullable } from '../../../../../../../types/lang.types'
import { getFigureTimeRange } from '../../../utils/time'
import { TAG_TEXTS, TAG_TYPES } from '../../../constants/tag'
import { ICurrentStudioSlice } from '../../../work-time-schedule.types'
import { IFigure, IFigureDetails } from '../hour-cell.types'
import { DEFAULT_FIGURE_DETAILS } from '../constants/figure'

export const getFigureDetails = (
  studio: Nullable<Pick<ICurrentStudioSlice, 'selectedRoom' | 'name'>>,
  column: number,
  figureOptions: Nullable<Pick<IFigure, 'color' | 'tag' | 'direction' | 'mode'>>
): IFigureDetails => {
  if (!figureOptions) return DEFAULT_FIGURE_DETAILS

  const figureTimeRange = getFigureTimeRange(String(column), figureOptions.direction, figureOptions.mode)
  const details = {
    roomName: studio?.selectedRoom?.name ?? 'Неизвестная комната',
    studioName: studio?.name ?? 'Неизвестный студия',
    timeRange: figureTimeRange,
    color: figureOptions?.color ?? formatNameColorsToHex('blue', 1),
  }

  if (figureOptions.tag.type === TAG_TYPES.ALL_ROOMS) details.roomName = TAG_TEXTS.ALL_ROOMS
  if (figureOptions.tag.type === TAG_TYPES.ALL_STUDIOS) {
    details.studioName = TAG_TEXTS.ALL_STUDIOS
    details.roomName = TAG_TEXTS.ALL_ROOMS
  }

  return details
}

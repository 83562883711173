import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { EmployeesHistoryPeekProps } from './employees-history-peek.types'

export const EmployeesHistoryPeek: FC<EmployeesHistoryPeekProps> = props => {
  const { isLoading, employeeHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(employeeHistory) ? (
        <HistoryList
          title="История действий с карточкой сотрудника"
          type={HistoryListType.EMPLOYEE}
          history={employeeHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

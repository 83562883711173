import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { usePositionsPageParams } from './positions-page-hooks/positions-page-params.hook'
import { positionsPageActions } from '../../store/pages/positions-page/positions.slice'
import { genPositionsIsLoading, genPositionsIsLoaded } from '../../store/pages/positions-page/positions-page.selectors'

export function usePositionsPage() {
  const params = usePositionsPageParams()

  const isLoading = useSelector(genPositionsIsLoading)
  const isLoaded = useSelector(genPositionsIsLoaded)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(positionsPageActions.fetchAllPositions(params))
  }, [dispatch, params])

  React.useEffect(() => {
    return () => {
      dispatch(positionsPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoaded,
    isLoading,
  }
}

import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  genClientsEditPageCommonClientsInfo,
  getClientsEditPageCommonIsUpdating,
  getClientsEditPageCommonManagersOptions,
} from '../../../store/pages/clients-edit-page/clients-edit-page-common/clients-edit-page-common.selectors'
import { clientsEditPageCommonActions } from '../../../store/pages/clients-edit-page/clients-edit-page-common/clients-edit-page-common.slice'
import { AppPeek } from '../../../types/peek.types'
import { peekActions } from '../../../store/common/peek/peek.slice'

export function useClientsEditPageInfo() {
  const dispatch = useDispatch()

  const clientInfo = useSelector(genClientsEditPageCommonClientsInfo)
  const managersOptions = useSelector(getClientsEditPageCommonManagersOptions)
  const isUpdating = useSelector(getClientsEditPageCommonIsUpdating)

  const onChangeManagerHandler = useCallback(
    (managerId: string) => {
      if (clientInfo) {
        dispatch(
          clientsEditPageCommonActions.updateManager({
            clientId: clientInfo.id,
            managerId,
          })
        )
      }
    },
    [clientInfo, dispatch]
  )

  const onHistoryHandler = useCallback((): void => {
    if (clientInfo) {
      dispatch(
        peekActions.show({
          peek: AppPeek.CLIENTS_EDIT_PAGE_PEEK_OVERVIEW_HISTORY,
          props: { id: clientInfo.id },
        })
      )
    }
  }, [clientInfo, dispatch])

  return {
    isUpdating,
    clientInfo,
    managersOptions,
    onChangeManagerHandler,
    onHistoryHandler,
  }
}

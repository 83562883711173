import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { DirectionsFormValues } from '../../../components/directions/directions-form/directions-form.types'
import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'

export interface DirectionsCreatePageState {
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: DirectionsCreatePageState = {
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: directionsCreatePageActions, reducer: directionsCreatePageReducer } = createSlice({
  name: '@@directions-create-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<DirectionsCreatePageState>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<DirectionsCreatePageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercisesTypes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<DirectionsCreatePageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createDirection: (state: Draft<DirectionsCreatePageState>, _: PayloadAction<DirectionsFormValues>) => {
      state.isLoading = true
    },
    createDirectionSuccess: (state: Draft<DirectionsCreatePageState>) => {
      state.isLoading = false
    },
    createDirectionError: (state: Draft<DirectionsCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

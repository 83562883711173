import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'

import {
  getEmployeesEditPageFormValues,
  getEmployeesEditPageDirectionsOptions,
  getEmployeesEditPageGradesOptions,
  getEmployeesEditPagePositionsOptions,
  getEmployeesEditPageFranchisesOptions,
  getEmployeesEditPageExercisesTypesOptions,
  getEmployeesPositionType,
  getEmployeesEditPageCustomFields,
} from '../../../store/pages/employees-edit-page/employees-edit-page.selectors'
import { employeesEditPageActions } from '../../../store/pages/employees-edit-page/employees-edit-page.slice'
import { isDef } from '../../../types/lang.types'
import { useEmployeesEditPageParams } from '../employees-edit-page-hooks/employees-edit-page-params.hook'
import {
  EmployeesFormValues,
  EmployeesFormValuesDTO,
} from '../../../components/employees/employees-form/employees-form.types'

export function useEmployeesEditPageForm() {
  const [form] = Form.useForm<EmployeesFormValues>()

  const employeeFormValues = useSelector(getEmployeesEditPageFormValues)
  const employeePositionType = useSelector(getEmployeesPositionType)
  const customFields = useSelector(getEmployeesEditPageCustomFields)

  const franchisesOptions = useSelector(getEmployeesEditPageFranchisesOptions)
  const positionsOptions = useSelector(getEmployeesEditPagePositionsOptions)
  const gradesOptions = useSelector(getEmployeesEditPageGradesOptions)
  const directionsOptions = useSelector(getEmployeesEditPageDirectionsOptions)
  const exercisesTypesOptions = useSelector(getEmployeesEditPageExercisesTypesOptions)

  const { id } = useEmployeesEditPageParams()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (isDef(employeeFormValues)) {
      form.setFieldsValue(employeeFormValues)
    }
  }, [form, employeeFormValues])

  const onChangePositionHandler = React.useCallback(
    (positionId: string) => {
      dispatch(employeesEditPageActions.updatePosition(positionId))
    },
    [dispatch]
  )

  const onFinishHandler = React.useCallback(
    (values: EmployeesFormValuesDTO): void => {
      if (isDef(id)) {
        dispatch(employeesEditPageActions.updateEmployee({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  return {
    form,
    franchisesOptions,
    positionsOptions,
    gradesOptions,
    directionsOptions,
    exercisesTypesOptions,
    employeePositionType,
    customFields,
    onChangePositionHandler,
    onFinishHandler,
  }
}

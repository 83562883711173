import { Button, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { LeftOutlined } from '@ant-design/icons'

import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'
import { genPositionsPagePath } from '../../../format/path.format'

export const PositionsCreatePageTopBar = () => {
  return (
    <div>
      <Button type="text" icon={<LeftOutlined />} size="small">
        <Link to={genPositionsPagePath()}>
          <Typography.Text className="backBtnTitle">{formatPathName(AppPath.POSITIONS)}</Typography.Text>
        </Link>
      </Button>
      <Typography.Title level={2}>Создать новую должность</Typography.Title>
    </div>
  )
}

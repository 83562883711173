import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  genScheduleGroupPageHistoryIsLoading,
  genScheduleGroupPageHistoryScheduleHistory,
  genScheduleGroupPageHistoryTotalElements,
} from '../../../store/pages/schedule-group-page/schedule-group-page-history/schedule-group-page-history.selectors'
import { scheduleGroupPageHistoryActions } from '../../../store/pages/schedule-group-page/schedule-group-page-history/schedule-group-page-history.slice'
import { genListPaginationConfig } from '../../../utils/pagination.utils'
import { useScheduleGroupPageParams } from '../schedule-group-page-hooks/schedule-group-page-params.hook'

const SCHEDULE_GROUP_PAGE_HISTORY_INITIAL_PAGE: number = 0
const SCHEDULE_GROUP_PAGE_HISTORY_INITIAL_SIZE: number = 25

export function useScheduleGroupPageHistory() {
  const dispatch = useDispatch()

  const { scheduleId } = useScheduleGroupPageParams()

  const scheduleHistory = useSelector(genScheduleGroupPageHistoryScheduleHistory)
  const isLoading = useSelector(genScheduleGroupPageHistoryIsLoading)
  const totalElements = useSelector(genScheduleGroupPageHistoryTotalElements)

  const [page, setPage] = useState(SCHEDULE_GROUP_PAGE_HISTORY_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(SCHEDULE_GROUP_PAGE_HISTORY_INITIAL_SIZE)

  const pagination = useMemo(
    () => genListPaginationConfig(page, pageSize, totalElements),
    [page, pageSize, totalElements]
  )

  const onChangePageHandler = useCallback((page: number, pageSize: number): void => {
    setPage(page)
    setPageSize(pageSize)
  }, [])

  const onChangePageSizeHandler = useCallback((pageSize: number): void => {
    setPageSize(pageSize)
  }, [])

  useEffect(() => {
    dispatch(
      scheduleGroupPageHistoryActions.fetchAudit({
        id: scheduleId,
        page,
        size: pageSize,
      })
    )
  }, [dispatch, scheduleId, page, pageSize])

  useEffect(() => {
    return () => {
      dispatch(scheduleGroupPageHistoryActions.reset())
    }
  }, [dispatch])

  return {
    scheduleHistory,
    isLoading,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}

import { put, race, select, take, takeLatest, call } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'
import { notification } from 'antd'
import { createMatchSelector } from 'connected-react-router'
import { match } from 'react-router-dom'

import { employeesScheduleEditActions } from './employees-schedule-edit.slice'
import { modalActions } from '../../../common/modal/modal.slice'
import { mapEmployeesScheduleDayOffFormValuesToDTO } from '../../../../mapping/employees.mapping'
import { AppPath } from '../../../../types/path.types'
import { AppState } from '../../../app.store'
import { IEmployeeScheduleEditParams } from '../../../../pages/employees/employees-schedule-edit/employees-schedule-edit.types'

export function* fetchById(action: ReturnType<typeof employeesScheduleEditActions.fetchById>) {
  try {
    const { scheduleId, successCallback } = action.payload
    const schedule: Awaited<ReturnType<typeof api.employeesScheduleService.fetchById>> = yield callApi(
      api.employeesScheduleService.fetchById,
      scheduleId
    )

    yield put(employeesScheduleEditActions.fetchByIdSuccess({ schedule: schedule.data }))
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.fetchByIdError(new Error()))
  }
}

// Days-Off
export function* fetchDaysOff(action: ReturnType<typeof employeesScheduleEditActions.fetchDaysOff>) {
  try {
    const { scheduleId } = action.payload

    const response: Awaited<ReturnType<typeof api.employeesScheduleService.fetchDaysOffById>> = yield callApi(
      api.employeesScheduleService.fetchDaysOffById,
      scheduleId
    )

    yield put(employeesScheduleEditActions.fetchDaysOffSuccess({ daysOff: response.data }))
  } catch {
    yield put(employeesScheduleEditActions.fetchDaysOffError(new Error()))
  }
}

export function* reFetchDaysOff() {
  const { params }: match<IEmployeeScheduleEditParams> = yield select(
    createMatchSelector<AppState, IEmployeeScheduleEditParams>(AppPath.EMPLOYEES_SCHEDULE_EDIT)
  )
  const { id } = params

  yield put(
    employeesScheduleEditActions.fetchDaysOff({
      scheduleId: id,
    })
  )

  yield race([
    take(employeesScheduleEditActions.fetchDaysOffSuccess.type),
    take(employeesScheduleEditActions.fetchDaysOffError.type),
  ])
}

export function* createDayOff(action: ReturnType<typeof employeesScheduleEditActions.createDayOff>) {
  try {
    const { scheduleId, dayOff } = action.payload

    const data = mapEmployeesScheduleDayOffFormValuesToDTO(dayOff)

    yield callApi(api.employeesScheduleService.createDayOff, scheduleId, data)

    yield put(employeesScheduleEditActions.createDayOffSuccess())
    yield call(reFetchDaysOff)

    yield notification.success({
      message: 'Перерыв создан',
    })
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.createDayOffError(new Error()))
  }
}

export function* editDayOff(action: ReturnType<typeof employeesScheduleEditActions.editDayOff>) {
  try {
    const { scheduleId, dayOff } = action.payload
    const { id } = dayOff

    if (id) {
      const data = mapEmployeesScheduleDayOffFormValuesToDTO(dayOff)

      yield callApi(api.employeesScheduleService.editDayOff, scheduleId, id, data)

      yield put(employeesScheduleEditActions.editDayOffSuccess())
      yield call(reFetchDaysOff)

      yield notification.success({
        message: 'Перерыв изменен',
      })
    }
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.editDayOffError(new Error()))
  }
}

export function* removeDayOff(action: ReturnType<typeof employeesScheduleEditActions.removeDayOff>) {
  try {
    const { scheduleId, dayOffId } = action.payload
    yield callApi(api.employeesScheduleService.deleteDayOff, scheduleId, dayOffId)

    yield put(employeesScheduleEditActions.removeDayOffSuccess())
    yield put(modalActions.closeLast())
    yield notification.success({
      message: 'Перерыв удален',
    })
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.removeDayOffError(new Error()))
  }
}

// TODO: Does not work
export function* edit(action: ReturnType<typeof employeesScheduleEditActions.edit>) {
  try {
    const { id, schedule, successCallback } = action.payload
    yield callApi(api.employeesScheduleService.edit, id, schedule)

    yield put(employeesScheduleEditActions.editSuccess())
    if (successCallback) successCallback()
  } catch (e) {
    console.error(e)
    yield put(employeesScheduleEditActions.editError(new Error()))
  }
}

export function* employeesScheduleEditSagas() {
  yield takeLatest(employeesScheduleEditActions.fetchById, fetchById)
  yield takeLatest(employeesScheduleEditActions.fetchDaysOff, fetchDaysOff)
  yield takeLatest(employeesScheduleEditActions.createDayOff, createDayOff)
  yield takeLatest(employeesScheduleEditActions.editDayOff, editDayOff)
  yield takeLatest(employeesScheduleEditActions.removeDayOff, removeDayOff)
  yield takeLatest(employeesScheduleEditActions.edit, edit)
}

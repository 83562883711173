import { ElementStates } from '../master-services-widget-form-design-settings.types'

export function getButtonFontColor(element: string, state: string) {
  if (state.toUpperCase() === ElementStates.DEFAULT) {
    switch (element) {
      case 'buttonBack':
        return '#FFF'
      case 'buttonFillColor':
        return '#FFF'
      case 'buttonOutline':
        return '#1D1D1F'
      case 'buttonContinue':
        return '#FFF'
      case 'buttonSelectStudio':
        return '#1D1D1F'
      case 'buttonAboutStudio':
        return '#FFF'
      default:
        return '#1D1D1F'
    }
  } else {
    switch (element) {
      case 'buttonBack':
        return '#FFF'
      case 'buttonFillColor':
        return '#1D1D1F'
      case 'buttonOutline':
        return '#FFF'
      case 'buttonContinue':
        return '#FFF'
      case 'buttonSelectStudio':
        return '#FFF'
      case 'buttonAboutStudio':
        return '#1D1D1F'
      default:
        return '#1D1D1F'
    }
  }
}

export function genButtonFontStyles(element: string, state: string) {
  return {
    desktop: {
      color: getButtonFontColor(element, state),
      fontSize: 15,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: getButtonFontColor(element, state),
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

import dayjs from 'dayjs'

import { AuditApi } from '../api/types/audit.types'
import { HistoryItem } from '../components/history-list/history-list.types'
import { isDef, isDefAndNotEmpty, NNumber, Nullable } from '../types/lang.types'

export function mapAuditToHistoryList(
  audit: Nullable<AuditApi.Audit[]>,
  studioOffset: NNumber
): Nullable<HistoryItem[]> {
  if (isDefAndNotEmpty(audit) && isDef(studioOffset)) {
    return audit.map(item => {
      const { id, subject, actionObject, parameterObject, action, changes, time } = item

      dayjs.locale('ru')

      const modificationDate = dayjs(time).utcOffset(studioOffset).format('DD MMMM YYYY')
      const modificationTime = dayjs(time).utcOffset(studioOffset).format('HH:mm')

      return {
        id,
        subject,
        actionObject,
        parameterObject,
        action,
        changes,
        date: modificationDate,
        time: modificationTime,
      }
    })
  }
  return null
}

import { api } from '../../../../../api/api'

export const getCurrentUser = async () => {
  const currentUser = await api.employeesService.getCurrent()
  const currentUserName = `${currentUser?.data?.lastName ?? ''} ${currentUser?.data?.firstName?.[0] ?? ''}.${
    currentUser?.data?.middleName?.[0] ?? ''
  }.`

  return [currentUserName, currentUser.data.inn]
}

import { OrganizationsApi } from '@api/types/organizations-api.types'
import { TillsApi } from '@api/types/tills-api.types'
import { getTotalDepositSum } from '@components/transactions/transactions-form/transactions-form.utils'
import { openShift } from '@pages/transactions-page/transactions-shift/utils/shift/open-shift'
import { transactionsCreatePageSubmitActions } from '@store/pages/transactions-create-page/transactions-create-page-submit/transactions-create-page-submit.slice'
import { FormInstance } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PaymentMethod } from '../../../api/types/api.types'
import { TransactionsFormValues } from '../../../components/transactions/transactions-form/transactions-form.types'
import { genTransactionIsLoading } from '../../../store/pages/transactions-create-page/transactions-create-page-submit/transactions-create-page-submit.selectors'
import {
  getTransactionsCreatePageTablePaymentMethod,
  getTransactionsCreatePageTableProducts,
  getTransactionsCreatePageTotal,
} from '../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.selectors'
import { transactionsCreatePageTableActions } from '../../../store/pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.slice'
import { Nullable } from '../../../types/lang.types'

export function useTransactionsCreatePageForm(
  form: FormInstance<TransactionsFormValues>,
  offlineTills: TillsApi.OfflineTillDTO[],
  currentOrganization: Nullable<OrganizationsApi.Organization>,
  depositSum: number
) {
  // SECTION: Store
  const dispatch = useDispatch()
  const isLoading = useSelector(genTransactionIsLoading)
  const products = useSelector(getTransactionsCreatePageTableProducts)
  const price = useSelector(getTransactionsCreatePageTotal)
  const paymentMethod = useSelector(getTransactionsCreatePageTablePaymentMethod)

  // SECTION: State
  const [hasPrompt, setHasPrompt] = React.useState(true)

  // SECTION: Computed
  const haveProductsDiscount = React.useMemo(() => products && products.find(product => product.discount), [products])

  // SECTION: Actions
  const onSubmitHandler = async () => {
    const values = await form.getFieldsValue()

    if (paymentMethod === PaymentMethod.NO_PAYMENT) {
      dispatch(transactionsCreatePageTableActions.setProductsNoPayment())
    }

    let isSendOfflineTillId = false

    const getCurrentDepositSum = () => {
      if (!values.hasDeposit) {
        return 0
      }
      return getTotalDepositSum(price, depositSum)
    }

    const totalDepositSum = getCurrentDepositSum()

    const openModalInitial = (isSendOfflineTillId: boolean) => {
      dispatch(
        transactionsCreatePageSubmitActions.createTransaction({
          ...values,
          isSendOfflineTillId,
          deposit: totalDepositSum ?? 0,
        })
      )
      setHasPrompt(false)
    }

    const openShiftInitial = async () => {
      const currentOfflineTillId = await form.getFieldValue('offlineTillId')
      const currentOfflineTill = offlineTills.find(till => till.id === currentOfflineTillId)

      if (!currentOfflineTill?.tillShiftOpened && currentOfflineTill) {
        await openShift(currentOfflineTill.id, currentOfflineTill.numDevice, currentOfflineTill.idDevice, () =>
          openModalInitial(isSendOfflineTillId)
        )
      } else {
        openModalInitial(isSendOfflineTillId)
      }
    }

    const paymentMethods = currentOrganization?.offlineTillSupportedPaymentMethodCategories
    if (values.paymentMethod === 'CASH' && (paymentMethods ?? []).includes('CASH')) {
      isSendOfflineTillId = true
      await openShiftInitial()
    } else if (values.paymentMethod === 'CARD' && (paymentMethods ?? []).includes('CARD')) {
      isSendOfflineTillId = true
      await openShiftInitial()
    } else {
      openModalInitial(isSendOfflineTillId)
    }
  }

  const onChangePaymentMethodHandler = React.useCallback(
    (paymentMethod: PaymentMethod) => {
      dispatch(transactionsCreatePageTableActions.setPaymentMethod(paymentMethod))

      // if (paymentMethod === PaymentMethod.NO_PAYMENT) {
      //   dispatch(transactionsCreatePageTableActions.setProductsNoPayment())
      // }
    },
    [dispatch]
  )

  return {
    hasPrompt,
    isLoading,
    products,
    haveProductsDiscount,
    onSubmitHandler,
    onChangePaymentMethodHandler,
    price,
    paymentMethod,
  }
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genMasterServiceAuditInternal = (state: AppState) =>
  state.masterServicesPage.masterServicesPagePeekHistory.masterServiceAudit

export const genMasterServicesPagePeekHistoryIsLoading = (state: AppState) =>
  state.masterServicesPage.masterServicesPagePeekHistory.isLoading

export const genMasterServicesPagePeekHistoryTotalElements = createSelector(
  genMasterServiceAuditInternal,
  audit => audit?.totalElements
)

export const genMasterServicesPagePeekHistoryMasterServiceHistory = createSelector(
  genMasterServiceAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

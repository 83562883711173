import { Tag } from 'antd'

interface Props {
  variant: string
}

export const ProductsSubscriptionsTableColumnVariant = ({ variant }: Props) => {
  let color = ''
  let text = ''
  switch (variant) {
    case 'BY_TIME':
      text = 'По часам'
      color = 'gold'
      break
    case 'BY_DURATION':
      text = 'По длительности'
      color = 'lime'
      break
    case 'BY_VISITS':
      text = 'По записям'
      color = 'magenta'
      break
    default:
      text = ''
      color = ''
      break
  }

  return <Tag color={color}>{text}</Tag>
}

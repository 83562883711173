import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import {
  TransactionsPageModalConfirmRefundPayload,
  TransactionsPageModalRefundSumPayload,
} from './transactions-page-modal-refund.types'
import { TransactionsApi } from '../../../../api/types/transactions-api.types'

export interface TransactionsPageModalRefundState {
  refundSum: Nullable<TransactionsApi.TransactionRefundSum>
  isLoading: boolean
  isLoaded: boolean
  isRefunded: boolean
  error: Nullable<Error>
}

const initialState: TransactionsPageModalRefundState = {
  refundSum: null,
  isLoading: false,
  isLoaded: false,
  isRefunded: false,
  error: null,
}

export const { actions: transactionsPageModalRefundActions, reducer: transactionsPageModalRefundReducer } = createSlice(
  {
    name: '@@transactions-page-modal-refund',
    initialState,
    reducers: {
      fetchRefundSum: (
        state: Draft<TransactionsPageModalRefundState>,
        _: PayloadAction<TransactionsPageModalRefundSumPayload>
      ) => {
        state.isLoading = true
      },
      fetchRefundSumSuccess: (
        state: Draft<TransactionsPageModalRefundState>,
        action: PayloadAction<Nullable<TransactionsApi.TransactionRefundSum>>
      ) => {
        state.refundSum = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      fetchRefundSumError: (state: Draft<TransactionsPageModalRefundState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
        state.isLoaded = true
      },
      refundTransaction: (
        state: Draft<TransactionsPageModalRefundState>,
        _: PayloadAction<TransactionsPageModalConfirmRefundPayload>
      ) => {
        state.isLoading = true
      },
      refundTransactionSuccess: (state: Draft<TransactionsPageModalRefundState>) => {
        state.isLoading = false
        state.isRefunded = true
      },
      refundTransactionError: (state: Draft<TransactionsPageModalRefundState>, action: PayloadAction<Error>) => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  }
)

import { TillsApi } from '@api/types/tills-api.types'
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { TillEntity } from './till.types'

const initialState: TillEntity.IState = {
  tills: null,
  isLoading: false,
  error: null,
}

export const { actions: tillEntityActions, reducer: tillEntityReducer } = createSlice({
  name: '@@till-entity',
  initialState,
  reducers: {
    fetchAllTills: (state: Draft<TillEntity.IState>) => {
      state.isLoading = true
    },
    fetchAllTillsSuccess: (
      state: Draft<TillEntity.IState>,
      action: PayloadAction<TillEntity.IFetchAllTillsSuccessPayload>
    ) => {
      state.isLoading = false
      state.tills = action.payload.tills
    },
    fetchAllTillsError: (state: Draft<TillEntity.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createTill: (state: Draft<TillEntity.IState>, _: PayloadAction<TillsApi.IOnlineTillDTO>) => {
      state.isLoading = true
    },
    createTillSuccess: (state: Draft<TillEntity.IState>) => {
      state.isLoading = false
    },
    createTillError: (state: Draft<TillEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    updateTill: (state: Draft<TillEntity.IState>, _: PayloadAction<TillsApi.IOnlineTillUpdateDTO>) => {
      state.isLoading = true
    },
    updateTillSuccess: (state: Draft<TillEntity.IState>) => {
      state.isLoading = false
    },
    updateTillError: (state: Draft<TillEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    deleteTill: (state: Draft<TillEntity.IState>, _: PayloadAction<TillsApi.IOnlineTillDTORemove>) => {
      state.isLoading = true
    },
    deleteTillSuccess: (state: Draft<TillEntity.IState>) => {
      state.isLoading = false
    },
    deleteTillError: (state: Draft<TillEntity.IState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import { CustomFieldsPageTopBar } from './custom-fields-page-top-bar/custom-fields-page-top-bar.component'
import { CustomFieldsPageTable } from './custom-fields-page-table/custom-fields-page-table.component'
import { useCustomFieldsPage } from './custom-fields-page.hook'
import { CustomFieldsPageTabs } from './custom-fields-page-tabs/custom-fields-page-tabs.component'

export const CustomFieldsPage = () => {
  useCustomFieldsPage()

  return (
    <>
      <CustomFieldsPageTopBar />
      <CustomFieldsPageTabs />
    </>
  )
}

import * as React from 'react'

interface Props {
  className?: string
}

export const Logo: React.FC<Props> = props => {
  const { className } = props

  return (
    <svg
      className={className}
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000221336 2.06086C1.63058 0.948802 3.62826 0.169328 5.62793 0.377455C6.99099 0.512125 8.10748 2.14664 8.83798 3.228C9.90107 4.79305 11.7799 7.51468 11.7799 7.51468V7.84544C10.3924 10.0613 9.22939 12.387 9.45981 15.0825C4.67037 9.03619 4.95044 9.19452 2.87104 6.26441C1.94058 4.84423 0.928497 3.48124 0 2.06095L0.000221336 2.06086Z"
        fill="url(#paint0_linear_516_5500)"
      />
      <path
        d="M15.6625 3.24488C17.0623 2.04303 18.7008 0.979938 20.5739 0.767678C21.8023 0.618723 23.0328 1.05743 24 1.80629C23.2388 2.92856 22.3165 3.9243 21.4881 4.99353C19.8904 7.01361 18.3662 9.09285 16.6827 11.0395C15.9318 10.9926 15.1156 11.0477 14.4587 10.6192C13.2813 9.85805 12.5304 8.63581 11.7509 7.50935L11.8325 7.38284C12.8915 5.82186 14.1811 4.41617 15.6625 3.24488Z"
        fill="url(#paint1_linear_516_5500)"
      />
      <path
        d="M9.27453 14.7783C8.77501 12.2784 10.3743 9.70439 11.7619 7.48828C12.5413 8.61464 12.8468 9.17475 14.0243 9.93592C14.6813 10.3644 15.9325 10.9922 16.6833 11.0391C14.6196 13.2832 14.3021 13.6669 12.69 14.9671C11.9799 15.4221 11.1249 15.8159 10.2658 15.5915C9.90055 15.4813 9.40921 15.1578 9.27453 14.7783Z"
        fill="url(#paint2_linear_516_5500)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_516_5500"
          x1="6.84017"
          y1="5.78149"
          x2="6.44326"
          y2="11.9998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#5247E6" />
          <stop offset="1" stop-color="#7451C5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_516_5500"
          x1="17.8754"
          y1="7.39555"
          x2="17.8754"
          y2="11.0395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DD0F41" />
          <stop offset="0.815001" stop-color="#72003A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_516_5500"
          x1="12.9966"
          y1="11.583"
          x2="12.9966"
          y2="7.50898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#7552C5" />
          <stop offset="1" stop-color="#5348E6" />
        </linearGradient>
      </defs>
    </svg>
  )
}

import { FC } from 'react'

import { isDefAndNotEmpty } from '../../../types/lang.types'
import { HistoryList } from '../../history-list/history-list.component'
import { HistoryListType } from '../../history-list/history-list.types'
import { PageEmpty } from '../../page/page-empty/page-empty.component'
import { PageLoader } from '../../page/page-loader/page-loader.component'
import { SidePeek } from '../../side-peek/side-peek.component'
import { ClientsExerciseHistoryPeekProps } from './clients-exercise-history-peek.types'

export const ClientsExerciseHistoryPeek: FC<ClientsExerciseHistoryPeekProps> = props => {
  const { isLoading, exerciseHistory, ...rest } = props

  return (
    <SidePeek>
      {isLoading ? (
        <PageLoader />
      ) : isDefAndNotEmpty(exerciseHistory) ? (
        <HistoryList
          title="История действий с записью"
          type={HistoryListType.EXERCISE}
          history={exerciseHistory}
          {...rest}
        />
      ) : (
        <PageEmpty description={'История не найдена'} />
      )}
    </SidePeek>
  )
}

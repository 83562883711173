import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { ExercisesApi } from '../../../../api/types/exercises-api.types'
import {
  ScheduleGroupPageModalCommentsCreateCommentPayload,
  ScheduleGroupPageModalCommentsGetCommentPayload,
} from './schedule-group-page-modal-comments.types'
import { Pagination } from '../../../../api/types/api.types'

export interface ScheduleGroupPageModalCommentsState {
  comments: Nullable<Pagination<ExercisesApi.ExerciseBookingComment>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: ScheduleGroupPageModalCommentsState = {
  comments: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: scheduleGroupPageModalCommentsActions, reducer: scheduleGroupPageModalCommentsReducer } =
  createSlice({
    name: '@@schedule-group-page-modal-comments',
    initialState,
    reducers: {
      fetchComments: (
        state: Draft<ScheduleGroupPageModalCommentsState>,
        _: PayloadAction<ScheduleGroupPageModalCommentsGetCommentPayload>
      ): void => {
        state.isLoading = true
      },
      fetchCommentsSuccess: (
        state: Draft<ScheduleGroupPageModalCommentsState>,
        action: PayloadAction<Nullable<Pagination<ExercisesApi.ExerciseBookingComment>>>
      ): void => {
        state.isLoading = false
        state.isLoaded = true
        state.comments = action.payload
      },
      fetchCommentsError: (state: Draft<ScheduleGroupPageModalCommentsState>, action: PayloadAction<Error>): void => {
        state.isLoading = false
        state.isLoaded = true
        state.error = action.payload
      },

      createComment: (
        state: Draft<ScheduleGroupPageModalCommentsState>,
        _: PayloadAction<ScheduleGroupPageModalCommentsCreateCommentPayload>
      ): void => {
        state.isLoading = true
      },
      createCommentSuccess: (state: Draft<ScheduleGroupPageModalCommentsState>): void => {
        state.isLoading = false
      },
      createCommentError: (state: Draft<ScheduleGroupPageModalCommentsState>, action: PayloadAction<Error>): void => {
        state.isLoading = false
        state.error = action.payload
      },

      reset: () => initialState,
    },
  })

import { useRouteMatch } from 'react-router-dom'

import { ExercisesTypesEditPageParams } from '../exercises-types-edit-page.types'

export function useExercisesTypesEditPageParams() {
  const match = useRouteMatch<ExercisesTypesEditPageParams>()
  const { params } = match
  const { id } = params

  return {
    id: Number(id),
  }
}

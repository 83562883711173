import * as React from 'react'

import { EmployeesForm } from '../../../components/employees/employees-form/employees-form.component'
import { useEmployeesCreatePageForm } from './employees-create-page-form.hook'

export const EmployeesCreatePageForm = () => {
  const {
    form,
    positionsOptions,
    gradesOptions,
    franchisesOptions,
    directionsOptions,
    exercisesTypesOptions,
    employeePositionType,
    onFinishHandler,
    onChangePositionHandler,
    customFields,
  } = useEmployeesCreatePageForm()

  return (
    <EmployeesForm
      submitText="Создание пользователя"
      form={form}
      franchisesOptions={franchisesOptions}
      positionsOptions={positionsOptions}
      gradesOptions={gradesOptions}
      directionsOptions={directionsOptions}
      exercisesTypesOptions={exercisesTypesOptions}
      customFields={customFields}
      employeePositionType={employeePositionType}
      onFinish={onFinishHandler}
      onChangePosition={onChangePositionHandler}
    />
  )
}

import { DownOutlined, IssuesCloseOutlined, TeamOutlined } from '@ant-design/icons'
import { AsyncButton } from '@components/ui/async-button/async-button.component'
import { Button, Dropdown, Flex } from 'antd'
import { clsx } from 'clsx'
import { FC, useMemo, useState } from 'react'

import './offline-till-shift-status.styles.less'
import { IOfflineTillShiftStatusProps } from './offline-till-shift-status.types'
import { getAdditionalActionsItems } from './offline-till-shift-status.utils'

export const OfflineTillShiftStatus: FC<IOfflineTillShiftStatusProps> = ({
  status,
  handleOpenShift,
  handleCloseShift,
  handleXReport,
  isDisabledActions,
  shifts,
  offlineTillsLength,
}) => {
  // State
  const [isForceShowOpenActions, setIsForceShowOpenActions] = useState(false)

  // Actions
  const handleAddNewTill = () => {
    const studioId = localStorage.getItem('APP_SELECTED_STUDIO_ID')
    window.location.href = `/studios/${studioId}`
  }

  const handleOpenDropdown = (open: boolean) => {
    setIsForceShowOpenActions(open)
  }

  // Computed
  const additionalActionsItems = useMemo(() => getAdditionalActionsItems(shifts), [shifts])

  // Render
  const moreButton = isDisabledActions ? (
    <Button className="shift-status__button" type="dashed" shape="round" disabled={isDisabledActions}>
      <Flex gap="small" justify="between" align="center">
        Действия
        <DownOutlined />
      </Flex>
    </Button>
  ) : (
    <Dropdown menu={{ items: additionalActionsItems }} placement="bottom" onOpenChange={handleOpenDropdown}>
      <Button className="shift-status__button" type="dashed" shape="round" disabled={isDisabledActions}>
        <Flex gap="small" justify="between" align="center">
          Действия
          <DownOutlined />
        </Flex>
      </Button>
    </Dropdown>
  )

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      className={clsx(`shift-status shift-status--${status}`, {
        'shift-status--force-show': isForceShowOpenActions && status === 'open',
      })}
    >
      <p>{isForceShowOpenActions}</p>
      <div className="shift-status__bg" />

      {offlineTillsLength > 0 && status === 'close' && (
        <Flex gap="small">
          <AsyncButton
            shape="round"
            type="primary"
            icon={<TeamOutlined />}
            onClick={handleOpenShift}
            disabled={isDisabledActions}
          >
            Открыть смену
          </AsyncButton>

          {moreButton}
        </Flex>
      )}

      {status === 'add-new' && (
        <Button shape="round" type="primary" onClick={handleAddNewTill}>
          Добавить новую кассу
        </Button>
      )}

      {status === 'open' && (
        <Flex gap="small">
          {offlineTillsLength > 0 && (
            <AsyncButton
              className="shift-status__button shift-status__button--close"
              shape="round"
              type="primary"
              icon={<IssuesCloseOutlined />}
              onClick={handleCloseShift}
              disabled={isDisabledActions}
            >
              Закрыть смену
            </AsyncButton>
          )}
          <AsyncButton
            className="shift-status__button shift-status__button--x-report"
            shape="round"
            onClick={handleXReport}
            disabled={isDisabledActions}
          >
            Х-отчёт
          </AsyncButton>

          {moreButton}
        </Flex>
      )}
    </Flex>
  )
}

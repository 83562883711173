import { FC } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

import { IQuestionTooltipProps } from './question-tooltip.types'
import './question-tooltip.styles.less'

export const QuestionTooltip: FC<IQuestionTooltipProps> = ({ title }) => {
  return (
    <Tooltip placement="top" title={title}>
      <QuestionCircleOutlined className="question-icon" />
    </Tooltip>
  )
}

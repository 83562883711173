export const genProductsSubcriptionsQuestions = {
  1: 'Абонемент, который позволяет указать конкретное количество записей, доступных для посещения клиентом.',
  2: 'Абонемент, который учитывает общее количество часов посещений, независимо от количества записей клиента.',
  3: 'Абонемент, который будет действовать указанный срок: неделя, месяц, пол года, год.',
  4: 'Укажите количество дней, на которое можно замораживать абонемент. Клиент может использовать заморозку по своему усмотрению, включая и выключая её, но не превышая максимальное количество дней.',
  5: 'Если клиент не активирует абонемент самостоятельно через запись, он будет активирован автоматически. Это свойство поможет избежать накопления неиспользованных абонементов, мотивируя клиентов к активности, и обеспечит вашу защиту.',
  6: 'Активируйте это свойство, и абонемент не будет доступен для выбора клиенту. Такой абонемент можно будет продать только через менеджера в разделе "Транзакции".',
  7: 'Включите это свойство, и данный абонемент позволит записываться только до 16:45',
}

export function genServicesInitialValues() {
  return {
    hasStudioLimitation: false,
    hasDirectionLimitation: false,
    hasTypeLimitation: false,
    timeLimitation: 'NONE',
    typeSubcriptions: false,
    freeze: false,
    autoActivateInput: false,
    hideFromUser: true,
    variant: 'BY_VISITS',
    validityDays: 30,
  }
}

export const validateProductsSubcriptionsNumberInput = (_: any, value: number) => {
  if (value > 0) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Значение должно быть больше 0'))
}

export const validateProductsSubcriptionsNumberIntegerInput = (_: any, value: number) => {
  if (Number.isInteger(value) && value > 0) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Значение должно быть целым числом и больше 0'))
}

export const validateProductsSubcriptionsNoSpacesOnly = (_: any, value: string) => {
  if (value && value.trim() !== '') {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Введите название абонемента'))
}

import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { useCustomFieldsPageButton } from './custom-fields-page-button.hook'

export const CustomFieldsPageButton = () => {
  const { onClick } = useCustomFieldsPageButton()
  return (
    <Button style={{ marginTop: 24 }} icon={<PlusOutlined />} onClick={onClick}>
      Добавить еще
    </Button>
  )
}

import { useWatch } from 'antd/es/form/Form'
import { useEffect, useState } from 'react'

interface DefaultOptionType {
  value: string
  format: string
  directions?: DefaultOptionType[]
  id?: string
}

export function useProductServicesForm(exercisesOptions: DefaultOptionType[], form: any) {
  const [filteredOptions, setFilteredOptions] = useState(exercisesOptions)
  const [filteredDirectionsOptions, setFilteredDirectionsOptions] = useState<DefaultOptionType[]>([])

  const typesSelect = useWatch('availableTypes', form)
  const directionsSelect = useWatch('availableDirections', form)

  useEffect(() => {
    const selectedIds = typesSelect?.map((type: DefaultOptionType) => type.value)

    if (selectedIds && selectedIds.length > 0) {
      const selectedFormats = exercisesOptions
        ?.filter(option => selectedIds.includes(option.value))
        .map(option => option.format)

      setFilteredOptions(exercisesOptions?.filter(option => selectedFormats?.includes(option.format)))

      if (selectedFormats?.includes('GROUP')) {
        const groupDirections = exercisesOptions
          ?.filter(option => selectedIds.includes(option.value) && option.format === 'GROUP')
          .flatMap(option => option.directions)
          .reduce((uniqueDirections: DefaultOptionType[], direction) => {
            if (!uniqueDirections.some(d => d.id === direction?.id)) {
              uniqueDirections.push(direction!)
            }
            return uniqueDirections
          }, [])

        setFilteredDirectionsOptions(groupDirections)
      } else {
        setFilteredDirectionsOptions([])
      }
    } else {
      setFilteredDirectionsOptions([])
      setFilteredOptions(exercisesOptions)
    }
  }, [typesSelect, exercisesOptions])

  useEffect(() => {
    const filteredDirectionsIds = filteredDirectionsOptions.map(direction => direction.id)
    const updatedDirectionsSelect = directionsSelect?.filter((direction: DefaultOptionType) =>
      filteredDirectionsIds.includes(direction.value)
    )

    if (JSON.stringify(directionsSelect) !== JSON.stringify(updatedDirectionsSelect)) {
      form.setFieldsValue({ availableDirections: updatedDirectionsSelect })
    }
  }, [filteredDirectionsOptions, directionsSelect, form])

  return { filteredOptions, filteredDirectionsOptions }
}

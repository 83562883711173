export function genStudioNameFontStyles() {
  return {
    desktop: {
      color: '#FFF',
      fontSize: 22,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 25,
      letterSpacing: 0,
      wordSpacing: 0,
    },
    mobile: {
      color: '#FFF',
      fontSize: 19,
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 24,
      letterSpacing: -0.45,
      wordSpacing: 0,
    },
  }
}

export function genStudioAddressFontStyles() {
  return {
    desktop: {
      color: '#FFF',
      fontSize: 15,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 20,
      letterSpacing: -0.2,
      wordSpacing: 0,
    },
    mobile: {
      color: '#FFF',
      fontSize: 14,
      fontWeight: 500,
      textTransform: 'none',
      fontStyle: 'normal',
      textDecoration: 'none',
      lineHeight: 19,
      letterSpacing: -0.15,
      wordSpacing: 0,
    },
  }
}

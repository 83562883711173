import * as React from 'react'

import { PageEmpty } from '../../../page/page-empty/page-empty.component'
import './autocomplete-empty.styles.less'
import { useTheme } from '../../../../hooks/use-theme.hook'

export const AutocompleteEmpty: React.FC = () => {
  const { theme } = useTheme()

  return (
    <li
      className={`${
        theme === 'light' ? 'autocomplete-dropdown-light' : 'autocomplete-dropdown-dark'
      } ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-dark autocomplete-empty`}
    >
      <PageEmpty className="autocomplete-empty__placeholder" description="Ничего не найдено" />
    </li>
  )
}

import { EmployeesHistoryPeek } from '../../../components/employees/employees-history-peek/employees-history-peek.component'
import { useEmployeesPagePeekHistory } from './employees-page-peek-history.hook'
import { EmployeesPagePeekHistoryProps } from './employees-page-peek-history.types'

export const EmployeesPagePeekHistory: React.FC<EmployeesPagePeekHistoryProps> = props => {
  const { id } = props

  const { employeeHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useEmployeesPagePeekHistory(id)

  return (
    <EmployeesHistoryPeek
      employeeHistory={employeeHistory}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genStudioAuditInternal = (state: AppState) => state.studiosPage.studiosPagePeekHistory.studioAudit

export const genStudiosPagePeekHistoryIsLoading = (state: AppState) =>
  state.studiosPage.studiosPagePeekHistory.isLoading

export const genStudiosPagePeekHistoryTotalElements = createSelector(
  genStudioAuditInternal,
  audit => audit?.totalElements
)

export const genStudiosPagePeekHistoryStudioHistory = createSelector(
  genStudioAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

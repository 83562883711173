import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { FIGURE_BUTTON_EVENT_TYPE, FIGURE_DIRECTION, FIGURE_MODE } from '../../constants/figure'
import { IFigure, IHourCellProps, IOnClickWrapperArgs } from './hour-cell.types'
import { matchKeyByDay } from '../../utils/match-key-by-day'
import { FigureButton } from '../figure-button/figure-button.component'
import { IFigureButtonEvent } from '../figure-button/figure-button.types'
import { TAG_COLORS, TAG_TEXTS, TAG_TYPES } from '../../constants/tag'
import { getIsWithin } from '../../utils/time'
import { CELL_MODE } from '../../constants/cell'
import { updateFigureItem } from './utils/figure'
import { ICurrentStudioSlice } from '../../work-time-schedule.types'
import './hour-cell.styles.less'
import { getFigureDetails } from './utils/figure-details'
import { DEFAULT_FIGURE_DETAILS } from './constants/figure'

export const HourCell: FC<IHourCellProps> = ({
  cellId,
  column,
  row,
  studio,
  onClick,
  defaultFigures,
  selectedFigure,
  figurePreset,
  isMouseDown,
  setIsMouseDown,
}) => {
  // SECTION: State
  const [isInitFigures, setIsInitFigures] = useState(false)

  const [isDisabled, setIsDisabled] = useState(false)
  const [currentFigureId, setCurrentFigureId] = useState<string | null>(null)
  const [cellMode, setCellMode] = useState<CELL_MODE>(CELL_MODE.CREATE)

  const [figuresCollection, setFiguresCollection] = useState<IFigure[]>([])

  // SECTION: Actions
  const getIsBlockClick = ({ id }: { id: string | null }) => {
    if (isDisabled) return true
    if (cellMode === CELL_MODE.CREATE) return true

    return id !== currentFigureId
  }

  const onClickWrapper = ({ type, syntheticEvent, id, figureDetails }: IOnClickWrapperArgs) => {
    if (!onClick) return

    let figureStudio = studio

    if (figurePreset?.tag?.type === TAG_TYPES.ALL_ROOMS) {
      if (!studio) return

      studio.selectedRoom = {
        color: TAG_COLORS.ALL_ROOMS,
        name: TAG_TEXTS.ALL_ROOMS,
        id: studio?.id,
        workTime: null,
      }
    }

    if (figurePreset?.tag?.type === TAG_TYPES.ALL_STUDIOS) {
      figureStudio = {
        id: TAG_TYPES.ALL_STUDIOS,
        name: TAG_TEXTS.ALL_STUDIOS,
        selectedRoom: {
          color: TAG_COLORS.ALL_ROOMS,
          name: TAG_TEXTS.ALL_ROOMS,
          id: TAG_TYPES.ALL_ROOMS,
          workTime: null,
        },
        workTime: null,
      }
    }

    onClick({
      type,
      syntheticEvent,
      id,
      day: row,
      studio: figureStudio as ICurrentStudioSlice,
      timeRange: figureDetails.timeRange,
      tagType: selectedFigure?.tag.type ?? TAG_TYPES.ONE_ROOM,
      cellId,
    })
  }

  const createFigure = (
    skipDisable = false,
    direction = FIGURE_DIRECTION.LEFT,
    mode = FIGURE_MODE.FULL,
    index: number | null = null
  ) => {
    if (isDisabled && !skipDisable) return
    if (!currentFigureId) return
    if (!figurePreset) return

    if (cellMode === CELL_MODE.CREATE) {
      const figure = {
        cellId: cellId,
        color: figurePreset.figure.color,
        type: figurePreset.figure.type,
        id: currentFigureId,
        mode: mode,
        direction: direction,
        details: DEFAULT_FIGURE_DETAILS,
        tag: {
          name: figurePreset.details.name,
          type: figurePreset.tag.type,
        },
      }

      const details = getFigureDetails(studio, column, figure)
      figure.details = details

      if (index !== null) {
        setFiguresCollection([...figuresCollection.slice(0, index), figure, ...figuresCollection.slice(index)])
      } else {
        setFiguresCollection([...figuresCollection, figure])
      }

      setCellMode(CELL_MODE.UPDATE)

      onClickWrapper({
        type: FIGURE_BUTTON_EVENT_TYPE.CREATE,
        syntheticEvent: null,
        id: currentFigureId,
        figureDetails: details,
      })
    }
  }

  const handleCellClick = () => createFigure()

  const handleFigureClick = ({ syntheticEvent, type, id, mode, direction }: IFigureButtonEvent) => {
    const figureDetails = getFigureDetails(studio, column, {
      direction,
      mode,
      color: figurePreset?.figure?.color,
      tag: {
        type: figurePreset?.tag?.type,
        name: figurePreset?.details?.name,
      },
    })

    onClickWrapper({
      type,
      syntheticEvent,
      id,
      figureDetails,
    })

    if (mode === FIGURE_MODE.DELETED) {
      setCellMode(CELL_MODE.CREATE)
      setFiguresCollection(figuresCollection.filter(figure => figure.id !== id))
    } else {
      const currentFigureIndex = figuresCollection.findIndex(figure => figure.id === id)
      if (currentFigureIndex === -1) return

      const updatedFigure = updateFigureItem(figuresCollection[currentFigureIndex], figureDetails, direction, mode)

      setFiguresCollection([
        ...figuresCollection.slice(0, currentFigureIndex),
        updatedFigure,
        ...figuresCollection.slice(currentFigureIndex + 1),
      ])
    }
  }

  const handleCellMouseDown = () => setIsMouseDown(true)
  const handleCellMouseUp = () => setIsMouseDown(false)
  const handleCellMouseOver = () => {
    if (isMouseDown) {
      createFigure()
    }
  }

  const handleMouseDown = (id: string) => {
    const clickedFigureIndex = figuresCollection.findIndex(figure => figure.id === id)
    const clickedFigure = figuresCollection[clickedFigureIndex]

    if (clickedFigure?.id !== currentFigureId) {
      if (!clickedFigure) return
      if (clickedFigure.mode === FIGURE_MODE.HALF) {
        const figureDirection =
          clickedFigure.direction === FIGURE_DIRECTION.LEFT ? FIGURE_DIRECTION.RIGHT : FIGURE_DIRECTION.LEFT
        const figureIndex = figureDirection === FIGURE_DIRECTION.LEFT ? clickedFigureIndex : clickedFigureIndex + 1

        createFigure(false, figureDirection, FIGURE_MODE.HALF, figureIndex)
      }
    }
  }

  // SECTION: Lifecycle
  useEffect(() => {
    // Set current id
    let currentFigureId = studio?.selectedRoom?.id ?? null

    if (figurePreset?.tag.type === TAG_TYPES.ALL_ROOMS) currentFigureId = studio?.id ?? TAG_TYPES.ALL_ROOMS
    if (figurePreset?.tag.type === TAG_TYPES.ALL_STUDIOS) currentFigureId = TAG_TYPES.ALL_STUDIOS

    setCurrentFigureId(currentFigureId)

    // Choose mode
    if (!figuresCollection.length) return
    const isAdded = figuresCollection.some(figure => figure.id === currentFigureId)

    isAdded ? setCellMode(CELL_MODE.UPDATE) : setCellMode(CELL_MODE.CREATE)
  }, [studio, figuresCollection, figurePreset])

  // Check disable status
  useEffect(() => {
    const day = matchKeyByDay(row)

    if (!figurePreset?.tag.type) {
      setIsDisabled(true)
      return
    }

    const studioWorkTime = studio?.workTime?.[day]
    const roomWorkTime = studio?.selectedRoom?.workTime?.dailyWorkTime?.[day]
    const isWithinForStudioTime = getIsWithin(
      column,
      dayjs(studioWorkTime?.from, 'HH:mm').format('HH:mm'),
      dayjs(studioWorkTime?.to, 'HH:mm').format('HH:mm')
    )
    const isWithinForRoomTime = getIsWithin(
      column,
      dayjs(roomWorkTime?.from, 'HH:mm').format('HH:mm'),
      dayjs(roomWorkTime?.to, 'HH:mm').format('HH:mm')
    )

    switch (figurePreset?.tag.type) {
      case TAG_TYPES.ONE_ROOM:
        if (roomWorkTime?.from && roomWorkTime?.to) setIsDisabled(!isWithinForRoomTime)
        else if (studioWorkTime?.from && studioWorkTime?.to) setIsDisabled(!isWithinForStudioTime)
        else setIsDisabled(false)
        break
      case TAG_TYPES.ALL_ROOMS:
        if (studioWorkTime?.from && studioWorkTime?.to) setIsDisabled(!isWithinForStudioTime)
        else setIsDisabled(false)
        break
      case TAG_TYPES.ALL_STUDIOS:
        setIsDisabled(false)
        break
      default:
        setIsDisabled(true)
    }
  }, [studio, row, column, figurePreset])

  useEffect(() => {
    if (!defaultFigures.length) return
    if (isInitFigures) return

    setFiguresCollection(defaultFigures)
    setIsInitFigures(true)
  }, [defaultFigures, isInitFigures])

  // SECTION: Render
  return (
    <div
      className={clsx('hour-cell', {
        'hour-cell--disabled': isDisabled,
        'hour-cell--one-item': figuresCollection.length === 1,
      })}
      onClick={handleCellClick}
      onMouseDown={handleCellMouseDown}
      onMouseUp={handleCellMouseUp}
      onMouseOver={handleCellMouseOver}
    >
      {figuresCollection.map(figure => {
        return (
          <div onMouseDown={() => handleMouseDown(figure.id)}>
            <FigureButton
              key={figure.id}
              onClick={handleFigureClick}
              getIsBlockClick={getIsBlockClick}
              figure={figure}
              isMoreOneItem={figuresCollection.length > 1}
            />
          </div>
        )
      })}
    </div>
  )
}

import { Pagination } from '@api/types/api.types'
import { AxiosResponse } from 'axios'

import { IToggleShiftResponse } from '../../kkm-api/types'
import { HttpConnector } from '../connectors/http.connector'
import { TillsApi } from '../types/tills-api.types'

export class TillsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public getAllOnline = (): Promise<AxiosResponse<TillsApi.IOnlineTill[]>> => {
    return this.httpConnector.get('/tills')
  }
  public getOnline = (id: string): Promise<AxiosResponse<TillsApi.IOnlineTill>> => {
    return this.httpConnector.get(`/tills/${id}`)
  }
  public createOnline = (data: TillsApi.IOnlineTillDTO): Promise<AxiosResponse<TillsApi.IOnlineTill>> => {
    return this.httpConnector.post('/tills', data)
  }
  public update = (id: string, data: any): Promise<AxiosResponse<TillsApi.IOnlineTill>> => {
    return this.httpConnector.patch(`tills/${id}`, data)
  }
  public delete = (id: string): Promise<AxiosResponse<any>> => {
    return this.httpConnector.delete(`tills/${id}`)
  }
  public createOffline = (
    studioId: string,
    data: TillsApi.OfflineTillDTO[]
  ): Promise<AxiosResponse<TillsApi.OfflineTillBD[]>> => {
    return this.httpConnector.post('/offline-tills', {
      studioId,
      offlineTills: data,
    })
  }

  public getOffline = (studioId: string): Promise<AxiosResponse<TillsApi.OfflineTillBD[]>> => {
    return this.httpConnector.get(`/studios/${studioId}/offline-tills`)
  }

  public openShift = (
    tillId: string,
    kkmResponse: IToggleShiftResponse
  ): Promise<AxiosResponse<TillsApi.IToggleShistResponse>> => {
    return this.httpConnector.post(`/offline-tills/${tillId}/shifts`, { kkmCommandResponse: kkmResponse })
  }

  public closeShift = (
    tillId: string,
    tillShiftId: string,
    kkmResponse: IToggleShiftResponse
  ): Promise<AxiosResponse<TillsApi.IToggleShistResponse>> => {
    return this.httpConnector.put(`/offline-tills/${tillId}/shifts/${tillShiftId}/close`, {
      kkmCommandResponse: kkmResponse,
    })
  }

  public getOfflineTillShifts = (
    tillId: string,
    studioId: string
  ): Promise<AxiosResponse<Pagination<TillsApi.IOfflineTillShift>>> => {
    return this.httpConnector.get(`/studios/${studioId}/offline-tills/${tillId}/shifts`)
  }
}

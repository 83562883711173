import * as React from 'react'
import { ColumnsType } from 'antd/lib/table'
import { TableCellProductName } from '@components/table-cells/table-cell-product-name/table-cell-product-name.component'
import { ProductType } from '@api/types/api.types'

import { TransactionsProductsTableActions } from './transactions-products-table-actions/transactions-products-table-actions.component'
import { TransactionsProductsTableAmount } from './transactions-products-table-amount/transactions-products-table-amount.component'
import { TransactionsProductsTableDataItem, TransactionsProductsTableEvents } from './transactions-products-table.types'
import { TransactionsProductsTableDiscount } from './transactions-products-table-discount/transactions-products-table-discount.component'
import { TransactionsProductsTableSum } from './transactions-products-table-sum/transactions-products-table-sum.component'

export function genTransactionsProductsTableColumns(
  events: TransactionsProductsTableEvents
): ColumnsType<TransactionsProductsTableDataItem> {
  const { onRemove, onChangeAmount, onChangeDiscount } = events

  return [
    {
      key: 'name',
      dataIndex: 'name',
      render: (_, product) => <TableCellProductName text={product.name} productType={product.productType} />,
    },
    {
      key: 'count',
      dataIndex: 'count',
      width: 84,
      render: (_, product) => (
        <TransactionsProductsTableAmount
          productUuid={product.uuid}
          value={product.count}
          onChangeAmount={onChangeAmount}
        />
      ),
    },
    {
      key: 'sum',
      dataIndex: 'sum',
      width: 130,
      render: (_, product) => (
        <TransactionsProductsTableSum
          count={product.count ?? 0}
          price={product.price ?? 0}
          trialCost={product.trialCost}
          discountPrice={product.discountPrice ?? 0}
          discount={product.discount ?? 0}
        />
      ),
    },
    {
      key: 'discount',
      dataIndex: 'discount',
      width: 100,
      render: (_, product) => (
        <TransactionsProductsTableDiscount
          productUuid={product.uuid}
          value={product.discount}
          onChangeDiscount={onChangeDiscount}
        />
      ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      width: 38,
      render: (_, product) => <TransactionsProductsTableActions productUuid={product.uuid} onRemove={onRemove} />,
    },
  ]
}

export function getSpaceProduct() {
  return {
    isSpace: true,
    hasMarkCode: false,
    canBindToBooking: false,
    id: 1,
    uuid: 1,
    photo: '1',
    name: 'space',
    price: 0,
    count: 0,
    discountPrice: 0,
    productType: ProductType.SUBSCRIPTION,
  } as unknown as TransactionsProductsTableDataItem
}

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../../types/lang.types'
import { Pagination } from '../../../../api/types/api.types'
import { MasterServicesApi } from '../../../../api/types/master-services-api.types'
import { MasterServicesPageParams } from '../../../../pages/master-services-page/master-services-page.types'

export interface MasterServicesPageListState {
  masterServices: Nullable<Pagination<MasterServicesApi.MasterService>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: MasterServicesPageListState = {
  masterServices: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: masterServicesPageListActions, reducer: masterServicesPageListReducer } = createSlice({
  name: '@@master-services-page-list',
  initialState,
  reducers: {
    fetchAllMasterServices: (state: Draft<MasterServicesPageListState>, _: PayloadAction<MasterServicesPageParams>) => {
      state.isLoading = true
    },
    fetchAllMasterServicesSuccess: (
      state: Draft<MasterServicesPageListState>,
      action: PayloadAction<Nullable<Pagination<MasterServicesApi.MasterService>>>
    ) => {
      state.masterServices = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchAllMasterServicesError: (state: Draft<MasterServicesPageListState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.isLoaded = true
      state.error = action.payload
    },
    removeMasterService: (state: Draft<MasterServicesPageListState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    removeMasterServiceSuccess: (state: Draft<MasterServicesPageListState>) => {
      state.isLoading = false
    },
    removeMasterServiceError: (state: Draft<MasterServicesPageListState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import qs from 'qs'

import { NString, isString, isDef } from '../../types/lang.types'
import { EXERCISES_TYPES_PAGE_SIZE, ExercisesTypesPageParams } from './exercises-types-page.types'

export function genExercisesTypesPageParams(search: NString): ExercisesTypesPageParams {
  const queryParams = isDef(search) ? qs.parse(search, { ignoreQueryPrefix: true }) : null

  const page = isString(queryParams?.page) ? Number(queryParams?.page) : null
  const size = isString(queryParams?.size) ? Number(queryParams?.size) : EXERCISES_TYPES_PAGE_SIZE

  return {
    size,
    page,
  }
}

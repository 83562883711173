import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'

const getExercisesTypesInternal = (state: AppState) => state.directionsCreatePage.exercisesTypes

export const genDirectionsCreatePageIsLoading = (state: AppState): boolean => state.directionsCreatePage.isLoading

export const getDirectionsCreatePageExercisesTypesOptions = createSelector(getExercisesTypesInternal, exercisesTypes =>
  mapExercisesTypesToOptions(exercisesTypes?.content)
)

import { Divider, Form, FormInstance, Space } from 'antd'
import { useState } from 'react'

import { getStrEnumValues } from '../../../../../utils/enum.utils'
import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiCollapse } from '../../master-services-widget-form-ui/master-services-widget-form-ui-collapse/master-services-widget-form-ui-collapse.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiDeviceMode } from '../../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.component'
import { DeviceMode } from '../../master-services-widget-form-ui/master-services-widget-form-ui-device-mode/master-services-widget-form-ui-device-mode.types'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiSize } from '../../master-services-widget-form-ui/master-services-widget-form-ui-size/master-services-widget-form-ui-size.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { genEnvelopeShadowStyles } from './master-services-widget-form-design-settings-envelope.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
}

export const MasterServicesWidgetFormDesignSettingsEnvelope: React.FC<Props> = props => {
  const { form } = props
  const staticWidgetMode = Form.useWatch(['general', 'staticWidgetMode'], form)

  const [deviceMode, setDeviceMode] = useState(DeviceMode.DESKTOP)

  return (
    <MasterServicesWidgetFormUiCollapse title="Дизайн оболочки">
      <Space direction="vertical" size="middle">
        <MasterServicesWidgetFormUiShadow
          form={form}
          element={['design', 'formStyles', 'shadow']}
          initialValues={genEnvelopeShadowStyles()}
        />
        <MasterServicesWidgetFormUiDeviceMode mode={deviceMode} onChaneMode={setDeviceMode} />
        {getStrEnumValues<DeviceMode>(DeviceMode).map(mode => {
          const envelopeDeviceMode = mode.toLowerCase()
          return (
            <Space
              direction="vertical"
              size="middle"
              key={envelopeDeviceMode}
              style={{ display: mode === deviceMode ? 'flex' : 'none' }}
            >
              {!staticWidgetMode && deviceMode === DeviceMode.DESKTOP && (
                <>
                  <MasterServicesWidgetFormUiSize
                    label="Ширина"
                    element={['design', 'formStyles', envelopeDeviceMode, 'width']}
                  />
                  <MasterServicesWidgetFormUiSize
                    label="Высота"
                    element={['design', 'formStyles', envelopeDeviceMode, 'height']}
                  />
                </>
              )}
              <MasterServicesWidgetFormUiBorderRadius
                element="formStyles"
                state={envelopeDeviceMode}
                property="borderRadius"
              />
              <Divider style={{ margin: 0 }} />
              <MasterServicesWidgetFormUiColor
                label="Цвет фона"
                name={['design', 'formStyles', envelopeDeviceMode, 'backgroundColor']}
              />
            </Space>
          )
        })}
      </Space>
    </MasterServicesWidgetFormUiCollapse>
  )
}

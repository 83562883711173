import { put, select, takeLatest } from 'redux-saga/effects'
import { RouterState } from 'connected-react-router'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { getRouterState } from '../../../common/router/router.selectors'
import { callApi } from '../../../../utils/sagas.utils'
import { genMasterServicesPageParams } from '../../../../pages/master-services-page/master-services-page.utils'
import { masterServicesPageListActions } from './master-services-page-list.slice'

export function* fetchAllMasterServices(
  action: ReturnType<typeof masterServicesPageListActions.fetchAllMasterServices>
) {
  try {
    const { page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.masterServicesService.fetchAll>> = yield callApi(
      api.masterServicesService.fetchAll,
      params
    )

    yield put(masterServicesPageListActions.fetchAllMasterServicesSuccess(response.data))
  } catch (e) {
    console.error(e)
    yield put(masterServicesPageListActions.fetchAllMasterServicesError(new Error()))
  }
}

export function* removeMasterService(action: ReturnType<typeof masterServicesPageListActions.removeMasterService>) {
  try {
    yield callApi(api.masterServicesService.remove, action.payload)

    yield put(masterServicesPageListActions.removeMasterServiceSuccess())

    const { location }: RouterState = yield select(getRouterState)

    const { search } = location
    const params = genMasterServicesPageParams(search)

    yield put(masterServicesPageListActions.fetchAllMasterServices(params))
  } catch (e) {
    console.error(e)
    yield put(masterServicesPageListActions.removeMasterServiceError(new Error()))
  }
}

export function* masterServicesPageListSagas() {
  yield takeLatest(masterServicesPageListActions.fetchAllMasterServices, fetchAllMasterServices)
  yield takeLatest(masterServicesPageListActions.removeMasterService, removeMasterService)
}

import { Checkbox, Input, Select, Space, Typography, Form, Col } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

import { CustomFieldsApi } from '../../api/types/custom-fields-api.types'

interface Props {
  handleInputChange: (name: string, value: string, isRequired: boolean) => void
  customFields: CustomFieldsApi.CustomField[] | null | undefined
  hideTitle?: boolean
  size?: SizeType
}

export const CustomFieldsSettingsGenInput = ({
  handleInputChange,
  customFields,
  hideTitle = false,
  size = 'middle',
}: Props) => {
  const renderFormItem = (item: CustomFieldsApi.CustomField) => {
    const name = item.id

    switch (item.type) {
      case 'INPUT':
        return (
          <Input
            placeholder={item.attributes?.placeholder}
            size={size}
            defaultValue={item.value.length === 0 ? item.attributes.default : item.value}
            onChange={e => handleInputChange(name, e.target.value, item.required)}
          />
        )
      case 'SELECT':
        return (
          <Select
            // @ts-ignore
            defaultValue={item.value.length === 0 ? item.attributes.options[0].id : item.value}
            placeholder={item.attributes.placeholder || ''}
            // @ts-ignore
            onChange={value => handleInputChange(name, value, item.required)}
            size={size}
          >
            {item.attributes.options &&
              item.attributes.options.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
          </Select>
        )
      case 'MULTILINE_INPUT':
        return (
          <Input.TextArea
            placeholder={item.attributes?.placeholder || ''}
            defaultValue={item.value.length === 0 ? item.attributes.default : item.value.join('\n')}
            // @ts-ignore
            onChange={e => handleInputChange(name, e.target.value.split('\n'), item.required)}
            size={size}
          />
        )
      case 'CHECKBOX_GROUP':
        return (
          <Space direction="vertical">
            {item.attributes.options &&
              item.attributes.options.map(option => (
                <Checkbox
                  key={option.id}
                  value={option.id}
                  // @ts-ignore
                  defaultChecked={item.value.includes(option.id)}
                  onChange={e => {
                    const checked = e.target.checked
                    let newValue: string[] = []
                    if (checked) {
                      // @ts-ignore
                      newValue = [...(item.value || []), option.id]
                    } else {
                      newValue = (item.value || []).filter(val => val !== option.id)
                    }
                    // @ts-ignore
                    handleInputChange(name, newValue, item.required)
                  }}
                >
                  {option.name}
                </Checkbox>
              ))}
          </Space>
        )
      default:
        return <Checkbox>{item.name}</Checkbox>
    }
  }

  return (
    <>
      {customFields && customFields?.length > 0 ? (
        <Col style={{ marginBottom: 20 }}>
          {!hideTitle && <Typography.Title level={4}>Кастомные поля</Typography.Title>}
          {customFields &&
            customFields.map((item, index) => (
              <div key={item.id} style={{ marginBottom: index < customFields.length - 1 ? 15 : 0 }}>
                <Form.Item required={item.required} label={item.name} name={String(item.id)}>
                  {renderFormItem(item)}
                </Form.Item>
              </div>
            ))}
        </Col>
      ) : (
        <Typography.Title level={4}>Нет кастомных полей</Typography.Title>
      )}
    </>
  )
}

import { ITerminalApi, ITerminalDTO } from '@api/types/terminals-api.types'
import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'

export class TerminalsService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (): Promise<AxiosResponse<ITerminalApi[]>> => {
    return this.httpConnector.get('/terminals')
  }
  public create = (data: ITerminalDTO): Promise<AxiosResponse<ITerminalApi>> => {
    return this.httpConnector.post<ITerminalApi>('terminals', data)
  }
  public update = (id: string, data: ITerminalApi): Promise<AxiosResponse<ITerminalApi>> => {
    return this.httpConnector.patch<ITerminalApi>(`terminals/${id}`, data)
  }
  public delete = (id: string): Promise<AxiosResponse<ITerminalApi>> => {
    return this.httpConnector.delete<ITerminalApi>(`terminals/${id}`)
  }
}

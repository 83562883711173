import * as React from 'react'
import { Table, TablePaginationConfig } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'

import { PositionsTableActions, PositionsTableDataItem } from './positions-table.types'
import { genPositionsTableColumns } from './positions-table.utils'
import { CustomCard } from '../../custom-card/custom-card.component'

interface Props extends PositionsTableActions {
  data: PositionsTableDataItem[]
  positionsOptions: DefaultOptionType[] | undefined
  pagination?: TablePaginationConfig
  loading?: boolean
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export const PositionsTable: React.FC<Props> = props => {
  const { data, positionsOptions, pagination, loading } = props
  const { onEdit, onRemove, onChangePage, onChangePageSize } = props

  const columns = React.useMemo(
    () =>
      genPositionsTableColumns({
        positionsOptions,
        onEdit,
        onRemove,
      }),
    [positionsOptions, onEdit, onRemove]
  )

  const paginationConfig = React.useMemo(
    (): TablePaginationConfig => ({
      ...pagination,
      onChange: onChangePage,
      onShowSizeChange: onChangePageSize,
    }),
    [onChangePage, onChangePageSize, pagination]
  )

  return (
    <CustomCard>
      <Table rowKey="id" columns={columns} dataSource={data} pagination={paginationConfig} loading={loading} />
    </CustomCard>
  )
}

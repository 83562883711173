import { FormInstance, Space } from 'antd'

import { MasterServicesWidgetFormUiBorderRadius } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border-radius/master-services-widget-form-border-radius.component'
import { MasterServicesWidgetFormUiBorder } from '../../master-services-widget-form-ui/master-services-widget-form-ui-border/master-services-widget-form-design-settings-border.component'
import { MasterServicesWidgetFormUiColor } from '../../master-services-widget-form-ui/master-services-widget-form-ui-color/master-services-widget-form-ui-color.component'
import { MasterServicesWidgetFormUiShadow } from '../../master-services-widget-form-ui/master-services-widget-form-ui-shadow/master-services-widget-form-ui-shadow.component'
import { MasterServicesWidgetFormUiTypography } from '../../master-services-widget-form-ui/master-services-widget-form-ui-typography/master-services-widget-form-ui-typography.component'
import { MasterServicesWidgetFormValues } from '../../master-services-widget-form.types'
import { ElementStates } from '../master-services-widget-form-design-settings.types'
import { genButtonFontStyles } from './master-services-widget-form-design-settings-buttons.utils'

interface Props {
  form: FormInstance<MasterServicesWidgetFormValues>
  element: string
  elementState?: string
  hideSelectBorderRadius?: boolean
  hideSelectTypography?: boolean
  hideSelectIconColor?: boolean
}

export const MasterServicesWidgetFormDesignSettingsButtons: React.FC<Props> = props => {
  const { form, element, elementState = ElementStates.DEFAULT } = props
  const { hideSelectBorderRadius, hideSelectTypography, hideSelectIconColor } = props

  return (
    <Space direction="vertical" size="middle">
      <MasterServicesWidgetFormUiColor
        label="Цвет фона"
        name={['design', 'buttonStyles', element, elementState, 'backgroundColor']}
      />
      {element === 'buttonFillColor' && elementState.toUpperCase() === ElementStates.DEFAULT && (
        <MasterServicesWidgetFormUiColor
          label="Цвет фона кнопки"
          name={['design', 'buttonStyles', element, elementState, 'buttonBackgroundColor']}
        />
      )}
      {!hideSelectTypography && (
        <MasterServicesWidgetFormUiTypography
          form={form}
          element={['design', 'buttonStyles', element, elementState, 'fontStyles']}
          initialValues={genButtonFontStyles(element, elementState)}
        />
      )}
      <MasterServicesWidgetFormUiShadow
        form={form}
        element={['design', 'buttonStyles', element, elementState, 'shadow']}
      />
      {!hideSelectIconColor && (
        <MasterServicesWidgetFormUiColor
          label="Цвет иконки"
          name={['design', 'buttonStyles', element, elementState, 'iconColor']}
        />
      )}
      {!hideSelectBorderRadius && (
        <MasterServicesWidgetFormUiBorderRadius
          element={element}
          elementGroup="buttonStyles"
          state={elementState}
          property="borderRadius"
        />
      )}
      <MasterServicesWidgetFormUiBorder element={element} elementGroup="buttonStyles" state={elementState} />
    </Space>
  )
}

import { ConditionTypes } from '../../../../../../api/types/api.types'

export function genPricingImpactsAddButtonTitle(type: string) {
  switch (type) {
    case ConditionTypes.TIME:
      return 'По дням неделям и времени'
    case ConditionTypes.GRADE:
      return 'По должности'
    case ConditionTypes.DURATION:
      return 'По длительности'
    case ConditionTypes.WITH_TRAINER:
      return 'По исполнителю'
  }
}

export function genPricingImpactsAddButtonSubtitle(type: string) {
  switch (type) {
    case ConditionTypes.TIME:
      return 'Добавьте вариацию цены в зависимости от времени услуги.'
    case ConditionTypes.GRADE:
      return 'Добавьте вариацию цены в зависимости от должности исполнителя услуги. Например, выбор старшего тренера увеличит стоимость на 2000 рублей.'
    case ConditionTypes.DURATION:
      return 'Добавьте вариацию цены в зависимости от длительности услуги.'
    case ConditionTypes.WITH_TRAINER:
      return 'Добавьте вариацию цены в зависимости исполнителя.'
  }
}

import { ClientsBookingsApi } from '@api/types/clients-bookings-api.types'

export const transformBookingToRecord = (booking: ClientsBookingsApi.ClientBooking | null) => {
  if (!booking) return null

  return {
    bookingIds: [booking.id],
    exerciseDate: booking.exerciseDate,
    timetable: null,
    exerciseIds: [booking.exerciseId],
    exerciseDirection: booking.exerciseDirection,
    exerciseDuration: booking.exerciseDuration,
    exerciseType: booking.exerciseType,
    exerciseTypeFormat: booking.exerciseTypeFormat,
    studio: booking.studio,
    trainers: (booking?.trainers ?? [])?.length > 0 ? booking.trainers : [],
    responseId: 77777777777,
  } as unknown as ClientsBookingsApi.UnpaidRecord
}

import * as React from 'react'

import { ClientsSearch } from '../../components/clients/clients-search/clients-search.component'
import { useClientsSearch } from './clients-search.hook'

interface Props {
  className?: string
}

export const ClientsSearchContainer: React.FC<Props> = props => {
  const { className } = props

  const { searchValue, options, loading, onChangeHandler, onSearchHandler, onSelectHandler, onClearHandler } =
    useClientsSearch()

  return (
    <ClientsSearch
      className={className}
      value={searchValue}
      options={options}
      loading={loading}
      onChange={onChangeHandler}
      onInputChange={onSearchHandler}
      onSelect={onSelectHandler}
      onClear={onClearHandler}
    />
  )
}

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { genFranchisesEditPagePath } from '../../../format/path.format'
import {
  genFranchisesCardList,
  genFranchisesIsLoading,
} from '../../../store/pages/franchises-page/franchises-page.selectors'

export function useFranchisesPageLists() {
  const { push } = useHistory()

  const franchises = useSelector(genFranchisesCardList)
  // const totalElements = useSelector(genFranchisesTotalElements)
  const isLoading = useSelector(genFranchisesIsLoading)

  const onEditHandler = (id: string): void => {
    push(genFranchisesEditPagePath({ id }))
  }

  return {
    franchises,
    isLoading,
    onEditHandler,
  }
}

import { Button, Row, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import { genMasterServicesCreatePagePath, genMasterServicesWidgetCreatePagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'

export const MasterServicesPageTopBar = () => {
  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
      <Typography.Title level={2}>Список персональных услуг</Typography.Title>
      <Space size={8}>
        <Link to={genMasterServicesWidgetCreatePagePath()}>
          <Button>{formatPathName(AppPath.MASTER_SERVICES_WIDGET_CREATE)}</Button>
        </Link>
        <Link to={genMasterServicesCreatePagePath()}>
          <Button type="primary" icon={<PlusOutlined />}>
            {formatPathName(AppPath.MASTER_SERVICES_CREATE)}
          </Button>
        </Link>
      </Space>
    </Row>
  )
}

import { put, takeLatest } from 'redux-saga/effects'

import { api } from '../../../../api/api'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { clientsEditPagePeekExerciseHistoryActions } from './clients-edit-page-peek-exercise-history.slice'

function* fetchAudit(action: ReturnType<typeof clientsEditPagePeekExerciseHistoryActions.fetchAudit>) {
  try {
    const { page, size, exerciseId, clientId } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.auditService.fetchById>> = yield callApi(
      api.auditService.fetchById,
      'exercise',
      exerciseId,
      { ...params, sort: 'time,desc', clientId }
    )
    yield put(clientsEditPagePeekExerciseHistoryActions.fetchAuditSuccess(response.data))
  } catch (e) {
    yield put(clientsEditPagePeekExerciseHistoryActions.fetchAuditError(new Error()))
  }
}

export function* clientsEditPagePeekExerciseHistorySagas() {
  yield takeLatest(clientsEditPagePeekExerciseHistoryActions.fetchAudit.type, fetchAudit)
}

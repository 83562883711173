import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../api/types/api.types'
import { Nullable } from '../../../types/lang.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { TrainersApi } from '../../../api/types/trainers-api.types'
import { StudiosApi } from '../../../api/types/studios-api.types'
import {
  MasterServicesCreatePageServiceCategoryCreatePayload,
  MasterServicesCreatePageUpdatePricingPayload,
} from './master-services-create-page.types'
import { ServiceCategoriesApi } from '../../../api/types/service-categories-api.types'
import { PricingApi } from '../../../api/types/pricing.types'
import {
  MasterServicesFormValues,
  MasterServicesPricingFormValues,
} from '../../../components/master-services/master-services-form/master-services-form.types'
import { EmployeesPositionsApi } from '../../../api/types/employees-positions-api.types'

export interface MasterServicesCreatePageState {
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  serviceCategories: Nullable<ServiceCategoriesApi.ServiceCategory[]>
  pricing: Nullable<Pagination<PricingApi.Pricing>>
  positions: Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>
  selectedPricing: Nullable<PricingApi.Pricing>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: MasterServicesCreatePageState = {
  exercisesTypes: null,
  trainers: null,
  studios: null,
  serviceCategories: null,
  pricing: null,
  positions: null,
  selectedPricing: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: masterServicesCreatePageActions, reducer: masterServicesCreatePageReducer } = createSlice({
  name: '@@master-services-create-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchExercisesTypes: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchExercisesTypesSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercisesTypes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchExercisesTypesError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainers: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchTrainersSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ) => {
      state.trainers = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainersError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchStudios: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchStudiosSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<StudiosApi.Studio>>>
    ) => {
      state.studios = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchStudiosError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricing: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchPricingSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<PricingApi.Pricing>>>
    ) => {
      state.pricing = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingById: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPricingByIdSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<PricingApi.Pricing>>
    ) => {
      state.selectedPricing = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingByIdError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPositions: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchPositionsSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>>
    ) => {
      state.positions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPositionsError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchServiceCategories: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = true
    },
    fetchServiceCategoriesSuccess: (
      state: Draft<MasterServicesCreatePageState>,
      action: PayloadAction<Nullable<ServiceCategoriesApi.ServiceCategory[]>>
    ) => {
      state.serviceCategories = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchServiceCategoriesError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    createServiceCategory: (
      state: Draft<MasterServicesCreatePageState>,
      _: PayloadAction<MasterServicesCreatePageServiceCategoryCreatePayload>
    ) => {
      state.isLoading = true
    },
    createServiceCategorySuccess: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = false
    },
    createServiceCategoryError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    createPricing: (state: Draft<MasterServicesCreatePageState>, _: PayloadAction<MasterServicesPricingFormValues>) => {
      state.isLoading = true
    },
    createPricingSuccess: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = false
    },
    createPricingError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    updatePricing: (
      state: Draft<MasterServicesCreatePageState>,
      _: PayloadAction<MasterServicesCreatePageUpdatePricingPayload>
    ) => {
      state.isLoading = true
    },
    updatePricingSuccess: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = false
    },
    updatePricingError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    createMasterService: (state: Draft<MasterServicesCreatePageState>, _: PayloadAction<MasterServicesFormValues>) => {
      state.isLoading = true
    },
    createMasterServiceSuccess: (state: Draft<MasterServicesCreatePageState>) => {
      state.isLoading = false
    },
    createMasterServiceError: (state: Draft<MasterServicesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    reset: () => initialState,
  },
})

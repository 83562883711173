import { LeftOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { genStudiosPagePath } from '../../../format/path.format'
import { formatPathName } from '../../../format/text.format'
import { AppPath } from '../../../types/path.types'

export const StudiosCreatePageTopBar = () => {
  return (
    <div>
      <Button type="text" icon={<LeftOutlined />} size="small">
        <Link to={genStudiosPagePath()}>
          <Typography.Text className="backBtnTitle">{formatPathName(AppPath.STUDIOS)}</Typography.Text>
        </Link>
      </Button>
      <Typography.Title level={2} style={{ margin: '24px 0' }}>
        Создание студии
      </Typography.Title>
    </div>
  )
}

import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { ServiceCategoriesApi } from '../types/service-categories-api.types'

export class ServiceCategoriesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (): Promise<AxiosResponse<ServiceCategoriesApi.ServiceCategory[]>> => {
    return this.httpConnector.get<ServiceCategoriesApi.ServiceCategory[]>('/products/services/categories')
  }

  public create = (
    data: ServiceCategoriesApi.ServiceCategoryDTO
  ): Promise<AxiosResponse<ServiceCategoriesApi.ServiceCategory>> => {
    return this.httpConnector.post<ServiceCategoriesApi.ServiceCategory>('/products/services/categories', data)
  }
}

import { put, takeLatest } from 'redux-saga/effects'
import { callApi } from '@utils/sagas.utils'
import { api } from '@api/api'

import { studioEntityActions } from './studio.slice'

export function* fetchAllStudios(_: ReturnType<typeof studioEntityActions.fetchAllStudios>) {
  try {
    const studios: Awaited<ReturnType<typeof api.studiosService.fetchAll>> = yield callApi(
      api.studiosService.fetchAll,
      { size: 300 }
    )

    yield put(studioEntityActions.fetchAllStudiosSuccess({ studios: studios?.data?.content ?? [] }))
  } catch (e) {
    yield put(studioEntityActions.fetchAllStudiosError(new Error()))
  }
}

export function* studioEntitySagas() {
  yield takeLatest(studioEntityActions.fetchAllStudios, fetchAllStudios)
}

import { createSelector } from '@reduxjs/toolkit'

import { mapAuditToHistoryList } from '../../../../mapping/audit.mapping'
import { AppState } from '../../../app.store'
import { getCurrentStudioOffset } from '../../../common/layout/layout.selectors'

const genGoodsAuditInternal = (state: AppState) => state.goodsPageReducer.goodsPagePeekHistory.goodsAudit

export const genGoodsPagePeekHistoryIsLoading = (state: AppState) =>
  state.goodsPageReducer.goodsPagePeekHistory.isLoading

export const genGoodsPagePeekHistoryTotalElements = createSelector(genGoodsAuditInternal, audit => audit?.totalElements)

export const genGoodsPagePeekHistoryGoodsHistory = createSelector(
  genGoodsAuditInternal,
  getCurrentStudioOffset,
  (audit, studioOffset) => mapAuditToHistoryList(audit?.content, studioOffset)
)

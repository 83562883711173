import { KkmAPI } from '../../../../../kkm-api/kkm'
import { IPrintXReportResponse } from '../../../../../kkm-api/types'
import { IOpenShiftInternalResult } from '../../types/utils'
import { notificationsXReport } from './notification-x-report'

export const printXReport = async (numDevice: number | null | undefined) => {
  if (!numDevice) {
    notificationsXReport.errorWithPrintXReport()
    return
  }

  let result: IOpenShiftInternalResult<IPrintXReportResponse> = {
    hasError: false,
    response: null,
  }

  let hasError = false
  result.response = await KkmAPI.printXReport(numDevice).catch(err => {
    hasError = true
    console.error(err)
  })

  if (hasError) {
    notificationsXReport.errorWithPrintXReport()
    return
  }

  notificationsXReport.successWithPrintXReport()
}

import { all, put, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'

import { api } from '../../../../api/api'
import { UserType } from '../../../../types/users.types'
import { callApi } from '../../../../utils/sagas.utils'
import { clientsEditPageCommonActions } from './clients-edit-page-common.slice'

export function* fetchPageData(action: ReturnType<typeof clientsEditPageCommonActions.fetchPageData>) {
  try {
    const [client, managers]: [
      Awaited<ReturnType<typeof api.clientsService.fetchById>>,
      Awaited<ReturnType<typeof api.employeesService.fetchAll>>
    ] = yield all([
      callApi(api.clientsService.fetchById, action.payload),
      callApi(
        api.employeesService.fetchAll,
        {
          size: 100,
        },
        UserType.Managers
      ),
    ])

    yield put(clientsEditPageCommonActions.fetchPageDataSuccess({ client: client.data, managers: managers.data }))
  } catch (e) {
    yield put(clientsEditPageCommonActions.fetchPageDataError(new Error()))
  }
}

export function* updateManager(action: ReturnType<typeof clientsEditPageCommonActions.updateManager>) {
  try {
    const { clientId, managerId } = action.payload

    yield callApi(api.clientsService.updateManager, clientId, managerId)
    yield put(clientsEditPageCommonActions.updateManagerSuccess(managerId))

    yield notification.success({
      message: 'Менеджер успешно обновлен',
    })
  } catch {
    yield put(clientsEditPageCommonActions.updateManagerError(new Error()))
  }
}

export function* clientsEditPageCommonSagas() {
  yield takeLatest(clientsEditPageCommonActions.fetchPageData, fetchPageData)
  yield takeLatest(clientsEditPageCommonActions.updateManager, updateManager)
}

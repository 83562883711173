import { MasterServicesWidgetFormValues } from '@components/master-services-widget/master-services-widget-form/master-services-widget-form.types'
import { Form } from 'antd'
import { useSelector } from 'react-redux'

import { getTenantKey } from '../../../store/common/layout/layout.selectors'
import { getMasterServicesWidgetCreatePageMasterServicesOptions } from '../../../store/pages/master-services-widget-create-page/master-services-widget-create-page.selectors'

export function useMasterServicesWidgetCreatePageForm() {
  const [form] = Form.useForm<MasterServicesWidgetFormValues>()

  const masterServicesOptions = useSelector(getMasterServicesWidgetCreatePageMasterServicesOptions)
  const tenantKey = useSelector(getTenantKey)

  return {
    form,
    masterServicesOptions,
    tenantKey,
  }
}

import { AxiosResponse } from 'axios'

import { HttpConnector } from '../connectors/http.connector'
import { SalariesApi } from '../types/salaries-api.types'

export class SalariesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (): Promise<AxiosResponse<SalariesApi.Salary[]>> => {
    return this.httpConnector.get<SalariesApi.Salary[]>('/salary')
  }

  public update = (data: SalariesApi.SalaryDTO[]): Promise<AxiosResponse<SalariesApi.Salary[]>> => {
    return this.httpConnector.post<SalariesApi.Salary[]>('/salary', data)
  }

  public remove = (data: SalariesApi.DeletedSalaryDTO[]) => {
    const config = {
      data: data,
    }
    return this.httpConnector.delete('/salary', config)
  }
}

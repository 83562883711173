import * as React from 'react'
import { Modal, Select } from 'antd'

import { TableCellActions } from '../../../table-cells/table-cell-actions/table-cell-actions.component'
import { PositionsTableActions } from '../positions-table.types'
import './positions-table-actions.styles.less'
import { PositionType } from '../../../../types/positions.types'

interface Props extends PositionsTableActions {
  id: string
  name: string
  positionType: PositionType
}

export const PositionsTableColumnActions: React.FC<Props> = props => {
  const { id, name, positionsOptions, positionType } = props
  const { onEdit, onRemove } = props

  const onEditHandler = React.useCallback(() => onEdit(id), [id, onEdit])

  const [substitutePositionId, setSubstitutePositionId] = React.useState<string>()

  const [modalOpen, setModalOpen] = React.useState(false)

  const showModal = () => {
    setModalOpen(true)
  }

  const handleOk = () => {
    onRemove(id, substitutePositionId)
    setModalOpen(false)
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  return (
    <>
      <TableCellActions
        forbidRemove={positionType !== PositionType.CUSTOM}
        onEdit={onEditHandler}
        onRemove={showModal}
      />
      <Modal title={`Подтвердите удаление должности ${name}`} open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div>Выберите должность, на которую будут переведены сотрудники удаляемой должности.</div>
        <Select
          options={positionsOptions}
          className="positions-table-actions__select"
          placeholder="Должность"
          onChange={value => setSubstitutePositionId(value)}
        />
      </Modal>
    </>
  )
}

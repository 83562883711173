import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Pagination } from '../../../../api/types/api.types'
import { AuditApi } from '../../../../api/types/audit.types'
import { Nullable } from '../../../../types/lang.types'
import { TransactionPagePeekHistoryFetchAuditPayload } from './transactions-page-peek-history.types'

export interface TransactionsPagePeekHistoryState {
  transactionAudit: Nullable<Pagination<AuditApi.Audit>>
  isLoading: boolean
  error: Nullable<Error>
}

const initialState: TransactionsPagePeekHistoryState = {
  transactionAudit: null,
  isLoading: false,
  error: null,
}

export const { actions: transactionsPagePeekHistoryActions, reducer: transactionsPagePeekHistoryReducer } = createSlice(
  {
    name: '@@transaction-page-peek-history',
    initialState,
    reducers: {
      fetchAudit: (
        state: Draft<TransactionsPagePeekHistoryState>,
        _: PayloadAction<TransactionPagePeekHistoryFetchAuditPayload>
      ): void => {
        state.isLoading = true
      },
      fetchAuditSuccess: (
        state: Draft<TransactionsPagePeekHistoryState>,
        action: PayloadAction<Pagination<AuditApi.Audit>>
      ): void => {
        state.transactionAudit = action.payload
        state.isLoading = false
      },
      fetchAuditError: (state: Draft<TransactionsPagePeekHistoryState>, action: PayloadAction<Error>): void => {
        state.error = action.payload
        state.isLoading = false
      },
      reset: () => initialState,
    },
  }
)

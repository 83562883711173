import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { NEmployeesScheduleEdit } from './employees-schedule-edit.types'

const initialState: NEmployeesScheduleEdit.IState = {
  schedule: null,
  daysOff: null,
  isLoading: false,
  error: null,
}

export const { actions: employeesScheduleEditActions, reducer: employeesScheduleEditReducer } = createSlice({
  name: '@@employees-schedule-edit',
  initialState,
  reducers: {
    // Fetch by id
    fetchById: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _: PayloadAction<NEmployeesScheduleEdit.IFetchByIdPayload>
    ) => {
      state.isLoading = true
    },
    fetchByIdSuccess: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      action: PayloadAction<NEmployeesScheduleEdit.IFetchByIdSuccessPayload>
    ) => {
      state.isLoading = false
      state.schedule = action.payload.schedule
      state.daysOff = action.payload.schedule.daysOff
    },
    fetchByIdError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Edit
    edit: (state: Draft<NEmployeesScheduleEdit.IState>, _: PayloadAction<NEmployeesScheduleEdit.IEditPayload>) => {
      state.isLoading = true
    },
    editSuccess: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.isLoading = false
    },
    editError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },

    // Days-Off
    fetchDaysOff: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _action: PayloadAction<NEmployeesScheduleEdit.IFetchDaysOffPayload>
    ) => {
      state.isLoading = false
    },
    fetchDaysOffSuccess: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      action: PayloadAction<NEmployeesScheduleEdit.IFetchDaysOffSuccessPayload>
    ) => {
      state.isLoading = false
      state.daysOff = action.payload.daysOff
    },
    fetchDaysOffError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    createDayOff: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _action: PayloadAction<NEmployeesScheduleEdit.ICreateDayOffPayload>
    ) => {
      state.isLoading = true
    },
    createDayOffSuccess: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.isLoading = false
    },
    createDayOffError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    editDayOff: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _: PayloadAction<NEmployeesScheduleEdit.IEditDayOffPayload>
    ) => {
      state.isLoading = true
    },
    editDayOffSuccess: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.isLoading = false
    },
    editDayOffError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    removeDayOff: (
      state: Draft<NEmployeesScheduleEdit.IState>,
      _action: PayloadAction<NEmployeesScheduleEdit.IRemoveDayOffPayload>
    ) => {
      state.isLoading = true
    },
    removeDayOffSuccess: (state: Draft<NEmployeesScheduleEdit.IState>) => {
      state.isLoading = false
    },
    removeDayOffError: (state: Draft<NEmployeesScheduleEdit.IState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

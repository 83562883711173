import { WIDGET_URL } from '../../../../app/app.config'

export const genMasterServicesWidgetScript = (config: { [key: string]: any }) => {
  return `
  <script>
    (function (w, d, s, o, f, js, fjs) {
      w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
      js = d.createElement(s), fjs = document.currentScript || d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    })(window, document, 'script', '_smBookingWidget', '${WIDGET_URL}');
    _smBookingWidget('init', ${JSON.stringify(config)});
  </script>
`
}

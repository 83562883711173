import * as React from 'react'
import { Flex } from 'antd'

import { NString } from '../../../types/lang.types'
import { TableCellTagProductType } from '../table-cell-tag-product-type/table-cell-tag-product-type.component'
import { TableCellText } from '../table-cell-text/table-cell-text.component'
import './table-cell-product-name.styles.less'
import { ProductType } from '../../../types/products.types'

interface Props {
  text: NString
  productType: ProductType
}

export const TableCellProductName: React.FC<Props> = props => {
  const { text, productType } = props

  return (
    <Flex vertical className="table-cell-product-name" gap="small">
      <TableCellTagProductType productType={productType} />
      <TableCellText text={text} />
    </Flex>
  )
}

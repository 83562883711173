import * as React from 'react'
import { Button, Flex, Table, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { IPeriodCellProps } from './period-cell.types'
import './period-cell.styles.less'

export const PeriodCell: React.FC<IPeriodCellProps> = ({ dateStart, dateEnd }) => {
  return (
    <Flex className="period-cell">
      <p>{`${dateStart} – ${dateEnd}`}</p>
    </Flex>
  )
}

// @ts-nocheck

import { notification } from 'antd'

import { KkmAPI } from '../../../../kkm-api/kkm'
import { ISyncUtilResult } from '../sync-kkm-with-backend.types'

export const runKkmCommands = async (kkmCommandData: any): ISyncUtilResult<any> => {
  if (!kkmCommandData?.length) return { response: [], hasError: false }

  const responses = await Promise.all(
    kkmCommandData.map(async commandData => {
      const response = await KkmAPI.printCheck(commandData)

      if (response.Status !== 0 || response.Status !== 1) return response

      while (true) {
        const result = await KkmAPI.getCommandResult(response.IdCommand)
        if (result.Rezult.Status !== 1) {
          return result.Rezult
        }
        await new Promise(resolve => setTimeout(resolve, 2000))
      }
    })
  )

  const hasError = responses.some(response => response.Status === 2)

  if (hasError) {
    notification.error({
      message: 'Не удалось выполнить команды ККМ',
      description: 'Причина: ' + responses.find(response => response.Status === 2).Error,
    })
  }

  return { response: responses, hasError }
}

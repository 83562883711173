import { createSelector } from '@reduxjs/toolkit'

import { AppState } from '../../app.store'
import { mapExercisesTypesToOptions } from '../../../mapping/exercises-types.mapping'
import { mapServiceCategoriesToServiceCategoriesOptions } from '../../../mapping/service-categories.mapping'
import { mapStudiosToOptions, mapStudiosToRoomsOptions } from '../../../mapping/studios.mapping'
import { mapTrainersToOptions, mapTrainersToTrainersWithGrades } from '../../../mapping/trainers.mapping'
import { genMasterServicesFormValues } from '../../../mapping/master-services.mapping'
import { mapPricingToOptions, genPricingFormValues } from '../../../mapping/pricing.mapping'
import { mapEmployeesPositionsToOptions } from '../../../mapping/employees-positions.mapping'
import { ExerciseTypeFormat } from '../../../api/types/api.types'

const getExercisesTypesInternal = (state: AppState) => state.masterServicesEditPage.exercisesTypes
const getTrainersInternal = (state: AppState) => state.masterServicesEditPage.trainers
const getStudiosInternal = (state: AppState) => state.masterServicesEditPage.studios
const getServiceCategoriesInternal = (state: AppState) => state.masterServicesEditPage.serviceCategories
const getSelectedPricingInternal = (state: AppState) => state.masterServicesEditPage.selectedPricing
const getPositionsInternal = (state: AppState) => state.masterServicesEditPage.positions

export const getMasterService = (state: AppState) => state.masterServicesEditPage.masterService
export const getPricing = (state: AppState) => state.masterServicesEditPage.pricing
export const genMasterServicesEditPageIsLoading = (state: AppState): boolean => state.masterServicesEditPage.isLoading
export const genMasterServicesEditPageIsLoaded = (state: AppState): boolean => state.masterServicesEditPage.isLoaded

export const getMasterServicesEditPageExercisesTypesOptions = createSelector(
  getExercisesTypesInternal,
  exercisesTypes =>
    mapExercisesTypesToOptions(exercisesTypes?.content?.filter(type => type.format === ExerciseTypeFormat.PERSONAL))
)

export const getMasterServicesEditPageTrainersOptions = createSelector(getTrainersInternal, trainers =>
  mapTrainersToOptions(trainers?.content)
)

export const getMasterServicesEditPageStudiosOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToOptions(studios?.content)
)

export const getMasterServicesEditPageStudiosRoomsOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToRoomsOptions(studios?.content)
)

export const getMasterServicesEditPagePricingOptions = createSelector(getPricing, pricing =>
  mapPricingToOptions(pricing?.content)
)

export const getMasterServicesEditPageSelectedPricing = createSelector(
  getSelectedPricingInternal,
  getPositionsInternal,
  (pricing, positions) => genPricingFormValues(pricing, positions?.content)
)

export const getMasterServicesEditPageServiceCategoriesOptions = createSelector(
  getServiceCategoriesInternal,
  mapServiceCategoriesToServiceCategoriesOptions
)

export const getMasterServicesEditPagePositions = (state: AppState) => state.masterServicesEditPage.positions?.content

export const getMasterServicesEditPagePositionsOptions = createSelector(getPositionsInternal, positions =>
  mapEmployeesPositionsToOptions(positions?.content)
)

export const getMasterServicesEditPageTrainers = createSelector(getTrainersInternal, trainers =>
  mapTrainersToTrainersWithGrades(trainers?.content)
)

export const getMasterServicesEditPagePricing = (state: AppState) => state.masterServicesEditPage.pricing?.content

export const getMasterServicesFormValues = createSelector(getMasterService, getPricing, (masterService, pricing) =>
  genMasterServicesFormValues(masterService, pricing?.content)
)

import {
  genScheduleGroupPageExercise,
  genScheduleGroupPageExerciseLinked,
} from '@store/pages/schedule-group-page/schedule-group-page-list/schedule-group-page-list.selectors'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { genScheduleGroupPagePath } from '../../../format/path.format'
import { isDef } from '../../../types/lang.types'
import { validateStrEnumValue } from '../../../utils/enum.utils'
import { useScheduleGroupPageParams } from '../schedule-group-page-hooks/schedule-group-page-params.hook'
import { ScheduleGroupPageSection, ScheduleGroupPageUrlParams } from '../schedule-group-page.types'
import { genScheduleGroupPageMenuTabs } from './schedule-group-page-menu.utils'

export function useScheduleGroupPageMenu() {
  const { push } = useHistory()
  const history = useHistory<{ pathname: string }>()
  const state = history.location.state
  const { studioId, scheduleId, section } = useScheduleGroupPageParams()
  const exerciseLinkedLength = useSelector(genScheduleGroupPageExerciseLinked)?.length
  const scheduleFormat = useSelector(genScheduleGroupPageExercise)?.type.format

  const menuItems = genScheduleGroupPageMenuTabs({
    linkedLength: exerciseLinkedLength ? exerciseLinkedLength : 0,
    scheduleFormat: scheduleFormat || 'GROUP',
  })

  const onChangeHandler = React.useCallback(
    (activeKey: string): void => {
      const section = validateStrEnumValue<ScheduleGroupPageSection>(ScheduleGroupPageSection, activeKey)
      const params: ScheduleGroupPageUrlParams =
        isDef(section) && section !== ScheduleGroupPageSection.OVERVIEW
          ? { studioId, scheduleId, section }
          : { studioId, scheduleId }

      state ? push(genScheduleGroupPagePath(params), state) : push(genScheduleGroupPagePath(params))
    },
    [studioId, scheduleId, push, state]
  )

  return {
    section,
    menuItems,
    onChangeHandler,
  }
}

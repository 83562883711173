import { Form } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ProductsSubscriptionsFormValue } from '../../../components/products/products-subscriptions-form/products-subscriptions-form.types'
import {
  genProductsSubscriptionsCreatePageIsLoading,
  getDirectionsOptions,
  getExercisesOptions,
  getStudiosOptions,
} from '../../../store/pages/products-subscriptions-сreate-page/products-subscriptions-сreate-page.selectors'
import { productsSubscriptionsCreatePageActions } from '../../../store/pages/products-subscriptions-сreate-page/products-subscriptions-сreate-page.slice'
import { useSubscriptionsCreatePageParams } from '../products-subscriptions-create-page-hooks/products-services-create-page.hook'

export function useProductSubscriptionsCreatePageForm() {
  const { type } = useSubscriptionsCreatePageParams()

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const isLoading = useSelector(genProductsSubscriptionsCreatePageIsLoading)
  const directionsOptions = useSelector(getDirectionsOptions)
  const studiosOptions = useSelector(getStudiosOptions)
  const exercisesOptions = useSelector(getExercisesOptions)

  const onFinishHandler = React.useCallback(
    (values: ProductsSubscriptionsFormValue): void => {
      const dataForm = {
        ...values,
        timeLimination: values.timeLimitation,
        hasTypeLimitation: true,
        hasDirectionLimitation: values.availableDirections?.length > 0 ? true : false,
        hasStudioLimitation: values.availableStudios?.length > 0 ? true : false,
      }
      dispatch(productsSubscriptionsCreatePageActions.createSubscription({ ...dataForm, type }))
    },
    [dispatch, type]
  )

  return {
    form,
    isLoading,
    directionsOptions,
    studiosOptions,
    exercisesOptions,
    onFinishHandler,
  }
}

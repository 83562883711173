import { Space, Typography, Form, InputNumber, ColorPicker } from 'antd'

import { isDef } from '../../../../../types/lang.types'

interface Props {
  element: string
  elementGroup?: string
  state?: string
}

export const MasterServicesWidgetFormUiBorder: React.FC<Props> = props => {
  const { element, elementGroup, state } = props

  return (
    <Space direction="vertical">
      <Typography.Text>Обводка, px</Typography.Text>
      <Space align="start">
        <Space direction="vertical">
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              'border',
              'top',
            ]}
            style={{ width: '44px' }}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Верх</Typography.Text>
        </Space>
        <Space direction="vertical">
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              'border',
              'right',
            ]}
            style={{ width: '44px' }}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Право</Typography.Text>
        </Space>
        <Space direction="vertical">
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              'border',
              'bottom',
            ]}
            style={{ width: '44px' }}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Низ</Typography.Text>
        </Space>
        <Space direction="vertical">
          <Form.Item
            name={[
              'design',
              ...(isDef(elementGroup) ? [elementGroup] : []),
              element,
              ...(isDef(state) ? [state] : []),
              'border',
              'left',
            ]}
            style={{ width: '44px' }}
          >
            <InputNumber />
          </Form.Item>
          <Typography.Text type="secondary">Лево</Typography.Text>
        </Space>
        <Form.Item
          name={[
            'design',
            ...(isDef(elementGroup) ? [elementGroup] : []),
            element,
            ...(isDef(state) ? [state] : []),
            'border',
            'color',
          ]}
          style={{ width: '44px' }}
          getValueFromEvent={color => {
            return '#' + color.toHex()
          }}
        >
          <ColorPicker />
        </Form.Item>
      </Space>
    </Space>
  )
}

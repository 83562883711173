import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FC, ReactElement, useMemo, useState } from 'react'

import { CustomFieldsTableActions, CustomFieldsTableRow } from './custom-fields-table.types'
import { genCustomFieldsTableColumns } from './custom-fields-table.utils'
import { CustomCard } from '../../custom-card/custom-card.component'
import { CustomFieldsPageButton } from '../../../pages/custom-fields-page/custom-fields-page-button/custom-fields-page-button.component'

interface Props extends CustomFieldsTableActions {
  className?: string
  data: CustomFieldsTableRow[]
  loading?: boolean
}

export const CustomFieldsTable: FC<Props> = props => {
  const { data, loading } = props
  const { onEdit, onRemove, onPause } = props

  const columns: ColumnsType<CustomFieldsTableRow> = genCustomFieldsTableColumns({ onEdit, onRemove, onPause, data })

  return (
    <CustomCard>
      <Table pagination={false} className="table" rowKey="id" columns={columns} dataSource={data} loading={loading} />
      <CustomFieldsPageButton />
    </CustomCard>
  )
}

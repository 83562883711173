// @ts-nocheck

import { notification } from 'antd'

import { Api, api } from '../../../../api/api'
import { ISyncUtilResult } from '../sync-kkm-with-backend.types'

export const sendKkmCommands = async (
  sendKKMResponsesCallback: (api: Api, kkmResponses: any) => Promise<any>,
  kkmResponses,
  errorMessageForSendKKMCommands
): ISyncUtilResult<any> => {
  let hasError = false

  const response = await sendKKMResponsesCallback(api, kkmResponses?.response).catch(e => {
    const axiosError = e?.message
    const backendError = e?.response?.data?.message

    hasError = true
    notification.error({
      message: errorMessageForSendKKMCommands ?? 'Соединение с кассой потеряно',
      description: 'Причина: ' + backendError ?? axiosError,
    })
  })

  return {
    response: response?.data,
    hasError,
  }
}

import { Card, Checkbox, Flex, Form, Input, Radio, Select, Space, Switch, Typography } from 'antd'
import { useState } from 'react'

import {
  genCurrencyOptions,
  genLanguageOptions,
  genWidgetModeOptions,
} from './master-services-widget-form-general-settings.utils'
import { useTheme } from '../../../../hooks/use-theme.hook'
import './master-services-widget-form-general-settings.styles.less'
import { MasterServicesWidgetFormGeneralSettingsProps } from './master-services-widget-form-general-settings.types'

declare const _smBookingWidget: any

export const MasterServicesWidgetFormGeneralSettings: React.FC<
  MasterServicesWidgetFormGeneralSettingsProps
> = props => {
  const { masterServicesOptions, form } = props
  const { theme } = useTheme()

  const [stepsHidden, setStepsHidden] = useState(false)
  const [slotPriceWithoutGradeImpact, setSlotPriceWithoutGradeImpact] = useState(false)
  const [requestFirstName, setRequestFirstName] = useState(true)
  const [requestLastName, setRequestLastName] = useState(false)

  const toggleDisplaySteps = (checked: boolean) => {
    setStepsHidden(checked)

    form.setFieldValue(['general', 'displaySteps'], !checked)
    _smBookingWidget('event', 'setSettings', undefined, 'displaySteps', !checked)
  }

  const toggleSlotPriceWithoutGradeImpact = (checked: boolean) => {
    setSlotPriceWithoutGradeImpact(checked)

    form.setFieldValue(['general', 'slotPriceWithoutGradeImpact'], checked)
    _smBookingWidget('event', 'setSettings', undefined, 'slotPriceWithoutGradeImpact', checked)
  }

  const toggleRequestFirstName = (checked: boolean) => {
    setRequestFirstName(checked)

    form.setFieldValue(['general', 'userContacts', 'requestFirstName'], checked)
    _smBookingWidget('event', 'setSettings', undefined, 'userContacts.requestFirstName', checked)
  }

  const toggleRequestLastName = (checked: boolean) => {
    setRequestLastName(checked)

    form.setFieldValue(['general', 'userContacts', 'requestLastName'], checked)
    _smBookingWidget('event', 'setSettings', undefined, 'userContacts.requestLastName', checked)
  }

  const handleMasterServiceSelect = (value: string) => {
    _smBookingWidget('event', 'setMasterService', value)
  }

  return (
    <Card
      title="Основные настройки"
      bordered={false}
      style={{ width: 300 }}
      className="master-services-widget-form-general-settings__card"
    >
      <Space direction="vertical" style={{ display: 'flex' }}>
        <Form.Item label="Название (видите только вы)" name={['general', 'name']} required>
          <Input />
        </Form.Item>
        <Form.Item label="Персональная услуга" name={['general', 'masterServiceId']} required>
          <Select options={masterServicesOptions} onSelect={handleMasterServiceSelect} />
        </Form.Item>
        <Form.Item label="Предел бронирования в днях" name={['general', 'bookingDays']} required tooltip="Tooltip">
          <Input suffix="день" />
        </Form.Item>
        <Form.Item label="Код страны по-умолчанию" name={['general', 'countryCode']} required tooltip="Tooltip">
          <Input prefix="+" />
        </Form.Item>
        <Form.Item label="Валюта" name={['general', 'currency']} required>
          <Select options={genCurrencyOptions()} />
        </Form.Item>
        <Form.Item label="Язык" name={['general', 'language']} required>
          <Select options={genLanguageOptions()} />
        </Form.Item>

        <div className="master-services-widget-form-general-settings__group">
          <Typography.Title level={5} style={{ marginTop: 0 }}>
            Формат размещения
          </Typography.Title>
          <Form.Item name={['general', 'staticWidgetMode']}>
            <Radio.Group options={genWidgetModeOptions()} />
          </Form.Item>
        </div>

        <div className="master-services-widget-form-general-settings__group">
          <Typography.Title level={5} style={{ marginTop: 0 }}>
            Поля для оформления
          </Typography.Title>

          <Space direction="vertical" style={{ display: 'flex' }}>
            <Flex gap="small" justify="spaceBetween">
              <Form.Item
                name={['general', 'userContacts', 'requestFirstName']}
                valuePropName="checked"
                className="master-services-widget-form-general-settings__switch"
              >
                <Switch size="small" value={requestFirstName} onChange={toggleRequestFirstName} />
              </Form.Item>
              <Flex gap={0} vertical>
                <span>Имя клиента</span>
                {requestFirstName && (
                  <Form.Item name={['general', 'userContacts', 'firstNameRequired']} valuePropName="checked">
                    <Flex gap="small" align="center">
                      <Checkbox />
                      <span>Обязательно</span>
                    </Flex>
                  </Form.Item>
                )}
              </Flex>
            </Flex>

            <Flex gap="small" justify="spaceBetween">
              <Form.Item
                name={['general', 'userContacts', 'requestLastName']}
                className="master-services-widget-form-general-settings__switch"
              >
                <Switch size="small" value={requestLastName} onChange={toggleRequestLastName} />
              </Form.Item>
              <Flex gap={0} vertical>
                <span>Фамилия клиента</span>
                {requestLastName && (
                  <Form.Item name={['general', 'userContacts', 'lastNameRequired']} valuePropName="checked">
                    <Flex gap="small" align="center">
                      <Checkbox />
                      <span>Обязательно</span>
                    </Flex>
                  </Form.Item>
                )}
              </Flex>
            </Flex>
          </Space>
        </div>

        <div className="master-services-widget-form-general-settings__group">
          <Typography.Title level={5} style={{ marginTop: 0 }}>
            Функционал
          </Typography.Title>
          <Form.Item name={['general', 'displaySteps']} valuePropName="checked">
            <Flex gap="small" align="center">
              <Switch size="small" value={stepsHidden} onChange={toggleDisplaySteps} />
              <span>Скрывать шаги</span>
            </Flex>
          </Form.Item>
          <Form.Item name={['general', 'slotPriceWithoutGradeImpact']} valuePropName="checked">
            <Flex gap="small" align="center">
              <Switch size="small" value={slotPriceWithoutGradeImpact} onChange={toggleSlotPriceWithoutGradeImpact} />
              <span>Показывать стоимость слотов в расписании без учета наценки за исполнителя</span>
            </Flex>
          </Form.Item>
        </div>

        <Card size="small" style={{ backgroundColor: theme === 'dark' ? '#1f1f1f' : '#F5F5F5' }}>
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Typography.Title level={5} style={{ marginTop: 0 }}>
              Словарь терминов
            </Typography.Title>
            <Form.Item label="Мастер" name={['general', 'vocabulary', 'master']} required>
              <Input />
            </Form.Item>
            <Form.Item label="Место (студия)" name={['general', 'vocabulary', 'place']} required>
              <Input />
            </Form.Item>
            <Form.Item label="Запись" name={['general', 'vocabulary', 'service']} required>
              <Input />
            </Form.Item>
          </Space>
        </Card>
      </Space>
    </Card>
  )
}

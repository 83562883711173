import * as React from 'react'
import { InputNumber } from 'antd'

import { TransactionsProductsTableAmountEvents } from './transactions-products-table-amount.types'

interface Props extends TransactionsProductsTableAmountEvents {
  productUuid: string
  value: number
}

export const TransactionsProductsTableAmount: React.FC<Props> = props => {
  const { productUuid, value, onChangeAmount } = props

  const onChangeHandler = React.useCallback(
    (value: number | null): void => {
      onChangeAmount(productUuid, value ?? 0)
    },
    [onChangeAmount, productUuid]
  )

  return (
    <InputNumber style={{ width: '64px' }} value={value} onChange={onChangeHandler} min={1} maxLength={1000000000} />
  )
}

import clsx from 'clsx'
import { Button, Checkbox, Flex, Form, FormInstance, FormListFieldData, Typography } from 'antd'
import { useState, useCallback, useEffect, useRef } from 'react'

import { StudiosFormTypes } from '../../../studios-form-types'
import './studios-form-room-places.styles.less'
import { isDefAndMoreThenZero } from '../../../../../../types/lang.types'

interface Props {
  form: FormInstance<StudiosFormTypes>
  field: FormListFieldData
}

function Panel({
  children,
  width,
  setInitialWidth,
}: {
  children?: React.ReactNode
  width: number
  setInitialWidth: any
}) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      setInitialWidth(ref.current.offsetWidth)
    }
  }, [ref])

  return (
    <div className="studios-form-room-places__panel" ref={ref} style={{ width: width ? `${width}px` : '100%' }}>
      {children ?? null}
    </div>
  )
}

export const StudiosFormRoomPlaces: React.FC<Props> = props => {
  const { form, field } = props

  const totalCapacity = Form.useWatch(['rooms', field.name, 'totalCapacity'], form)

  const snakeCountdown = Form.useWatch(['rooms', field.name, 'snakeCountdown'], form)

  const [position, setPosition] = useState<'top' | 'bottom' | 'left' | 'right'>('top')

  const [state, setState] = useState<{ box1: number; box2: number; box3: number }>({ box1: 0, box2: 0, box3: 0 })

  const [resizeIsActive, setResizeIsActive] = useState<number | undefined>()

  const onMouseDown = useCallback(
    ([key1, key2]: Array<'box1' | 'box2' | 'box3'>, dragKey: number) =>
      () => {
        setResizeIsActive(dragKey)
        const onMove = (e: MouseEvent) => {
          const { movementX } = e
          const minWidth = 100

          setState(x => {
            // get new sizes
            const firstWidth = x[key1] + movementX
            const scndWidth = x[key2] - movementX

            // check constraints
            if ((firstWidth < minWidth && firstWidth < x[key1]) || (scndWidth < minWidth && scndWidth < x[key2])) {
              return x
            }

            return {
              ...x,
              [key1]: firstWidth,
              [key2]: scndWidth,
            }
          })
        }
        window.addEventListener('mousemove', onMove)
        window.addEventListener('mouseup', () => {
          window.removeEventListener('mousemove', onMove)
          setResizeIsActive(undefined)
        })
      },
    [setState, setResizeIsActive]
  )

  if (isDefAndMoreThenZero(totalCapacity)) {
    return (
      <>
        <div
          className={clsx('studios-form-room-places', {
            'studios-form-room-places_resize': resizeIsActive,
          })}
        >
          <Panel setInitialWidth={(box1: number) => setState(state => ({ ...state, box1 }))} width={state.box1}>
            <Button
              type="primary"
              ghost={position !== 'right'}
              style={{ transform: 'rotate(270deg)', float: 'right' }}
              onClick={() => setPosition('right')}
            >
              Тренер
            </Button>
          </Panel>
          <div className="studios-form-room-places__draggable" onMouseDown={onMouseDown(['box1', 'box2'], 1)}></div>
          <Panel setInitialWidth={(box2: number) => setState(state => ({ ...state, box2 }))} width={state.box2}>
            <Flex vertical gap="32px">
              <Button type="primary" ghost={position !== 'top'} onClick={() => setPosition('top')}>
                Тренер
              </Button>
              <div
                className={clsx('studios-form-room-places__scheme', {
                  'studios-form-room-places__scheme--grid-column': snakeCountdown,
                })}
                style={{ gridTemplateRows: `repeat(${Math.ceil(totalCapacity / (state.box2 / 76))}, 1fr)` }}
              >
                {[...Array(totalCapacity)]
                  .map((_, i) => {
                    return i + 1
                  })
                  .map(index => (
                    <div key={index} className="studios-form-room-places__place">
                      {index}
                    </div>
                  ))}
              </div>
              <Button type="primary" ghost={position !== 'bottom'} onClick={() => setPosition('bottom')}>
                Тренер
              </Button>
            </Flex>
          </Panel>
          <div className="studios-form-room-places__draggable" onMouseDown={onMouseDown(['box2', 'box3'], 2)} />
          <Panel setInitialWidth={(box3: number) => setState(state => ({ ...state, box3 }))} width={state.box3}>
            <Button
              type="primary"
              ghost={position !== 'left'}
              style={{ transform: 'rotate(270deg)' }}
              onClick={() => setPosition('left')}
            >
              Тренер
            </Button>
          </Panel>
        </div>
        <Form.Item valuePropName="checked" name={[field.name, 'snakeCountdown']}>
          <Checkbox>Отсчёт мест идёт змейкой</Checkbox>
        </Form.Item>
      </>
    )
  }
  return <Typography.Text type="secondary">Пожалуйста, укажите количество мест</Typography.Text>
}

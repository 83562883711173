import * as React from 'react'

import { ClientsEditPagePeekExerciseHistory } from '../pages/clients-edit-page/clients-edit-page-peek-exercise-history/clients-edit-page-peek-exercise-history.component'
import { ClientsEditPagePeekOverviewHistory } from '../pages/clients-edit-page/clients-edit-page-peek-overview-history/clients-edit-page-peek-overview-history.component'
import { ClientsEditPagePeekTransactionHistory } from '../pages/clients-edit-page/clients-edit-page-peek-transaction-history/clients-edit-page-peek-transaction-history.component'
import { EmployeesPagePeekHistory } from '../pages/employees-page/employees-page-peek-history/employees-page-peek-history.component'
import { EmployeesSchedulePeekHistory } from '../pages/employees/employees-schedule-peek-history/employees-schedule-peek-history.component'
import { GoodsPagePeekHistory } from '../pages/goods-page/peek-history/peek-history.component'
import { MasterServicesPagePeekHistory } from '../pages/master-services-page/master-services-page-peek-history/master-services-page-peek-history.component'
import { ProductsPagePeekHistory } from '../pages/products-page/products-page-peek-history/products-page-peek-history.component'
import { ScheduleManagementPagePeekHistory } from '../pages/schedule-management-page/schedule-management-page-peek-history/schedule-management-page-peek-history.component'
import { StudiosPagePeekHistory } from '../pages/studios-page/studios-page-peek-history/studios-page-peek-history.component'
import { TransactionsPagePeekHistory } from '../pages/transactions-page/transactions-page-peek-history/transactions-page-peek-history.component'
import { AppPeek } from '../types/peek.types'

export function genAppPeekComponent(peek: AppPeek): React.FC<any> {
  switch (peek) {
    // region ---- schedule management page
    case AppPeek.SCHEDULE_MANAGEMENT_PAGE_PEEK_HISTORY:
      return ScheduleManagementPagePeekHistory
    // endregion

    // region ---- master-service page
    case AppPeek.MASTER_SERVICES_PAGE_PEEK_HISTORY:
      return MasterServicesPagePeekHistory
    // endregion

    // region ---- clients edit page
    case AppPeek.CLIENTS_EDIT_PAGE_PEEK_OVERVIEW_HISTORY:
      return ClientsEditPagePeekOverviewHistory
    case AppPeek.CLIENTS_EDIT_PAGE_PEEK_EXERCISE_HISTORY:
      return ClientsEditPagePeekExerciseHistory
    case AppPeek.CLIENTS_EDIT_PAGE_PEEK_TRANSACTION_HISTORY:
      return ClientsEditPagePeekTransactionHistory
    // endregion

    // region ---- transactions page
    case AppPeek.TRANSACTIONS_PAGE_PEEK_HISTORY:
      return TransactionsPagePeekHistory
    // endregion

    // region ---- employees page
    case AppPeek.EMPLOYEES_PAGE_PEEK_HISTORY:
      return EmployeesPagePeekHistory
    // endregion

    // region ---- studios page
    case AppPeek.STUDIOS_PAGE_PEEK_HISTORY:
      return StudiosPagePeekHistory
    // endregion

    // region ---- products page
    case AppPeek.PRODUCTS_PAGE_PEEK_HISTORY:
      return ProductsPagePeekHistory
    // endregion

    // region ---- goods page
    case AppPeek.GOODS_PAGE_PEEK_HISTORY:
      return GoodsPagePeekHistory
    // endregion

    // region ---- employees schedule page
    case AppPeek.EMPLOYEES_SCHEDULE_PEEK_HISTORY:
      return EmployeesSchedulePeekHistory
    // endregion
  }
}

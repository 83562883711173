import { DownOutlined } from '@ant-design/icons'
import { TillsApi } from '@api/types/tills-api.types'
import { Alert, Button, Col, Flex, Form, FormInstance, Input, Radio, Select, Switch, Tag, Typography } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { RadioChangeEvent } from 'antd/lib'
import { useState } from 'react'

import { useFranchiseFormPrintingReceipts } from './franchises-form-printing-receipts.hook'
import './franchises-form-printing-receipts.styles.less'
export const FranchisesFormPrintingReceipts = ({
  form,
  tills,
  theme,
}: {
  form: FormInstance
  tills: TillsApi.IOnlineTillOptions[]
  theme: string
}) => {
  const {
    paymentOptions,
    openModalCreateTill,
    onlineTillEnabledSwitchValue,
    onChangeOnlineTillValueEnabledSwitchValue,
    offlineTillEnabledSwitchValue,
    onChangeOfflineTillValueEnabledSwitchValue,
    openModalEditTill,
    onlineTillValue,
    vatTypeOptions,
    snoTypeOptions,
  } = useFranchiseFormPrintingReceipts({ form })

  const [typeReceipts, setTypeReceipts] = useState<'online' | 'physical'>('online')
  const handleTypeReceiptsChange = (e: RadioChangeEvent) => {
    const value = e.target.value
    setTypeReceipts(value)
    form.setFieldsValue({ typeReceipts: value })
  }
  const goods = useWatch('GOODS', form)

  return (
    <Col className={`franchises-form franchises-form__bottom-printing-receipts ${theme}`}>
      <Typography.Title level={4}>Печать ОФД чеков</Typography.Title>

      <Flex gap={15} style={{ marginTop: 24, flexDirection: 'column' }}>
        <Typography.Title level={5}>Настройки чеков</Typography.Title>
        <Form.Item
          rules={[{ required: true, message: 'Выберите место расчётов для онлайн-кассы' }]}
          name="paymentAddressOnline"
          label="Место расчётов для онлайн-кассы"
        >
          <Input size="large" placeholder="Рекомендуем указать ваш сайт" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Выберите место рассчетов для чека зачёта аванса' }]}
          name="paymentAddressOffline"
          label="Место рассчетов для чека зачёта аванса"
        >
          <Input size="large" placeholder="Например: студия" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Выберите префикс к названию товара при онлайн-записи' }]}
          label="Префикс к названию товара при онлайн-записи"
          name="visitReceiptNamePrefix"
        >
          <Input size="large" placeholder="Например: тренировка" />
        </Form.Item>
      </Flex>
      <Flex gap={15} style={{ flexDirection: 'column', marginTop: 24, paddingBottom: 10 }}>
        <Typography.Title level={5}>Настройки налогообложения</Typography.Title>

        <Col>
          <Typography>Система НО для товаров</Typography>
          <Flex gap={24} style={{ marginTop: 7 }}>
            <Form.Item style={{ width: 237 }} name={['GOODS', 'snoType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={snoTypeOptions} defaultValue="УСН" size="large" />
            </Form.Item>
            <Form.Item style={{ width: 237 }} name={['GOODS', 'vatType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={vatTypeOptions} defaultValue="NONE" size="large" />
            </Form.Item>
          </Flex>
        </Col>
        <Col>
          <Typography>Система НО для разовых услуг</Typography>
          <Flex gap={24} style={{ marginTop: 7 }}>
            <Form.Item style={{ width: 237 }} name={['FULL_PAYMENT_SERVICE', 'snoType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={snoTypeOptions} defaultValue="УСН" size="large" />
            </Form.Item>
            <Form.Item style={{ width: 237 }} name={['FULL_PAYMENT_SERVICE', 'vatType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={vatTypeOptions} defaultValue="NONE" size="large" />
            </Form.Item>
          </Flex>
        </Col>
        <Col>
          <Typography>Система НО для авансовых разовых услуги</Typography>
          <Flex gap={24} style={{ marginTop: 7 }}>
            <Form.Item style={{ width: 237 }} name={['SERVICE', 'snoType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={snoTypeOptions} defaultValue="УСН" size="large" />
            </Form.Item>
            <Form.Item style={{ width: 237 }} name={['SERVICE', 'vatType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={vatTypeOptions} defaultValue="NONE" size="large" />
            </Form.Item>
          </Flex>
        </Col>
        <Col>
          <Typography>Система НО для персональных услуг</Typography>
          <Flex gap={24} style={{ marginTop: 7 }}>
            <Form.Item style={{ width: 237 }} name={['INSTANT_SUB_SERVICE', 'snoType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={snoTypeOptions} size="large" />
            </Form.Item>
            <Form.Item style={{ width: 237 }} name={['INSTANT_SUB_SERVICE', 'vatType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={vatTypeOptions} size="large" />
            </Form.Item>
          </Flex>
        </Col>
        <Col>
          <Typography>Система НО для абонементов</Typography>
          <Flex gap={24} style={{ marginTop: 7 }}>
            <Form.Item style={{ width: 237 }} name={['SUBSCRIPTION', 'snoType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={snoTypeOptions} size="large" />
            </Form.Item>
            <Form.Item style={{ width: 237 }} name={['SUBSCRIPTION', 'vatType']} rules={[{ required: true }]}>
              <Select dropdownClassName="customDropdown" options={vatTypeOptions} size="large" />
            </Form.Item>
          </Flex>
        </Col>
      </Flex>
      <Col style={{ paddingTop: 15 }}>
        <Typography.Title level={5}>Настройка подключения</Typography.Title>
        <Flex style={{ marginTop: 24, marginBottom: 15 }}>
          <Form.Item name="typeReceipts">
            <Radio.Group onChange={handleTypeReceiptsChange} defaultValue={typeReceipts} value={typeReceipts}>
              <Radio.Button style={{ width: 240, textAlign: 'center' }} value="online">
                <Flex justify="center" gap={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                  <Typography>Онлайн</Typography>
                  {onlineTillEnabledSwitchValue && <Tag color="green">Вкл.</Tag>}
                </Flex>
              </Radio.Button>
              <Radio.Button style={{ width: 240, textAlign: 'center' }} value="physical">
                <Flex justify="center" gap={8} style={{ paddingTop: 4, paddingBottom: 4 }}>
                  <Typography>Физическая ККМ</Typography>
                  {offlineTillEnabledSwitchValue && <Tag color="green">Вкл.</Tag>}
                </Flex>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Flex>

        <Col style={{ display: `${typeReceipts === 'online' ? 'block' : 'none'}` }}>
          <Form.Item name="onlineTillEnabled">
            <Flex align="center" gap={8} onClick={onChangeOnlineTillValueEnabledSwitchValue}>
              <Switch value={onlineTillEnabledSwitchValue} size="small" />
              <Typography>Включить онлайн-кассу для чеков</Typography>
            </Flex>
          </Form.Item>
          {onlineTillEnabledSwitchValue && (
            <Flex style={{ flexDirection: 'column', marginTop: 15 }} gap={15}>
              <Form.Item
                name="onlineTillSupportedPaymentMethodCategories"
                label="Включить онлайн кассу для выбранных методов оплат"
                rules={[{ required: true }]}
              >
                <Select mode="multiple" options={paymentOptions} size="large" />
              </Form.Item>
              <Form.Item label="Выберите кассу" name="onlineTill" rules={[{ required: true }]}>
                <Select
                  className="master-services-form-subservice-pricing-item__pricing-select"
                  options={tills}
                  suffixIcon={
                    <>
                      {onlineTillValue && (
                        <Button onClick={openModalEditTill} size="small">
                          Изменить / посмотреть
                        </Button>
                      )}

                      <DownOutlined />
                    </>
                  }
                  placeholder="Название онлайн-кассы"
                  size="large"
                />
              </Form.Item>
              <Button onClick={openModalCreateTill} type="dashed" style={{ width: 214 }}>
                Добавить новую кассу
              </Button>
            </Flex>
          )}
        </Col>
        <Flex
          gap={15}
          style={{ flexDirection: 'column', display: `${typeReceipts === 'physical' ? 'block' : 'none'}` }}
        >
          <Form.Item name="offlineTillEnabled">
            <Flex gap={8} align="center" onClick={onChangeOfflineTillValueEnabledSwitchValue}>
              <Switch size="small" value={offlineTillEnabledSwitchValue} />
              <Typography>Включить офлайн-кассу для чеков</Typography>
            </Flex>
          </Form.Item>
          {offlineTillEnabledSwitchValue && (
            <Flex
              gap={15}
              style={{
                flexDirection: 'column',
                padding: 15,
                backgroundColor: '#F5F5F5',
                borderRadius: 6,
              }}
            >
              <Alert message="Перейдите в настройку нужной студии и подключите ККМ." showIcon closable />
              <Form.Item
                name="offlineTillSupportedPaymentMethodCategories"
                label="Включить офлайн кассу для выбранных методов оплат"
                rules={[{ required: true }]}
              >
                <Select mode="multiple" options={paymentOptions} size="large" />
              </Form.Item>
            </Flex>
          )}
        </Flex>
      </Col>
    </Col>
  )
}

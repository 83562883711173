import { call, put, race, select, take, takeLatest } from 'redux-saga/effects'
import * as H from 'history'
import { createMatchSelector, getSearch } from 'connected-react-router'
import { match } from 'react-router-dom'

import { api } from '../../../../api/api'
import { genClientsEditPageParams } from '../../../../pages/clients-edit-page/clients-edit-page.utils'
import { genPaginationParamsDTO } from '../../../../utils/pagination.utils'
import { callApi } from '../../../../utils/sagas.utils'
import { clientsEditPageBookingsHistoryActions } from './clients-edit-page-bookings-history.slice'
import { ClientsEditPageUrlParams } from '../../../../pages/clients-edit-page/clients-edit-page.types'
import { AppPath } from '../../../../types/path.types'
import { AppState } from '../../../app.store'

export function* fetchBookings(action: ReturnType<typeof clientsEditPageBookingsHistoryActions.fetchBookings>) {
  try {
    const { clientId, page, size } = action.payload

    const params = genPaginationParamsDTO(page, size)

    const response: Awaited<ReturnType<typeof api.clientsBookingsService.fetchAllPast>> = yield callApi(
      api.clientsBookingsService.fetchAllPast,
      clientId,
      params
    )
    yield put(clientsEditPageBookingsHistoryActions.fetchBookingsSuccess(response.data))
  } catch {
    yield put(clientsEditPageBookingsHistoryActions.fetchBookingsError(new Error()))
  }
}

export function* reFetchBookings() {
  const search: H.Search = yield select(getSearch)
  const { params }: match<ClientsEditPageUrlParams> = yield select(
    createMatchSelector<AppState, ClientsEditPageUrlParams>(AppPath.CLIENTS_EDIT)
  )
  const { id, page, size } = genClientsEditPageParams(params, search)

  yield put(
    clientsEditPageBookingsHistoryActions.fetchBookings({
      clientId: id,
      page,
      size,
    })
  )

  yield race([
    take(clientsEditPageBookingsHistoryActions.fetchBookingsSuccess.type),
    take(clientsEditPageBookingsHistoryActions.fetchBookingsError.type),
  ])
}

export function* changeBookingPaymentType(
  action: ReturnType<typeof clientsEditPageBookingsHistoryActions.changeBookingPaymentType>
) {
  try {
    const { exerciseId, paymentType, bookingId } = action.payload

    yield callApi(api.exercisesService.changeBookingPaymentType, exerciseId, { paymentType, bookingId })

    yield put(clientsEditPageBookingsHistoryActions.changeBookingPaymentTypeSuccess())

    yield call(reFetchBookings)
  } catch (e) {
    yield put(clientsEditPageBookingsHistoryActions.changeBookingPaymentTypeError(new Error()))
  }
}

export function* clientsEditPageBookingsHistorySagas() {
  yield takeLatest(clientsEditPageBookingsHistoryActions.fetchBookings.type, fetchBookings)
  yield takeLatest(clientsEditPageBookingsHistoryActions.reFetchBookings.type, reFetchBookings)
  yield takeLatest(clientsEditPageBookingsHistoryActions.changeBookingPaymentType, changeBookingPaymentType)
}

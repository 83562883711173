import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { EmployeesFormValuesDTO } from '../../../components/employees/employees-form/employees-form.types'
import { Pagination } from '../../../api/types/api.types'
import { EmployeesPositionsApi } from '../../../api/types/employees-positions-api.types'
import { OrganizationsApi } from '../../../api/types/organizations-api.types'
import { NString, Nullable } from '../../../types/lang.types'
import { EmployeesCreatePageDataSuccessPayload } from './employees-create-page.types'
import { ExercisesDirectionsApi } from '../../../api/types/exercises-directions-api.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'

export interface EmployeesCreatePageState {
  franchises: Nullable<Pagination<OrganizationsApi.Organization>>
  positions: Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>
  directions: Nullable<Pagination<ExercisesDirectionsApi.ExerciseDirection>>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
  position: NString
}

const initialState: EmployeesCreatePageState = {
  franchises: null,
  positions: null,
  directions: null,
  exercisesTypes: null,
  isLoading: false,
  isLoaded: false,
  error: null,
  position: null,
}

export const { actions: employeesCreatePageActions, reducer: employeesCreatePageReducer } = createSlice({
  name: '@@employees-create-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<EmployeesCreatePageState>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (
      state: Draft<EmployeesCreatePageState>,
      action: PayloadAction<EmployeesCreatePageDataSuccessPayload>
    ) => {
      const { franchises, positions, directions, exercisesTypes } = action.payload

      state.franchises = franchises
      state.positions = positions
      state.directions = directions
      state.exercisesTypes = exercisesTypes
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<EmployeesCreatePageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    updatePosition: (state: Draft<EmployeesCreatePageState>, action) => {
      state.position = action.payload
    },
    createEmployee: (state: Draft<EmployeesCreatePageState>, _: PayloadAction<EmployeesFormValuesDTO>) => {
      state.isLoading = true
    },
    createEmployeeSuccess: (state: Draft<EmployeesCreatePageState>) => {
      state.isLoading = false
    },
    createEmployeeError: (state: Draft<EmployeesCreatePageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})

import { formatPluralized } from '@format/text.format'
import { Typography } from 'antd'
import dayjs from 'dayjs'

interface Props {
  variant: string
  minutes: number
  visits: number
  duration: number
}

export const ProductsSubscriptionsTableColumnFormats = ({ variant, minutes, visits, duration }: Props) => {
  let text = ''
  if (variant === 'BY_VISITS') {
    text = `${formatPluralized(visits, ['занятия', 'занятий', 'занятий'])}`
  } else if (variant === 'BY_TIME') {
    text = `${formatPluralized(minutes / 60, ['часы', 'часов', 'часов'])}`
  } else if (variant === 'BY_DURATION') {
    text = `${formatPluralized(dayjs.duration(duration).asDays(), ['дни', 'дней', 'дней'])}`
  }
  return <Typography.Text>{text}</Typography.Text>
}

import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { MasterServicesPageParams } from '../master-services-page.types'
import { genMasterServicesPageParams } from '../master-services-page.utils'

export function useMasterServicesPageParams(): MasterServicesPageParams {
  const { search } = useLocation()

  return React.useMemo(() => genMasterServicesPageParams(search), [search])
}

import { AxiosResponse } from 'axios'

import { DictionaryItem } from './../types/api.types'
import { HttpConnector } from '../connectors/http.connector'
import { ProductsGoodsApi } from '../types/products-goods-api.types'
import { Pagination } from '../types/api.types'

export class GoodsCategoriesService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public create = (data: ProductsGoodsApi.ProductGoodsDTO): Promise<AxiosResponse<DictionaryItem>> => {
    return this.httpConnector.post<DictionaryItem>('/products/goods/categories', data)
  }

  public fetchAll = (params: any): Promise<AxiosResponse<Pagination<DictionaryItem>>> => {
    return this.httpConnector.get<Pagination<DictionaryItem>>('/products/goods/categories', { params })
  }

  public fetchById = (id: string): Promise<AxiosResponse<DictionaryItem>> => {
    return this.httpConnector.get<DictionaryItem>(`/products/goods/categories/${id}`)
  }

  public update = (id: string, data: DictionaryItem): Promise<AxiosResponse<DictionaryItem>> => {
    return this.httpConnector.patch<DictionaryItem>(`/products/goods/categories/${id}`, data)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/products/goods/categories/${id}`)
  }
}

import { ProductsHistoryPeek } from '../../../components/products/products-history-peek/products-history-peek.component'
import { useProductsPagePeekHistory } from './products-page-peek-history.hook'
import { ProductsPagePeekHistoryProps } from './products-page-peek-history.types'

export const ProductsPagePeekHistory: React.FC<ProductsPagePeekHistoryProps> = props => {
  const { id, name, section } = props

  const { productHistory, isLoading, pagination, onChangePageHandler, onChangePageSizeHandler } =
    useProductsPagePeekHistory(id, section)

  return (
    <ProductsHistoryPeek
      productHistory={productHistory}
      name={name}
      section={section}
      isLoading={isLoading}
      pagination={pagination}
      onChangePage={onChangePageHandler}
      onChangePageSize={onChangePageSizeHandler}
    />
  )
}

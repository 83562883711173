import * as React from 'react'
import { Input, InputRef } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

import { AutocompleteInputProps } from '../../autocomplete/autocomplete-input/autocomplete-input.types'
import { formatSearchPhoneNumber } from '../../../../format/phone.format'

const SearchAndQrInputInternal = (props: AutocompleteInputProps, ref: React.Ref<InputRef>) => {
  const { value, placeholder, disabled, onChange, onClick, onClear } = props

  return (
    <Input.Search
      allowClear={{ clearIcon: <CloseOutlined onClick={onClear} /> }}
      ref={ref}
      value={formatSearchPhoneNumber(value)}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
    />
  )
}

export const SearchAndQrInput = React.forwardRef(SearchAndQrInputInternal) as typeof SearchAndQrInputInternal
